import React from 'react';
import { Permissions } from '@apw/components/profileDetail/profileBody/technicalDetails/permissions';
import { styled } from '@ringcentral/juno';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 58px;
`;

export const TechnicalDetails = () => {
  return (
    <Wrapper>
      <Permissions />
    </Wrapper>
  );
};
