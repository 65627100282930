import React, { FC, useMemo } from 'react';
import { IPageCard, PageCard } from '@apw/components/pageCard';
import { addUtmTrackingParams, UtmTrackingParam } from '@apw/shared';
import { breakpoints } from '@apw/shared/theme/utils';
import { applyProximaNovaSemibold } from '@apw/shared/theme/utils/typography';
import { styled } from '@ringcentral/juno';

const PAGE_CARD_GAP = '12px';
const ALL_APPLICATIONS = 'All applications';

interface FeaturedInProps {
  pages: IPageCard[] | undefined;
  className?: string;
}

const utmTrackingParams = {
  [UtmTrackingParam.SOURCE]: 'app-gallery',
  [UtmTrackingParam.CAMPAIGN]: 'profile-featured-in',
};

const pagePathResolver = (rawPath: string): string => {
  return addUtmTrackingParams(rawPath, utmTrackingParams);
};

export const FeaturedIn: FC<FeaturedInProps> = ({ pages, className = '' }) => {
  const items = useMemo(() => {
    return (pages || [])
      .filter((page) => Boolean(page.imageUrl))
      .filter((page) => page.name !== ALL_APPLICATIONS);
  }, [pages]);

  if (items.length > 0) {
    return (
      <StyledContainer
        className={className}
        data-test-automation-id="feature-in-container"
      >
        <StyledHeader data-test-automation-id="feature-in-title">
          Featured in
        </StyledHeader>
        <StyledBody data-test-automation-id="feature-in-list">
          {items.map((page) => (
            <StyledPageCard
              key={page.pageId}
              page={page}
              pathResolver={pagePathResolver}
            />
          ))}
        </StyledBody>
      </StyledContainer>
    );
  }

  return null;
};

const StyledPageCard = styled(PageCard)``;

const StyledContainer = styled.div``;

const StyledHeader = styled.div`
  ${applyProximaNovaSemibold()}
  margin: 0 0 20px;
  font-size: 22px;
  font-weight: bold;
  color: #001138;
`;

const StyledBody = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${StyledPageCard} {
    width: calc(50% - ${PAGE_CARD_GAP} / 2);
    margin-bottom: ${PAGE_CARD_GAP};

    @media ${breakpoints.contentCard1} {
      width: 100%;
    }
  }
`;
