export enum MediaAndDocumentProps {
  MEDIA = 'media',
  DOCUMENTS = 'documents',
}

export interface MediaAndDocument {
  [MediaAndDocumentProps.MEDIA]: Array<Screenshot | Video>;
  [MediaAndDocumentProps.DOCUMENTS]: Document[];
}

export enum MediaType {
  SCREENSHOT = 'Screenshot',
  VIDEO = 'Vedio',
}

export enum MediaProps {
  TYPE = 'type',
  URL = 'url',
}

export interface Media {
  [MediaProps.TYPE]: MediaType;
  [MediaProps.URL]: string;
}

export enum ScreenshotProps {
  TYPE = 'type',
  URL = 'url',
  TITLE = 'title',
  DESC = 'description',
}

export interface Screenshot extends Media {
  [ScreenshotProps.TYPE]: MediaType.SCREENSHOT;
  [ScreenshotProps.TITLE]: string;
  [ScreenshotProps.DESC]: string;
}

export enum VideoPropEnum {
  TYPE = 'type',
  URL = 'url',
}

export interface Video extends Media {
  [VideoPropEnum.TYPE]: MediaType.VIDEO;
}
