import { overridesFactory } from '@apw/shared/theme/utils/overrides.factory';
import { paletteFactory } from '@apw/shared/theme/utils/palette.factory';
import { RcThemeInput } from '@ringcentral/juno';

export const telusColor = '#2B8000';

export const telusTheme: RcThemeInput = {
  palette: paletteFactory(telusColor),
  overrides: overridesFactory(telusColor),
};
