export const decodeBrand = (value: string | undefined): string => {
  if (value) {
    const decodeMapping = {
      'AT&amp;T': 'AT&T',
      '1&amp;1': '1&1',
    };
    const reg = new RegExp(Object.keys(decodeMapping).join('|'), 'g');
    return value.replace(reg, (matchedStr) => decodeMapping[matchedStr]);
  }
  return '';
};
