import React, { FC, useState } from 'react';
import { storageService } from '@apw/shared/storage/storage.service';
import { applyProximaNovaRegular } from '@apw/shared/theme';
import { useStores } from '@apw/stores';
import { ICompatibility } from '@apw/stores/compatibility';
import { RcButton, RcIcon, styled } from '@ringcentral/juno';
import { ArrowDown, ArrowUp } from '@ringcentral/juno-icon';

import { observer } from 'mobx-react';

const Wrapper = styled.div`
  padding-bottom: 40px;
`;

const DropdownTrigger = styled(RcButton)`
  &&& {
    ${applyProximaNovaRegular()}
    padding: 0;
    font-weight: normal;
    font-size: 16px;
    padding: 8px 0;
    margin-top: 4px;
  }
`;

const DropdownContent = styled.div``;

const Description = styled.p`
  margin: 8px 0 24px;
  line-height: 24px;
`;

const LogosContainer = styled.div``;

const LogoWrapper = styled.div`
  margin: 16px 0;
`;

const Logo = styled.img`
  height: 24px;
`;

const STORAGE_KEY_DROPDOWN_IS_EXPANDED =
  'RCDPW-AlsoAvailableFor-MoreServiceProviders-isExpanded';

const getDefaultStateForDropdown = () => {
  const isExpanded = storageService.getItem(STORAGE_KEY_DROPDOWN_IS_EXPANDED);
  return isExpanded === null || isExpanded === String(true);
};

const setDefaultStateForDropdown = (isExpanded: boolean) => {
  storageService.setItem(STORAGE_KEY_DROPDOWN_IS_EXPANDED, isExpanded);
};

interface MoreServiceProvidersPureProps {
  isExpanded: boolean;
  onToggle: () => void;
  subBrands: ICompatibility['subBrands'];
}

const MoreServiceProvidersPure: FC<MoreServiceProvidersPureProps> = ({
  isExpanded,
  onToggle,
  subBrands,
}) => {
  return (
    <Wrapper>
      <DropdownTrigger
        variant="plain"
        onClick={onToggle}
        data-test-automation-id="moreServiceProvidersTrigger"
        data-testid="moreServiceProvidersTrigger"
        aria-expanded={isExpanded}
      >
        <span>More service providers</span>
        <RcIcon
          symbol={isExpanded ? ArrowUp : ArrowDown}
          size="medium"
          tabIndex={-1}
        />
      </DropdownTrigger>
      {isExpanded && (
        <DropdownContent>
          <Description data-test-automation-id="moreServiceProvidersDesc">
            These service providers are built on top of the RingCentral
            Platform. Apps that work for RingCentral also work for these service
            providers.
          </Description>
          <LogosContainer data-test-automation-id="moreServiceProvidersLogosContainer">
            {subBrands.map((subBrand) => (
              <LogoWrapper>
                <Logo
                  src={subBrand.logoUrl}
                  alt={subBrand.name}
                  tabIndex={0}
                  data-test-automation-id={`moreServiceProvidersSubBrandLogo-${subBrand.brandId}`}
                />
              </LogoWrapper>
            ))}
          </LogosContainer>
        </DropdownContent>
      )}
    </Wrapper>
  );
};

export const MoreServiceProviders = observer(() => {
  const [isExpanded, setIsExpanded] = useState(getDefaultStateForDropdown());
  const { compatibilitiesStore } = useStores();
  const { rcCompatibility } = compatibilitiesStore;
  const risePartners = rcCompatibility?.subBrands || [];

  const onToggle = () => {
    const newState = !isExpanded;
    setIsExpanded(newState);
    setDefaultStateForDropdown(newState);
  };

  if (risePartners.length === 0) {
    return null;
  }

  return (
    <MoreServiceProvidersPure
      isExpanded={isExpanded}
      subBrands={risePartners}
      onToggle={onToggle}
    />
  );
});
