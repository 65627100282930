import { AxiosResponse } from 'axios';
import { isString } from 'lodash';
import { ErrorResponseInterface } from './error-response.interface';

export abstract class ErrorResponse implements ErrorResponseInterface {
  protected constructor(private err: AxiosResponse) {}

  protected getResponseBody(): any {
    return this.err?.data || {};
  }

  abstract extractErrorCode(): string;

  isTypeMatched(): boolean {
    const errorCode = this.extractErrorCode();
    return isString(errorCode);
  }
}
