import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { ExternalLink } from '@ringcentral/juno-icon';
import {
  LinkIcon,
  LinkItem,
  LinkItemContent,
  LinkUrl,
  LinkWrapper,
  Wrapper,
} from './helpfulLinks.sc';

export const HelpfulLinks = ({ className = '', links }) => {
  const theme = useTheme();

  if (!links || !links.length) {
    return null;
  }

  return (
    <Wrapper className={`${className}`}>
      <h5>HELPFUL LINKS</h5>
      <LinkWrapper>
        {(links || []).map((item) => {
          return (
            <LinkItem key={item.key}>
              <LinkItemContent
                href={item.link}
                target="_blank"
                rel="noreferrer"
                style={{ color: theme.palette.secondary.main }}
                aria-label={item.label}
                data-test-automation-id={`helpful-link-${item.label}`}
              >
                <LinkUrl
                  aria-hidden="true"
                  data-test-automation-id={`helpful-link-label`}
                >
                  {item.label}
                </LinkUrl>
                <LinkIcon
                  style={{
                    marginLeft: 24,
                    color: theme.palette.secondary.main,
                    flexShrink: 0,
                  }}
                  symbol={ExternalLink}
                  size="small"
                  data-test-automation-id={`helpful-link-icon`}
                />
              </LinkItemContent>
            </LinkItem>
          );
        })}
      </LinkWrapper>
    </Wrapper>
  );
};
