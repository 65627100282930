import { css } from '@ringcentral/juno';

export const osStyles = css`
  &.macOS {
    svg {
      font-size: 18px;
    }
  }

  &.Windows {
    svg {
      font-size: 15px;
    }
  }

  &.Android {
    svg {
      font-size: 19px;
    }
  }

  &.iOS {
    svg {
      font-size: 17px;
    }
  }

  &.otherOS {
    svg {
      font-size: 18px;
    }
  }
`;
