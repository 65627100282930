import { get } from '@apw/core/api/api.service';
import { IGalleryBrandConfig } from '@apw/stores/compatibility/compatibilities/galleryBrand/galleryBrand.interface';
import { IGalleryBrand } from './galleryBrand.interface';

export interface IGalleryBrandsLoaderOptions {
  getAll?: boolean;
}

export const loadGalleryBrands = (
  options: IGalleryBrandsLoaderOptions = {},
): Promise<IGalleryBrand[]> => {
  const { getAll } = options;
  const endpoint = `/api/gallery-brands`;
  return get(endpoint, {
    params: {
      getAll,
    },
    useGlobalLoading: true,
  });
};

export const prepareGalleryBrands = (
  options: IGalleryBrandsLoaderOptions = {},
): Promise<IGalleryBrand[]> => {
  return loadGalleryBrands(options).then((galleryBrands) =>
    normalize(galleryBrands),
  );
};

const normalize = (galleryBrands: IGalleryBrand[]): IGalleryBrand[] => {
  return galleryBrands.map((item) => {
    let parsedConfig: IGalleryBrandConfig;

    try {
      parsedConfig = JSON.parse(item.configuration);
    } catch (err) {
      parsedConfig = {} as IGalleryBrandConfig;
    }

    return {
      ...item,
      parsedConfig,
    };
  });
};
