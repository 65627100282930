import React, { FC } from 'react';
import { MultipleLineEllipsis } from '@drw/lib';

interface IProfileNameProps {
  isMobileDevice: boolean;
  name: string;
}

export const ProfileName: FC<IProfileNameProps> = ({
  isMobileDevice,
  name,
}) => {
  const lineHeight = isMobileDevice ? 28 : 48;
  const key = isMobileDevice ? 'mobileAppName' : 'desktopAppName';

  return (
    <MultipleLineEllipsis
      linesToDisplay={2}
      lineHeightPx={lineHeight}
      key={key}
      data-test-automation-id="profileName"
    >
      {name}
    </MultipleLineEllipsis>
  );
};
