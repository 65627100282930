import React, { useContext, useEffect, useMemo, useState } from 'react';
import { PageContentContext } from '@apw/lib/components/pageContent/pageContent.context';
import { IFeatureBase, IFeatureBlock } from '@apw/lib/types';
import { BlockHeader, filterFeatures, flexNumMap } from '../shared';
import styles from './featureBlock.module.scss';
import { FeatureItem } from './featureItem';

const FeatureList = ({ array }) => {
  return array.map((item, index) => {
    // eslint-disable-next-line react/no-array-index-key
    return <FeatureItem item={item} key={index.toString()} />;
  });
};

function FeatureBlock({
  data,
  isFirst,
}: {
  data: IFeatureBlock;
  isFirst: boolean;
}) {
  /**
   * flexNumMap: flex number map
   * flex: flex layout for the feature items
   * percent: percentage  layout of the feature container
   */
  const [flex, setFlex] = useState<IFeatureBase[]>([]);
  const [percent, setPercent] = useState<IFeatureBase[]>([]);
  const items = useMemo(() => filterFeatures(data.items), [data.items]);
  const {
    contentLayout,
    styleTokens: { features },
  } = useContext(PageContentContext);

  useEffect(() => {
    if (!items || items.length === 0) return;
    const num = flexNumMap[items.length - 1];
    setFlex(items.slice(0, num));
    setPercent(items.slice(num));
  }, [items]);

  return flex.length > 0 || percent.length > 0 ? (
    <div
      className={`${styles.featureBlock} featureBlock`}
      data-test-automation-id="feature-block"
    >
      <BlockHeader
        data={{
          ...data,
          styleTokens: {
            heading: features.heading,
            subHeading: features.subHeading,
          },
        }}
        isH1={isFirst}
      />
      <div className={`${styles.flex} ${styles[contentLayout]}`}>
        <FeatureList array={flex} />
      </div>
      <div className={`${styles.percent} ${styles[contentLayout]}`}>
        <FeatureList array={percent} />
      </div>
    </div>
  ) : null;
}

export { FeatureBlock };
