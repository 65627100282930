import React, { FC, useContext } from 'react';
import { ProfileViewModelContext } from '@apw/components/profileDetail/Profile.ViewModel.context';
import { StyledButton } from '@apw/components/ratingAndReview/actionButtons/writeReviewButton/writeReviewButton.sc';

export interface IWriteReviewButtonProps {
  disabled?: boolean;
  fullWidth: boolean;
}

export const WriteReviewButton: FC<IWriteReviewButtonProps> = ({
  disabled,
  fullWidth,
}) => {
  const profileViewModel = useContext(ProfileViewModelContext);
  const onClick = () => {
    profileViewModel.openRatingPopup();
  };

  return (
    <StyledButton
      size="xlarge"
      variant="outlined"
      color="action.primary"
      radius="lg"
      fullWidth={fullWidth}
      onClick={onClick}
      data-test-automation-id={'write-review-button'}
      disabled={disabled}
    >
      Write a review
    </StyledButton>
  );
};
