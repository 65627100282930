import {
  onMobile,
  PAGE_DESKTOP_MARGINTOP,
  PAGE_MOBILE_MARGINTOP,
} from '@apw/core/sc';
import { styled, css } from '@ringcentral/juno';

export const ProfilePageWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
`;

export const ProfileWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  max-width: 1064px;
  margin: ${PAGE_DESKTOP_MARGINTOP}px auto 0;
  padding: 0 20px;
  width: 100%;

  ${onMobile} {
    margin: ${PAGE_MOBILE_MARGINTOP}px auto 0;
    padding: 0;
  }
`;

export const profileNavigationBarStyles = css`
  margin-bottom: 0 !important;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
`;
