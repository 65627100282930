import React, { FC, useEffect, useState } from 'react';
import { Alert } from '@apw/components';
import { IProcessedProfile } from '@apw/components/profileDetail';
import { Language, languageService } from '@apw/core/language';
import { LeadForm } from '@apw/modules/profile';
import { PopupTypes } from '@apw/modules/profile/lead/popupTypes';
import { LoginOption } from '@apw/modules/profile/loginOption';
import { withGlobalScrollForLeadForm } from '@apw/shared';
import { useStores } from '@apw/stores';
import { observer } from 'mobx-react';

type PopupType =
  | PopupTypes.LoginOption
  | PopupTypes.Form
  | PopupTypes.Success
  | null;

const LeadPure: FC<{
  profile: IProcessedProfile;
  onAfterSignIn: () => void;
  onClose: () => void;
}> = ({ profile, onAfterSignIn, onClose }) => {
  const [popupType, setPopupType] = useState<PopupType>(null);

  const { userStore } = useStores();

  const showLeadForm = () => {
    setPopupType(PopupTypes.Form);
  };

  const handleSubmit = () => {
    setPopupType(PopupTypes.Success);
  };

  useEffect(() => {
    if (userStore.isLoggedIn) {
      showLeadForm();
    } else {
      setPopupType(PopupTypes.LoginOption);
    }
  }, []);

  return (
    <>
      {popupType === PopupTypes.LoginOption && (
        <LoginOption
          title={languageService.get(Language.LEAD_FORM_LOGIN_OPTION_TITLE)}
          onClose={onClose}
          onAfterSignIn={onAfterSignIn}
          onAsGuest={showLeadForm}
        />
      )}
      {popupType === PopupTypes.Form && (
        <LeadForm profile={profile} onSubmit={handleSubmit} onClose={onClose} />
      )}
      {popupType === PopupTypes.Success && (
        <Alert
          title={languageService.get(Language.TITLE_CONGRATULATIONS)}
          message={languageService.get(
            Language.LEAD_FORM_SUCCESSFUL_SUBMISSION,
          )}
          onClose={onClose}
        />
      )}
    </>
  );
};

export const Lead = withGlobalScrollForLeadForm(observer(LeadPure));
