import React, { FC } from 'react';
import { applyProximaNovaSemibold } from '@apw/shared/theme';
import { GalleryIconType } from '@apw/stores/compatibility';
import { RcButton, RcIcon, styled } from '@ringcentral/juno';

const CardButton = styled(RcButton)`
  &&& {
    border-color: #e1e6ea;
    padding: 20px 16px;
    width: 274px;
    height: 96px;
    justify-content: flex-start;
    border-radius: 8px;
  }
`;

const DEFAULT_ICON_SIZE = 56;
const SPECIAL_ICON_SIZE = DEFAULT_ICON_SIZE * 1.5;

const GalleryIconContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${DEFAULT_ICON_SIZE}px;
  height: ${DEFAULT_ICON_SIZE}px;
  flex-shrink: 0;

  img {
    width: 100%;
  }

  .ringcentral {
    width: ${DEFAULT_ICON_SIZE}px;
    height: ${DEFAULT_ICON_SIZE}px;
  }

  .office-at-hand {
    width: ${DEFAULT_ICON_SIZE}px;
    height: ${DEFAULT_ICON_SIZE}px;
  }

  .avaya-cloud-office {
    width: ${SPECIAL_ICON_SIZE}px;
    height: ${SPECIAL_ICON_SIZE}px;
  }

  .bt-cloud-work {
    width: ${DEFAULT_ICON_SIZE}px;
    height: ${DEFAULT_ICON_SIZE}px;
  }

  .telus-business-connect {
    width: ${DEFAULT_ICON_SIZE}px;
    height: ${DEFAULT_ICON_SIZE}px;
  }

  .atos-unify-office {
    width: ${SPECIAL_ICON_SIZE}px;
    height: ${SPECIAL_ICON_SIZE}px;
  }
`;

const BrandNameContainer = styled.div`
  &&& {
    ${applyProximaNovaSemibold()}
    font-size: 16px;
    line-height: 19px;
    color: #001138;
    margin-left: 16px;
    text-align: left;
  }
`;

interface IAppGalleryCardProps {
  brandId: string;
  brandName: string;
  galleryIcon: any;
  galleryIconType: GalleryIconType;
  galleryIconClassName: string;
  onClick: (brandId: string) => void;
  className: string;
  automationId?: string;
}

export const AppGalleryCard: FC<IAppGalleryCardProps> = ({
  brandId,
  brandName,
  galleryIcon,
  galleryIconType,
  galleryIconClassName,
  onClick,
  className,
  automationId,
}) => {
  const isSvgFormat = galleryIconType === GalleryIconType.SVG_FILE;

  return (
    <CardButton
      color="neutral"
      size="large"
      variant="outlined"
      onClick={() => onClick(brandId)}
      className={className}
      data-test-automation-id={automationId}
    >
      <GalleryIconContainer>
        {isSvgFormat && (
          <RcIcon
            symbol={galleryIcon}
            size="large"
            className={galleryIconClassName}
          />
        )}
        {!isSvgFormat && (
          <img src={galleryIcon} alt="" className={galleryIconClassName} />
        )}
      </GalleryIconContainer>
      <BrandNameContainer>{brandName}</BrandNameContainer>
    </CardButton>
  );
};
