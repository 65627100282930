import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { StoreContext } from '@apw/lib/components/profileDetail/StoreContext';
import { WriteReviewButton } from '@apw/lib/components/ratingAndReview/writeReviewButton/WriteReviewButton';
import { useIsMobile } from '@apw/lib/hooks/useIsMobile';
import {
  ActionButtonsContainer,
  Divider,
  NoRatingsWrapper,
  StyledNoRatingsSection,
} from './RatingsAndReviewsTab.sc';
import { RatingsSection } from './ratingsSection/RatingsSection';
import { ReviewsSection } from './reviewsSection/ReviewsSection';

export const RatingsAndReviewsTab = observer(() => {
  const profileVM = useContext(StoreContext);
  const isMobile = useIsMobile();
  const profile = profileVM.profile;

  if (!profile) {
    return null;
  }

  if (!profile.appRating && profileVM.totalNumberForReviews === 0) {
    return (
      <NoRatingsWrapper data-test-automation-id="no-ratings-section">
        <StyledNoRatingsSection
          variant={isMobile ? 'subheading1' : 'headline1'}
        />
        <ActionButtonsContainer>
          <WriteReviewButton fullWidth={isMobile} />
        </ActionButtonsContainer>
      </NoRatingsWrapper>
    );
  }

  return (
    <>
      {profile.appRating && (
        <>
          <RatingsSection
            data-test-automation-id="ratings-section"
            ratings={profile.appRating}
          />
          <Divider />
        </>
      )}
      <ReviewsSection />
    </>
  );
});
