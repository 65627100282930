import { PrimaryButton } from '@apw/components/button';
import { styled } from '@ringcentral/juno';
import { ctaButtonSizeOnMobile, ctaButtonTextOnMobile } from '../ctaButton.sc';

export const BotButton = styled(PrimaryButton)`
  ${ctaButtonSizeOnMobile};

  [class~='MuiButton-label'] {
    ${ctaButtonTextOnMobile};
  }
`;
