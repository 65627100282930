import Cookies from 'js-cookie';
export class StorageService {
    constructor() {
        Object.defineProperty(this, "storage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        if (!this.isLocalStorageSupported()) {
            Object.defineProperty(this.storage, 'length', {
                get: () => Object.keys(Cookies.get()).length,
            });
            this.storage.key = (index) => Object.keys(Cookies.get())[index] || null;
            this.storage.getItem = (key) => Cookies.get(key) || null;
            this.storage.setItem = (key, value, setAsCookieAnyway = true) => {
                if (setAsCookieAnyway) {
                    Cookies.set(key, value, { expires: 365, path: '/' });
                }
            };
            this.storage.removeItem = (key) => Cookies.remove(key);
        }
        else {
            this.storage = window.localStorage;
        }
    }
    key(index) {
        return this.storage.key(index);
    }
    getItem(key) {
        return this.storage.getItem(key);
    }
    setItem(key, value, setAsCookieAnyway = true) {
        this.storage.setItem(key, value, setAsCookieAnyway);
    }
    removeItem(key) {
        this.storage.removeItem(key);
    }
    isLocalStorageSupported() {
        const testKey = 'rcapw-isLocalStorageSupported';
        const storage = window.localStorage;
        try {
            storage.setItem(testKey, '1');
            storage.removeItem(testKey);
            return true;
        }
        catch (error) {
            return false;
        }
    }
}
export const storageService = new StorageService();
