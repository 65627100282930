import { Compatibility } from '@apw/stores/compatibility';

export const brandBannersTexts = {
  [Compatibility.TELUS]: [
    'Seamlessly integrate your business apps to drive business productivity.',
  ],
  [Compatibility.VODAFONE]: [
    'Vodafone Business UC solutions to keep you connected anytime, any where. Browser integrated business apps to drive productivity and save time for you and you business.',
  ],
};
