import { IAppGalleryOption } from '@apw/shared/navigation/compatibilitySelector/typings';
import { useStores } from '@apw/stores';
import { ICompatibility } from '@apw/stores/compatibility';

const buildAppGalleryOption = (
  compatibility: ICompatibility,
): IAppGalleryOption => {
  return {
    brandId: compatibility.id,
    brandName: compatibility.name,
    galleryIcon: compatibility.galleryIcon,
    galleryIconType: compatibility.galleryIconType,
    galleryIconClassName: compatibility.className,
  };
};

const buildAppGalleryOptions = (
  compatibilities: ICompatibility[],
): IAppGalleryOption[] => {
  return compatibilities.map((compatibility) => {
    return buildAppGalleryOption(compatibility);
  });
};

const buildAppGalleryOptionsForSubBrands = (
  compatibility: ICompatibility,
): IAppGalleryOption[] => {
  const subBrands = compatibility.subBrands || [];

  return subBrands.map((subBrand) => ({
    brandId: subBrand.brandId,
    brandName: subBrand.name,
    galleryIcon: compatibility.galleryIcon,
    galleryIconType: compatibility.galleryIconType,
    galleryIconClassName: compatibility.className,
    parentBrandId: compatibility.id,
    parentBrandName: compatibility.name,
  }));
};

export const useAppGalleryOptions = () => {
  const { compatibilitiesStore, compatibilityStore } = useStores();
  const { rcCompatibility, partnerCompatibilities } = compatibilitiesStore;

  const rcGallery = buildAppGalleryOption(rcCompatibility!);
  const partnerGalleries = buildAppGalleryOptions(partnerCompatibilities);
  const risePartners = buildAppGalleryOptionsForSubBrands(rcCompatibility!);
  const sourceForSearch = [rcGallery, ...partnerGalleries, ...risePartners];
  const currentGalleryBrandId = compatibilityStore.compatibility.id;

  return {
    rcGallery,
    partnerGalleries,
    sourceForSearch,
    currentGalleryBrandId,
  };
};
