import { OverallRating } from '@apw/components/ratingAndReview/overallRating/OverallRating';
import { onMobile, useProximaNovaRegular } from '@apw/core/sc';
import { palette2, styled } from '@ringcentral/juno';

export const Container = styled.div`
  margin-top: 40px;
  padding-bottom: 36px;
  display: flex;
  justify-content: space-between;

  ${onMobile} {
    margin-top: 20px;
    flex-direction: column;
  }
`;

export const StyledOverallRating = styled(OverallRating)`
  &&& {
    margin-left: 64px;
    margin-right: 80px;
    padding-top: 10px;
    padding-bottom: 0;

    > .MuiTypography-root {
      font-size: 16px;
      font-weight: 700;
      color: ${palette2('neutral', 'f06')};
    }

    ${onMobile} {
      margin-left: 0;
      margin-right: 0;
      padding-top: 0;
      padding-bottom: 24px;
      border-bottom: 1px solid ${palette2('neutral', 'l02')};
    }

    > p {
      font-size: 1rem;
      font-weight: 700;
      ${useProximaNovaRegular};
      line-height: 24px;
    }

    > div {
      margin-bottom: 4px;

      .star {
        font-size: 16px;
      }

      .MuiLinearProgress-root {
        width: 250px;
        margin-right: 12px;
      }
    }
  }
`;
