import { get } from '@apw/core/api/api.service';
import { from, Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { TimeZone } from './time-zone.interface';

class TimeZoneService {
  private timezones: TimeZone[] = [];

  getTimeZone(id: string): Observable<TimeZone> {
    if (this.timezones.length > 0) {
      return new Observable((observer: Observer<TimeZone>) => {
        setTimeout(() => {
          observer.next(this.findTimeZone(id));
          observer.complete();
        });
      });
    }
    return this.getTimeZones().pipe(
      map((data) => {
        this.timezones = data;
        return this.findTimeZone(id);
      }),
    );
  }

  formatTimeZone(timeZone: number): string {
    if (timeZone === 0) {
      return '(UTC)';
    }

    const hour = Math.abs(Math.floor(timeZone));
    const hourPart = hour < 10 ? `0${hour}` : hour;

    const minutes = Math.abs(this.getFloatPartFromNumber(timeZone));
    const minutePart = minutes > 0 ? minutes * 60 : '00';

    const result = `(UTC${timeZone > 0 ? '+' : '-'}${hourPart}:${minutePart})`;

    return result;
  }

  private getTimeZones(): Observable<TimeZone[]> {
    const endpoint = '/api/timezone';
    return from<Promise<TimeZone[]>>(get(endpoint));
  }

  private findTimeZone(id): TimeZone {
    return this.timezones.find((v) => {
      return v.id === id;
    }) as TimeZone;
  }

  private getFloatPartFromNumber(number: number): number {
    return parseFloat((number % 1).toFixed(4));
  }
}

export const timeZoneService = new TimeZoneService();
