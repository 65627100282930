import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ProfileOptionsContext } from '@apw/components/profileDetail/profile-options.context';
import { ProfileContext } from '@apw/components/profileDetail/profile.context';
import {
  AvailableOS,
  DownloadButton,
  NoDownloadLinksAvailable,
  OsIcon,
  PlatformOptionInPrimaryButton,
} from '@apw/components/profileDetail/profileHeader/ctaButton/appDownloadButton/appDownloadButton.sc';
import { SelectPlatformWithPopover } from '@apw/components/profileDetail/profileHeader/ctaButton/appDownloadButton/selectPlatformWithPopover';
import { useQueryParams, useUserCompatibility } from '@apw/hooks';
import { useOnce } from '@apw/hooks/useOnce';
import { ProfilePageQueryParamsEnum } from '@apw/modules/profile/ProfilePageQueryParams.enum';
import { useStores } from '@apw/stores';
import { AppDownloadLink } from '@apw/types';
import { BigBlueButtonStateEnum } from '@apw/types/bigBlueButtonState.enum';
import { observer } from 'mobx-react';
import { v4 as uuid } from 'uuid';
import {
  getAvailableOS,
  getDownloadItemByCurrentOS,
  OSConfig,
  resolve,
  SupportedOS,
} from './appDownloadService';

export const AppDownloadButton = observer(() => {
  const profile = useContext(ProfileContext);
  const { onDownload } = useContext(ProfileOptionsContext);
  const [items, setItems] = useState<AppDownloadLink[]>([]);
  const queryParams = useQueryParams();
  const { isUserIncompatibleWithCurrentGallery } = useUserCompatibility();
  const { userStore, compatibilitiesStore } = useStores();

  useEffect(() => {
    const resolveItems = resolve(profile).map((item) => ({
      ...item,
      buttonState: BigBlueButtonStateEnum.PRIMARY,
    }));
    setItems(resolveItems);
  }, [profile]);

  const userBrandId = useMemo(() => {
    if (!userStore.user) {
      return;
    }
    return compatibilitiesStore.getCompatibilityByIdOrSubBrandId(
      userStore.user.brandId,
    )?.id;
  }, [userStore.awaiting, userStore.user?.brandId]);

  const downloadOnlyOnce = useOnce(onDownload);

  useEffect(() => {
    if (userStore.awaiting) {
      return;
    }
    const appDownload = queryParams[ProfilePageQueryParamsEnum.appDownload];
    const canUserDownload = !userBrandId || profile.brand === userBrandId;
    if (
      appDownload?.toLowerCase() === 'true' &&
      items.length > 0 &&
      canUserDownload
    ) {
      const target = queryParams[ProfilePageQueryParamsEnum.TARGET];
      const downloadItem = getDownloadItemByCurrentOS(items, target);
      if (downloadItem) {
        downloadOnlyOnce(downloadItem);
      }
    }
  }, [queryParams, items, userStore.awaiting, userBrandId, profile.brand]);

  const availableOS = useMemo<AppDownloadLink[]>(
    () => getAvailableOS(profile.downloadLinks || []),
    [profile.downloadLinks],
  );

  return (
    <React.Fragment>
      {items.length === 0 && (
        <NoDownloadLinksAvailable
          data-test-automation-id={`no-download-links-available`}
        >
          {resolveCurrentlyOnlyAvailableFor(availableOS)}
        </NoDownloadLinksAvailable>
      )}
      {items.length === 1 && (
        <DownloadButton
          onClick={() => {
            onDownload!(items[0]);
          }}
          disabled={isUserIncompatibleWithCurrentGallery}
        >
          <PlatformOptionInPrimaryButton
            item={items[0]}
            useIconForActive
            useIconForDisabled={isUserIncompatibleWithCurrentGallery}
          />
        </DownloadButton>
      )}
      {items.length > 1 && <SelectPlatformWithPopover items={items} />}
    </React.Fragment>
  );
});

const resolveCurrentlyOnlyAvailableFor = (
  availableOS: AppDownloadLink[],
): any => {
  if (availableOS.length === 1 && availableOS[0].os === SupportedOS.OtherOS) {
    return 'Currently only available for other OS';
  }

  return (
    <React.Fragment>
      Currently only available for:
      <AvailableOS>
        {availableOS.map((item) => (
          <OsIcon
            key={uuid()}
            symbol={OSConfig[item.os].icon}
            size="medium"
            className={`${item.os}`}
            data-test-automation-id={`${item.os}-os-icon`}
          />
        ))}
      </AvailableOS>
    </React.Fragment>
  );
};
