import React from 'react';
import oneTrustHelper from '@apw/core/onetrust';

const Iframe = (props) => {
  const { src, ...rest } = props;
  return oneTrustHelper.targetingCookiesEnabled ? (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe {...props} />
  ) : (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe {...rest} data-src={src} />
  );
};

export default Iframe;
