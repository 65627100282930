import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { BlockContent, BlockTypeEnum, PageContent } from '@apw/components';
import { domService } from '@apw/components/dom/dom.service';
import {
  CollectionBlockInterface,
  DisplayOnAppsEnum,
} from '@apw/components/pageContent/collectionBlock/typings';
import {
  FeatureOptionEnum,
  FeatureTypeEnum,
  IFeatureBlock,
  IFeatureItem,
} from '@apw/components/pageContent/galleryFeatureBlock';
import { transport } from '@apw/core';
import {
  normalizeProfilesInPageContent,
  transformProfileForPageContent,
} from '@apw/modules/page/page.service';
import { useStores } from '@apw/stores';
import { PagingInterface } from '@apw/types';
import produce from 'immer';
import _ from 'lodash';
import { observer } from 'mobx-react';

interface PageContentAccessibleProps {
  contentBlocks: BlockContent;
  isPreviewMode?: boolean;
}

const PageContentAccessibleTemp: FC<PageContentAccessibleProps> = ({
  contentBlocks,
  isPreviewMode = false,
}) => {
  const [content, setContent] = useState<BlockContent>([]);
  const lastContent = useRef<BlockContent>();
  const { pagesStore, compatibilityStore } = useStores();

  const distanceFromBottom = 100;

  useEffect(() => {
    if (contentBlocks) {
      pagesStore.clearLoadList();
      lastContent.current = contentBlocks;
      setContent(lastContent.current!);
      dispatchBlock(lastContent.current);
    }
  }, [contentBlocks]);

  const actionGalleryFeature = async (block: IFeatureBlock, blockIndex) => {
    if (!block.items || block.items.length === 0) return;
    await Promise.all<any>(
      block.items.map(async (item, index) => {
        if (item.featureType === FeatureTypeEnum.IndividualApp) {
          let profile = item.profile;

          if (isPreviewMode) {
            const appId = item.appId;
            const brandId = compatibilityStore.compatibility.id;

            try {
              const res = await transport.getProfileByAppIdAndBrandId(
                appId!,
                brandId,
              );

              profile = transformProfileForPageContent(res);
              // eslint-disable-next-line no-empty
            } catch (e) {}
          }

          try {
            lastContent.current = produce(lastContent.current, (draft) => {
              // @ts-ignore
              const item: IFeatureItem = draft![blockIndex].items[index];
              if (item.titleOption === FeatureOptionEnum.Default) {
                item.title = profile?.application.displayName || profile?.name;
              }
              if (item.frontTextOption === FeatureOptionEnum.Default) {
                item.frontText = profile?.shortDescription;
              }
              item.profile = profile;
            });
            // eslint-disable-next-line no-empty
          } catch (e) {}
        }
        if (item.featureType === FeatureTypeEnum.Page) {
          if (isPreviewMode) {
            const pageId = item.pageId;
            const brandId = compatibilityStore.compatibility.id;
            try {
              const page = await transport.fetchGalleryPageContent(
                parseInt(pageId!, 10),
                brandId,
              );
              lastContent.current = produce(lastContent.current, (draft) => {
                // @ts-ignore
                const item: IFeatureItem = draft![blockIndex].items[index];
                item.page = page;
              });
              // eslint-disable-next-line no-empty
            } catch (e) {}
          }
        }
      }),
    );
    setContent(lastContent.current!);
  };

  const registerCollectionToLoadList = useCallback(
    (
      block: CollectionBlockInterface,
      index: number,
      paging: PagingInterface,
    ) => {
      if (
        block.appList &&
        block.appList.length &&
        block.displayCount === DisplayOnAppsEnum.All &&
        paging &&
        paging.totalPages > 1
      ) {
        pagesStore.addLoadListItem({
          isLoading: false,
          page: paging.page,
          perPage: paging.perPage,
          totalPage: paging.totalPages,
          index,
          collectionId: block.collectionId,
        });
      }
    },
    [pagesStore],
  );

  const fetchCollectionBlockProfiles = (collectionId: number, page = 1) => {
    const brand = compatibilityStore.compatibility;
    return transport.fetchProfilesByAppCollection(collectionId, brand.id, page);
  };

  const actionCollection = useCallback(
    (block: CollectionBlockInterface, index: number) => {
      if (!block.collectionId) {
        return;
      }

      if (block.profiles) {
        const newBlock = {
          ...block,
          appList: normalizeProfilesInPageContent(block.profiles.records),
          isLoading: false,
        };
        updateContentBlock(newBlock, index);
        registerCollectionToLoadList(newBlock, index, block.profiles.paging);
        return;
      }

      fetchCollectionBlockProfiles(block.collectionId, 1)
        .then((result) => {
          if (result && result.profiles) {
            const newBlock = {
              ...block,
              appList: result.profiles,
              isLoading: false,
            };
            updateContentBlock(newBlock, index);
            registerCollectionToLoadList(newBlock, index, result.paging);
          }
        })
        .catch(() => {
          const newBlock = {
            ...block,
            appList: [],
            isLoading: false,
          };
          updateContentBlock(newBlock, index);
          registerCollectionToLoadList(newBlock, index, {
            page: 1,
            perPage: 0,
            totalPages: 0,
            pageStart: 0,
            pageEnd: 0,
            totalElements: 0,
          });
        });
    },
    [registerCollectionToLoadList],
  );

  // TODO: if collection request fast than feature, it would appear the invalid app and page block display
  const dispatchBlock = useCallback(
    (blocks: BlockContent) => {
      const strategies = {
        [BlockTypeEnum.Feature]: actionGalleryFeature,
        [BlockTypeEnum.Collection]: actionCollection,
      };

      blocks.forEach((block, index) => {
        const action = strategies[block.blockType];
        if (action) action(block, index);
      });
    },
    [actionCollection],
  );

  const shouldInvokeFunc = useCallback(() => {
    return (
      window.innerHeight +
        domService.getPageScrollXY().y +
        distanceFromBottom >=
      domService.getDocumentHeight()
    );
  }, []);

  const updateContentBlock = (blockItem, index: number) => {
    const content = [...lastContent.current!];
    content[index] = blockItem;
    setContent(content);
    lastContent.current = content;
  };

  const onScroll = useCallback(() => {
    const loadList = pagesStore.allLoadList;
    if (loadList.filter((item) => item.isLoading).length > 0) {
      return;
    }
    if (shouldInvokeFunc()) {
      loadList.forEach((item) => {
        if (item.page >= item.totalPage) {
          return;
        }
        item.isLoading = true;
        item.page++;
        let blockItem = {
          ...(lastContent.current![item.index] as CollectionBlockInterface),
        };
        blockItem.isLoading = true;
        updateContentBlock(blockItem, item.index);

        fetchCollectionBlockProfiles(item.collectionId, item.page)
          .then((result) => {
            processAppProfiles(result?.profiles);
          })
          .catch(() => processAppProfiles([]));

        const processAppProfiles = (profiles) => {
          item.isLoading = false;
          blockItem = {
            ...(lastContent.current![item.index] as CollectionBlockInterface),
          };
          blockItem.isLoading = false;
          blockItem.appList = [...blockItem.appList, ...(profiles || [])];
          updateContentBlock(blockItem, item.index);
        };
      });
    }
  }, [pagesStore.allLoadList, shouldInvokeFunc]);

  useEffect(() => {
    const scrollHandle = _.throttle(() => onScroll(), 300);
    window.addEventListener('scroll', scrollHandle);
    return () => {
      window.removeEventListener('scroll', scrollHandle);
    };
  }, [onScroll]);

  useEffect(() => {
    return () => pagesStore.clearLoadList();
  }, []);

  return <PageContent content={content} />;
};

export const PageContentAccessible = observer(PageContentAccessibleTemp);
