import { onMobile } from '@apw/core/sc';
import {
  cardDescription,
  cardTitle,
} from '@apw/shared/coveoSearch/shared/contentView/contentView.sc';
import { RcIcon, styled } from '@ringcentral/juno';

export const CardContainer = styled.a`
  display: flex;
  padding: 16px;
  text-decoration: none;

  &:hover {
    background: #f5f6f7;
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  position: relative;
  overflow: hidden;
`;

export const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  margin-right: 12px;
  border-radius: 12px;
  flex-shrink: 0;
`;

export const Title = styled.span`
  ${cardTitle};
  width: calc(100% - 120px);

  ${onMobile} {
    width: 100%;
  }
`;

export const Description = styled.div`
  ${cardDescription}
`;

export const PlatformIndicator = styled.span`
  color: rgb(0, 47, 167);
  font-size: 16px;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: absolute;
  right: 0;
  top: 0;

  ${onMobile} {
    position: static;
  }
`;

export const PlatformName = styled.span`
  text-transform: capitalize;
`;

export const ExternalLinkIcon = styled(RcIcon)`
  margin-left: 10px;
  width: 16px;

  ${onMobile} {
    width: 16px;
    margin-left: 6px;
  }
`;
