import React, { FC } from 'react';
import {
  RcDialogTitle,
  RcDialogActions,
  RcDialogContent,
} from '@ringcentral/juno';
import { Close } from '@ringcentral/juno-icon';
import { CustomDialog, DialogCloseButton } from './Dailog.sc';
import { ICloseDialog } from './types';

interface DialogProps {
  title: string;
  content: any;
  actions?: any;
  onClose: ICloseDialog;
  hiddenCloseIcon?: boolean;
}

export const Dialog: FC<DialogProps> = ({
  title,
  content,
  actions,
  onClose,
  hiddenCloseIcon,
}) => {
  return (
    <CustomDialog open>
      <RcDialogTitle>
        {title}
        {!hiddenCloseIcon && (
          <DialogCloseButton
            onClick={() => onClose()}
            symbol={Close}
            data-test-automation-id="dialog-close-btn"
          />
        )}
      </RcDialogTitle>
      <RcDialogContent>{content}</RcDialogContent>
      {actions && <RcDialogActions>{actions}</RcDialogActions>}
    </CustomDialog>
  );
};
