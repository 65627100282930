import { get } from '@apw/core/api/api.service';
import {
  ProfileListRequestInterface,
  ProfileListResponseInterface,
} from '@apw/types';
import { includes, isEmpty } from 'lodash';

const separator = /[,;]/;

export const shouldCallMultipleApps = (options): boolean => {
  return !isEmpty(options.apps);
};

const buildRcVanityUrlArray = (...appsName: string[]): string[] => {
  function buildRcVanityUrl(appName: string, iosName: string): string {
    return `ringcentral${appName}-${iosName}`;
  }

  const iosArray: Array<string> = ['windows', 'mac', 'ios', 'android'];
  return appsName.reduce(
    (total: string[], appName: string) =>
      total.concat(iosArray.map((e) => buildRcVanityUrl(appName, e))),
    [],
  );
};

const rcPhonePreviousVanityUrls: string[] = buildRcVanityUrlArray(
  '',
  'phone',
).concat(['ringcentral-phone']);
const rcMeetingPreviousVanityUrls: string[] = buildRcVanityUrlArray(
  '-meetings',
).concat(['ringcentral-meetings']);
const rcGlipPreviousVanityUrls: string[] = ['ringcentral-glip'];

export const buildParamAppsArray = (paramApps: string[]): string[] => {
  function replaceUrl(target: string, urlArray: string[], replaceTo: string) {
    return includes(urlArray, target) ? replaceTo : target;
  }

  return (
    paramApps
      .join(',')
      .split(separator, 200)
      .map((vanityUrl) => vanityUrl.trim().toLowerCase())
      .map((vanityUrl) =>
        replaceUrl(vanityUrl, rcPhonePreviousVanityUrls, 'rc-phone'),
      )
      .map((vanityUrl) =>
        replaceUrl(vanityUrl, rcMeetingPreviousVanityUrls, 'rc-meetings'),
      )
      .map((vanityUrl) =>
        replaceUrl(vanityUrl, rcGlipPreviousVanityUrls, 'rc-app'),
      )
      .filter((vanityUrl) => vanityUrl.length > 0 && vanityUrl.length <= 100)
      /* remove duplicated elements */
      .filter((v, i, a) => a.indexOf(v) === i)
  );
};

export const loadMultipleApps = (
  request: ProfileListRequestInterface,
): Promise<ProfileListResponseInterface> => {
  const endpoint = `/api/app/multiple-apps/${request.apps}`;
  return get<ProfileListResponseInterface>(endpoint, {
    params: request,
  });
};
