import { overflowWrap, useProximaNovaSemibold } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const HeaderContainer = styled.div`
  margin-bottom: 20px;
`;

export const Heading = styled.div`
  ${overflowWrap('break-word')};
  ${useProximaNovaSemibold};
  font-size: 22px;
  font-weight: 600;
  color: #001138;
`;

export const SubHeading = styled.div`
  ${overflowWrap('break-word')};
  margin-top: 5px;
  font-size: 16px;
  color: #686869;
`;
