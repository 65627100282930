import { useEffect, useRef } from 'react';
import { debounce } from 'lodash';

export const useDebounce = (
  callback: Function,
  wait?: number,
  option?: { leading?: boolean; trailing?: boolean },
) => {
  const callbackRef = useRef<Function>();

  useEffect(() => {
    callbackRef.current = callback;
  });

  const handle = useRef(
    debounce<any>(
      (...args) => {
        return callbackRef.current!(...args);
      },
      wait,
      option,
    ),
  );

  return handle.current;
};
