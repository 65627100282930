import { RootStore } from '@apw/stores';
import { CompatibilitiesStore } from '@apw/stores/compatibility/compatibilities';
import { action, computed, observable } from 'mobx';
import type { ICompatibility } from './compatibility.interface';

export class CompatibilityStore {
  @observable
  compatibility: ICompatibility;

  @computed
  get brandId(): string {
    return this.compatibility.id;
  }

  @computed
  get theme() {
    return this.compatibility.theme;
  }

  private get compatibilitiesStore(): CompatibilitiesStore {
    return this.rootStore.compatibilitiesStore;
  }

  constructor(private rootStore: RootStore) {}

  resolve(pathname: string) {
    const compatibility = this.compatibilitiesStore.resolve(pathname);
    this.setCompatibility(compatibility);
  }

  setCompatibilityById(id: string) {
    const compatibility = this.compatibilitiesStore.getCompatibilityById(id);

    if (compatibility) {
      this.setCompatibility(compatibility);
    }
  }

  @action.bound
  setCompatibility(compatibility: ICompatibility) {
    this.compatibility = compatibility;
  }
}
