import { onMobile } from '@apw/core/sc';
import { styled, RcIcon } from '@ringcentral/juno';

const desktopLogoSize = 72;
const mobileLogoSize = 48;

export const BotLogoStyled = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${desktopLogoSize}px;
  height: ${desktopLogoSize}px;

  ${onMobile} {
    width: ${mobileLogoSize}px;
    height: ${mobileLogoSize}px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 22.22%;
  }
`;

export const DefaultImage = styled(RcIcon)`
  &,
  svg {
    width: 100%;
    height: 100%;
  }
`;
