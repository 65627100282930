export const urlParameterKeys = {
  COMPATIBILITY: 'compatibility',
  APP_CATEGORY: 'appCategory',
  PRODUCT: 'product',
  SEARCH_TEXT: 'q',
  APPS: 'apps',
  LOGIN_ERROR: 'loginError',
  TAB: 'tab',
  TIMESTAMP: 't',
  TOKEN: 'o',
  LANDING_URL: 'landing_url',
  BOT_NAME: 'bot_name',
  CUSTOMER_PARAMETER: 'c_para',
  PARTNER_BADGE: 'partnerBadge',
  TYPE: 'type',
  BRAND_ID: 'brand_id',
  ENABLE_HIDDEN_APP_GALLERIES: 'enableHiddenAppGalleries',
};
