import React, { FC, ReactChild, useContext, useState } from 'react';
import {
  palette2,
  RcIcon,
  RcVisuallyHidden,
  setOpacity,
  styled,
} from '@ringcentral/juno';
import { ensureAction } from '@apw/lib/services/a11y/a11y.service';
import premierPartnerBadgeSvg from '../../assets/images/premier-partner-badge.svg';
import { AppLibContext } from '../../context/AppLibContext';
import { MultipleLineEllipsis } from '../multipleLineEllipsis';

const TileContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  vertical-align: top;
  border-radius: 8px;
  overflow: hidden;

  > a {
    text-decoration: none;
  }
`;

const TileLink = styled.div`
  height: 100%;
  width: 100%;
  cursor: pointer;
  outline: none;
`;

const TileContent = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  height: 100%;
  padding: 16px 16px 20px 16px;
  border: 1px solid #e1e6ea;
  border-radius: 8px;
`;

const TileTop = styled.div`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  align-items: flex-end;
`;

const AppLogo = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 72px;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 22.3%;
  }
`;

const Status = styled.div`
  background-color: ${setOpacity(palette2('label', 'red01'), '08')};
  color: ${palette2('danger', 'f02')};
  border-radius: 10px;
  padding: 0 8px;
  font-size: 12px;
  margin-left: 10px;
`;

const TileBottom = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row-reverse;
`;

const AppInfo = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 0;
  height: 100%;
`;

const AppName = styled.div`
  flex: 0 0 auto;
  margin-bottom: 4px;
  overflow: hidden;
  overflow-wrap: break-word;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.25px;
  color: #001138;
  font-weight: 600;
`;

const AppPublisher = styled.div`
  flex: 0 0 auto;
  margin-bottom: 15px;
  font-size: 16px;
  overflow-wrap: break-word;
  letter-spacing: 0.25px;
  color: #001138;
`;

const PublisherName = styled.span`
  line-height: 24px;
  overflow: hidden;
  overflow-wrap: break-word;
  vertical-align: bottom;
`;

const PublisherIcon = styled(RcIcon)`
  margin-left: 5px;
  vertical-align: middle;

  svg {
    width: 12px;
    height: 10px;
    color: #066fac;
  }
`;

const AppDesc = styled.div`
  flex: 0 0 auto;
  line-height: 24px;
  overflow: hidden;
  overflow-wrap: break-word;
  font-size: 16px;
  color: #282829;
  letter-spacing: 0.25px;
`;

const InstallTrigger = styled.div`
  opacity: 0;
  position: absolute;
  right: 15px;
  top: 15px;

  ${TileContainer}:hover & {
    opacity: 1;
    background-color: transparent;
  }
`;

type AppTileProps = {
  app: {
    logo: string;
    name: string;
    publisher?: string;
    description: string;
    partnerBadge?: string;
    creator?: string;
    status?: string;
  };
  logoAlt: string;
  // deprecated
  installTrigger?: any;
  installTriggerRenderer?: (actions: {
    focus?: () => void;
    blur?: () => void;
  }) => ReactChild | null;
  onClick: () => void;
  premierPartnerText: string;
};

export const AppTile: FC<AppTileProps> = (props) => {
  const { isInDev } = useContext(AppLibContext);
  const { app, onClick, installTriggerRenderer, logoAlt, premierPartnerText } =
    props;
  const [hoverTile, setHoverTile] = useState(false);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const keydownTile = (e: KeyboardEvent) => {
    const actionFn = () => handleClick();
    ensureAction(e, actionFn, ['Enter']);
  };

  const showStatus =
    isInDev && (app.status === 'Blocked' || app.status === 'Suspended');
  return (
    <TileContainer
      className={'wrapper'}
      data-test-automation-id={`app-tile-${app.name}`}
    >
      <TileLink
        className={`tileContent ${hoverTile ? 'hoverTile' : ''}`}
        onClick={() => handleClick()}
        onKeyDown={(e: any) => keydownTile(e)}
        role="link"
        tabIndex={0}
      >
        <TileContent className={'content'}>
          <TileTop>
            {app.logo && (
              <AppLogo data-test-automation-id="app-tile-logo">
                <img src={app.logo} alt={logoAlt} />
              </AppLogo>
            )}
            {showStatus && (
              <Status
                className="status"
                data-test-automation-id="app-tile-status"
              >
                {app.status}
              </Status>
            )}
          </TileTop>
          <TileBottom>
            <AppInfo>
              <AppName
                title={app.name}
                className={'name'}
                data-test-automation-id="app-tile-name"
              >
                <MultipleLineEllipsis linesToDisplay={2} lineHeightPx={24}>
                  {app.name}
                </MultipleLineEllipsis>
              </AppName>
              <AppPublisher>
                <PublisherName
                  className={'publisher'}
                  data-test-automation-id="app-tile-publisher-name"
                >
                  {isInDev ? app.creator : app.publisher}
                </PublisherName>
                {app.partnerBadge === 'Premier' && (
                  <>
                    <PublisherIcon
                      symbol={premierPartnerBadgeSvg}
                      data-test-automation-id="app-tile-publisher-icon"
                    />
                    <RcVisuallyHidden>{premierPartnerText}</RcVisuallyHidden>
                  </>
                )}
              </AppPublisher>
              <AppDesc
                title={app.description}
                className={'description'}
                data-test-automation-id="app-tile-desc"
              >
                <MultipleLineEllipsis linesToDisplay={2} lineHeightPx={24}>
                  {app.description}
                </MultipleLineEllipsis>
              </AppDesc>
            </AppInfo>
          </TileBottom>
        </TileContent>
      </TileLink>
      {installTriggerRenderer?.({}) && (
        <InstallTrigger
          className="installTrigger"
          data-test-automation-id="app-tile-install-trigger"
        >
          {installTriggerRenderer({
            focus: () => setHoverTile(true),
            blur: () => setHoverTile(false),
          })}
        </InstallTrigger>
      )}
    </TileContainer>
  );
};
