import React from 'react';
import { useStores } from '@apw/stores';
import { GalleryPageSection } from '@apw/stores/pages/galleryPageSection.enum';
import { galleryPageSections } from '@apw/stores/pages/galleryPageSections';
import { observer } from 'mobx-react';
import { PageNavigation } from '../pageNavigation/PageNavigation';
import { Section } from './secondaryNavigation.sc';

export interface ISidebarSection {
  displayName: string;
  sectionId: GalleryPageSection;
  firstPageAsDefaultPage?: boolean;
}

const resolveSidebarSections = (): ISidebarSection[] => {
  return getSidebarSectionsForGalleryPage();
};

const getSidebarSectionsForGalleryPage = (): ISidebarSection[] => {
  return [
    {
      ...galleryPageSections[GalleryPageSection.DISCOVER_APPS],
      firstPageAsDefaultPage: true,
    },
    galleryPageSections[GalleryPageSection.PRODUCTS],
    galleryPageSections[GalleryPageSection.CATEGORIES],
  ];
};

export const SecondaryNavigation = observer(() => {
  const { pagesStore } = useStores();
  const pages = pagesStore.pages;
  const sidebarSections = resolveSidebarSections();

  return (
    <React.Fragment>
      {sidebarSections.map((section: ISidebarSection) => (
        <Section
          key={section.sectionId}
          data-test-automation-id="secondaryNavigation"
        >
          <PageNavigation
            sectionName={section.displayName}
            items={pages[section.sectionId]}
            firstPageAsDefaultPage={section.firstPageAsDefaultPage}
          />
        </Section>
      ))}
    </React.Fragment>
  );
});
