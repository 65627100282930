export const urlPatterns = [
  /^https?:\/\/vimeo\.com\/(.+)/i,
  /^https?:\/\/player\.vimeo\.com\/video\/(.+)/i,
];

export const isVimeoUrl = (url: string): boolean => {
  return urlPatterns.some((pattern) => {
    return pattern.test(url);
  });
};
