import React, { useEffect, useState } from 'react';
import { IOpenGraphTag, ITwitterCardTag, SEO, Sticky } from '@apw/components';
import Carousel from '@apw/components/carousel/Carousel';
import { BlockContent } from '@apw/components/pageContent';
import { googleAnalytics, mixpanel } from '@apw/core/tracker';
import { PageViewTypeEnum } from '@apw/core/tracker/types';
import {
  useBrandBannerTexts,
  useQueryParams,
  useRedirectionRules,
} from '@apw/hooks';
import { IPageContent } from '@apw/modules/page/typings/page-content.interface';
import { BackToTop, BrokenLinkIndicator } from '@apw/shared';
import { Menu } from '@apw/shared/menu';
import { Sidebar } from '@apw/shared/sidebar';
import { withLayout } from '@apw/shared/withLayout';
import { useStores } from '@apw/stores';
import { ICompatibility } from '@apw/stores/compatibility';
import { galleryPageSections } from '@apw/stores/pages/galleryPageSections';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import {
  HeroContainer,
  MainContainer,
  RightContent,
  SidebarContainer,
} from './Page.sc';
import { PageContentAccessible } from './pageContentAccessible';
import {
  redirectionRulesFactory,
  RedirectionRulesFactoryData,
} from './redirectionRules.factory';
import { usePageLoader } from './usePageLoader';
import { usePartnerGalleryGuard } from './usePartnerGalleryGuard';

const currPageSections = galleryPageSections;

const PagePure = (props) => {
  const { headerHeight } = props;
  const { vanityUrl } = useParams<{ vanityUrl: string }>();
  const history = useHistory();
  const { compatibilityStore, pagesStore, compatibilitiesStore } = useStores();
  const [pageSlug, setPageSlug] = useState('');
  const [redirected, setRedirected] = useState<boolean | undefined>();
  const queryParams = useQueryParams();
  const pageLoader = usePageLoader();
  const imagesAlt = useBrandBannerTexts();

  const [contentBlocks, setContentBlocks] = useState<
    BlockContent | null | undefined
  >();

  const [pageBasicInfo, setPageBasicInfo] = useState<{
    seoTitle?: string | null;
    seoDescription?: string | null;
    name?: string | null;
    imageUrl?: string | '';
  }>({});

  const [pageBrand, setPageBrand] = useState<ICompatibility | undefined>();

  const redirectionProcess = useRedirectionRules<RedirectionRulesFactoryData>(
    redirectionRulesFactory,
  );

  useEffect(() => {
    let isMounted = true;

    if (Object.keys(queryParams).length > 0) {
      redirectionProcess
        .kickStart({
          queryParams,
          currentCompatibility: compatibilityStore.compatibility,
        })
        .then((isRedirected) => {
          if (isMounted) {
            setRedirected(isRedirected);
          }
        });
    } else {
      setRedirected(false);
    }

    return () => {
      isMounted = false;
    };
  }, [queryParams, compatibilityStore.compatibility]);

  usePartnerGalleryGuard({
    enabled: compatibilitiesStore.useFallback,
  });

  useEffect(() => {
    if (!vanityUrl && redirected === false) {
      const compatibility = compatibilitiesStore.getCompatibilityById(
        compatibilityStore.brandId,
      );
      googleAnalytics.trackPageView(`${compatibility?.name} Home page`);
      mixpanel.trackPageView(`${compatibility?.name} Home page`);
    }
  }, [redirected]);

  useEffect(() => {
    if (vanityUrl) {
      setPageSlug(vanityUrl);
    }
  }, [vanityUrl]);

  useEffect(() => {
    if (vanityUrl) {
      return;
    }

    if (!pagesStore.isLoading && pagesStore.firstPage) {
      setPageSlug(pagesStore.firstPage.vanityUrl);
      return;
    }

    return reaction(
      () => ({
        isLoading: pagesStore.isLoading,
        firstPage: pagesStore.firstPage,
      }),
      ({ isLoading, firstPage }) => {
        if (isLoading || !firstPage) {
          return;
        }

        setPageSlug(firstPage.vanityUrl);
      },
    );
  }, [
    vanityUrl,
    pagesStore.isLoading,
    pagesStore.firstPage,
    compatibilityStore.compatibility.slug,
    history,
  ]);

  useEffect(() => {
    let isMounted = true;

    if (pagesStore.isLoading) {
      return;
    }

    pageLoader(pageSlug).then((result) => {
      if (!isMounted) {
        return;
      }

      if (result) {
        const {
          pageContentBlocks,
          name,
          brandId,
          seoTitle,
          seoDescription,
          imageUrl,
        } = result;

        setContentBlocks(pageContentBlocks);

        setPageBasicInfo({
          seoTitle: seoTitle || name,
          seoDescription,
          name,
          imageUrl: imageUrl || compatibilityStore.compatibility.galleryIcon,
        });

        setPageBrand(compatibilitiesStore.getCompatibilityById(brandId));
        trackPage(result);
      }

      if (result === null || result === undefined) {
        setContentBlocks(result);
      }

      if (result === null) {
        googleAnalytics.trackPageView('Not found');
        mixpanel.trackPageView('Not found');
      }

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 300);
    });

    return () => {
      isMounted = false;
    };
  }, [pagesStore.isLoading, pageSlug]);

  const trackPage = (pageInfo: IPageContent) => {
    const { name: pageName, section } = pageInfo;
    const brandName = compatibilityStore.compatibility.name;
    const moreInfo = {
      'Page Type': PageViewTypeEnum.PAGE,
      'Section Name': currPageSections[section]?.displayName || '',
      Brand: brandName,
    };
    googleAnalytics.trackPageView(`${brandName} ${pageName} page`);
    mixpanel.trackPageView(pageName, moreInfo);
  };

  const getSEOTitle = () => {
    return (
      pageBasicInfo.seoTitle &&
      `${pageBasicInfo.seoTitle} for ${pageBrand?.name}`
    );
  };

  const isFirstPage = () => {
    if (pagesStore.firstPage) {
      return pageSlug === pagesStore.firstPage.vanityUrl;
    }
    return false;
  };

  const getCanonicalLink = () => {
    const suffix = pageSlug ? `p/${pageSlug}` : '';
    const brandSlug = pageBrand?.slug || compatibilityStore.compatibility.slug;

    if (isFirstPage()) {
      return brandSlug ? `${brandSlug}/` : brandSlug;
    }

    if (brandSlug) {
      return `${brandSlug}/${suffix}`;
    }
    return suffix;
  };

  const trackBackToTop = () => {
    const brandName = compatibilityStore.compatibility.name;
    let trackPageName;

    if (isFirstPage()) {
      trackPageName = `${brandName} Home page`;
    } else {
      trackPageName = `${brandName} ${pageBasicInfo.name}`;
    }
    googleAnalytics.trackPageView(`${trackPageName}:BackToTop`);
  };

  const ogTag = {
    image: pageBasicInfo.imageUrl,
  } as IOpenGraphTag;

  const twitterTag = {
    image: pageBasicInfo.imageUrl,
  } as ITwitterCardTag;

  return (
    <React.Fragment>
      {contentBlocks !== null && (
        <>
          <SEO
            title={getSEOTitle()}
            ogTag={ogTag}
            twitterTag={twitterTag}
            metaDescription={pageBasicInfo.seoDescription}
            canonical={getCanonicalLink()}
          />
          {compatibilityStore.compatibility.headerBanners.length > 0 && (
            <HeroContainer>
              <Carousel
                data-test-automation-id={'banner-container'}
                images={compatibilityStore.compatibility.headerBanners}
                imagesAlt={imagesAlt}
              />
            </HeroContainer>
          )}
          <Sticky position="top" offset={headerHeight}>
            <Menu />
          </Sticky>
          <MainContainer>
            <SidebarContainer>
              <Sidebar />
            </SidebarContainer>
            <RightContent data-test-automation-id="pagePage">
              {Array.isArray(contentBlocks) && (
                <PageContentAccessible contentBlocks={contentBlocks} />
              )}
            </RightContent>
          </MainContainer>
          <BackToTop
            data-test-automation-id="page-back-to-top"
            onClick={trackBackToTop}
          />
        </>
      )}
      {contentBlocks === null && (
        <BrokenLinkIndicator data-test-automation-id="page-not-exist-msg">
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
          <span tabIndex={0} role="alert">
            This page does not exist or has been removed.
          </span>
        </BrokenLinkIndicator>
      )}
    </React.Fragment>
  );
};

export const Page = withLayout(observer(PagePure), { stickyHeader: true });
