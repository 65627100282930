import { RcIcon, styled } from '@ringcentral/juno';
import {
  BPS,
  PAGE_DESKTOP_PADDING,
  PAGE_MAX_WIDTH,
  PAGE_MOBILE_PADDING,
  respondTo,
  onMobile,
  useProximaNovaRegular,
} from '@apw/core/sc';

export const FooterContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background: #001138;

  ${useProximaNovaRegular}
  ${respondTo(BPS.ltTablet)} {
    height: auto;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 0 auto;
  padding: 19px ${PAGE_DESKTOP_PADDING}px 8px;
  max-width: ${PAGE_MAX_WIDTH + PAGE_DESKTOP_PADDING * 2}px;
  height: 100%;

  ${respondTo(BPS.ltTablet)} {
    padding: 16px ${PAGE_DESKTOP_PADDING}px;
  }

  ${onMobile} {
    justify-content: center;
    padding: 25px ${PAGE_MOBILE_PADDING} 23px;
  }
`;

export const FooterContentLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: 8px;
  }
`;

export const AccessibilityIconLink = styled.a``;

export const AccessibilityIcon = styled(RcIcon)`
  &&& {
    svg {
      width: 75px;
      height: 28px;
    }
  }
`;

export const IconWrap = styled.div`
  display: flex;
  flex-wrap: nowrap;

  ${onMobile} {
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 8px;
  }
`;

export const IconItem = styled.a`
  display: inline-block;
  width: 28px;
  height: 28px;
  background-color: #fff;
  color: #fff;
  fill: #fff;
  border-radius: 50%;

  ${onMobile} {
    width: 27px;
    height: 27px;
  }

  &:not(:last-child) {
    margin-right: 14px;
  }

  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    svg {
      fill: currentColor;
      width: 100%;
      height: 100%;
    }
  }

  &.footerSocialLink-Twitter {
    svg {
      width: 50%;
      height: 50%;

      path {
        fill: #001138;
      }
    }
  }
`;

export const LinkWrap = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-wrap: wrap;
  font-size: 12px;
  line-height: 1.2;
  margin-right: 40px;
  margin-bottom: 4px;

  ${onMobile} {
    justify-content: flex-start;
    width: 100%;
    font-size: 14px;
  }
`;

export const LinkItem = styled.a`
  display: flex;
  color: #a8a8a8;
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
  margin: 7px 0;

  ${onMobile} {
    color: #999faf;
  }
  &:hover {
    color: #fff;
    text-decoration: underline;
  }
  &:not(:last-child) {
    margin-right: 8px;
    padding-right: 8px;
    border-right: 1px solid #a8a8a8;
    ${onMobile} {
      border-right: 1px solid #999faf;
    }
  }
`;

export const CopyrightWrap = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.6);
  margin: 7px 0;

  ${respondTo(BPS.ltTablet)} {
    justify-content: flex-end;
  }

  ${onMobile} {
    justify-content: flex-start;
    margin-top: 10px;
    width: 100%;
    font-size: 13px;
  }
`;
