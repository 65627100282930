import MarkdownIt, { Token } from 'markdown-it';

export function md2Html(mdString: string): string {
  const md = MarkdownIt('commonmark', {
    html: true,
    linkify: true,
    breaks: true,
  });

  // override the "a" open tag
  md.renderer.rules.link_open = (tokens: Token[], index: number) => {
    const attrs = {} as any;
    tokens[index].attrs.forEach((val) => (attrs[val[0]] = val[1]));

    Object.assign(attrs, getFormattedLinkAttrs(attrs.href));

    let html = '<a';

    for (const propName in attrs) {
      // eslint-disable-next-line no-prototype-builtins
      if (attrs.hasOwnProperty(propName)) {
        html += ` ${propName}="${attrs[propName]}"`;
      }
    }

    html += '>';

    return html;
  };

  md.renderer.rules.image = function () {
    return '';
  };

  // 3 steps:
  // (1) htmlEncode html
  // (2) render md to html
  // (3) handle br tag
  return decodeBr(md.render(htmlEncode(mdString)));

  function decodeBr(html) {
    return html.replace(/\[br]/g, '<br>');
  }

  function getFormattedLinkAttrs(url) {
    const attrs = { class: 'blueText' } as any;
    attrs.target = /^mailto:/.test(url) ? '_self' : '_blank';
    return attrs;
  }
}

export function clearHistoricalPrefix(mdString: string): string {
  return mdString.replace(/^(\[(md|pt)])/g, '');
}

function htmlEncode(s: string): string {
  const div = document.createElement('div');
  div.appendChild(document.createTextNode(s));

  // because the markdown syntax "> " will be encoded, so need to decode it
  return div.innerHTML.replace(/&gt; /g, '> ');
}

export const markdownService = {
  md2Html,
  clearHistoricalPrefix,
};
