import { overridesFactory } from '@apw/shared/theme/utils/overrides.factory';
import { paletteFactory } from '@apw/shared/theme/utils/palette.factory';
import { RcThemeInput } from '@ringcentral/juno';

export const btColor = '#5514B4';

export const btTheme: RcThemeInput = {
  palette: paletteFactory(btColor),
  overrides: overridesFactory(btColor),
};
