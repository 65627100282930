import { action, observable } from 'mobx';

export class MixpanelStore {
  @observable
  public isEnabled = false;

  @observable
  public token = RCAPW.mixpanelToken;

  @action.bound
  setIsEnabled(enabled = true): void {
    this.isEnabled = enabled;
  }

  @action.bound
  setToken(value: string): void {
    this.token = value;
  }
}
