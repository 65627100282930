import { NoRatingsSection } from '@apw/components/ratingAndReview/noRatingsSection/NoRatingsSection';
import { onMobile } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';
import { RcDivider } from '@ringcentral/juno/components/Divider';

export const NoRatingsWrapper = styled.div``;

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 120px;

  ${onMobile} {
    margin-bottom: 80px;
  }
`;

export const StyledNoRatingsSection = styled(NoRatingsSection)`
  margin-top: 80px;

  ${onMobile} {
    margin-top: 40px;
  }
`;

export const Divider = styled(RcDivider)`
  margin-bottom: 44px;
`;
