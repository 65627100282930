import React, { useMemo } from 'react';
import { RcTypography } from '@ringcentral/juno';
import { IBlockHeader } from '@apw/lib/types';
import styles from './blockHeader.module.scss';

function BlockHeader({ data, isH1 }: { data: IBlockHeader; isH1: boolean }) {
  const headingA11yProps = useMemo(() => {
    return isH1
      ? {
          role: 'heading',
          'aria-level': 1,
        }
      : {};
  }, [isH1]);

  return (
    <div
      className={styles.headerContainer}
      data-test-automation-id="block-header"
    >
      {data.heading && (
        <RcTypography
          component="div"
          {...headingA11yProps}
          {...data.styleTokens.heading}
          className={styles.heading}
          data-test-automation-id="block-header-heading"
        >
          {data.heading}
        </RcTypography>
      )}
      {data.subHeading && (
        <RcTypography
          component="div"
          {...data.styleTokens.subHeading}
          className={styles.subHeading}
          data-test-automation-id="block-header-subHeading"
        >
          {data.subHeading}
        </RcTypography>
      )}
    </div>
  );
}

export { BlockHeader };
