import { ErrorResponse } from '@apw/core/api/error-response/error-response.class';
import { AxiosResponse } from 'axios';

interface ErrorResponseBody {
  error: string;
  message: string;
}

export class ErrorResponseFromAps extends ErrorResponse {
  constructor(err: AxiosResponse) {
    super(err);
  }

  extractErrorCode(): string {
    const responseBody: ErrorResponseBody = this.getResponseBody();
    return responseBody.error;
  }
}
