import { RefObject, useEffect, useState } from 'react';
import { useResizeObserver } from '@apw/lib/hooks/useResizeObserver';

interface IBreakpoint<T> {
  min?: number;
  max?: number;
  name: T;
}

export const useResponsive = <T>(
  ref: RefObject<HTMLElement>,
  breakpoints: Array<IBreakpoint<T>>,
) => {
  const [currentName, setCurrentName] = useState<T>();
  const width = useResizeObserver(ref);
  useEffect(() => {
    for (const breakpoint of breakpoints) {
      const { min = 0, max = Infinity, name } = breakpoint;
      if (width >= min && width < max) {
        setCurrentName(name);
        break;
      }
    }
  }, [width]);
  return currentName as T;
};
