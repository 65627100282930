import { useProximaNovaSemibold, onMobile, FontWeight } from '@apw/core/sc';
import { styled, RcIcon, RcButton } from '@ringcentral/juno';

export const Container = styled.div`
  text-align: center;
  margin-top: 160px;
  margin-bottom: 160px;

  ${onMobile} {
    margin: 88px auto;
  }
`;

export const Image = styled(RcIcon)`
  svg {
    width: 64px;
    height: 73px;
  }
`;

export const Description = styled.p`
  font-size: 18px;
  color: #282829;
  font-weight: ${FontWeight.bold};
  margin: 16px auto 32px;
  ${useProximaNovaSemibold};
  ${onMobile} {
    margin: 14px auto 40px;
  }

  br {
    display: none;

    ${onMobile} {
      display: block;
    }
  }
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const Button = styled(RcButton)`
  border-radius: 3px !important;
  width: 160px !important;
  height: 40px !important;
  font-size: 16px !important;
  ${useProximaNovaSemibold};
`;
