import {
  normalizeSitemapProperty,
  normalizeUri,
} from '@apw/shared/coveoSearch/coveoAdapter.service';
import {
  CoveoResultProperty,
  ICoveoResultForProfile,
  SitemapProperty,
} from '@apw/shared/searchProvider';
import { IAppRating } from '@apw/types';
import { IProfileInSearch } from '../types';

class CoveoProfileService {
  transform(result: ICoveoResultForProfile): IProfileInSearch {
    const uri = result[CoveoResultProperty.Uri];
    const data = result[CoveoResultProperty.Raw];

    const overallRating = data[SitemapProperty.OVERALL_RATING];
    const voteCount = data[SitemapProperty.VOTE_COUNT];
    const appRating =
      overallRating && voteCount
        ? ({
            overallRating,
            voteCount,
          } as IAppRating)
        : undefined;

    return {
      logos: [data[SitemapProperty.LOGO_URL]],
      shortDes: normalizeSitemapProperty(data[SitemapProperty.SHORT_DESC]),
      appName: '', // will not use
      appType: data[SitemapProperty.PROFILE_TYPE] || '',
      path: normalizeUri(uri),
      iconUrl: data[SitemapProperty.APP_ICON_URL],
      applicationDisplayName: normalizeSitemapProperty(
        data[SitemapProperty.APP_DISPLAY_NAME],
      ),
      publisher: normalizeSitemapProperty(data[SitemapProperty.PUBLISHER]),
      partnerBadge: data[SitemapProperty.PARTNER_BADGE],
      certifiedBadge: data[SitemapProperty.CERTIFIED_BADGE] === 'true',
      appRatingEnabled: true,
      appRating,
      _id: data[SitemapProperty.PROFILE_ID],
    };
  }
}

export const coveoProfileService = new CoveoProfileService();
