import { RcTypography, styled } from '@ringcentral/juno';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled(RcTypography)`
  margin-right: 2px;
`;
