// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.74.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.74.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pageContent_wrapper__FJhEZ{width:100%;box-sizing:border-box}@media(max-width: 767px){.pageContent_wrapper__FJhEZ{padding:0}}", "",{"version":3,"sources":["webpack://./src/components/pageContent/pageContent.module.scss"],"names":[],"mappings":"AAAA,4BAAS,UAAU,CAAC,qBAAqB,CAAC,yBAAyB,4BAAS,SAAS,CAAC","sourcesContent":[".wrapper{width:100%;box-sizing:border-box}@media(max-width: 767px){.wrapper{padding:0}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "pageContent_wrapper__FJhEZ"
};
export default ___CSS_LOADER_EXPORT___;
