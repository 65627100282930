import {
  onMobile,
  useProximaNovaSemibold,
  overflowWrap,
  useProximaNovaRegular,
} from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const Wrapper = styled.div`
  width: 100%;
  color: #001138;
`;

export const SectionTitle = styled.div`
  margin: 0;
  font-size: 30px;
  font-weight: 300;
  ${useProximaNovaSemibold};
`;

export const SectionDesc = styled.div`
  margin: 16px 0 0;
  font-size: 16px;
  line-height: 16px;
  color: #494949;
  ${overflowWrap('break-word')};
  ${useProximaNovaRegular}

  ${onMobile} {
    line-height: 24px;
  }

  p {
    display: inline-block;
  }

  a {
    text-decoration: none;
  }

  span {
    font-size: 16px !important;
    font-weight: 700 !important;
    ${useProximaNovaRegular};
  }
`;
