import React, { FC, useCallback, useContext, useMemo } from 'react';
import { InfiniteScroll } from '@apw/components/infiniteScroll';
import { ProfileViewModelContext } from '@apw/components/profileDetail/Profile.ViewModel.context';
import {
  SectionBody,
  SectionHeading,
  StyledNoReviewsSection,
  TotalNumber,
  Wrapper,
} from '@apw/components/profileDetail/profileBody/ratingsAndReviewsTab/reviewsSection/ReviewsSection.sc';
import { ReviewItem } from '@apw/components/ratingAndReview/reviewItem/ReviewItem';
import { IReviewItem } from '@apw/components/ratingAndReview/typings/reviewItem.interface';
import { useInformDialog } from '@apw/hooks';
import { useIsMobileDevice } from '@apw/hooks/deviceWidth/useIsMobileDevice';
import { RcDivider } from '@ringcentral/juno/components/Divider';
import { observer } from 'mobx-react';

interface IReviewsSectionProps {
  onItemDeleted: () => void;
}

export const ReviewsSection: FC<IReviewsSectionProps> = observer(
  ({ onItemDeleted }) => {
    const {
      myPendingReviewItem,
      myPublishedReviewItem,
      isPublishedReviewItemsLoading,
      publishedReviewItems,
      totalNumberForReviews,
      pagingForReviews,
      deleteMyReviewItem,
      loadPublishedReviewItems,
      openRatingPopup,
    } = useContext(ProfileViewModelContext);

    const informDialog = useInformDialog();
    const isMobileDevice = useIsMobileDevice();

    const isInfiniteScrollEnabled = useMemo(() => {
      if (!pagingForReviews) {
        return false;
      }

      return pagingForReviews.page < pagingForReviews.totalPages;
    }, [pagingForReviews]);

    const onScroll = useCallback(() => {
      if (!pagingForReviews || isPublishedReviewItemsLoading) {
        return;
      }

      if (pagingForReviews.page < pagingForReviews.totalPages) {
        loadPublishedReviewItems(pagingForReviews.page + 1);
      }
    }, [
      pagingForReviews,
      loadPublishedReviewItems,
      isPublishedReviewItemsLoading,
    ]);

    const onEdit = useCallback(() => {
      openRatingPopup();
    }, []);

    const onDelete = useCallback(async (reviewItem: IReviewItem) => {
      await informDialog
        .show({
          title: 'Delete rating and review? ',
          content: '',
          actions: [
            {
              label: 'Cancel',
              variant: 'text',
              dialogResult: false,
            },
            {
              label: 'Delete',
              color: 'negative',
              variant: 'contained',
              dialogResult: true,
            },
          ],
        })
        .afterClosed((result) => {
          if (result) {
            deleteMyReviewItem(reviewItem).then(() => {
              onItemDeleted();
            });
          }
        });
    }, []);

    const showMyPendingReview = myPendingReviewItem && !myPublishedReviewItem;

    const isLast = useCallback(
      (index) => {
        return index === publishedReviewItems.length - 1;
      },
      [publishedReviewItems.length],
    );

    return (
      <Wrapper data-test-automation-id="reviews-section">
        <SectionHeading
          variant="title2"
          color="neutral.f06"
          data-test-automation-id="reviews-section-heading"
        >
          Reviews (
          <TotalNumber data-test-automation-id="reviews-total-number">
            {totalNumberForReviews}
          </TotalNumber>
          )
        </SectionHeading>
        <SectionBody data-test-automation-id="reviews-section-body">
          {totalNumberForReviews > 0 && (
            <>
              {showMyPendingReview && (
                <ReviewItem
                  item={myPendingReviewItem}
                  isMyReview
                  onEdit={onEdit}
                  onDelete={onDelete}
                  data-test-automation-id="my-review-item"
                />
              )}
              {showMyPendingReview && publishedReviewItems.length > 0 && (
                <RcDivider />
              )}
              <InfiniteScroll
                enabled={isInfiniteScrollEnabled}
                onScroll={onScroll}
                distanceFromBottom={150}
              >
                {publishedReviewItems.map((item, index) => (
                  <>
                    <ReviewItem
                      key={item.id}
                      item={item}
                      isMyReview={item.myReview}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      data-test-automation-id="published-review-item"
                    />
                    {!isLast(index) && <RcDivider />}
                  </>
                ))}
              </InfiniteScroll>
            </>
          )}
          {totalNumberForReviews === 0 && (
            <StyledNoReviewsSection
              variant={isMobileDevice ? 'subheading1' : 'headline1'}
            />
          )}
        </SectionBody>
      </Wrapper>
    );
  },
);
