import React, { ReactElement, useEffect, useRef } from 'react';
import { PopoverExternalControl } from '@apw/core/popover/typings';
import { urlParameterKeys } from '@apw/core/url';
import { getSearchParams } from '@apw/core/url/url.service';
import {
  allCategories,
  supportedCategoryConfig,
} from '@apw/modules/search/filterBar';
import { CategoryContent } from '@apw/modules/search/filterBar/share/CategoryContent';
import { useStores } from '@apw/stores';
import { RcIcon } from '@ringcentral/juno';
import { ArrowDown2, ArrowUp2 } from '@ringcentral/juno-icon';
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Container,
  PopoverContainer,
  CategoryTriggerStyled,
} from './CategoryFilter.sc';

const CategoryTrigger = (categoryId: string) => {
  const category = supportedCategoryConfig[categoryId] || allCategories;
  return (showPopover: (event) => void, open: boolean): ReactElement => {
    return (
      <CategoryTriggerStyled
        size="large"
        onClick={showPopover}
        className={`${open && 'on'}`}
        tabIndex={0}
        role="button"
        aria-expanded={open}
        data-test-automation-id="category-filter"
      >
        {category.displayName}
        <RcIcon
          className={'triggerIcon'}
          symbol={open ? ArrowUp2 : ArrowDown2}
          size="large"
        />
      </CategoryTriggerStyled>
    );
  };
};

const CategoryFilterPure = observer(() => {
  const { searchStore } = useStores();
  const hidePopover = () => {
    popoverControlRef.current.hidePopover();
  };

  const popoverControlRef = useRef<PopoverExternalControl>({
    hidePopover: () => {},
  });

  return (
    <PopoverContainer
      data-test-automation-id="category-filter-popover"
      getTrigger={CategoryTrigger(searchStore.selectedCategory)}
      initExternalControl={(control) => (popoverControlRef.current = control)}
    >
      <CategoryContent
        data-test-automation-id="category-filter-content"
        onChange={hidePopover}
      />
    </PopoverContainer>
  );
});

export const CategoryFilter = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const { compatibilityStore, searchStore } = useStores();

  useEffect(() => {
    searchStore
      .loadCategories(
        compatibilityStore.compatibility.id,
        searchStore.selectedProductId,
      )
      .then(() => {
        const hasCategoryInURL =
          getSearchParams()[urlParameterKeys.APP_CATEGORY];
        const invalidCategory = !_.find(searchStore.categories, {
          id: searchStore.selectedCategory,
        });

        if (hasCategoryInURL && invalidCategory) {
          searchStore.setSelectedCategory(allCategories.id);
          history.replace(
            `${location.pathname}${searchStore.getQueryString()}`,
          );
        }
      });
  }, [
    compatibilityStore.compatibility.id,
    searchStore,
    searchStore.selectedProductId,
  ]);

  return (
    <Container>
      <CategoryFilterPure />
    </Container>
  );
});
