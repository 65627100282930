import React from 'react';
import { usePreviousPath } from '@apw/hooks';
import { useCompatibilityHomePath } from '@apw/routes/useCompatibilityPaths';
import { Previous } from '@ringcentral/juno-icon';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { BackIcon, Button } from './GoBackButtom.sc';

export const GoBackButton = observer(() => {
  const homePath = useCompatibilityHomePath();
  const history = useHistory();
  const previousPath = usePreviousPath();

  const handleClick = () => {
    if (Boolean(previousPath) && history.length > 1) {
      history.goBack();
      return;
    }

    history.push(homePath);
  };

  return (
    <Button
      variant="text"
      color="text.primary"
      onClick={handleClick}
      data-test-automation-id="profile-go-back-button"
    >
      <BackIcon symbol={Previous} size="medium" />
      <span>Back</span>
    </Button>
  );
});
