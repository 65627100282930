export enum Language {
  /** Common * */
  TITLE_SORRY,
  TITLE_CONGRATULATIONS,
  OK,
  SESSION_TIMEOUT,

  /** Login * */
  LOGIN_COMMON_ERROR,

  /** Bot Provision * */
  BOT_PROVISION_NO_PERMISSION,
  BOT_NAME_RULES,
  BOT_NAME_FORMAT_ERROR,
  BOT_NAME_OCCUPIED,
  DISABLE_BOT_NAME_OCCUPIED,
  ADD_A_BOT_WITH_BOT_NAME,
  REMOVE_BOT_CONFIRMATION_TITLE,
  REMOVE_BOT_CONFIRMATION,
  BOT_ALREADY_INSTALLED_ERROR,
  BOT_ALREADY_REMOVED_ERROR,
  BOT_PROVISION_CALL_REDIRECT_URI_ERROR,
  BOT_BRAND_NOT_SUPPORTED,
  HIPAA_ACCOUNT,
  FREE_TIER_CANNOT_INSTALL_BOT,
  FREE_TIER_UPGRADE_BUTTON_LABEL,
  RC_PLANS_AND_PRICING_PAGE_URL,
  BOT_SUBSCRIBE_WEB_HOOK_FAIL,

  /** Lead Form * */
  LEAD_FORM_SUCCESSFUL_SUBMISSION,
  LEAD_FORM_LOGIN_OPTION_TITLE,
  LEAD_FORM_DESCRIPTION,
  PARTNER_LEAD_FORM_DESCRIPTION,

  /** Guest Form * */
  GUEST_FORM_DESCRIPTION,
  GUEST_FORM_LOGIN_OPTION_TITLE,

  /** Form * */
  FORM_ERROR_REQUIRED,
  FORM_ERROR_EMAIL,
  FORM_ERROR_PHONE_NUMBER,

  /** Google reCAPTCHA * */
  GOOGLE_RECAPTCHA_ERROR_REQUIRED,
  GOOGLE_RECAPTCHA_ERROR_VERIFICATION,

  /** Internal Error * */
  INTERNAL_ERROR_IPAAS_DATA_INVALID,

  /** Download Popup * */
  DOWNLOAD_POPUP_TITLE,
  DOWNLOAD_POPUP_MESSAGE,

  /** Leaving Popup * */
  LEAVING_POPUP_TITLE,
  LEAVING_POPUP_MESSAGE,
  LEAVING_POPUP_NOTE,
}
