import { overridesFactory } from '@apw/shared/theme/utils/overrides.factory';
import { paletteFactory } from '@apw/shared/theme/utils/palette.factory';
import { RcThemeInput } from '@ringcentral/juno';

export const avayaPrimaryColor = '#575759';
export const avayaSecondaryColor = '#DA291C';

export const avayaTheme: RcThemeInput = {
  palette: paletteFactory(avayaPrimaryColor, avayaSecondaryColor),
  overrides: overridesFactory(avayaPrimaryColor),
};
