import React, { useEffect, useMemo, useState } from 'react';
import { ProfileTile, Sticky } from '@apw/components';
import { transport } from '@apw/core';
import { useInformDialog, useQueryParams } from '@apw/hooks';
import {
  MainContainer,
  navigationBarMixin,
} from '@apw/modules/download/Download.sc';
import { normalizeQueryParams } from '@apw/modules/download/download.service';
import {
  getCompatibilityHomePath,
  getCompatibilityProfilePath,
} from '@apw/routes/getCompatibilityPaths';
import { NavigationBar, withLayout } from '@apw/shared';
import { useStores } from '@apw/stores';
import { Compatibility } from '@apw/stores/compatibility';
import { ProfileListItemInterface } from '@apw/types';
import { observer } from 'mobx-react';
import * as qs from 'qs';
import { useHistory } from 'react-router-dom';

const DownloadPure = (props) => {
  const { headerHeight } = props;
  const queryParams = useQueryParams();
  const { compatibilitiesStore } = useStores();
  const history = useHistory();
  const [profileList, setProfileList] = useState<ProfileListItemInterface[]>(
    [],
  );
  const informDialog = useInformDialog();

  const popupProfileNotFound = () => {
    informDialog.show({
      title: 'Sorry!',
      content: 'This app does not exist or has been removed.',
    });
  };

  const goToHomepage = (brandId: string) => {
    const brand =
      compatibilitiesStore.getCompatibilityByIdOrSubBrandId(brandId);
    const homepagePath = getCompatibilityHomePath(brand?.slug || '');
    history.push(homepagePath);
  };

  const handleProfileErr = (brandId: string = Compatibility.RINGCENTRAL) => {
    popupProfileNotFound();
    goToHomepage(brandId);
  };

  const getProfilePath = (profile, params) => {
    const urlParams = qs.stringify(params, {
      addQueryPrefix: true,
    });
    const brand = compatibilitiesStore.getCompatibilityById(profile.brand);
    const profilePath = getCompatibilityProfilePath(
      brand?.slug || '',
      profile.vanityUrl,
    );
    return `${profilePath}${urlParams}`;
  };

  const getProfiles = async () => {
    const {
      brandId,
      app,
      target,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      auto_download,
      osList,
    } = normalizeQueryParams(queryParams);
    try {
      const profiles = await transport.fetchProfilesToDownload(
        brandId,
        app,
        osList,
      );
      if (!profiles.length) {
        handleProfileErr(brandId);
        return;
      }
      const profilePathParams = {
        target,
        brandId,
        auto_download,
      };
      if (profiles.length === 1) {
        const profile = profiles[0];
        const profilePagePath = getProfilePath(profile, profilePathParams);
        history.replace(profilePagePath);
        return;
      }
      const list = profiles.map((item) => ({
        ...item,
        path: getProfilePath(item, profilePathParams),
      }));
      setProfileList(list);
    } catch (e) {
      handleProfileErr(brandId);
    }
  };

  const isBrandIdInvalid = useMemo(() => {
    return (
      !queryParams.brandId ||
      !compatibilitiesStore.getCompatibilityByIdOrSubBrandId(
        queryParams.brandId,
      )
    );
  }, [queryParams]);

  useEffect(() => {
    if (isBrandIdInvalid) {
      handleProfileErr();
      return;
    }
    getProfiles();
  }, [queryParams]);

  return (
    <>
      <Sticky position="top" offset={headerHeight}>
        <NavigationBar cssProp={navigationBarMixin} />
      </Sticky>
      <MainContainer data-test-automation-id="download-profile-list">
        {profileList.length > 0 &&
          profileList.map((item) => (
            <ProfileTile
              data-test-automation-id={`download-profile-item-${item._id}`}
              app={item}
              key={item._id}
            />
          ))}
      </MainContainer>
    </>
  );
};

export const Download = withLayout(observer(DownloadPure), {
  stickyHeader: true,
  disableGalleryWarning: true,
});
