import React, { FC } from 'react';
import {
  RatingContainer,
  RatingCount,
  StyledRating,
} from '@apw/lib/components/ratingAndReview/ratingInfo/ratingInfo.sc';

export interface IRatingProps {
  rating: number;
  ratingCount?: number;
  className?: string;
  translation: {
    countLabel: {
      plural: string;
      singular: string;
    };
  };
}

export const RatingInfo: FC<IRatingProps> = ({
  rating,
  ratingCount,
  className,
  translation,
}) => {
  return (
    <RatingContainer className={className}>
      <StyledRating
        // @ts-ignore
        precision={0.1}
        value={rating}
        readOnly
        data-test-automation-id={'rating-stars'}
      />
      {ratingCount && (
        <RatingCount
          data-test-automation-id={'rating-count'}
          color="neutral.f04"
          variant="caption1"
        >
          {ratingCount}{' '}
          {ratingCount > 1
            ? translation.countLabel.plural
            : translation.countLabel.singular}
        </RatingCount>
      )}
    </RatingContainer>
  );
};
