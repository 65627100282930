import React, { FC } from 'react';
import { DefaultProfileLogo } from '@apw/assets/images';
import { IProfile } from '@apw/types';
import {
  ProfileLogo as Logo,
  DefaultProfileLogo as Default,
} from './profileLogo.sc';

interface IProfileLogoProps {
  profile: Partial<IProfile>;
  borderRadius?: number;
  tabIndex?: number;
}

export const ProfileLogo: FC<IProfileLogoProps> = ({
  profile,
  borderRadius = 0,
  tabIndex = -1,
}) => {
  const logoUri = profile.iconUrl;
  const alt = `${profile.applicationDisplayName || ''} app logo`;

  return logoUri ? (
    <Logo
      src={logoUri}
      alt={alt}
      style={{ borderRadius }}
      data-test-automation-id="profileLogo"
      tabIndex={tabIndex}
    />
  ) : (
    <Default
      symbol={DefaultProfileLogo}
      data-test-automation-id="profileLogoDefault"
      role="img"
      aria-label={alt}
      tabIndex={tabIndex}
    />
  );
};
