import React, { FC, useMemo } from 'react';
import {
  CreatedTime,
  LeftContainer,
  ReviewActions,
  ReviewContent,
  ReviewerName,
  ReviewerRating,
  RightContainer,
  StatusBadgeContainer,
  Wrapper,
} from '@apw/components/ratingAndReview/reviewItem/ReviewItem.sc';
import {
  IReviewItem,
  ReviewStatus,
} from '@apw/components/ratingAndReview/typings/reviewItem.interface';
import { useUserCompatibility } from '@apw/hooks';
import { useRelativeTime } from '@apw/hooks/useRelativeTime';
import { useStores } from '@apw/stores';
import { RcBadge, RcButton } from '@ringcentral/juno';
import { observer } from 'mobx-react';
import moment from 'moment';

interface IReviewItemProps {
  item: IReviewItem;
  isMyReview?: boolean;
  className?: string;
  onEdit?: (reviewId: string) => void;
  onDelete?: (reviewItem: IReviewItem) => void;
  'data-test-automation-id'?: string;
}

export const ReviewItem: FC<IReviewItemProps> = observer(
  ({
    item,
    isMyReview = false,
    className = '',
    onEdit,
    onDelete,
    'data-test-automation-id': dataTestAutomationId = 'review-item',
  }) => {
    const { id, status, userName, creationTime, overallRating, content } = item;

    const { userStore } = useStores();
    const { isUserIncompatibleWithCurrentGallery } = useUserCompatibility();

    const creationTimeWithTimeErrorTolerated = useMemo(() => {
      return moment(creationTime).subtract(3, 'seconds').toISOString();
    }, [creationTime]);

    const relativeCreatedTime = useRelativeTime(
      creationTimeWithTimeErrorTolerated,
    );

    const statusBadgeContent = useMemo(() => {
      const mapping = {
        [ReviewStatus.Pending]: 'Pending',
      };

      return mapping[status];
    }, [status]);

    const reviewerName = useMemo(() => {
      const name = isMyReview ? userStore.extension?.name : userName;
      return name || 'Inactive user';
    }, [isMyReview, userName, userStore.extension?.name]);

    return (
      <Wrapper
        data-test-automation-id={dataTestAutomationId}
        className={className}
      >
        <LeftContainer>
          <ReviewerName
            variant="subheading2"
            color="neutral.f06"
            data-test-automation-id="reviewer-name"
          >
            {reviewerName}
          </ReviewerName>
          <CreatedTime
            color="neutral.f04"
            data-test-automation-id="created-time"
          >
            {relativeCreatedTime}
          </CreatedTime>
        </LeftContainer>
        <RightContainer>
          <ReviewerRating
            value={overallRating}
            readOnly
            data-test-automation-id="reviewer-rating"
          />
          {isMyReview && statusBadgeContent && (
            <StatusBadgeContainer>
              <RcBadge
                color="action.grayLight"
                textColor="neutral.f01"
                data-test-automation-id="status-badge"
                badgeContent={statusBadgeContent}
                overlap="none"
                variant="standard"
              />
            </StatusBadgeContainer>
          )}
          <ReviewContent
            variant="subheading1"
            color="neutral.f06"
            data-test-automation-id="review-content"
          >
            {content}
          </ReviewContent>
          {isMyReview && (
            <ReviewActions>
              <RcButton
                variant="plain"
                color="action.primary"
                onClick={() => onEdit?.(id)}
                data-test-automation-id="review-edit-button"
                disabled={isUserIncompatibleWithCurrentGallery}
              >
                Edit
              </RcButton>
              <RcButton
                variant="plain"
                color="action.primary"
                onClick={() => onDelete?.(item)}
                data-test-automation-id="review-delete-button"
                disabled={isUserIncompatibleWithCurrentGallery}
              >
                Delete
              </RcButton>
            </ReviewActions>
          )}
        </RightContainer>
      </Wrapper>
    );
  },
);
