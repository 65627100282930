import { TrackedEvent } from '../tracked-event.enum';
import {
  BigBlueButtonClickedProperties,
  ContactUsPromptProperties,
  ContactUsSubmittedProperties,
} from '../types';
import { CustomDimension } from './custom-dimension.enum';
import {
  buildCustomDimensions,
  prepareGoogleAnalytics,
  trackButtonClick,
  logPageView,
} from './google-analytics-core.service';
import { GoogleAnalyticsConfig } from './types';

export const init = (options: GoogleAnalyticsConfig) => {
  prepareGoogleAnalytics(options);
};

export const trackPageView = (
  pageName: string,
  userId?: string,
  path?: string,
) => {
  logPageView(pageName, userId, path);
};

export { trackButtonClick };

export const trackContactUsPrompt = (
  properties: ContactUsPromptProperties,
): void => {
  const customDimensions = buildCustomDimensions({
    [CustomDimension.APP_ID]: properties.appId,
    [CustomDimension.PROFILE_ID]: properties.profileId,
    [CustomDimension.PROFILE_NAME]: properties.profileName,
    [CustomDimension.BRAND_PARTNER]: properties.brandPartner,
  });

  trackButtonClick(TrackedEvent.AG_CONTACT_US_PROMPT, customDimensions);
};

export const trackContactUsSubmitted = (
  properties: ContactUsSubmittedProperties,
): void => {
  const customDimensions = buildCustomDimensions({
    [CustomDimension.APP_ID]: properties.appId,
    [CustomDimension.PROFILE_ID]: properties.profileId,
    [CustomDimension.PROFILE_NAME]: properties.profileName,
    [CustomDimension.BRAND_PARTNER]: properties.brandPartner,
    [CustomDimension.CLICK_PATH]: properties.clickPath,
  });

  trackButtonClick(TrackedEvent.AG_CONTACT_US_SUBMITTED, customDimensions);
};

export const trackBigBlueButtonClicked = (
  properties: BigBlueButtonClickedProperties,
): void => {
  const params = {
    [CustomDimension.BUTTON_TEXT]: properties.buttonText,
    [CustomDimension.BUTTON_STATE]: properties.buttonStatus || '',
    [CustomDimension.LOGGED_IN_STATE]: `${properties.loggedInState}`,
    [CustomDimension.APP_NAME]: properties.appName,
    [CustomDimension.PROFILE_NAME]: properties.profileName,
    [CustomDimension.APP_ID]: properties.appId,
    [CustomDimension.BRAND_PARTNER]: properties.brandPartner,
    [CustomDimension.ORG_TYPE]: properties.orgType,
    [CustomDimension.ORG_ID]: properties.orgId,
  };

  const customDimensions = buildCustomDimensions(params);

  trackButtonClick(TrackedEvent.AG_BIG_BLUE_BUTTON_CLICKED, customDimensions);
};

export const trackGetSupportClicked = (
  properties: ContactUsPromptProperties,
): void => {
  const customDimensions = buildCustomDimensions({
    [CustomDimension.APP_ID]: properties.appId,
    [CustomDimension.PROFILE_ID]: properties.profileId,
    [CustomDimension.PROFILE_NAME]: properties.profileName,
    [CustomDimension.BRAND_PARTNER]: properties.brandPartner,
  });

  trackButtonClick(TrackedEvent.AG_Get_SUPPORT, customDimensions);
};
