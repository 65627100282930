import React, { FC, useContext, useEffect, useState } from 'react';
import { SEO, Sticky } from '@apw/components';
import {
  IProcessedProfile,
  ProfileBody,
  ProfileHeader,
} from '@apw/components/profileDetail';
import { ProfileViewModel } from '@apw/components/profileDetail/Profile.ViewModel';
import { useQueryParams } from '@apw/hooks';
import usePartnerHandlebars from '@apw/hooks/usePartnerHandlebars';
import { useVM } from '@apw/hooks/useVM';
import {
  buildHandlebarsProfile,
  directDownload,
  getAppPageTitle,
} from '@apw/modules/profile';
import { BackToEditButton } from '@apw/modules/profile/preview/backToEditButton';
import {
  MainContainer,
  navigationBarMixin,
  ProfileContainer,
} from '@apw/modules/profile/preview/ProfilePreviewPage.sc';
import { resolveProfileForPreview } from '@apw/modules/profile/preview/profilePreviewService';
import {
  ExpiredPreviewLink,
  NavigationBar,
  ThemeOverrideContext,
} from '@apw/shared';
import { withLayout } from '@apw/shared/withLayout';
import { rootStore, useStores } from '@apw/stores';
import { AppDownloadLink } from '@apw/types';
import { RcThemeInput } from '@ringcentral/juno';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

interface ProfilePreviewPageProps {
  headerHeight?: number;
}

type IProfilePreviewState = IProcessedProfile | null | undefined;

const ProfilePreviewPagePure: FC<ProfilePreviewPageProps> = ({
  headerHeight,
}) => {
  const viewModel = useVM(ProfileViewModel);
  const { profile, setProfile } = viewModel;

  const [brandId, setBrandId] = useState<string>();
  const { profileId } = useParams<{ profileId: string }>();
  const { userStore } = useStores();
  const {
    t: timestamp,
    o: token,
    type,
    brandId: previewBrandId,
  } = useQueryParams();
  const themeOverride = useContext(ThemeOverrideContext);
  const handlebars = usePartnerHandlebars(brandId);

  const onDownload = (item: AppDownloadLink) => {
    if (profile) {
      directDownload(profile, item.link, userStore.isLoggedIn);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (
      typeof timestamp !== 'string' ||
      typeof token !== 'string' ||
      typeof type !== 'string'
    ) {
      return;
    }

    resolveProfileForPreview(profileId, timestamp, token, type, previewBrandId)
      .then((profile) => {
        if (isMounted) {
          setBrandId(profile.brand);
          const handlebarsProfile = buildHandlebarsProfile(profile, handlebars);
          setProfile(handlebarsProfile);
        }
      })
      .catch(() => {
        if (isMounted) {
          setProfile(null);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [profileId, timestamp, token, type]);

  useEffect(() => {
    const currentTheme = resolveCurrentTheme(profile);

    if (currentTheme) {
      themeOverride.set(currentTheme);
    }

    return () => {
      themeOverride.reset();
    };
  }, [profile]);

  return (
    <MainContainer>
      {profile && (
        <React.Fragment>
          <SEO title={getAppPageTitle(profile)} />
          <Sticky position="top" offset={headerHeight}>
            <NavigationBar
              leftContent={<BackToEditButton />}
              cssProp={navigationBarMixin}
            />
          </Sticky>
          <ProfileContainer>
            <ProfileHeader
              data-test-automation-id="page-preview-header"
              viewModel={viewModel}
              onDownload={onDownload}
              isPreviewMode
            />
            <ProfileBody
              data-test-automation-id="page-preview-body"
              viewModel={viewModel}
              isPreviewMode
            />
          </ProfileContainer>
        </React.Fragment>
      )}
      {profile === null && <ExpiredPreviewLink />}
    </MainContainer>
  );
};

export const ProfilePreviewPage = withLayout(observer(ProfilePreviewPagePure), {
  stickyHeader: true,
});

const resolveCurrentTheme = (
  profile: IProfilePreviewState,
): RcThemeInput | undefined => {
  if (profile) {
    const brand = profile.brand;
    const compatibility =
      rootStore.compatibilitiesStore.getCompatibilityById(brand);
    return compatibility?.theme;
  }

  return undefined;
};
