import React, { useEffect } from 'react';
import { IRoute } from '@apw/routes/route.interface';
import { RouteDataContext } from '@apw/routes/RouteDataContext';
import { useStores } from '@apw/stores';
import { observer } from 'mobx-react';
import { Route } from 'react-router';
import { useHistory } from 'react-router-dom';

const RedirectToCLWLogin = ({ routeSetting }) => {
  const { userStore } = useStores();
  const history = useHistory();

  useEffect(() => {
    const redirect = routeSetting?.data?.redirect;
    if (userStore.shouldGoToRedirect && redirect) {
      history.push(redirect);
      return;
    }
    userStore.signInViaCLW();
  }, [userStore]);

  return null;
};

export const GuardedRoute = observer(
  ({ component: Component, ...rest }: IRoute) => {
    const { userStore } = useStores();
    const { awaiting, ssoLoading } = userStore;
    const isLoggedIn = userStore.isLoggedIn;

    return (
      <Route
        {...rest}
        render={() => {
          if (awaiting || ssoLoading) return null;
          return isLoggedIn ? (
            <RouteDataContext.Provider value={rest.data}>
              <Component />
            </RouteDataContext.Provider>
          ) : (
            <RedirectToCLWLogin routeSetting={rest} />
          );
        }}
      />
    );
  },
);

export const CommonRoute = ({ component: Component, ...rest }: IRoute) => {
  return (
    <Route
      {...rest}
      render={() => (
        <RouteDataContext.Provider value={rest.data}>
          <Component />
        </RouteDataContext.Provider>
      )}
    />
  );
};
