import { CertifiedBadge } from '@apw/components/certifiedBadge';
import {
  onMobile,
  useProximaNovaBold,
  useProximaNovaSemibold,
} from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

const desktopImgSize = 200;
const mobileImgSize = 128;

export const Container = styled.div`
  width: 100%;
  margin-bottom: 16px;

  ${onMobile} {
    min-height: auto !important;
  }
`;

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  ${onMobile} {
    flex-direction: column;
  }
`;

export const AppLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${desktopImgSize}px;
  height: ${desktopImgSize}px;

  ${onMobile} {
    min-width: ${mobileImgSize}px;
    height: ${mobileImgSize}px;
  }
`;

export const AppInfoWrapper = styled.div`
  margin-left: 62px;

  ${onMobile} {
    margin-left: 13px;
  }
`;

export const CtaButtonWrapper = styled.div`
  width: ${desktopImgSize}px;

  ${onMobile} {
    width: 100%;
    margin-top: 13px;
  }
`;

export const AppNameWrapper = styled.div`
  ${onMobile} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 0;
    min-height: ${mobileImgSize}px;
  }
`;

export const AppName = styled.h5`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 12px;
  line-height: 48px;
  font-size: 40px;
  color: #001138;
  ${useProximaNovaSemibold};

  ${onMobile} {
    ${useProximaNovaBold};
    padding-top: 0;
    line-height: 32px;
    font-size: 20px;
    font-weight: 700;
  }
`;

export const ProfileNameContainer = styled.div`
  overflow-wrap: break-word;
  word-break: break-word;
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }
`;

export const CertifiedBadgeContainer = styled(CertifiedBadge)`
  flex-shrink: 0;
  margin-top: 4px;
`;

export const PublisherWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 4px;

  ${onMobile} {
    display: block;
    margin-top: 5px;

    & > span {
      display: block;
    }
  }
`;

export const PublisherName = styled.span`
  font-size: 20px;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;

  ${onMobile} {
    font-size: 16px;
  }
`;

export const ShortDesWrapper = styled.p`
  margin-top: 22px;
  margin-bottom: 0;
  line-height: 28px;
  font-size: 20px;
  overflow-wrap: break-word;

  ${onMobile} {
    margin-left: 0;
    margin-top: 22px;
    line-height: 24px;
    font-size: 16px;
  }
`;

export const RatingWrapper = styled.div`
  margin-top: 22px;

  ${onMobile} {
    margin-top: 12px;
    margin-left: 0;
  }
`;

export const DirectSupportWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 62px;

  & > * {
    &:not(:first-child) {
      margin-left: 12px;
    }

    ${onMobile} {
      margin-left: 0 !important;
    }
  }

  &:empty {
    margin-top: 0 !important;
  }

  ${onMobile} {
    justify-content: center;
    flex-direction: column;
    margin: 35px auto 0;
    font-size: 18px !important;
  }
`;
