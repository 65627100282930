import React, { useEffect, useMemo, useRef, useState } from 'react';
import { triggerDocumentHeightChange } from '@apw/components/ObserverScroll/ObserverScroll';
import { mixpanel, transport } from '@apw/core';
import { storageService } from '@apw/shared/storage/storage.service';
import { set } from 'lodash';

const EventBanner = () => {
  const STORAGE_KEY = 'RCDPW-ForceEnableEventBanner';
  const logoSlot = '%bannerLogoUrl%';
  const [visible, setVisible] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const htmlObject = useMemo(() => ({ __html: htmlContent }), [htmlContent]);
  const bannerRef = useRef<HTMLDivElement | null>(null);

  const initCommand = () => {
    set(window, 'RCDPW.forceEnableEventBanner', (isBannerVisible = true) => {
      setVisible(isBannerVisible);
      storageService.setItem(STORAGE_KEY, isBannerVisible);
    });
  };

  const replaceLogoUrl = (htmlContent: string, logoUrl: string): string => {
    return htmlContent.replace(new RegExp(logoSlot, 'g'), logoUrl);
  };

  const appendStyles = (styleContent: string) => {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = styleContent;
    document.head.appendChild(styleElement);
  };

  const getBanner = async () => {
    const data = await transport.getBanner();
    const isBannerVisible = storageService.getItem(STORAGE_KEY) === 'true';
    appendStyles(data.styleContent);
    setHtmlContent(replaceLogoUrl(data.htmlContent, data.logo));
    setVisible(data.visible || isBannerVisible);
  };

  const track = () => {
    mixpanel.trackAction('App Gallery Event Banner', 'Event banner click');
  };

  useEffect(() => {
    initCommand();
    getBanner();
  }, []);

  useEffect(() => {
    triggerDocumentHeightChange();
  }, [visible]);

  return (
    <div ref={bannerRef}>
      {visible && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react/no-danger
        <div dangerouslySetInnerHTML={htmlObject} onClick={track} />
      )}
    </div>
  );
};

export default EventBanner;
