import React, { useMemo } from 'react';
import { googleAnalytics, mixpanel } from '@apw/core/tracker';
import { useRouteDataContext } from '@apw/hooks/useRouteDataContext';
import {
  useCompatibilityHomePath,
  useCompatibilityMyAppsPath,
  useCompatibilityPartnerEcosystemPath,
} from '@apw/routes/useCompatibilityPaths';
import { useStores } from '@apw/stores';
import { IGalleryBrandNav } from '@apw/stores/compatibility/compatibilities/galleryBrand';
import { palette2, RcIcon, RcMenuItem, styled } from '@ringcentral/juno';
import { ExternalLink } from '@ringcentral/juno-icon';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import {
  Container,
  IconExternalLink,
  MenuItem as MenuItemComp,
} from './primaryNavigation.sc';

type MenuItem = {
  external?: boolean;
  alwaysActive?: boolean;
  hidden?: boolean;
  isActive?: (match) => boolean;
  onClick?: () => void;
} & IGalleryBrandNav;

interface PrimaryNavigationProps {
  onNavigated?: Function;
  cssProp: {
    container?: any;
    menuItem?: any;
  };
  StyledNavLink: any;
}

export type StyledPrimaryNavigationProps = Omit<
  PrimaryNavigationProps,
  'StyledNavLink'
>;

const StyledMenuItem = styled(RcMenuItem)`
  &:hover,
  &:active {
    color: ${palette2('text', 'secondary')} !important;
  }
`;

export const PrimaryNavigation = observer((props: PrimaryNavigationProps) => {
  const { onNavigated, StyledNavLink } = props;
  const homePath = useCompatibilityHomePath();
  const myAppsPath = useCompatibilityMyAppsPath();
  const partnerEcosystemPath = useCompatibilityPartnerEcosystemPath();
  const { userStore, compatibilityStore } = useStores();
  const location = useLocation();
  const routeData = useRouteDataContext();

  const menuItems: MenuItem[] = useMemo(
    () => [
      {
        label: 'Home',
        url: homePath,
        alwaysActive: true,
        isActive: () => {
          return (
            !!routeData?.isLandingPage ||
            !!routeData?.isIndividualPage ||
            !!routeData?.isPreviewPage
          );
        },
        onClick: () => {
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 300);
          if (onNavigated) {
            onNavigated();
          }
        },
      },
      {
        label: 'Partner Ecosystem',
        url: partnerEcosystemPath,
        external: false,
        hidden: userStore.isLoggedIn,
        isActive: () => {
          return location.pathname === partnerEcosystemPath;
        },
        onClick: () => {
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 300);
        },
      },
      {
        label: 'Installed apps',
        url: myAppsPath,
        external: false,
        hidden: !userStore.isLoggedIn,
        isActive: () => {
          return location.pathname === myAppsPath;
        },
        onClick: () => {
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 300);
        },
      },
      ...compatibilityStore.compatibility.horizontalNavs.map((nav) => ({
        label: nav.label,
        url: nav.url,
        external: true,
        onClick: () => {
          googleAnalytics.trackPageView(nav.label);
          mixpanel.trackPageView(nav.label);
        },
      })),
    ],
    [compatibilityStore.compatibility, userStore.isLoggedIn],
  );

  const trackClick = ({ onClick }: MenuItem) => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Container
      css={props.cssProp?.container}
      data-test-automation-id="primary-navigation"
    >
      {menuItems.map(
        (item) =>
          !item.hidden && (
            <MenuItemComp css={props.cssProp?.menuItem} key={item.label}>
              {item.external && (
                <a
                  onClick={() => trackClick(item)}
                  href={item.url}
                  target="_blank"
                  rel="noreferrer"
                  data-test-automation-id={`primaryNavigationItem-${item.label}`}
                  aria-label={item.label}
                >
                  <StyledMenuItem aria-hidden="true">
                    <span data-class="menuItemText">{item.label}</span>
                    <IconExternalLink>
                      <RcIcon symbol={ExternalLink} size="small" />
                    </IconExternalLink>
                  </StyledMenuItem>
                </a>
              )}
              {!item.external && (
                <StyledNavLink
                  onClick={() => trackClick(item)}
                  to={item.url}
                  exact
                  isActive={(match) => {
                    if (item.isActive) {
                      return item.isActive(match);
                    }
                    return !!match;
                  }}
                  data-test-automation-id={`primaryNavigationItem-${item.label}`}
                  aria-label={item.label}
                >
                  <StyledMenuItem aria-hidden="true">
                    <span data-class="menuItemText">{item.label}</span>
                  </StyledMenuItem>
                </StyledNavLink>
              )}
            </MenuItemComp>
          ),
      )}
    </Container>
  );
});
