import React, { FC, useMemo } from 'react';
import {
  CtaButton,
  GetSupport,
  IProfileOptions,
  RequireLogin,
} from '@apw/components/profileDetail';
import {
  defaultProfileOptions,
  ProfileOptionsContext,
} from '@apw/components/profileDetail/profile-options.context';
import { ProfileContext } from '@apw/components/profileDetail/profile.context';
import { ProfileViewModel } from '@apw/components/profileDetail/Profile.ViewModel';
import { Rating } from '@apw/components/ratingAndReview/rating/Rating';
import { useProfileType } from '@apw/modules/profile';
import { ProfileLogo } from '@apw/shared/profile';
import { breakpoints } from '@apw/shared/theme';
import { useStores } from '@apw/stores';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { observer } from 'mobx-react';
import { MultipleLineEllipsis } from '@drw/lib';
import { ProfileViewModelContext } from '../Profile.ViewModel.context';
import { DirectSupport } from './directSupport/DirectSupport';
import { PremierPartner } from './premierPartner/PremierPartner';
import {
  AppInfoWrapper,
  AppLogoWrapper,
  AppName,
  AppNameWrapper,
  BottomWrapper,
  CertifiedBadgeContainer,
  Container,
  CtaButtonWrapper,
  DirectSupportWrapper,
  ProfileNameContainer,
  PublisherName,
  PublisherWrapper,
  RatingWrapper,
  ShortDesWrapper,
  TopWrapper,
} from './ProfileHeader.sc';
import { ProfileName } from './ProfileName';

interface ProfileHeaderProps extends IProfileOptions {
  viewModel: ProfileViewModel;
}

const publisherMessage = ({ publisher = '', developer = '' }) => {
  if (developer) {
    return `built by ${developer} for ${publisher}`;
  }
  return `by ${publisher}`;
};

export const ProfileHeaderPure: FC<ProfileHeaderProps> = ({
  viewModel,
  onDownload,
  onSupport,
  onRequireLoginGate,
  isPreviewMode,
}) => {
  const profile = viewModel.profile!;
  const { userStore } = useStores();
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { isRegularApp } = useProfileType(profile);

  const options = useMemo<IProfileOptions>(
    () => ({
      ...defaultProfileOptions,
      onDownload,
      onSupport,
      onRequireLoginGate,
      isPreviewMode,
    }),
    [onDownload, onSupport, isPreviewMode],
  );

  if (!profile) {
    return null;
  }

  const appName = profile.appName;

  const ctaButton = () => {
    if (canShowRequireLogin()) {
      return (
        <RequireLogin pageName={profile.appName} brandId={profile.brand} />
      );
    }
    return <CtaButton />;
  };

  const canShowRequireLogin = () => {
    return (
      isRegularApp &&
      profile.loginGate &&
      !userStore.isLoggedIn &&
      !isPreviewMode
    );
  };

  const renderShortDesc = () => {
    return (
      <ShortDesWrapper data-test-automation-id={`short-desc`}>
        {profile.shortDes}
      </ShortDesWrapper>
    );
  };

  const renderRatings = () => {
    if (profile.appRatingEnabled && profile.appRating) {
      return (
        <RatingWrapper
          data-test-automation-id={'profile-header-rating-wrapper'}
        >
          <Rating
            rating={profile.appRating.overallRating}
            ratingCount={profile.appRating.voteCount}
          />
        </RatingWrapper>
      );
    }
    return <div />;
  };

  return (
    <ProfileViewModelContext.Provider value={viewModel}>
      <ProfileContext.Provider value={profile}>
        <ProfileOptionsContext.Provider value={options}>
          <Container>
            <TopWrapper>
              <AppLogoWrapper>
                <ProfileLogo profile={profile} borderRadius={24} tabIndex={0} />
              </AppLogoWrapper>
              <AppInfoWrapper>
                <AppNameWrapper>
                  <AppName>
                    <ProfileNameContainer
                      title={appName}
                      data-test-automation-id={`profile-detail-app-name`}
                    >
                      <ProfileName isMobileDevice={isMobile} name={appName} />
                    </ProfileNameContainer>
                    {profile.certifiedBadge && <CertifiedBadgeContainer />}
                  </AppName>
                  <PublisherWrapper>
                    <PublisherName
                      title={publisherMessage(profile)}
                      data-test-automation-id={`publisher-name`}
                    >
                      <MultipleLineEllipsis
                        linesToDisplay={2}
                        lineHeightPx={24}
                      >
                        {publisherMessage(profile)}
                      </MultipleLineEllipsis>
                    </PublisherName>
                    {profile.partnerBadge === 'Premier' && <PremierPartner />}
                  </PublisherWrapper>
                  {isMobile && renderRatings()}
                </AppNameWrapper>
                {!isMobile && renderShortDesc()}
                {!isMobile && renderRatings()}
              </AppInfoWrapper>
            </TopWrapper>
            {isMobile && renderShortDesc()}
            <BottomWrapper>
              <CtaButtonWrapper>{ctaButton()}</CtaButtonWrapper>
              <DirectSupportWrapper>
                <DirectSupport />
                {profile.supporterLink && <GetSupport profile={profile} />}
              </DirectSupportWrapper>
            </BottomWrapper>
          </Container>
        </ProfileOptionsContext.Provider>
      </ProfileContext.Provider>
    </ProfileViewModelContext.Provider>
  );
};

export const ProfileHeader = observer(ProfileHeaderPure);
