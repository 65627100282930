import { useMemo } from 'react';
import moment from 'moment';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    w: '1 week',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
});

export const useRelativeTime = (rawTimeString: string) => {
  return useMemo(() => {
    const timeFromNowInMonths = moment().diff(moment(rawTimeString), 'months');

    if (moment(rawTimeString).year() < moment().year()) {
      return moment(rawTimeString).format('MMM D, YYYY');
    }

    if (timeFromNowInMonths > 6) {
      return moment(rawTimeString).format('MMM D');
    }

    return moment(rawTimeString).fromNow();
  }, [rawTimeString]);
};
