import { map } from 'lodash';
import { BehaviorSubject } from 'rxjs';

type LoaderOptions = {
  siteKey: string;
};

let siteKey: string;

export const getSiteKey = () => siteKey;

export const isGoogleRecaptchaLoaded = new BehaviorSubject(false);

export const loadGoogleRecaptcha = (options: LoaderOptions) => {
  siteKey = options.siteKey;

  const callbackName = 'onGoogleRecaptchaLoad';
  const baseUrl = 'https://www.google.com/recaptcha/api.js';
  const params = {
    hl: 'en',
    render: 'explicit',
    onload: callbackName,
    r: Math.random(),
  };

  const paramsString = map(params, (value, key) => [key, value].join('=')).join(
    '&',
  );
  const requestUrl = [baseUrl, paramsString].join('?');

  window[callbackName] = () => {
    isGoogleRecaptchaLoaded.next(true);
  };

  const scriptElement = document.createElement('script');
  scriptElement.src = requestUrl;
  document.body.appendChild(scriptElement);
};
