import { get } from '@apw/core/api/api.service';
import { CollectionAppProfilesResponseInterface } from '@apw/modules/page/typings/collection-app-profiles-response.interface';
import {
  IPageContent,
  IProfileInPageContent,
} from '@apw/modules/page/typings/page-content.interface';
import { ProfileListItemInterface } from '@apw/types';
import { IProfileByAppAndBrandResponse } from '@apw/types/getProfileByAppAndBrandResponse.interface';

export const fetchPageContent = (pageId: number): Promise<IPageContent> => {
  const endpoint = `/api/pages/${pageId}`;
  return get(endpoint, { useGlobalLoading: true });
};

export const fetchGalleryPageContent = (
  pageId: number,
  brandId: string,
): Promise<IPageContent> => {
  const endpoint = `/api/gallery-pages/${pageId}/brand/${brandId}`;
  return get(endpoint, { useGlobalLoading: true });
};

export function fetchCollectionAppProfiles(
  id: number,
  page: number,
  perPage = 18,
): Promise<CollectionAppProfilesResponseInterface> {
  const endPoint = `/api/collection/${id}`;
  return get(endPoint, {
    params: {
      page,
      perPage,
    },
  });
}

export function fetchProfilesByAppCollection(
  collectionId: number,
  brandId: string,
  page: number,
  perPage = 24,
): Promise<CollectionAppProfilesResponseInterface> {
  const endPoint = `/api/gallery-collection/${collectionId}/brand/${brandId}`;
  return get(endPoint, {
    params: {
      page,
      perPage,
    },
  });
}

export function fetchGalleryCollectionAppProfiles(
  id: number,
  brandId: string,
  page: number,
  perPage = 18,
): Promise<CollectionAppProfilesResponseInterface> {
  const endPoint = `/api/collection/${id}`;
  return get(endPoint, {
    params: {
      page,
      perPage,
    },
  });
}

export const getProfileByAppIdAndBrandId = (
  appId: string,
  brandId: string,
): Promise<IProfileByAppAndBrandResponse> => {
  const endpoint = `/api/app/profile/application/${appId}/brand/${brandId}`;
  return get(endpoint, { useGlobalLoading: true });
};

export const normalizeProfilesInPageContent = (
  records: IProfileInPageContent[],
): ProfileListItemInterface[] => {
  return (records || []).map((record) => {
    const profile: Partial<ProfileListItemInterface> = {
      iconUrl: record.application.iconUri,
      applicationDisplayName: record.application.displayName,
      appName: record.name,
      publisher: record.publisher,
      partnerBadge: record.organization.partnerBadge,
      shortDes: record.shortDescription,
      vanityUrl: record.vanityUrlApplicationName,
      certifiedBadge: record.application.certifiedBadge,
      appRating: record.application.appRating,
      appRatingEnabled: record.application.appRatingEnabled,
    };

    return profile as ProfileListItemInterface;
  });
};

export const transformProfileForPageContent = (
  record: IProfileByAppAndBrandResponse,
): IProfileInPageContent => {
  return {
    application: {
      displayName: record.applicationDisplayName,
      iconUri: record.iconUrl,
      id: record.appId,
    },
    name: record.appName,
    organization: {
      id: record.orgId,
      partnerBadge: record.partnerBadge,
    },
    publisher: record.publisher,
    shortDescription: record.shortDes,
    vanityUrlApplicationName: record.vanityUrl,
  };
};
