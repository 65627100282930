import React, { FC, useEffect } from 'react';
import { Language, languageService } from '@apw/core/language';
import { useQueryParams } from '@apw/hooks';
import { shouldAppendBrandId2DownloadLink } from '@apw/modules/profile';
import { useStores } from '@apw/stores';
import { IProfile } from '@apw/types';
import { RcButton } from '@ringcentral/juno';
import { Close } from '@ringcentral/juno-icon';
import {
  Actions,
  CloseButton,
  Container,
  Content,
  DialogTitle,
} from './DownloadPopup.sc';

export const DownloadPopup: FC<{
  profile: IProfile;
  downloadUrl: string;
  onClose;
}> = ({ profile, downloadUrl, onClose }) => {
  const { userStore, compatibilitiesStore } = useStores();
  const { brandId } = useQueryParams();
  let timer;
  const onDownload = () => {
    onClose();
    window.location.href = getDownloadLink();
  };

  const getDownloadLink = () => {
    if (shouldAppendBrandId2DownloadLink(profile.vanityUrl || '')) {
      return getAppendBrandId()
        ? `${downloadUrl}?brandId=${getAppendBrandId()}`
        : downloadUrl;
    }
    return downloadUrl;
  };

  const getAppendBrandId = () => {
    if (brandId) {
      return brandId;
    }
    if (
      compatibilitiesStore.isRisePartnerBrand(userStore.user?.brandId || '')
    ) {
      return userStore.user?.brandId;
    }
    return '';
  };

  useEffect(() => {
    timer = setTimeout(() => {
      onDownload();
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Container open>
      <DialogTitle
        role="banner"
        data-test-automation-id="download-dialog-title"
      >
        {languageService.get(Language.DOWNLOAD_POPUP_TITLE)}
        <CloseButton
          onClick={onClose}
          symbol={Close}
          aria-label="close"
          data-test-automation-id="download-dialog-close-button"
        />
      </DialogTitle>
      <Content role="main" data-test-automation-id="download-dialog-content">
        {languageService.getWithContext(
          Language.DOWNLOAD_POPUP_MESSAGE,
          profile.appName,
        )}
      </Content>
      <Actions>
        <RcButton
          variant="text"
          onClick={onClose}
          data-test-automation-id="download-dialog-cancel-button"
        >
          Cancel
        </RcButton>
        <RcButton onClick={onDownload} data-test-automation-id="downloadAppBtn">
          Download
        </RcButton>
      </Actions>
    </Container>
  );
};
