import { RefObject, useContext, useEffect } from 'react';
import { PageContentContext } from '@apw/lib/components/pageContent';

const safeWindowOpen = (url: string) => {
  return window.open(url, '_blank', 'noopener');
};

export const useLinksInHtml = (ref: RefObject<HTMLElement>) => {
  const { isInternalLink } = useContext(PageContentContext);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const anchorEls = ref.current.querySelectorAll('a');

    anchorEls.forEach((anchorEl) => {
      if (anchorEl.target === '_blank') {
        return;
      }

      anchorEl.onclick = (e: Event) => {
        if (!isInternalLink(anchorEl.href)) {
          e.preventDefault();
          e.stopPropagation();
          safeWindowOpen(anchorEl.href);
          return;
        }

        // For internal link, propagate and hand over to Jupiter's LinkClickingMonitor
      };
    });
  }, [ref.current]);
};
