import xss from 'xss';
import { markdownService } from './markdown.service';

const PLAIN_TEXT_FORMAT_PREFIX = '[pt]';
const MARKDOWN_FORMAT_PREFIX = '[md]';
const HTML_FORMAT_PREFIX = '[html]';

function isMarkdownFormat(value: string): boolean {
  return (value || '').startsWith(MARKDOWN_FORMAT_PREFIX);
}

function isHtmlFormat(value: string): boolean {
  return (value || '').startsWith(HTML_FORMAT_PREFIX);
}

function formatPrefixMatcher(prefix: string): RegExp {
  return new RegExp(`^\\${prefix}`);
}

function clearFormatPrefix(value: string): string {
  return (value || '')
    .replace(formatPrefixMatcher(PLAIN_TEXT_FORMAT_PREFIX), '')
    .replace(formatPrefixMatcher(MARKDOWN_FORMAT_PREFIX), '')
    .replace(formatPrefixMatcher(HTML_FORMAT_PREFIX), '');
}

function prependHtmlFormatPrefix(value: string): string {
  return `${HTML_FORMAT_PREFIX} ${value}`;
}

function convertToHtmlFormat(value: string): string {
  if (isHtmlFormat(value)) {
    return xssFilterForQuill(clearFormatPrefix(value));
  }

  const markdown = clearFormatPrefix(value);
  return xss(markdownService.md2Html(markdown));
}

function xssFilterForQuill(value: string): string {
  return xss(value, {
    whiteList: {
      h2: [],
      h3: [],
      p: [],
      strong: [],
      u: [],
      em: [],
      ol: [],
      ul: [],
      li: ['data-list', 'class'],
      a: ['href', 'target'],
      blockquote: [],
      span: ['class'],
      br: [],
      div: [],
    },
  });
}

export const richTextFieldService = {
  isMarkdownFormat,
  isHtmlFormat,
  prependHtmlFormatPrefix,
  convertToHtmlFormat,
};
