import React, { useContext } from 'react';
import { RcIcon, RcLink, styled } from '@ringcentral/juno';
import { ExternalLink } from '@ringcentral/juno-icon';
import { get } from 'lodash';
import { StoreContext } from '../../../StoreContext';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

const Title = styled.h5`
  margin: 0 0 12px;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
`;

const LinkWrapper = styled.div`
  display: table;
  table-layout: fixed;
  margin-bottom: 32px;
`;

const LinkItem = styled(RcLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0 10px;
`;

const LinkLabel = styled.span`
  word-break: break-word;
`;

const LinkIcon = styled(RcIcon)`
  flex-shrink: 0;
  margin-left: 24px;
`;

export function HelpfulLinks({ className = '', links }) {
  const {
    translation: { helpfulLinks },
  } = useContext(StoreContext);

  if (!links || !links.length) {
    return null;
  }

  const getTranslation = (key: string): string => {
    return get(helpfulLinks, key);
  };

  return (
    <Wrapper className={`helpfulLinks ${className || ''}`}>
      <Title
        className="helpfulLinksTitle"
        data-test-automation-id="helpfulLinksTitle"
      >
        {helpfulLinks.title}
      </Title>
      <LinkWrapper data-test-automation-id="helpfulLinksList">
        {links.map((item, index) => {
          return (
            <LinkItem
              key={item.key}
              variant="inherit"
              href={item.link}
              target="_blank"
              rel="noreferrer"
              data-test-automation-id={`helpfulLinksItem-${index}`}
            >
              <LinkLabel
                className="helpfulLinkLabel"
                data-test-automation-id="helpfulLinksItemLabel"
              >
                {getTranslation(item.label)}
              </LinkLabel>
              <LinkIcon
                symbol={ExternalLink}
                size="small"
                data-test-automation-id="helpfulLinksItemIcon"
              />
            </LinkItem>
          );
        })}
      </LinkWrapper>
    </Wrapper>
  );
}
