import React, { FC } from 'react';
import { BaseButton, BaseButtonProps } from '../baseButton';

type PrimaryButtonProps = Omit<BaseButtonProps, 'buttonStyle'>;

export const PrimaryButton: FC<PrimaryButtonProps> = ({
  onClick,
  className = '',
  children,
  disabled = false,
  ariaLabel,
  ariaExpanded,
}) => {
  return (
    <BaseButton
      onClick={onClick}
      buttonStyle="contained"
      className={`${className}`}
      disabled={disabled}
      ariaLabel={ariaLabel}
      ariaExpanded={ariaExpanded}
    >
      {children}
    </BaseButton>
  );
};
