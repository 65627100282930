import { useEffect, useState } from 'react';
import { throttle } from 'lodash';

export const useInnerWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handle = throttle((e) => {
    const innerWidth = e.target.innerWidth;
    setWidth(innerWidth);
  }, 500);

  useEffect(() => {
    window.addEventListener('resize', handle);
    return () => {
      window.removeEventListener('resize', handle);
    };
  }, []);
  return width;
};
