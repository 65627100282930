import React, { FC, useMemo } from 'react';
import {
  Container,
  ItemList,
} from '@apw/components/profileDetail/profileBody/ratingsAndReviewsTab/ratingsSection/content/qualityRating/qualityRating.sc';
import { QualityRatingItem } from '@apw/components/profileDetail/profileBody/ratingsAndReviewsTab/ratingsSection/content/qualityRating/qualityRatingItem/QualityRatingItem';
import { QualityRatingLabels } from '@apw/components/ratingAndReview/qualityRating/qualityRatingLabels';
import { IAppRating } from '@apw/types';
import { RcTypography } from '@ringcentral/juno';

export interface IQualityRatingProps {
  ratings: IAppRating;
}

export const QualityRating: FC<IQualityRatingProps> = ({ ratings }) => {
  const items = useMemo(() => {
    return QualityRatingLabels.map((item, index) => {
      const scores = [
        ratings.valueRating,
        ratings.convenienceRating,
        ratings.featureRating,
        ratings.supportRating,
      ];
      return {
        score: scores[index],
        ...item,
      };
    });
  }, [ratings]);

  return (
    <Container>
      <RcTypography
        variant={'subheading2'}
        color="neutral.f06"
        data-test-automation-id={'quality-rating-title'}
      >
        Quality ratings
      </RcTypography>
      <ItemList data-test-automation-id={'quality-rating-item-list'}>
        {items.map((item) => {
          return (
            <QualityRatingItem
              tooltip={item.tooltip}
              text={item.label}
              score={item.score}
            />
          );
        })}
      </ItemList>
    </Container>
  );
};
