import { onMobile, useProximaNovaSemibold } from '@apw/core/sc';
import { SuggestApp } from '@apw/modules/search/suggestApp/SuggestApp';
import { RcButton, RcDialog, styled } from '@ringcentral/juno';

export const Dialog = styled(RcDialog)`
  [class~='MuiDialog-scrollPaper'] {
    align-items: flex-start;
  }

  [class~='MuiDialog-paper'] {
    max-width: 880px !important;

    ${onMobile} {
      max-width: none !important;
      max-height: none !important;
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
      border-radius: 0 !important;
    }
  }
`;

export const SearchResultContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${onMobile} {
    max-height: none;
  }
`;

export const ContentViewWrapper = styled.div`
  position: relative;
  max-height: 600px;
  min-height: 100px;
  overflow: auto;

  ${onMobile} {
    max-height: calc(100vh - 150px);
  }
`;

export const SuggestAppStyled = styled(SuggestApp)`
  margin: 56px auto 76px !important;
`;

export const SearchFooter = styled.div`
  padding: 20px;
  text-align: right;
  border-top: 1px solid #eeeeee;
`;

export const ShowAllButton = styled(RcButton)`
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  ${useProximaNovaSemibold}

  ${onMobile} {
    width: 100%;
    height: 48px;
    text-align: center;
    border-radius: 4px;
    font-size: 20px;
  }
`;
