import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ProfileContext } from '@apw/components/profileDetail/profile.context';
import ScreenshotSlide from '@apw/components/profileDetail/profileBody/overviewTab/overviewSlider/screenshotSlide/ScreenshotSlide';
import { VideoSlideRef } from '@apw/components/profileDetail/profileBody/overviewTab/overviewSlider/video-slide.interface';
import { isVimeoUrl } from '@apw/components/profileDetail/profileBody/overviewTab/overviewSlider/vimeoSlide/vimeo-slide.service';
import VimeoSlide from '@apw/components/profileDetail/profileBody/overviewTab/overviewSlider/vimeoSlide/VimeoSlide';
import YoutubeSlide from '@apw/components/profileDetail/profileBody/overviewTab/overviewSlider/youtubeSlide/YoutubeSlide';
import ScaleDiv from '@apw/components/scaleDiv/ScaleDiv';
import {
  SliderEnhance as Slider,
  SliderRefMethods,
} from '@apw/components/slider';
import oneTrustHelper from '@apw/core/onetrust';
import { MediaProps, MediaType } from '@apw/types/media.interface';
import { RcDialogContent } from '@ringcentral/juno';
import { ChevronLeft, ChevronRight } from '@ringcentral/juno-icon';
import {
  ActionContainer,
  Back,
  CloseBtn,
  DialogContainer,
  DialogSlideBackground,
  Divider,
  Next,
  SlideAction,
  SlideIndicator,
} from './overviewSliderDialog.sc';

const OverviewSliderDialog = ({
  initialDialogIndex,
  dialogState,
  setDialogState,
}) => {
  const [curPage, setCurPage] = useState(1);
  const sliderRef = useRef<SliderRefMethods>(null);
  const profile = useContext(ProfileContext);
  const close = useCallback(() => setDialogState(false), [setDialogState]);
  const videoSlideRefs = useRef<VideoSlideRef[]>([]);
  const media = useMemo(() => profile.media || [], [profile]);
  const mediaFiltered = useMemo(() => {
    return oneTrustHelper.targetingCookiesEnabled
      ? media
      : media.filter((md) => md[MediaProps.TYPE] === MediaType.SCREENSHOT);
  }, [media]);
  const childrenTemp = useMemo(() => {
    return [
      ...mediaFiltered.map((media, index) => {
        if (media[MediaProps.TYPE] === MediaType.VIDEO) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`media${index}`}>
              <ScaleDiv widthWeight={488} heightWeight={274}>
                {isVimeoUrl(media[MediaProps.URL]) ? (
                  <VimeoSlide
                    url={media[MediaProps.URL]}
                    ref={(ref) =>
                      (videoSlideRefs.current[index] = ref as VideoSlideRef)
                    }
                  />
                ) : (
                  <YoutubeSlide
                    url={media[MediaProps.URL]}
                    ref={(ref) =>
                      (videoSlideRefs.current[index] = ref as VideoSlideRef)
                    }
                  />
                )}
              </ScaleDiv>
            </React.Fragment>
          );
        }
        return (
          <ScaleDiv
            widthWeight={488}
            heightWeight={274}
            // eslint-disable-next-line react/no-array-index-key
            key={`media${index}`}
          >
            <ScreenshotSlide src={media[MediaProps.URL]} />
          </ScaleDiv>
        );
      }),
    ];
  }, [profile]);
  const dots = useMemo(
    () => Array.from(Array(childrenTemp.length)),
    [childrenTemp],
  );

  const onBack = useCallback(() => {
    sliderRef.current?.backPage();
  }, [sliderRef.current]);

  const onNext = useCallback(() => {
    sliderRef.current?.nextPage();
  }, [sliderRef.current]);

  const onClickDot = (index) => {
    sliderRef.current?.goPage(index);
  };

  const onChangePage = (index) => {
    setCurPage(index);
    setTimeout(() => {
      const curr = videoSlideRefs.current[curPage - 1];
      if (curr && curr.forceUpdate) {
        curr.forceUpdate();
      }
    }, 500);
  };

  return (
    <DialogContainer open={dialogState} onClose={close}>
      <RcDialogContent role="main">
        <DialogSlideBackground>
          <Slider
            ref={sliderRef}
            keyboardEnable
            initialPageIndex={initialDialogIndex}
            dragEnable={false}
            onChangePage={onChangePage}
          >
            {childrenTemp}
          </Slider>
        </DialogSlideBackground>
        <Divider />
        <ActionContainer>
          {childrenTemp.length > 1 && (
            <SlideAction>
              <Back
                onClick={onBack}
                symbol={ChevronLeft}
                disabled={curPage === 1}
                aria-label="previous screen shot"
                data-test-automation-id={'previous-screen-shot-button'}
              />
              {dots.map((_, index) => {
                return (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                  <SlideIndicator
                    className={`${curPage === index + 1 ? 'active' : ''}`}
                    onClick={() => onClickDot(index + 1)}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    data-test-automation-id={`slide-indicator-${index + 1}`}
                  />
                );
              })}
              <Next
                onClick={onNext}
                symbol={ChevronRight}
                disabled={curPage === childrenTemp.length}
                aria-label="next screen shot"
                data-test-automation-id={'next-screen-shot-button'}
              />
            </SlideAction>
          )}
          <CloseBtn onClick={close} data-test-automation-id={`close-button`}>
            Close
          </CloseBtn>
        </ActionContainer>
      </RcDialogContent>
    </DialogContainer>
  );
};

export { OverviewSliderDialog };
