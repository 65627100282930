import React, { useContext } from 'react';
import { RcTypography } from '@ringcentral/juno';
import { StoreContext } from '@apw/lib/components/profileDetail/StoreContext';
import { RectangleRating } from '@apw/lib/components/rectangleRating/RectangleRating';
import { Tooltip } from '@apw/lib/components/tooltip';
import { Container, LabelBox, RatingBox } from './criteria.sc';

interface CriteriaBoxProps {
  label: string;
  tooltip: string;
  name: string;
  value: number | null;
  onChange: (event: React.ChangeEvent<{}>, value: number | null) => void;
}

export const Criteria = ({
  label,
  tooltip,
  name,
  value,
  onChange,
}: CriteriaBoxProps) => {
  const { translation } = useContext(StoreContext);
  return (
    <Container data-test-automation-id={`rating-popup-criteria-${name}`}>
      <LabelBox>
        <RcTypography
          color="neutral.f05"
          variant="body1"
          data-test-automation-id="criteria-label"
        >
          {label}
        </RcTypography>
        <Tooltip tabIndex={0} title={tooltip} automationId="criteria-tooltip" />
      </LabelBox>
      <RatingBox>
        <RectangleRating
          tooltips={translation.ratingsAndReviews.ratingTooltips}
          name={name}
          value={value}
          onChange={onChange}
        />
      </RatingBox>
    </Container>
  );
};
