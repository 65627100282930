import React, { useEffect, useRef, useState } from 'react';
import { ensureAction } from '@apw/core';
import { RcIconButton } from '@ringcentral/juno';
import { Close, Search } from '@ringcentral/juno-icon';
import { RcDivider } from '@ringcentral/juno/components/Divider';
import { ClearBtn, Container, InputField } from './SearchInput.sc';

const SearchInput = ({
  handleClose,
  handleChange,
  handleKeyDown,
  initialValue,
}) => {
  const inputRef = useRef<any>(null);
  const [keyword, setKeyword] = useState(initialValue);
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleClear = () => {
    setKeyword('');
    inputRef.current?.focus();
    handleChange('');
  };

  const onChange = (event) => {
    setKeyword(event.target.value);
    handleChange(event.target.value);
  };

  const onKeyDown = (event) => {
    handleKeyDown(event.key);
  };

  const keyDownOnClear = (event: KeyboardEvent) => {
    const actionFn = () => handleClear();
    ensureAction(event, actionFn);
  };
  return (
    <Container role="banner">
      <RcIconButton
        symbol={Search}
        onClick={() => handleKeyDown('Enter')}
        aria-label="search icon"
        data-test-automation-id="search-icon"
      />
      <InputField
        ref={inputRef}
        type="text"
        value={keyword}
        onChange={onChange}
        onKeyDown={onKeyDown}
        aria-label="keywords"
        data-test-automation-id="search-input-field"
      />

      {keyword && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <ClearBtn
          role="button"
          tabIndex={0}
          onClick={handleClear}
          onKeyDown={(e) => keyDownOnClear(e as any)}
          data-test-automation-id="search-input-clear-btn"
        >
          Clear
        </ClearBtn>
      )}
      <RcDivider vertical />
      <RcIconButton
        onClick={handleClose}
        symbol={Close}
        aria-label="close"
        data-test-automation-id="search-close-btn"
      />
    </Container>
  );
};

export default SearchInput;
