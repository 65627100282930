import React from 'react';
import { richTextFieldService } from '@apw/components/markdown/rich-text-field.service';
import { palette2, styled } from '@ringcentral/juno';
import {
  InstallationContent,
  InstallationHeader,
  InstallationStyle,
} from './installation.sc';

interface InstallationProps {
  className: string;
  content: string;
}

const Installation = ({ className = '', content }: InstallationProps) => {
  if (!content || !content.trim()) {
    return null;
  }

  const detailDesc = {
    __html: richTextFieldService.convertToHtmlFormat(content),
  };

  return (
    <InstallationStyle className={`${className}`}>
      <InstallationHeader>Installation</InstallationHeader>
      {/* eslint-disable-next-line react/no-danger */}
      <InstallationContent
        data-test-automation-id={`installation-content`}
        dangerouslySetInnerHTML={detailDesc}
      />
    </InstallationStyle>
  );
};

const StyledInstallation = styled(Installation)`
  a {
    color: ${palette2('text', 'secondary')};
  }
`;

export { StyledInstallation as Installation };
