import React, { useContext, useMemo, useRef } from 'react';
import { RcTab, RcTabs, styled } from '@ringcentral/juno';
import { observer } from 'mobx-react';
import { RatingsAndReviewsTab } from '@apw/lib/components/profileDetail/profileBody/ratingsAndReviewsTab';
import { useTabsBodyMinHeight } from '@apw/lib/components/profileDetail/profileBody/useTabsBodyMinHeight';
import { RatingPopupContainer } from '@apw/lib/components/ratingAndReview/popup/ratingPopupContainer';
import { JUNO_THEME_PREFIX } from '@apw/lib/constants';
import { StoreContext } from '../StoreContext';
import { ProfileTabKeysEnum } from '../types';
import { OverviewTab } from './overviewTab';
import { TechnicalDetails } from './technicalDetails';

interface ITabContentProps {
  isActive: boolean;
}

const ProfileContent = styled.div`
  width: 100%;
  padding-top: 32px;
`;

const ProfileContentWithTabs = styled.div`
  width: 100%;
`;

const TabsHeader = styled(RcTabs)`
  border-bottom: 1px solid #e5e5e5;

  button.${JUNO_THEME_PREFIX}-MuiTab-root {
    padding: 0 8px;
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

const TabsBody = styled.div`
  padding-top: 32px;
  box-sizing: border-box;
`;

const TabContent = styled.div`
  display: ${(props: ITabContentProps) => (props.isActive ? 'block' : 'none')};
`;

function ProfileBodyPure() {
  const {
    profile,
    translation,
    selectedTab,
    setSelectedTab,
    onSelectedTabChanged,
    ratingPopupOpen,
    scrollContainerRef,
  } = useContext(StoreContext);
  const includePermissions = !!profile?.permissions?.length;
  const tabsHeaderRef = useRef(null);
  const tabsBodyMinHeight = useTabsBodyMinHeight(
    scrollContainerRef,
    tabsHeaderRef,
  );

  const changeTab = ($event, value) => {
    if (value === selectedTab) return;
    setSelectedTab(value);
    if (onSelectedTabChanged) {
      onSelectedTabChanged(value);
    }
  };

  const tabs = useMemo(() => {
    const allTabs = [
      {
        label: translation.tabs.overview,
        value: ProfileTabKeysEnum.OVERVIEW,
        component: <OverviewTab />,
        automationId: 'overview-tab',
        display: true,
      },
      {
        label: translation.tabs.technicalDetails,
        value: ProfileTabKeysEnum.TECHNICAL_DETAILS,
        component: <TechnicalDetails />,
        automationId: 'technical-details-tab',
        display: includePermissions,
      },
      {
        label: translation.tabs.ratingsAndReviews,
        value: ProfileTabKeysEnum.RATINGS_AND_REVIEWS,
        component: <RatingsAndReviewsTab />,
        automationId: 'ratings-and-reviews-tab',
        display: profile?.appRatingEnabled,
      },
    ];

    return allTabs.filter((tab) => tab.display);
  }, [includePermissions, profile]);

  if (tabs.length === 1) {
    return (
      <ProfileContent data-test-automation-id={`${tabs[0].automationId}-panel`}>
        {tabs[0].component}
      </ProfileContent>
    );
  }

  return (
    <ProfileContentWithTabs>
      <TabsHeader
        className="tabsHeader"
        size="large"
        value={selectedTab}
        onChange={changeTab}
        ref={tabsHeaderRef}
      >
        {tabs.map((tab) => (
          <RcTab
            label={tab.label}
            value={tab.value}
            data-test-automation-id={tab.automationId}
          />
        ))}
      </TabsHeader>
      <TabsBody className="tabsBody" style={{ minHeight: tabsBodyMinHeight }}>
        {tabs.map((tab) => (
          <TabContent
            isActive={selectedTab === tab.value}
            data-test-automation-id={`${tab.automationId}-panel`}
          >
            {tab.component}
          </TabContent>
        ))}
      </TabsBody>
      {ratingPopupOpen && <RatingPopupContainer />}
    </ProfileContentWithTabs>
  );
}

export const ProfileBody = observer(ProfileBodyPure);
