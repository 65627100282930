import {
  FontWeight,
  useProximaNovaMedium,
  useProximaNovaSemibold,
} from '@apw/core/sc';
import {
  css,
  RcButton,
  RcAccordion,
  RcAccordionSummary,
  RcAccordionDetails,
  RcIcon,
  styled,
} from '@ringcentral/juno';

export const HeaderMenu = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: #fff;
  font-size: 15px;
  line-height: 21px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: auto;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  padding: 0 16px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 16px;
`;

export const CloseIcon = styled(RcIcon)`
  color: #000;
  svg {
    font-size: 24px;
  }
`;

export const Button = styled(RcButton)`
  && {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    font-size: 20px;
    ${useProximaNovaSemibold}
  }
`;

export const ToLoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 33px 20px;
`;

export const LoginStatusContainer = styled(RcAccordion)`
  box-shadow: none !important;
  margin: 0 !important;
  padding: 16px 8px 16px 20px !important;
`;

export const UserName = styled(RcAccordionSummary)`
  ${useProximaNovaMedium};
  color: #282829 !important;
  font-size: 18px;
  line-height: 24px;
  font-weight: ${FontWeight.bold};
  padding: 0 !important;
  min-height: 32px !important;
  height: 32px !important;
`;

export const NameWrapper = styled.span`
  white-space: nowrap;
`;

export const Logout = styled(RcAccordionDetails)`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-top: none !important;
`;

export const StatusContainer = styled.div`
  border-top: 1px solid #e0e0e0;
  font-size: 18px;
  color: #000000;
`;

export const primaryNavigationContainer = css`
  flex-direction: column;
  padding: 0 4px 16px;
`;

export const primaryNavigationMenuItem = css`
  padding: 16px 0;

  a {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  [class~='MuiListItem-root'] {
    justify-content: flex-start;
    width: 100%;
    font-size: 18px !important;
    min-height: 24px !important;
    padding: 0 24px 0 16px !important;
  }
`;
