import { useEffect, useRef } from 'react';
import { Observable } from 'rxjs';

export const useSubscribe = (
  observableCreator: () => Observable<any>,
  observer: Function,
) => {
  const observerRef = useRef<Function>();
  const resultRef = useRef();

  useEffect(() => {
    observerRef.current = observer;
  });

  useEffect(() => {
    const subscription = observableCreator().subscribe((x) => {
      observerRef.current!(x);
      resultRef.current = x;
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return resultRef.current;
};
