import React, { FC } from 'react';
import { BasicInfo } from '@apw/components/profileDetail/profileBody/ratingsAndReviewsTab/ratingsSection/content/basicInfo/BasicInfo';
import {
  Container,
  StyledOverallRating,
} from '@apw/components/profileDetail/profileBody/ratingsAndReviewsTab/ratingsSection/content/content.sc';
import { QualityRating } from '@apw/components/profileDetail/profileBody/ratingsAndReviewsTab/ratingsSection/content/qualityRating/QualityRating';
import { ActionButtons } from '@apw/components/ratingAndReview/actionButtons/ActionButtons';
import { buildOverallItems } from '@apw/components/ratingAndReview/overallRating/overallRating.service';
import { breakpoints } from '@apw/shared/theme';
import { IAppRating } from '@apw/types';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export interface IContentProps {
  ratings: IAppRating;
}

export const Content: FC<IContentProps> = ({ ratings }) => {
  const overallRatingItems = buildOverallItems(ratings);
  const isMobile = useMediaQuery(breakpoints.mobile);
  return (
    <Container data-test-automation-id={'rating-and-review-tab-ratings'}>
      <BasicInfo
        overallRating={ratings.overallRating}
        voteCount={ratings.voteCount}
      />
      <StyledOverallRating
        items={overallRatingItems}
        automationId={'rating-and-review-tab-overall-ratings'}
      />
      <QualityRating ratings={ratings} />
      {isMobile && <ActionButtons />}
    </Container>
  );
};
