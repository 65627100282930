import {
  FontWeight,
  menuItemHoverActiveStatus,
  onMobile,
  removeRcMenuScrollEffectOnMobile,
  useProximaNovaRegular,
  useProximaNovaSemibold,
} from '@apw/core/sc';
import { RcMenuItem, styled } from '@ringcentral/juno';

export const ProductItem = styled(RcMenuItem)`
  display: flex;
  justify-content: flex-start;
  padding: 4px 16px;
  width: 240px !important;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 16px !important;
  ${useProximaNovaRegular};
  ${menuItemHoverActiveStatus};

  ${onMobile} {
    width: auto !important;
  }

  &.selectProductItem {
    color: #282829 !important;
    font-weight: ${FontWeight.bold} !important;
    ${useProximaNovaSemibold};
  }

  &.mobileDevice:not(.selectProductItem) {
    &[role='menuitem'] {
      ${removeRcMenuScrollEffectOnMobile};
    }
  }
`;

export const Separator = styled.div`
  background: #cbcbcb;
  height: 1px;
  width: 208px;
  margin: 18px auto;

  ${onMobile} {
    display: none;
    width: auto !important;
  }
`;
