import { BPS, respondTo } from '@apw/core/sc';
import { styled, RcDialog, RcIconButton } from '@ringcentral/juno';

export const DialogContainer = styled(RcDialog)`
  [class~='MuiDialogContent-root'] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 !important;
    background-color: #000;
  }

  ${respondTo(BPS.ltTablet)} {
    [class~='MuiDialog-paperWidthSm'] {
      max-width: none !important;
    }

    [class~='MuiDialog-paper'] {
      height: 100% !important;
      max-height: none !important;
      margin: 0 !important;
      width: 100% !important;
      border-radius: 0 !important;
    }
  }
`;

export const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #2f2f2f;
  padding: 4px 10px;
  display: flex;
  justify-content: flex-end;
`;

export const CloseIcon = styled(RcIconButton)`
  color: #ffffff !important;
  font-weight: bold !important;
`;
