import { Tooltip } from '@apw/components';
import { onMobile } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const TooltipContainer = styled(Tooltip)`
  margin-left: 5px;
  color: #686869 !important;
`;

export const BrandName = styled.span`
  display: inline-block;
`;

export const TooltipTitle = styled.span`
  ${onMobile} {
    display: block;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    span {
      vertical-align: middle;
    }
  }
`;
