import { useProximaNovaMedium } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const Container = styled.div`
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #cbcbcb;
`;

export const Title = styled.div`
  ${useProximaNovaMedium};
  color: #282829;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
`;
