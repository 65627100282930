import { css } from '@ringcentral/juno';

const fallbackFontFamily = 'Helvetica, Arial, sans-serif';

export const useProximaNovaRegular = css`
  font-family: 'ProximaNova-Regular', ${fallbackFontFamily} !important;
`;

export const useProximaNovaMedium = css`
  font-family: 'ProximaNova-Medium', ${fallbackFontFamily} !important;
`;

export const useProximaNovaBold = css`
  font-family: 'ProximaNova-Bold', ${fallbackFontFamily} !important;
`;

export const useProximaNovaSemibold = css`
  font-family: 'ProximaNova-Semibold', ${fallbackFontFamily} !important;
`;

export const removeRcMenuScrollEffectOnMobile = css`
  &:hover {
    color: #282b33 !important;
    background-color: transparent !important;
  }
  &:active {
    color: #282b33 !important;
    background-color: transparent !important;
  }
`;

export const textNoWrap = (fontSize: string) => css`
  display: inline-block;
  font-size: ${fontSize};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const overflowWrap = (value: string) => css`
  // ie11
  word-wrap: ${value};
  // modern browser
  overflow-wrap: ${value};
`;

export const menuItemHoverActiveStatus = () => css`
  &:hover {
    background-color: rgba(#6a7186, 0.08) !important;
  }
  &:active {
    background-color: rgba(#6a7186, 0.24) !important;
    color: #282b33 !important;
  }
`;

// Font color
export const fontColorBlack = '#001138';
