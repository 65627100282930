import {
  onMobile,
  PAGE_DESKTOP_PADDING,
  PAGE_MAX_WIDTH,
  PAGE_MOBILE_PADDING,
} from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

const MAX_WIDTH = PAGE_MAX_WIDTH + PAGE_DESKTOP_PADDING * 2;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const HeaderWrap = styled.div`
  background: #fff;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  margin: auto;
  background: #fff;
`;

export const Main = styled.div`
  flex-grow: 1;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;

  max-width: ${MAX_WIDTH}px;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 104px - 66px);
  margin: auto;
  padding: 0 ${PAGE_DESKTOP_PADDING}px;

  ${onMobile} {
    padding: 0 ${PAGE_MOBILE_PADDING}px;
  }
`;
