import { BrandId } from './brand-id.enum';

export const BrandId2NameMapping = {
  [BrandId.RINGCENTRAL]: 'RingCentral',
  [BrandId.ATT]: 'AT&T Office@Hand',
  [BrandId.AVAYA]: 'Avaya Cloud Office',
  [BrandId.BT]: 'BT Cloud Work',
  [BrandId.ALCATEL]: 'Rainbow Office',
  [BrandId.MCM]: 'RingCentral for Symphony',
  [BrandId.ECOTEL]: 'RingCentral mit ecotel',
  [BrandId.EASTLINK]: 'RingCentral with Eastlink',
  [BrandId.VERIZON]: 'RingCentral with Verizon',
  [BrandId.TELUS]: 'TELUS Business Connect',
  [BrandId.ATOS]: 'Unify Office',
  [BrandId.VODAFONE]: 'Vodafone Business',
};

export const BrandId2ShortNameMapping = {
  [BrandId.RINGCENTRAL]: 'RingCentral',
  [BrandId.ATT]: 'AT&T',
  [BrandId.ALCATEL]: 'Alcatel',
  [BrandId.ATOS]: 'Atos',
  [BrandId.AVAYA]: 'Avaya',
  [BrandId.BT]: 'BT',
  [BrandId.VERIZON]: 'Verizon',
  [BrandId.TELUS]: 'Telus',
  [BrandId.VODAFONE]: 'Vodafone',
  [BrandId.MCM]: 'MCM',
  [BrandId.ECOTEL]: 'Ecotel',
  [BrandId.EASTLINK]: 'Eastlink',
  [BrandId.VERSATEL]: 'Versatel',
  [BrandId.TELEKOM_DT]: 'Telekom (DT)',
  [BrandId.UNIFY_OFFICE_DT_ATOS]: 'Unify Office (DT/ATOS)',
  [BrandId.FRONTIER]: 'Frontier',
};

export const brandOtherFamilyIds = {
  [BrandId.RINGCENTRAL]: [
    /* RC CA: */ '3610',
    /* RC UK: */ '3710',
    /* RC EU: */ '2010',
    /* RC AU: */ '5010',
  ],
  [BrandId.ATT]: [/* ATT_UB */ '3460'],
};
