import { onMobile } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  background: rgb(255, 255, 255);
  padding: 15px 5px;
  border-bottom: 1px #eeeeee solid;

  ${onMobile} {
    height: 72px;
  }
`;

export const InputField = styled.input`
  flex-grow: 1;
  height: 26px;
  color: rgb(40, 40, 41);
  font-size: 18px;
  border: 0;

  &:focus {
    outline: none;
  }
`;

export const ClearBtn = styled.span`
  font-size: 16px;
  color: #666c7e;
  margin-left: 12px;
  margin-right: 12px;
  cursor: pointer;

  &:hover {
    color: #212121;
  }
`;
