/* eslint-disable */
import {
  Compatibility,
  ICompatibility,
} from '@apw/stores/compatibility';
import {
  RedirectionRules,
  RedirectionRulesFactory,
} from '@apw/hooks/redirectionRules';
import { getRedirectTarget } from '@apw/core/redirect/redirect.service';
import { rootStore } from '@apw/stores';

export interface RedirectionRulesFactoryData {
  vanityUrl: string;
  queryParams: any,
  currentCompatibility: ICompatibility;
}

export const redirectionRulesFactory: RedirectionRulesFactory<RedirectionRulesFactoryData> = (
  data,
): RedirectionRules => {
  const {vanityUrl, queryParams, currentCompatibility} = data;

  return [
    {
      test: () => {
        const redirectList = getRcMeetingsRedirectUrls();
        return currentCompatibility.slug === rootStore.compatibilitiesStore.rcCompatibility?.slug &&
          redirectList.includes(vanityUrl.trim().toLowerCase());
      },
      getTarget: () => {
        const keyMapping = {};
        Object.keys(queryParams).forEach(key=>keyMapping[key]=key);
        const rcMeetingsProfilePath = `/rc-meetings`;
        return Promise.resolve(getRedirectTarget({
          basePath: rcMeetingsProfilePath,
          keyMapping,
          queryParams,
        }));
      },
    },
  ];
};

const getRcMeetingsRedirectUrls = () => {
  return [
    'rc-meetings.',
    'rc-meetingsy',
    'rc-meetingsfrom',
    'rcmeetings',
    'rc-meetings',
    'rv-meetings',
    'rc-meetings code',
    'ringcentralmeetings',
    'rc-meetingshi',
    'rc-meetingsif',
    'rc-meetings!',
    'rc -meetings',
    'rc-meetings.com',
    'rc-meetingsyou',
    'rc_meetings',
    'rc-meetings\'',
    'rc-meetingsf',
    'rc-meetingsstep',
  ];
}
