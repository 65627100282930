import { useStores } from '@apw/stores';

export const useUserCompatibility = () => {
  const { userStore, compatibilityStore, compatibilitiesStore } = useStores();
  const { isLoggedIn, user } = userStore;

  const currentCompatibility = compatibilityStore.compatibility;
  const userCompatibility =
    compatibilitiesStore.getCompatibilityByIdOrSubBrandId(user?.brandId!);

  return {
    isUserIncompatibleWithCurrentGallery:
      isLoggedIn && currentCompatibility !== userCompatibility,
  };
};
