import { onMobile, useProximaNovaSemibold } from '@apw/core/sc';
import {
  RcAccordion,
  RcAccordionDetails,
  RcAccordionSummary,
  styled,
} from '@ringcentral/juno';

export const Container = styled(RcAccordion)`
  box-shadow: none !important;
  margin: 0 !important;
`;

export const Header = styled(RcAccordionSummary)`
  display: flex;
  text-transform: uppercase;
  color: #282829 !important;
  font-size: 16px;
  line-height: 24px;
  padding: 0 !important;
  min-height: 32px !important;
  height: 32px !important;

  .icon svg {
    font-size: 24px;
  }

  ${onMobile} {
    min-height: 28px !important;
    height: 28px !important;
    font-weight: 600;
    font-size: 18px;
    ${useProximaNovaSemibold}
  }
`;

export const Body = styled(RcAccordionDetails)`
  display: flex;
  flex-direction: column;
  padding: 16px 0 0 !important;
  border-top: none !important;
`;
