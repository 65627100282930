import React, { FC, useState } from 'react';
import defaultBotImage from '@apw/assets/images/default_bot_image.svg';
import { BotLogoStyled, DefaultImage } from './BotLogo.sc';

interface BotLogoProps {
  src: string;
}

export const BotLogo: FC<BotLogoProps> = ({ src }) => {
  const [isDefault, setIsDefault] = useState(false);

  const onBotImageError = () => {
    setIsDefault(true);
  };

  return (
    <BotLogoStyled>
      {!isDefault && (
        <img
          data-test-automation-id="bot-logo"
          src={src}
          onError={onBotImageError}
          alt="Bot Logo"
        />
      )}
      {isDefault && (
        <DefaultImage
          data-test-automation-id="bot-default-logo"
          symbol={defaultBotImage}
        />
      )}
    </BotLogoStyled>
  );
};
