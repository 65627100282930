import React from 'react';
import { Tooltip } from '@apw/components';
import { onMobile } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const Text = styled.span`
  font-size: 16px;
  line-height: 16px;
  color: #666;

  ${onMobile} {
    font-size: 18px;
    color: #282b33;
  }
`;

const StyledTooltip = styled(Tooltip)`
  margin: 0 2px;

  svg {
    color: #666;
    width: 18px;
    height: 18px;
    font-size: 20px;

    ${onMobile} {
      width: 18px;
      height: 18px;
    }
  }
`;

const ToolTipTitle = styled.div`
  ${onMobile} {
    font-size: 15px;
    line-height: 21px;
  }
`;

const brandPartnersHint = `Select a brand partner Gallery to see which apps are available for you to use.`;

export const YourGalleryIndicator = () => {
  return (
    <Wrapper>
      <Text>Gallery</Text>
      <StyledTooltip
        title={<ToolTipTitle>{brandPartnersHint}</ToolTipTitle>}
        automationId="galleryTooltip"
        tabIndex={0}
      />
      <Text>:</Text>
    </Wrapper>
  );
};
