import React, { useEffect, useState } from 'react';
import { BlockHeader } from '../shared';
import { Block, FlexContainer, PercentContainer } from './featureBlock.sc';
import {
  FeatureBlockItem,
  FeatureTypeEnum,
  IFeatureItem,
} from './featureBlockItem';
import { IFeatureBlock } from './typings';

const generateList = (array) => {
  return array.map((item) => {
    return <FeatureBlockItem item={item} />;
  });
};

const FeatureBlock = ({
  data,
  indexInPageContent,
}: {
  data: IFeatureBlock;
  indexInPageContent: number;
}) => {
  const [flexListCountMap] = useState(() => [1, 2, 0, 1, 2, 0, 2, 2, 0]);
  const [flexList, setFlexList] = useState<IFeatureItem[]>([]);
  const [percentList, setPercentList] = useState<IFeatureItem[]>([]);

  const filterValidFeature = (items: IFeatureItem[]) => {
    return items.filter((item) => {
      if (
        item.featureType === FeatureTypeEnum.IndividualApp &&
        !item.profile?.vanityUrlApplicationName
      ) {
        return false;
      }

      return !(
        item.featureType === FeatureTypeEnum.Page && !item.page?.vanityUrl
      );
    });
  };

  useEffect(() => {
    if (!data.items || data.items.length === 0) return;
    const items = filterValidFeature(data.items);
    const flexListCount = flexListCountMap[items.length - 1];
    setFlexList(items.slice(0, flexListCount));
    setPercentList(items.slice(flexListCount));
  }, [data.items]);

  return flexList.length > 0 || percentList.length > 0 ? (
    <Block data-test-automation-id={`feature-block`}>
      <BlockHeader data={data} isH1={indexInPageContent === 0} />
      <FlexContainer data-test-automation-id={'feature-block-flex-list'}>
        {generateList(flexList)}
      </FlexContainer>
      <PercentContainer data-test-automation-id={'feature-block-percent-list'}>
        {generateList(percentList)}
      </PercentContainer>
    </Block>
  ) : null;
};

export { FeatureBlock as GalleryFeatureBlock };
