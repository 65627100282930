import { CountryEnum } from '@apw/core/country';

export const defaultNumberOfEmployees = [
  '1-19',
  '20-99',
  '100-399',
  '400-4999',
  '5000+',
];
const numberOfEmployeesForOthers = ['1-19', '20-99', '100-4999', '5000+'];

export const numberOfEmployeesConfig = {
  [CountryEnum.UK]: numberOfEmployeesForOthers,
  [CountryEnum.AU]: numberOfEmployeesForOthers,
  [CountryEnum.FRANCE]: numberOfEmployeesForOthers,
};
