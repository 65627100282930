import { RootStore } from '@apw/stores';
import { Compatibility } from '@apw/stores/compatibility';
import { prepareCompatibilities } from '@apw/stores/compatibility/compatibilities/compatibilities.service';
import type {
  ICompatibilitiesMapping,
  ICompatibility,
} from '@apw/stores/compatibility/compatibility.interface';
import { includes } from 'lodash';
import { computed, observable, runInAction } from 'mobx';
import { hiddenAppGalleriesService } from './hiddenAppGalleries.service';

const defaultJupiterWebUrl = 'https://app.ringcentral.com';

export class CompatibilitiesStore {
  @observable
  items: ICompatibility[] = [];

  @observable
  useFallback: boolean;

  @computed
  get mapping(): ICompatibilitiesMapping {
    return produceMapping(this.items);
  }

  @computed
  get loading(): boolean {
    return this.items.length === 0;
  }

  @computed
  get rcCompatibility(): ICompatibility | undefined {
    return this.getCompatibilityById(Compatibility.RINGCENTRAL);
  }

  @computed
  get partnerCompatibilities(): ICompatibility[] {
    return this.items
      .filter((c) => c.id !== this.rcCompatibility?.id)
      .filter((c) => {
        const hideItemsWithoutVisiblePages = this.useFallback;

        if (hideItemsWithoutVisiblePages) {
          return this.rootStore.pagesStore.hasVisiblePages(c.id);
        }

        return true;
      });
  }

  constructor(private rootStore: RootStore) {
    this.initStore();

    hiddenAppGalleriesService.initConsoleCommand({
      onStatusChange: () => this.initStore(),
    });
  }

  isSupportedSlug(slug: string | undefined): boolean {
    if (slug === undefined) {
      return false;
    }

    const supportedSlugs = this.items.map((c) => c.slug);
    return supportedSlugs.includes(slug);
  }

  isRCBrand(brandId: string): boolean {
    return brandId === Compatibility.RINGCENTRAL;
  }

  isRisePartnerBrand(brandId = ''): boolean {
    return this.getRisePartnerBrandIds().includes(brandId);
  }

  getCompatibilityFromUrl(pathname: string): ICompatibility | undefined {
    const slug = pathname.split('/')[1];

    if (!this.isSupportedSlug(slug)) {
      return;
    }

    return this.getCompatibilityBySlug(slug);
  }

  getDefaultCompatibility(): ICompatibility {
    const defaultCompatibility = this.items.find((c) => c.isDefault);

    if (!defaultCompatibility) {
      throw new Error(`Default compatibility is not set.`);
    }

    return defaultCompatibility;
  }

  resolve(pathname: string): ICompatibility {
    return (
      this.getCompatibilityFromUrl(pathname) || this.getDefaultCompatibility()
    );
  }

  getCompatibilityById(rawId: string): ICompatibility | undefined {
    return this.items.find((compatibility) => {
      let ids = [compatibility.id];
      if (compatibility.idAliases?.length) {
        ids = [...ids, ...compatibility.idAliases];
      }
      return includes(ids, rawId);
    });
  }

  getCompatibilityByIdOrSubBrandId(
    brandId: string,
  ): ICompatibility | undefined {
    const compatibility = this.getCompatibilityById(brandId);

    if (compatibility) {
      return compatibility;
    }

    return this.items.find((compatibility) => {
      const subBrands = compatibility.subBrands || [];
      const subBrandIds = subBrands.map((subBrand) => subBrand.brandId);
      return subBrandIds.includes(brandId);
    });
  }

  getCompatibilityByValue(rawValue: string): ICompatibility | undefined {
    return this.items.find((compatibility) => {
      let values = [compatibility.value];
      if (compatibility.valueAliases?.length) {
        values = [...values, ...compatibility.valueAliases];
      }
      return includes(values, rawValue);
    });
  }

  getCompatibilityBySlug(slug: string): ICompatibility | undefined {
    return this.items.find((c: ICompatibility) => {
      return c.slug === slug;
    });
  }

  getJupiterWebUrl(brandId: string): string {
    const compatibility = this.getCompatibilityByIdOrSubBrandId(brandId);
    return compatibility?.jupiterWebUrl || defaultJupiterWebUrl;
  }

  private initStore() {
    const enableHiddenAppGalleries = hiddenAppGalleriesService.isEnabled();

    prepareCompatibilities(enableHiddenAppGalleries).then(
      ({ items, useFallback }) => {
        runInAction(() => {
          this.items = items;
          this.useFallback = useFallback;
        });
      },
    );
  }

  private getRisePartnerBrandIds() {
    const risePartnerBrands = this.rcCompatibility!.subBrands;
    return (risePartnerBrands || []).map((brand) => brand.brandId);
  }
}

const produceMapping = (
  compatibilities: ICompatibility[],
): ICompatibilitiesMapping => {
  return compatibilities.reduce((mapping, compatibility) => {
    mapping[compatibility.id] = compatibility;
    return mapping;
  }, {});
};
