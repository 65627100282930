import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { StoreContext } from '@apw/lib/components/profileDetail/StoreContext';
import { RatingPopupStore } from '@apw/lib/components/ratingAndReview/popup/ratingPopup.store';
import { Review } from '@apw/lib/components/ratingAndReview/popup/reviewStep/reviewStep.sc';

interface ReviewStepProps {
  store: RatingPopupStore;
}

const ReviewStepPure = ({ store }: ReviewStepProps) => {
  const { translation } = useContext(StoreContext);
  const handleChange = (e) => {
    const value: string = e.target.value;
    store.setContent(value.slice(0, 1000));
  };

  return (
    <Review
      fullWidth
      minRows={8}
      maxRows={8}
      textVariant="body1"
      label={translation.ratingsAndReviews.popup.reviewTextLabel}
      value={store.content}
      onChange={handleChange}
      data-test-automation-id="rating-popup-review"
    />
  );
};

export const ReviewStep = observer(ReviewStepPure);
