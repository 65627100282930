import * as Yup from 'yup';
import { installBotFormConfig } from './installBotFormConfig';
import { InstallBotFormErrorEnum, InstallBotFormPropsEnum } from './typings';

const setFormatError = (formik) => {
  formik.setErrors({
    [InstallBotFormPropsEnum.BOT_NAME]:
      InstallBotFormErrorEnum.BOT_NAME_FORMAT_ERROR,
  });
};

const setOccupiedError = (formik) => {
  formik.setErrors({
    [InstallBotFormPropsEnum.BOT_NAME]: InstallBotFormErrorEnum.OCCUPIED,
  });
};

const buildForm = () => {
  return {
    initialValues: {
      [InstallBotFormPropsEnum.BOT_NAME]: '',
    },
    validationSchema: formSchema,
    onSubmit: () => {},
  };
};

const handleFormError = (formik, errorCode): boolean => {
  const handleError = {
    [InstallBotFormErrorEnum.BOT_NAME_FORMAT_ERROR]: () =>
      setFormatError(formik),
    [InstallBotFormErrorEnum.BOT_NAME_OCCUPIED]: () => setOccupiedError(formik),
  };

  const handleFn = handleError[errorCode];
  if (!handleFn) {
    return false;
  }

  handleFn(formik);
  return true;
};

const formSchema = Yup.object().shape({
  botName: Yup.string()
    .trim()
    .min(
      installBotFormConfig[InstallBotFormPropsEnum.BOT_NAME].minLength,
      InstallBotFormErrorEnum.BOT_NAME_FORMAT_ERROR,
    )
    .max(
      installBotFormConfig[InstallBotFormPropsEnum.BOT_NAME].maxLength,
      InstallBotFormErrorEnum.BOT_NAME_FORMAT_ERROR,
    )
    .required(InstallBotFormErrorEnum.BOT_NAME_FORMAT_ERROR)
    .matches(
      /^(\s|\d|[a-zA-Z]|\.|-|_)+$/,
      InstallBotFormErrorEnum.BOT_NAME_FORMAT_ERROR,
    ),
});

export const installBotFormService = {
  setFormatError,
  setOccupiedError,
  buildForm,
  handleFormError,
  formSchema,
};
