import { styled } from '@ringcentral/juno';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 24px;
  color: #282829;
  overflow: auto;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    line-height: 26px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  img {
    max-width: 100%;
  }
`;
