import { AppTypeEnum } from '@apw/components/profileDetail/typings';
import { IReviewItem } from '@apw/components/ratingAndReview/typings/reviewItem.interface';
import { platformTypeToAppType } from '@apw/core/application.service';
import { IRatingStateChangeProperties } from '@apw/core/tracker/types/rating-state-change-properties.interface';
import { SearchClickProperties } from '@apw/core/tracker/types/search-click-properties.interface';
import { IProfile } from '@apw/types';
import { Config } from 'mixpanel-browser';
import { Observable } from 'rxjs';
import { GroupAnalyticsKey } from '@drw/common';
import { TrackedEvent } from '../tracked-event.enum';
import {
  BigBlueButtonClickedProperties,
  ContactUsPromptProperties,
  ContactUsSubmittedProperties,
  MediumEnum,
} from '../types';
import {
  identify,
  initialize,
  isIgnored,
  register,
  reset,
  setGroup,
  track,
} from './mixpanel-core.service';

export const init = (token: string, config?: Partial<Config>) => {
  initialize(token, config);
};

export const identifyUser = (rcExtensionId: string) => {
  identify(rcExtensionId);
};

export const setHashedAccountId = (hashedAccountId: string) => {
  setGroup(GroupAnalyticsKey.HASHED_RC_ACCOUNT_ID, [hashedAccountId]);
};

export const registerSuperProperties = (superProperties: object) => {
  register(superProperties);
};

export const isTrackingIgnored = () => {
  return isIgnored();
};

export const resetMixpanel = () => {
  reset();
};

export const trackPageView = (
  pageName: string,
  moreInfo?: Object,
): Observable<any> => {
  return track(TrackedEvent.PAGEVIEWS, {
    'Page Name': pageName,
    Medium: MediumEnum.WEB,
    // if "App Type" has value, it will be overwritten by the property in the moreInfo
    'App Type': '',
    ...(moreInfo || {}),
  });
};

export const trackBigBlueButtonClicked = (
  properties: BigBlueButtonClickedProperties,
): Observable<any> => {
  const params = {
    'Button Text': properties.buttonText,
    'Button State': properties.buttonStatus,
    'Logged In State': properties.loggedInState,
    'App Name': properties.appName,
    'Profile Name': properties.profileName,
    'App ID': properties.appId,
    'App Type': properties.appType,
    Brand: properties.brandPartner,
    'Org Type': properties.orgType,
    'Org ID': properties.orgId,
    'App Display Name': properties.appDisplayName,
    Medium: MediumEnum.WEB,
  };

  return track(TrackedEvent.AG_BIG_BLUE_BUTTON_CLICKED, params);
};

export const trackContactUsPrompt = (
  properties: ContactUsPromptProperties,
): Observable<any> => {
  return track(TrackedEvent.AG_CONTACT_US_PROMPT, {
    'App ID': properties.appId,
    'Profile ID': properties.profileId,
    'Profile Name': properties.profileName,
    Brand: properties.brandPartner,
    'App Name': properties.appName,
    'App Display Name': properties.appDisplayName,
    'App Type': properties.appType,
    Medium: MediumEnum.WEB,
  });
};

export const trackContactUsSubmitted = (
  properties: ContactUsSubmittedProperties,
): Observable<any> => {
  return track(TrackedEvent.AG_CONTACT_US_SUBMITTED, {
    'App ID': properties.appId,
    'Profile ID': properties.profileId,
    'Profile Name': properties.profileName,
    Brand: properties.brandPartner,
    'Click Path': properties.clickPath,
    'App Name': properties.appName,
    'App Display Name': properties.appDisplayName,
    'App Type': properties.appType,
    Medium: MediumEnum.WEB,
  });
};

export const trackAuthorizeBot = (
  profile: IProfile,
  brandName: string,
): Observable<any> => {
  return track(TrackedEvent.AUTHORIZE_BOT, {
    Medium: MediumEnum.WEB,
    'App Type': AppTypeEnum.BOT,
    'App Display Name': profile.applicationDisplayName || '',
    'Profile Name': profile.appName || '',
    'App ID': profile.appId || '',
    'Org Type': profile.orgType || '',
    Brand: brandName || '',
  });
};

export const trackInstallBot = (
  profile: IProfile,
  brandName: string,
): Observable<any> => {
  return track(TrackedEvent.INSTALL_BOT, {
    Medium: MediumEnum.WEB,
    'App Type': AppTypeEnum.BOT,
    'App Display Name': profile.applicationDisplayName || '',
    'Profile Name': profile.appName || '',
    'App ID': profile.appId || '',
    'Org Type': profile.orgType || '',
    Brand: brandName || '',
  });
};

export const trackRemoveBot = (
  profile: IProfile,
  brandName: string,
): Observable<any> => {
  return track(TrackedEvent.REMOVE_BOT, {
    Medium: MediumEnum.WEB,
    'App Type': AppTypeEnum.BOT,
    'App Display Name': profile.applicationDisplayName || '',
    'Profile Name': profile.appName || '',
    'App ID': profile.appId || '',
    'Org Type': profile.orgType || '',
    Brand: brandName || '',
  });
};

export const trackRatingStateChange = (
  properties: IRatingStateChangeProperties,
): Observable<any> => {
  return track(TrackedEvent.RATING_STATE_CHANGE, {
    'App ID': properties.appId,
    'App Name': properties.appName,
    'App Type': properties.appType,
    'App Display Name': properties.appDisplayName,
    Brand: properties.brand,
    'Org ID': properties.orgId,
    'Org Type': properties.orgType,
    'Profile Name': properties.profileName,
    Medium: properties.medium,
    'Request date': properties.requestDate,
    'Modified date': properties.modifiedDate,
    'Old state': properties.oldState,
    'New state': properties.newState,
    'Rating: overall': properties.overallRating,
    'Rating: value': properties.valueRating,
    'Rating: ease of use': properties.convenienceRating,
    'Rating: functionality': properties.featureRating,
    'Rating: support': properties.supportRating,
    Reviews: properties.reviews,
  });
};

export const trackSearchBehavior = (
  type: string,
  value: string,
): Observable<any> => {
  return track(TrackedEvent.SEARCH_BEHAVIOR, {
    Type: type,
    Value: value,
    'App Type': '',
    Medium: MediumEnum.WEB,
  });
};

export const trackAction = (
  pageName: string,
  actionName: string,
  module?: string,
): Observable<any> => {
  return track(TrackedEvent.ACTIONS, {
    'Page Name': pageName,
    'Action Name': actionName,
    Module: module,
  });
};

export const trackSearchClick = (
  properties: SearchClickProperties,
): Observable<any> => {
  const params = {
    ...(properties.productFilter && {
      'Product filter': properties.productFilter,
    }),
    ...(properties.categoryFilter && {
      'Category filter': properties.categoryFilter,
    }),
    ...{
      Brand: properties.brand,
      'Search term': properties.searchTerm,
      'Destination URL': properties.destinationURL,
      'Result type': properties.resultType,
      'Click depth': properties.clickDepth,
      'Result count': properties.resultCount,
      Source: properties.source,
    },
  };
  return track(TrackedEvent.SEARCH_CLICK, params);
};

export const trackGetSupportClicked = (
  properties: ContactUsPromptProperties,
): Observable<any> => {
  return track(TrackedEvent.AG_Get_SUPPORT, {
    'App ID': properties.appId,
    'Profile ID': properties.profileId,
    'Profile Name': properties.profileName,
    Brand: properties.brandPartner,
    'App Name': properties.appName,
    'App Display Name': properties.appDisplayName,
    'App Type': properties.appType,
    Medium: MediumEnum.WEB,
  });
};

export const trackRatingForPending = (
  profile: IProfile,
  review: IReviewItem,
) => {
  return track(TrackedEvent.RATING_PENDING, {
    'App ID': profile.appId,
    'App Name': profile.applicationName,
    'App Type': platformTypeToAppType(profile.applicationType),
    'App Display Name': profile.applicationDisplayName,
    Brand: profile.brand,
    'Org ID': profile.orgId,
    'Org Type': profile.orgType,
    'Profile Name': profile.appName,
    Medium: MediumEnum.WEB,
    'Request date': review.creationTime,
    'Rating: overall': review.overallRating,
    'Rating: value': review.valueRating,
    'Rating: ease of use': review.convenienceRating,
    'Rating: functionality': review.featureRating,
    'Rating: support': review.supportRating,
    Reviews: review.content,
  });
};
