import { RcIcon, RcLink, styled } from '@ringcentral/juno';

export const Link = styled(RcLink)`
  display: inline-flex;
  align-items: flex-end;
`;

export const LabelStyled = styled.span`
  line-height: 1;
`;

export const IconStyled = styled(RcIcon)`
  margin-left: 8px;
`;
