import React, { FC } from 'react';
import { IPageCard } from '@apw/shared/coveoSearch/shared/pageCard/page.interface';
import {
  applyProximaNovaSemibold,
  applyProximaNovaRegular,
} from '@apw/shared/theme/utils/typography';
import { styled } from '@ringcentral/juno';
import { Link } from 'react-router-dom';

const oneLineStyles = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 `;

const PageTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: rgb(0, 17, 56);
  letter-spacing: 0.25px;
  line-height: 26px;
  ${oneLineStyles};
  ${applyProximaNovaSemibold()}
`;

const PageDescription = styled.div`
  font-size: 14px;
  color: rgb(104, 104, 105);
  line-height: 20px;
  ${oneLineStyles};
  ${applyProximaNovaRegular()}
`;

const PageInfoContainer = styled.div`
  flex-grow: 1;
  margin-left: 12px;
  min-width: 0;
`;

const PageIcon = styled.img`
  max-height: 100%;
`;

const PageIconContainer = styled.div`
  width: 48px;
  height: 48px;
  background: rgb(248, 248, 248);
  border-radius: 12px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-content: center;
`;

const cardContainerStyles = `
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 16px 20px;

  &:hover {
    background: #f5f6f7;
  }
`;

const PageLink = styled(Link)`
  ${cardContainerStyles}
`;

export const PageCard: FC<IPageCard> = (props) => {
  return (
    <PageLink to={props.path} data-test-automation-id="page-link">
      <PageIconContainer>
        {props.imageUrl && (
          <PageIcon
            src={props.imageUrl}
            alt={`${props.name} image`}
            data-test-automation-id="page-icon"
          />
        )}
      </PageIconContainer>
      <PageInfoContainer>
        <PageTitle title={props.name} data-test-automation-id="page-title">
          {props.name}
        </PageTitle>
        {props.description && (
          <PageDescription
            title={props.description}
            data-test-automation-id="page-description"
          >
            {props.description}
          </PageDescription>
        )}
      </PageInfoContainer>
    </PageLink>
  );
};
