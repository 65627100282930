import { IOpenGraphTag, ITwitterCardTag, sanitize } from '@apw/components';
import {
  IProcessedProfile,
  IProcessLinks,
} from '@apw/components/profileDetail';
import { getDownloadUrlForSeo } from '@apw/components/profileDetail/profileHeader/ctaButton/appDownloadButton/appDownloadService';
import { transport } from '@apw/core';
import { get } from '@apw/core/api/api.service';
import { getInstallUrlForSeo } from '@apw/modules/botProvision/shared/bot-installer.service';
import { profileProcessor } from '@apw/modules/profile/shared/profileProcessor';
import { rootStore } from '@apw/stores';
import { AppDownloadLink, IProfile } from '@apw/types';
import * as _ from 'lodash';
import moment from 'moment';
import { zip } from 'rxjs';
import { map } from 'rxjs/operators';

export const loadProfile = (
  vanityUrl: string,
  useGlobalLoading?: boolean,
): Promise<IProfile> => {
  const endpoint = `/api/app/${vanityUrl}`;
  return get<IProfile>(endpoint, {
    useGlobalLoading: useGlobalLoading === undefined ? true : useGlobalLoading,
  });
};

const buildEndpointForLoadProfileByBrand = (
  vanityUrl: string,
  brand: string,
) => {
  return `/api/app/${vanityUrl}/brand/${brand}`;
};

export const loadProfileByBrand = (
  vanityUrl: string,
  brand: string,
  useGlobalLoading?: boolean,
): Promise<IProfile> => {
  const endpoint = buildEndpointForLoadProfileByBrand(vanityUrl, brand);
  return get<IProfile>(endpoint, {
    useGlobalLoading: useGlobalLoading === undefined ? true : useGlobalLoading,
  });
};

export enum ProfileLoadingError {
  PROFILE_NOT_FOUND,
  FAILED_TO_LOAD,
}

const decodeSanitizedValue = (value) => {
  if (_.isString(value)) {
    return value.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  }

  if (_.isArray(value)) {
    return value.map((v) => decodeSanitizedValue(v));
  }

  if (_.isObject(value)) {
    _.forIn(value, (v, k) => {
      value[k] = decodeSanitizedValue(v);
    });
    return value;
  }

  return value;
};

export const resolveProfileFromState = (
  vanityUrl: string,
  brand: string,
): Promise<IProcessedProfile> => {
  try {
    const cachedProfileKey = buildEndpointForLoadProfileByBrand(
      vanityUrl,
      brand,
    );

    const cachedProfile = window.__APW_STATE__[cachedProfileKey];

    window.__APW_STATE__[cachedProfileKey] = null;

    if (cachedProfile) {
      const decodedProfile = decodeSanitizedValue(cachedProfile);
      const profile = profileProcessor(decodedProfile);

      return profile ? Promise.resolve(profile) : Promise.reject();
    }

    return Promise.reject();
  } catch (e) {
    return Promise.reject();
  }
};

export const resolveProfile = (
  vanityUrl: string,
  brand: string,
): Promise<IProcessedProfile> => {
  return Promise.resolve()
    .then(() => resolveProfileFromState(vanityUrl, brand))
    .then((profile) => profile)
    .catch(() => {
      return transport.fetchProfileByBrand(vanityUrl, brand).then((res) => {
        if (res) {
          return profileProcessor(res);
        }

        return Promise.reject(ProfileLoadingError.PROFILE_NOT_FOUND);
      });
    });
};

export const buildSeoElementForApp = (app: IProfile) => {
  const externalDependencies = zip(
    getDownloadUrlForSeo(app),
    getInstallUrlForSeo(app),
  );

  return externalDependencies.pipe(
    map(([downloadUrl, installUrl]) => {
      const categories = (app.category || '').split(';');
      const appCategoryList = categories.map((category) => sanitize(category));
      const supportURLs = _.compact([
        sanitize(_.get(app, 'support.documentLink')),
        sanitize(_.get(app, 'support.wikiLink')),
        sanitize(_.get(app, 'support.communityLink')),
      ]);

      const seoConfig = {
        '@context': 'http://schema.org/',
        '@type': 'SoftwareApplication',
        name: sanitize(app.appName),
        applicationCategory:
          _.size(appCategoryList) === 1
            ? _.first(appCategoryList)
            : appCategoryList,
        description: sanitize(app.shortDes),
        image: app.iconUrl,
        downloadUrl: sanitize(downloadUrl),
        installUrl: sanitize(installUrl),
        datePublished: moment(app.releaseTime).format('MMMM y'),
        sameAs: _.isEmpty(supportURLs)
          ? undefined
          : _.size(supportURLs) === 1
          ? _.first(supportURLs)
          : supportURLs,
        screenshot:
          _.size(app.screenshots) === 1
            ? _.first(app.screenshots)
            : app.screenshots,
        offers: {
          '@type': 'Offer',
          category: 'free',
          priceCurrency: 'USD',
          price: '0',
        },
        author: {
          '@type': 'Person',
          name: sanitize(app.publisher),
          url: sanitize(app.publisherLink),
          email: sanitize(_.get(app, 'support.email')),
          telephone: sanitize(_.get(app, 'support.contactPhone')),
        },
      };

      return JSON.stringify(seoConfig, null, 4);
    }),
  );
};

export const getAppPageTitle = (item: IProcessedProfile) => {
  return item.h1Tag || item.appName;
};

export const getOgTagData = (item: IProcessedProfile): IOpenGraphTag => {
  return {
    title: item.appName,
    type: 'website',
    image: getLogoUrl(item),
    url: `https://www.ringcentral.com/apps/${getCanonicalLink(item)}`,
    description: item.shortDes,
    site_name: 'RingCentral App Gallery',
  };
};

export const getTwitterTagData = (item: IProcessedProfile): ITwitterCardTag => {
  return {
    card: 'summary_large_image',
    title: item.appName,
    image: getLogoUrl(item),
    description: item.shortDes,
    site: '@ringcentraldevs',
    creator: '@ringcentraldevs',
  };
};

export const getCanonicalLink = (item: IProcessedProfile) => {
  const { vanityUrl } = item;
  const profileBrandSlug = rootStore.compatibilitiesStore.getCompatibilityById(
    item.brand,
  )?.slug;

  if (profileBrandSlug) {
    return `${profileBrandSlug}/${vanityUrl}`;
  }
  return vanityUrl;
};

export const getLogoUrl = (item: IProcessedProfile): string => {
  return item.iconUrl || (item.logos && item.logos[0]) || '';
};

export const buildHandlebarsProfile = (
  profile: IProcessedProfile,
  handlebars: Function,
): IProcessedProfile => {
  const handlebarsHelpfulLinks = (links: IProcessLinks[]) => {
    return links.map((item: IProcessLinks) => ({
      ...item,
      link: handlebars(item.link),
    }));
  };

  const handlebarsDownloadLinks = (links: AppDownloadLink[]) => {
    return links.map((item: AppDownloadLink) => ({
      ...item,
      link: handlebars(item.link),
      desc: handlebars(item.desc),
    }));
  };

  const handlebarsResourceLinks = (links: IProcessLinks[]) => {
    return links.map((item: IProcessLinks) => ({
      ...item,
      label: handlebars(item.label),
      link: handlebars(item.link),
    }));
  };

  return {
    ...profile,
    ...(profile.appName && { appName: handlebars(profile.appName) }),
    ...(profile.h1Tag && { h1Tag: handlebars(profile.h1Tag) }),
    ...(profile.detailedDes && {
      detailedDes: handlebars(profile.detailedDes),
    }),
    ...(profile.publisherLink && {
      publisherLink: handlebars(profile.publisherLink),
    }),
    ...(profile.helpfulLinks && {
      helpfulLinks: handlebarsHelpfulLinks(profile.helpfulLinks),
    }),
    ...(profile.processResourceLinks && {
      processResourceLinks: handlebarsResourceLinks(
        profile.processResourceLinks,
      ),
    }),
    ...(profile.downloadLinks && {
      downloadLinks: handlebarsDownloadLinks(profile.downloadLinks),
    }),
    ...(profile.vanityUrl && {
      vanityUrl: handlebars(profile.vanityUrl),
    }),
  };
};
