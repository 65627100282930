import { overridesFactory } from '@apw/shared/theme/utils/overrides.factory';
import { paletteFactory } from '@apw/shared/theme/utils/palette.factory';
import { RcThemeInput } from '@ringcentral/juno';

export const rcColor = '#066fac';

export const rcTheme: RcThemeInput = {
  palette: paletteFactory(rcColor),
  overrides: overridesFactory(rcColor),
};
