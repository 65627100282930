import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from 'react';
import { updateParams } from '../../../../../../../../services/url/url.service';
import { VideoSlideRef } from '../video.interface';
import { urlPatterns } from './vimeo.utils';

interface Props {
  src: string;
  autoPlay?: boolean;
}

export const Vimeo = React.forwardRef<VideoSlideRef, Props>(
  ({ src, autoPlay = false }, ref) => {
    const [updateState, forceUpdate] = useReducer((x) => x + 1, 0);
    useImperativeHandle<any, VideoSlideRef>(ref, () => ({
      forceUpdate,
    }));

    const [vimeoUrl, setVimeoUrl] = useState('');

    const buildIframeUrl = (videoId: string): string => {
      return `https://player.vimeo.com/video/${videoId}`;
    };

    const getVideoId = (url: string): string => {
      let videoId = '';

      for (const pattern of urlPatterns) {
        const matches = url.match(pattern);

        if (matches) {
          videoId = matches[1];
          break;
        }
      }
      return videoId;
    };

    const resolve = (url: string) => {
      const videoId = getVideoId(url);
      let iframeUrl = buildIframeUrl(videoId);

      if (autoPlay) {
        iframeUrl = updateParams(iframeUrl, { autoplay: 1 });
      }
      setVimeoUrl(iframeUrl);
    };

    useEffect(() => {
      resolve(src);
    }, [src]);

    return (
      // eslint-disable-next-line jsx-a11y/iframe-has-title
      <iframe
        key={updateState}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        src={vimeoUrl}
        width="100%"
        height="100%"
        data-test-automation-id="carouselVimeo"
        title={`vimeo video - ${vimeoUrl}`}
      />
    );
  },
);
