import React, { useContext } from 'react';
import { ProfileContext } from '@apw/components/profileDetail/profile.context';
import { Container } from '@apw/components/profileDetail/profileHeader/ctaButton/ctaButton.sc';
import { useProfileType } from '@apw/modules/profile';
import { AppDownloadButton } from './appDownloadButton';
import { BotManageButton } from './botManageButton';
import { MnaInstallButton } from './mnaInstallButton';

export const CtaButton = ({ className = '' }) => {
  const profile = useContext(ProfileContext);
  const { isRegularApp, isMna, isBot } = useProfileType(profile);

  return (
    <Container
      className={`${className}`}
      data-test-automation-id={`cta-button-container`}
    >
      {isBot && <BotManageButton profile={profile} />}
      {isMna && <MnaInstallButton />}
      {isRegularApp && <AppDownloadButton />}
    </Container>
  );
};
