import React, { useState } from 'react';
import { useStores } from '@apw/stores';
import {
  ArrowDown,
  ArrowUp,
  Logout,
  MemberBorder,
} from '@ringcentral/juno-icon';
import { observer } from 'mobx-react';
import {
  ArrowIconButton,
  Container,
  SignOutIcon,
  SignOutMenuItem,
  UserIcon,
  UserInfo,
  UserMenu,
  UserName,
} from './LoginStatus.sc';

export const LoginStatusPure = () => {
  const { userStore } = useStores();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleLogout = () => {
    userStore.logout();
  };

  return (
    <Container data-test-automation-id="headerLoginStatus">
      <UserInfo>
        <UserIcon symbol={MemberBorder} size="medium" />
        <UserName
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          aria-label={`current logged in user is ${userStore.extension?.name}`}
        >
          {userStore.extension?.name}
        </UserName>
        <ArrowIconButton
          symbol={open ? ArrowUp : ArrowDown}
          onClick={handleClick}
          size="small"
          aria-label="toggle dropdown"
          aria-expanded={open}
        />
      </UserInfo>
      <UserMenu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <SignOutMenuItem onClick={handleLogout}>
          <SignOutIcon size="medium" symbol={Logout} />
          Sign out
        </SignOutMenuItem>
      </UserMenu>
    </Container>
  );
};

export const LoginStatus = observer(LoginStatusPure);
