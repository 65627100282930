import React, { FC, useContext } from 'react';
import {
  Container,
  CountInfo,
  RatingContainer,
  Score,
  StyledRating,
} from '@apw/lib/components/profileDetail/profileBody/ratingsAndReviewsTab/ratingsSection/content/basicInfo/basicInfo.sc';
import { StoreContext } from '@apw/lib/components/profileDetail/StoreContext';

export interface IBasicInfo {
  overallRating: number;
  voteCount: number;
}

export const BasicInfo: FC<IBasicInfo> = ({ overallRating, voteCount }) => {
  const { translation } = useContext(StoreContext);
  return (
    <Container data-test-automation-id={'rating-and-review-tab-basic-info'}>
      <Score
        variant="display3"
        color="neutral.f06"
        data-test-automation-id={'rating-and-review-tab-score'}
        tabIndex={0}
      >
        {overallRating.toFixed(1)}
      </Score>
      <RatingContainer>
        <StyledRating
          rating={overallRating}
          translation={translation.ratingsAndReviews.basicInfo}
        />
        <CountInfo
          variant="subheading1"
          color="neutral.f04"
          data-test-automation-id={'rating-and-review-tab-rating-count'}
          tabIndex={0}
        >
          {translation.ratingsAndReviews.basicInfo.countLabelPrefix} {voteCount}{' '}
          {voteCount > 1
            ? translation.ratingsAndReviews.basicInfo.countLabel.plural
            : translation.ratingsAndReviews.basicInfo.countLabel.singular}
        </CountInfo>
      </RatingContainer>
    </Container>
  );
};
