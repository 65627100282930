import { transport } from '@apw/core';
import { RootStore } from '@apw/stores';
import { GalleryPageSection } from '@apw/stores/pages/galleryPageSection.enum';
import { IPagesForAllBrands, PageListItem, PagesInterface } from '@apw/types';
import { action, computed, observable, runInAction } from 'mobx';
import { CollectionLoadListItemInterface } from './collection-load-list-item.interface';

type VanityUrlToPageMapping = { [vanityUrl: string]: PageListItem };
type PageIdToPageMapping = { [pageId: string]: PageListItem };

const resolveVisibleSections = (): any[] => {
  return [
    GalleryPageSection.DISCOVER_APPS,
    GalleryPageSection.PRODUCTS,
    GalleryPageSection.CATEGORIES,
  ];
};

export class PagesStore {
  private readonly rootStore: RootStore;

  @observable
  pagesForAllBrands: IPagesForAllBrands = {};

  @computed
  get visiblePagesStatus(): Record<string, boolean> {
    return this.getVisiblePagesStatus(this.pagesForAllBrands);
  }

  hasVisiblePages(brandId: string): boolean {
    return this.visiblePagesStatus[brandId];
  }

  @computed
  get pages(): PagesInterface {
    const brandId = this.rootStore.compatibilityStore.brandId;
    return this.pagesForAllBrands[brandId] || {};
  }

  @observable
  isLoading = false;

  @observable
  collectionLoadList: CollectionLoadListItemInterface[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.initialize();
  }

  initialize() {
    this.isLoading = true;

    // Wait until `transport` is available
    setTimeout(() => {
      let pagesForAllBrands = {};

      const request = transport.fetchGalleryPagesForAllBrands();

      request
        .then((res) => {
          pagesForAllBrands = res.pages || {};
        })
        .finally(() => {
          runInAction(() => {
            this.pagesForAllBrands = pagesForAllBrands;
            this.isLoading = false;
          });
        });
    });
  }

  @action
  addLoadListItem = (item: CollectionLoadListItemInterface) => {
    this.collectionLoadList.push(item);
  };

  @action
  clearLoadList = () => {
    this.collectionLoadList = [];
  };

  @computed
  get allLoadList(): CollectionLoadListItemInterface[] {
    return this.collectionLoadList;
  }

  @computed
  get vanityUrlToPage(): VanityUrlToPageMapping {
    return this.getVanityUrlToPage(this.pages);
  }

  @computed
  get pageIdToPage(): PageIdToPageMapping {
    return this.getPageIdToPage(this.pages);
  }

  @computed
  get firstPage(): PageListItem | null {
    return this.getFirstPage(this.pages);
  }

  getVanityUrlToPage(pages: PagesInterface): VanityUrlToPageMapping {
    return Object.keys(pages).reduce((accumulated, sectionName) => {
      const sectionPages = pages[sectionName];

      if (sectionPages && sectionPages.length > 0) {
        sectionPages.forEach((page) => {
          accumulated[page.vanityUrl] = page;
        });
      }

      return accumulated;
    }, {});
  }

  getPageIdToPage(pages: PagesInterface): PageIdToPageMapping {
    return Object.keys(pages).reduce((accumulated, sectionName) => {
      const sectionPages = pages[sectionName];

      if (sectionPages && sectionPages.length > 0) {
        sectionPages.forEach((page) => {
          accumulated[page.pageId] = page;
        });
      }

      return accumulated;
    }, {});
  }

  getFirstPage(pages: PagesInterface): PageListItem | null {
    const visibleSections = resolveVisibleSections();

    for (const sectionName of visibleSections) {
      const sectionPages = pages[sectionName];

      if (sectionPages && sectionPages.length > 0) {
        return sectionPages[0];
      }
    }

    return null;
  }

  getVisiblePagesStatus(
    pagesForAllBrands: IPagesForAllBrands,
  ): Record<string, boolean> {
    const brandIds = Object.keys(pagesForAllBrands);
    const visiblePagesStatus = {};
    const visibleSections = resolveVisibleSections();

    for (const id of brandIds) {
      const pages = pagesForAllBrands[id] || {};
      let flattenPages: PageListItem[] = [];

      flattenPages = visibleSections.reduce((accumulated, sectionName) => {
        const sectionPages = pages[sectionName] || [];
        return [...accumulated, ...sectionPages];
      }, flattenPages);

      visiblePagesStatus[id] = flattenPages.length > 0;
    }

    return visiblePagesStatus;
  }
}
