import React, { FC } from 'react';
import { RcTypography } from '@ringcentral/juno';
import { MultipleLineEllipsis } from '@apw/lib/components/multipleLineEllipsis';
import {
  IContentBlockTypography,
  IProfileInContentBlock,
} from '@apw/lib/types';

interface IProfileNameProps {
  profile: Partial<IProfileInContentBlock>;
  styleTokens: IContentBlockTypography;
  [x: string]: any;
}

export const ProfileName: FC<IProfileNameProps> = ({
  profile,
  styleTokens,
  ...others
}) => {
  const name = profile.application?.displayName || profile.name;

  return (
    <RcTypography
      component="div"
      {...styleTokens}
      title={name}
      data-test-automation-id="app-tile-name"
      {...others}
    >
      <MultipleLineEllipsis linesToDisplay={2} lineHeightPx={24}>
        {name}
      </MultipleLineEllipsis>
    </RcTypography>
  );
};
