import { onMobile, useProximaNovaBold } from '@apw/core/sc';
import {
  styled,
  RcDialogTitle,
  RcIconButton,
  RcDialogContent,
  RcButton,
} from '@ringcentral/juno';

export const DialogTitle = styled(RcDialogTitle)`
  margin-top: 32px !important;
  padding: 0 48px !important;

  h2 {
    ${useProximaNovaBold};
    white-space: normal !important;
  }
`;

export const DialogCloseButton = styled(RcIconButton)`
  position: absolute !important;
  top: 8px !important;
  right: 8px !important;

  svg {
    font-size: 20px;
  }
`;

export const DialogContent = styled(RcDialogContent)`
  padding: 24px 40px !important;

  ${onMobile} {
    padding: 0 15px 20px !important;
  }
`;

export const DialogContentMain = styled.div`
  background: #f9f9f9;
  text-align: center;
`;

export const ActionButtonContainer = styled.div`
  padding: 16px 20px 20px;
  ${onMobile} {
    padding: 20px 15px 25px !important;
  }
`;

export const ActionButtonHeader = styled.div`
  margin: 0;
  font-size: 15px;

  span {
    vertical-align: middle;
  }

  br {
    display: none;
  }

  ${onMobile} {
    font-size: 16px;
    line-height: 24px;
    br {
      display: block;
    }
  }
`;

export const ActionButton = styled(RcButton)`
  ${useProximaNovaBold};
  font-size: 14px !important;
  width: 155px !important;
  margin-top: 20px !important;

  ${onMobile} {
    width: 100% !important;
    height: 40px !important;
    font-size: 17px !important;
  }
`;
