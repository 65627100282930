import React, { useContext } from 'react';
import { CoveoSearchContext } from '@apw/shared/coveoSearch/CoveoSearch.context';
import { PageCard } from '@apw/shared/coveoSearch/shared';
import IpaasCard from '@apw/shared/coveoSearch/shared/ipaasCard/IpaasCard';
import { IPageCard } from '@apw/shared/coveoSearch/shared/pageCard/page.interface';
import ProfileCard from '@apw/shared/coveoSearch/shared/profileCard/ProfileCard';
import { IProfileCard } from '@apw/shared/coveoSearch/shared/profileCard/profileCard.interface';
import Section from '@apw/shared/coveoSearch/shared/section/Section';
import { SearchResult, SearchResultType } from '@apw/shared/searchTracking';
import { IpaasListItem } from '@apw/types';
import { RcLoading } from '@ringcentral/juno';

export interface ContentViewProps {
  profileSectionTitle: string;
  profiles: Array<IProfileCard | IpaasListItem>;
  pageSectionTitle: string;
  pages: IPageCard[];
  handleClick?: (
    clickLink: string,
    resultType: SearchResultType,
    clickDepth: number,
  ) => void;
  loading: boolean;
}

const ContentView = ({
  profileSectionTitle,
  profiles,
  pageSectionTitle,
  pages,
  handleClick,
  loading,
}: ContentViewProps) => {
  const coveoSearchContext = useContext(CoveoSearchContext);
  const onHandleClick = (
    clickLink: string,
    resultType: SearchResultType,
    resultDepth: number,
  ) => {
    if (resultType !== SearchResult.IPAAS) {
      coveoSearchContext.handleClose();
    }
    if (handleClick) {
      handleClick(clickLink, resultType, resultDepth + 1);
    }
  };

  const checkItemIsIpaas = (item) => {
    return !!item.thirdPartyAppName;
  };

  const getItemUrl = (item) => {
    return item.path || item.vanityUrl || (item as IpaasListItem).url;
  };

  if (loading) {
    return (
      <RcLoading loading>
        <span />
      </RcLoading>
    );
  }

  return (
    <>
      {profiles && profiles.length > 0 && (
        <Section title={profileSectionTitle} isH1>
          {profiles.map((item, index) => (
            <div
              role="presentation"
              key={getItemUrl(item)}
              onClick={() =>
                onHandleClick(
                  checkItemIsIpaas(item)
                    ? (item as IpaasListItem).url
                    : (item as IProfileCard).path,
                  checkItemIsIpaas(item)
                    ? SearchResult.IPAAS
                    : SearchResult.PROFILE,
                  index,
                )
              }
              data-test-automation-id={`search-profile-result-${index}`}
            >
              {checkItemIsIpaas(item) ? (
                <IpaasCard app={item as IpaasListItem} />
              ) : (
                <ProfileCard app={item as IProfileCard} />
              )}
            </div>
          ))}
        </Section>
      )}
      {pages && pages.length > 0 && (
        <Section
          title={pageSectionTitle}
          isH1={!profiles || profiles.length === 0}
        >
          {pages.map((page, index) => (
            <div
              role="presentation"
              key={page.path}
              onClick={() => onHandleClick(page.path, SearchResult.PAGE, index)}
              data-test-automation-id={`search-page-result-${index}`}
            >
              <PageCard {...page} />
            </div>
          ))}
        </Section>
      )}
    </>
  );
};

export default ContentView;
