import { useCallback } from 'react';
import { decodeBrand } from '@apw/core';
import { useStores } from '@apw/stores';
import { BrandId2ShortNameMapping } from '@apw/stores/compatibility/compatibilities/galleryBrand';
import Handlebars from 'handlebars';
import * as _ from 'lodash';

Handlebars.registerHelper({
  uppercase: (str: string) => str.toUpperCase(),
  lowercase: (str: string) => str.toLowerCase(),
  eq: (v1, v2) => v1 === decodeBrand(v2),
  ne: (v1, v2) => v1 !== decodeBrand(v2),
  replace: (str, pattern, replacement) => str.replace(pattern, replacement),
  replaceAll: (str, pattern, replacement) => {
    const reg = new RegExp(pattern, 'g');
    return str.replace(reg, replacement);
  },
});

const usePartnerHandlebars = (brandId?: string) => {
  const { compatibilityStore } = useStores();

  if (brandId) {
    compatibilityStore.setCompatibilityById(brandId);
  }

  const temp = useCallback(
    (str: any) => {
      const compatibility = compatibilityStore.compatibility;
      const data = {
        partner: {
          id: compatibility.id,
          short_name:
            BrandId2ShortNameMapping[compatibility.id] || compatibility.id,
          full_name: compatibility.name,
          url_slug: compatibility.slug,
          domain: compatibility.jupiterWebUrl,
          gallery_name: compatibility.galleryName,
        },
      };
      const template = Handlebars.compile(str, { noEscape: true });
      return template(data);
    },
    [compatibilityStore],
  );

  return (input: any) => {
    if (_.isEmpty(input)) {
      return input;
    }

    try {
      if (typeof input === 'string') {
        return temp(input);
      }

      if (_.isObject(input)) {
        const str = JSON.stringify(input).replace(/\\"/g, '"');
        return JSON.parse(temp(str));
      }

      return input;
    } catch (e) {
      return input;
    }
  };
};

export default usePartnerHandlebars;
