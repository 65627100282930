import React, { FC } from 'react';
import { Content } from '@apw/components/profileDetail/profileBody/ratingsAndReviewsTab/ratingsSection/content/Content';
import { Header } from '@apw/components/profileDetail/profileBody/ratingsAndReviewsTab/ratingsSection/header/Header';
import { IAppRating } from '@apw/types';

export interface IRatingsSectionProps {
  ratings: IAppRating;
}

export const RatingsSection: FC<IRatingsSectionProps> = ({ ratings }) => {
  return (
    <>
      <Header />
      <Content ratings={ratings} />
    </>
  );
};
