import { ICountry, IState } from '@apw/core/country';
import { countriesRequireState } from '@apw/core/country/countriesRequireState';
import { companyTypes } from '@apw/modules/profile/shared/companyTypes';
import {
  defaultNumberOfEmployees,
  numberOfEmployeesConfig,
} from '@apw/modules/profile/shared/numberOfEmployees';
import { SelectOption } from '@apw/modules/profile/shared/selectOption.interface';
import * as _ from 'lodash';
import { userFormConfig, userFormSchema } from './userForm.config';

const buildSelectionOption = (options: string[]): SelectOption[] => {
  return options.map((option) => {
    return {
      label: option,
      value: option,
    } as SelectOption;
  });
};

const companyTypeOptions = buildSelectionOption(companyTypes);

const defaultNumberOfEmployeesOptions = buildSelectionOption(
  defaultNumberOfEmployees,
);

export const defaultPhoneMaxlength = 25;

export const buildCompanyTypeOptions = (): SelectOption[] => {
  return companyTypeOptions;
};

export const buildCountryOptions = (countries: ICountry[]): SelectOption[] => {
  return (countries || []).map((country) => {
    return {
      label: country.name,
      value: country.id,
    };
  });
};

export const buildStateOptions = (states: IState[]): SelectOption[] => {
  return (states || []).map((state) => {
    return {
      label: state.name,
      value: state.name,
    };
  });
};

export const buildNumberOfEmployeeOptions = (
  countryId?: string,
): SelectOption[] => {
  return countryId && numberOfEmployeesConfig[countryId]
    ? buildSelectionOption(numberOfEmployeesConfig[countryId])
    : defaultNumberOfEmployeesOptions;
};

export const requireState = (countryId: string) => {
  return _.includes(countriesRequireState, countryId);
};

export const buildUserFormikConfig = (initialValues?: object): any => {
  const defaultValues = {};
  Object.keys(userFormConfig).forEach((key) => {
    const config = userFormConfig[key];
    defaultValues[config.name] = config.value;
  });

  return {
    initialValues: Object.assign(defaultValues, initialValues),
    validationSchema: userFormSchema,
    onSubmit: () => {},
  };
};

export const getPhoneMaxlength = (callingCode: string) => {
  const callingCodeLength = `+${callingCode}`.length;
  return callingCode
    ? defaultPhoneMaxlength - callingCodeLength
    : defaultPhoneMaxlength;
};

export const buildErrorHandler = (popupRecaptchaErrorHandler) => {
  return {
    handle: (err: any) => {
      const { error } = err;
      switch (error) {
        case 'GOOGLE_RECAPTCHA_CHECK_FAIL':
          popupRecaptchaErrorHandler();
          return Promise.resolve();
        default:
          return Promise.reject();
      }
    },
  };
};
