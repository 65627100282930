import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from 'react';
import { updateParams } from '../../../../../../../../services/url/url.service';
import { VideoSlideRef } from '../video.interface';

interface Prop {
  src: string;
  autoPlay?: boolean;
}

export const Youtube = React.forwardRef<VideoSlideRef, Prop>(
  ({ src, autoPlay = false }, ref) => {
    const [embeddedYoutubeUrl, setEmbeddedYoutubeUrl] = useState('');
    const [updateState, forceUpdate] = useReducer((x) => x + 1, 0);
    useImperativeHandle<any, VideoSlideRef>(ref, () => ({
      forceUpdate,
    }));

    useEffect(() => {
      setURL();
    }, [src]);

    const setURL = () => {
      const t = transformUrl(src);
      if (!t) return;
      const d = disableUnrelatedVideos(t);
      setEmbeddedYoutubeUrl(d);
    };

    const transformUrl = (url: string): string | undefined => {
      try {
        const embeddedUrl = url
          .replace(/watch\?v=/i, 'embed/')
          .replace(/youtu\.be/i, 'www.youtube.com/embed');
        if (!autoPlay) {
          return embeddedUrl;
        }
        return updateParams(url, { autoplay: 1 });
      } catch (e) {
        return undefined;
      }
    };

    const disableUnrelatedVideos = (url: string): string => {
      return updateParams(url, { rel: 0 });
    };

    return (
      // eslint-disable-next-line jsx-a11y/iframe-has-title
      <iframe
        key={updateState}
        width="100%"
        height="100%"
        frameBorder="0"
        allow="autoplay"
        allowFullScreen
        src={embeddedYoutubeUrl}
        data-test-automation-id="carouselYoutube"
        title={`youtube video - ${embeddedYoutubeUrl}`}
      />
    );
  },
);
