import { styled, RcDialog } from '@ringcentral/juno';
import { onMobile } from '@apw/lib/sc';

export const Popup = styled(RcDialog)`
  [class~='RcDialog-paper'] {
    ${onMobile} {
      max-width: none !important;
      max-height: none !important;
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
      border-radius: 0 !important;
    }
  }
`;
