import React from 'react';
import { ApiError, mixpanel } from '@apw/core';
import { ErrorResponseFromAps } from '@apw/core/api/error-response/error-response-from-aps.class';
import { ErrorResponseFromPlatform } from '@apw/core/api/error-response/error-response-from-platform.class';
import { ErrorResponseConstructor } from '@apw/core/api/error-response/error-response.interface';
import { Language, languageService } from '@apw/core/language';

import { useInformDialog } from '@apw/hooks';
import { useStores } from '@apw/stores';
import {
  LOAD_EXTENSION_ENDPOINT,
  LOAD_USER_ENDPOINT,
  LOGOUT_APS_ENDPOINT,
  signInViaCLW,
} from '@apw/stores/user';
import { AxiosError, AxiosResponse } from 'axios';
import { endsWith } from 'lodash';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';

interface ApiErrorItem {
  shouldHandle: (err) => Observable<boolean>;
  handle: Function;
}

const errorResponseConstructors: ErrorResponseConstructor[] = [
  ErrorResponseFromAps,
  ErrorResponseFromPlatform,
];

export const getErrorCode = (err: AxiosResponse): string => {
  for (let i = 0; i < errorResponseConstructors.length; i++) {
    const errorResponse = new errorResponseConstructors[i](err);

    if (errorResponse.isTypeMatched()) {
      return errorResponse.extractErrorCode();
    }
  }

  return '';
};

export const isSessionTimeout = (error: AxiosError) => {
  return getErrorCode(error.response!) === ApiError.NOT_LOGGED_IN;
};

export const useApiErrorService = () => {
  const informDialog = useInformDialog();
  const { userStore } = useStores();

  const afterSessionTimeoutInformed = (redirectToLogin: boolean) => {
    if (redirectToLogin) {
      signInViaCLW();
      return;
    }

    userStore.logout().then(() => {
      mixpanel.resetMixpanel();
    });
  };

  const popupSessionTimeout = () => {
    return informDialog.show({
      title: languageService.get(Language.TITLE_SORRY),
      content: languageService.get(Language.SESSION_TIMEOUT),
      actions: [
        {
          label: 'Login',
          dialogResult: true,
        },
      ],
    });
  };

  const popupRequestRateExceeded = () => {
    return informDialog.show({
      title: languageService.get(Language.TITLE_SORRY),
      content: (
        <React.Fragment>
          The operation is too frequent. Please try again later. If the problem
          persists, please contact{' '}
          <a
            href="http://success.ringcentral.com/RCContactSupp"
            target="_blank"
            rel="noreferrer"
            tabIndex={-1}
          >
            Customer Support
          </a>
          .
        </React.Fragment>
      ),
      actions: [
        {
          label: 'OK',
        },
      ],
    });
  };

  const apiErrorItems: { [errorCode: string]: ApiErrorItem } = {
    [ApiError.NOT_LOGGED_IN]: {
      shouldHandle: (err: AxiosError) => {
        const config = err.response?.config || {};

        const excludedEndpoints = [
          LOAD_USER_ENDPOINT,
          LOAD_EXTENSION_ENDPOINT,
          LOGOUT_APS_ENDPOINT,
        ];

        const shouldInformUser = excludedEndpoints.every(
          (endpoint) => !endsWith(config.url, endpoint),
        );
        return of(shouldInformUser);
      },
      handle: () => {
        if (shownPopups[ApiError.NOT_LOGGED_IN] !== null) {
          return;
        }

        const popupInstance = popupSessionTimeout();
        shownPopups[ApiError.NOT_LOGGED_IN] = popupInstance;

        popupInstance.afterClosed((redirectToLogin: boolean) => {
          shownPopups[ApiError.NOT_LOGGED_IN] = null;
          afterSessionTimeoutInformed(redirectToLogin);
        });
      },
    },
    [ApiError.REQUEST_RATE_EXCEEDED]: {
      shouldHandle: () => of(true),
      handle: () => {
        if (shownPopups[ApiError.REQUEST_RATE_EXCEEDED] !== null) {
          return;
        }

        const popupInstance = popupRequestRateExceeded();
        shownPopups[ApiError.REQUEST_RATE_EXCEEDED] = popupInstance;

        popupInstance.afterClosed(() => {
          shownPopups[ApiError.REQUEST_RATE_EXCEEDED] = null;
        });
      },
    },
    [ApiError.SESSIONS_NUMBER_EXCEEDED]: {
      shouldHandle: () => of(true),
      handle: () => {
        userStore.logout().then(() => {
          mixpanel.resetMixpanel();
        });
      },
    },
    [ApiError.ACCESS_TOKEN_CORRUPTED]: {
      shouldHandle: () => of(true),
      handle: () => {
        userStore.logout().then(() => {
          mixpanel.resetMixpanel();
        });
      },
    },
  };

  return {
    handleCommonErrors(err: AxiosError): Promise<any> {
      const errorCode = getErrorCode(err.response!);
      const apiErrorItem = apiErrorItems[errorCode];

      if (apiErrorItem) {
        apiErrorItem
          .shouldHandle(err)
          .pipe(filter((shouldHandle: boolean) => shouldHandle))
          .subscribe(() => apiErrorItem.handle());
      }

      return Promise.reject(err);
    },
  };
};

const shownPopups: { [error: string]: any } = {
  [ApiError.NOT_LOGGED_IN]: null,
  [ApiError.REQUEST_RATE_EXCEEDED]: null,
};
