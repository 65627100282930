import { FeaturedIn } from '@apw/components/profileDetail/profileBody/overviewTab/featuredIn';
import { HelpfulLinks } from '@apw/components/profileDetail/profileBody/overviewTab/helpfulLinks';
import { Installation } from '@apw/components/profileDetail/profileBody/overviewTab/installation';
import { LongDesc } from '@apw/components/profileDetail/profileBody/overviewTab/longDesc';
import { PartnerCompatibility } from '@apw/components/profileDetail/profileBody/overviewTab/partnerCompatibility';
import { Support } from '@apw/components/profileDetail/profileBody/overviewTab/support';
import { onMobile, useProximaNovaSemibold } from '@apw/core/sc';
import { css, styled } from '@ringcentral/juno';
import { Requirements } from './requirements';

// nest item: the quill-editor can render 8-level nest at most
const listIndentLevel = 8;
const listRowSpacing = 20;
const nestListRowSpacing = 12;

const appendBulletIndentStyle = (basicSpace: number) => {
  const classesStyle: string[] = [];
  for (let i = 0; i < listIndentLevel; i++) {
    const num = i + 1;
    const classStyle = `
      &.ql-indent-${num} {
        padding-left: ${basicSpace * num}px;
      }
    `;
    classesStyle.push(classStyle);
  }
  return classesStyle.join('');
};

const orderedItemCounterReset = (start: number, end: number) => {
  let result = '';
  for (let i = start; i <= end; i++) {
    result = `${result} list-${i}`;
  }
  return result;
};

const orderedItemCounterMode = (index: number) => {
  const val = index % 3;
  if (val === 0) {
    return 'decimal';
  }
  if (val === 1) {
    return 'lower-alpha';
  }
  if (val === 2) {
    return 'lower-roman';
  }
  return 'decimal';
};

const appendOrderedIndentStyle = (basicSpace: number) => {
  const classesStyle: string[] = [];
  for (let i = 0; i < listIndentLevel; i++) {
    const num = i + 1;
    const classStyle = `
    &.ql-indent-${num}{
        counter-increment: list-${num};
        counter-reset: ${orderedItemCounterReset(num + 1, listIndentLevel + 1)};
        padding-left: ${basicSpace * num}px;
        [class~='ql-ui'] {
          &:before {
            content: counter(list-${num}, ${orderedItemCounterMode(num)}) '. ';
           }
        }
    }`;
    classesStyle.push(classStyle);
  }
  return classesStyle.join('');
};

const nestListRowAdjustStyle = () => {
  return `margin-top: -${listRowSpacing - nestListRowSpacing}px;`;
};

const appendNestListRowSpacingStyle = () => {
  const classesStyle: string[] = [];
  for (let i = 0; i < listIndentLevel; i++) {
    const num = i + 1;
    const classStyle = `
    &.ql-indent-${num} {
      &+[class~=ql-indent-${num}] {
        ${nestListRowAdjustStyle()}
      }
      &+[class~=ql-indent-${num + 1}] {
        ${nestListRowAdjustStyle()}
      }
    }`;
    classesStyle.push(classStyle);
  }
  return classesStyle.join('');
};

const leftContentItem = () => css`
  margin-bottom: 43px;
  border-bottom: 1px solid #cbcbcb;
  padding-bottom: 4px;

  &:empty,
  &:last-child {
    margin-bottom: 0;
    border-bottom-width: 0;
  }
`;

const markdownContentStyle = () => css`
  font-size: 16px;
  font-weight: normal;
  color: #282829;

  h1,
  h2,
  h3 {
    margin: 20px 0 20px;
    line-height: 26px;
    font-weight: 600;
    font-size: 20px;
    ${useProximaNovaSemibold};
  }

  h3 {
    font-size: 16px;
    line-height: 24px;
  }

  p {
    margin: 0 0 20px;
    line-height: 24px;
  }

  ol,
  ul {
    margin: 0;
    line-height: 24px;
    padding-left: 37px;

    li {
      margin-bottom: ${listRowSpacing}px;
      padding-left: 0;
      color: #282829;
    }
  }

  ol li {
    padding-left: 3px;

    // Workaround for quill@2.0.0-dev.3, which produces <ol><li data-list="bullet">example</li></ol> for unordered lists
    &[data-list='bullet'] {
      list-style: none;

      [class~='ql-ui']:before {
        content: '\\2022';
        display: inline-block;
        margin-left: -18px;
        text-align: left;
        width: 18px;
        font-size: 24px;
      }
      ${appendBulletIndentStyle(40)};
    }

    &[data-list='ordered'] {
      list-style: none;
      counter-increment: list-0;
      counter-reset: ${orderedItemCounterReset(1, listIndentLevel + 1)};
      .ql-ui:before {
        display: inline-block;
        margin-left: -16px;
        text-align: left;
        width: 16px;
      }
      .ql-ui:before {
        content: counter(list-0, ${orderedItemCounterMode(0)}) '. ';
      }
      ${appendOrderedIndentStyle(40)};
    }

    &[data-list='bullet'],
    &[data-list='ordered'] {
      & + [class~='ql-indent-1'] {
        ${nestListRowAdjustStyle()}
      }
      ${appendNestListRowSpacingStyle()}
    }
  }

  a {
    line-height: 26px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  blockquote {
    margin: 20px;
    padding-left: 14px;
    border-left: 2px solid #686869;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const TwoColumnsLayout = styled.div`
  display: flex;
  margin-top: 42px;

  ${onMobile} {
    flex-direction: column-reverse;
    margin-top: 40px;
  }
`;

export const LeftContent = styled.div`
  flex: 0 0 auto;
  width: 240px;
  margin-right: 48px;

  ${onMobile} {
    width: 100%;
    margin-right: 0;
  }
`;

export const RightContent = styled.div`
  flex: 1 1 auto;
  width: 0;
  overflow-wrap: break-word;

  ${onMobile} {
    width: auto;
  }
`;

export const TagListContainer = styled.div`
  ${leftContentItem};
`;

export const HelpfulLinksContainer = styled(HelpfulLinks)`
  ${leftContentItem};
`;

export const PartnerCompatibilityContainer = styled(PartnerCompatibility)`
  ${leftContentItem};
`;

export const H1Tag = styled.h1`
  margin: 0 0 20px;
  font-size: 22px;
  font-weight: 600;
  color: #001138;
  ${useProximaNovaSemibold};

  ${onMobile} {
    font-size: 20px;
  }
`;

export const DetailContent = styled.div`
  width: 100%;
  margin-bottom: 48px;
  color: #001138;
`;

export const LongDescContainer = styled(LongDesc)`
  width: 100%;
  margin-bottom: 48px;
  ${markdownContentStyle};
`;

export const RequirementsContainer = styled(Requirements)`
  margin-bottom: 48px;
  ${markdownContentStyle};
`;

export const InstallationContainer = styled(Installation)`
  margin-bottom: 48px;
  ${markdownContentStyle};
`;

export const SupportContainer = styled(Support)`
  margin-bottom: 48px;
`;

export const FeaturedInContainer = styled(FeaturedIn)`
  margin-bottom: 48px;
`;
