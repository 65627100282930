import { RcThemeInput } from '@ringcentral/juno';
import {
  overridesFactory,
  paletteFactory,
} from '../../../../../shared/theme/utils';

export const unifyOfficeDtAtosDtColor = '#0066A1';

export const unifyOfficeDtAtosDtTheme: RcThemeInput = {
  palette: paletteFactory(unifyOfficeDtAtosDtColor),
  overrides: overridesFactory(unifyOfficeDtAtosDtColor),
};
