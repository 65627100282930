import React, { FC } from 'react';
import { styled } from '@ringcentral/juno';
import { Link } from 'react-router-dom';
import placeholderImage from '@apw/lib/assets/images/feature-card-placeholder.png';
import { MultipleLineEllipsis } from '@apw/lib/components/multipleLineEllipsis';
import { IPageSimpleModel } from '@apw/lib/types';

interface IPageCardProps {
  page: IPageSimpleModel;
  pagePathBuilder: (vanityUrl: string) => string;
  className?: string;
  handleClick?: () => void;
}

const CARD_TITLE = 'pageCardTitle';
const CARD_DESC = 'pageCardDesc';

export const PageCard: FC<IPageCardProps> = ({
  page,
  pagePathBuilder,
  className = '',
  handleClick,
}) => {
  const pagePath = pagePathBuilder(page.vanityUrl);

  const onClick = () => {
    if (handleClick) {
      handleClick();
    }
  };

  return (
    <Wrapper className={className} data-test-automation-id="pageCard">
      <PageLink
        to={pagePath}
        onClick={onClick}
        data-test-automation-id="pageCardLink"
      >
        <PageImage
          style={{
            backgroundImage: `url("${page.imageUrl}")`,
          }}
        >
          {!page.imageUrl && <img src={placeholderImage} alt="" />}
        </PageImage>
        <PageInfo>
          <PageTitle title={page.name} className={CARD_TITLE}>
            {page.name}
          </PageTitle>
          {page.description && (
            <PageDescription title={page.description} className={CARD_DESC}>
              <MultipleLineEllipsis linesToDisplay={2} lineHeightPx={24}>
                {page.description}
              </MultipleLineEllipsis>
            </PageDescription>
          )}
        </PageInfo>
        <MaskLayer />
      </PageLink>
    </Wrapper>
  );
};

const MaskLayer = styled.div`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 17, 56, 0.04);
  pointer-events: none;
`;

const PageLink = styled(Link)`
  &,
  &:focus-visible {
    text-decoration: none;
  }

  &:focus-visible {
    ${MaskLayer} {
      display: block;
    }
  }
`;

const Wrapper = styled.div`
  border-radius: 8px;
  border: 1px solid rgb(225, 230, 234);
  overflow: hidden;
  width: 328px;
  position: relative;

  &:hover ${MaskLayer} {
    display: block;
  }
`;

const PageImage = styled.div`
  height: 176px;
  background-size: cover;
  background-position: 50%;
  display: flex;
  justify-content: center;
  align-items: stretch;
`;

const PageInfo = styled.div`
  padding: 20px 16px 24px;
`;

const PageTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  color: rgb(0, 17, 56);
  letter-spacing: 0.28px;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const PageDescription = styled.div`
  margin-top: 8px;
  font-size: 16px;
  color: rgb(40, 40, 41);
  letter-spacing: 0.25px;
`;
