import React from 'react';
import { styled } from '@ringcentral/juno';
import { observer } from 'mobx-react';
import { Permissions } from './permissions';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 58px;
`;

export function TechnicalDetailsPure() {
  return (
    <Wrapper data-test-automation-id="technical-details">
      <Permissions />
    </Wrapper>
  );
}

export const TechnicalDetails = observer(TechnicalDetailsPure);
