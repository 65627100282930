import {
  BPS,
  fontColorBlack,
  respondTo,
  useProximaNovaSemibold,
} from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

const galleryListItemMargin = '12px';
const appLogoSize = '72px';

export const WrapperShadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s;
  pointer-events: none;
  border-radius: 8px;
`;

export const Wrapper = styled.a`
  position: relative;
  display: inline-block;
  width: calc(33.33% - ${galleryListItemMargin});
  margin: 0 ${galleryListItemMargin} ${galleryListItemMargin} 0;
  vertical-align: top;
  text-decoration: none;

  > a {
    color: #494949;
    text-decoration: none;
  }

  ${respondTo(BPS.gtTablet)} {
    &:nth-of-type(3n) {
      margin-right: 0;
    }

    &:hover {
      ${WrapperShadow} {
        background-color: rgba(#001138, 0.04);
      }
    }
  }

  ${respondTo(BPS.contentCard2)} {
    width: calc(50% - ${galleryListItemMargin});

    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }

  ${respondTo(BPS.contentCard1)} {
    width: calc(100% - ${galleryListItemMargin});
    margin-right: 0;
  }
`;

export const Content = styled.div`
  position: relative;
  display: block;
  padding: 15px 15px 5px 15px;
  border: 1px solid #e1e6ea;
  background: #ffffff;
  border-radius: 8px;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`;

export const AppLogo = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${appLogoSize};
  height: ${appLogoSize};
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
  }

  &.logoSquareWithSpacing {
    position: relative;

    img {
      position: absolute;
      top: 10px;
      right: 10px;
      bottom: 10px;
      left: 10px;
      margin: auto;
      max-width: calc(100% - 20px);
      max-height: calc(100% - 20px);
    }
  }
`;

export const AppInfo = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 0;
`;

export const AppName = styled.div`
  ${useProximaNovaSemibold};
  font-size: 18px;
  line-height: 26px;
  color: ${fontColorBlack};
  margin-bottom: 16px;
`;

export const AppPublisher = styled.div`
  height: 18px;
  margin-bottom: 15px;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  letter-spacing: 0.25px;
  color: ${fontColorBlack};
`;
