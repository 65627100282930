import { Category } from './category.enum';

export type CategoryConfig = {
  [id in Category]: {
    id: string;
    displayName: string;
  };
};

export const categoryConfig: CategoryConfig = {
  [Category.AI]: {
    id: Category.AI,
    displayName: 'AI',
  },
  [Category.ANALYTICS]: {
    id: Category.ANALYTICS,
    displayName: 'Analytics',
  },
  [Category.AUTOMATION]: {
    id: Category.AUTOMATION,
    displayName: 'Automation',
  },
  [Category.BOTS_NOTIFICATIONS]: {
    id: Category.BOTS_NOTIFICATIONS,
    displayName: 'Bots & Notifications',
  },
  [Category.CALENDARING]: {
    id: Category.CALENDARING,
    displayName: 'Calendaring',
  },
  [Category.COLLABORATION]: {
    id: Category.COLLABORATION,
    displayName: 'Collaboration',
  },
  [Category.CRM]: {
    id: Category.CRM,
    displayName: 'CRM',
  },
  [Category.CUSTOMER_SUPPORT]: {
    id: Category.CUSTOMER_SUPPORT,
    displayName: 'Customer Support',
  },
  [Category.DEVELOPER_TOOLS]: {
    id: Category.DEVELOPER_TOOLS,
    displayName: 'Developer Tools',
  },
  [Category.EDUCATION]: {
    id: Category.EDUCATION,
    displayName: 'Education',
  },
  [Category.ERP]: {
    id: Category.ERP,
    displayName: 'ERP',
  },
  [Category.FAX]: {
    id: Category.FAX,
    displayName: 'Fax',
  },
  [Category.FINANCIAL_SERVICES]: {
    id: Category.FINANCIAL_SERVICES,
    displayName: 'Financial Services',
  },
  [Category.HEALTHCARE]: {
    id: Category.HEALTHCARE,
    displayName: 'Healthcare',
  },
  [Category.LABS]: {
    id: Category.LABS,
    displayName: 'Labs',
  },
  [Category.LEGAL]: {
    id: Category.LEGAL,
    displayName: 'Legal',
  },
  [Category.MARKETING_AUTOMATION]: {
    id: Category.MARKETING_AUTOMATION,
    displayName: 'Marketing Automation',
  },
  [Category.PRODUCTIVITY]: {
    id: Category.PRODUCTIVITY,
    displayName: 'Productivity',
  },
  [Category.RETAIL]: {
    id: Category.RETAIL,
    displayName: 'Retail',
  },
  [Category.SMS]: {
    id: Category.SMS,
    displayName: 'SMS',
  },
  [Category.SPEECH]: {
    id: Category.SPEECH,
    displayName: 'Speech',
  },
  [Category.STORAGE]: {
    id: Category.STORAGE,
    displayName: 'Storage',
  },
};
