import { onMobile } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const Container = styled.div`
  margin-bottom: 16px;

  ${onMobile} {
    margin-bottom: 32px;
  }
`;

export const LabelBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  svg {
    width: 16px;
  }
`;

export const RatingBox = styled.div`
  margin-left: -4px;
`;
