import { PrimaryButton } from '@apw/components/button';
import { PlatformOption } from '@apw/components/profileDetail/profileHeader/ctaButton/appDownloadButton/platformOption';
import { osStyles } from '@apw/components/profileDetail/profileHeader/ctaButton/appDownloadButton/platformOption/platformIcons.sc';
import {
  ctaButtonSizeOnMobile,
  ctaButtonTextOnMobile,
} from '@apw/components/profileDetail/profileHeader/ctaButton/ctaButton.sc';
import { RcIcon, styled } from '@ringcentral/juno';

export const PlatformOptionInPrimaryButton = styled(PlatformOption)`
  justify-content: center;
  ${ctaButtonTextOnMobile};
`;

export const NoDownloadLinksAvailable = styled.div`
  text-align: center;
`;

export const AvailableOS = styled.span`
  margin-left: 8px;
`;

export const OsIcon = styled(RcIcon)`
  vertical-align: middle;

  &:not(:first-child) {
    margin-left: 4px;
  }

  ${osStyles};
`;

export const DownloadButton = styled(PrimaryButton)`
  padding: 8px 16px;

  ${ctaButtonSizeOnMobile};
`;
