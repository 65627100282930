import React, { FC, ReactElement, useContext, useRef } from 'react';
import { ProfileOptionsContext } from '@apw/components/profileDetail/profile-options.context';
import { PlatformOption } from '@apw/components/profileDetail/profileHeader/ctaButton/appDownloadButton/platformOption';
import {
  PopoverExternalControl,
  PopoverTriggerGenerator,
} from '@apw/core/popover/typings';
import { useUserCompatibility } from '@apw/hooks';
import { AppDownloadLink } from '@apw/types';
import { RcMenuList } from '@ringcentral/juno';
import { ArrowDown2, ArrowUp2 } from '@ringcentral/juno-icon';
import { v4 as uuid } from 'uuid';
import {
  MenuItem,
  MenuTriggerContainer,
  MenuTriggerPlatformOption,
  MenuTriggerPopoverOpener,
  MenuTriggerPopoverOpenerIcon,
  MenuTriggerPrimaryItem,
  PopoverContainer,
} from './selectPlatformWithPopover.sc';

interface SelectPlatformWithPopoverProps {
  items: AppDownloadLink[];
}

export const SelectPlatformWithPopover: FC<SelectPlatformWithPopoverProps> = ({
  items,
}) => {
  const { onDownload } = useContext(ProfileOptionsContext);
  const { isUserIncompatibleWithCurrentGallery } = useUserCompatibility();
  const menuTriggerGenerator = menuTriggerGeneratorFactory(
    items,
    onDownload,
    isUserIncompatibleWithCurrentGallery,
  );

  const popoverControlRef = useRef<PopoverExternalControl>({
    hidePopover: () => {},
  });

  const handleDownload = (item: AppDownloadLink) => {
    popoverControlRef.current.hidePopover();
    onDownload?.(item);
  };

  return (
    <PopoverContainer
      getTrigger={menuTriggerGenerator}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      initExternalControl={(control) => (popoverControlRef.current = control)}
    >
      {getPopoverContentNode(items, handleDownload)}
    </PopoverContainer>
  );
};

const menuTriggerGeneratorFactory = (
  items: AppDownloadLink[],
  onDownload,
  disabled,
): PopoverTriggerGenerator => {
  return (showPopover, open) => (
    <MenuTriggerContainer>
      <MenuTriggerPrimaryItem
        onClick={() => {
          onDownload(items[0]);
        }}
        disabled={disabled}
        data-test-automation-id={`main-action-button`}
      >
        <MenuTriggerPlatformOption
          item={items[0]}
          useIconForActive
          useIconForDisabled={disabled}
        />
      </MenuTriggerPrimaryItem>
      <MenuTriggerPopoverOpener
        onClick={showPopover}
        disabled={disabled}
        ariaLabel="toggle dropdown"
        ariaExpanded={`${open}` as 'true' | 'false'}
        data-test-automation-id={`open-menu-button`}
      >
        <MenuTriggerPopoverOpenerIcon
          tabIndex={-1}
          symbol={open ? ArrowUp2 : ArrowDown2}
          data-test-automation-id={`open-menu-button-icon`}
        />
      </MenuTriggerPopoverOpener>
    </MenuTriggerContainer>
  );
};

const getMenuItemNode = (
  item: AppDownloadLink,
  onDownload: Function,
): ReactElement => {
  return (
    <MenuItem
      onClick={() => onDownload(item)}
      key={uuid()}
      tabIndex={0}
      data-test-automation-id={`menu-item`}
    >
      <PlatformOption item={item} />
    </MenuItem>
  );
};

const getPopoverContentNode = (
  items: AppDownloadLink[],
  onDownload: Function,
): ReactElement => {
  const secondaryItems = items.slice(1);

  return (
    <RcMenuList data-test-automation-id={`menu-list`}>
      {secondaryItems.map((item) => getMenuItemNode(item, onDownload))}
    </RcMenuList>
  );
};
