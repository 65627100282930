import { styled } from '@ringcentral/juno';
import { onMobile } from '@apw/lib/sc';

export const RatingWrapper = styled.div`
  margin-top: 22px;
  ${onMobile} {
    margin-top: 12px;
    margin-left: 0;
  }
`;
