import { Product } from './product.enum';

export type ProductConfig = {
  [id in Product]: {
    id: Product;
    displayName: string;
  };
};

export const productConfig: ProductConfig = {
  // Primary products

  [Product.MESSAGE]: {
    id: Product.MESSAGE,
    displayName: 'Team Messaging',
  },
  [Product.VIDEO]: {
    id: Product.VIDEO,
    displayName: 'Video',
  },
  [Product.WEBINAR]: {
    id: Product.WEBINAR,
    displayName: 'Webinar',
  },
  [Product.PHONE]: {
    id: Product.PHONE,
    displayName: 'Phone',
  },

  // Secondary products

  [Product.CONTACT_CENTER]: {
    id: Product.CONTACT_CENTER,
    displayName: 'Contact Center',
  },
  [Product.ENGAGE_DIGITAL]: {
    id: Product.ENGAGE_DIGITAL,
    displayName: 'RingCX Digital',
  },
  [Product.ENGAGE_VOICE]: {
    id: Product.ENGAGE_VOICE,
    displayName: 'RingCX Voice',
  },
  [Product.MEETINGS]: {
    id: Product.MEETINGS,
    displayName: 'Meetings',
  },
  [Product.SERVICE_WEB]: {
    id: Product.SERVICE_WEB,
    displayName: 'Service Web',
  },
};
