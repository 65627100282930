import { useProximaNovaRegular } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const Container = styled.div`
  align-self: stretch;
  display: flex;
`;

export const MenuItem = styled.div`
  display: flex;

  a {
    display: flex;
    text-decoration: none;
    color: rgb(40, 43, 51);
  }

  [role='menuitem'] {
    ${useProximaNovaRegular};
    justify-content: center;
    font-size: 16px !important;
    min-width: 0 !important;
    padding: 0 !important;

    &:hover,
    &:active {
      background-color: transparent !important;
    }
  }

  [data-class='menuItemText'] {
    display: flex;
    align-items: center;
    height: 100%;
    border-bottom: 3px solid transparent;
    white-space: pre-line;
  }

  [class~='MuiTouchRipple-root'] {
    display: none;
  }
`;

export const IconExternalLink = styled.span`
  margin-left: 4px;
  line-height: 0;
  transform: translateY(-2px);
`;
