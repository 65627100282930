import React, { FC, useContext } from 'react';
import { StoreContext } from '@apw/lib/components/profileDetail/StoreContext';
import {
  Container,
  Title,
} from '@apw/lib/components/ratingAndReview/overallRating/overallRating.sc';
import {
  IOverallRatingItemProps,
  OverallRatingItem,
} from '@apw/lib/components/ratingAndReview/overallRating/overallRatingItem/OverallRatingItem';

export interface IOverallRatingProps {
  items: IOverallRatingItemProps[];
  automationId: string;
  className?: string;
}

export const OverallRating: FC<IOverallRatingProps> = ({
  items,
  automationId,
  className,
}) => {
  const { translation } = useContext(StoreContext);
  return (
    <Container className={className} data-test-automation-id={automationId}>
      <Title
        color="neutral.f06"
        variant="subheading1"
        data-test-automation-id={'overall-rating-title'}
      >
        {translation.ratingsAndReviews.overallRatings.title}
      </Title>
      {items.map((item) => {
        return (
          <OverallRatingItem
            stars={item.stars}
            percentage={item.percentage}
            key={`start${item.stars}`}
          />
        );
      })}
    </Container>
  );
};
