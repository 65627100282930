import {
  BPS,
  respondTo,
  useProximaNovaRegular,
  useProximaNovaSemibold,
} from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Header = styled.h5`
  width: 100%;
  margin: 0 0 20px;
  font-size: 22px;
  font-weight: 600;
  color: #001138;
  ${useProximaNovaSemibold};
  ${respondTo(BPS.ltTablet)} {
    font-size: 20px;
  }
`;

export const Content = styled.div`
  width: 100%;
  color: #282829;
  ${useProximaNovaRegular};
`;

export const ContentItem = styled.div`
  line-height: 24px;
  font-size: 16px;
`;

export const TimeWrapper = styled.span`
  display: inline-block;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
`;

export const EmailLink = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
