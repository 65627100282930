import { RefObject, useEffect, useState } from 'react';
import { throttle } from 'lodash';
import ResizeObserver from 'resize-observer-polyfill';

export const useResizeObserver = (ref: RefObject<HTMLElement>, wait = 200) => {
  const [width, setWidth] = useState(ref.current?.offsetWidth || 0);

  useEffect(() => {
    if (!ref || !ref.current) return;

    const handle = throttle((entries: ResizeObserverEntry[]) => {
      setWidth(entries[0].contentRect.width);
    }, wait);

    const observer = new ResizeObserver(handle);
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref.current]);

  return width;
};
