import React, { FC } from 'react';
import { RcButton, styled } from '@ringcentral/juno';
import { Link } from 'react-router-dom';
import { ILink } from '@apw/lib/types';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

const Type = styled.h5`
  margin: 0 0 20px;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
`;

const Ul = styled.ul`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  flex-direction: column;
`;

const Button = styled(RcButton)`
  height: 36px;
  border-radius: 2px;

  &,
  &.RcButton-outlined {
    min-width: auto;
    padding: 0 7px;
  }

  &.readonlyButton {
    cursor: default;
    pointer-events: none;
  }
`;

const Li = styled.li`
  display: inline-block;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const TagName = styled.span`
  font-size: 15px;
  font-weight: normal;
`;

export const TagList: FC<{
  tagType: string;
  readonly?: boolean;
  tagList?: ILink[];
  className?: string;
}> = (props) => {
  const { tagType = '', tagList, readonly = false, className } = props;

  if (!tagList || !tagList.length) {
    return null;
  }

  const tagItemContent = (item: ILink) => {
    const content = (
      <Button variant="plain" className={readonly ? 'readonlyButton' : ''}>
        <TagName className="tagName" data-test-automation-id="tagItemLabel">
          {item.label}
        </TagName>
      </Button>
    );
    return readonly ? (
      content
    ) : (
      <Link
        to={item.link}
        rel="noreferrer"
        data-test-automation-id="tagItemLink"
      >
        {content}
      </Link>
    );
  };

  return (
    <Wrapper
      className={`tagList ${className || ''}`}
      data-test-automation-id={`tagList-${tagType}`}
    >
      <Type className="tagListTitle" data-test-automation-id="tagListTitle">
        {tagType}
      </Type>
      <Ul data-test-automation-id="tagList">
        {(tagList || []).map((item, index) => {
          return (
            <Li key={item.key} data-test-automation-id={`tagItem-${index}`}>
              {tagItemContent(item)}
            </Li>
          );
        })}
      </Ul>
    </Wrapper>
  );
};
