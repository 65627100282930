import React, { FC } from 'react';
import { IAppRating } from '@apw/lib/types';
import { Content } from './content/Content';
import { Header } from './header/Header';

export interface IRatingsSectionProps {
  ratings: IAppRating;
}

export const RatingsSection: FC<IRatingsSectionProps> = ({ ratings }) => {
  return (
    <>
      <Header />
      <Content ratings={ratings} />
    </>
  );
};
