import { RcThemeInput } from '@ringcentral/juno';
import { IGalleryBrandNav } from './compatibilities/galleryBrand';

export interface ICompatibility {
  name: string;
  galleryName: string;
  id: string;
  idAliases?: string[];
  value: string;
  valueAliases?: string[];
  isDefault?: boolean;
  logo: string;
  galleryIcon: string;
  galleryIconType: GalleryIconType;
  headerBanners: string[];
  primaryColor: string;
  secondaryColor: string;
  horizontalNavs: IGalleryBrandNav[];
  className: string;
  jupiterWebUrl?: string;
  hideIfNoApps?: boolean;
  slug: string; // Compatibility String Present in URI
  theme: RcThemeInput;
  subBrands: ICompatibilitySubBrand[];
}

export interface ICompatibilitySubBrand {
  brandId: string;
  logoUrl: string;
  name: string;
}

export enum GalleryIconType {
  URL,
  SVG_FILE,
  PNG_FILE,
}

export type ICompatibilitiesMapping = Record<string, ICompatibility>;
