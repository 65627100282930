import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Alert } from '@apw/components';
import { transport } from '@apw/core';
import { isSessionTimeout } from '@apw/core/api/api-error.service';
import {
  countryDic,
  getCountries,
  getCountryByName,
  getStates,
} from '@apw/core/country';
import {
  GoogleRecaptcha,
  RecaptchaRenderParams,
} from '@apw/core/google-recaptcha';
import { Language, languageService } from '@apw/core/language';
import { usePrevious } from '@apw/hooks';
import { useCommonError } from '@apw/hooks/useCommonError';
import {
  buildCompanyTypeOptions,
  buildCountryOptions,
  buildErrorHandler,
  buildNumberOfEmployeeOptions,
  buildStateOptions,
  BusinessPhone,
  requireState,
  userFormConfig,
} from '@apw/modules/profile';
import { SelectOption } from '@apw/modules/profile/shared/selectOption.interface';
import { HeaderMobilePopup } from '@apw/shared';
import { useStores } from '@apw/stores';
import { RcLink } from '@ringcentral/juno';
import { Close } from '@ringcentral/juno-icon';
import { AxiosError } from 'axios';
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import {
  FormField,
  Description,
  DialogCloseButton,
  DialogContent,
  DialogTitle,
  Loading,
  RecaptchaContainer,
  SubmitButton,
  DialogActions,
  SelectorFieldItem,
  SelectorFieldWithErrorMsg,
  SelectorField,
  FieldErrorMsg,
  GroupLabel,
  GroupTwoColumn,
  GroupOneColumn,
  GroupContent,
  FormTextField,
} from './UserForm.sc';

let googleRecaptcha: GoogleRecaptcha;

const scrollToError = (formRef) => {
  if (formRef && formRef.current) {
    setTimeout(() => {
      const errorEle = formRef.current.querySelector('.Mui-error');
      if (errorEle) {
        errorEle.scrollIntoView();
      }
    }, 200);
  }
};

const focusOnFirstEle = (formRef) => {
  const inputEle = formRef.current.querySelector('.MuiInput-input');
  if (inputEle) {
    inputEle.focus();
  }
};

const truncate = (value: any, maxLength?: number) => {
  return _.isString(value) ? value.substr(0, maxLength) : value;
};

const UserFormTextField = observer((props) => {
  const { form, fieldConfig } = props;
  const errorMsg =
    form.touched[fieldConfig.name] && form.errors[fieldConfig.name];
  const handleChange = (event) => {
    form.setFieldValue(fieldConfig.name, event.target.value);
  };

  return (
    <FormTextField
      label={fieldConfig.label}
      name={fieldConfig.name}
      value={form.values[fieldConfig.name] || ''}
      onChange={handleChange}
      helperText={
        errorMsg && (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          <div role="alert" tabIndex={0}>
            {errorMsg}
          </div>
        )
      }
      error={
        !!(form.touched[fieldConfig.name] && form.errors[fieldConfig.name])
      }
      autoComplete="off"
      inputProps={{
        maxLength: fieldConfig.maxlength,
        'aria-label': fieldConfig.label,
      }}
    />
  );
});

const UserFormSelector = observer((props) => {
  const { form, fieldConfig, options, renderValue } = props;
  const errorMsg =
    form.touched[fieldConfig.name] && form.errors[fieldConfig.name];
  const Selector = errorMsg ? SelectorFieldWithErrorMsg : SelectorField;

  return (
    <FormField>
      <Selector
        label={fieldConfig.label}
        variant="line"
        fullWidth
        name={fieldConfig.name}
        data-test-automation-id={`${fieldConfig.name}-selector`}
        value={form.values[fieldConfig.name]}
        renderValue={renderValue}
        onChange={form.handleChange}
        aria-label={fieldConfig.label}
      >
        {options &&
          options.map((item) => (
            <SelectorFieldItem
              value={item.value}
              key={item.value}
              aria-hidden="true"
            >
              {item.label}
            </SelectorFieldItem>
          ))}
      </Selector>
      {errorMsg && (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        <FieldErrorMsg role="alert" tabIndex={0}>
          {errorMsg}
        </FieldErrorMsg>
      )}
    </FormField>
  );
});

const GoogleRecaptchaContainer = ({ form, fieldConfig }) => {
  const errorMsg =
    form.touched[fieldConfig.name] && form.errors[fieldConfig.name];
  return (
    <RecaptchaContainer>
      <div id="user-form-google-recaptcha" />
      {errorMsg && (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        <FieldErrorMsg role="alert" tabIndex={0}>
          {errorMsg}
        </FieldErrorMsg>
      )}
    </RecaptchaContainer>
  );
};

const UserFormPure: FC<{
  form: any;
  description: string;
  onClose: () => void;
  onSubmit: (errorHandlers) => void;
  extraControls?: ReactNode;
  secondaryBtn?: ReactNode;
}> = ({
  form,
  description,
  onClose,
  onSubmit,
  extraControls,
  secondaryBtn,
}) => {
  let isMounted = true;
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);
  const {
    values: { country },
    setFieldValue,
  } = form;

  const fieldConfig = userFormConfig;

  const [employeesOptions, setEmployeesOptions] = useState(() =>
    buildNumberOfEmployeeOptions(),
  );
  const [countryOptions, setCountryOptions] = useState([] as SelectOption[]);
  const [stateOptions, setStateOptions] = useState([] as SelectOption[]);
  const [recaptchaErrorVisible, setRecaptchaErrorVisible] = useState(false);
  const [callingCodeVisible, setCallingCodeVisible] = useState(true);
  const [prefilledStateId, setPrefilledStateId] = useState('');
  const [loading, setloading] = useState(true);
  const formRef = useRef(document.createElement('form'));
  const { userStore } = useStores();
  const commonError = useCommonError();

  const initGoogleRecaptcha = () => {
    const parameters: RecaptchaRenderParams = {
      callback: (recaptchaResponse) => {
        if (isMounted) {
          setFieldValue(fieldConfig.recaptchaResponse.name, recaptchaResponse);
        }
      },
      'expired-callback': () => {
        if (isMounted) {
          setFieldValue(fieldConfig.recaptchaResponse.name, '');
        }
      },
    };

    googleRecaptcha = new GoogleRecaptcha(
      'user-form-google-recaptcha',
      parameters,
    );
  };

  const initCountryOptions = () => {
    getCountries().then((countries) => {
      if (isMounted) {
        setCountryOptions(buildCountryOptions(countries));
      }
    });
  };

  const updateEmployeeOptions = (countryId: string) => {
    setEmployeesOptions(buildNumberOfEmployeeOptions(countryId));
  };

  const updateStates = (countryId: string) => {
    if (!countryId || !requireState(countryId)) {
      setFieldValue(fieldConfig.state.name, '');
    } else {
      getStates(countryId).then((states) => {
        if (!isMounted) {
          return;
        }
        setStateOptions(buildStateOptions(states));
        if (prefilledStateId) {
          const state = states.find(
            (state) => state.isoCode === prefilledStateId,
          );
          setFieldValue(userFormConfig.state.name, state?.name);
          setPrefilledStateId('');
        } else {
          setFieldValue(userFormConfig.state.name, '');
        }
      });
    }
  };

  const popupRecaptchaError = () => {
    setRecaptchaErrorVisible(true);
  };

  const closeRecaptchaError = () => {
    setRecaptchaErrorVisible(false);
    googleRecaptcha.reset();
    setFieldValue(fieldConfig.recaptchaResponse.name, '');
  };

  const handleSubmit = () => {
    form.handleSubmit();
    if (form.isValid) {
      onSubmit(buildErrorHandler(popupRecaptchaError));
    } else {
      scrollToError(formRef);
    }
  };

  const preFill = () => {
    const contact = userStore.extension?.contact;
    if (!contact) {
      focusOnFirstEle(formRef);
      setloading(false);
      return;
    }

    if (contact.businessPhone) {
      setCallingCodeVisible(false);
      setFieldValue(fieldConfig.callingCode.name, '');
    }

    transport
      .fetchCompany()
      .then((company) => {
        const countryName = _.get(company, 'businessAddress.country');
        return getCountryByName(countryName).then((country) => {
          return { company, country };
        });
      })
      .then(({ company, country }) => {
        const preFilledValues = {
          [fieldConfig.firstName.name]: truncate(
            contact.firstName,
            fieldConfig.firstName.maxlength,
          ),
          [fieldConfig.lastName.name]: truncate(
            contact.lastName,
            fieldConfig.lastName.maxlength,
          ),
          [fieldConfig.workEmail.name]: truncate(
            contact.email,
            fieldConfig.workEmail.maxlength,
          ),
          [fieldConfig.phoneNumber.name]: truncate(
            contact.businessPhone,
            fieldConfig.phoneNumber.maxlength,
          ),
          [fieldConfig.companyName.name]: truncate(
            company.company,
            fieldConfig.companyName.maxlength,
          ),
          [fieldConfig.country.name]: country ? country.id : undefined,
          [fieldConfig.city.name]: truncate(
            company.businessAddress?.city,
            fieldConfig.city.maxlength,
          ),
        };

        if (country && requireState(country.id)) {
          setPrefilledStateId(company.businessAddress.state);
        }

        if (isMounted) {
          Object.keys(preFilledValues).forEach((key) => {
            setFieldValue(key, preFilledValues[key]);
          });
          focusOnFirstEle(formRef);
        }
        setloading(false);
      })
      .catch((error: AxiosError) => {
        setloading(false);
        if (!isSessionTimeout(error)) {
          commonError.show();
        }
      });
  };

  useEffect(() => {
    initCountryOptions();
    initGoogleRecaptcha();
    preFill();
  }, []);

  useEffect(() => {
    updateEmployeeOptions(country);
    updateStates(country);
  }, [country]);

  const previousEmployeeOptions = usePrevious(employeesOptions);
  useEffect(() => {
    if (
      JSON.stringify(previousEmployeeOptions) !==
      JSON.stringify(employeesOptions)
    ) {
      setFieldValue(fieldConfig.numberOfEmployees.name, '');
    }
  }, [employeesOptions, previousEmployeeOptions]);

  return (
    <>
      {loading && <Loading />}
      <HeaderMobilePopup
        onBack={onClose}
        data-test-automation-id="user-form-header-mobile-popup"
      />
      <div hidden={loading}>
        <DialogTitle data-test-automation-id="user-form-dialog-title">
          Tell us about yourself
          <DialogCloseButton
            data-test-automation-id="user-form-dialog-close-button"
            onClick={onClose}
            symbol={Close}
            aria-label="close"
          />
        </DialogTitle>
        <form ref={formRef}>
          <DialogContent role="main">
            <Description data-test-automation-id="user-form-dialog-desc">
              {description}
            </Description>
            <GroupTwoColumn>
              <GroupLabel data-test-automation-id="user-form-dialog-group-your-info">
                Your info
              </GroupLabel>
              <GroupContent>
                <UserFormTextField
                  data-test-automation-id="user-form-dialog-field-first-name"
                  form={form}
                  fieldConfig={fieldConfig.firstName}
                />
                <UserFormTextField
                  data-test-automation-id="user-form-dialog-field-last-name"
                  form={form}
                  fieldConfig={fieldConfig.lastName}
                />
                <UserFormTextField
                  data-test-automation-id="user-form-dialog-field-job-title"
                  form={form}
                  fieldConfig={fieldConfig.jobTitle}
                />
                <UserFormTextField
                  data-test-automation-id="user-form-dialog-field-email"
                  form={form}
                  fieldConfig={fieldConfig.workEmail}
                />
                <FormField>
                  <BusinessPhone
                    data-test-automation-id="user-form-dialog-field-business-phone"
                    form={form}
                    fieldConfig={fieldConfig}
                    showCallingCode={callingCodeVisible}
                  />
                </FormField>
              </GroupContent>
            </GroupTwoColumn>
            <GroupTwoColumn>
              <GroupLabel data-test-automation-id="user-form-dialog-group-company">
                Company
              </GroupLabel>
              <GroupContent>
                <UserFormTextField
                  data-test-automation-id="user-form-dialog-field-company-name"
                  form={form}
                  fieldConfig={fieldConfig.companyName}
                />
                <UserFormSelector
                  data-test-automation-id="user-form-dialog-field-company-type"
                  form={form}
                  fieldConfig={fieldConfig.companyType}
                  options={buildCompanyTypeOptions()}
                />
                <UserFormSelector
                  data-test-automation-id="user-form-dialog-field-country"
                  form={form}
                  fieldConfig={fieldConfig.country}
                  options={countryOptions}
                  renderValue={(countryId) => countryDic[countryId]?.name}
                />
                <UserFormSelector
                  data-test-automation-id="user-form-dialog-field-employee"
                  form={form}
                  fieldConfig={fieldConfig.numberOfEmployees}
                  options={employeesOptions}
                />
                {requireState(country) && (
                  <UserFormSelector
                    data-test-automation-id="user-form-dialog-field-state"
                    form={form}
                    fieldConfig={fieldConfig.state}
                    options={stateOptions}
                  />
                )}
                <UserFormTextField
                  data-test-automation-id="user-form-dialog-field-city"
                  form={form}
                  fieldConfig={fieldConfig.city}
                />
              </GroupContent>
            </GroupTwoColumn>
            <GroupOneColumn>
              <GroupLabel />
              <GroupContent>
                <FormField>{extraControls}</FormField>
                <GoogleRecaptchaContainer
                  data-test-automation-id="user-form-dialog-google-recaptcha"
                  form={form}
                  fieldConfig={fieldConfig.recaptchaResponse}
                />
              </GroupContent>
            </GroupOneColumn>
          </DialogContent>
          <DialogActions>
            <RcLink
              href="https://www.ringcentral.com/legal/privacy-notice.html"
              target="_blank"
              data-test-automation-id="user-form-dialog-privacy-notice"
            >
              Privacy Notice
            </RcLink>
            {secondaryBtn}
            <SubmitButton
              type="button"
              onClick={handleSubmit}
              data-test-automation-id="user-form-dialog-submit-button"
            >
              Submit
            </SubmitButton>
          </DialogActions>
        </form>
        {recaptchaErrorVisible && (
          <Alert
            data-test-automation-id="user-form-dialog-google-recaptcha-error"
            title={languageService.get(Language.TITLE_SORRY)}
            message={languageService.get(
              Language.GOOGLE_RECAPTCHA_ERROR_VERIFICATION,
            )}
            onClose={closeRecaptchaError}
          />
        )}
      </div>
    </>
  );
};

export const UserForm = observer(UserFormPure);
