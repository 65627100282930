import { RcTypography, styled } from '@ringcentral/juno';
import { RatingInfo } from '@apw/lib/components/ratingAndReview/ratingInfo/RatingInfo';
import { JUNO_THEME_PREFIX } from '@apw/lib/constants';
import { onLtMidScreen } from '@apw/lib/sc';

export const Container = styled.div`
  width: 192px;

  ${onLtMidScreen} {
    width: 100%;
    margin-bottom: 28px;
  }
`;

export const RatingContainer = styled.div`
  &&& {
    .${JUNO_THEME_PREFIX}-MuiRating-decimal {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }

    .icon {
      font-size: 32px;
    }

    ${onLtMidScreen} {
      display: flex;
      align-items: center;
    }
  }
`;

export const StyledRating = styled(RatingInfo)`
  ${onLtMidScreen} {
    margin-top: 0;
    display: block;
  }
`;

export const Score = styled(RcTypography)`
  margin-bottom: 4px;
`;

export const CountInfo = styled(RcTypography)`
  margin-top: 20px;

  ${onLtMidScreen} {
    margin-left: 4px;
    margin-top: 0;
  }
`;
