import {
  BPS,
  respondTo,
  onMobile,
  useProximaNovaRegular,
  useProximaNovaSemibold,
} from '@apw/core/sc';
import {
  RcButton,
  RcDialog,
  RcDialogContent,
  RcDialogTitle,
  RcIconButton,
  styled,
} from '@ringcentral/juno';

export const DialogWrapper = styled(RcDialog)`
  [class~='MuiDialog-paper'] {
    max-width: none !important;
    width: auto !important;

    ${onMobile} {
      max-width: none !important;
      max-height: none !important;
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
    }
  }
`;

export const DialogTitle = styled(RcDialogTitle)`
  padding: 24px 24px 0 24px !important;

  h2 {
    ${useProximaNovaSemibold};
    font-size: 22px !important;
    font-weight: 600 !important;
    white-space: normal !important;
  }
`;

export const CloseButton = styled(RcIconButton)`
  position: absolute !important;
  top: 8px !important;
  right: 8px !important;
  color: #686869 !important;

  svg {
    font-size: 20px;
  }
`;

export const DialogContent = styled(RcDialogContent)`
  max-width: 800px;
  padding: 24px !important;

  ${onMobile} {
    padding: 0 15px 20px !important;
  }
`;

export const Main = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;

  ${respondTo(BPS.ltTablet)} {
    flex-direction: column;
  }

  & > * {
    &:not(:last-child) {
      margin-right: 20px;
    }

    ${respondTo(BPS.ltTablet)} {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
`;

export const ButtonHeader = styled.div`
  span {
    vertical-align: middle;
  }

  br {
    display: none;
  }

  ${onMobile} {
    font-size: 16px;
    line-height: 24px;

    br {
      display: block;
    }
  }
}
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 20px;
  background: #f9f9f9;

  ${ButtonHeader} {
    ${useProximaNovaRegular};
    margin: 0;
    color: #282829;
    font-size: 16px;
    text-align: left;

    ${respondTo(BPS.ltTablet)} {
      text-align: center;
    }
  }

  ${onMobile} {
    padding: 20px 15px 25px !important;
  }
`;

export const ButtonWrapper = styled(RcButton)`
  height: 40px;
  margin-top: 24px !important;
  ${useProximaNovaSemibold};

  &.signInBtn {
    width: 120px;
  }

  &.freeTrialBtn {
    width: 169px;
  }

  ${onMobile} {
    width: 100% !important;
    height: 48px !important;
    font-size: 20px !important;
  }
`;
