import { Popover } from '@apw/core/popover';
import { onMobile, PAGE_MOBILE_PADDING } from '@apw/core/sc';
import { styled, RcListItem } from '@ringcentral/juno';

export const MenuTrigger = styled(RcListItem)`
  height: 48px;
  ${onMobile} {
    padding: 4px ${PAGE_MOBILE_PADDING}px !important;
  }

  .icon svg {
    font-size: 24px;
  }
`;
export const FilterContainer = styled(Popover)`
  .contentWrap {
    height: 100%;
    padding: 8px 16px;
  }

  [class~='MuiPopover-paper'] {
    margin-top: 1px;
    box-shadow: none !important;
  }
`;

export const Divider = styled.div`
  background: #cbcbcb;
  height: 1px;
  margin: 18px auto;
`;
