import React, { useContext, useRef } from 'react';
import { RcLoading } from '@ringcentral/juno';
import { PageContentContext } from '@apw/lib/components/pageContent';
import { useInfiniteScroll } from '@apw/lib/hooks';
import { ICollectionBlock, IProfileInContentBlock } from '@apw/lib/types';
import { ProfileTile } from '../../profileTile';
import { BlockHeader } from '../shared';
import styles from './collectionBlock.module.scss';

export function CollectionBlock(props: {
  className: string;
  data: ICollectionBlock;
  isFirst: boolean;
  blockIndex: number;
}) {
  const {
    className = '',
    data: {
      title,
      subTitle,
      displayCount = 0,
      appList = [],
      loading = false,
    } = {},
    isFirst,
    blockIndex,
  } = props;

  const {
    styleTokens: { collectionBlock },
    translation: {
      certifiedBadge,
      premierPartner,
      getAppLogo,
      ratingsAndReviews,
    },
    onScrollOverCollectionBlock,
    scrollContainerRef,
  } = useContext(PageContentContext);

  const contentContainerRef = useRef(null);

  const onScroll = () => {
    if (onScrollOverCollectionBlock) {
      onScrollOverCollectionBlock(props.data, blockIndex);
    }
  };

  useInfiniteScroll(scrollContainerRef, onScroll, {
    contentContainerRef,
  });

  if (!appList || !appList.length) {
    return null;
  }

  const tileList = Array.from(
    appList
      .reduce((prev, curr) => {
        prev.set(curr.application.id, curr);
        return prev;
      }, new Map<string, IProfileInContentBlock>())
      .values(),
  ).slice(0, Number(displayCount));

  return (
    <div
      className={`${className} ${styles.wrapper} collectionBlock`}
      data-test-automation-id="collection-block"
      ref={contentContainerRef}
    >
      <BlockHeader
        data={{
          heading: title,
          subHeading: subTitle,
          styleTokens: {
            heading: collectionBlock.title,
            subHeading: collectionBlock.subTitle,
          },
        }}
        isH1={isFirst}
      />
      <div
        className={styles.collectionBody}
        data-test-automation-id="collection-list"
      >
        {tileList.map((app) => {
          return (
            <ProfileTile
              app={app}
              key={app.application.id}
              handleClick={app.onClick}
              styleTokens={collectionBlock.item}
              certifiedBadgeText={certifiedBadge}
              logoAlt={getAppLogo(app.application?.displayName || '')}
              premierPartnerText={premierPartner}
              countLabel={ratingsAndReviews.countLabel}
            />
          );
        })}
      </div>
      {loading && (
        <div
          className={styles.loadingWrapper}
          data-test-automation-id="collection-block-loading"
        >
          <RcLoading loading>
            <span />
          </RcLoading>
        </div>
      )}
    </div>
  );
}
