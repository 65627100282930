import React, { FC } from 'react';
import { RcIcon, styled } from '@ringcentral/juno';
import { ItemListSelected } from '@ringcentral/juno-icon';
import styles from './certifiedBadge.module.scss';

interface ICertifiedBadgeProps {
  text?: string;
  className?: string;
  color?: string;
}

export const CertifiedBadge: FC<ICertifiedBadgeProps> = ({
  text = 'CERTIFIED',
  className = '',
  color = '#32773B',
}) => {
  return (
    <CertifiedBadgeWrapper
      color={color}
      className={`${styles.wrapper} certifiedBadge ${className}`}
      data-test-automation-id="certifiedBadge"
    >
      <RcIcon
        className={styles.certifiedIcon}
        symbol={ItemListSelected}
        aria-hidden="true"
      />
      <span
        className={styles.certifiedText}
        data-test-automation-id="certifiedBadgeText"
      >
        {text}
      </span>
    </CertifiedBadgeWrapper>
  );
};

const CertifiedBadgeWrapper = styled.span`
  color: ${(props: any) => props.color};
  svg {
    path {
      fill: ${(props: any) => props.color};
    }
  }
`;
