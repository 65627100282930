import React, { FC, useEffect, useRef, useState } from 'react';
import { triggerDocumentHeightChange } from '@apw/components/ObserverScroll/ObserverScroll';
import { Sticky, StickyRef } from '@apw/components/sticky';
import { AppGalleryWarning } from '@apw/shared/appGalleryWarning';
import EventBanner from '@apw/shared/banner/EventBanner';
import { Footer } from '@apw/shared/footer';
import { Header } from '@apw/shared/header';
import {
  Container,
  HeaderContainer,
  HeaderWrap,
  Main,
} from '@apw/shared/withLayout/withLayout.sc';

type IWithLayoutProps = {
  stickyHeader: boolean;
};

type IWithLayoutOptions = {
  stickyHeader: boolean;
  disableGalleryWarning?: boolean;
};

export const withLayout = (
  Content,
  options = {} as IWithLayoutOptions,
): FC<IWithLayoutProps> => {
  return (props: IWithLayoutProps) => {
    const [headerHeight, setHeaderHeight] = useState(0);
    const stickyRef = useRef<StickyRef | null>(null);
    const { stickyHeader = false, disableGalleryWarning = false } = options;
    const onHeaderStickyChange = (isSticky, stickyRef) => {
      setHeaderHeight(stickyRef.offsetHeight);
    };

    const [isAppGalleryWarningVisible, setIsAppGalleryWarningVisible] =
      useState(false);

    useEffect(() => {
      setTimeout(() => {
        triggerDocumentHeightChange();
      });
    }, []);

    return (
      <Container data-test-automation-id="with-layout-container">
        <header>
          <EventBanner />

          <Sticky
            ref={stickyRef}
            enabled={stickyHeader}
            onChange={onHeaderStickyChange}
            position="top"
            style={{ zIndex: 101 }}
          >
            <HeaderWrap>
              <HeaderContainer>
                <Header />
              </HeaderContainer>
            </HeaderWrap>
          </Sticky>

          {!disableGalleryWarning && (
            <AppGalleryWarning
              onVisibilityChange={(visible) =>
                setIsAppGalleryWarningVisible(visible)
              }
            />
          )}
        </header>

        <Main data-test-automation-id="withLayout" role="main">
          <Content
            {...props}
            headerHeight={headerHeight}
            isAppGalleryWarningVisible={isAppGalleryWarningVisible}
          />
        </Main>

        <Footer />
      </Container>
    );
  };
};
