type BreakpointValue = number | [number] | [number, number];
type Breakpoint =
  | 'mobile'
  | 'tablet'
  | 'desktop'
  | 'ltTablet'
  | 'gtTablet'
  | 'ltDesktop'
  | 'gtDesktop'
  | 'headerBlock'
  | 'contentCard1'
  | 'contentCard2';

type ValueByBreakpoint = {
  [propName in Breakpoint]: BreakpointValue;
};

// breakpoints
export const BPS: ValueByBreakpoint = {
  mobile: [0, 768],
  tablet: [768, 996],
  desktop: [996, 1296],
  ltTablet: [0, 996],
  gtTablet: 996,
  ltDesktop: [0, 1296],
  gtDesktop: 1296,
  headerBlock: [0, 644],
  contentCard1: [0, 481],
  contentCard2: [481, 996],
};

/**
 * @description
 * - number: min-width
 * - [number]: min-width
 * - [number, number]: min-width and max-width
 */
export const respondTo = (breakpoint: BreakpointValue) => {
  if (!Array.isArray(breakpoint)) {
    return `@media (min-width: ${breakpoint}px)`;
  }

  if (breakpoint.length === 1) {
    return `@media (min-width: ${breakpoint[0]}px)`;
  }

  return `@media (min-width: ${breakpoint[0]}px) and (max-width: ${breakpoint[1]}px)`;
};

export const onMobile = respondTo(BPS.mobile);
