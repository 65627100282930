import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { List, SectionTitle } from './SearchResultSection.sc';

const SearchResultSectionPure = (props: {
  title: string;
  children: ReactNode;
  isH1: boolean;
}) => {
  const titleA11yProps = props.isH1
    ? {
        role: 'heading',
        'aria-level': 1,
      }
    : {};
  return (
    <React.Fragment>
      <SectionTitle
        data-test-automation-id="search-result-title"
        {...titleA11yProps}
      >
        {props.title}
      </SectionTitle>
      <List data-test-automation-id="search-result-list">{props.children}</List>
    </React.Fragment>
  );
};

export const SearchResultSection = observer(SearchResultSectionPure);
