import { safeWindowOpen } from '@apw/core';

export const clickAToNewTab = (element) => {
  if (!element) {
    return;
  }
  const eleList = element!.querySelectorAll('a');
  eleList.forEach((item) => {
    if (item.target !== '_blank') {
      item.onclick = (e) => {
        e.preventDefault();
        const link = item.href;
        if (isInternalLink(link)) {
          window.location.href = link;
        } else {
          safeWindowOpen(link);
        }
      };
    }
  });
};

const isInternalLink = (link: string) => {
  if (!link || !link.trim()) {
    return false;
  }
  if (link.startsWith('./')) {
    return true;
  }
  if (link.startsWith(process.env.PUBLIC_URL)) {
    return true;
  }
  const origin = window.location.origin;
  return link.startsWith(`${origin}${process.env.PUBLIC_URL}`);
};
