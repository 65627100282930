import React, { useEffect } from 'react';
import { mixpanel, useAPIRequestLoading } from '@apw/core';
import { loadGoogleRecaptcha } from '@apw/core/google-recaptcha';
import { updateParams } from '@apw/core/url/url.service';
import {
  GalleryThemeProvider,
  GlobalInformDialogContext,
  GlobalLoading,
  withInformDialog,
} from '@apw/shared';
import { useStores } from '@apw/stores';
import { getLoginUrl, signInSSO } from '@apw/stores/user';
import { SSOMessage } from '@apw/types/SSOMessage';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppRoutes } from './routes';
import './App.css';

export const useSSO = () => {
  const { userStore } = useStores();
  const history = useHistory();

  useEffect(() => {
    userStore.loadUser(true).catch(async () => {
      const url = await getLoginUrl();
      if (url) {
        signInSSO(`${url}&prompt=none`);
      }
    });
  }, [userStore]);

  useEffect(() => {
    const handle = async (event) => {
      if (event.data.type === SSOMessage.ERROR) {
        userStore.setSSOLoading(false);
        return;
      }
      // Avoid impact from other iframe
      if (event.data.type === SSOMessage.SUCCESS) {
        try {
          await userStore.loadUser();
          const compatibilityValue = event.data.compatibilityValue;
          // react-router useLocation can only get the location at the time of component rendering
          const pathname = window.location.pathname.split(/^\/apps/)[1];
          const relPath = pathname + window.location.search;
          const url = updateParams(relPath, {
            compatibility: compatibilityValue,
          });
          history.replace(url);
        } finally {
          userStore.setSSOLoading(false);
        }
      }
    };
    window.addEventListener('message', handle);
    return () => {
      window.removeEventListener('message', handle);
    };
  }, []);
};

function App() {
  const {
    mixpanelStore,
    googleRecaptchaStore,
    compatibilityStore,
    compatibilitiesStore,
  } = useStores();
  const [isLoading] = useAPIRequestLoading();
  const { pathname } = useLocation();
  const loading = compatibilitiesStore.loading;
  const resolved = Boolean(compatibilityStore.compatibility);
  useSSO();

  useEffect(() => {
    if (loading) {
      return;
    }

    compatibilityStore.resolve(pathname);
  }, [pathname, loading]);

  useEffect(() => {
    if (mixpanelStore.isEnabled) {
      mixpanel.init(mixpanelStore.token, {
        autotrack: false,
        persistence: 'localStorage',
        cross_subdomain_cookie: false,
      });
    }
  }, [mixpanelStore.isEnabled]);

  if (!mixpanelStore.isEnabled) {
    mixpanelStore.setIsEnabled();
  }

  useEffect(() => {
    if (googleRecaptchaStore.isEnabled) {
      loadGoogleRecaptcha({
        siteKey: googleRecaptchaStore.siteKey,
      });
    }
  }, [googleRecaptchaStore.isEnabled]);

  if (!resolved) {
    return null;
  }

  return (
    <GalleryThemeProvider>
      <AppRoutesWithInformDialog />
      <GlobalLoading isLoading={isLoading} />
    </GalleryThemeProvider>
  );
}

const AppRoutesWithInformDialog = withInformDialog(
  AppRoutes,
  GlobalInformDialogContext,
);

export default observer(App);
