/**
 * The event names are specified in below Google Sheet
 * Legacy: https://docs.google.com/spreadsheets/d/1KmDP0TXaXwaHjEFZl_6tHmdN45GPF9N5jqa5GSRuLdg/edit#gid=1080979200
 * New:    https://docs.google.com/spreadsheets/d/1x8FxqTq39vs7zXcDR8WuUnbqaUDE3evYjaJsx64QGGg/edit#gid=0
 */
export enum TrackedEvent {
  PAGEVIEWS = 'Pageviews',
  ACTIONS = 'Actions',
  AUTHORIZE_BOT = 'AG: Authorized Bot',
  INSTALL_BOT = 'AG: Installed Bot',
  REMOVE_BOT = 'AG: Removed Bot',
  SEARCH_BEHAVIOR = 'Search Behavior',
  AG_CONTACT_US_PROMPT = 'AG: Contact Us Prompt',
  AG_CONTACT_US_SUBMITTED = 'AG: Contact Us Submitted',
  AG_BIG_BLUE_BUTTON_CLICKED = 'AG: Big Blue Button Clicked',
  SEARCH_CLICK = 'Search click',
  AG_Get_SUPPORT = 'AG: Get support',
  RATING_PENDING = 'Rating: Submit for approval',
  RATING_STATE_CHANGE = 'Rating: State change',
}
