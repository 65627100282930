export const countriesInEU = [
  '16', // Austria
  '23', // Belgium
  '34', // Bulgaria
  '54', // Croatia
  '56', // Cyprus
  '57', // Czech Republic
  '58', // Denmark
  '69', // Estonia
  '74', // Finland
  '75', // France
  '83', // Germany
  '86', // Greece
  '101', // Hungary
  '107', // Ireland
  '109', // Italy
  '121', // Latvia
  '127', // Lithuania
  '128', // Luxembourg
  '136', // Malta
  '157', // Netherlands
  '176', // Poland
  '177', // Portugal
  '182', // Romania
  '189', // Slovakia
  '190', // Slovenia
  '195', // Spain
  '205', // Sweden
  '224', // United Kingdom
];
