import { onMobile } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const Wrapper = styled.div`
  width: 100%;
  padding: 4px 0 24px 32px;

  ${onMobile} {
    padding: 0;
  }
`;
