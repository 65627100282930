import { styled } from '@ringcentral/juno';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SlideWrap = styled.div`
  width: 488px;
`;
