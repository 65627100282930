import { RatingPopupOption } from '@apw/components/ratingAndReview/popup/ratingPopup.store';
import {
  deleteMyReviewItem,
  fetchMyReviewItem,
  fetchPublishedReviewItems,
} from '@apw/components/ratingAndReview/reviewList/reviewList.network';
import {
  IMyReviewItemResponse,
  IPublishedReviewItemsResponse,
  IReviewItem,
} from '@apw/components/ratingAndReview/typings/reviewItem.interface';
import { api } from '@apw/core';
import { fetchCountry } from '@apw/core/company';
import {
  getConfirmationInfo,
  isFreeTier,
  removeBot,
  requestBotConfirmationInfo,
  requestInstallBot,
} from '@apw/modules/botProvision';
import { IBotConfirmationInfo } from '@apw/modules/botProvision/typings';
import { fetchProfilesFromBE } from '@apw/modules/download/download.service';
import {
  IAdditionalAppInfo,
  IClientInfoWebUrisResponse,
  IRawBotItemsResponse,
} from '@apw/modules/myApps';
import {
  requestAdditionalAppInfo,
  requestClientInfoWebUris,
  requestInstalledBots,
} from '@apw/modules/myApps/myApp.service';
import {
  fetchCollectionAppProfiles,
  fetchGalleryCollectionAppProfiles,
  fetchGalleryPageContent,
  fetchPageContent,
  fetchProfilesByAppCollection,
  getProfileByAppIdAndBrandId,
} from '@apw/modules/page/page.service';
import { loadGalleryPageForPreview } from '@apw/modules/page/preview/pagePreviewService';
import { IPagePreviewRequest } from '@apw/modules/page/preview/typings';
import { IPagePreviewResponse } from '@apw/modules/page/preview/typings/pagePreviewResponse.interface';
import { CollectionAppProfilesResponseInterface } from '@apw/modules/page/typings/collection-app-profiles-response.interface';
import { IPageContent } from '@apw/modules/page/typings/page-content.interface';
import { submitGuestForm, submitLeadForm } from '@apw/modules/profile';
import { loadProfileForPreview } from '@apw/modules/profile/preview/profilePreviewService';
import {
  loadProfile,
  loadProfileByBrand,
} from '@apw/modules/profile/profile.service';
import { loadCategory } from '@apw/modules/search';
import { loadMultipleApps } from '@apw/modules/search/filterBar/multipleAppsFilter';
import { searchIpaas } from '@apw/modules/search/ipaasSearch/ipaasSearch.service';
import { IpaasListResponseInterface } from '@apw/modules/search/ipaasSearch/typings';
import { searchProfiles } from '@apw/modules/search/profileSearch';
import { getBanner } from '@apw/shared/banner/event-banner.service';
import {
  loadGalleryPagesForAllBrands,
  loadPages,
  loadPagesForAllBrands,
} from '@apw/shared/navigation/pageNavigation';
import {
  ICoveoRequestForPages,
  ICoveoRequestForProfiles,
  requestCoveoSearchFlag,
  ICoveoResponseForPages,
  ICoveoResponseForProfiles,
} from '@apw/shared/searchProvider';
import * as coveoCloudService from '@apw/shared/searchProvider/coveo/coveoNetworkService';
import {
  IGalleryBrand,
  IGalleryBrandsLoaderOptions,
  loadGalleryBrands,
} from '@apw/stores/compatibility/compatibilities/galleryBrand';
import {
  getAdditionalAccountInfo,
  getExtension,
  getUser,
} from '@apw/stores/user';
import { TransportInterface } from '@apw/transport.interface';
import {
  IPagesForAllBrandsResponse,
  IProfile,
  PagesInterface,
  ProfileListResponseInterface,
} from '@apw/types';
import { IProfileByAppAndBrandResponse } from '@apw/types/getProfileByAppAndBrandResponse.interface';
import { ProfileListRequestInterface } from '@apw/types/profileListRequest.interface';

export class TransportOnAPW implements TransportInterface {
  public fetchGalleryBrands(
    options?: IGalleryBrandsLoaderOptions,
  ): Promise<IGalleryBrand[]> {
    return loadGalleryBrands(options);
  }

  public fetchUser() {
    return getUser();
  }

  public fetchExtension() {
    return getExtension();
  }

  public fetchAdditionalAccountInfo() {
    return getAdditionalAccountInfo();
  }

  public fetchCompany() {
    return fetchCountry();
  }

  public fetchProfile(
    vanityUrl: string,
    useGlobalLoading?: boolean,
  ): Promise<IProfile> {
    return loadProfile(vanityUrl, useGlobalLoading);
  }

  public fetchProfileByBrand(
    vanityUrl: string,
    brand: string,
    useGlobalLoading?: boolean,
  ): Promise<IProfile> {
    return loadProfileByBrand(vanityUrl, brand, useGlobalLoading);
  }

  public fetchProfileForPreview(
    profileId: string,
    timestamp: string,
    token: string,
    type: string,
    brandId?: string,
  ): Promise<IProfile> {
    return loadProfileForPreview(profileId, timestamp, token, type, brandId);
  }

  public fetchPages(brandId: string): Promise<PagesInterface> {
    return loadPages(brandId);
  }

  public fetchPagesForAllBrands(): Promise<IPagesForAllBrandsResponse> {
    return loadPagesForAllBrands();
  }

  public fetchGalleryPagesForAllBrands(): Promise<IPagesForAllBrandsResponse> {
    return loadGalleryPagesForAllBrands();
  }

  public fetchGalleryPageForPreview(
    request: IPagePreviewRequest,
  ): Promise<IPagePreviewResponse> {
    return loadGalleryPageForPreview(request);
  }

  public fetchPageContent(pageId: number) {
    return fetchPageContent(pageId);
  }

  public fetchGalleryPageContent(
    pageId: number,
    brandId: string,
  ): Promise<IPageContent> {
    return fetchGalleryPageContent(pageId, brandId);
  }

  public fetchCollectionAppProfiles(
    id: number,
    page: number,
    perPage?: number,
  ) {
    return fetchCollectionAppProfiles(id, page, perPage);
  }

  public fetchProfilesByAppCollection(
    id: number,
    brandId: string,
    page: number,
    perPage?: number,
  ) {
    return fetchProfilesByAppCollection(id, brandId, page, perPage);
  }

  public fetchGalleryCollectionAppProfiles(
    id: number,
    brandId: string,
    page: number,
    perPage: number,
  ): Promise<CollectionAppProfilesResponseInterface> {
    return fetchGalleryCollectionAppProfiles(id, brandId, page, perPage);
  }

  public fetchBotConfirmationInfo(
    appId: string,
  ): Promise<IBotConfirmationInfo> {
    return requestBotConfirmationInfo(appId);
  }

  public fetchIsFreeTier(): Promise<boolean> {
    return isFreeTier();
  }

  public fetchInstallBot(
    clientId: string,
    botName: string,
    customerParameter?: string,
  ) {
    return requestInstallBot(clientId, botName, customerParameter);
  }

  public searchProfiles(
    request: ProfileListRequestInterface,
    options?: { useGlobalLoading?: boolean },
  ): Promise<ProfileListResponseInterface> {
    return searchProfiles(request, options);
  }

  public fetchMultipleApps(
    request: ProfileListRequestInterface,
  ): Promise<ProfileListResponseInterface> {
    return loadMultipleApps(request);
  }

  public searchIpaas(
    searchText: string,
    brandId: string,
    page: number,
  ): Promise<IpaasListResponseInterface> {
    return searchIpaas(searchText, brandId, page);
  }

  public removeBot(clientId: string) {
    return removeBot(clientId);
  }

  public getConfirmationInfo(appId: string): Promise<IBotConfirmationInfo> {
    return getConfirmationInfo(appId);
  }

  public fetchClientInfoWebUris(): Promise<IClientInfoWebUrisResponse> {
    return requestClientInfoWebUris();
  }

  public fetchInstalledBots(): Promise<IRawBotItemsResponse> {
    return requestInstalledBots();
  }

  public fetchAdditionalAppInfo(
    botExtensionId: string,
  ): Promise<IAdditionalAppInfo> {
    return requestAdditionalAppInfo(botExtensionId);
  }

  public fetchCategories(brandId: string, product: string): Promise<string[]> {
    return loadCategory(brandId, product);
  }

  public submitLeadForm(
    appProfileId: string,
    appProfileName: string,
    values: any,
  ): Promise<any> {
    return submitLeadForm(appProfileId, appProfileName, values);
  }

  public submitGuestForm(appProfileId: string, values: any): Promise<any> {
    return submitGuestForm(appProfileId, values);
  }

  public getBanner() {
    return getBanner();
  }

  public getProfileByAppIdAndBrandId(
    appId: string,
    brandId: string,
  ): Promise<IProfileByAppAndBrandResponse> {
    return getProfileByAppIdAndBrandId(appId, brandId);
  }

  public requestCoveoSearchFlag() {
    return requestCoveoSearchFlag();
  }

  searchPagesFromCoveo(
    request: ICoveoRequestForPages,
  ): Promise<ICoveoResponseForPages> {
    return coveoCloudService.searchPages(request);
  }

  searchProfilesFromCoveo(
    request: ICoveoRequestForProfiles,
  ): Promise<ICoveoResponseForProfiles> {
    return coveoCloudService.searchProfiles(request);
  }

  public fetchProfilesToDownload(
    brandId: string,
    app?: string,
    osList?: string[],
  ): Promise<IProfile[]> {
    return fetchProfilesFromBE(brandId, app, osList);
  }

  fetchMyReviewItem(applicationId: string): Promise<IMyReviewItemResponse> {
    return fetchMyReviewItem(applicationId);
  }

  fetchPublishedReviewItems(
    applicationId: string,
    page: number,
  ): Promise<IPublishedReviewItemsResponse> {
    return fetchPublishedReviewItems(applicationId, page);
  }

  saveReview(
    applicationId: string,
    review: RatingPopupOption,
  ): Promise<IReviewItem> {
    return api.put<IReviewItem>(
      `/api/rating-review/applications/${applicationId}/reviews/my-review`,
      review,
      {
        useGlobalLoading: true,
      },
    );
  }

  deleteMyReviewItem(applicationId: string): Promise<void> {
    return deleteMyReviewItem(applicationId);
  }
}
