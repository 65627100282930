import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { transport } from '@apw/core';
import { getSearchParams } from '@apw/core/url/url.service';
import { useDebounce } from '@apw/hooks/useDebounce';
import { allCategories, allProductsItem } from '@apw/modules/search/filterBar';
import { IpaasListResponseInterface } from '@apw/modules/search/ipaasSearch/typings';
import {
  getCompatibilityPagePath,
  getCompatibilityProfilePath,
} from '@apw/routes/getCompatibilityPaths';
import { useCompatibilitySearchPath } from '@apw/routes/useCompatibilityPaths';
import {
  normalizePageResults,
  normalizeProfileResults,
} from '@apw/shared/coveoSearch/coveoAdapter.service';
import { CoveoSearchContext } from '@apw/shared/coveoSearch/CoveoSearch.context';
import SearchInput from '@apw/shared/coveoSearch/searchInput/SearchInput';
import ContentView from '@apw/shared/coveoSearch/shared/contentView/ContentView';
import { IPageCard } from '@apw/shared/coveoSearch/shared/pageCard/page.interface';
import { IProfileCard } from '@apw/shared/coveoSearch/shared/profileCard/profileCard.interface';
import {
  ICoveoResponseForPages,
  ICoveoResponseForProfiles,
} from '@apw/shared/searchProvider';
import {
  SearchResult,
  SearchResultType,
  SearchSource,
  searchTracking,
} from '@apw/shared/searchTracking';
import { useStores } from '@apw/stores';
import { useCurrentCompatibility } from '@apw/stores/compatibility';
import { GalleryPageSection } from '@apw/stores/pages';
import {
  IpaasListItem,
  PageListItem,
  PagesInterface,
  PagingInterface,
  ProfileListResponseInterface,
} from '@apw/types';
import { observer } from 'mobx-react';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import { PER_PAGE_FOR_PAGES, PER_PAGE_FOR_PROFILES } from '../searchProvider';
import { withGlobalScroll } from '../withGlobalScroll';
import {
  ContentViewWrapper,
  Dialog,
  SearchFooter,
  SearchResultContainer,
  ShowAllButton,
  SuggestAppStyled,
} from './CoveoSearch.sc';

enum SearchState {
  SUGGESTIONS,
  RESULTS,
  NO_RESULTS,
}

const searchStateMap = {
  [SearchState.SUGGESTIONS]: { profileAndIpaasLimit: 3, pageLimit: 2 },
  [SearchState.RESULTS]: { profileAndIpaasLimit: 4, pageLimit: 2 },
  [SearchState.NO_RESULTS]: { profileAndIpaasLimit: 0, pageLimit: 0 },
};

export const CoveoSearch = withGlobalScroll(
  observer(({ dialogClose }: { dialogClose: Function }) => {
    const { compatibilityStore, searchStore, coveoSearchStore, pagesStore } =
      useStores();

    const searchPath = useCompatibilitySearchPath();
    const compatibility = useCurrentCompatibility();

    const searchState = useRef(SearchState.SUGGESTIONS);
    const cacheKeyword = useRef<string>((getSearchParams().q as string) || '');

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [isKeywordResultMatched, setIsKeywordResultMatched] = useState(false);

    const [profileItems, setProfileItems] = useState<IProfileCard[]>([]);
    const [ipaasItems, setIpaasItems] = useState<IpaasListItem[]>([]);
    const [pageItems, setPageItems] = useState<IPageCard[]>([]);

    const [profileTotalCount, setProfileTotalCount] = useState(0);
    const [ipaasTotalCount, setIpaasTotalCount] = useState(0);
    const [pageTotalCount, setPageTotalCount] = useState(0);

    // eslint-disable-next-line
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);

    const profileAndIpaasItemsForDisplay = useMemo(() => {
      const { profileAndIpaasLimit } = searchStateMap[searchState.current];
      return [...profileItems, ...ipaasItems].slice(0, profileAndIpaasLimit);
    }, [profileItems, ipaasItems]);

    const pageItemsForDisplay = useMemo(() => {
      const { pageLimit } = searchStateMap[searchState.current];
      return pageItems.slice(0, pageLimit);
    }, [pageItems]);

    const searchProfilesFromCoveo = async (
      keyword: string,
      limit: number,
    ): Promise<ICoveoResponseForProfiles | ProfileListResponseInterface> => {
      try {
        const res = await transport.searchProfilesFromCoveo({
          brand: compatibilityStore.brandId,
          keyword,
          paging: {
            page: 1,
            perPage: limit,
          },
        });
        setProfileTotalCount(res.totalCount);
        return res;
      } catch (err) {
        if (err && err.response && err.response.status === 429) {
          return searchProfilesFromAPS(keyword, limit);
        }
        throw err;
      }
    };

    // eslint-disable-next-line @typescript-eslint/default-param-last
    const searchProfilesFromAPS = async (keyword = '', limit) => {
      const res = await transport.searchProfiles(
        {
          brand: compatibilityStore.brandId,
          searchText: keyword,
          perPage: limit,
        },
        {
          useGlobalLoading: false,
        },
      );
      setProfileTotalCount(res.paging.totalElements);
      res.items.forEach((item) => {
        item.path = getCompatibilityProfilePath(
          compatibility.slug,
          item.vanityUrl || item._id,
        );
      });
      return res;
    };

    const searchPagesFromCoveo = async (
      keyword: string,
      limit: number,
    ): Promise<ICoveoResponseForPages> => {
      try {
        const res = await transport.searchPagesFromCoveo({
          brand: compatibilityStore.brandId,
          keyword,
          paging: {
            page: 1,
            perPage: limit,
          },
        });
        setPageTotalCount(res.totalCount);
        return res;
      } catch (err) {
        if (err && err.response && err.response.status === 429) {
          const totalCount = 0;
          setPageTotalCount(totalCount);
          return { results: [], totalCount };
        }
        throw err;
      }
    };

    const searchFromIpaas = async (keyword, brandId) => {
      const res = await transport.searchIpaas(keyword, brandId, 1);
      setIpaasTotalCount(res.paging.totalElements);
      return res;
    };

    const checkProfileFromCoveo = (profiles) => {
      return profiles.totalCount !== undefined;
    };

    const formatProfileToArray = (response): IProfileCard[] => {
      if (checkProfileFromCoveo(response)) {
        const coveoRes = response as ICoveoResponseForProfiles;
        return normalizeProfileResults(coveoRes.results);
      }
      return (response as ProfileListResponseInterface).items as IProfileCard[];
    };

    const getDataByCoveoFlag = async () => {
      const keyword = cacheKeyword.current;
      const brandId = compatibilityStore.brandId;

      if (coveoSearchStore.isEnabled) {
        const [resForProfiles, resForPages, resForIpaas] = await Promise.all([
          searchProfilesFromCoveo(keyword, PER_PAGE_FOR_PROFILES),
          searchPagesFromCoveo(keyword, PER_PAGE_FOR_PAGES),
          searchFromIpaas(keyword, brandId),
        ]);
        const profiles = formatProfileToArray(resForProfiles);
        return [profiles, resForPages, resForIpaas];
      }
      const [resForProfiles, resForIpaas] = await Promise.all([
        searchProfilesFromAPS(keyword, PER_PAGE_FOR_PROFILES),
        searchFromIpaas(keyword, brandId),
      ]);
      return [resForProfiles.items, { results: [] }, resForIpaas];
    };

    const setItems = (
      profileItems: IProfileCard[],
      ipaasItems: IpaasListItem[],
      pageItems: IPageCard[],
    ) => {
      setProfileItems(profileItems);
      setIpaasItems(ipaasItems);
      setPageItems(pageItems);
    };

    const resetItems = () => {
      setItems([], [], []);
    };

    const resetTotalCounts = () => {
      setProfileTotalCount(0);
      setIpaasTotalCount(0);
      setPageTotalCount(0);
    };

    const loadSearchResults = async () => {
      setLoading(true);
      try {
        // @ts-ignore
        const [resForProfiles, resForPage, resForIpaas]: [
          IProfileCard[],
          ICoveoResponseForPages,
          IpaasListResponseInterface,
        ] = await getDataByCoveoFlag();
        if (
          resForProfiles.length === 0 &&
          resForPage.results.length === 0 &&
          resForIpaas.items.length === 0
        ) {
          searchState.current = SearchState.NO_RESULTS;
          resetItems();
          resetTotalCounts();
          setLoading(false);
          setIsKeywordResultMatched(true);
          forceUpdate();
          return;
        }
        searchState.current = SearchState.RESULTS;

        const pageItems = normalizePageResults(resForPage.results);
        const ipaasItems = (resForIpaas as IpaasListResponseInterface).items;
        const profileItems = resForProfiles;

        setItems(profileItems, ipaasItems, pageItems);
      } catch (error) {
        resetItems();
        resetTotalCounts();
      }
      setLoading(false);
      setIsKeywordResultMatched(true);
    };

    const loadSuggestion = async () => {
      setLoading(true);

      try {
        const resForProfiles = await searchProfilesFromAPS(
          '',
          PER_PAGE_FOR_PROFILES,
        );

        const profileItems = resForProfiles.items as IProfileCard[];

        const allPages = resolveTopCollections(pagesStore.pages);

        const pageItems: IPageCard[] = allPages.map((item) => ({
          ...item,
          path: getCompatibilityPagePath(compatibility.slug, item.vanityUrl),
        }));

        setItems(profileItems, [], pageItems);

        setProfileTotalCount(resForProfiles.paging.totalElements);
        setIpaasTotalCount(0);
        setPageTotalCount(allPages.length);
      } catch (error) {
        resetItems();
        resetTotalCounts();
      }
      setLoading(false);
      setIsKeywordResultMatched(true);
    };

    const debounceLoadSearchResults = useDebounce(loadSearchResults, 1000, {
      leading: true,
      trailing: false,
    });

    const debounceLoadSuggestion = useDebounce(loadSuggestion, 1000, {
      leading: true,
      trailing: false,
    });

    const handleSearch = () => {
      const keyword = cacheKeyword.current;
      if (keyword) {
        debounceLoadSearchResults();
      } else {
        searchState.current = SearchState.SUGGESTIONS;
        debounceLoadSuggestion();
      }
    };

    const debounceKeywordChangeSearch = useDebounce(handleSearch, 1000);

    const debounceKeydown = useDebounce(
      (key) => {
        if (key === 'Enter') {
          showAllResult();
        }
      },
      200,
      { leading: true },
    );

    const onKeywordChange = (keyword) => {
      cacheKeyword.current = keyword;
      setIsKeywordResultMatched(false);
      debounceKeywordChangeSearch();
    };

    const resolveTopCollections = (pages: PagesInterface): PageListItem[] => {
      const pageSections = [
        GalleryPageSection.DISCOVER_APPS,
        GalleryPageSection.PRODUCTS,
        GalleryPageSection.CATEGORIES,
      ];

      const candidates: PageListItem[] = [];
      pageSections.forEach((section) => {
        if (pages[section]) {
          candidates.push(...pages[section]);
        }
      });
      return candidates;
    };

    const showAllResult = () => {
      dialogClose();

      if (isKeywordResultMatched) {
        searchStore.setFirstScreenPageCache({
          items: pageItems as any[],
          paging: {
            page: 1,
            perPage: PER_PAGE_FOR_PAGES,
            totalElements: pageTotalCount,
            totalPages: Math.ceil(pageTotalCount / PER_PAGE_FOR_PAGES) || 1,
          } as PagingInterface,
        });

        searchStore.setFirstScreenProfileCache({
          items: profileItems as any[],
          paging: {
            page: 1,
            perPage: PER_PAGE_FOR_PROFILES,
            totalElements: profileTotalCount,
            totalPages:
              Math.ceil(profileTotalCount / PER_PAGE_FOR_PROFILES) || 1,
          } as PagingInterface,
        });
      }

      history.push(
        `${searchPath}${qs.stringify(
          { q: cacheKeyword.current },
          { addQueryPrefix: true },
        )}`,
      );
    };

    const handleClose = () => {
      dialogClose();
    };

    const onSearchClick = (
      clickLink: string,
      resultType: SearchResultType,
      clickDepth: number,
    ): void => {
      const params = {
        productFilter: allProductsItem.id,
        categoryFilter: allCategories.id,
        brand: compatibilityStore.compatibility.name,
        searchTerm: cacheKeyword.current,
        destinationURL: clickLink,
        resultType,
        clickDepth: buildClickDepth(resultType, clickDepth),
        resultCount: `${profileTotalCount + ipaasTotalCount + pageTotalCount}`,
        source: SearchSource.FLYOUT_RESULTS,
      };
      // @ts-ignore
      searchTracking(params);
    };

    const buildClickDepth = (
      resultType: SearchResultType,
      clickDepth: number,
    ) => {
      if ([SearchResult.PROFILE, SearchResult.IPAAS].includes(resultType)) {
        return `${clickDepth}`;
      }

      return `${profileAndIpaasItemsForDisplay.length + clickDepth}`;
    };

    useEffect(() => {
      const onEvent = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          handleClose();
        }
      };
      window.addEventListener('keyup', onEvent);
      return () => window.removeEventListener('keyup', onEvent);
    }, []);

    useEffect(() => {
      handleSearch();
    }, []);

    return (
      <Dialog open>
        <SearchInput
          handleClose={dialogClose}
          initialValue={cacheKeyword.current}
          handleKeyDown={debounceKeydown}
          handleChange={onKeywordChange}
          data-test-automation-id="search-input-container"
        />
        <SearchResultContainer role="main">
          <CoveoSearchContext.Provider value={{ handleClose }}>
            <ContentViewWrapper>
              {searchState.current === SearchState.SUGGESTIONS && (
                <ContentView
                  loading={loading}
                  profileSectionTitle="POPULAR APPS"
                  profiles={profileAndIpaasItemsForDisplay}
                  pageSectionTitle="TOP COLLECTIONS"
                  pages={pageItemsForDisplay}
                  handleClick={onSearchClick}
                />
              )}
              {searchState.current === SearchState.RESULTS && (
                <ContentView
                  loading={loading}
                  profileSectionTitle="TOP MATCHES IN APPS"
                  profiles={profileAndIpaasItemsForDisplay}
                  pageSectionTitle="TOP MATCHES IN COLLECTIONS"
                  pages={pageItemsForDisplay}
                  handleClick={onSearchClick}
                />
              )}
              {searchState.current === SearchState.NO_RESULTS && (
                <SuggestAppStyled />
              )}
            </ContentViewWrapper>
          </CoveoSearchContext.Provider>
          {searchState.current === SearchState.RESULTS && !loading && (
            <SearchFooter data-test-automation-id="search-footer">
              <ShowAllButton
                size="large"
                onClick={showAllResult}
                data-test-automation-id="show-all-result-btn"
              >
                Show all results
              </ShowAllButton>
            </SearchFooter>
          )}
        </SearchResultContainer>
      </Dialog>
    );
  }),
);
