import { IpaasPlatform } from '@apw/modules/search/ipaasSearch/ipaasPlatform.enum';
import { IpaasPlatformInterface } from '@apw/modules/search/ipaasSearch/ipaasPlatform.interface';

export const IpaasPlatforms: { [key: number]: IpaasPlatformInterface } = {
  [IpaasPlatform.ZAPIER]: {
    id: 'zapier',
    name: 'Zapier',
  },

  [IpaasPlatform.WORKATO]: {
    id: 'workato',
    name: 'Workato',
    squareIconWithSpacing: true,
  },

  [IpaasPlatform.TRAY_IO]: {
    id: 'tray.io',
    name: 'Tray.io',
  },

  [IpaasPlatform.PIESYNC]: {
    id: 'piesync',
    name: 'PieSync',
  },

  [IpaasPlatform.TENFOLD]: {
    id: 'tenfold',
    name: 'Tenfold',
  },
  [IpaasPlatform.PIPEDREAM]: {
    id: 'pipedream',
    name: 'Pipedream',
  },
};
