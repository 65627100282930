import React, { FC, useCallback, useContext } from 'react';
import { RcLoading } from '@ringcentral/juno';
import { observer } from 'mobx-react';
import { useScrollLoading } from '@drw/common';
import { ProfileDetailContext } from '@apw/lib/components/profileDetail';
import { ReviewItem } from '@apw/lib/components/ratingAndReview/reviewItem/ReviewItem';
import { IReviewItem } from '@apw/lib/components/ratingAndReview/typings/reviewItem.interface';
import { useIsMobile } from '@apw/lib/hooks/useIsMobile';
import {
  Divider,
  LoadingWrapper,
  SectionBody,
  SectionHeading,
  StyledNoReviewsSection,
  TotalNumber,
  Wrapper,
} from './ReviewsSection.sc';

export const ReviewsSection: FC = observer(() => {
  const {
    myPendingReviewItem,
    showMyPendingReview,
    isPublishedReviewItemsLoading,
    publishedReviewItems,
    totalNumberForReviews,
    pagingForReviews,
    onDeleteReview,
    loadPublishedReviewItems,
    openRatingPopup,
    translation,
    scrollContainerRef,
  } = useContext(ProfileDetailContext);

  const isMobile = useIsMobile();

  const onScroll = useCallback(() => {
    if (!pagingForReviews) {
      return;
    }

    if (pagingForReviews.page < pagingForReviews.totalPages) {
      loadPublishedReviewItems(pagingForReviews.page + 1);
    }
  }, [pagingForReviews, loadPublishedReviewItems]);

  useScrollLoading(scrollContainerRef, onScroll);

  const onEdit = useCallback(() => {
    openRatingPopup();
  }, []);

  const onDelete = (reviewItem: IReviewItem) => {
    onDeleteReview?.(reviewItem);
  };

  const isLast = useCallback(
    (index) => {
      return index === publishedReviewItems.length - 1;
    },
    [publishedReviewItems && publishedReviewItems.length],
  );

  return (
    <Wrapper data-test-automation-id="reviews-section">
      <SectionHeading
        variant="title2"
        color="neutral.f06"
        data-test-automation-id="reviews-section-heading"
      >
        {translation.ratingsAndReviews.reviewsSection.title} (
        <TotalNumber data-test-automation-id="reviews-total-number">
          {totalNumberForReviews}
        </TotalNumber>
        )
      </SectionHeading>
      <SectionBody data-test-automation-id="reviews-section-body">
        {totalNumberForReviews > 0 && (
          <>
            {showMyPendingReview && (
              <ReviewItem
                item={myPendingReviewItem!}
                isMyReview
                onEdit={onEdit}
                onDelete={onDelete}
                data-test-automation-id="my-review-item"
              />
            )}
            {showMyPendingReview && publishedReviewItems.length > 0 && (
              <Divider />
            )}
            {publishedReviewItems.map((item, index) => (
              <>
                <ReviewItem
                  key={item.id}
                  item={item}
                  isMyReview={item.myReview}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  data-test-automation-id="published-review-item"
                />
                {!isLast(index) && <Divider />}
              </>
            ))}
            {isPublishedReviewItemsLoading && (
              <LoadingWrapper data-test-automation-id="published-reviews-loading">
                <RcLoading loading>
                  <span />
                </RcLoading>
              </LoadingWrapper>
            )}
          </>
        )}
        {totalNumberForReviews === 0 && (
          <StyledNoReviewsSection
            variant={isMobile ? 'subheading1' : 'headline1'}
          />
        )}
      </SectionBody>
    </Wrapper>
  );
});
