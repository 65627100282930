import React, { FC } from 'react';
import { RcTypographyProps } from '@ringcentral/juno';
import { Tooltip } from '@apw/lib/components/tooltip';
import { Container, Label } from './itemLabel.sc';

export interface IRatingItemLabelProps {
  text: string;
  tooltip: string;
  labelProps: RcTypographyProps;
  className?: string;
}

export const ItemLabel: FC<IRatingItemLabelProps> = ({
  text,
  tooltip,
  labelProps,
  className,
}) => {
  return (
    <Container className={className}>
      {/* @ts-ignore */}
      <Label
        {...labelProps}
        data-test-automation-id={'quality-rating-item-label'}
        tabIndex={0}
      >
        {text}
      </Label>
      <Tooltip
        automationId={'quality-rating-item-tooltip'}
        title={tooltip}
        tabIndex={0}
      />
    </Container>
  );
};
