import { UAParser } from 'ua-parser-js';

export enum DetectableOS {
  macOS = 'Mac OS',
  Windows = 'Windows',
  Android = 'Android',
  iOS = 'iOS',
}

export enum MacChipEnum {
  M1,
  INTEL,
}

export enum WindowsBitEnum {
  BIT_32,
  BIT_64,
}

export const getParsedOS = () => {
  const instance = new UAParser(window.navigator.userAgent);
  return instance.getOS();
};

export const isMac = (): boolean => {
  return getParsedOS().name === DetectableOS.macOS;
};

export const isWindows = (): boolean => {
  return getParsedOS().name === DetectableOS.Windows;
};

export const isAndroid = (): boolean => {
  return getParsedOS().name === DetectableOS.Android;
};

export const isIOS = (): boolean => {
  return getParsedOS().name === DetectableOS.iOS;
};

export const isDesktop = (): boolean => {
  return isMac() || isWindows();
};

export const isMobile = (): boolean => {
  return isIOS() || isAndroid();
};

export const isUndetected = (): boolean => {
  return !(isMac() || isWindows() || isAndroid() || isIOS());
};

const getWebglRenderer = () => {
  let renderer;
  const canvas = document.createElement('canvas');
  try {
    const gl =
      canvas.getContext('webgl2') ||
      canvas.getContext('webgl') ||
      canvas.getContext('experimental-webgl');
    if (gl) {
      let debugInfo;
      if ('getExtension' in gl) {
        debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
      }
      if (debugInfo && 'getParameter' in gl) {
        renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
      }
    }
  } catch (e) {
    return renderer;
  }
  return renderer;
};

export const getMacChip = () => {
  if (!isMac()) {
    return;
  }
  const renderer = getWebglRenderer();
  return renderer && renderer.match(/apple m1/i)
    ? MacChipEnum.M1
    : MacChipEnum.INTEL;
};

export const getWindowsBit = () => {
  if (!isWindows()) {
    return;
  }
  const userAgent = navigator.userAgent.toLowerCase();
  const is64Bit =
    userAgent.indexOf('wow64') !== -1 || userAgent.indexOf('win64') !== -1;
  return is64Bit ? WindowsBitEnum.BIT_64 : WindowsBitEnum.BIT_32;
};

export const isSafari = (): boolean => {
  const instance = new UAParser(window.navigator.userAgent);
  return instance.getBrowser().name === 'Safari';
};
