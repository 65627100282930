import React from 'react';
import { ITagItem } from '@apw/components/profileDetail';
import {
  TagItem,
  TagItemButton,
  TagItemName,
  TagListContainer,
  TagListWrapper,
  TagType,
} from '@apw/components/profileDetail/profileBody/overviewTab/tagList/tagList.sc';
import { Link } from 'react-router-dom';

export const TagList = (props: {
  tagType: string;
  tagList: ITagItem[] | undefined;
}) => {
  const { tagType = '', tagList } = props;

  if (!tagList || !tagList.length) {
    return null;
  }

  const tagItemContent = (item: ITagItem) => {
    return (
      <Link to={item.href} data-test-automation-id={`tag-item-link`}>
        <TagItemButton
          color="text.primary"
          variant={'plain'}
          aria-hidden="true"
          tabIndex={-1}
        >
          <TagItemName data-test-automation-id={`tag-item-name`}>
            {item.tag}
          </TagItemName>
        </TagItemButton>
      </Link>
    );
  };

  return (
    <TagListWrapper>
      <TagType data-test-automation-id={`tag-type`}>{tagType}</TagType>
      <TagListContainer data-test-automation-id={`tag-list`}>
        {(tagList || []).map((item) => {
          return (
            <TagItem data-test-automation-id={`tag-item`} key={item.key}>
              {tagItemContent(item)}
            </TagItem>
          );
        })}
      </TagListContainer>
    </TagListWrapper>
  );
};
