import { BlockInterface } from '@apw/components/pageContent/typings/block.interface';

export enum HeaderBlockPropertyEnum {
  TITLE = 'title',
  DESCRIPTION = 'desc',
  IMAGE = 'image',
  LAYOUT = 'layout',
}

export enum HeaderBlockLayoutEnum {
  TEXT_WITH_IMAGE_ON_RIGHT = 'textWithImageOnRight',
  TEXT_WITH_IMAGE_ON_LEFT = 'textWithImageOnLeft',
  TEXT_WITH_BACKGROUND_IMAGE = 'textWithBackgroundImage',
}

export interface HeaderBlockInterface extends BlockInterface {
  title: string;
  desc: string;
  image: string;
  layout: HeaderBlockLayoutType;
}

export type HeaderBlockLayoutType =
  | 'textWithImageOnRight'
  | 'textWithImageOnLeft'
  | 'textWithBackgroundImage';
