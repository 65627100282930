import { IPaging } from '@apw/lib/types';

export enum ReviewStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Deleted = 'Deleted',
}

export interface IReviewItem {
  id: string;
  userName: string | null;
  overallRating: number;
  valueRating: number;
  convenienceRating: number;
  featureRating: number;
  supportRating: number;
  creationTime: string;
  content: string;
  status: ReviewStatus;
  myReview?: true;
}

export type IMyReviewItemResponse = IReviewItem;

export interface IPublishedReviewItemsResponse {
  records: IReviewItem[];
  paging: IPaging;
}
