import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const recordedPaths: string[] = [];

export const usePreviousPath = () => {
  return recordedPaths[recordedPaths.length - 2];
};

const useCurrentPath = () => {
  return recordedPaths[recordedPaths.length - 1];
};

export const useLocationRecorder = () => {
  const location = useLocation();
  const currentPath = useCurrentPath();

  useEffect(() => {
    const pathname = location.pathname;

    if (pathname !== currentPath) {
      recordedPaths.push(pathname);
    }
  }, [location]);
};
