import React, { useContext, useEffect, useMemo, useState } from 'react';
import { BigBlueButtonInterface } from '@apw/components/profileDetail';
import { ProfileOptionsContext } from '@apw/components/profileDetail/profile-options.context';
import { BotActionEnum } from '@apw/components/profileDetail/profileHeader/ctaButton/botManageButton/bot-manager.interface';
import { trackBigBlueButtonClicked } from '@apw/components/profileDetail/profileHeader/ctaButton/shared/ctaButtonService';
import { ApiError, googleAnalytics, mixpanel } from '@apw/core';
import * as apiErrorService from '@apw/core/api/api-error.service';
import { useUserCompatibility } from '@apw/hooks';
import { useBotProvision } from '@apw/modules/botProvision';
import * as botProvisionService from '@apw/modules/botProvision/bot-provision.service';
import { useBotManagement } from '@apw/modules/botProvision/shared/useBotManagement';
import { useStores } from '@apw/stores';
import { getLoginUrl, storeSession } from '@apw/stores/user';
import { IProfile } from '@apw/types';
import { BigBlueButtonStateEnum } from '@apw/types/bigBlueButtonState.enum';
import { BotExtension } from '@apw/types/botExtension.interface';
import { AxiosError } from 'axios';
import { includes } from 'lodash';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { BotButton } from './botManageButton.sc';

const getTrackingButtonName = (action) => {
  const buttonNameMapping = {
    [BotActionEnum.GET_IT_NOW]: 'Get it now',
    [BotActionEnum.SIGN_IN_TO_INSTALL]: 'Sign in to install',
    [BotActionEnum.INSTALL]: 'Install Bot',
  };

  return buttonNameMapping[action] || action;
};

const shouldTrackBigBlueButtonEvent = (action) => {
  return [
    BotActionEnum.SIGN_IN_TO_INSTALL,
    BotActionEnum.INSTALL,
    BotActionEnum.GET_IT_NOW,
  ].includes(action);
};

export const BotManageButton = observer(
  ({ profile }: { profile: IProfile }) => {
    const { isPreviewMode } = useContext(ProfileOptionsContext);
    const botManagement = useBotManagement();
    const botProvision = useBotProvision();
    const { userStore, compatibilitiesStore } = useStores();
    const [action, setAction] = useState<BotActionEnum>();
    const isFromGallery = useMemo(
      () => botProvisionService.shouldGetAppFromGallery(profile),
      [profile],
    );
    const [botClientId, setBotClientId] = useState('');
    const [installedBotExt, setInstalledBotExt] = useState<BotExtension>();
    const [preCheckErrors, setPreCheckErrors] = useState<string[]>();
    const botInstallUrl = useMemo(
      () => botProvisionService.getBotInstallUrl(profile),
      [profile],
    );
    const { isUserIncompatibleWithCurrentGallery } = useUserCompatibility();

    const resolveAction = async (isLoginIn) => {
      const isBot = await botProvisionService.isBotProfile(profile);
      if (!isBot || !isFromGallery) {
        setAction(BotActionEnum.GET_IT_NOW);
        return;
      }

      if (!isLoginIn) {
        setAction(BotActionEnum.SIGN_IN_TO_INSTALL);
        return;
      }

      if (isPreviewMode) {
        setAction(BotActionEnum.INSTALL);
        return;
      }

      try {
        if (!profile.appId) return;
        const data = await botProvisionService.requestBotConfirmationInfo(
          profile.appId,
        );
        setBotClientId(data.clientId);
        setInstalledBotExt(data.botExtension);
        setPreCheckErrors(data.preCheckErrors);

        if (data.botExtension !== undefined) {
          const action = data.ableToRemove
            ? BotActionEnum.REMOVE
            : BotActionEnum.VIEW_BOT_NAME;
          setAction(action);
          return;
        }
        setAction(BotActionEnum.INSTALL);
      } catch (e) {
        const error = apiErrorService.getErrorCode((e as AxiosError).response!);
        switch (error) {
          case 'PLATFORM_TYPE_ERROR':
            setAction(BotActionEnum.GET_IT_NOW);
            break;
          case 'BOT_BRAND_NOT_SUPPORTED':
            setPreCheckErrors(['BOT_BRAND_NOT_SUPPORTED']);
            setAction(BotActionEnum.INSTALL);
            break;
          default:
            setAction(BotActionEnum.GET_IT_NOW);
            botProvision.handleError(error);
        }
      }
    };

    const trackButtonClick = () => {
      let name = `GalleryItem:${profile.appName}:`;
      switch (action) {
        case BotActionEnum.SIGN_IN_TO_INSTALL:
          name += 'SignInToInstall';
          break;
        case BotActionEnum.INSTALL:
          name += 'AddToGlip';
          break;
        case BotActionEnum.REMOVE:
          name += 'Remove';
          break;
        case BotActionEnum.GET_IT_NOW:
          name += 'GetItNow';
          break;
        default:
          break;
      }
      googleAnalytics.trackButtonClick(name);
      if (shouldTrackBigBlueButtonEvent(action)) {
        trackBigBlueButton();
      }
    };

    const trackBigBlueButton = () => {
      const buttonMsg: BigBlueButtonInterface = {
        desc: getTrackingButtonName(action),
        buttonState: BigBlueButtonStateEnum.PRIMARY,
      };
      trackBigBlueButtonClicked(profile, buttonMsg, userStore.isLoggedIn);
    };

    const signInToInstall = async () => {
      if (isPreviewMode) return;
      trackButtonClick();
      const loginUrl = await getLoginUrl();
      storeSession();
      window.location.href = loginUrl;
    };

    const trackRemoveBot = () => {
      const compatibility = compatibilitiesStore.getCompatibilityById(
        profile.brand,
      );
      const brandName = compatibility ? compatibility.name : '';
      mixpanel.trackRemoveBot(profile, brandName);
    };

    const installBot = async () => {
      if (isPreviewMode) return;
      trackButtonClick();
      try {
        await botManagement.installBot({
          appId: profile.appId,
          knownErrors: preCheckErrors,
        });
        // eslint-disable-next-line no-empty
      } catch (e) {}
    };

    const removeBot = async () => {
      trackButtonClick();
      try {
        const res = await botManagement.removeBot({
          botClientId,
          botName: installedBotExt?.name,
        });
        if (!res) return;
        trackRemoveBot();
        await resolveAction(userStore.isLoggedIn);
      } catch (e) {
        const flag = !includes(
          [
            ApiError.NOT_LOGGED_IN,
            ApiError.REQUEST_RATE_EXCEEDED,
            ApiError.SESSIONS_NUMBER_EXCEEDED,
            ApiError.ACCESS_TOKEN_CORRUPTED,
          ],
          e,
        );
        if (flag) {
          await resolveAction(userStore.isLoggedIn);
        }
      }
    };

    useEffect(
      () =>
        autorun(() => {
          resolveAction(userStore.isLoggedIn);
        }),
      [],
    );

    const onGetItNowClicked = () => {
      window.open(botInstallUrl, '_blank', 'noreferrer');
      trackButtonClick();
    };

    return (
      <>
        {action === BotActionEnum.GET_IT_NOW && (
          <BotButton
            onClick={onGetItNowClicked}
            disabled={isUserIncompatibleWithCurrentGallery}
            data-test-automation-id={`get-it-now-button`}
          >
            Get it now
          </BotButton>
        )}
        {action === BotActionEnum.SIGN_IN_TO_INSTALL && (
          <BotButton
            onClick={signInToInstall}
            disabled={isPreviewMode}
            data-test-automation-id={`sign-in-to-install-button`}
          >
            Sign in to install
          </BotButton>
        )}
        {action === BotActionEnum.INSTALL && (
          <BotButton
            onClick={installBot}
            disabled={isPreviewMode || isUserIncompatibleWithCurrentGallery}
            data-test-automation-id={`install-bot-button`}
          >
            Install Bot
          </BotButton>
        )}
        {action === BotActionEnum.REMOVE && (
          <BotButton
            onClick={removeBot}
            disabled={isUserIncompatibleWithCurrentGallery}
            data-test-automation-id={`remove-bot-button`}
          >
            Remove
          </BotButton>
        )}
        {action === BotActionEnum.VIEW_BOT_NAME && (
          <div data-test-automation-id={`view-bot-name`}>
            <strong>Installed</strong> as "{installedBotExt?.name}"
          </div>
        )}
      </>
    );
  },
);
