import React from 'react';
import { TabLabelEnum } from '@apw/components/profileDetail';
import { StyledButton } from '@apw/components/profileDetail/profileBody/overviewTab/ratingsAndReviews/ratingsSection/viewReviewsButton/viewReviewButton.sc';
import { ProfilePageQueryParamsEnum } from '@apw/modules/profile/ProfilePageQueryParams.enum';
import * as qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';

export const ViewReviewButton = () => {
  const history = useHistory();
  const location = useLocation();

  const onClick = () => {
    const search = qs.stringify(
      { [ProfilePageQueryParamsEnum.TAB]: TabLabelEnum.RATINGS_AND_REVIEWS },
      { addQueryPrefix: true },
    );
    const path = location.pathname + search;
    history.replace(path);
    setTimeout(() => {
      window.scrollTo(0, 400);
    }, 300);
  };

  return (
    <StyledButton
      size="xlarge"
      variant="plain"
      color="action.primary"
      data-test-automation-id={'ratings-section-view-reviews'}
      onClick={onClick}
    >
      View all customer reviews
    </StyledButton>
  );
};
