import React, { FC, useEffect } from 'react';
import { safeWindowOpen } from '@apw/core';
import { Language, languageService } from '@apw/core/language';
import { useTheme } from '@material-ui/core/styles';
import { RcButton } from '@ringcentral/juno';
import { Close } from '@ringcentral/juno-icon';
import {
  Actions,
  CloseButton,
  Container,
  Content,
  DialogTitle,
} from './LeavingPopup.sc';

export const LeavingPopup: FC<{
  leavingUrl: string;
  onClose;
}> = ({ leavingUrl, onClose }) => {
  const theme = useTheme();
  let timer;

  const onLeave = () => {
    safeWindowOpen(leavingUrl);
    onClose();
  };

  useEffect(() => {
    timer = setTimeout(() => {
      onLeave();
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Container open>
      <DialogTitle role="banner" data-test-automation-id="leaving-dialog-title">
        {languageService.get(Language.LEAVING_POPUP_TITLE)}
        <CloseButton
          onClick={onClose}
          symbol={Close}
          aria-label="close"
          data-test-automation-id="leaving-dialog-close-button"
        />
      </DialogTitle>
      <Content role="main" data-test-automation-id="leaving-dialog-content">
        <p data-test-automation-id="leaving-dialog-msg">
          {languageService.get(Language.LEAVING_POPUP_MESSAGE)}
        </p>
        <p>
          <a
            href={leavingUrl}
            target="_blank"
            rel="noreferrer"
            onClick={onClose}
            style={{ color: theme.palette.text.secondary }}
            data-test-automation-id="leaving-dialog-url"
          >
            {leavingUrl}
          </a>
        </p>
        <p data-test-automation-id="leaving-dialog-note">
          {languageService.get(Language.LEAVING_POPUP_NOTE)}
        </p>
      </Content>
      <Actions>
        <RcButton
          variant="text"
          onClick={onClose}
          data-test-automation-id="leaving-dialog-cancel-button"
        >
          Cancel
        </RcButton>
        <RcButton
          onClick={onLeave}
          data-test-automation-id="leaving-dialog-leave-button"
        >
          Go Now
        </RcButton>
      </Actions>
    </Container>
  );
};
