import React from 'react';
import { useStores } from '@apw/stores';
import { Compatibility } from '@apw/stores/compatibility';
import { makeStyles } from '@material-ui/core/styles';
import {
  TooltipContainer,
  BrandName,
  TooltipTitle,
} from './PartnerAccountTooltip.sc';

const useStyles = makeStyles(() => ({
  partnerAccountTooltip: {
    maxWidth: 350,
  },
}));

export const PartnerAccountTooltip = ({ automationId }) => {
  const classes = useStyles();
  const { compatibilitiesStore } = useStores();
  const compatibilities = compatibilitiesStore.items.filter(
    (compatibility) => compatibility.id !== Compatibility.RINGCENTRAL,
  );

  const isLast = (index: number): boolean => {
    return index === compatibilities.length - 1;
  };

  const isSecondLast = (index: number): boolean => {
    return index === compatibilities.length - 2;
  };

  return (
    <TooltipContainer
      classes={{ tooltip: classes.partnerAccountTooltip }}
      automationId={automationId}
      title={
        <TooltipTitle data-test-automation-id="partner-account-tooltip-title">
          Partner accounts include{' '}
          {compatibilities.map((compatibility, index) => (
            <React.Fragment key={compatibility.id}>
              {!isLast(index) && !isSecondLast(index) && (
                <React.Fragment>
                  <BrandName>{compatibility.name},</BrandName>{' '}
                </React.Fragment>
              )}
              {isSecondLast(index) && (
                <React.Fragment>
                  <BrandName>{compatibility.name}</BrandName>{' '}
                </React.Fragment>
              )}
              {isLast(index) && <BrandName>and {compatibility.name}</BrandName>}
            </React.Fragment>
          ))}
        </TooltipTitle>
      }
      tabIndex={0}
    />
  );
};
