import React, { FC } from 'react';
import {
  RcDialogTitle,
  RcDialogContent,
  RcDialogActions,
  RcButton,
  RcDialog,
} from '@ringcentral/juno';

export const Alert: FC<{
  title: string;
  message: string;
  onClose;
}> = ({ title, message, onClose }) => {
  return (
    <RcDialog open>
      <RcDialogTitle>{title}</RcDialogTitle>
      <RcDialogContent>{message}</RcDialogContent>
      <RcDialogActions>
        <RcButton onClick={onClose}>OK</RcButton>
      </RcDialogActions>
    </RcDialog>
  );
};
