import { IProcessedProfile } from '@apw/components/profileDetail';
import { ClickPath } from '@apw/components/profileDetail/profileHeader/ctaButton/shared/clickPath.enum';
import { api, googleAnalytics, mixpanel } from '@apw/core';
import { platformTypeToAppType } from '@apw/core/application.service';
import { countryDic } from '@apw/core/country';
import { countriesWithReceiveEmail } from '@apw/core/country/countriesWithReceiveEmail';
import { buildUserFormikConfig, ILeadRequest } from '@apw/modules/profile';
import { LeadFormConfig } from '@apw/modules/profile/lead/leadForm/leadForm.config';
import { rootStore } from '@apw/stores';
import * as _ from 'lodash';

export const buildLeadFormikConfig = () => {
  return buildUserFormikConfig({
    [LeadFormConfig.requirements.name]: '',
    [LeadFormConfig.receiveEmails.name]: false,
  });
};

export const showEmailConsentCheckbox = (countryId: string) => {
  return _.includes(countriesWithReceiveEmail, countryId);
};

export const submitLeadForm = (
  appProfileId: string,
  appProfileName: string,
  values: any,
) => {
  const data = Object.assign(_.clone(values), {
    appProfileId,
    appProfileName,
    country: countryDic[values.country].name,
    receiveEmails: showEmailConsentCheckbox(values.country)
      ? values.receiveEmails
      : null,
  }) as ILeadRequest;
  const endpoint = '/api/lead';
  return api.post<any>(`${endpoint}`, data, {
    useGlobalLoading: true,
    responseType: 'text',
  });
};

export const trackContactUsSubmitted = (
  profile: IProcessedProfile,
  isLogin: boolean,
) => {
  const brand = rootStore.compatibilitiesStore.getCompatibilityById(
    profile.brand,
  );

  const properties = {
    appId: profile.appId || '',
    profileId: profile._id,
    profileName: profile.appName,
    clickPath: isLogin ? ClickPath.CUSTOMER : ClickPath.GUEST,
    brandPartner: brand?.name || '',
    appName: profile.applicationName || '',
    appDisplayName: profile.applicationDisplayName || '',
    appType: platformTypeToAppType(profile.applicationType) || '',
  };

  mixpanel.trackContactUsSubmitted(properties);
  googleAnalytics.trackContactUsSubmitted(properties);
};
