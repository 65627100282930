import React from 'react';
import { LoginOptionDialog } from './LoginOption.sc';
import { LoginOptionContent } from './LoginOptionContent';

type LoginOptionProps = {
  title: string;
  onClose: () => void;
  onAfterSignIn: () => void;
  onAsGuest: () => void;
};

export const LoginOption = (props: LoginOptionProps) => {
  return (
    <LoginOptionDialog open size="medium">
      <LoginOptionContent {...props} />
    </LoginOptionDialog>
  );
};
