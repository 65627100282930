import { Language } from './language.enum';

export const Languages: { [key: number]: string } = {
  /** Common * */
  [Language.TITLE_SORRY]: 'Sorry!',
  [Language.TITLE_CONGRATULATIONS]: 'Congratulations!',
  [Language.OK]: 'OK',
  [Language.SESSION_TIMEOUT]:
    'Your current session has expired. Please login again.',

  /** Login * */
  [Language.LOGIN_COMMON_ERROR]:
    'Sorry, an unexpected error has occurred, please try again later. ' +
    'If the problem persists, please contact <a href="http://success.ringcentral.com/RCContactSupp" target="_blank" tabindex="-1" rel="noreferrer">Customer Support</a>.',

  /** Bot Provision * */
  [Language.BOT_NAME_RULES]: `
    The bot name must follow below rules:
    <ol>
        <li>Only letters, numbers, periods, hyphens, underscores and spaces are allowed.</li>
        <li>The length must be between 3 and 32 characters.</li>
    </ol>
  `,
  [Language.BOT_NAME_FORMAT_ERROR]:
    'Bot name must be between 3 and 32 characters long. Bot name can only contain letters, numbers, periods, hyphens, underscores and spaces.',
  [Language.BOT_NAME_OCCUPIED]:
    'Sorry, you already have a bot with the same name, please try another.',
  [Language.DISABLE_BOT_NAME_OCCUPIED]:
    'Sorry, you already have a bot with the same name. You must uninstall/rename the existing one and retry.',
  [Language.BOT_PROVISION_CALL_REDIRECT_URI_ERROR]:
    'We are unable to add the bot to the account. Please contact the bot developer.',
  [Language.BOT_BRAND_NOT_SUPPORTED]:
    'The bot is not compatible with your account. Please contact the application developer for further support.',
  [Language.HIPAA_ACCOUNT]: 'Bot support for HIPAA Customer is coming soon!',
  [Language.FREE_TIER_CANNOT_INSTALL_BOT]:
    'Upgrade your account to RingCentral Office Premium or Ultimate in order to add this bot to RingCentral App.',
  [Language.FREE_TIER_UPGRADE_BUTTON_LABEL]: 'Upgrade your account',
  [Language.RC_PLANS_AND_PRICING_PAGE_URL]:
    'https://www.ringcentral.com/office/plansandpricing.html',
  [Language.BOT_SUBSCRIBE_WEB_HOOK_FAIL]:
    'We are unable to add the bot to the account. The webhook URL returned an unsuccessful response.',
  [Language.REMOVE_BOT_CONFIRMATION_TITLE]: 'Remove this bot for everyone?',

  /** Lead Form * */
  [Language.LEAD_FORM_SUCCESSFUL_SUBMISSION]:
    'Your request has been submitted successfully!',
  [Language.LEAD_FORM_LOGIN_OPTION_TITLE]: 'Sign in to RingCentral',

  /** Guest Form * */
  [Language.GUEST_FORM_DESCRIPTION]:
    'Tell us more about yourself so that we can better serve you. We will not share your personal information with anyone.',
  [Language.GUEST_FORM_LOGIN_OPTION_TITLE]: 'Sign in required',

  /** Form * */
  [Language.FORM_ERROR_REQUIRED]: 'This is required',
  [Language.FORM_ERROR_EMAIL]: 'The email format is invalid',
  [Language.FORM_ERROR_PHONE_NUMBER]: 'The phone number is invalid',

  /** Google reCAPTCHA * */
  [Language.GOOGLE_RECAPTCHA_ERROR_REQUIRED]: `Please tick "I'm not a robot" above`,
  [Language.GOOGLE_RECAPTCHA_ERROR_VERIFICATION]:
    'The Google reCAPTCHA verification is failed. Please try again.',

  /** Internal Error * */
  [Language.INTERNAL_ERROR_IPAAS_DATA_INVALID]:
    'The JSON file for the IPaaS Search is invalid, please check.',

  /** Download Popup * */
  [Language.DOWNLOAD_POPUP_TITLE]: 'Your download will begin shortly',

  /** Leaving POPUP * */
  [Language.LEAVING_POPUP_TITLE]: 'You are leaving RingCentral...',
  [Language.LEAVING_POPUP_MESSAGE]:
    'You will soon be leaving RingCentral and be taken to the following URL:',
  [Language.LEAVING_POPUP_NOTE]:
    'If you are not automatically redirected, or you would like to visit the website immediately, click the "Go Now" button below.',
};

export const LanguagesWithContext: {
  [key: number]: (...context: string[]) => string;
} = {
  /** Bot Provision * */
  [Language.ADD_A_BOT_WITH_BOT_NAME]: (botName) =>
    `Add a bot with bot name <span>@${botName}</span>`,
  [Language.BOT_ALREADY_INSTALLED_ERROR]: (botName) =>
    `The bot ${botName} is already installed in your organization. To start a conversation with this bot, please use the \`/invite ${botName}\` command.`,
  [Language.BOT_ALREADY_REMOVED_ERROR]: (botName) =>
    `The bot ${botName} has already been removed from your organization.`,
  [Language.BOT_PROVISION_NO_PERMISSION]: (permissionName) =>
    `You currently don't have the necessary ${
      permissionName || ''
    } permission. Please contact your system administrator.`,
  [Language.REMOVE_BOT_CONFIRMATION]: (botName) =>
    `Are you sure you want to remove ${botName} from RingCentral App? Once removed, no users will be able to use this bot.`,

  [Language.GUEST_FORM_LOGIN_OPTION_TITLE]: (appName) => `Get ${appName} Now`,

  [Language.DOWNLOAD_POPUP_MESSAGE]: (appName) =>
    `Your download of ${appName} will begin shortly. If it does not, or you would like to initiate the download sooner, click the "Download" button below.`,

  [Language.LEAD_FORM_DESCRIPTION]: (publisher) =>
    `I agree to share my contact information with ${publisher} and allow them to contact me.`,

  [Language.PARTNER_LEAD_FORM_DESCRIPTION]: (publisher) =>
    `I agree to share my contact information with RingCentral and this publisher, ${publisher}, and allow them to contact me.`,
};
