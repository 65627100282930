import React, { useEffect } from 'react';
import { domService } from '@apw/components/dom/dom.service';
import {
  GLOBAL_SCROLL_BLOCK_CLASS,
  GlobalScrollBlockStyles,
} from '@apw/shared/withGlobalScroll/withGlobalScroll.sc';

export const globalScrollBlock = (active: boolean) => {
  const htmlEl = document.getElementsByTagName('html')[0];

  const onPopoverShow = () => {
    htmlEl.style.top = `-${window.scrollY}px`;
    domService.addClass(htmlEl, GLOBAL_SCROLL_BLOCK_CLASS);
  };

  const onPopoverHidden = () => {
    const scrollY = htmlEl.style.top;
    htmlEl.style.top = '';
    domService.removeClass(htmlEl, GLOBAL_SCROLL_BLOCK_CLASS);
    window.requestAnimationFrame(() =>
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1),
    );
  };

  return active ? onPopoverShow() : onPopoverHidden();
};

export const withGlobalScroll = (Content) => {
  return (props) => {
    useEffect(() => {
      globalScrollBlock(true);
      return () => {
        globalScrollBlock(false);
      };
    }, []);

    return (
      <>
        <GlobalScrollBlockStyles />
        <Content {...props} />
      </>
    );
  };
};
