import React, { FC, useEffect, useRef } from 'react';
import { richTextFieldService } from '@apw/components/markdown/rich-text-field.service';
import { palette2, styled } from '@ringcentral/juno';

type LongDescProps = {
  className: string;
  content: string;
};

const LongDesc: FC<LongDescProps> = ({ className = '', content }) => {
  const container = useRef<HTMLDivElement>(null);
  const detailDesc = {
    __html: richTextFieldService.convertToHtmlFormat(content),
  };

  useEffect(() => {
    if (container.current) {
      const matches = container.current.querySelectorAll('a');
      matches.forEach((match) => {
        match.setAttribute('rel', 'noopener noreferrer');
      });
    }
  }, [detailDesc]);

  return (
    <div
      data-test-automation-id="profile-detail-long-desc"
      className={`${className}`}
      ref={container}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={detailDesc}
    />
  );
};

const StyledLongDesc = styled(LongDesc)`
  & {
    a {
      color: ${palette2('secondary', 'main')};
    }
  }
`;

export { StyledLongDesc as LongDesc };
