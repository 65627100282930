import { storageService } from '@apw/shared/storage/storage.service';
import { ICanFetchFlags, IFlagValue } from '@apw/stores/myReviews/types';
import {
  generateExpireTime,
  generateHashKey,
  isExpired,
} from '@apw/stores/myReviews/utils';
import moment from 'moment';

const LOCAL_STORAGE_KEY = 'rcapw-my-review-flags';

export const FRONTEND_EXPIRE_DURATION = 7;
export const FRONTEND_EXPIRE_DURATION_UNIT = 'days';

const BACKEND_CACHE_TIME = 60;
const BACKEND_CACHE_TIME_UNIT = 'seconds';

const readFlagsFromLocalStorage = (): ICanFetchFlags => {
  const json = storageService.getItem(LOCAL_STORAGE_KEY) || '{}';

  try {
    return JSON.parse(json);
  } catch (err) {
    return {};
  }
};

const saveFlagsToLocalStorage = (flags: ICanFetchFlags) => {
  storageService.setItem(LOCAL_STORAGE_KEY, JSON.stringify(flags));
};

export const hasCanFetchFlag = (rcExtId: string, appId: string): boolean => {
  const flag = getCanFetchFlag(rcExtId, appId);
  const result = flag && flag.value === 1;
  return Boolean(result);
};

export const getCanFetchFlag = (
  rcExtId: string,
  appId: string,
): IFlagValue | undefined => {
  const hashKey = generateHashKey(rcExtId, appId);

  clearExpiredFlags();

  const flags = readFlagsFromLocalStorage();
  return flags[hashKey];
};

export const setCanFetchFlag = (rcExtId: string, appId: string) => {
  const hashKey = generateHashKey(rcExtId, appId);

  const flags = readFlagsFromLocalStorage();

  flags[hashKey] = {
    value: 1,
    expireTime: generateExpireTime(
      moment().add(FRONTEND_EXPIRE_DURATION, FRONTEND_EXPIRE_DURATION_UNIT),
    ),
  };

  saveFlagsToLocalStorage(flags);
};

export const delCanFetchFlag = (rcExtId: string, appId: string) => {
  const hashKey = generateHashKey(rcExtId, appId);

  const flags = readFlagsFromLocalStorage();

  delete flags[hashKey];

  saveFlagsToLocalStorage(flags);
};

export const clearExpiredFlags = () => {
  const flags = readFlagsFromLocalStorage();
  const newFlags: ICanFetchFlags = {};

  for (const hashKey in flags) {
    if (Object.prototype.hasOwnProperty.call(flags, hashKey)) {
      const flag = flags[hashKey];

      if (!isExpired(flag)) {
        newFlags[hashKey] = flag;
      }
    }
  }

  saveFlagsToLocalStorage(newFlags);
};

export const shouldProtectCanFetchFlagFromDeletion = (
  rcExtId: string,
  appId: string,
): boolean => {
  const flag = getCanFetchFlag(rcExtId, appId);

  if (!flag) {
    return false;
  }

  const expireTime = flag.expireTime;
  const safeDeleteTime = moment(expireTime)
    .subtract(FRONTEND_EXPIRE_DURATION, FRONTEND_EXPIRE_DURATION_UNIT)
    .add(BACKEND_CACHE_TIME, BACKEND_CACHE_TIME_UNIT);

  return moment().isBefore(safeDeleteTime);
};
