import React, { createContext, FC, ReactNode, useRef, useState } from 'react';
import { useStores } from '@apw/stores';
import { RcThemeInput, RcThemeProvider } from '@ringcentral/juno';
import { observer } from 'mobx-react';

interface GalleryThemeProviderProps {
  children: ReactNode;
}

type IThemeOverride = RcThemeInput | undefined;

interface IThemeOverrideController {
  set: (theme: RcThemeInput) => void;
  reset: () => void;
}

export const GalleryThemeProvider: FC<GalleryThemeProviderProps> = observer(
  ({ children }) => {
    const { compatibilityStore } = useStores();
    const [themeOverride, setThemeOverride] = useState<IThemeOverride>();
    const defaultTheme = compatibilityStore.compatibility?.theme;

    const themeOverrideRef = useRef({
      set: (theme: RcThemeInput) => setThemeOverride(theme),
      reset: () => setThemeOverride(undefined),
    });

    return (
      <RcThemeProvider theme={themeOverride || defaultTheme}>
        <ThemeOverrideContext.Provider value={themeOverrideRef.current}>
          {children}
        </ThemeOverrideContext.Provider>
      </RcThemeProvider>
    );
  },
);

export const ThemeOverrideContext = createContext<IThemeOverrideController>({
  set: () => {},
  reset: () => {},
});
