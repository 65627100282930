import React, { FC, useCallback, useEffect } from 'react';
import { SEO, Sticky } from '@apw/components';
import Carousel from '@apw/components/carousel/Carousel';
import { googleAnalytics, mixpanel } from '@apw/core';
import { onMobile } from '@apw/core/sc';
import { useBrandBannerTexts } from '@apw/hooks';
import { AppGalleryCard } from '@apw/modules/partnerEcosystem/shared/AppGalleryCard';
import { getCompatibilityHomePath } from '@apw/routes/getCompatibilityPaths';
import { withLayout } from '@apw/shared';
import { Menu } from '@apw/shared/menu';
import { applyProximaNovaSemibold, breakpoints } from '@apw/shared/theme';
import { useStores } from '@apw/stores';
import { ICompatibility } from '@apw/stores/compatibility';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { palette2, styled } from '@ringcentral/juno';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import pageIllustration from '../../assets/images/partner-ecosystem-page-illustration.png';
import { HeroContainer } from '../page/Page.sc';

const APP_GALLERY_CARD_CLASS_NAME = 'appGalleryCard';
const PARTNER_SECTION_CLASS_NAME = 'partnerSection';
const SUB_BRAND_LOGO_GAP = 12;

const PageHeader = styled.div`
  margin-top: 36px;
  display: flex;
  align-items: center;
  padding-right: 2%;

  @media ${breakpoints.mobilePortrait} {
    padding-right: 0;
  }
`;

const PageHeaderContent = styled.div`
  flex-grow: 1;
`;

const PageIllustration = styled.img`
  width: 160px;
  height: 160px;
  flex-shrink: 0;
`;

const PageHeading = styled.h1`
  ${applyProximaNovaSemibold()}
  font-weight: normal;
  font-size: 40px;
  line-height: 56px;
  color: #001138;
  margin: 0;

  @media ${breakpoints.mobilePortrait} {
    display: flex;
    align-items: center;
    font-size: 32px;
    line-height: 38px;

    ${PageIllustration} {
      width: 112px;
      height: 112px;
    }
  }
`;

const PageDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #282829;
  margin-top: 16px;
  padding-right: 8%;

  p {
    margin: 0;
  }

  @media ${breakpoints.mobilePortrait} {
    margin-top: 30px;
    padding-right: 0;
  }
`;

const SectionHeader = styled.section`
  margin-top: 64px;

  .${PARTNER_SECTION_CLASS_NAME} & {
    margin-top: 88px;
  }
`;

const SectionHeading = styled.h2`
  ${applyProximaNovaSemibold()}
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #001138;
  margin: 0;
`;

const SectionDescription = styled.p`
  font-size: 16px;
  line-height: 16px;
  color: #494949;
  margin: 16px 0 0;

  ${onMobile} {
    line-height: 24px;
  }
`;

const SectionBody = styled.div`
  margin-top: 28px;
`;

const SubBrandLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 20px;
  background: white;
  border-radius: 8px;
  height: 76px;
  margin-left: ${SUB_BRAND_LOGO_GAP}px;

  @media ${breakpoints.mediumDesktop} {
    width: calc((100% - 3 * ${SUB_BRAND_LOGO_GAP}px) / 4);

    &:nth-child(4n + 1) {
      margin-left: 0;
    }

    &:nth-child(n + 5) {
      margin-top: ${SUB_BRAND_LOGO_GAP}px;
    }
  }

  @media ${breakpoints.tablet} {
    width: calc((100% - 2 * ${SUB_BRAND_LOGO_GAP}px) / 3);

    &:nth-child(3n + 1) {
      margin-left: 0;
    }

    &:nth-child(n + 4) {
      margin-top: ${SUB_BRAND_LOGO_GAP}px;
    }
  }

  @media ${breakpoints.mobileLandscape} {
    width: calc((100% - 1 * ${SUB_BRAND_LOGO_GAP}px) / 2);

    &:nth-child(2n + 1) {
      margin-left: 0;
    }

    &:nth-child(n + 3) {
      margin-top: ${SUB_BRAND_LOGO_GAP}px;
    }
  }

  @media ${breakpoints.mobilePortrait} {
    width: 100%;
    margin-left: 0;

    &:nth-child(n + 2) {
      margin-top: ${SUB_BRAND_LOGO_GAP}px;
    }
  }
`;

const SubBrandLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const SubBrandLogosContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 8px;
  padding: 16px;
  background-color: ${palette2('neutral', 'b02')};
  border-radius: 8px;

  @media ${breakpoints.mobilePortrait} {
    margin-top: 12px;
  }
`;

const SUB_BRAND_MARGIN_BOTTOM = 28;

const PageSection = styled.div`
  &.${PARTNER_SECTION_CLASS_NAME} {
    margin-bottom: 100px;

    ${SectionHeading} {
      margin-top: -${SUB_BRAND_MARGIN_BOTTOM}px;
    }
  }
`;

const COLS_4_WIDTH = '24.3%';
const COLS_3_WIDTH = '32.5%';
const COLS_2_WIDTH = '49%';

const AppGalleryCardsContainer = styled.div`
  &&& {
    .${APP_GALLERY_CARD_CLASS_NAME} {
      margin-bottom: 12px;

      @media ${breakpoints.mediumDesktop} {
        width: ${COLS_4_WIDTH};
        margin-left: calc((100% - 4 * ${COLS_4_WIDTH}) / 3);

        &:nth-child(4n + 1) {
          margin-left: 0;
        }
      }

      @media ${breakpoints.tablet} {
        width: ${COLS_3_WIDTH};
        margin-left: calc((100% - 3 * ${COLS_3_WIDTH}) / 2);

        &:nth-child(3n + 1) {
          margin-left: 0;
        }
      }

      @media ${breakpoints.mobileLandscape} {
        width: ${COLS_2_WIDTH};
        margin-left: calc(100% - 2 * ${COLS_2_WIDTH});

        &:nth-child(2n + 1) {
          margin-left: 0;
        }
      }

      @media ${breakpoints.mobilePortrait} {
        width: 100%;
      }
    }
  }
`;

interface IPartnerEcosystemPureProps {
  headerBanners: ICompatibility['headerBanners'];
  headerHeight: number;
  rcCompatibility: ICompatibility;
  partnerCompatibilities: ICompatibility[];
  onAppGalleryCardClick: (brandId: string) => void;
  isMobilePortrait: boolean;
}

const PartnerEcosystemPure: FC<IPartnerEcosystemPureProps> = (props) => {
  const {
    headerBanners,
    headerHeight,
    rcCompatibility,
    partnerCompatibilities,
    onAppGalleryCardClick,
    isMobilePortrait,
  } = props;
  const imagesAlt = useBrandBannerTexts();

  const risePartners = rcCompatibility.subBrands || [];

  const siteHeader = (
    <>
      {headerBanners.length > 0 && (
        <HeroContainer>
          <Carousel images={headerBanners} imagesAlt={imagesAlt} />
        </HeroContainer>
      )}
      <Sticky position="top" offset={headerHeight}>
        <Menu />
      </Sticky>
    </>
  );

  const pageHeader = (
    <PageHeader data-test-automation-id="partnerEcosystemPageHeader">
      <PageHeaderContent>
        <PageHeading>
          <span data-test-automation-id="partnerEcosystemPageTitle">
            Explore RingCentral's Partner Ecosystem
          </span>
          {isMobilePortrait && (
            <PageIllustration src={pageIllustration} alt="" />
          )}
        </PageHeading>
        <PageDescription>
          <p data-test-automation-id="partnerEcosystemPageDesc">
            RingCentral works with service providers, carriers and partners to
            bring cloud communications and unified communications to customers
            around the world.
          </p>
        </PageDescription>
      </PageHeaderContent>
      {!isMobilePortrait && <PageIllustration src={pageIllustration} alt="" />}
    </PageHeader>
  );

  const rcSection = (
    <PageSection>
      <SectionHeader>
        <SectionHeading data-test-automation-id="partnerEcosystemRcSectionHeading">
          RingCentral App Gallery
        </SectionHeading>
        <SectionDescription data-test-automation-id="partnerEcosystemRcSectionDesc">
          Find apps and integrations for these service provider partners in the
          main RingCentral App Gallery.
        </SectionDescription>
      </SectionHeader>
      <SectionBody>
        <AppGalleryCardsContainer>
          <AppGalleryCard
            automationId="partnerEcosystemRingCentralCard"
            className={APP_GALLERY_CARD_CLASS_NAME}
            brandId={rcCompatibility.id}
            brandName="RingCentral App Gallery"
            galleryIcon={rcCompatibility.galleryIcon}
            galleryIconType={rcCompatibility.galleryIconType}
            galleryIconClassName={rcCompatibility.className}
            onClick={onAppGalleryCardClick}
          />
        </AppGalleryCardsContainer>
        <SubBrandLogosContainer data-test-automation-id="partnerEcosystemSubBrandLogosContainer">
          {risePartners.map((subBrand) => (
            <SubBrandLogoContainer
              data-test-automation-id={`partnerEcosystemSubBrandLogoContainer-${subBrand.brandId}`}
            >
              <SubBrandLogo
                key={subBrand.brandId}
                src={subBrand.logoUrl}
                alt={subBrand.name}
                tabIndex={0}
                data-test-automation-id={`partnerEcosystemSubBrandLogo-${subBrand.brandId}`}
              />
            </SubBrandLogoContainer>
          ))}
        </SubBrandLogosContainer>
      </SectionBody>
    </PageSection>
  );

  const partnerSection = (
    <PageSection className={PARTNER_SECTION_CLASS_NAME}>
      <SectionHeader>
        <SectionHeading data-test-automation-id="partnerEcosystemPartnerSectionHeading">
          Custom-branded app galleries
        </SectionHeading>
        <SectionDescription data-test-automation-id="partnerEcosystemPartnerSectionDesc">
          These partner service providers offer their own version of
          RingCentral. Find apps and integrations in dedicated app galleries.
        </SectionDescription>
      </SectionHeader>
      <SectionBody>
        <AppGalleryCardsContainer data-test-automation-id="partnerEcosystemPartnerCardsContainer">
          {partnerCompatibilities.map((compatibility) => (
            <AppGalleryCard
              key={compatibility.id}
              className={APP_GALLERY_CARD_CLASS_NAME}
              brandId={compatibility.id}
              brandName={compatibility.name}
              galleryIcon={compatibility.galleryIcon}
              galleryIconType={compatibility.galleryIconType}
              galleryIconClassName={compatibility.className}
              onClick={onAppGalleryCardClick}
              automationId={`partnerEcosystemPartnerCard-${compatibility.id}`}
            />
          ))}
        </AppGalleryCardsContainer>
      </SectionBody>
    </PageSection>
  );

  return (
    <>
      <SEO
        title="RingCentral carrier and partner brands"
        metaDescription="RingCentral works with carriers and providers around the world to deliver a global unified communications network."
      />
      {siteHeader}
      {pageHeader}
      {rcSection}
      {partnerSection}
    </>
  );
};

export const PartnerEcosystem = withLayout(
  observer((props) => {
    const { headerHeight } = props;
    const { compatibilitiesStore, compatibilityStore } = useStores();
    const { compatibility } = compatibilityStore;
    const history = useHistory();
    const isMobilePortrait = useMediaQuery(breakpoints.mobilePortrait);

    const onAppGalleryCardClick = useCallback((brandId: string) => {
      const compatibility = compatibilitiesStore.getCompatibilityById(brandId);
      const homePath = getCompatibilityHomePath(compatibility?.slug || '');
      history.push(homePath);
    }, []);

    useEffect(() => {
      const pageName = 'Partner Ecosystem';
      mixpanel.trackPageView(pageName);
      googleAnalytics.trackPageView(pageName);
    }, []);

    return (
      <PartnerEcosystemPure
        headerBanners={compatibility.headerBanners}
        headerHeight={headerHeight}
        rcCompatibility={compatibilitiesStore.rcCompatibility!}
        partnerCompatibilities={compatibilitiesStore.partnerCompatibilities}
        onAppGalleryCardClick={onAppGalleryCardClick}
        isMobilePortrait={isMobilePortrait}
      />
    );
  }),
  { stickyHeader: true },
);
