import { styled } from '@ringcentral/juno';

export const ScaleContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;
