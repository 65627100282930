import React, { FC } from 'react';
import {
  Container,
  StyledLabel,
} from '@apw/components/profileDetail/profileBody/ratingsAndReviewsTab/ratingsSection/content/qualityRating/qualityRatingItem/qualityRatingItem.sc';
import { RectangleRating } from '@apw/components/ratingAndReview/rectangleRating/RectangleRating';

export interface IQualityRatingItemProps {
  text: string;
  tooltip: string;
  score: number;
}

export const QualityRatingItem: FC<IQualityRatingItemProps> = ({
  text,
  tooltip,
  score,
}) => {
  return (
    <Container data-test-automation-id={`quality-rating-item-${text}`}>
      <RectangleRating
        // @ts-ignore
        precision={0.1}
        value={score}
        readOnly
        data-test-automation-id={'quality-rating-item-rating'}
      />
      <StyledLabel
        text={text}
        tooltip={tooltip}
        labelProps={{
          color: 'neutral.f05',
          variant: 'caption1',
        }}
      />
    </Container>
  );
};
