import { UAParser } from 'ua-parser-js';

export enum DetectableBrowser {
  IE = 'IE',
  Edge = 'Edge',
  Chrome = 'Chrome',
  Firefox = 'Firefox',
}

export const getParsedBrowser = () => {
  const instance = new UAParser(window.navigator.userAgent);
  return instance.getBrowser();
};

export const isIE = () => {
  return getParsedBrowser().name === DetectableBrowser.IE;
};
