import React, { FC, ReactNode } from 'react';
import { Dialog, IAfterDialogClosed } from '@apw/shared/dialog';
import { ActionButton } from '@apw/shared/dialog/informDialog/informDialog.sc';
import { RcButtonColor } from '@ringcentral/juno/components/Buttons/Button/Button';
import { RcPaletteProp } from '@ringcentral/juno/foundation';
import { ICloseDialog } from '../types';

export interface InformDialogAction {
  label: string;
  color?: RcPaletteProp | RcButtonColor;
  variant?: 'contained' | 'outlined' | 'text';
  dialogResult?: any;
}

export interface InformDialogOptions {
  title: string;
  content: any;
  actions?: InformDialogAction[];
  // eslint-disable-next-line react/no-unused-prop-types
  afterClosed?: IAfterDialogClosed;
  hiddenCloseIcon?: boolean;
}

interface InformDialogProps extends InformDialogOptions {
  onClose: ICloseDialog;
}

export const InformDialog: FC<InformDialogProps> = ({
  title,
  content,
  actions = [],
  onClose,
  hiddenCloseIcon,
}) => {
  return (
    <Dialog
      title={title}
      content={content}
      actions={buildActionsNode(actions, onClose)}
      onClose={onClose}
      hiddenCloseIcon={hiddenCloseIcon}
    />
  );
};

const buildActionsNode = (
  actions: InformDialogAction[],
  closeDialog: ICloseDialog,
): ReactNode => {
  return (
    <div>
      {actions.map((action, index) => (
        <ActionButton
          key={action.label}
          color={action.color || 'text.primary'}
          variant={action.variant || 'contained'}
          onClick={() => closeDialog(action.dialogResult)}
          data-test-automation-id={`action-button-${index}`}
        >
          {action.label}
        </ActionButton>
      ))}
    </div>
  );
};
