import { createContext } from 'react';
import { IProfileOptions } from '@apw/components/profileDetail/typings';

export const defaultProfileOptions = {
  onDownload: () => {},
  onSupport: () => {},
  onRequireLoginGate: () => {},
  isPreviewMode: false,
};

export const ProfileOptionsContext = createContext<IProfileOptions>(
  defaultProfileOptions,
);
