import { Rating } from '@apw/components/ratingAndReview/rating/Rating';
import { onMobile } from '@apw/core/sc';
import { RcTypography, styled } from '@ringcentral/juno';

export const Container = styled.div`
  width: 192px;

  ${onMobile} {
    width: 100%;
    margin-bottom: 28px;
  }
`;

export const RatingContainer = styled.div`
  &&& {
    .MuiRating-decimal {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }

    .icon {
      font-size: 32px;
    }

    ${onMobile} {
      display: flex;
      align-items: center;
    }
  }
`;

export const StyledRating = styled(Rating)`
  ${onMobile} {
    margin-top: 0;
    display: block;
  }
`;

export const Score = styled(RcTypography)`
  margin-bottom: 4px;
`;

export const CountInfo = styled(RcTypography)`
  margin-top: 20px;

  ${onMobile} {
    margin-left: 4px;
    margin-top: 0;
  }
`;
