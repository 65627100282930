import { onMobile, useProximaNovaSemibold } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 640px;
  margin: auto;

  ${onMobile} {
    width: 100%;
  }
`;

export const Image = styled.img`
  width: 162px;
`;

export const Content = styled.div`
  ${useProximaNovaSemibold};
  margin-top: 16px;
  font-size: 18px;
  line-height: 24px;
  color: #282829;
  text-align: center;
  white-space: pre-line;

  a {
    text-decoration: none;
  }
`;

export const CallToAction = styled.div`
  margin-top: 32px;
`;
