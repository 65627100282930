import { useCallback } from 'react';
import { transport } from '@apw/core';
import { useCommonError } from '@apw/hooks/useCommonError';
import { IPageContent } from '@apw/modules/page/typings/page-content.interface';
import { useStores } from '@apw/stores';

export const usePageLoader = () => {
  const { pagesStore, compatibilityStore } = useStores();
  const commonError = useCommonError();
  return useCallback(
    async (vanityUrl: string): Promise<IPageContent | null | undefined> => {
      if (pagesStore.isLoading || !vanityUrl) {
        return;
      }

      const pageListItem = pagesStore.vanityUrlToPage[vanityUrl];

      if (!pageListItem) {
        return null;
      }

      const pageId = pageListItem.pageId;
      const brandId = compatibilityStore.brandId;

      try {
        return await transport.fetchGalleryPageContent(pageId, brandId);
      } catch (e) {
        commonError.show();
        return;
      }
    },
    [pagesStore.isLoading, pagesStore.vanityUrlToPage],
  );
};

const partnerNameTokenRe = /{{ PARTNER_NAME }}/g;

export const usePartnerNameReplacer = () => {
  const { compatibilityStore } = useStores();

  return useCallback((content: string): string => {
    const compatibility = compatibilityStore.compatibility;
    return content.replace(partnerNameTokenRe, compatibility.name);
  }, []);
};
