import {
  FontWeight,
  menuItemHoverActiveStatus,
  onMobile,
  removeRcMenuScrollEffectOnMobile,
  useProximaNovaRegular,
  useProximaNovaSemibold,
} from '@apw/core/sc';
import { RcMenuItem, styled } from '@ringcentral/juno';

export const CategoryItem = styled(RcMenuItem)`
  display: flex;
  justify-content: flex-start;
  padding: 4px 16px;
  width: 240px !important;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 16px !important;
  ${useProximaNovaRegular};
  ${menuItemHoverActiveStatus};

  ${onMobile} {
    width: auto !important;
  }

  &.selectCategoryItem {
    color: #282829 !important;
    font-weight: ${FontWeight.bold} !important;
    ${useProximaNovaSemibold};
  }

  &.mobileDevice:not(.selectCategoryItem) {
    &[role='menuitem'] {
      ${removeRcMenuScrollEffectOnMobile};
    }
  }
`;
