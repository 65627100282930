import { onMobile } from '@apw/core/sc';
import { RcDialog, RcIconButton, styled } from '@ringcentral/juno';

export const DialogCloseButton = styled(RcIconButton)`
  position: absolute !important;
  top: 8px !important;
  right: 8px !important;

  svg {
    font-size: 20px;
  }
`;

export const CustomDialog = styled(RcDialog)`
  [class~='MuiDialog-paper'] {
    ${onMobile} {
      max-width: none !important;
      max-height: none !important;
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
      border-radius: 0 !important;
    }
  }
`;
