import { onMobile } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const HeroContainer = styled.div`
  flex: 0 0 auto;
  height: 192px;
  width: calc(100% - 2px);
  margin: 0 auto 24px;
  border-radius: 6px;
  overflow: hidden;

  ${onMobile} {
    height: 214px;
    margin-bottom: 4px;
  }
`;

export const MainContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  height: 100%;
  margin: 56px 0;

  ${onMobile} {
    margin: 16px 0;
  }
`;

export const SidebarContainer = styled.div`
  flex-shrink: 0;
  ${onMobile} {
    display: none;
  }
`;

export const RightContent = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  width: 0;
`;
