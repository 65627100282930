import { overridesFactory } from '@apw/shared/theme/utils/overrides.factory';
import { paletteFactory } from '@apw/shared/theme/utils/palette.factory';
import { RcThemeInput } from '@ringcentral/juno';

interface ThemeBuilderOptions {
  colors: {
    primaryColor: string;
    secondaryColor: string;
  };
}

export const buildTheme = (options: ThemeBuilderOptions): RcThemeInput => {
  return {
    typography: {
      fontFamily: `'ProximaNova-Regular', Helvetica, Arial, sans-serif`,
    },
    palette: paletteFactory(
      options.colors.primaryColor,
      options.colors.secondaryColor,
    ),
    overrides: overridesFactory(options.colors.primaryColor),
  };
};
