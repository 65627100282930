import { applyProximaNovaRegular } from '@apw/shared/theme';
import { palette2, RcTabList, RcTabPanel, styled } from '@ringcentral/juno';

export const Container = styled.div`
  margin-top: 16px;
  margin-bottom: 40px;
`;

export const ProfileContent = styled.div`
  width: 100%;
  padding-top: 48px;
`;

export const ProfileContentWithTabs = styled.div`
  width: 100%;
`;

export const TabsWrapper = styled(RcTabList)`
  &.MuiTabs-root {
    height: 80px !important;
    min-height: 80px !important;
    padding: 0;
    border-bottom: 1px solid rgb(232, 233, 237);

    & + div {
      padding-top: 40px;
      overflow: visible;
    }
  }

  .MuiTab-root {
    min-height: 80px;
    padding: 0;
    margin-left: 40px;

    &:first-child {
      margin-left: 0;
    }
  }

  .MuiTab-textColorPrimary {
    &:hover,
    &.Mui-selected {
      color: ${palette2('secondary', 'main')};
    }

    .MuiTouchRipple-root {
      display: none;
    }

    &:focus-visible {
      .MuiTouchRipple-root {
        display: inline;
      }
    }
  }

  .MuiTabs-indicator {
    background-color: ${palette2('secondary', 'main')};
  }

  .MuiTab-wrapper {
    ${applyProximaNovaRegular()}
  }
`;

export const TabPanel = styled(RcTabPanel)`
  padding: 40px 0 0;
`;
