import { css, palette2, styled } from '@ringcentral/juno';

export const Rectangle = styled.div`
  width: 40px;
  height: 20px;
  background-color: ${palette2('interactive', 'f01')};
`;

export const EmptyRectangle = styled(Rectangle)`
  background-color: transparent;
  border: 1px solid ${palette2('neutral', 'l03')};
`;

export const RatingMixin = css`
  [class~='MuiRating-label'] {
    padding: 0 4px !important;

    &:nth-of-type(1) {
      ${Rectangle}, ${EmptyRectangle} {
        border-bottom-left-radius: 9px;
        border-top-left-radius: 9px;
      }
    }
    &:nth-of-type(5) {
      ${Rectangle}, ${EmptyRectangle} {
        border-bottom-right-radius: 9px;
        border-top-right-radius: 9px;
      }
    }
  }

  [class~='MuiRating-decimal'] {
    [class~='MuiRating-icon'] {
      box-sizing: content-box;
      padding: 0 !important;
    }

    margin: 0 4px;
    &:first-child {
      ${Rectangle}, ${EmptyRectangle} {
        border-bottom-left-radius: 9px;
        border-top-left-radius: 9px;
      }
    }
    &:last-child {
      > span:last-child {
        ${Rectangle}, ${EmptyRectangle} {
          border-bottom-right-radius: 9px;
          border-top-right-radius: 9px;
        }
      }
    }
  }
`;
