import { useContext } from 'react';
import { ApiError, safeWindowOpen, transport } from '@apw/core';
import { isSessionTimeout } from '@apw/core/api/api-error.service';
import { Language, languageService } from '@apw/core/language';
import { useCommonError } from '@apw/hooks/useCommonError';
import { IErrorDialogContent } from '@apw/modules/botProvision/typings';
import { GlobalInformDialogContext, IDialogController } from '@apw/shared';
import { backOtherwiseClose } from '@apw/stores/user';
import { includes } from 'lodash';

export const useBotProvision = () => {
  const dialog = useContext<IDialogController>(GlobalInformDialogContext);
  const commonError = useCommonError();

  const checkAccountTier = async (data: any = {}) => {
    let isFreeTier;

    try {
      isFreeTier = await transport.fetchIsFreeTier();
    } catch (error) {
      if (!isSessionTimeout(error)) {
        commonError.show();
      }
      return false;
    }

    if (isFreeTier) {
      handleFreeTier(data);
      return false;
    }
    return true;
  };

  const handleFreeTier = (data: any = {}) => {
    const { shouldReturn = false } = data;
    dialog
      .show({
        title: languageService.get(Language.TITLE_SORRY),
        content: languageService.get(Language.FREE_TIER_CANNOT_INSTALL_BOT),
        actions: [
          {
            label: languageService.get(Language.FREE_TIER_UPGRADE_BUTTON_LABEL),
            dialogResult: 'link',
          },
        ],
      })
      .afterClosed((actionResult) => {
        if (actionResult === 'link') {
          safeWindowOpen(
            languageService.get(Language.RC_PLANS_AND_PRICING_PAGE_URL),
          );
          return;
        }
        if (shouldReturn) {
          backOtherwiseClose();
        }
      });
  };

  const handleError = (code: string, data?: any) => {
    if (
      includes(
        [
          ApiError.NOT_LOGGED_IN,
          ApiError.REQUEST_RATE_EXCEEDED,
          ApiError.SESSIONS_NUMBER_EXCEEDED,
          ApiError.ACCESS_TOKEN_CORRUPTED,
        ],
        code,
      )
    ) {
      return;
    }

    const content: {
      code: string;
      dialogContent: IErrorDialogContent | null;
    } = {
      code,
      dialogContent: null,
    };
    switch (code) {
      case 'BOT_HIPAA':
        handleHipaaError(data);
        break;
      case 'BOT_BRAND_NOT_SUPPORTED':
        handleBrandNotSupportedError(data);
        break;
      case 'BOT_ALREADY_INSTALLED':
        handleAlreadyInstalledError(data);
        break;
      case 'NO_BOT_MANAGE_PERMISSION':
        handleNoPermissionError(data);
        break;
      case 'BOT_CALL_REDIRECT_URI_FAIL':
        handleCallRedirectUriError(data);
        break;
      case 'BOT_NAME_FORMAT_ERROR':
      case 'BOT_NAME_OCCUPIED':
        break;
      default:
        commonError.show();
    }
    return content;
  };

  const handleAlreadyInstalledError = (data: any = {}): void => {
    const { shouldReturn = false, installedBotName } = data;

    dialog
      .show({
        title: '',
        content: languageService.getWithContext(
          Language.BOT_ALREADY_INSTALLED_ERROR,
          installedBotName,
        ),
      })
      .afterClosed(() => {
        if (shouldReturn) {
          backOtherwiseClose();
        }
      });
  };

  const handleAlreadyRemovedError = (data: any = {}): void => {
    const { installedBotName } = data;
    dialog.show({
      title: '',
      content: languageService.getWithContext(
        Language.BOT_ALREADY_REMOVED_ERROR,
        installedBotName,
      ),
    });
  };

  const handleCallRedirectUriError = (data: any = {}): void => {
    const { shouldReturn = false } = data;

    dialog
      .show({
        title: languageService.get(Language.TITLE_SORRY),
        content: languageService.get(
          Language.BOT_PROVISION_CALL_REDIRECT_URI_ERROR,
        ),
      })
      .afterClosed(() => {
        if (shouldReturn) {
          backOtherwiseClose();
        }
      });
  };

  const handleHipaaError = (data: any = {}): void => {
    const { shouldReturn = false } = data;

    dialog
      .show({
        title: languageService.get(Language.TITLE_SORRY),
        content: languageService.get(Language.HIPAA_ACCOUNT),
      })
      .afterClosed(() => {
        if (shouldReturn) {
          backOtherwiseClose();
        }
      });
  };

  const handleBrandNotSupportedError = (data: any = {}): void => {
    dialog
      .show({
        title: languageService.get(Language.TITLE_SORRY),
        content: languageService.get(Language.BOT_BRAND_NOT_SUPPORTED),
      })
      .afterClosed(() => {
        if (data.shouldReturn) {
          backOtherwiseClose();
        }
      });
  };

  const handleNoPermissionError = (data: any = {}): void => {
    const {
      shouldReturn = false,
      onInstallation = false,
      onRemoval = false,
    } = data;

    let permissionName;
    if (onInstallation) {
      permissionName = '"Add Bots"';
    }
    if (onRemoval) {
      permissionName = '"Remove Bots"';
    }

    dialog
      .show({
        title: languageService.get(Language.TITLE_SORRY),
        content: languageService.getWithContext(
          Language.BOT_PROVISION_NO_PERMISSION,
          permissionName,
        ),
      })
      .afterClosed(() => {
        if (shouldReturn) {
          backOtherwiseClose();
        }
      });
  };

  const handleWebhookSubscriptionFailure = (): void => {
    dialog.show({
      title: languageService.get(Language.TITLE_SORRY),
      content: languageService.get(Language.BOT_SUBSCRIBE_WEB_HOOK_FAIL),
    });
  };

  return {
    handleFreeTier,
    checkAccountTier,
    handleError,
    handleAlreadyRemovedError,
    handleAlreadyInstalledError,
    handleNoPermissionError,
    handleHipaaError,
    handleBrandNotSupportedError,
    handleWebhookSubscriptionFailure,
  };
};
