import { onMobile, useProximaNovaSemibold } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const RequirementsWrapper = styled.div`
  width: 100%;
`;

export const RequirementHeader = styled.h5`
  ${useProximaNovaSemibold};
  margin: 0 0 20px;
  font-size: 22px;
  font-weight: 600;
  color: #001138;

  ${onMobile} {
    font-size: 20px;
  }
`;

export const RequirementList = styled.div`
  width: 100%;
`;

export const RequirementItem = styled.div`
  width: 100%;
`;
