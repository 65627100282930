import { IAppRating } from '@apw/lib/types/profile/appRating.interface';

export const buildOverallItems = (ratings: IAppRating) => {
  return [
    {
      stars: 5,
      percentage: ratings.percentageStar5,
    },
    {
      stars: 4,
      percentage: ratings.percentageStar4,
    },
    {
      stars: 3,
      percentage: ratings.percentageStar3,
    },
    {
      stars: 2,
      percentage: ratings.percentageStar2,
    },
    {
      stars: 1,
      percentage: ratings.percentageStar1,
    },
  ];
};
