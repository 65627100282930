import {
  IMyReviewItemResponse,
  IPublishedReviewItemsResponse,
} from '@apw/components/ratingAndReview/typings/reviewItem.interface';
import { del, get } from '@apw/core/api/api.service';

export const fetchMyReviewItem = (
  applicationId: string,
): Promise<IMyReviewItemResponse> => {
  const endpoint = `/api/rating-review/applications/${applicationId}/reviews/my-review`;
  return get(endpoint, {
    useGlobalLoading: true,
  });
};

export const fetchPublishedReviewItems = (
  applicationId: string,
  page: number,
): Promise<IPublishedReviewItemsResponse> => {
  const endpoint = `/api/rating-review/applications/${applicationId}/reviews`;
  return get(endpoint, {
    useGlobalLoading: page === 1,
    params: {
      page,
    },
  });
};

export const deleteMyReviewItem = (applicationId: string): Promise<void> => {
  const endpoint = `/api/rating-review/applications/${applicationId}/reviews/my-review`;
  return del(endpoint, {
    useGlobalLoading: true,
  });
};
