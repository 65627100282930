export enum MediaTypeEnum {
  SCREENSHOT = 'Screenshot',
  VIDEO = 'Video',
}

export enum MediaPropEnum {
  TYPE = 'type',
  URL = 'url',
}

export interface IMedia {
  [MediaPropEnum.TYPE]: MediaTypeEnum;
  [MediaPropEnum.URL]: string;
}

export enum ScreenshotPropEnum {
  TYPE = 'type',
  URL = 'url',
  TITLE = 'title',
  DESC = 'description',
}

export interface Screenshot extends IMedia {
  [ScreenshotPropEnum.TYPE]: MediaTypeEnum.SCREENSHOT;
  [ScreenshotPropEnum.TITLE]: string;
  [ScreenshotPropEnum.DESC]: string;
}

export enum VideoPropEnum {
  TYPE = 'type',
  URL = 'url',
}

export interface IVideo extends IMedia {
  [VideoPropEnum.TYPE]: MediaTypeEnum.VIDEO;
}
