import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from 'react';
import Iframe from '@apw/components/onetrust/Iframe';
import { sanitizeUrl } from '@apw/core';
import { updateParams } from '@apw/core/url/url.service';
import { VideoSlideRef } from '../video-slide.interface';
import { urlPatterns } from './vimeo-slide.service';

const VimeoSlide = React.forwardRef<VideoSlideRef, any>(
  ({ url, autoPlay = false }, ref) => {
    const [updateState, forceUpdate] = useReducer((x) => x + 1, 0);
    useImperativeHandle<any, VideoSlideRef>(ref, () => ({
      forceUpdate,
    }));

    const [vimeoUrl, setVimeoUrl] = useState('');

    const buildIframeUrl = (videoId: string): string => {
      return `https://player.vimeo.com/video/${videoId}`;
    };

    const getVideoId = (url: string): string => {
      let videoId = '';

      for (const pattern of urlPatterns) {
        const matches = url.match(pattern);

        if (matches) {
          videoId = matches[1];
          break;
        }
      }
      return videoId;
    };

    const resolve = (url: string) => {
      const videoId = getVideoId(url);
      let iframeUrl = buildIframeUrl(videoId);

      if (autoPlay) {
        iframeUrl = updateParams(iframeUrl, { autoplay: 1 });
      }
      setVimeoUrl(iframeUrl);
    };

    useEffect(() => {
      resolve(url);
    }, [url]);

    return (
      <Iframe
        key={updateState}
        className="vimeo-player optanon-category-C0004"
        style={{ border: 'none' }}
        allow="autoplay; fullscreen"
        allowFullScreen
        src={sanitizeUrl(vimeoUrl)}
        width="100%"
        height="100%"
        data-test-automation-id={'video-slide-item'}
        title={`vimeo video - ${url}`}
      />
    );
  },
);

export default VimeoSlide;
