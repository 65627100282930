import { RcIconButton, styled, keyframes } from '@ringcentral/juno';

const ICON_COLOR = '#686869';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Icon = styled(RcIconButton)`
  svg {
    font-size: 28px;
    color: ${ICON_COLOR} !important;
  }

  &:hover {
    background: rgba(${ICON_COLOR}, 0.08) !important;
  }
`;

export const BackToTop = styled.div`
  display: none;
  position: fixed;
  right: 16px;
  bottom: 128px;
  z-index: 20;

  &.visible {
    display: block;
    animation: ${fadeIn} 0.4s linear;
  }
`;
