import { onMobile } from '@apw/core/sc';
import { styled, RcDialog } from '@ringcentral/juno';

export const Popup = styled(RcDialog)`
  [class~='RcDialog-paper'] {
    ${onMobile} {
      max-width: none !important;
      max-height: none !important;
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
      border-radius: 0 !important;
    }
  }
`;
