import React, { FC, useContext } from 'react';
import { StoreContext } from '@apw/lib/components/profileDetail/StoreContext';
import { buildOverallItems } from '@apw/lib/components/ratingAndReview/overallRating/overallRating.service';
import { RatingInfo } from '@apw/lib/components/ratingAndReview/ratingInfo/RatingInfo';
import { IAppRating } from '@apw/lib/types/profile/appRating.interface';
import {
  BasicInfo,
  ScoreWrapper,
  StyledOverallRating,
} from './ratingsSection.sc';
import { ViewReviewButton } from './viewReviewsButton/ViewReviewsButton';

export interface IRatingsSectionProps {
  ratings: IAppRating;
}

export const RatingsSection: FC<IRatingsSectionProps> = ({ ratings }) => {
  const { translation } = useContext(StoreContext);
  const overallRatingItems = buildOverallItems(ratings);
  return (
    <div>
      <BasicInfo data-test-automation-id={'overview-tab-ratings-section'}>
        <ScoreWrapper
          color="neutral.f06"
          variant="display3"
          data-test-automation-id={'ratings-section-score'}
          tabIndex={0}
        >
          {ratings.overallRating.toFixed(1)}
        </ScoreWrapper>
        <RatingInfo
          rating={ratings.overallRating}
          ratingCount={ratings.voteCount}
          translation={translation.ratingsAndReviews.basicInfo}
        />
        <ViewReviewButton />
      </BasicInfo>
      <StyledOverallRating
        items={overallRatingItems}
        automationId={'overview-tab-overall-ratings'}
      />
    </div>
  );
};
