import React, { FC } from 'react';
import { find } from 'lodash';
import { BotTypeStyled } from './BotType.sc';

export enum BotTypeEnum {
  PUBLIC,
  PRIVATE,
  INTERNAL,
}

interface BotTypeProps {
  type: string;
}

export const BotType: FC<BotTypeProps> = ({ type }) => {
  const config = getBotTypeConfig(type);

  if (!config) {
    return null;
  }

  return (
    <BotTypeStyled
      className={`${config!.className}`}
      data-test-automation-id="installed-bot-type"
    >
      {config!.label}
    </BotTypeStyled>
  );
};

export interface BotTypeConfig {
  id: string;
  label: string;
  className: string;
}

export const botTypes: { [key: number]: BotTypeConfig } = {
  [BotTypeEnum.PRIVATE]: {
    id: 'Private',
    label: 'Private',
    className: 'bot-list-bot-type-private',
  },
  [BotTypeEnum.INTERNAL]: {
    id: 'Internal',
    label: 'Internal',
    className: 'bot-list-bot-type-internal',
  },
};

const getBotTypeConfig = (id: string): BotTypeConfig | undefined => {
  return find(botTypes, { id });
};
