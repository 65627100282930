import { styled } from '@ringcentral/juno';

export const AppLogo = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
  }

  &.logoSquareWithSpacing {
    position: relative;

    img {
      position: absolute;
      top: 10px;
      right: 10px;
      bottom: 10px;
      left: 10px;
      margin: auto;
      max-width: calc(100% - 20px);
      max-height: calc(100% - 20px);
    }
  }
`;
