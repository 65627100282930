import {
  onMobile,
  PAGE_DESKTOP_MARGINTOP,
  PAGE_MOBILE_MARGINTOP,
} from '@apw/core/sc';
import { css, styled } from '@ringcentral/juno';

export const navigationBarMixin = css`
  margin-bottom: 0 !important;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const ProfileContainer = styled.div`
  max-width: 1024px;
  margin: ${PAGE_DESKTOP_MARGINTOP}px auto 0;
  width: 100%;

  ${onMobile} {
    margin: ${PAGE_MOBILE_MARGINTOP}px auto 0;
  }
`;
