import React, { FC } from 'react';
import { ensureAction, transport } from '@apw/core';
import { useInformDialog } from '@apw/hooks';
import { useCommonError } from '@apw/hooks/useCommonError';
import { BotAction } from '@apw/modules/myApps/botList/botListItem/botAction';
import { BotLogo } from '@apw/modules/myApps/botList/botListItem/botLogo';
import { BotType } from '@apw/modules/myApps/botList/botListItem/botType';
import { getCompatibilityProfilePath } from '@apw/routes/getCompatibilityPaths';
import { useStores } from '@apw/stores';
import { useHistory } from 'react-router-dom';
import { IBotItem } from '../typings';
import { BotInstaller } from './botInstaller';
import { BotItem, BotNameWrapper, BotName, BotDesc } from './BotListItem.sc';

interface BotListItemProps {
  item: IBotItem;
  onChange: () => void;
}

export const BotListItem: FC<BotListItemProps> = ({ item, onChange }) => {
  const { userStore, compatibilitiesStore } = useStores();
  const history = useHistory();
  const commonError = useCommonError();
  const informDialog = useInformDialog();

  const botLogo = <BotLogo src={item.logoUrl} />;
  const botName = (
    <BotName data-test-automation-id="my-apps-bpt-name">{item.name}</BotName>
  );
  const botType = <BotType type={item.type} />;
  const botInstalled = <BotInstaller item={item} />;
  const botDesc = <BotDesc>{item.desc}</BotDesc>;
  const botAction = <BotAction item={item} onChange={onChange} />;

  const keyDownBotRecord = (e: KeyboardEvent) => {
    const actionFn = () => goToProfile();
    ensureAction(e, actionFn);
  };

  const goToProfile = async () => {
    try {
      const userBrandId = userStore.user?.brandId || '';
      const compatibility =
        compatibilitiesStore.getCompatibilityByIdOrSubBrandId(userBrandId);
      const brandId = compatibility?.id;
      if (!brandId) {
        commonError.show();
        return;
      }
      const profileInfo = await transport.getProfileByAppIdAndBrandId(
        item.appId,
        brandId,
      );
      const slug =
        compatibility?.slug || compatibilitiesStore.rcCompatibility?.slug || '';
      const path = getCompatibilityProfilePath(slug, profileInfo.vanityUrl);
      history.push(path);
    } catch (e) {
      if (e.response?.status === 404) {
        popupProfileNotFound();
        return;
      }
      commonError.show();
    }
  };

  const popupProfileNotFound = () => {
    informDialog.show({
      title: 'Sorry!',
      content: 'This app does not exist or has been removed.',
    });
  };

  return (
    <React.Fragment>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <BotItem
        data-test-automation-id={`botItem-${item.id}`}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        onClick={() => goToProfile()}
        onKeyDown={(e: any) => keyDownBotRecord(e)}
      >
        <div className={'contentWrapper'}>
          <div className={'headerWrapper'}>
            <div
              className={'botLogoWrapper'}
              data-test-automation-id="bot-list-item-logo"
            >
              {botLogo}
            </div>
            <BotNameWrapper>
              {botName}
              {botType}
              {botInstalled}
            </BotNameWrapper>
          </div>
          {item.desc && (
            <div
              className={'botDescWrapper'}
              data-test-automation-id="bot-list-item-desc"
            >
              {botDesc}
            </div>
          )}
        </div>
        {botAction}
      </BotItem>
    </React.Fragment>
  );
};
