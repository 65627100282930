import {
  FeatureTypeEnum,
  IFeatureApp,
  IFeatureBase,
  IFeaturePage,
} from '@apw/lib/types';

export const flexNumMap = [1, 2, 0, 1, 2, 0, 2, 2, 0];

export const filterFeatures = (items: IFeatureBase[]) => {
  return items.filter((item) => {
    if (item.featureType === FeatureTypeEnum.App) {
      const i = item as IFeatureApp;
      if (!i.profile?.vanityUrlApplicationName) {
        return false;
      }
    }

    if (item.featureType === FeatureTypeEnum.Page) {
      const i = item as IFeaturePage;
      if (!i.page?.vanityUrl) {
        return false;
      }
    }
    return true;
  });
};

export const isFeatureBlockAvailable = (items: IFeatureBase[]) => {
  const availableItems = filterFeatures(items);
  if (!availableItems || availableItems.length === 0) return false;
  const num = flexNumMap[items.length - 1];
  return items.slice(0, num).length > 0 || items.slice(num).length > 0;
};
