import React, { FC, useEffect, useState } from 'react';
import { IProcessedProfile } from '@apw/components/profileDetail';
import {
  DownloadPopup,
  isDownloadableUrl,
  LeavingPopup,
  sendMarketoForm,
} from '@apw/modules/profile';
import { PopupTypes } from '@apw/modules/profile/downloadGate/popupTypes';
import { withGlobalScroll } from '@apw/shared/withGlobalScroll';
import { useStores } from '@apw/stores';
import { observer } from 'mobx-react';

interface DownloadGateProps {
  profile: IProcessedProfile;
  downloadUrl: string;
  onClose: () => void;
}

type PopupType =
  | PopupTypes.LoginOption
  | PopupTypes.Download
  | PopupTypes.Leaving
  | null;

const DownloadGatePure: FC<DownloadGateProps> = ({
  profile,
  downloadUrl,
  onClose,
}) => {
  const [popupType, setPopupType] = useState<PopupType>(null);
  const { userStore } = useStores();

  const showGetAppPopup = () => {
    const type = isDownloadableUrl(downloadUrl)
      ? PopupTypes.Download
      : PopupTypes.Leaving;
    setPopupType(type);
  };

  const handleAfterSignIn = () => {
    showGetAppPopup();
    sendMarketoForm(profile._id, profile.appName);
  };

  useEffect(() => {
    if (userStore.isLoggedIn) {
      handleAfterSignIn();
    } else {
      showGetAppPopup();
    }
  }, []);

  return (
    <>
      {popupType === PopupTypes.Download && (
        <DownloadPopup
          profile={profile}
          downloadUrl={downloadUrl}
          onClose={onClose}
        />
      )}
      {popupType === PopupTypes.Leaving && (
        <LeavingPopup leavingUrl={downloadUrl} onClose={onClose} />
      )}
    </>
  );
};

export const DownloadGate = withGlobalScroll(observer(DownloadGatePure));
