import { useEffect, useState } from 'react';
import { BaseViewModel } from '../types';

export const useVM = <VM extends BaseViewModel<P>, P>(
  ViewModel: new (props: P) => VM,
  props?: P,
) => {
  const deps = { ...props } as P;
  const [vm] = useState(() => new ViewModel(deps));

  useEffect(() => {
    return () => {
      vm.dispose();
    };
  }, [vm]);

  return vm;
};
