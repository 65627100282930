import React, { useEffect, useState } from 'react';
import { SEO, Sticky } from '@apw/components';
import { InfiniteScroll } from '@apw/components/infiniteScroll';
import { IPageCard } from '@apw/components/pageCard';
import { googleAnalytics, mixpanel, transport } from '@apw/core';
import { useQueryParams, useRedirectionRules } from '@apw/hooks';
import { useCommonError } from '@apw/hooks/useCommonError';
import { useDebounce } from '@apw/hooks/useDebounce';
import { PageSearchResult } from '@apw/modules/search/pageSearch';
import { redirectionRulesFactory } from '@apw/modules/search/redirectionRules.factory';
import { SuggestApp } from '@apw/modules/search/suggestApp/SuggestApp';
import { getCompatibilitySearchPath } from '@apw/routes/getCompatibilityPaths';
import { BackToTop, withLayout } from '@apw/shared';
import { CompatibilitySelector } from '@apw/shared/navigation';
import {
  ICoveoResultForPage,
  ICoveoResultForProfile,
  coveoPageService,
  coveoProfileService,
  PER_PAGE_FOR_PAGES,
  PER_PAGE_FOR_PROFILES,
} from '@apw/shared/searchProvider';
import {
  SearchResult,
  SearchResultType,
  SearchSource,
  searchTracking,
} from '@apw/shared/searchTracking';
import { breakpoints } from '@apw/shared/theme';
import { useStores } from '@apw/stores';
import { CoveoSearchStore } from '@apw/stores/CoveoSearchStore';
import {
  IpaasListItem,
  PagingInterface,
  ProfileListItemInterface,
  ProfileListRequestInterface,
  ProfileListResponseInterface,
} from '@apw/types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  allCategories,
  allProductsItem,
  FilterMobileBar,
  FilterSideBar,
} from './filterBar';
import { IpaasSearchResult } from './ipaasSearch';
import { ProfileSearchResult } from './profileSearch';
import {
  LeftContent,
  MainContainer,
  MobileBar,
  NavigationBarStyled,
  ResultList,
  ResultTitle,
  RightContent,
} from './SearchPage.sc';

interface LoadingState {
  page: number;
  canLoadMore: boolean;
  initialized: boolean;
  loading: boolean;
  total: number;
}

const initLoadingState = (): LoadingState => {
  return {
    page: 0,
    canLoadMore: false,
    initialized: false,
    loading: false,
    total: 0,
  };
};

const buildLoadingState = (paging: PagingInterface): LoadingState => {
  return {
    page: paging.page,
    canLoadMore: paging.page < paging.totalPages,
    initialized: true,
    loading: false,
    total: paging.totalElements,
  } as LoadingState;
};

const trackBackToTop = () => {
  googleAnalytics.trackPageView('GalleryList:BackToTop');
};

const shouldUseCoveo = (
  coveoSearchStore: CoveoSearchStore,
  searchText: string | undefined,
) => {
  return coveoSearchStore.isEnabled && searchText;
};

const SearchPagePure = (props) => {
  const { headerHeight } = props;
  const [profileList, setProfileList] = useState(
    [] as ProfileListItemInterface[],
  );
  const [ipaasList, setIpaasList] = useState([] as IpaasListItem[]);
  const [pageList, setPageList] = useState([] as IPageCard[]);
  const [profileState, setProfileState] = useState(() => initLoadingState());
  const [ipaasState, setIpaasState] = useState(() => initLoadingState());
  const [pageState, setPageState] = useState(() => initLoadingState());
  const [redirected, setRedirected] = useState<boolean>(true);

  const location = useLocation();
  const history = useHistory();
  const isMobile = useMediaQuery(breakpoints.mobile);
  const {
    compatibilityStore,
    searchStore,
    coveoSearchStore,
    compatibilitiesStore,
  } = useStores();
  const commonError = useCommonError();
  const queryParams = useQueryParams();

  const onCompatibilityChanged = () => {
    const queryString = searchStore.getQueryString();
    const newPathSearch = getCompatibilitySearchPath(
      compatibilityStore.compatibility.slug,
    );
    history.push(`${newPathSearch}${queryString}`);
  };

  const searchProfilesFromCoveo = (
    request: ProfileListRequestInterface,
  ): Promise<ProfileListResponseInterface> => {
    const page = request.page || 1;

    const firstScreenProfileCache = searchStore.getFirstScreenProfileCache();

    if (page === 1 && firstScreenProfileCache) {
      return Promise.resolve(firstScreenProfileCache);
    }

    const promise = transport.searchProfilesFromCoveo({
      brand: request.brand,
      keyword: request.searchText || '',
      product: request.product,
      category: request.appCategory,
      paging: {
        page,
        perPage: PER_PAGE_FOR_PROFILES,
      },
    });

    return promise
      .then((res) => {
        return {
          items: res.results.map((result: ICoveoResultForProfile) => {
            return coveoProfileService.transform(result);
          }),
          paging: {
            page: request.page || 1,
            perPage: PER_PAGE_FOR_PROFILES,
            totalElements: res.totalCount,
            totalPages: Math.ceil(res.totalCount / PER_PAGE_FOR_PROFILES),
          } as any,
        };
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 429) {
          return transport.searchProfiles({
            brand: compatibilityStore.brandId,
            searchText: request.searchText || '',
          });
        }
        throw err;
      });
  };

  const requestSearchProfiles = (
    request: ProfileListRequestInterface,
  ): Promise<ProfileListResponseInterface> => {
    if (request.apps) {
      return transport.fetchMultipleApps(request);
    }

    if (request.badge) {
      return transport.searchProfiles(request);
    }

    return shouldUseCoveo(coveoSearchStore, request.searchText)
      ? searchProfilesFromCoveo(request)
      : transport.searchProfiles(request);
  };

  const searchProfiles = (page: number) => {
    setProfileLoadingState(true);

    const request: ProfileListRequestInterface = {
      searchText: searchStore.searchText || undefined,
      brand: compatibilityStore.brandId,
      product:
        searchStore.selectedProductId === allProductsItem.id
          ? undefined
          : searchStore.selectedProductId,
      appCategory:
        searchStore.selectedCategory === allCategories.id
          ? undefined
          : searchStore.selectedCategory,
      badge:
        searchStore.selectedPartnerBadge === null
          ? undefined
          : searchStore.selectedPartnerBadge,
      apps:
        searchStore.specifiedVanityUrls === null
          ? undefined
          : searchStore.specifiedVanityUrls,
      page,
    };

    return requestSearchProfiles(request)
      .then((res) => {
        const items =
          res.paging.page === 1 ? res.items : [...profileList, ...res.items];
        setProfileList(items);
        setProfileState(buildLoadingState(res.paging));
      })
      .catch(() => {
        commonError.show();
      })
      .finally(() => {
        setProfileLoadingState(false);
      });
  };

  const clearPage = () => {
    setPageList([]);
    setPageState(initLoadingState());
  };

  const searchPages = (page: number) => {
    if (!shouldUseCoveo(coveoSearchStore, searchStore.searchText)) {
      clearPage();
      return;
    }

    setPageLoadingState(true);

    const firstScreenPageCache = searchStore.getFirstScreenPageCache();

    if (page === 1 && firstScreenPageCache) {
      const { items, paging } = firstScreenPageCache;
      setPageList(items);
      setPageState(buildLoadingState(paging));
      return;
    }

    const promise = transport.searchPagesFromCoveo({
      brand: compatibilityStore.brandId,
      keyword: searchStore.searchText || '',
      paging: {
        page,
        perPage: PER_PAGE_FOR_PAGES,
      },
    });

    promise
      .then((res) => {
        return {
          items: res.results.map((result: ICoveoResultForPage) => {
            return coveoPageService.transform(result);
          }),
          paging: {
            page,
            perPage: PER_PAGE_FOR_PAGES,
            totalElements: res.totalCount,
            totalPages: Math.ceil(res.totalCount / PER_PAGE_FOR_PAGES),
          } as any,
        };
      })
      .then((res) => {
        const items =
          res.paging.page === 1 ? res.items : [...pageList, ...res.items];
        setPageList(items);
        setPageState(buildLoadingState(res.paging));
      })
      .catch(() => {
        clearPage();
      })
      .finally(() => {
        setPageLoadingState(false);
      });
  };

  const searchIpaas = (page: number) => {
    setIpaasLoadingState(true);

    transport
      .searchIpaas(
        searchStore.searchText,
        compatibilityStore.compatibility.id,
        page,
      )
      .then((res) => {
        const isFirstPage = res.paging.page === 1;
        setIpaasList((items) =>
          isFirstPage ? res.items : [...items, ...res.items],
        );
        setIpaasState(buildLoadingState(res.paging));
      })
      .catch(() => commonError.show())
      .finally(() => {
        setIpaasLoadingState(false);
      });
  };

  const onScroll = useDebounce(() => {
    if (profileState.loading || pageState.loading || ipaasState.loading) {
      return;
    }

    if (profileState.canLoadMore) {
      searchProfiles(profileState.page + 1);
      return;
    }

    if (ipaasState.canLoadMore) {
      searchIpaas(ipaasState.page + 1);
      return;
    }

    if (pageState.canLoadMore) {
      searchPages(pageState.page + 1);
      return;
    }
  }, 300);

  const redirectionProcess = useRedirectionRules(redirectionRulesFactory);

  const setProfileLoadingState = (loading: boolean) => {
    setProfileState((prevState) => ({
      ...prevState,
      loading,
    }));
  };

  const setPageLoadingState = (loading: boolean) => {
    setPageState((prevState) => ({
      ...prevState,
      loading,
    }));
  };

  const setIpaasLoadingState = (loading: boolean) => {
    setIpaasState((prevState) => ({
      ...prevState,
      loading,
    }));
  };

  useEffect(() => {
    if (Object.keys(queryParams).length > 0) {
      redirectionProcess
        .kickStart({
          queryParams,
          pathname: location.pathname,
        })
        .then((isRedirected) => {
          setRedirected(isRedirected);
        });
    } else {
      setRedirected(false);
    }
  }, [queryParams, compatibilityStore.brandId, coveoSearchStore.loading]);

  useEffect(() => {
    if (redirected || coveoSearchStore.loading) {
      return;
    }

    searchProfiles(1).then(() => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 300);
    });
  }, [redirected, searchStore.criteriaForProfiles, coveoSearchStore.loading]);

  useEffect(() => {
    if (redirected || coveoSearchStore.loading) {
      return;
    }

    searchPages(1);
  }, [redirected, searchStore.criteriaForPages, coveoSearchStore.loading]);

  useEffect(() => {
    if (redirected || coveoSearchStore.loading) {
      return;
    }

    searchIpaas(1);
  }, [redirected, searchStore.criteriaForIPAAS, coveoSearchStore.loading]);

  useEffect(() => {
    searchStore.resolve(location.search);
  }, [location.search]);

  useEffect(() => {
    googleAnalytics.trackPageView(`GallerySearch`);
    mixpanel.trackPageView(`App Gallery Search`);
  }, []);

  useEffect(() => {
    if (redirected) {
      return;
    }
    if (searchStore.searchText) {
      googleAnalytics.trackPageView(
        `GalleryList:Search:${searchStore.searchText}`,
      );
      mixpanel.trackSearchBehavior('Text Search', searchStore.searchText);
    }
  }, [redirected, searchStore.searchText]);

  useEffect(() => {
    if (redirected) {
      return;
    }
    if (searchStore.selectedProductId !== allProductsItem.id) {
      googleAnalytics.trackPageView(
        `GalleryList:Product:${searchStore.selectedProductId}`,
      );
      mixpanel.trackSearchBehavior('Product', searchStore.selectedProductId);
    }
  }, [redirected, searchStore.selectedProductId]);

  useEffect(() => {
    if (redirected) {
      return;
    }
    if (searchStore.selectedCategory !== allCategories.id) {
      googleAnalytics.trackPageView(
        `GalleryList:Category:${searchStore.selectedCategory}`,
      );
      mixpanel.trackSearchBehavior('Category', searchStore.selectedCategory);
    }
  }, [redirected, searchStore.selectedCategory]);

  // Reset Search Store When SearchPage Component Destroy
  useEffect(() => {
    return () => {
      searchStore.reset();
    };
  }, []);

  const searchPageTitle = () => {
    return `Apps and Integrations for ${compatibilityStore.compatibility.name}`;
  };

  const addSuffix =
    compatibilitiesStore.getDefaultCompatibility().name !==
    compatibilityStore.compatibility.name;

  const searchPageDesc = () => {
    return `Live in your apps - seamlessly integrate your application with ${compatibilityStore.compatibility.name}.`;
  };

  const getCanonicalLink = () => {
    const suffix = 'search';
    const brandSlug = compatibilityStore.compatibility.slug;
    return brandSlug ? `${brandSlug}/${suffix}` : suffix;
  };

  const getTotalSearchResultsNum = () => {
    return `${profileState.total + pageState.total + ipaasState.total}`;
  };

  // DPW-16330
  const totalAppsSearchResultsNum = () => {
    return `${profileState.total + ipaasState.total}`;
  };

  const onSearchClick = (
    clickLink: string,
    resultType: SearchResultType,
    clickDepth: number,
  ): void => {
    const params = {
      productFilter: searchStore.selectedProductId,
      categoryFilter: searchStore.selectedCategory,
      brand: compatibilityStore.compatibility.name,
      searchTerm: searchStore.searchText,
      destinationURL: clickLink,
      resultType,
      clickDepth: buildClickDepth(resultType, clickDepth),
      resultCount: getTotalSearchResultsNum(),
      source: SearchSource.SEARCH_RESULTS,
    };
    searchTracking(params);
  };

  const buildClickDepth = (
    resultType: SearchResultType,
    clickDepth: number,
  ) => {
    if (resultType === SearchResult.PROFILE) {
      return `${clickDepth}`;
    }

    if (resultType === SearchResult.IPAAS) {
      return `${profileState.total + clickDepth}`;
    }

    if (resultType === SearchResult.PAGE) {
      return `${profileState.total + ipaasState.total + clickDepth}`;
    }

    return `${clickDepth}`;
  };

  return (
    <React.Fragment>
      <SEO
        title={searchPageTitle()}
        titleSuffix={addSuffix}
        metaDescription={searchPageDesc()}
        canonical={getCanonicalLink()}
      />
      <Sticky position="top" offset={headerHeight}>
        <NavigationBarStyled
          rightContent={
            isMobile ? (
              <div />
            ) : (
              <CompatibilitySelector
                onCompatibilityChanged={onCompatibilityChanged}
              />
            )
          }
        />
      </Sticky>
      <MainContainer data-test-automation-id="searchPage">
        <LeftContent>
          <FilterSideBar />
        </LeftContent>
        {profileState.initialized && ipaasState.initialized && (
          <RightContent>
            <ResultTitle data-test-automation-id="searchResultTitle">
              {totalAppsSearchResultsNum()} results
              {searchStore.searchText && ` for "${searchStore.searchText}"`}
            </ResultTitle>
            <MobileBar>
              <FilterMobileBar />
            </MobileBar>
            <ResultList>
              <InfiniteScroll
                distanceFromBottom={150}
                enabled={
                  profileState.canLoadMore ||
                  pageState.canLoadMore ||
                  ipaasState.canLoadMore
                }
                onScroll={onScroll}
              >
                {profileList.length > 0 && (
                  <ProfileSearchResult
                    data-test-automation-id="profile-search-result"
                    profileList={profileList}
                    handleClick={onSearchClick}
                  />
                )}
                {!profileState.canLoadMore && ipaasList.length > 0 && (
                  <IpaasSearchResult
                    data-test-automation-id="ipaas-search-result"
                    ipaasList={ipaasList}
                    handleClick={onSearchClick}
                    isH1={profileList.length === 0}
                  />
                )}
                {!profileState.canLoadMore &&
                  !ipaasState.canLoadMore &&
                  pageList.length > 0 && (
                    <PageSearchResult
                      data-test-automation-id="page-search-result"
                      collectionList={pageList}
                      handleClick={onSearchClick}
                      isH1={
                        profileList.length === 0 &&
                        (profileState.canLoadMore || ipaasList.length === 0)
                      }
                    />
                  )}
              </InfiniteScroll>
            </ResultList>
            {!profileState.canLoadMore &&
              !ipaasState.canLoadMore &&
              !pageState.canLoadMore && <SuggestApp />}
          </RightContent>
        )}
      </MainContainer>
      <BackToTop
        data-test-automation-id="search-page-back-to-top"
        onClick={trackBackToTop}
      />
    </React.Fragment>
  );
};

export const SearchPage = withLayout(observer(SearchPagePure), {
  stickyHeader: true,
});
