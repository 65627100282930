import { BigBlueButtonInterface } from '@apw/components/profileDetail/profileHeader/requireLogin/IRequireLogin';
import { platformTypeToAppType } from '@apw/core/application.service';
import { googleAnalytics, mixpanel } from '@apw/core/tracker';
import { rootStore } from '@apw/stores';
import { AppDownloadLink, IProfile } from '@apw/types';

export const trackBigBlueButtonClicked = (
  profile: IProfile,
  item: AppDownloadLink | BigBlueButtonInterface,
  loggedInState: boolean,
): void => {
  const brand = rootStore.compatibilitiesStore.getCompatibilityById(
    profile.brand,
  );

  const properties = {
    loggedInState,
    buttonText: item.desc || '',
    buttonStatus: item.buttonState || '',
    appName: profile.applicationName || '',
    profileName: profile.appName || '',
    appId: profile.appId || '',
    appType: platformTypeToAppType(profile.applicationType) || '',
    brandPartner: brand?.name || '',
    orgType: profile.orgType || '',
    orgId: profile.orgId || '',
    appDisplayName: profile.applicationDisplayName || '',
  };

  mixpanel.trackBigBlueButtonClicked(properties);
  googleAnalytics.trackBigBlueButtonClicked(properties);
};
