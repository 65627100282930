import { urlParameterKeys } from '@apw/core/url';
import { storageService } from '@apw/shared/storage/storage.service';
import qs from 'qs';

interface IOptions {
  onStatusChange: () => void;
}

const STORAGE_KEY = 'rcapw-enableHiddenAppGalleries';
const STORAGE_VALUE_ENABLED = String(true);

const initConsoleCommand = (options: IOptions) => {
  const { onStatusChange } = options;

  RCAPW.enableHiddenAppGalleries = (enable = true) => {
    if (enable) {
      storageService.setItem(STORAGE_KEY, STORAGE_VALUE_ENABLED);
    } else {
      storageService.removeItem(STORAGE_KEY);
    }

    onStatusChange();
  };
};

const isEnabled = (): boolean => {
  const search = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  if (search[urlParameterKeys.ENABLE_HIDDEN_APP_GALLERIES] === String(true)) {
    return true;
  }

  return storageService.getItem(STORAGE_KEY) === STORAGE_VALUE_ENABLED;
};

export const hiddenAppGalleriesService = {
  initConsoleCommand,
  isEnabled,
};
