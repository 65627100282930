import React from 'react';
import { AppLogo } from '@apw/components/ipaasTile/IpaasIcon/ipassIcon.sc';
import { IpaasListItem } from '@apw/types';

const IpaasIcon = ({ app, title }: { app: IpaasListItem; title: string }) => {
  return (
    <AppLogo
      className={`${app.squareIconWithSpacing ? 'logoSquareWithSpacing' : ''}`}
      style={app.extraStyle}
      data-test-automation-id={`ipaas-icon-app-logo`}
    >
      <img src={app.logo} alt={`${title} app logo`} />
    </AppLogo>
  );
};

export default IpaasIcon;
