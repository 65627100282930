import React, { FC } from 'react';
import premierPartnerBadge from '@apw/assets/images/premier-partner-badge.svg';
import {
  PremierPartnerContainer,
  PremierPartnerIcon,
  PremierPartnerText,
} from '@apw/components/profileDetail/profileHeader/premierPartner/premierPartner.sc';
import { useTheme } from '@material-ui/core/styles';
import { RcIcon } from '@ringcentral/juno';

export const PremierPartner: FC = () => {
  const theme = useTheme();

  return (
    <PremierPartnerContainer>
      <PremierPartnerIcon>
        <RcIcon
          symbol={premierPartnerBadge}
          size="inherit"
          style={{ color: theme.palette.secondary.main }}
          data-test-automation-id={'premier-partner-icon'}
        />
      </PremierPartnerIcon>
      <PremierPartnerText data-test-automation-id={`premier-partner-text`}>
        PREMIER PARTNER
      </PremierPartnerText>
    </PremierPartnerContainer>
  );
};
