import { useProximaNovaRegular } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const Header = styled.div`
  height: 48px;
  background: rgb(255, 255, 255);
  padding-left: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px #cbcbcb solid;
`;

export const HeaderTitle = styled.span`
  color: rgb(104, 104, 105);
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 0.25px;
  ${useProximaNovaRegular}
`;
