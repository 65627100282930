import React, { useEffect, useState } from 'react';
import { useIsMobileDevice } from '@apw/hooks/deviceWidth/useIsMobileDevice';
import { globalScrollBlock } from '@apw/shared';
import { useStores } from '@apw/stores';
import { ListView } from '@ringcentral/juno-icon';
import { observer } from 'mobx-react';
import { LoginStatus } from '../loginStatus';
import {
  BrandDeveloperWidget,
  Container,
  MobileHeaderContent,
  MobileHeaderWrapper,
  MobileMenuWidget,
  SignInButton,
  TriggerIcon,
} from './BrandDeveloper.sc';

export const BrandDeveloperPure = () => {
  const { userStore } = useStores();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobilePopoverEnter, setMobilePopoverEnter] = useState(false);

  const signIn = () => {
    userStore.signInViaCLW();
  };

  const isMobile = useIsMobileDevice();

  useEffect(() => {
    if (isMobile && mobileMenuOpen) {
      globalScrollBlock(true);
    } else {
      globalScrollBlock(false);
    }
  }, [isMobile, mobileMenuOpen]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <Container>
      <BrandDeveloperWidget>
        {userStore.isLoggedIn && <LoginStatus />}
        {!userStore.isLoggedIn && (
          <SignInButton
            onClick={signIn}
            color="text.primary"
            size="medium"
            variant="outlined"
            data-test-automation-id="signInButton"
          >
            Sign in to your App Gallery
          </SignInButton>
        )}
      </BrandDeveloperWidget>

      <MobileMenuWidget>
        <TriggerIcon
          onClick={toggleMobileMenu}
          symbol={ListView}
          color={['text', 'primary']}
          role="button"
          tabIndex={0}
          aria-label="toggle dropdown"
          aria-expanded={mobileMenuOpen}
          data-test-automation-id="mobile-header-trigger-icon"
        />
        <MobileHeaderWrapper
          open={mobileMenuOpen}
          className={mobilePopoverEnter ? 'open' : ''}
          onEnter={() => setMobilePopoverEnter(true)}
          onExit={() => setMobilePopoverEnter(false)}
          transitionDuration={{
            appear: 400,
            enter: 400,
            exit: 400,
          }}
          TransitionProps={{
            appear: true,
          }}
        >
          <MobileHeaderContent
            toggleMenu={toggleMobileMenu}
            menuOpen={mobileMenuOpen}
          />
        </MobileHeaderWrapper>
      </MobileMenuWidget>
    </Container>
  );
};

export const BrandDeveloper = observer(BrandDeveloperPure);
