export const allowUrlPrefix = [
  '://downloads.ringcentral.com/glip/rc/GlipForWindows64',
  '://downloads.ringcentral.com/glip/rc/GlipForMac',
  '://downloads.ringcentral.com/sp/bt/BTCloudPhoneForMac',
  '://downloads.ringcentral.com/sp/bt/BTCloudPhoneForWindows',
  '://downloads.businessconnect.telus.com/sp/telus/TelusBusinessConnectForMac',
  '://downloads.businessconnect.telus.com/sp/telus/TelusBusinessConnectForWindows',
  '://downloads.ringcentral.com/sp/att/RingCentralForWindows',
  '://downloads.ringcentral.com/sp/att/RingCentralForMac',
  '://downloads.ringcentral.com/sp/RingCentralForWindows',
  '://downloads.ringcentral.com/sp/RingCentralForMac',
];
export const allowExtends = ['exe', 'pkg', 'zip', 'msi', 'dmg'];
