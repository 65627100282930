import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  RcDialogContent,
  RcDialogTitle,
  RcDialogActions,
  RcButton,
  RcLoading,
} from '@ringcentral/juno';
import { observer } from 'mobx-react';
import { StoreContext } from '@apw/lib/components/profileDetail/StoreContext';
import { Popup } from '@apw/lib/components/ratingAndReview/popup/ratingPopup.sc';
import { RatingPopupStore } from '@apw/lib/components/ratingAndReview/popup/ratingPopup.store';
import { RatingStep } from '@apw/lib/components/ratingAndReview/popup/ratingStep/ratingStep';
import { ReviewStep } from '@apw/lib/components/ratingAndReview/popup/reviewStep/reviewStep';

type Step = 'Rating' | 'Review';

interface RatingPopupProps {
  onSave: Function;
}

const RatingPopupPure = ({ onSave }: RatingPopupProps) => {
  const detailStore = useContext(StoreContext);
  const { translation, ratingPopupLoading } = detailStore;
  const name =
    detailStore.profile?.applicationDisplayName ||
    detailStore.profile?.appName ||
    '';

  const [step, setStep] = useState<Step>('Rating');
  const isRatingStep = step === 'Rating';
  const isEdit = !!detailStore.myReviewItem;
  const title = isEdit
    ? translation.ratingsAndReviews.popup.editMode.title
    : translation.ratingsAndReviews.popup.createMode.title(name);

  const submitBtnName = isEdit ? translation.update : translation.submit;

  const store = useMemo(() => {
    return isEdit
      ? new RatingPopupStore(detailStore.myReviewItem!)
      : new RatingPopupStore();
  }, [isEdit]);

  useEffect(() => {
    if (detailStore.myReviewLoader) {
      detailStore.myReviewLoader(true);
    }
  }, []);

  return (
    <Popup
      open
      data-test-automation-id="rating-and-review-popup"
      onClose={detailStore.closeRatingPopup}
    >
      <RcLoading loading={ratingPopupLoading}>
        <RcDialogTitle data-test-automation-id="rating-popup-title">
          {title}
        </RcDialogTitle>
        <RcDialogContent>
          {isRatingStep ? (
            <RatingStep store={store} />
          ) : (
            <ReviewStep store={store} />
          )}
        </RcDialogContent>
        <RcDialogActions>
          {isRatingStep ? (
            <>
              <RcButton
                variant="text"
                onClick={detailStore.closeRatingPopup}
                data-test-automation-id="rating-popup-cancel"
              >
                {translation.cancel}
              </RcButton>
              <RcButton
                onClick={() => setStep('Review')}
                disabled={!store.valid}
                data-test-automation-id="rating-popup-continue"
              >
                {translation.continue}
              </RcButton>
            </>
          ) : (
            <>
              <RcButton
                variant="text"
                onClick={() => setStep('Rating')}
                data-test-automation-id="rating-popup-back"
              >
                {translation.back}
              </RcButton>
              <RcButton
                onClick={() => onSave(store, isEdit)}
                disabled={!store.dirty}
                data-test-automation-id="rating-popup-save"
              >
                {submitBtnName}
              </RcButton>
            </>
          )}
        </RcDialogActions>
      </RcLoading>
    </Popup>
  );
};

export const RatingPopup = observer(RatingPopupPure);
