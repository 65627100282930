import React from 'react';
import { MoreServiceProviders } from '@apw/components/profileDetail/profileBody/overviewTab/partnerCompatibility/moreServiceProviders';
import { RcLink } from '@ringcentral/juno';
import { useHistory } from 'react-router-dom';
import { Item, Wrapper } from './partnerCompatibility.sc';

export const PartnerCompatibility = ({ className = '', partnerProfiles }) => {
  const history = useHistory();

  if (!partnerProfiles || !partnerProfiles.length) {
    return null;
  }

  return (
    <Wrapper className={className}>
      <h5>ALSO AVAILABLE FOR</h5>
      <ul>
        {(partnerProfiles || []).map((item) => {
          return (
            <Item key={item.key}>
              <RcLink
                onClick={() => history.push(item.profileUrl)}
                color="secondary"
                tabIndex={0}
                role={`link`}
                data-test-automation-id={`partner-compatibility-link-${item.brandName}`}
              >
                {item.brandName}
              </RcLink>
            </Item>
          );
        })}
      </ul>
      <MoreServiceProviders />
    </Wrapper>
  );
};
