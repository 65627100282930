import { RcThemeInput } from '@ringcentral/juno';

type Palette = RcThemeInput['palette'];

export const paletteFactory = (
  primaryColor: string,
  secondaryColor = primaryColor,
): Palette => {
  return {
    interactive: {
      f01: primaryColor,
      b02: primaryColor,
    },
    informative: {
      f02: primaryColor,
    },
    bg: {
      primary: primaryColor,
      secondary: secondaryColor,
    },
    text: {
      button: primaryColor,
      info: primaryColor,
      primary: primaryColor,
      secondary: secondaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    border: {
      primary: primaryColor,
    },
    icon: {
      primary: primaryColor,
    },
    action: {
      primary: primaryColor,
    },
  } as any;
};
