import { BLACK, FORM_LABEL_COLOR } from '@apw/shared/theme/utils/color';
import { RcThemeInput } from '@ringcentral/juno';

type Overrides = RcThemeInput['overrides'];

export const overridesFactory = (primaryColor: string): Overrides => ({
  MuiDialog: {
    paper: {
      color: `${BLACK} !important`,
    },
  },
  MuiInput: {
    underline: {
      '&.Mui-focused:after': {
        borderBottomColor: `${primaryColor} !important`,
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottomColor: `${primaryColor} !important`,
      },
    },
  },
  MuiFormLabel: {
    root: {
      color: FORM_LABEL_COLOR,
    },
  },
  MuiInputBase: {
    root: {
      color: BLACK,
    },
  },
});
