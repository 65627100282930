import * as Cookies from 'js-cookie';

interface StorageInterface {
  length: number;
  key: (index: number) => string | null;
  getItem: (key: string) => string | null;
  setItem: (key: string, value: any, setAsCookieAnyway: boolean) => void;
  removeItem: (key: string) => void;
}

class StorageService {
  private readonly storage: StorageInterface = {} as StorageInterface;

  constructor() {
    if (!this.isLocalStorageSupported()) {
      Object.defineProperty(this.storage, 'length', {
        get: () => Object.keys(Cookies.get()).length,
      });

      this.storage.key = (index: number): string | null =>
        Object.keys(Cookies.get())[index] || null;

      this.storage.getItem = (key: string): string | null =>
        Cookies.get(key) || null;

      this.storage.setItem = (
        key: string,
        value: any,
        setAsCookieAnyway = true,
      ): void => {
        if (setAsCookieAnyway) {
          Cookies.set(key, value, { expires: 365, path: '/' });
        }
      };

      this.storage.removeItem = (key: string): void => Cookies.remove(key);
    } else {
      this.storage = window.localStorage;
    }
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  setItem(key: string, value: any, setAsCookieAnyway = true): void {
    this.storage.setItem(key, value, setAsCookieAnyway);
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  private isLocalStorageSupported(): boolean {
    const testKey = 'rcapw-isLocalStorageSupported';
    const storage = window.localStorage;

    try {
      storage.setItem(testKey, '1');
      storage.removeItem(testKey);
      return true;
    } catch (error) {
      return false;
    }
  }
}

export const storageService = new StorageService();
