import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react';
import { FeatureBlock } from '@apw/lib/components/pageContent/featureBlock';
import { firstH1BlockIndex } from '@apw/lib/components/pageContent/shared/pageContent.service';
import { BlockType, IContentBlocks } from '@apw/lib/types';
import { CollectionBlock } from './collectionBlock';
import { HeaderBlock } from './headerBlock/HeaderBlock';
import { HtmlBlock } from './htmlBlock/HtmlBlock';
import styles from './pageContent.module.scss';

interface PageContentProps {
  contentBlocks: IContentBlocks;
}

const Component: FC<PageContentProps> = ({ contentBlocks }) => {
  const map = {
    [BlockType.Header]: HeaderBlock,
    [BlockType.Feature]: FeatureBlock,
    [BlockType.Collection]: CollectionBlock,
    [BlockType.Html]: HtmlBlock,
  };

  const firstBlockIndex = useMemo(
    () => firstH1BlockIndex(contentBlocks),
    [contentBlocks],
  );

  return (
    <div className={styles.wrapper} data-test-automation-id="page-content-view">
      {contentBlocks.map((block, index) => {
        const BlockTag = map[block.blockType];

        return (
          <BlockTag
            // eslint-disable-next-line react/no-array-index-key
            key={index.toString()}
            // @ts-ignore
            data={block}
            isFirst={index === firstBlockIndex}
            blockIndex={index}
          />
        );
      })}
    </div>
  );
};

export const PageContentView = observer(Component);
