import { useState } from 'react';

export const useDerivedState = <T>(prop: T): [T, (x: T) => void] => {
  const [preProp, setPreProp] = useState<T>();
  const [state, setState] = useState<T>();

  if (preProp !== prop) {
    setPreProp(prop);
    setState(prop);
  }

  return [state!, setState];
};
