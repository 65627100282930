import { useEffect, useState } from 'react';
import { throttle } from 'lodash';

export const useInnerWidth = (wait = 200) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handle = throttle((e) => {
      setWidth(e.target.innerWidth);
    }, wait);

    window.addEventListener('resize', handle);
    return () => {
      window.removeEventListener('resize', handle);
    };
  }, []);
  return width;
};
