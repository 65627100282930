import { Popover } from '@apw/core/popover';
import {
  onMobile,
  PAGE_MOBILE_PADDING,
  useProximaNovaSemibold,
} from '@apw/core/sc';
import {
  palette2,
  RcIcon,
  RcListItem,
  RcListItemText,
  styled,
} from '@ringcentral/juno';

export const MenuTrigger = styled(RcListItem)`
  padding: 0 ${PAGE_MOBILE_PADDING}px !important;
  height: 56px !important;
`;

export const MenuTriggerText = styled(RcListItemText)`
  span {
    color: ${palette2('text', 'primary')};
    ${useProximaNovaSemibold};
    font-size: 18px !important;
  }
`;

export const MenuTriggerIcon = styled(RcIcon)`
  svg {
    font-size: 28px;
  }
`;

export const PopoverContainer = styled(Popover)`
  [class~='MuiPopover-paper'] {
    margin-top: 1px;
    ${onMobile} {
      margin-top: 0;
      box-shadow: none !important;
    }
  }
`;

export const ContentWrap = styled.div`
  padding: 32px ${PAGE_MOBILE_PADDING}px;
`;
