import { BPS, PAGE_MOBILE_PADDING, respondTo, onMobile } from '@apw/core/sc';
import { css, styled } from '@ringcentral/juno';

export const MenuContainer = styled.div`
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid #cbcbcb;

  ${onMobile} {
    height: 56px;
    margin: 0 -${PAGE_MOBILE_PADDING}px;
  }
`;

export const Desktop = styled(MenuContainer)`
  ${onMobile} {
    display: none;
  }
`;

export const Mobile = styled(MenuContainer)`
  display: none;
  ${onMobile} {
    display: block;
  }
`;

export const primaryNavigationMenuItem = css`
  margin-left: 40px !important;

  ${respondTo([995, 1064])} {
    margin-left: 32px !important;
  }

  ${respondTo(BPS.ltTablet)} {
    margin-left: 25px !important;
  }

  &:first-child {
    margin-left: 0 !important;
  }

  &:last-child {
    margin-right: 16px !important;
  }
`;
