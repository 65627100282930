export const companyTypes = [
  'Education',
  'Energy + Utilities',
  'Entertainment and Media',
  'Financial Services',
  'Government',
  'Healthcare',
  'High Tech',
  'Hospitality',
  'Manufacturing',
  'Non-Profit',
  'Professional Services',
  'Real Estate',
  'Retail',
  'Telecommunications',
  'Transportation',
  'Wholesale/Distribution',
  'Other',
];
