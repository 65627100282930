import React from 'react';
import { Container } from '@apw/components/profileDetail/profileBody/ratingsAndReviewsTab/ratingsSection/header/header.sc';
import { ActionButtons } from '@apw/components/ratingAndReview/actionButtons/ActionButtons';
import { breakpoints } from '@apw/shared/theme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { RcTypography } from '@ringcentral/juno';

export const Header = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  return (
    <Container>
      <RcTypography
        variant="headline2"
        color="neutral.f06"
        data-test-automation-id={'ratings-section-title'}
      >
        Ratings and reviews
      </RcTypography>
      {!isMobile && <ActionButtons />}
    </Container>
  );
};
