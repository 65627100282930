import { IProcessedProfile } from '@apw/components/profileDetail';
import { googleAnalytics, mixpanel } from '@apw/core';
import { platformTypeToAppType } from '@apw/core/application.service';
import { rootStore } from '@apw/stores';

export const trackContactMeClick = (profile: IProcessedProfile) => {
  const brand = rootStore.compatibilitiesStore.getCompatibilityById(
    profile.brand,
  );

  const properties = {
    appId: profile.appId || '',
    profileId: profile._id,
    profileName: profile.appName,
    brandPartner: brand?.name || '',
    appName: profile.applicationName || '',
    appDisplayName: profile.applicationDisplayName || '',
    appType: platformTypeToAppType(profile.applicationType) || '',
  };

  mixpanel.trackContactUsPrompt(properties);
  googleAnalytics.trackContactUsPrompt(properties);
};
