import React, { FC } from 'react';
import { Tooltip } from '@apw/components';
import {
  Container,
  Label,
} from '@apw/components/ratingAndReview/qualityRating/ratingItemLabel/ratingItemLabel.sc';
import { RcTypographyProps } from '@ringcentral/juno';

export interface IRatingItemLabelProps {
  text: string;
  tooltip: string;
  labelProps: RcTypographyProps;
  className?: string;
}

export const RatingItemLabel: FC<IRatingItemLabelProps> = ({
  text,
  tooltip,
  labelProps,
  className,
}) => {
  return (
    <Container className={className}>
      {/* @ts-ignore */}
      <Label
        {...labelProps}
        data-test-automation-id={'quality-rating-item-label'}
        tabIndex={0}
      >
        {text}
      </Label>
      <Tooltip
        automationId={'quality-rating-item-tooltip'}
        title={tooltip}
        tabIndex={0}
      />
    </Container>
  );
};
