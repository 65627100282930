import { osStyles } from '@apw/components/profileDetail/profileHeader/ctaButton/appDownloadButton/platformOption/platformIcons.sc';
import { RcIcon, styled } from '@ringcentral/juno';

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-size: 16px !important;
  justify-content: flex-start;
`;

export const MainContent = styled.div`
  display: flex;
  align-items: center;
`;

export const PlatformIcon = styled(RcIcon)`
  flex-shrink: 0;
  justify-content: center;
  margin-left: 3px;
  margin-right: 0 !important;
  width: 20px;
  ${osStyles}
`;

export const ExternalLinkIcon = styled(RcIcon)`
  margin-left: 12px;
`;
