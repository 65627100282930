import React, { FC, useEffect } from 'react';
import { useQueryParams } from '@apw/hooks';
import { pick } from 'lodash';
import * as qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';

export const withQueryParamCleaner = (
  Component: FC,
  allowedQueryParamKeys: string[],
): FC => {
  return (props) => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = useQueryParams();

    useEffect(() => {
      const pickedQueryParams = pick(queryParams, allowedQueryParamKeys);

      const queryString = qs.stringify(pickedQueryParams, {
        addQueryPrefix: true,
      });

      history.replace(`${location.pathname}${queryString}`);
    }, []);

    return <Component {...props} />;
  };
};
