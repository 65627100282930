import React, { useEffect } from 'react';
import { useAPIErrorHandling } from '@apw/core/api/useAPIErrorHandling';
import { urlParameterKeys } from '@apw/core/url';
import { updateParams } from '@apw/core/url/url.service';
import { useLocationRecorder } from '@apw/hooks';
import { useCommonError } from '@apw/hooks/useCommonError';
import { CommonRoute, GuardedRoute } from '@apw/routes/GuardedRoute';
import { IRoute } from '@apw/routes/route.interface';
import { useStores } from '@apw/stores';
import * as qs from 'qs';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  getCompatibilityProfileRoutes,
  getCompatibilityRoutes,
} from './getCompatibilityRoutes';
import { getRouteSettings } from './routeSettings';

export const AppRoutes = () => {
  const { compatibilitiesStore } = useStores();
  const routeSettings = getRouteSettings();

  const routes = [
    routeSettings.homePage,
    routeSettings.searchPage,
    ...getCompatibilityRoutes(compatibilitiesStore.mapping),
    routeSettings.profilePreviewPage,
    routeSettings.appInstallPage,
    routeSettings.profileNotAvailableForPartner,
    ...getCompatibilityProfileRoutes(compatibilitiesStore.mapping),
  ];

  const commonError = useCommonError();
  const history = useHistory();
  const location = useLocation();

  useAPIErrorHandling();
  useLocationRecorder();

  useEffect(() => {
    const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const loginError = searchParams[urlParameterKeys.LOGIN_ERROR];

    if (loginError) {
      commonError.show();
      const search = updateParams(window.location.search, {
        [urlParameterKeys.LOGIN_ERROR]: undefined,
      });
      history.replace(location.pathname + search);
    }
  }, []);

  useEffect(() => {
    window.RCAPW.navigate = (route) => {
      history.push(route);
    };

    return () => {
      window.RCAPW.navigate = () => {};
    };
  });

  return (
    <Switch>
      {routes.map((route: IRoute) => {
        const TargetRoute = route.data?.auth ? GuardedRoute : CommonRoute;
        return <TargetRoute key={`route-${route.path}`} {...route} />;
      })}
      <Route path="*">
        <Redirect to={routeSettings.homePage.path as string} />
      </Route>
    </Switch>
  );
};
