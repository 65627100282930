import React, { FC, useCallback, useState } from 'react';
import { ApiError, googleAnalytics, mixpanel, transport } from '@apw/core';
import { loadTrackProfile } from '@apw/modules/botProvision';
import { useBotManagement } from '@apw/modules/botProvision/shared/useBotManagement';
import { BotItemStatus, IBotItem } from '@apw/modules/myApps/botList/typings';
import { useStores } from '@apw/stores';
import { includes } from 'lodash';
import { BotActionBtn, BotItemAction } from './BotAction.sc';

interface BotActionProps {
  item: IBotItem;
  onChange: () => void;
}

export const BotAction: FC<BotActionProps> = ({ item, onChange }) => {
  const [itemStatus, setItemStatus] = useState(item.status);
  const { compatibilitiesStore, compatibilityStore, myAppsStore } = useStores();

  const userAbleToRemove = item.ableToRemove;
  const botCanBeRemoved = () => {
    return itemStatus === BotItemStatus.REMOVABLE;
  };

  const botManagement = useBotManagement();
  const installBot = useCallback(
    (appId: string, event: React.MouseEvent) => {
      event.stopPropagation();

      googleAnalytics.trackPageView('InstalledBotsList:Reinstall');
      return botManagement.installBot({ appId });
    },
    [item],
  );

  const trackRemoveBot = async (appId: string) => {
    const brandId = compatibilityStore.brandId;
    const profile = await loadTrackProfile(brandId, appId);
    const compatibility = compatibilitiesStore.getCompatibilityById(
      profile.brand,
    );
    const brandName = compatibility ? compatibility.name : '';
    mixpanel.trackRemoveBot(profile, brandName);
  };

  const isProfileExist = async () => {
    if (!item.appId) {
      return false;
    }

    const brandId = compatibilityStore.brandId;

    try {
      const profile = await transport.getProfileByAppIdAndBrandId(
        item.appId,
        brandId,
      );

      return !!profile;
    } catch {
      return false;
    }
  };

  const removeBot = useCallback(
    (item: IBotItem, event: React.MouseEvent) => {
      event.stopPropagation();

      googleAnalytics.trackPageView('InstalledBotsList:Remove');

      botManagement
        .removeBot({
          botClientId: item.botClientId,
          botName: item.name,
        })
        .then((result) => {
          if (!result) {
            return;
          }

          trackRemoveBot(item.appId);

          isProfileExist().then((isExist) => {
            if (isExist) {
              item.status = BotItemStatus.INSTALLABLE;
              setItemStatus(item.status);
              return;
            }

            myAppsStore.removeBotItem(item.id);
          });
        })
        .catch((error: string) => {
          if (
            !includes(
              [
                ApiError.NOT_LOGGED_IN,
                ApiError.REQUEST_RATE_EXCEEDED,
                ApiError.SESSIONS_NUMBER_EXCEEDED,
                ApiError.ACCESS_TOKEN_CORRUPTED,
              ],
              error,
            )
          ) {
            onChange();
          }
        });
    },
    [item],
  );

  const keydownAction = (e) => {
    e.stopPropagation();
  };

  return (
    <BotItemAction>
      {userAbleToRemove && botCanBeRemoved() && (
        <BotActionBtn
          color="text.primary"
          variant="outlined"
          onClick={(e) => removeBot(item, e)}
          onKeyDown={(e) => keydownAction(e)}
          data-test-automation-id="installed-bot-remove-action"
        >
          Remove
        </BotActionBtn>
      )}
      {itemStatus === BotItemStatus.INSTALLABLE && (
        <BotActionBtn
          color="text.primary"
          variant="outlined"
          onClick={(e) => installBot(item.appId, e)}
          onKeyDown={(e) => keydownAction(e)}
          data-test-automation-id="installed-bot-install-action"
        >
          Install Bot
        </BotActionBtn>
      )}
    </BotItemAction>
  );
};
