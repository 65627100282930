import React, { FC, useEffect, useRef, useState } from 'react';
import { Sticky, StickyRef } from '@apw/components';
import { ProfileViewModel } from '@apw/components/profileDetail/Profile.ViewModel';
import { ProfileBody } from '@apw/components/profileDetail/profileBody/ProfileBody';
import { trackBigBlueButtonClicked } from '@apw/components/profileDetail/profileHeader/ctaButton/shared/ctaButtonService';
import { ProfileHeader } from '@apw/components/profileDetail/profileHeader/ProfileHeader';
import { BigBlueButtonInterface } from '@apw/components/profileDetail/profileHeader/requireLogin/IRequireLogin';
import { onGetFreeTrial } from '@apw/components/profileDetail/profileHeader/requireLogin/requireLogin.service';
import { IProcessedProfile } from '@apw/components/profileDetail/typings';
import { SEO } from '@apw/components/seo/seo';
import { platformTypeToAppType } from '@apw/core/application.service';
import { Language, languageService } from '@apw/core/language';
import { googleAnalytics, mixpanel } from '@apw/core/tracker';
import { PageViewTypeEnum } from '@apw/core/tracker/types';
import { useQueryParams, useRedirectionRules } from '@apw/hooks';
import { useCommonError } from '@apw/hooks/useCommonError';
import usePartnerHandlebars from '@apw/hooks/usePartnerHandlebars';
import { useVM } from '@apw/hooks/useVM';
import { DownloadGate } from '@apw/modules/profile/downloadGate';
import { Lead, trackContactMeClick } from '@apw/modules/profile/lead';
import {
  buildHandlebarsProfile,
  buildSeoElementForApp,
  getAppPageTitle,
  getCanonicalLink,
  getOgTagData,
  getTwitterTagData,
  ProfileLoadingError,
  resolveProfile,
} from '@apw/modules/profile/profile.service';
import {
  redirectionRulesFactory,
  RedirectionRulesFactoryData,
} from '@apw/modules/profile/redirectionRules.factory';
import { RequireLoginOption } from '@apw/modules/profile/requireLoginOption';
import { EventTypes } from '@apw/modules/profile/shared/eventTypes';
import { BrokenLinkIndicator, NavigationBar, withLayout } from '@apw/shared';
import { rootStore, useStores } from '@apw/stores';
import { AppDownloadLink } from '@apw/types';
import { BigBlueButtonStateEnum } from '@apw/types/bigBlueButtonState.enum';
import { observer } from 'mobx-react';
import { useLocation, useParams } from 'react-router-dom';
import { Subscription } from 'rxjs';
import { GoBackButton } from './goBackButton';
import {
  ProfilePageWrapper,
  ProfileWrapper,
  profileNavigationBarStyles,
} from './ProfilePage.sc';
import { handleSupportAvailableTime } from './shared/profileProcessor';

const ProfilePagePure: FC<{
  headerHeight?: number;
  isAppGalleryWarningVisible?: boolean;
}> = ({ headerHeight, isAppGalleryWarningVisible }) => {
  const { userStore, compatibilitiesStore } = useStores();
  const viewModel = useVM(ProfileViewModel);
  const { profile, setProfile } = viewModel;

  const { vanityUrl } = useParams<{ vanityUrl: string }>();
  const { compatibilityStore } = useStores();
  const commonError = useCommonError();
  const [eventType, setEventType] = useState<
    EventTypes.Download | EventTypes.Support | EventTypes.LoginOption | null
  >(null);
  const [downloadUrl, setDownloadUrl] = useState('');
  const [seoElementForApp, setSeoElementForApp] = useState<string>('');
  const queryParams = useQueryParams();
  const { pathname } = useLocation();
  const handlebars = usePartnerHandlebars();
  const stickyNavBarRef = useRef<StickyRef | null>();

  const redirectionProcess = useRedirectionRules<RedirectionRulesFactoryData>(
    redirectionRulesFactory,
  );

  useEffect(() => {
    redirectionProcess.kickStart({
      vanityUrl,
      queryParams,
      currentCompatibility: compatibilityStore.compatibility,
    });

    return () => {};
  }, []);

  const closeEventPopup = () => {
    setEventType(null);
  };

  const trackPageView = (profile: IProcessedProfile) => {
    const compatibility = compatibilitiesStore.getCompatibilityById(
      profile.brand,
    );
    const brandName = compatibility ? compatibility.name : '';
    const moreInfo = {
      'Page Type': PageViewTypeEnum.PROFILE,
      'App ID': profile.appId || '',
      'App Name': profile.applicationName || '',
      'App Display Name': profile.applicationDisplayName || '',
      'Profile Name': profile.appName || '',
      'App Type': platformTypeToAppType(profile.applicationType) || '',
      Brand: brandName,
    };
    googleAnalytics.trackPageView(`GalleryItem:${profile.appName}`);
    mixpanel.trackPageView(profile.appName, moreInfo);
  };

  const onDownload = (item: AppDownloadLink) => {
    if (!profile || !item.link) {
      return;
    }

    trackBigBlueButtonClicked(profile, item, userStore.isLoggedIn);

    setEventType(EventTypes.Download);
    setDownloadUrl(item.link);
  };

  const onSupport = () => {
    if (profile) {
      trackContactMeClick(profile);
    }
    setEventType(EventTypes.Support);
  };

  const onRequireLoginGate = () => {
    if (profile) {
      const loginButton: BigBlueButtonInterface = {
        desc: 'Sign in to proceed',
        buttonState: BigBlueButtonStateEnum.SECONDARY,
      };
      trackBigBlueButtonClicked(profile, loginButton, userStore.isLoggedIn);
    }
    setEventType(EventTypes.LoginOption);
  };

  const updateProfileWithAvailableTime = (profile: IProcessedProfile) => {
    handleSupportAvailableTime(profile).then((updatedProfile) => {
      setProfile(updatedProfile);
    });
  };

  useEffect(() => {
    let buildSub: Subscription;
    if (profile) {
      buildSub = buildSeoElementForApp(profile).subscribe((res) => {
        setSeoElementForApp(res);
      });
    }

    return () => {
      if (buildSub) {
        buildSub.unsubscribe();
      }
    };
  }, [profile]);

  useEffect(() => {
    let isMounted = true;
    const compatibility = rootStore.compatibilitiesStore.resolve(pathname);

    resolveProfile(vanityUrl, compatibility.id)
      .then((profile) => {
        if (isMounted) {
          setProfile(profile);
          updateProfileWithAvailableTime(profile);
          window.__APW_SSR_READY__ = true;
          window.scroll(0, 0);
          return profile;
        }
        return null;
      })
      .then((profile) => {
        if (isMounted && profile) {
          const handlebarsProfile = buildHandlebarsProfile(profile, handlebars);
          setProfile(handlebarsProfile);
          trackPageView(handlebarsProfile);
        }
      })
      .catch((err: ProfileLoadingError | any) => {
        if (!isMounted) {
          return;
        }

        if (err === ProfileLoadingError.PROFILE_NOT_FOUND) {
          setProfile(null);
          googleAnalytics.trackPageView(`GalleryItem:AppNotFound`);
          mixpanel.trackPageView('Not found');
          return;
        }

        commonError.show();
        setProfile(undefined);
      });

    return () => {
      isMounted = false;
    };
  }, [pathname]);

  useEffect(() => {
    stickyNavBarRef.current?.recalculate();
  }, [isAppGalleryWarningVisible]);

  return (
    <ProfilePageWrapper data-test-automation-id="profilePage">
      {profile && (
        <React.Fragment>
          <SEO
            title={getAppPageTitle(profile)}
            metaDescription={profile.shortDes}
            ogTag={getOgTagData(profile)}
            twitterTag={getTwitterTagData(profile)}
            linkedData={seoElementForApp}
            canonical={getCanonicalLink(profile)}
          />
          <Sticky
            position="top"
            offset={headerHeight}
            ref={(ref) => (stickyNavBarRef.current = ref)}
          >
            <NavigationBar
              leftContent={<GoBackButton />}
              cssProp={profileNavigationBarStyles}
            />
          </Sticky>
          <ProfileWrapper id="profile-content">
            <ProfileHeader
              viewModel={viewModel}
              onDownload={onDownload}
              onSupport={onSupport}
              onRequireLoginGate={onRequireLoginGate}
              isPreviewMode={false}
              data-test-automation-id="profile-header"
            />
            <ProfileBody
              data-test-automation-id="profile-body"
              viewModel={viewModel}
              isPreviewMode={false}
            />
            {eventType === EventTypes.Download && (
              <DownloadGate
                data-test-automation-id="profile-download-gate"
                profile={profile}
                downloadUrl={downloadUrl}
                onAfterSignIn={() => setEventType(EventTypes.Download)}
                onClose={closeEventPopup}
              />
            )}
            {eventType === EventTypes.LoginOption && (
              <RequireLoginOption
                data-test-automation-id="profile-require-login-option"
                title={languageService.get(
                  Language.GUEST_FORM_LOGIN_OPTION_TITLE,
                )}
                onClose={closeEventPopup}
                onAfterSignIn={() => {
                  userStore.loadUser().then(() => {
                    closeEventPopup();
                  });
                }}
                onGetFreeTrial={() =>
                  onGetFreeTrial(profile.appName, 'Start your free trial')
                }
              />
            )}
            {eventType === EventTypes.Support && (
              <Lead
                data-test-automation-id="profile-require-lead"
                profile={profile}
                onAfterSignIn={() => {
                  closeEventPopup();
                  userStore.loadUser().then(() => {
                    setEventType(EventTypes.Support);
                  });
                }}
                onClose={closeEventPopup}
              />
            )}
          </ProfileWrapper>
        </React.Fragment>
      )}
      {profile === null && (
        <BrokenLinkIndicator data-test-automation-id="profile-not-exist-msg">
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
          <span role="alert" tabIndex={0}>
            This app does not exist or has been removed.
          </span>
        </BrokenLinkIndicator>
      )}
    </ProfilePageWrapper>
  );
};

export const ProfilePage = withLayout(observer(ProfilePagePure), {
  stickyHeader: true,
});
