import { RcIcon, styled } from '@ringcentral/juno';

export const ProfileLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: 100%;
`;

export const DefaultProfileLogo = styled(RcIcon)`
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
`;
