import React, { FC } from 'react';
import writeNotes from '@apw/assets/images/write_notes.svg';
import { RcTypography } from '@ringcentral/juno';
import { RcTypographyVariant } from '@ringcentral/juno/components/Typography/Typography';
import { Container, Image } from './noReviewsSection.sc';

interface INoReviewsSectionProps {
  variant?: RcTypographyVariant;
  className?: string;
}

export const NoReviewsSection: FC<INoReviewsSectionProps> = ({
  variant = 'subheading1',
  className = '',
}) => {
  return (
    <Container className={className}>
      <Image data-test-automation-id="no-reviews-img" symbol={writeNotes} />
      <RcTypography
        color="neutral.f06"
        variant={variant}
        data-test-automation-id="no-reviews-msg"
        tabIndex={0}
      >
        No reviews yet
      </RcTypography>
    </Container>
  );
};
