import { transport } from '@apw/core';
import { urlParameterKeys } from '@apw/core/url';
import {
  allCategories,
  allProductsItem,
  productConfig,
  supportedCategoryConfig,
} from '@apw/modules/search/filterBar';
import { buildParamAppsArray } from '@apw/modules/search/filterBar/multipleAppsFilter';
import { supportedPartnerBadgeConfig } from '@apw/modules/search/filterBar/partnerBadgeFilter';
import { IPageInSearch, IProfileInSearch } from '@apw/shared/searchProvider';
import { RootStore } from '@apw/stores';
import { PagingInterface } from '@apw/types';
import { ICategory } from '@apw/types/category';
import type { IProductId } from '@apw/types/product';
import * as _ from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import * as qs from 'qs';

const EMPTY_SEARCH_TEXT = '';

type IFirstScreenCacheConfig<T> = {
  items: T[];
  paging: PagingInterface;
};

type IFirstScreenProfileCacheConfig = IFirstScreenCacheConfig<IProfileInSearch>;
type IFirstScreenPageCacheConfig = IFirstScreenCacheConfig<IPageInSearch>;

type IFirstScreenCache = {
  profile: IFirstScreenProfileCacheConfig | null;
  page: IFirstScreenPageCacheConfig | null;
};

export class SearchStore {
  rootStore: RootStore | undefined;

  @observable
  searchText: string = EMPTY_SEARCH_TEXT;

  @observable
  selectedProductId: IProductId = allProductsItem.id;

  @observable
  selectedCategory: string = allCategories.id;

  @observable
  selectedPartnerBadge: string[] | null = null;

  @observable
  specifiedVanityUrls: string | null = null;

  @observable
  categories: ICategory[] = [];

  firstScreenCache: IFirstScreenCache = {
    profile: null,
    page: null,
  };

  constructor(rootStore?: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  resolve = (search: string) => {
    const searchParams = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    this.resolveSearchTextFromUrl(searchParams);
    this.resolveProductIdFromUrl(searchParams);
    this.resolveCategoryIdFromUrl(searchParams);
    this.resolvePartnerBadgeIdFromUrl(searchParams);
    this.resolveMultipleAppsFromUrl(searchParams);
  };

  @action
  reset = () => {
    // Reset the Key State in Search Store
    this.setSearchText(EMPTY_SEARCH_TEXT);
    this.setSelectedProductId(allProductsItem.id);
    this.setSelectedCategory(allCategories.id);
    this.setSelectedPartnerBadge(null);
    this.setSpecifiedVanityUrls(null);
  };

  getFirstScreenProfileCache() {
    const result = this.firstScreenCache.profile;
    this.setFirstScreenProfileCache(null);
    return result;
  }

  getFirstScreenPageCache() {
    const result = this.firstScreenCache.page;
    this.setFirstScreenPageCache(null);
    return result;
  }

  setFirstScreenProfileCache(config: IFirstScreenProfileCacheConfig | null) {
    this.firstScreenCache.profile = config;
  }

  setFirstScreenPageCache(config: IFirstScreenPageCacheConfig | null) {
    this.firstScreenCache.page = config;
  }

  // Computed Search Criteria Which Would be used for Reaction to Fetch Profiles From API
  @computed
  get criteriaForProfiles() {
    return (
      this.searchText +
      // eslint-disable-next-line no-unsafe-optional-chaining
      this.rootStore?.compatibilityStore.brandId +
      this.selectedCategory +
      this.selectedProductId +
      this.selectedPartnerBadge +
      this.specifiedVanityUrls
    );
  }

  @computed
  get criteriaForIPAAS() {
    // eslint-disable-next-line no-unsafe-optional-chaining
    return this.searchText + this.rootStore?.compatibilityStore.brandId;
  }

  @computed
  get criteriaForPages() {
    // eslint-disable-next-line no-unsafe-optional-chaining
    return this.searchText + this.rootStore?.compatibilityStore.brandId;
  }

  @action
  setSearchText = (searchText: string) => {
    this.searchText = searchText || EMPTY_SEARCH_TEXT;
  };

  @action
  setSelectedProductId = (product) => {
    this.selectedProductId = product;
  };

  @action
  setSelectedCategory = (categoryId: string) => {
    this.selectedCategory = categoryId;
  };

  @action
  setSelectedPartnerBadge = (partnerBadgeIds: string[] | null) => {
    this.selectedPartnerBadge = partnerBadgeIds;
  };

  @action
  setSpecifiedVanityUrls = (apps: string | null) => {
    this.specifiedVanityUrls = apps;
  };

  @action
  loadCategories = async (brandId: string, product: string) => {
    const categories = await transport.fetchCategories(brandId, product);

    runInAction(() => {
      this.categories = this.buildCategories(categories);
    });
  };

  @action
  resolveSearchTextFromUrl = (searchParams) => {
    const searchText = searchParams[urlParameterKeys.SEARCH_TEXT];

    this.setSearchText(searchText);
  };

  @action
  resolveProductIdFromUrl = (searchParams) => {
    const product = _.find(productConfig, (product) => {
      const value = searchParams[urlParameterKeys.PRODUCT];
      return product.id === value || product.displayName === value;
    });
    const productId = (product || allProductsItem).id;

    this.setSelectedProductId(productId);
  };

  @action
  resolveCategoryIdFromUrl = (searchParams) => {
    const category = _.find(supportedCategoryConfig, (category) => {
      const value = searchParams[urlParameterKeys.APP_CATEGORY];
      return category.id === value || category.displayName === value;
    });
    const categoryId = (category || allCategories).id;

    this.setSelectedCategory(categoryId);
  };

  @action
  resolvePartnerBadgeIdFromUrl = (searchParams) => {
    const badge = searchParams[urlParameterKeys.PARTNER_BADGE];
    if (!badge) {
      this.setSelectedPartnerBadge(null);
      return;
    }

    const badgeArray = Array.isArray(badge) ? badge : [badge];

    const partnerBadges = _.filter(
      supportedPartnerBadgeConfig,
      (badgeConfig) => {
        return _.includes(badgeArray, badgeConfig.displayName);
      },
    );
    if (Array.isArray(partnerBadges)) {
      this.setSelectedPartnerBadge(partnerBadges.map((badge) => badge.id));
    }
  };

  @action
  resolveMultipleAppsFromUrl = (searchParams) => {
    const apps = searchParams[urlParameterKeys.APPS];

    if (apps) {
      const appsArray = Array.isArray(apps) ? apps : [apps];
      const paramAppsArray = buildParamAppsArray(appsArray);
      this.setSpecifiedVanityUrls(paramAppsArray.join(','));
      return;
    }

    this.setSpecifiedVanityUrls(null);
  };

  private buildCategories = (rawCategories: string[]): ICategory[] => {
    return _.filter(supportedCategoryConfig, (categoryConfig) => {
      return !!_.find(
        rawCategories,
        (category) => category === categoryConfig.id,
      );
    });
  };

  getQueryString = () => {
    const category =
      this.selectedCategory === allCategories.id
        ? undefined
        : encodeURIComponent(
            supportedCategoryConfig[this.selectedCategory].displayName,
          );
    const product =
      this.selectedProductId === allProductsItem.id
        ? undefined
        : encodeURIComponent(productConfig[this.selectedProductId].displayName);
    const searchText = this.searchText
      ? encodeURIComponent(this.searchText)
      : undefined;

    return qs.stringify(
      {
        [urlParameterKeys.PRODUCT]: product,
        [urlParameterKeys.APP_CATEGORY]: category,
        [urlParameterKeys.SEARCH_TEXT]: searchText,
      },
      { arrayFormat: 'comma', addQueryPrefix: true, encode: false },
    );
  };
}
