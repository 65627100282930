import React from 'react';
import {
  Content,
  ContentItem,
  EmailLink,
  Header,
  TimeWrapper,
  Wrapper,
} from '@apw/components/profileDetail/profileBody/overviewTab/support/support.sc';
import { useTheme } from '@material-ui/core/styles';

interface AvailableTime {
  weekDayStart: string;
  weekDayEnd: string;
  TimeStart: string;
  TimeEnd: string;
  tz: string;
  availableTimeZone: string;
}

interface SupportProps {
  content:
    | {
        availableTime: AvailableTime;
        contactPhone: string;
        email: string;
      }
    | undefined;
}

export const Support = ({ content }: SupportProps) => {
  const theme = useTheme();

  if (!content) {
    return null;
  }

  const isEmptyContactPhone = isEmpty(content.contactPhone);
  const isEmptyEmail = isEmpty(content.email);
  const availableTime = content.availableTime;
  const isEmptyWeekDayStart = isEmpty(availableTime.weekDayStart);
  const isEmptyWeekDayEnd = isEmpty(availableTime.weekDayEnd);
  const isEmptyTimeStart = isEmpty(availableTime.TimeStart);
  const isEmptyTimeEnd = isEmpty(availableTime.TimeEnd);
  const isEmptyAvailableTimeZone = isEmpty(availableTime.availableTimeZone);
  const isEmptyAvailableTime =
    isEmptyWeekDayStart &&
    isEmptyWeekDayEnd &&
    isEmptyTimeStart &&
    isEmptyTimeEnd &&
    isEmptyAvailableTimeZone;

  if (isEmptyContactPhone && isEmptyEmail && isEmptyAvailableTime) {
    return null;
  }

  const getAvailableTime = () => {
    if (isEmptyAvailableTime) {
      return null;
    }
    const availableTime = content.availableTime;

    return (
      <ContentItem data-test-automation-id={`available-time`}>
        {!isEmptyWeekDayStart && !isEmptyWeekDayEnd && (
          <TimeWrapper data-test-automation-id={`week-day`}>
            {`${transformWeekday(
              availableTime.weekDayStart,
            )} - ${transformWeekday(availableTime.weekDayEnd)}`}
          </TimeWrapper>
        )}
        {!isEmptyTimeStart && !isEmptyTimeEnd && (
          <TimeWrapper data-test-automation-id={`support-time`}>
            {`${availableTime.TimeStart} - ${availableTime.TimeEnd}`}
          </TimeWrapper>
        )}
        {!isEmptyAvailableTimeZone && (
          <TimeWrapper data-test-automation-id={`available-timezone`}>
            {availableTime.availableTimeZone}
          </TimeWrapper>
        )}
      </ContentItem>
    );
  };

  const getContactPhone = () => {
    if (isEmptyContactPhone) {
      return null;
    }

    return (
      <ContentItem data-test-automation-id={`contact-phone`}>
        {content.contactPhone}
      </ContentItem>
    );
  };

  const getEmailContent = () => {
    if (isEmptyEmail) {
      return null;
    }

    return (
      <ContentItem>
        <EmailLink
          data-test-automation-id={'email-link'}
          href={`mailto:${content.email}`}
          style={{ color: theme.palette.text.secondary }}
          rel="noreferrer"
        >
          {content.email}
        </EmailLink>
      </ContentItem>
    );
  };

  return (
    <Wrapper>
      <Header>Support</Header>
      <Content>
        {getAvailableTime()}
        {getContactPhone()}
        {getEmailContent()}
      </Content>
    </Wrapper>
  );
};

const isEmpty = (value: string) => {
  return !value || !value.trim();
};

const transformWeekday = (value: string) => {
  const weekdayMapping = {
    '1': 'Mon',
    '2': 'Tue',
    '3': 'Wed',
    '4': 'Thu',
    '5': 'Fri',
    '6': 'Sat',
    '7': 'Sun',
  };

  return weekdayMapping[value] || '';
};
