import { BPS, respondTo, Color, onMobile } from '@apw/core/sc';
import { RcTextField, styled } from '@ringcentral/juno';

export const SearchFilterContainer = styled.div`
  width: 480px;
  margin: 0 28px;
  padding: 1px;

  ${onMobile} {
    display: none;
  }
`;

export const SearchFilter = styled(RcTextField)`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 164px;
  border: none;
  background: #f3f3f3;
  position: relative;
  height: 40px;
  min-height: 40px;
  border-radius: 20px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background: #f3f3f3 !important;
  }

  [class~='MuiInput-root'] {
    width: 100%;
    background: transparent !important;

    &::before {
      box-shadow: none !important;
    }

    // Styles From Juno, For SSR
    > span:first-of-type {
      display: inline-flex;
      position: relative;
      margin: 0 16px 0 0;
      cursor: inherit;
      color: rgb(129, 137, 157);
      width: 20px;
      height: 20px;
      -webkit-box-align: center;
      align-items: center;

      > span {
        display: inline-flex;
        position: relative;
      }

      svg {
        display: inline-block;
        width: 1em;
        height: 1em;
        stroke-width: 0;
        stroke: currentcolor;
        fill: currentcolor;
        pointer-events: none;
        font-size: 16px;
      }
    }

    // Styles From Juno, For SSR
    fieldset {
      display: none;
    }

    input {
      &::placeholder {
        color: ${Color.lightGray2};
      }
      margin-left: 0;
      padding: 10px 0;
      font-size: 1rem;
      font-weight: 400;
      line-height: 24px;
    }
  }
`;

export const SearchFilterMobile = styled.div`
  display: none;
  margin: 0 20px 0 auto;
  background: #f3f3f3;
  border-radius: 6px;

  ${onMobile} {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
  }

  span {
    svg {
      color: #686869;

      ${respondTo(BPS.ltTablet)} {
        font-size: 16px;
      }
  }
`;
