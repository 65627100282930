import React, { FC } from 'react';
import {
  Container,
  Title,
} from '@apw/components/ratingAndReview/overallRating/overallRating.sc';
import {
  IOverallRatingItemProps,
  OverallRatingItem,
} from '@apw/components/ratingAndReview/overallRating/overallRatingItem/OverallRatingItem';

export interface IOverallRatingProps {
  items: IOverallRatingItemProps[];
  automationId: string;
  className?: string;
}

export const OverallRating: FC<IOverallRatingProps> = ({
  items,
  automationId,
  className,
}) => {
  return (
    <Container className={className} data-test-automation-id={automationId}>
      <Title
        color="neutral.f06"
        variant="subheading1"
        data-test-automation-id={'overall-rating-title'}
      >
        Overall ratings
      </Title>
      {items.map((item) => {
        return (
          <OverallRatingItem stars={item.stars} percentage={item.percentage} />
        );
      })}
    </Container>
  );
};
