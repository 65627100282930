import React, { FC } from 'react';
import { IPageCard, PageCard } from '@apw/components/pageCard';
import { SearchResultSection } from '@apw/modules/search/searchResultSection';
import { getCompatibilityPagePath } from '@apw/routes/getCompatibilityPaths';
import { SearchResult } from '@apw/shared/searchTracking';
import { breakpoints } from '@apw/shared/theme/utils';
import { useCurrentCompatibility } from '@apw/stores/compatibility';
import { styled } from '@ringcentral/juno';
import { observer } from 'mobx-react';

const PAGE_CARD_GAP = '12px';

const PageSearchResultPure: FC<{
  collectionList: IPageCard[];
  handleClick: (clickLink, resultType, clickDepth: number) => void;
  isH1: boolean;
}> = (props) => {
  const { collectionList, handleClick } = props;
  const compatibility = useCurrentCompatibility();

  const onPageCardClick = (clickItem: IPageCard, resultDepth: number) => {
    if (handleClick) {
      const defaultPagePath = getCompatibilityPagePath(
        compatibility.slug,
        clickItem.vanityUrl,
      );
      const path = clickItem.path || defaultPagePath;
      handleClick(path, SearchResult.PAGE, resultDepth + 1);
    }
  };

  return (
    <SearchResultSection title="Relevant collections" isH1={props.isH1}>
      <StyledContainer>
        <StyledBody>
          {collectionList.map((item, index) => (
            <StyledPageCard
              key={item.pageId}
              page={item}
              handleClick={() => onPageCardClick(item, index)}
            />
          ))}
        </StyledBody>
      </StyledContainer>
    </SearchResultSection>
  );
};

const StyledPageCard = styled(PageCard)``;

const StyledContainer = styled.div`
  width: 100%;
  min-width: 0;
`;

const StyledBody = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${StyledPageCard} {
    width: calc(50% - ${PAGE_CARD_GAP} / 2);
    margin-bottom: ${PAGE_CARD_GAP};

    @media ${breakpoints.contentCard1} {
      width: 100%;
    }
  }
`;

export const PageSearchResult = observer(PageSearchResultPure);
