import {
  BPS,
  PAGE_DESKTOP_PADDING,
  PAGE_MAX_WIDTH,
  PAGE_MOBILE_PADDING,
  respondTo,
  onMobile,
} from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const HeaderContainer = styled.div`
  width: 100%;
  background: #fff;
`;

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
  padding: 0 ${PAGE_DESKTOP_PADDING}px;
  max-width: ${PAGE_MAX_WIDTH + PAGE_DESKTOP_PADDING * 2}px;
  height: 104px;

  ${respondTo(BPS.ltTablet)} {
    height: 84px;
  }

  ${onMobile} {
    flex-wrap: wrap;
    height: 72px;
    padding: 0 ${PAGE_MOBILE_PADDING}px;
  }
`;

export const SideItem = styled.div`
  min-width: 330px;

  ${respondTo(BPS.ltTablet)} {
    width: auto;
    min-width: auto;
  }

  ${onMobile} {
    width: auto;
    min-width: auto;
  }
`;
