import React, { useCallback } from 'react';
import { isVimeoUrl } from '@apw/components/profileDetail/profileBody/overviewTab/overviewSlider/vimeoSlide/vimeo-slide.service';
import VimeoSlide from '@apw/components/profileDetail/profileBody/overviewTab/overviewSlider/vimeoSlide/VimeoSlide';
import YoutubeSlide from '@apw/components/profileDetail/profileBody/overviewTab/overviewSlider/youtubeSlide/YoutubeSlide';
import ScaleDiv from '@apw/components/scaleDiv/ScaleDiv';
import { RcDialogContent } from '@ringcentral/juno';
import { Close } from '@ringcentral/juno-icon';
import { CloseIcon, DialogContainer, Header } from './mobileVideoDialog.sc';

const MobileVideoDialog = ({ video, dialogState, setDialogState }) => {
  const close = useCallback(() => setDialogState(false), [setDialogState]);
  return (
    <DialogContainer open={dialogState} onClose={close}>
      <RcDialogContent>
        <Header>
          <CloseIcon
            symbol={Close}
            onClick={close}
            data-test-automation-id={`close-button`}
          />
        </Header>
        <ScaleDiv widthWeight={488} heightWeight={274}>
          {isVimeoUrl(video) ? (
            <VimeoSlide url={video} />
          ) : (
            <YoutubeSlide url={video} />
          )}
        </ScaleDiv>
      </RcDialogContent>
    </DialogContainer>
  );
};

export default MobileVideoDialog;
