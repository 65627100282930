import {
  normalizeSitemapProperty,
  normalizeUri,
} from '@apw/shared/coveoSearch/coveoAdapter.service';
import {
  ICoveoResultForPage,
  CoveoResultProperty,
  SitemapProperty,
} from '@apw/shared/searchProvider';
import { IPageInSearch } from '../types';

class CoveoPageService {
  transform(result: ICoveoResultForPage): IPageInSearch {
    const uri = result[CoveoResultProperty.Uri];
    const data = result[CoveoResultProperty.Raw];

    return {
      imageUrl: data[SitemapProperty.LOGO_URL],
      name: normalizeSitemapProperty(data[SitemapProperty.PAGE_NAME]),
      description: normalizeSitemapProperty(data[SitemapProperty.SHORT_DESC]),
      path: normalizeUri(uri),
      pageId: data[SitemapProperty.PAGE_ID],
      vanityUrl: '', // will not use
    };
  }
}

export const coveoPageService = new CoveoPageService();
