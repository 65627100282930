import React from 'react';
import { RcIcon } from '@ringcentral/juno';
import accessibilityIcon from '@apw/assets/images/footer-icon/accessibility-icon.svg';
import { ensureAction } from '@apw/core';
import { socialIcons, footerLinks } from '@apw/shared/footer/footerData';
import {
  AccessibilityIcon,
  AccessibilityIconLink,
  CopyrightWrap,
  FooterContainer,
  FooterContent,
  FooterContentLine,
  IconItem,
  IconWrap,
  LinkItem,
  LinkWrap,
} from './Footer.sc';

const IconItems = () => {
  return (
    <>
      {socialIcons.map((item: any) => (
        <IconItem
          key={item.name}
          href={item.href}
          target="_blank"
          rel="noreferrer"
          data-test-automation-id={`footerSocialLink-${item.name}`}
          aria-label={item.name}
          className={`footerSocialLink-${item.name}`}
        >
          <RcIcon symbol={item.icon} aria-hidden="true" />
        </IconItem>
      ))}
    </>
  );
};

const LinkItems = () => {
  const keyPressItem = (e, item) => {
    if (!item.onClick) {
      return;
    }
    const actionFn = () => item.onClick();
    ensureAction(e, actionFn, ['Enter']);
  };

  return (
    <>
      {footerLinks.map((item: any) => (
        <LinkItem
          key={item.label}
          href={item.href}
          target="_blank"
          rel="noreferrer"
          onClick={() => item.onClick && item.onClick()}
          onKeyDown={(e) => keyPressItem(e, item)}
          data-test-automation-id={`footerLink-${item.label}`}
          tabIndex={0}
        >
          {item.label}
        </LinkItem>
      ))}
    </>
  );
};

const Copyright = () => {
  return (
    <div>
      &copy; {new Date().getFullYear().toString()} RingCentral, Inc. All rights
      reserved.
    </div>
  );
};

export const Footer = () => {
  return (
    <FooterContainer data-test-automation-id="footer" role="contentinfo">
      <FooterContent data-test-automation-id="footer-content">
        <FooterContentLine>
          <IconWrap>
            <IconItems />
          </IconWrap>
          <AccessibilityIconLink
            href="https://www.essentialaccessibility.com/ringcentral"
            target="_blank"
            rel="noopener noreferrer"
            title="Accessibility icon"
          >
            <AccessibilityIcon symbol={accessibilityIcon} aria-hidden="true" />
          </AccessibilityIconLink>
        </FooterContentLine>
        <FooterContentLine>
          <LinkWrap>
            <LinkItems />
          </LinkWrap>
          <CopyrightWrap data-test-automation-id="footerCopyRight">
            <Copyright />
          </CopyrightWrap>
        </FooterContentLine>
      </FooterContent>
    </FooterContainer>
  );
};
