import { onMobile } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const Container = styled.div`
  background: #fff;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cbcbcb;
  height: 80px;
  padding-bottom: 4px;
  ${onMobile} {
    height: 48px;
  }
`;
