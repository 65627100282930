import { onMobile } from '@apw/core/sc';
import { styled, RcTextarea } from '@ringcentral/juno';

export const Review = styled(RcTextarea)`
  margin-top: 32px;
  margin-bottom: 16px;

  ${onMobile} {
    textarea {
      height: 320px !important;
    }
  }
`;
