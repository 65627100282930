import { onMobile, useProximaNovaRegular } from '@apw/core/sc';
import {
  RcDialog,
  RcDialogActions,
  RcDialogContent,
  RcDialogTitle,
  RcIconButton,
  styled,
} from '@ringcentral/juno';

export const Container = styled(RcDialog)`
  [class~='RcDialog-paper'] {
    padding: 32px 24px;
    max-width: 640px !important;
    position: relative;
    ${useProximaNovaRegular}

    > div {
      height: auto !important;
    }

    ${onMobile} {
      max-width: none !important;
      max-height: none !important;
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
      border-radius: 0 !important;
    }
  }
`;

export const DialogTitle = styled(RcDialogTitle)`
  h2 {
    ${useProximaNovaRegular}
  }
`;

export const Content = styled(RcDialogContent)`
  padding: 24px !important;
  line-height: 1.6;
  font-size: 15px;
`;

export const Actions = styled(RcDialogActions)`
  padding-bottom: 0 !important;
`;

export const CloseButton = styled(RcIconButton)`
  position: absolute !important;
  top: 12px;
  right: 12px;
`;
