import React, { useContext } from 'react';
import { styled } from '@ringcentral/juno';
import { observer } from 'mobx-react';
import { StoreContext } from '../../../StoreContext';

const Wrapper = styled.div`
  width: 100%;
`;

const SectionTitle = styled.h2`
  margin: 0 0 16px;
  font-size: 22px;
  font-weight: 600;
  color: #001138;
`;

const SectionDesc = styled.p`
  margin: 0 0 20px;
  font-size: 16px;
  color: #282829;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: #282829;

  thead,
  tr {
    border-bottom: 1px solid #e5e5e5;
  }

  tr:last-child {
    border-bottom-width: 0;
  }

  td {
    padding: 16px 0 16px 16px;
  }

  th:first-child,
  td:first-child {
    width: 196px;
  }

  @media (max-width: 960px) {
    th:first-child,
    td:first-child {
      width: 128px;
    }
  }
`;

const Thead = styled.thead`
  background-color: #f3f3f3;

  th {
    padding: 8px 0 8px 16px;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    word-wrap: break-word;
    word-break: break-word;
  }
`;

const Tr = styled.tr`
  td {
    font-size: 15px;
    line-height: 24px;
    word-wrap: break-word;
    word-break: break-word;
    vertical-align: baseline;
  }
`;

function PermissionsPure() {
  const { profile, translation } = useContext(StoreContext);

  if (!profile) {
    return null;
  }

  const permissionList = profile.permissions || [];

  if (!permissionList.length) {
    return null;
  }

  return (
    <Wrapper data-test-automation-id="permissions">
      <SectionTitle
        className="permissionsTitle"
        data-test-automation-id="permissions-title"
        tabIndex={0}
      >
        {translation.permissions.title}
      </SectionTitle>
      <SectionDesc
        className="permissionsDesc"
        data-test-automation-id="permissions-desc"
        tabIndex={0}
      >
        {translation.permissions.desc}
      </SectionDesc>
      <Table
        className="permissionsTable"
        data-test-automation-id="permissions-table"
      >
        <Thead>
          <tr aria-hidden="true">
            <th>{translation.permissions.columns.nameTitle}</th>
            <th>{translation.permissions.columns.descTitle}</th>
          </tr>
        </Thead>
        <tbody>
          {permissionList.map((item) => (
            <Tr key={item.id}>
              <td
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
                aria-label={translation.permissions.columns.nameLabel(
                  item.name,
                )}
              >
                {item.name}
              </td>
              <td
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
                aria-label={translation.permissions.columns.descLabel(
                  item.description,
                )}
              >
                {item.description}
              </td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
}

export const Permissions = observer(PermissionsPure);
