export enum QualityRatingTooltips {
  Value = 'Is this app worth your time?',
  Convenience = 'How easy was it to set up and use the app?',
  Feature = 'How well does the app meet your needs?',
  Support = 'Were you able to get any help you needed?',
}

export const QualityRatingLabels = [
  {
    label: 'Value',
    tooltip: QualityRatingTooltips.Value,
  },
  {
    label: 'Ease of use',
    tooltip: QualityRatingTooltips.Convenience,
  },
  {
    label: 'Feature and functionality',
    tooltip: QualityRatingTooltips.Feature,
  },
  {
    label: 'Support and documentation',
    tooltip: QualityRatingTooltips.Support,
  },
];
