import { urlParameterKeys } from '@apw/core/url';
import * as qs from 'qs';

export const getRedirectTarget = (options: {
  basePath: string;
  queryParams;
  keyMapping;
  customEncoders?;
}) => {
  const { basePath, queryParams, keyMapping, customEncoders } = options;

  const newQueryParams = Object.keys(keyMapping).reduce(
    (newQueryParams, legacyQueryParamKey) => {
      const value = queryParams[legacyQueryParamKey];
      const newQueryParamKey = keyMapping[legacyQueryParamKey];
      const encoder = customEncoders?.[newQueryParamKey];
      newQueryParams[newQueryParamKey] = encoder ? encoder(value) : value;
      return newQueryParams;
    },
    {},
  );

  newQueryParams[urlParameterKeys.LOGIN_ERROR] =
    queryParams[urlParameterKeys.LOGIN_ERROR];
  const newQueryString = qs.stringify(newQueryParams, { encode: false });

  return `${basePath}?${newQueryString}`;
};
