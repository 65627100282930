import { words, debounce } from 'lodash';
import { domService } from '../../services/dom/dom.service';
import { checkIsSupportCss } from './checkIsSupportCss';

export function dpwMultipleLineEllipsis(
  elementRef: HTMLElement | null,
  lineHeightPx = 0,
  linesToDisplay = 0,
): void {
  const element = elementRef;
  let innerHTML: string;
  let innerText: string;

  const supportByCss = (element: HTMLElement) => {
    setStyle(element, 'display', '-webkit-box');
    setStyle(element, 'overflow', `hidden`);
    setStyle(element, 'text-overflow', 'ellipsis');
    setStyle(element, '-webkit-line-clamp', `${linesToDisplay}`);
    setStyle(element, 'line-height', `${lineHeightPx}px`);
    setStyle(element, '-webkit-box-orient', 'vertical');
  };

  const init = () => {
    if (!element) {
      return;
    }
    innerHTML = element.innerHTML;
    innerText = element.innerText;
    clampText();
  };

  const clampText = () => {
    if (!element) {
      return;
    }
    restore();
    let clamped = false;
    const words = divideIntoWords(innerHTML);
    while (domService.getScrollHeight(element) > element.clientHeight) {
      words.pop();
      element.innerHTML = `${words.join(' ')} ...`;
      clamped = true;
    }

    if (clamped) {
      setAttribute(element, 'title', innerText);
    } else {
      removeAttribute(element, 'title');
    }
  };

  const restore = () => {
    if (!element) {
      return;
    }
    element.innerHTML = innerHTML;
  };

  const divideIntoWords = (html: string) => {
    return words(html, /(<.+>.*<\/.+>)|[^ ]+/g);
  };

  const clampTextWithDebounce = debounce(() => clampText(), 500);

  const supportByCalc = () => {
    if (!element) {
      return;
    }
    setStyle(element, 'line-height', `${lineHeightPx}px`);
    setStyle(element, 'max-height', `${lineHeightPx * linesToDisplay}px`);
    setStyle(element, 'overflow', `hidden`);

    domService.documentComplete.subscribe((ready: boolean) => {
      if (!ready) {
        return;
      }
      init();
      window.addEventListener('resize', clampTextWithDebounce);
    });
  };

  const onInit = () => {
    if (checkIsSupportCss(element!)) {
      supportByCss(element!);
      return;
    }
    supportByCalc();
  };

  const onDestroy = () => {
    window.removeEventListener('resize', clampTextWithDebounce);
  };

  if (element) {
    onInit();
  } else {
    onDestroy();
  }
}

export const setAttribute = (
  element: HTMLElement | null,
  name: string,
  value: string,
  namespace?: string,
) => {
  if (!element) {
    return;
  }
  if (namespace) {
    element.setAttributeNS(namespace, name, value);
  } else {
    element.setAttribute(name, value);
  }
};

export const removeAttribute = (
  element: HTMLElement | null,
  name: string,
  namespace?: string,
) => {
  if (!element) {
    return;
  }
  if (namespace) {
    element.removeAttributeNS(namespace, name);
  } else {
    element.removeAttribute(name);
  }
};

export const setStyle = (
  element: HTMLElement | null,
  property: string,
  value: string,
) => {
  if (!element) {
    return;
  }
  element.style[property] = value;
};
