import {
  BPS,
  overflowWrap,
  respondTo,
  useProximaNovaBold,
  useProximaNovaRegular,
} from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

const imgSize = '160px';
const mobileImgSize = '96px';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
  padding: 20px 9.5% 20px 0;

  &:last-child {
    margin-bottom: 0;
  }

  ${respondTo(BPS.headerBlock)} {
    padding: 16px 0;
  }
`;

export const ImageWrapper = styled.div`
  position: absolute;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  ${useProximaNovaBold};
  color: #001138;
`;

export const HeaderTitle = styled.div`
  ${overflowWrap('break-word')};
  width: 100%;
  margin: 0;
  font-size: 40px;
  font-weight: 700;
`;

export const DescWrapper = styled.div`
  ${overflowWrap('break-word')};
  ${useProximaNovaRegular};
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #282829;

  a {
    line-height: 26px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  img {
    max-width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  min-height: ${imgSize};

  ${ImageWrapper} {
    top: 0;
    bottom: 0;
    left: 0;
    height: ${imgSize};
    margin: auto;

    img {
      display: block;
      width: ${imgSize};
      height: ${imgSize};
    }
  }

  ${HeaderWrapper},
  ${DescWrapper} {
    margin-left: ${imgSize};
  }

  &.notImage {
    min-height: auto;

    ${HeaderWrapper},
    ${DescWrapper} {
      padding-right: 0 !important;
    }
  }

  ${respondTo(BPS.headerBlock)} {
    display: block;
    min-height: ${mobileImgSize};

    ${ImageWrapper} {
      top: 0;
      height: ${mobileImgSize};

      img {
        display: block;
        width: ${mobileImgSize};
        height: ${mobileImgSize};
      }
    }

    ${HeaderWrapper} {
      position: relative;
      min-height: ${mobileImgSize};
      margin-left: ${mobileImgSize};
    }

    ${HeaderTitle} {
      font-size: 32px;
    }

    ${DescWrapper} {
      margin-top: 12px;
      margin-right: 0 !important;
      padding-right: 0 !important;
    }

    &.notImage {
      min-height: auto;

      ${HeaderWrapper} {
        min-height: auto;
      }
    }
  }

  &.textWithImageOnRight {
    ${ImageWrapper} {
      left: auto;
      right: 0;
    }

    ${HeaderWrapper},
    ${DescWrapper} {
      margin-left: 0;
      margin-right: ${imgSize};
      padding-right: 8%;
    }

    &.notImage {
      ${HeaderWrapper},
      ${DescWrapper} {
        margin-right: 0;
      }
    }

    ${respondTo(BPS.headerBlock)} {
      ${HeaderWrapper} {
        margin-right: ${mobileImgSize};
        padding-right: 16px;
      }

      ${ImageWrapper} {
        right: -${mobileImgSize};
      }

      &.notImage {
        ${DescWrapper} {
          margin-right: 0;
        }
      }
    }
  }

  &.textWithImageOnLeft {
    ${ImageWrapper} {
      padding-left: 4.8%;
      padding-right: 9.5%;
    }

    ${HeaderWrapper},
    ${DescWrapper} {
      margin-left: calc(${imgSize} + 4.8% + 9.5%);
    }

    &.notImage {
      ${HeaderWrapper},
      ${DescWrapper} {
        margin-left: 0;
      }
    }

    ${respondTo(BPS.headerBlock)} {
      ${ImageWrapper} {
        left: calc(-${mobileImgSize} - 16px);
        padding: 0;
      }

      ${HeaderWrapper} {
        margin-left: calc(${mobileImgSize} + 16px);
      }

      ${DescWrapper} {
        margin-left: 0;
      }
    }
  }
`;
