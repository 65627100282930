import { onMobile, useProximaNovaSemibold } from '@apw/core/sc';
import { RcButton, styled } from '@ringcentral/juno';

export const Button = styled(RcButton)`
  ${useProximaNovaSemibold};
  padding: 8px !important;
  font-size: 16px !important;

  ${onMobile} {
    padding-left: 0 !important;
  }
`;
