import React, { FC } from 'react';
import { transport } from '@apw/core';
import { Language, languageService } from '@apw/core/language';
import { useCommonError } from '@apw/hooks/useCommonError';
import { buildGuestFormikConfig, UserForm } from '@apw/modules/profile';
import {
  Container,
  SkipButton,
} from '@apw/modules/profile/downloadGate/guestForm/GuestForm.sc';
import { IProfile } from '@apw/types';
import { useFormik } from 'formik';
import * as _ from 'lodash';
import { observer } from 'mobx-react';

const GuestFormPure: FC<{
  profile: IProfile;
  onSubmit;
  onSkip;
  onClose;
}> = ({ profile, onSubmit, onSkip, onClose }) => {
  const form = useFormik(buildGuestFormikConfig());
  const commonError = useCommonError();
  const handleSubmit = (errorHandler) => {
    transport
      .submitGuestForm(profile._id, form.values)
      .then(() => {
        onSubmit();
      })
      .catch((res) => {
        const error = _.get(res, 'response.data');
        return errorHandler.handle(error);
      })
      .catch(() => {
        commonError.show();
      });
  };

  return (
    <Container open>
      <UserForm
        data-test-automation-id="guest-form"
        form={form}
        onClose={onClose}
        onSubmit={handleSubmit}
        description={languageService.get(Language.GUEST_FORM_DESCRIPTION)}
        secondaryBtn={<SkipButton onClick={onSkip} />}
      />
    </Container>
  );
};

export const GuestForm = observer(GuestFormPure);
