import { onMobile, FontWeight } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const BotTypeStyled = styled.div`
  display: inline-block;
  margin-left: 10px;
  padding: 4px 10px;
  color: white;
  font-weight: ${FontWeight.bold};
  font-size: 13px;
  border-radius: 4px;
  vertical-align: middle;

  &[class~='bot-list-bot-type-private'],
  &[class~='bot-list-bot-type-internal'] {
    color: #be3933;
    background-color: #ffe5e0;
  }

  ${onMobile} {
    font-size: 11px;
    font-weight: ${FontWeight.bold};
    color: white;
    padding: 3px 8px;
    border-radius: 4px;
    margin-left: 6px;
  }
`;
