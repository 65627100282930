import { onMobile } from '@apw/core/sc';
import {
  createGlobalStyle,
  RcButton,
  RcIcon,
  RcPopover,
  RcTooltip,
  styled,
} from '@ringcentral/juno';

const tooltipBgColor = '#06132F';

export const TooltipStyled = styled(RcTooltip)`
  font-size: 20px;
`;

export const MobileTooltipWrapper = styled(RcPopover)`
  [class~='MuiPopover-paper'] {
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const MobileContainer = styled.div`
  position: fixed;
  display: none;
  background-color: ${tooltipBgColor};
  color: #fff;
  z-index: 10000;
  opacity: 0.95;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  padding: 63px 15px;
  text-transform: none;
  white-space: normal;

  ${onMobile} {
    display: block;
  }
`;

export const CloseButton = styled(RcButton)`
  &&& {
    font-size: 30px;
    color: #fff;
    font-weight: 200;
    position: absolute;
    top: 12px;
    right: 0;
    padding: 12px;
    line-height: 15px;
  }
`;

export const TooltipGlobalStyles = createGlobalStyle`
  [class~='RcTooltip-popper'] {
    [class~='RcTooltip-tooltip'] {
      background-color: ${tooltipBgColor} !important;
    }

    [class~='MuiTooltip-arrow'] {
      color: ${tooltipBgColor} !important;
    }

    ${onMobile} {
      display: none;
    }
  }
`;

export const StyledRcIcon = styled(RcIcon)`
  svg {
    color: rgb(102, 102, 102);
    margin-left: 2px;
    font-size: 18px;
    margin-right: 2px;
  }
`;
