import { startsWith } from 'lodash';
import qs, { IParseOptions } from 'qs';

const APP_BASE_HREF = `${process.env.PUBLIC_URL}/`;

export const prepareExternalUrl = (url) => {
  const isAbsoluteURL =
    startsWith(url, 'http://') ||
    startsWith(url, 'https://') ||
    startsWith(url, '//');

  if (isAbsoluteURL) {
    return url;
  }

  return startsWith(url, APP_BASE_HREF)
    ? url
    : APP_BASE_HREF + url.replace(/^\//g, '');
};

export const getSearchParams = (
  options?: IParseOptions & { decoder?: never },
) => {
  return qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
    ...options,
  });
};

export const patchSearchParams = (params: object) => {
  const search = getSearchParams();
  return qs.stringify(
    {
      ...search,
      ...params,
    },
    { addQueryPrefix: true },
  );
};

export const formatSearchParams = (params: object) => {
  return qs.stringify(
    {
      ...params,
    },
    { addQueryPrefix: true },
  );
};

export const updateParams = (url: string, newParam: object) => {
  const tokens = url.split(/\?(.+)/);
  const domain: string = tokens[0];
  const paramStr: string | undefined = tokens[1];
  const params = { ...qs.parse(paramStr), ...newParam };
  return domain + qs.stringify(params, { addQueryPrefix: true });
};
