import React, { FC, useContext, useMemo } from 'react';
import {
  Container,
  Title,
} from '@apw/lib/components/profileDetail/profileBody/overviewTab/ratingsAndReviews/ratingsAndReview.sc';
import { RatingsSection } from '@apw/lib/components/profileDetail/profileBody/overviewTab/ratingsAndReviews/ratingsSection/RatingsSection';
import { StoreContext } from '@apw/lib/components/profileDetail/StoreContext';
import { NoRatingsSection } from '@apw/lib/components/ratingAndReview/noRatingsSection/NoRatingsSection';
import { WriteReviewButton } from '@apw/lib/components/ratingAndReview/writeReviewButton/WriteReviewButton';
import { useInnerWidth } from '@apw/lib/hooks';
import { IAppRating } from '@apw/lib/types/profile/appRating.interface';

export interface IRatingsAndReviewsProps {
  ratings?: IAppRating;
}

export const RatingsAndReviews: FC<IRatingsAndReviewsProps> = ({ ratings }) => {
  const { translation } = useContext(StoreContext);
  const width = useInnerWidth();
  const isTablet = useMemo(() => {
    const mobileWidth = 768;
    const tabletWidth = 960;
    return width > mobileWidth && width < tabletWidth;
  }, []);

  return (
    <Container>
      <Title data-test-automation-id={'ratings-and-review-title'}>
        {translation.ratingsAndReviews.title}
      </Title>
      {ratings ? <RatingsSection ratings={ratings} /> : <NoRatingsSection />}
      <WriteReviewButton fullWidth={!isTablet} />
    </Container>
  );
};
