import { palette2, RcTypography, styled } from '@ringcentral/juno';
import {
  NoReviewsSection,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  INoReviewsSectionProps,
} from '@apw/lib/components/ratingAndReview/noReviewsSection/NoReviewsSection';
import { onMobile } from '@apw/lib/sc';

export const Wrapper = styled.div``;

export const SectionHeading = styled(RcTypography)`
  margin-top: 0;
`;

export const SectionBody = styled.div``;

export const TotalNumber = styled.span``;

export const Divider = styled.div`
  border-bottom: 1px solid ${palette2('neutral', 'l02')};
`;

export const StyledNoReviewsSection = styled(NoReviewsSection)`
  margin-top: 132px;
  margin-bottom: 132px;

  ${onMobile} {
    margin-top: 80px;
    margin-bottom: 80px;
  }
`;

export const LoadingWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
`;
