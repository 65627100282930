import { onMobile } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  ${onMobile} {
    margin-top: 28px;
    margin-bottom: 48px;
    padding-top: 0;
  }
`;

export const ItemList = styled.div`
  margin-top: 12px;
`;
