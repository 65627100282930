import React, { useContext, useEffect } from 'react';
import { ProfileViewModelContext } from '@apw/components/profileDetail/Profile.ViewModel.context';
import { RatingPopupContent } from '@apw/components/ratingAndReview/popup/RatingPopupContent';
import { useStores } from '@apw/stores';
import { observer } from 'mobx-react';
import { Popup } from './RatingPopup.sc';
import { RatingPopupStore } from './ratingPopup.store';

interface RatingPopupProps {
  open: boolean;
  onClose: () => void;
  onSave: (store: RatingPopupStore, isEdit: boolean) => void;
}

const RatingPopupPure = ({ open, onClose, onSave }: RatingPopupProps) => {
  const { myReviewsStore, userStore } = useStores();
  const profileVM = useContext(ProfileViewModelContext);

  useEffect(() => {
    myReviewsStore.loadItem(
      userStore.user?.extensionId!,
      profileVM.profile?.appId!,
      true,
    );
  }, []);

  return (
    <Popup open={open} data-test-automation-id="rating-and-review-popup">
      <RatingPopupContent onClose={onClose} onSave={onSave} />
    </Popup>
  );
};

export const RatingPopup = observer(RatingPopupPure);
