const sizes = {
  sm: 481,
  md: 768,
  lg: 996,
  xl: 1296,
};

export const breakpoints = {
  mobilePortrait: `(max-width: ${sizes.sm - 1}px)`,

  mobileLandscape: `(min-width: ${sizes.sm}px) and (max-width: ${
    sizes.md - 1
  }px)`,

  mobile: `(max-width: ${sizes.md - 1}px)`,
  tablet: `(min-width: ${sizes.md}px) and (max-width: ${sizes.lg - 1}px)`,
  tabletMobile: `(max-width: ${sizes.lg - 1}px)`,
  mediumDesktop: `(min-width: ${sizes.lg}px)`,
  largeDesktop: `(min-width: ${sizes.xl}px)`,

  galleryCols3: `(min-width: ${sizes.lg}px)`,
  galleryCols2: `(min-width: ${sizes.md}px) and (max-width: ${sizes.lg - 1}px)`,
  galleryCols1: `(max-width: ${sizes.md - 1}px)`,

  contentCard2: `(min-width: ${sizes.sm}px) and (max-width: ${sizes.lg - 1}px)`,
  contentCard1: `(max-width: ${sizes.sm - 1}px)`,
};
