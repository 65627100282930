import React, { FC } from 'react';
import { RcIcon } from '@ringcentral/juno';
import { Star } from '@ringcentral/juno-icon';
import {
  Container,
  Percentage,
  ProgressBar,
  StarsNumber,
} from '@apw/lib/components/ratingAndReview/overallRating/overallRatingItem/overallRatingItem.sc';

export interface IOverallRatingItemProps {
  stars: number;
  percentage: number;
}

export const OverallRatingItem: FC<IOverallRatingItemProps> = ({
  stars,
  percentage,
}) => {
  return (
    <Container
      data-test-automation-id={`overall-rating-item-container-${stars}`}
    >
      <StarsNumber
        color="neutral.f05"
        variant="subheading1"
        data-test-automation-id={'overall-rating-item-stars'}
      >
        {stars}
      </StarsNumber>
      <RcIcon color="warning.b03" symbol={Star} size="medium" />
      <ProgressBar
        variant="determinate"
        value={percentage}
        data-test-automation-id={'overall-rating-item-progress-bar'}
      />
      <Percentage
        color="neutral.f04"
        variant="caption1"
        data-test-automation-id={'overall-rating-item-percentage'}
      >
        {percentage}%
      </Percentage>
    </Container>
  );
};
