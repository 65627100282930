import {
  BPS,
  respondTo,
  useProximaNovaSemibold,
  overflowWrap,
} from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

const galleryListItemMargin = 12;
const appLogoSize = 72;

export const WrapperShadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s;
  pointer-events: none;
  border-radius: 8px;
`;

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: calc(33.33% - ${galleryListItemMargin}px);
  margin: 0 ${galleryListItemMargin}px ${galleryListItemMargin}px 0;
  vertical-align: top;

  > a {
    color: #494949;
    text-decoration: none;
  }

  ${respondTo(BPS.gtTablet)} {
    width: calc(33.33% - ${galleryListItemMargin}px);

    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }

  ${respondTo(BPS.gtTablet)} {
    &:hover {
      ${WrapperShadow} {
        background-color: rgba(0, 17, 56, 0.04);
      }
    }
  }

  ${respondTo(BPS.contentCard2)} {
    width: calc(50% - ${galleryListItemMargin}px);

    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }

  ${respondTo(BPS.contentCard1)} {
    width: 100%;
    margin-right: 0;
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px 15px 20px 15px;
  border: 1px solid #e1e6ea;
  background: #ffffff;
  border-radius: 8px;
`;

export const TileTop = styled.div`
  flex: 0 0 auto;
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  overflow: hidden;

  .appLogo {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: ${appLogoSize}px;
    overflow: hidden;

    span svg {
      width: auto;
    }
  }

  .certifiedBadge {
    flex-shrink: 0;
    margin-left: 12px;
  }
`;

export const TileBottom = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row-reverse;

  .appInfo {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    width: 0;
    height: 100%;

    .appName {
      flex: 0 0 auto;
      margin-bottom: 4px;
      overflow: hidden;
      ${overflowWrap('break-word')};
      font-size: 18px;
      line-height: 26px;
      ${useProximaNovaSemibold};
      letter-spacing: 0.25px;
      color: #001138;
      font-weight: 600;
    }
  }
`;

export const AppPublisher = styled.div`
  flex: 0 0 auto;
  margin-bottom: 8px;
  font-size: 14px;
  ${overflowWrap('break-word')};
  letter-spacing: 0.25px;
  color: #001138;

  .publisherName {
    line-height: 24px;
    overflow: hidden;
    ${overflowWrap('break-word')};
    vertical-align: bottom;
  }

  .appPublisherIcon {
    height: 19px;
    margin-left: 5px;
    vertical-align: bottom;

    svg {
      height: 12px;
    }
  }
`;

export const ShortDes = styled.div`
  flex: 0 0 auto;
  line-height: 24px;
  overflow: hidden;
  ${overflowWrap('break-word')};
  font-size: 14px;
  color: #282829;
  letter-spacing: 0.25px;
`;

export const RatingWrapper = styled.div`
  margin-top: auto;
  padding-top: 12px;
`;
