import { googleAnalytics, isSSR, mixpanel, transport } from '@apw/core';
import { IUser } from '@apw/types';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import { IAdditionalAccountInfo } from '@drw/lib';
import { IExtension } from './extension.interface';
import * as userService from './user.service';

export class UserStore {
  @observable
  awaiting = true;

  @observable
  ssoLoading = false;

  @observable
  user: IUser | null = null;

  @observable
  extension: IExtension | null = null;

  @observable
  additionalAccountInfo: IAdditionalAccountInfo | null = null;

  shouldGoToRedirect = false;

  constructor() {
    reaction(
      () => this.user,
      (user: IUser) => {
        if (user) {
          mixpanel.identifyUser(user.extensionId);
        } else {
          mixpanel.resetMixpanel();
        }
      },
    );

    reaction(
      () => this.extension,
      (extension: IExtension) => {
        if (extension && this.isInternalUser(extension)) {
          mixpanel.registerSuperProperties({
            $ignore: 'true',
          });
        }
      },
    );

    reaction(
      () => this.additionalAccountInfo,
      (additionalAccountInfo: IAdditionalAccountInfo) => {
        if (additionalAccountInfo) {
          mixpanel.setHashedAccountId(additionalAccountInfo.hashedAccountId);
        }
      },
    );
  }

  @computed
  get isLoggedIn() {
    return !!this.user;
  }

  @action.bound
  async loadUser(isSSO = false) {
    if (isSSR()) return;

    try {
      const user = await transport.fetchUser();
      const extension = await transport.fetchExtension();
      const additionalAccountInfo =
        await transport.fetchAdditionalAccountInfo();

      runInAction(() => {
        this.user = user;
        this.extension = extension;
        this.additionalAccountInfo = additionalAccountInfo;
      });
    } catch (e) {
      if (mixpanel.isTrackingIgnored()) {
        mixpanel.resetMixpanel();
      }
      if (isSSO) {
        runInAction(() => {
          this.ssoLoading = true;
        });
        throw e;
      }
    } finally {
      runInAction(() => {
        this.awaiting = false;
      });
    }
  }

  @action.bound
  setUser(user: IUser | null) {
    this.user = user;
  }

  @action.bound
  setSSOLoading(loading: boolean) {
    this.ssoLoading = loading;
  }

  @action
  setExtension = (extension: IExtension | null) => {
    this.extension = extension;
  };

  @action.bound
  async logout() {
    this.shouldGoToRedirect = true;
    googleAnalytics.trackPageView('Logout');
    try {
      await userService.logout();
      // eslint-disable-next-line no-empty
    } catch (e) {
    } finally {
      runInAction(() => {
        this.setUser(null);
        this.setExtension(null);
      });
    }
  }

  signInViaCLW() {
    googleAnalytics.trackPageView('Sign In');
    mixpanel.trackPageView('Sign In (App Gallery)');
    userService.signInViaCLW();
  }

  onResolved = () => {
    return new Promise<void>((resolve) => {
      const disposer = reaction(
        () => this.awaiting,
        (awaiting) => {
          if (awaiting) {
            return;
          }

          disposer();
          resolve();
        },
        {
          fireImmediately: true,
        },
      );
    });
  };

  private isInternalUser(extensionInfo: IExtension): boolean {
    const emailPattern = [
      /^(.*)@(.*)\.?ringcentral\.com$/i,
      /^(.*)@(.*)\.?nordigy\.ru$/i,
      /^(.*)@(.*)\.?ab-soft\.net$/i,
    ];
    return emailPattern.some((re: RegExp) =>
      re.test(extensionInfo.contact.email),
    );
  }
}
