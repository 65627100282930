import React, { FC, ReactNode, useMemo, useState } from 'react';
import { InfoBorder } from '@ringcentral/juno-icon';
import {
  CloseButton,
  MobileContainer,
  MobileTooltipWrapper,
  StyledRcIcon,
  TooltipGlobalStyles,
  TooltipStyled,
} from './Tooltip.sc';

const mobileDeviceSize = 768;

export const Tooltip: FC<{
  trigger?: ReactNode;
  className?: string;
  title: ReactNode | string;
  classes?: any;
  automationId?: string;
  tabIndex?: number;
}> = ({ trigger, className, title, classes, automationId, tabIndex = -1 }) => {
  const [mobileVisible, setMobileVisible] = useState(false);

  const [openTooltip, setOpenTooltip] = useState(false);

  const [isOnFocusDisabled, setIsOnFocusDisabled] = useState(false);

  const isMobile = useMemo(() => {
    return window.innerWidth <= mobileDeviceSize;
  }, [window.innerWidth]);

  const showMobileTooltip = () => {
    if (isMobile) {
      setMobileVisible(true);
      return;
    }
    setOpenTooltip(true);
  };

  const onCloseTooltip = () => {
    setOpenTooltip(false);
  };

  const onMouseEnter = () => {
    setOpenTooltip(true);
  };

  const onFocus = () => {
    if (isOnFocusDisabled) {
      return;
    }

    showMobileTooltip();
  };

  return (
    <>
      <TooltipGlobalStyles />
      <TooltipStyled
        open={openTooltip}
        onClose={onCloseTooltip}
        className={className}
        title={<div data-test-automation-id={'tooltip-content'}>{title}</div>}
        classes={classes}
        data-test-automation-id={automationId}
      >
        {trigger ? (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <span
            onMouseEnter={onMouseEnter}
            onClick={() => showMobileTooltip()}
            tabIndex={tabIndex}
            role="button"
            aria-label="tooltip"
            onFocus={onFocus}
          >
            {trigger}
          </span>
        ) : (
          <StyledRcIcon
            onMouseEnter={onMouseEnter}
            onClick={() => showMobileTooltip()}
            symbol={InfoBorder}
            size="inherit"
            tabIndex={tabIndex}
            role="button"
            aria-label="tooltip"
            onFocus={onFocus}
          />
        )}
      </TooltipStyled>
      {mobileVisible && (
        <MobileTooltipWrapper open TransitionProps={{ appear: false }}>
          <MobileContainer>
            <CloseButton
              variant="plain"
              onClick={() => {
                setIsOnFocusDisabled(true);
                setMobileVisible(false);

                setTimeout(() => {
                  setIsOnFocusDisabled(false);
                });
              }}
              aria-label="close"
              tabIndex={0}
              data-test-automation-id="mobile-tooltip-close-button"
            >
              ×
            </CloseButton>
            {title}
          </MobileContainer>
        </MobileTooltipWrapper>
      )}
    </>
  );
};
