import { useEffect, useMemo } from 'react';
import { useApiErrorService } from '@apw/core/api/api-error.service';
import { AxiosError } from 'axios';
import { axiosInstance } from './api.service';

export const useAPIErrorHandling = () => {
  const apiErrorService = useApiErrorService();

  const interceptor = useMemo(
    () => ({
      response: (response) => response,
      error: (error: AxiosError) => {
        return apiErrorService.handleCommonErrors(error);
      },
    }),
    [],
  );

  useEffect(() => {
    const interceptors = axiosInstance.interceptors;
    const resInterceptor = interceptors.response.use(
      interceptor.response,
      interceptor.error,
    );

    return () => {
      interceptors.response.eject(resInterceptor);
    };
  }, [interceptor]);
};
