import { SitemapProperty } from './sitemap-property.enum';

export enum EntityType {
  PROFILE = 'profile',
  PAGE = 'page',
}

interface IProfileInCoveo {
  [SitemapProperty.ENTITY_TYPE]: EntityType.PROFILE;
  [SitemapProperty.LOGO_URL]: string;
  [SitemapProperty.SHORT_DESC]: string;
  [SitemapProperty.PROFILE_TYPE]?: string;
  [SitemapProperty.PUBLISHER]: string;
  [SitemapProperty.PROFILE_ID]: string;
  [SitemapProperty.PARTNER_BADGE]: string;
}

interface IPageInCoveo {
  [SitemapProperty.ENTITY_TYPE]: EntityType.PAGE;
  [SitemapProperty.PAGE_ID]: number;
  [SitemapProperty.PAGE_NAME]: string;
  [SitemapProperty.LOGO_URL]: string;
  [SitemapProperty.SHORT_DESC]: string;
}

export enum CoveoResultProperty {
  Uri = 'uri',
  Raw = 'raw',
}

export interface ICoveoResult<T> {
  [CoveoResultProperty.Uri]: string;
  [CoveoResultProperty.Raw]: T;
}

export interface ICoveoResponse<T> {
  results: ICoveoResult<T>[];
  totalCount: number;
}

export type ICoveoResponseForProfiles = ICoveoResponse<IProfileInCoveo>;
export type ICoveoResponseForPages = ICoveoResponse<IPageInCoveo>;

export type ICoveoResultForProfile = ICoveoResult<IProfileInCoveo>;
export type ICoveoResultForPage = ICoveoResult<IPageInCoveo>;

export interface ICoveoRequestForProfiles {
  brand: string;
  keyword: string;
  product?: string;
  category?: string;
  paging: {
    page: number;
    perPage: number;
  };
}

export interface ICoveoRequestForPages {
  brand: string;
  keyword: string;
  paging: {
    page: number;
    perPage: number;
  };
}
