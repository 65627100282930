import React, {
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { RcIconButton, styled } from '@ringcentral/juno';
import { ChevronLeft, ChevronRight } from '@ringcentral/juno-icon';
import { EMPTY, fromEvent } from 'rxjs';
import { Breakpoint, ResizeObservable, useCarousel } from '../../hooks';
import { useSubscribe } from '../../hooks/useSubscribe';

const PositionContainer = styled.div`
  position: relative;
`;

const ViewWindow = styled.div`
  overflow: hidden;
`;

const ActualWindow = styled.div`
  transition: transform 0.5s cubic-bezier(0.1, 0.57, 0.1, 1);
  white-space: nowrap;
  font-size: 0;
  touch-action: pan-y;
  &.no-transition {
    transition: transform 0s;
  }
`;

const Slide = styled.div`
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
`;

const ArrowBtn = styled(RcIconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const ArrowLeftBtn = styled(ArrowBtn)`
  left: -64px;
`;

const ArrowRightBtn = styled(ArrowBtn)`
  right: -64px;
`;

const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

const Indicator = styled.div`
  height: 9px;
  width: 9px;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: rgb(40, 40, 40);
  margin-right: 16px;
  cursor: pointer;

  &.active {
    border: 2px solid rgb(0, 17, 56);
    background-color: white;
  }
`;

export interface CarouselBreakpoint extends Breakpoint {
  gap: number;
  shift: number;
}

export interface CarouselBreakpoints {
  [key: number]: CarouselBreakpoint;
}

interface ICarouselTranslation {
  previousBtn: string;
  nextBtn: string;
}

export interface CarouselProps {
  breakpoints?: CarouselBreakpoints;
  resize?: ResizeObservable;
  initialIndex?: number;
  arrow?: boolean | ((pageTotal) => boolean);
  indicator?: boolean | ((pageTotal) => boolean);
  interval?: number;
  loop?: boolean;
  draggable?: boolean;
  keyboard?: boolean;
  change?: Function;
  click?: Function;
  translation?: ICarouselTranslation;
  children: ReactNode | ReactNode[];
}

export interface CarouselRef {
  next: () => void;
  previous: () => void;
  go: (i) => void;
}

const Carousel = React.forwardRef<CarouselRef, CarouselProps>(
  (
    {
      breakpoints = {},
      resize = 'window',
      initialIndex = 1,
      arrow = false,
      indicator = false,
      interval = 0,
      loop = false,
      draggable = false,
      keyboard = false,
      click = () => {},
      translation = {},
      children,
    },
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      next,
      previous,
      go,
    }));

    const dragRef = useRef(null);
    const resizeRef = useRef(null);
    const {
      index,
      pageIndex,
      pageTotal,
      breakpoint: { size, gap, shift },
      canPrevious,
      canNext,
      go,
      next,
      previous,
      slides,
    } = useCarousel({
      breakpoints,
      resize,
      resizeRef,
      initialIndex,
      interval,
      draggable,
      keyboard,
      dragRef,
      loop,
      throttleTime: 500,
      children,
    });

    const ratio = useMemo(() => 100 / size, [size]);
    const offset = useMemo(() => -(index - 1) * ratio, [index, ratio]);
    const showArrow = useMemo(() => {
      return typeof arrow === 'function' ? arrow(pageTotal) : arrow;
    }, [arrow, pageTotal]);

    const showIndicator = useMemo(() => {
      return typeof indicator === 'function' ? indicator(pageTotal) : indicator;
    }, [indicator, pageTotal]);

    const actualRef = useRef(null);
    const [enableTransition, setEnableTransition] = useState(false);

    useSubscribe(
      () => {
        if (!loop || !actualRef.current) return EMPTY;
        return fromEvent(actualRef.current!, 'transitionend');
      },
      () => {
        const count = React.Children.count(children);
        const handle = (index) => {
          setEnableTransition(false);
          go(index);
        };
        if (index <= count) handle(index + count);
        if (index > count * 2) handle(index - count);
      },
      [actualRef.current],
    );

    useEffect(() => {
      if (enableTransition) return;
      setTimeout(() => setEnableTransition(true));
    }, [enableTransition]);

    return (
      <>
        <PositionContainer ref={resizeRef}>
          {/* breakpoint compute must after the "resizeRef" dom render */}
          {resizeRef.current && (
            <>
              <ViewWindow ref={dragRef} data-test-automation-id="view-window">
                <ActualWindow
                  ref={actualRef}
                  className={enableTransition ? '' : 'no-transition'}
                  style={{
                    transform: `
                      translateX(${offset}%)
                      translateX(${shift ? gap + shift : 0}px)
                    `,
                    width: `calc(
                      100% + ${shift ? -gap : gap}px - ${shift * 2}px
                    )`,
                  }}
                  data-test-automation-id="carouselActualWindow"
                  role="list"
                >
                  {slides.map((slide, index) => (
                    <Slide
                      style={{
                        width: `${ratio}%`,
                        paddingRight: `${gap}px`,
                      }}
                      onClick={() => click(index + 1)}
                      data-test-automation-id={`carouselSlide-${index}`}
                      role="listitem"
                    >
                      {slide}
                    </Slide>
                  ))}
                </ActualWindow>
              </ViewWindow>
              {showArrow && (
                <>
                  <ArrowLeftBtn
                    symbol={ChevronLeft}
                    size="small"
                    onClick={previous}
                    disabled={!canPrevious}
                    data-test-automation-id="carouselPreviousBtn"
                    aria-label={translation?.previousBtn || `previous button`}
                  />
                  <ArrowRightBtn
                    symbol={ChevronRight}
                    size="small"
                    onClick={next}
                    disabled={!canNext}
                    data-test-automation-id="carouselNextBtn"
                    aria-label={translation?.nextBtn || `next button`}
                  />
                </>
              )}
            </>
          )}
        </PositionContainer>
        {showIndicator && resizeRef.current && (
          <IndicatorContainer data-test-automation-id="carouselIndicator">
            {Array.from(Array(pageTotal)).map((_, index) => {
              return (
                <Indicator
                  className={pageIndex === index + 1 ? 'active' : ''}
                  onClick={() => go(index + 1)}
                  data-test-automation-id={`carouselIndicator-${index}`}
                />
              );
            })}
          </IndicatorContainer>
        )}
      </>
    );
  },
);

export default Carousel;
