import { overridesFactory } from '@apw/shared/theme/utils/overrides.factory';
import { paletteFactory } from '@apw/shared/theme/utils/palette.factory';
import { RcThemeInput } from '@ringcentral/juno';

export const attColor = '#0A6EBE';

export const attTheme: RcThemeInput = {
  palette: paletteFactory(attColor),
  overrides: overridesFactory(attColor),
};
