import { transport } from '@apw/core';
import { post } from '@apw/core/api/api.service';
import { IPagePreviewRequest, IPagePreviewResponse } from './typings';

export const loadGalleryPageForPreview = (
  request: IPagePreviewRequest,
): Promise<IPagePreviewResponse> => {
  const endpoint = '/api/app/gallery-page-preview';
  return post<IPagePreviewResponse>(endpoint, request);
};

export const resolveGalleryPageForPreview = (
  request: IPagePreviewRequest,
): Promise<string> => {
  return transport.fetchGalleryPageForPreview(request).then((res) => {
    if (res) {
      return res.entity;
    }
    return Promise.reject();
  });
};
