import { RatingItemLabel } from '@apw/components/ratingAndReview/qualityRating/ratingItemLabel/RatingItemLabel';
import { styled } from '@ringcentral/juno';

export const Container = styled.div`
  display: flex;
  align-items: center;

  &&& {
    .MuiRating-decimal {
      margin: 0 4px 0 0;
    }
    .RcRating-icon div {
      width: 28px;
      height: 10px;
    }
  }
`;

export const StyledLabel = styled(RatingItemLabel)`
  margin-top: 4px;
  margin-left: 20px;
  padding: 4px 0;

  &&& {
    svg {
      font-size: 16px;
    }
  }
`;
