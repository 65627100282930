import { api } from '@apw/core';
import { countryDic } from '@apw/core/country';
import { buildUserFormikConfig, IGuestRequest } from '@apw/modules/profile';
import * as _ from 'lodash';

export const buildGuestFormikConfig = () => {
  return buildUserFormikConfig();
};

export const submitGuestForm = (appProfileId: string, values: any) => {
  const data = Object.assign(_.clone(values), {
    appProfileId,
    country: countryDic[values.country].name,
  }) as IGuestRequest;
  const endpoint = '/api/login-gate-guest-form';
  return api.post<any>(`${endpoint}`, data, {
    useGlobalLoading: true,
    responseType: 'text',
  });
};
