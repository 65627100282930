import {
  BlockType,
  IPaging,
  IProfileInContentBlock,
  IProfileInContentBlockFromApi,
} from '@apw/lib/types';

export interface ICollectionBlock {
  blockType: BlockType.Collection;
  title: string;
  subTitle?: string;
  collectionId: number;
  displayCount: number;
  profiles: {
    records: IProfileInContentBlockFromApi[];
    paging: IPaging;
  };
  appList: IProfileInContentBlock[];
  loading?: boolean;
}

export enum DisplayOnAppsEnum {
  All = 9999,
}
