import { onMobile, useProximaNovaRegular } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const PremierPartnerContainer = styled.span`
  display: flex;
  margin-left: 8px;

  ${onMobile} {
    display: flex !important;
    margin-top: 7px;
    margin-left: 0;
  }
`;

export const PremierPartnerIcon = styled.span`
  display: flex;
  align-items: center;

  svg {
    font-size: 18px;
    ${onMobile} {
      font-size: 14px;
    }
  }
`;

export const PremierPartnerText = styled.span`
  display: inline-block;
  margin-left: 8px;
  font-size: 16px;
  vertical-align: baseline;
  color: #686869;
  ${useProximaNovaRegular};

  ${onMobile} {
    margin-left: 4px;
    font-size: 12px;
  }
`;
