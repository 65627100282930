import {
  onMobile,
  removeRcMenuScrollEffectOnMobile,
  useProximaNovaRegular,
} from '@apw/core/sc';
import {
  HOVER_OPACITY,
  PRESSED_OPACITY,
  SELECTED_OPACITY,
} from '@apw/shared/theme';
import { css, palette2, styled } from '@ringcentral/juno';
import { NavLink } from 'react-router-dom';

const FONT_SIZE = 16;
const rcMenuItemMixin = (content) => css`
  [role='menuitem'] {
    ${content}
    ${onMobile} {
      height: auto !important;
      padding: 8px 20px !important;
      font-size: 18px !important;
      border-radius: 2.34px;
    }
  }
`;

export const NavItem = styled(NavLink).attrs(() => ({
  activeClassName: 'active',
}))`
  & {
    [role='menuitem'] {
      &:hover {
        background-color: ${palette2('text', 'primary', HOVER_OPACITY)};
      }
      &:active {
        color: #282b33;
        background-color: ${palette2('text', 'primary', PRESSED_OPACITY)};
      }
    }
  }
  &.active {
    [role='menuitem'] {
      background-color: ${palette2('text', 'primary', SELECTED_OPACITY)};
    }
  }

  border-radius: 2px;
  text-decoration: none;
  display: block;
  color: #282b33;

  ${rcMenuItemMixin(css`
    ${useProximaNovaRegular};
    font-size: ${FONT_SIZE}px !important;
    height: 40px !important;
    text-overflow: ellipsis;
    display: block;
    line-height: 32px !important;
    padding: 4px 16px;
  `)};

  ${onMobile} {
    -webkit-tap-highlight-color: transparent;
    [role='menuitem'] {
      ${removeRcMenuScrollEffectOnMobile}
    }
  }
`;
