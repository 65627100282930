import { BPS, respondTo, onMobile } from '@apw/core/sc';
import { css, RcIcon, styled } from '@ringcentral/juno';
import { Link } from 'react-router-dom';

const featureGap = 12;
export const Block = styled.div`
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Content = styled.div`
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  ${onMobile} {
    padding: 16px;
  }
`;

export const Mask = styled.div`
  border-radius: 8px;
  display: none;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 17, 56, 0.04);
  pointer-events: none;
`;

export const Sponsored = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  position: absolute;
  top: 16px;
  right: 16px;
  background: rgb(85, 213, 134);
  border-radius: 12px;
  height: 20px;
  width: 104px;

  ${onMobile} {
    top: 12px;
    right: 12px;
    height: 20px;
    font-size: 12px;
    width: 104px;
    border-radius: 8px;
  }
`;

export const FeatureTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FeatureTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: rgb(0, 17, 56);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 6px;

  ${onMobile} {
    font-size: 20px;
  }
`;

export const ExternalLink = styled(RcIcon)`
  width: 16px;
  ${onMobile} {
    width: 14px;
  }
`;

export const FeatureFrontText = styled.div`
  margin-top: 8px;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: rgb(40, 40, 41);
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 176px;
  ${onMobile} {
    height: 168px;
  }
`;

export const FeatureImage = styled.div`
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
`;

const mixin = css`
  display: inline-block;
  position: relative;
  margin: 0 ${featureGap}px ${featureGap}px 0;
  border: 1px solid rgb(225, 230, 234);
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;

  ${respondTo(BPS.ltTablet)} {
    &:hover ${Mask} {
      display: block;
      z-index: 1;
    }
  }

  ${onMobile} {
    width: 288px;
    min-width: 0;
    margin-bottom: 16px;
  }
`;
export const FeatureContainerByATag = styled.a`
  ${mixin}
`;

export const FeatureContainerByLink = styled(Link)`
  ${mixin}
`;

export const containerMixin = css`
  margin-right: -${featureGap}px;

  ${respondTo(996)} {
    ${FeatureContainerByATag}, ${FeatureContainerByLink} {
      width: calc(33.33% - ${featureGap}px);
    }
  }

  ${respondTo([480, 995])} {
    ${FeatureContainerByATag}, ${FeatureContainerByLink} {
      width: calc(50% - ${featureGap}px);
    }

    ${FeatureFrontText} {
      -webkit-line-clamp: 2;
    }
  }

  ${respondTo([0, 480])} {
    ${FeatureContainerByATag}, ${FeatureContainerByLink} {
      width: calc(100% - ${featureGap}px);
    }
  }
`;

export const FlexContainer = styled.div`
  ${containerMixin};
  display: flex;

  ${FeatureContainerByATag}, ${FeatureContainerByLink} {
    flex-grow: 1;
  }

  ${respondTo([0, 1200])} {
    flex-wrap: wrap;
  }
`;

export const PercentContainer = styled.div`
  ${containerMixin};
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
`;

export const RatingContainer = styled.div`
  margin-top: auto;
  padding-top: 12px;
`;
