import React from 'react';
import { getCompatibilitySearchPath } from '@apw/routes/getCompatibilityPaths';
import { useCompatibilitySearchPath } from '@apw/routes/useCompatibilityPaths';
import { HeaderBrand } from '@apw/shared/header/shared/headerBrand/HeaderBrand';
import { StyledPrimaryNavigation } from '@apw/shared/menu/mobileHeader/styledPrimaryNavigation';
import { CompatibilitySelector } from '@apw/shared/navigation';
import { useStores } from '@apw/stores';
import { ICompatibility } from '@apw/stores/compatibility';
import { useTheme } from '@material-ui/core/styles';
import { Close } from '@ringcentral/juno-icon';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ToLoginContainer,
  Button,
  LoginStatusContainer,
  UserName,
  NameWrapper,
  Logout,
  HeaderMenu,
  Header,
  CloseIcon,
  primaryNavigationContainer,
  primaryNavigationMenuItem,
  StatusContainer,
} from './mobileHeader.sc';

const ToLogin = ({ userStore }) => {
  return (
    <ToLoginContainer>
      <Button
        color="text.primary"
        variant="outlined"
        onClick={userStore.signInViaCLW}
        data-test-automation-id="login-btn"
      >
        Sign in to your App Gallery
      </Button>
    </ToLoginContainer>
  );
};

const LoginStatus = ({ userStore }) => {
  const theme = useTheme();
  const handleLogout = () => {
    userStore.shouldGoToRedirect = true;
    userStore.logout();
  };

  return (
    <LoginStatusContainer data-test-automation-id="mobile-login-status">
      <UserName
        expandIcon
        aria-label={`current logged in user is ${userStore.extension?.name}`}
        data-test-automation-id="user-name"
      >
        <NameWrapper style={{ color: theme.palette.text.primary }}>
          {userStore.extension?.name}
        </NameWrapper>
      </UserName>
      <Logout
        onClick={handleLogout}
        tabIndex={0}
        role="button"
        data-test-automation-id="login-out"
      >
        <span style={{ color: theme.palette.text.primary }}>Sign out</span>
      </Logout>
    </LoginStatusContainer>
  );
};

export const MobileHeader = (props) => {
  const { toggleMenu, className } = props;
  const { userStore } = useStores();
  const location = useLocation();
  const history = useHistory();

  const isSearchPage =
    useCompatibilitySearchPath() === location.pathname.replace(/\/$/, '');

  const onCompatibilityChanged = isSearchPage
    ? (compatibility: ICompatibility) => {
        const newLocation = `${getCompatibilitySearchPath(compatibility.slug)}${
          location.search
        }`;

        history.push(newLocation);
      }
    : undefined;

  return (
    <HeaderMenu className={className} data-test-automation-id="header-menu">
      <Header>
        <HeaderBrand
          logoClick={toggleMenu}
          data-test-automation-id="header-brand-link"
        />
        <CloseIcon
          onClick={toggleMenu}
          symbol={Close}
          role="button"
          tabIndex={0}
          aria-label="close"
          data-test-automation-id="header-close-icon"
        />
      </Header>
      <CompatibilitySelector onCompatibilityChanged={onCompatibilityChanged} />
      <StyledPrimaryNavigation
        onNavigated={toggleMenu}
        cssProp={{
          container: primaryNavigationContainer,
          menuItem: primaryNavigationMenuItem,
        }}
        data-test-automation-id="primary-navigation"
      />
      <StatusContainer>
        {!userStore.isLoggedIn && <ToLogin userStore={userStore} />}
        {userStore.isLoggedIn && <LoginStatus userStore={userStore} />}
      </StatusContainer>
    </HeaderMenu>
  );
};
