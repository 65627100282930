import React, { useContext } from 'react';
import { ProfileTabKeysEnum } from '@apw/lib/components/profileDetail';
import { StyledButton } from '@apw/lib/components/profileDetail/profileBody/overviewTab/ratingsAndReviews/ratingsSection/viewReviewsButton/viewReviewsButton.sc';
import { StoreContext } from '@apw/lib/components/profileDetail/StoreContext';

export const ViewReviewButton = () => {
  const profileVM = useContext(StoreContext);
  const { translation } = useContext(StoreContext);

  const onClick = () => {
    profileVM.setSelectedTab(ProfileTabKeysEnum.RATINGS_AND_REVIEWS);
    setTimeout(() => {
      const profileBody = document.getElementById('profile-body');
      if (profileBody) {
        profileBody.scrollTo(0, 280);
      }
    }, 100);
  };

  return (
    <StyledButton
      size="xlarge"
      variant="plain"
      color="action.primary"
      data-test-automation-id={'ratings-section-view-reviews'}
      onClick={onClick}
    >
      {translation.ratingsAndReviews.actions.viewReviewsButton}
    </StyledButton>
  );
};
