import React, { ReactElement, useRef, useState } from 'react';
import { ObserverScroll } from '@apw/components/ObserverScroll/ObserverScroll';
import { observer } from 'mobx-react';
import { TriggerWrap, Container } from './popover.sc';
import { PopoverExternalControl, PopoverTriggerGenerator } from './typings';

interface PopoverProps {
  getTrigger: PopoverTriggerGenerator;
  children: ReactElement;
  className?: string;
  anchorOrigin?: any;
  transformOrigin?: any;
  initExternalControl?: (control: PopoverExternalControl) => void;
  expandPopover?: boolean;
}

const defaultAnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const defaultTransformOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

export const Popover = observer((props: PopoverProps) => {
  const {
    getTrigger,
    children,
    className = '',
    anchorOrigin = defaultAnchorOrigin,
    transformOrigin = defaultTransformOrigin,
    initExternalControl,
    expandPopover = false,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [height, setHeight] = useState(0);
  const triggerRef = useRef<HTMLDivElement>(null);

  const showPopover = (event) => {
    if (triggerRef && triggerRef.current) {
      setAnchorEl(event.currentTarget);
      setPopoverHeight();
    }
  };

  const setPopoverHeight = () => {
    if (triggerRef && triggerRef.current) {
      const rect = triggerRef?.current?.getBoundingClientRect();
      const popoverH = window.innerHeight - (rect.top + rect.height);
      setHeight(popoverH);
    }
  };

  const hidePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (initExternalControl) {
    initExternalControl({
      hidePopover,
    });
  }

  const shouldExpandPopover = () => {
    return expandPopover;
  };

  return (
    <ObserverScroll callback={setPopoverHeight} activeObserver={open}>
      <TriggerWrap
        ref={triggerRef as any}
        className="popoverTriggerWrap"
        data-test-automation-id="popover-trigger-wrap"
      >
        {getTrigger(showPopover, open)}
      </TriggerWrap>
      <Container
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={hidePopover}
        className={className}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        style={
          shouldExpandPopover()
            ? { transform: 'translate(0, 16px)' }
            : undefined
        }
        data-test-automation-id="popover-container"
      >
        <div style={shouldExpandPopover() ? { height } : undefined}>
          {children}
        </div>
      </Container>
    </ObserverScroll>
  );
});
