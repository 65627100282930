(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("lodash"), require("@ringcentral/juno"), require("react-router-dom"), require("mobx-react"), require("mobx"), require("moment"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "lodash", "@ringcentral/juno", "react-router-dom", "mobx-react", "mobx", "moment"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("lodash"), require("@ringcentral/juno"), require("react-router-dom"), require("mobx-react"), require("mobx"), require("moment")) : factory(root["react"], root["lodash"], root["@ringcentral/juno"], root["react-router-dom"], root["mobx-react"], root["mobx"], root["moment"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__467__, __WEBPACK_EXTERNAL_MODULE__5675__, __WEBPACK_EXTERNAL_MODULE__6128__, __WEBPACK_EXTERNAL_MODULE__5264__, __WEBPACK_EXTERNAL_MODULE__259__, __WEBPACK_EXTERNAL_MODULE__3192__) => {
return 