import { RcThemeInput } from '@ringcentral/juno';
import {
  overridesFactory,
  paletteFactory,
} from '../../../../../shared/theme/utils';

export const vodafoneColor = '#E60000';

export const vodafoneTheme: RcThemeInput = {
  palette: paletteFactory(vodafoneColor),
  overrides: overridesFactory(vodafoneColor),
};
