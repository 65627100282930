import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/es/symbol';
import React from 'react';
import { RootContextProvider } from '@apw/stores';
import { enableES5 } from 'immer';
import { hydrate, render } from 'react-dom';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './core/onetrust';

enableES5();

const rootElement = document.getElementById('root');

const Root = () => (
  <React.StrictMode>
    <Helmet defaultTitle="RingCentral App Gallery" />
    <StyleSheetManager disableCSSOMInjection>
      <Router basename="/apps">
        <RootContextProvider>
          <App />
        </RootContextProvider>
      </Router>
    </StyleSheetManager>
  </React.StrictMode>
);

if (rootElement!.hasChildNodes()) {
  hydrate(<Root />, rootElement);
} else {
  render(<Root />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
