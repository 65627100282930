import React, { useContext } from 'react';
import { ProfileViewModelContext } from '@apw/components/profileDetail/Profile.ViewModel.context';
import { StyledButton } from '@apw/components/ratingAndReview/actionButtons/signInButton/signInButton.sc';
import { useStores } from '@apw/stores';
import { signIn } from '@apw/stores/user';

export const SignInButton = ({ fullWidth }) => {
  const profileViewModel = useContext(ProfileViewModelContext);
  const { userStore } = useStores();
  const { compatibilitiesStore } = useStores();

  const onAfterSignIn = () => {
    const user = userStore.user;
    const profile = profileViewModel.profile;

    if (!user || !profile) {
      return;
    }

    const userGalleryBrand =
      compatibilitiesStore.getCompatibilityByIdOrSubBrandId(user.brandId)?.id;

    const currentGalleryBrand = profile.brand;

    if (userGalleryBrand !== currentGalleryBrand) {
      return;
    }

    profileViewModel.openRatingPopup();
  };

  const signInCallback = () => {
    userStore.loadUser().then(() => {
      onAfterSignIn();
    });
  };

  const onClick = () => {
    signIn(signInCallback);
  };

  return (
    <StyledButton
      size="xlarge"
      variant="outlined"
      color="action.primary"
      radius="lg"
      fullWidth={fullWidth}
      onClick={onClick}
      data-test-automation-id={'sign-in-write-review-button'}
    >
      Sign in to write a review
    </StyledButton>
  );
};
