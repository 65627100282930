import { api } from '@apw/core';
import { CountryEnum } from '@apw/core/country/country.enum';
import { IState } from '@apw/core/country/state.interface';
import * as _ from 'lodash';
import { ICountry } from './country.interface';

let countryPromise: Promise<ICountry[]>;
let countries: ICountry[];
const statesForCountries: { [countryId: string]: IState[] } = {};
export const countryDic = {};

export const getCountries = (): Promise<ICountry[]> => {
  if (countries) {
    return Promise.resolve(countries);
  }
  return fetchCountries().then(() => {
    return Promise.resolve(countries);
  });
};

export const getCountryById = (id: string): Promise<ICountry> => {
  if (!_.isEmpty(countryDic)) {
    return Promise.resolve(countryDic[id]);
  }
  return fetchCountries().then(() => {
    return Promise.resolve(countryDic[id]);
  });
};

export const getCountryByName = (
  name: string,
): Promise<ICountry | undefined> => {
  if (!_.isEmpty(countryDic)) {
    return Promise.resolve(_.find(countries, { name }));
  }
  return fetchCountries().then(() => {
    return Promise.resolve(_.find(countries, { name }));
  });
};

export const getStates = (countryId: string): Promise<IState[]> => {
  const cachedStates = statesForCountries[countryId];
  if (Array.isArray(cachedStates)) {
    return Promise.resolve(cachedStates);
  }

  return fetchStates(countryId).then(() => {
    return Promise.resolve(statesForCountries[countryId]);
  });
};

const fetchCountries = () => {
  if (countryPromise) {
    return initCountryData(countryPromise);
  }

  const endpoint = '/api/countries';
  countryPromise = api.get<ICountry[]>(endpoint);
  return initCountryData(countryPromise);
};

const fetchStates = (countryId) => {
  const endpoint = '/api/states';
  return api
    .get<IState[]>(endpoint, {
      params: {
        countryId,
      },
    })
    .then((states) => {
      statesForCountries[countryId] = states;
    });
};

const initCountryData = (promise: Promise<ICountry[]>) => {
  return promise.then((rawCountries) => {
    initCountries(rawCountries);
    initCountryDic(rawCountries);
  });
};

const initCountries = (rawCountries: ICountry[]): void => {
  const topCountryIds = [
    CountryEnum.UNITED_STATES,
    CountryEnum.UK,
    CountryEnum.CANADA,
    CountryEnum.AU,
  ].map((country) => country.toString());

  const topCountries = new Array<ICountry>(topCountryIds.length);
  const otherCountries = [] as ICountry[];
  rawCountries.forEach((country) => {
    const index = topCountryIds.indexOf(country.id);
    if (index > -1) {
      topCountries.splice(index, 1, country);
    } else {
      otherCountries.push(country);
    }
  });
  countries = topCountries.concat(otherCountries);
};

const initCountryDic = (rawCountries: ICountry[]) => {
  rawCountries.forEach((country) => {
    countryDic[country.id] = country;
  });
};
