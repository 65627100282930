import { RcThemeInput } from '@ringcentral/juno';
import {
  overridesFactory,
  paletteFactory,
} from '../../../../../shared/theme/utils';

export const frontierColor = '#D9272D';

export const frontierTheme: RcThemeInput = {
  palette: paletteFactory(frontierColor),
  overrides: overridesFactory(frontierColor),
};
