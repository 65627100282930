import { onMobile } from '@apw/core/sc';
import { RcPopover, styled } from '@ringcentral/juno';

export const TriggerWrap = styled.div`
  width: 100%;
`;

export const Container = styled(RcPopover)`
  [class~='MuiPopover-paper'] {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1) !important;
    ${onMobile} {
      max-width: none;
      width: 100vw;
      left: 0 !important;
    }
  }
`;
