import { onMobile } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const SlideBackground = styled.div`
  background: rgb(243, 243, 243);
  border-radius: 8px;
  padding: 22px 16px;

  ${onMobile} {
    padding: 13px 8px;
  }
`;

export const Mask = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;
