import { useState } from 'react';
import { isEqual } from 'lodash';
import * as qs from 'qs';
import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const { search } = useLocation();
  const [queryParams, setQueryParams] = useState<any>({});
  const newQueryParams = qs.parse(search, { ignoreQueryPrefix: true });

  if (isEqual(newQueryParams, queryParams)) {
    return queryParams;
  }

  setQueryParams(newQueryParams);
  return newQueryParams;
};
