import { palette2, styled } from '@ringcentral/juno';
import { OverallRating } from '@apw/lib/components/ratingAndReview/overallRating/OverallRating';
import { JUNO_THEME_PREFIX } from '@apw/lib/constants';
import {
  onLtMidScreen,
  onLtSmScreen,
  onMidScreen,
  onMobile,
} from '@apw/lib/sc';

export const Container = styled.div`
  margin-top: 40px;
  padding-bottom: 36px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${onLtMidScreen} {
    margin-top: 20px;
    flex-direction: column;
  }
`;

export const RatingsWidgetContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${onLtSmScreen} {
    flex-direction: column;
  }
`;

export const StyledOverallRating = styled(OverallRating)`
  &&& {
    margin-right: 108px;
    padding-top: 10px;
    padding-bottom: 0;

    // TODO: Use Component API instead
    > .${JUNO_THEME_PREFIX}-MuiTypography-root {
      font-weight: 700;
    }

    ${onMidScreen} {
      margin-right: 70px;
    }

    ${onLtMidScreen} {
      margin-left: 0;
      margin-right: 40px;
      padding-top: 0;
    }

    ${onMobile} {
      margin-right: 0;
      padding-bottom: 24px;
      border-bottom: 1px solid ${palette2('neutral', 'l02')};
      width: 100%;
    }

    > p {
      font-size: 1rem;
      font-weight: 700;
      line-height: 24px;
    }

    > div {
      margin-bottom: 4px;

      .star {
        font-size: 16px;
      }

      .${JUNO_THEME_PREFIX}-MuiLinearProgress-root {
        width: 250px;
        margin-right: 12px;
      }
    }
  }
`;
