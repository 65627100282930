import React from 'react';
import { observer } from 'mobx-react';
import { CategoryFilter } from './categoryFilter';
import { Title } from './FilterSideBar.sc';
import { ProductFilter } from './productFilter';

export const FilterSideBar = observer(() => {
  return (
    <React.Fragment>
      <Title data-test-automation-id="filter-side-bar-title">
        FILTER OPTIONS
      </Title>
      <ProductFilter />
      <CategoryFilter />
    </React.Fragment>
  );
});
