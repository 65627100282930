import {
  useProximaNovaSemibold,
  onMobile,
  FontColor,
  FontWeight,
} from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const SectionTitle = styled.div`
  font-size: 22px;
  color: ${FontColor.black};
  font-weight: ${FontWeight.bold};
  margin-bottom: 20px;
  ${useProximaNovaSemibold};
  ${onMobile} {
    font-size: 20px;
    margin: 12px 0 20px 0;
  }
`;

export const List = styled.div`
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
`;
