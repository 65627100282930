import React, { useEffect, useState } from 'react';
import { RcLink } from '@ringcentral/juno';
import { isString } from 'lodash';
import { Wrapper, SectionTitle, SectionDesc } from './UserApps.sc';

export const UserApps = ({ url = '' }) => {
  const [onlineAccountPortalUrl, setOnlineAccountPortalUrl] = useState('');

  useEffect(() => {
    if (isString(url)) {
      const matches = url.match(/^https?:\/\/[^/]+/i);
      if (matches) {
        setOnlineAccountPortalUrl(
          `${matches[0]}/application/extension/tools/authorizedApps`,
        );
      }
    }
  }, [url]);

  return (
    <Wrapper data-test-automation-id="userApps">
      <SectionTitle data-test-automation-id="userAppsTitle">
        Authorized apps
      </SectionTitle>
      <SectionDesc data-test-automation-id="userAppsDesc">
        To review the list of apps that you have authorized to access your user
        data, please login and view them in the&nbsp;
        <RcLink
          href={onlineAccountPortalUrl}
          target={`_blank`}
          data-test-automation-id="userAppsLink"
        >
          <span>Admin Console</span>
        </RcLink>
        .
      </SectionDesc>
    </Wrapper>
  );
};
