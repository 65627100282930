// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.74.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.74.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".certifiedBadge_wrapper__1oQ4o{font-size:12px;font-weight:bold;letter-spacing:0;line-height:16px}.certifiedBadge_certifiedIcon__30LuQ{vertical-align:bottom}.certifiedBadge_certifiedIcon__30LuQ svg{width:16px;height:16px}.certifiedBadge_certifiedText__2RQ6-{margin-left:4px}", "",{"version":3,"sources":["webpack://./src/components/certifiedBadge/certifiedBadge.module.scss"],"names":[],"mappings":"AAAA,+BAAS,cAAc,CAAC,gBAAgB,CAAC,gBAAgB,CAAC,gBAAgB,CAAC,qCAAe,qBAAqB,CAAC,yCAAmB,UAAU,CAAC,WAAW,CAAC,qCAAe,eAAe","sourcesContent":[".wrapper{font-size:12px;font-weight:bold;letter-spacing:0;line-height:16px}.certifiedIcon{vertical-align:bottom}.certifiedIcon svg{width:16px;height:16px}.certifiedText{margin-left:4px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "certifiedBadge_wrapper__1oQ4o",
	"certifiedIcon": "certifiedBadge_certifiedIcon__30LuQ",
	"certifiedText": "certifiedBadge_certifiedText__2RQ6-"
};
export default ___CSS_LOADER_EXPORT___;
