// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.74.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.74.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".htmlBlock_wrapper__G6RSL{width:100%;margin-bottom:32px;overflow:auto}.htmlBlock_wrapper__G6RSL:last-child{margin-bottom:0}.htmlBlock_wrapper__G6RSL a:not(:focus-visible){text-decoration:none}.htmlBlock_wrapper__G6RSL a:hover{text-decoration:underline}.htmlBlock_wrapper__G6RSL a:focus-visible{outline:0;box-shadow:0 0 0 .25rem rgba(49,132,253,.5)}.htmlBlock_wrapper__G6RSL img{max-width:100%}", "",{"version":3,"sources":["webpack://./src/components/pageContent/htmlBlock/htmlBlock.module.scss"],"names":[],"mappings":"AAAA,0BAAS,UAAU,CAAC,kBAAkB,CAAC,aAAa,CAAC,qCAAoB,eAAe,CAAC,gDAA+B,oBAAoB,CAAC,kCAAiB,yBAAyB,CAAC,0CAAyB,SAAS,CAAC,2CAA2C,CAAC,8BAAa,cAAc","sourcesContent":[".wrapper{width:100%;margin-bottom:32px;overflow:auto}.wrapper:last-child{margin-bottom:0}.wrapper a:not(:focus-visible){text-decoration:none}.wrapper a:hover{text-decoration:underline}.wrapper a:focus-visible{outline:0;box-shadow:0 0 0 .25rem rgba(49,132,253,.5)}.wrapper img{max-width:100%}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "htmlBlock_wrapper__G6RSL"
};
export default ___CSS_LOADER_EXPORT___;
