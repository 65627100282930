import React, {
  Context,
  FC,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  IAfterDialogClosed,
  ICloseDialog,
  IDialogController,
  IDialogRef,
  InformDialog,
  InformDialogAction,
  InformDialogOptions,
} from '../../index';

export const withInformDialog = (
  Component: FC,
  DialogContext: Context<any>,
): FC => {
  return (props) => {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState<string>('');
    const [content, setContent] = useState<any>('');
    const [actions, setActions] = useState<InformDialogAction[]>([]);
    const [afterClosed, setAfterClosed] = useState<
      IAfterDialogClosed | undefined
    >();
    const [hiddenCloseIcon, setHiddenCloseIcon] = useState<boolean>(false);

    const closeDialog = useCallback<ICloseDialog>(
      (dialogResult?: any) => {
        setOpen(false);
        setTitle('');
        setContent('');
        setActions([]);

        afterClosed?.(dialogResult);
        setAfterClosed(undefined);
      },
      [afterClosed],
    );

    const dialogRef = useRef<IDialogRef>({
      close: closeDialog,
      afterClosed: (callback: IAfterDialogClosed) => {
        return new Promise((resolve) => {
          const handle = (dialogResult) => {
            resolve(callback(dialogResult));
          };
          setAfterClosed(() => handle);
        });
      },
    });

    dialogRef.current.close = closeDialog;

    const dialogController = useMemo<IDialogController>(
      () => ({
        show: (options: InformDialogOptions): IDialogRef => {
          setOpen(true);
          setTitle(options.title);
          setContent(options.content);
          setActions(options.actions || []);
          setAfterClosed(options.afterClosed);
          setHiddenCloseIcon(options.hiddenCloseIcon || false);
          return dialogRef.current;
        },
      }),
      [closeDialog],
    );

    return (
      <DialogContext.Provider value={dialogController}>
        <Component {...props} />
        {open && (
          <InformDialog
            title={title}
            content={content}
            actions={actions}
            onClose={closeDialog}
            hiddenCloseIcon={hiddenCloseIcon}
          />
        )}
      </DialogContext.Provider>
    );
  };
};
