import { get } from '@apw/core/api/api.service';
import { AppOptionsResponse } from '@apw/types/category';
import { IProductId } from '@apw/types/product';
import * as _ from 'lodash';
import { allProductsItem, productConfig } from './filterBar';

const loadAppOptions = (
  brandId: string,
  product: string,
): Promise<AppOptionsResponse> => {
  const endpoint = '/api/app/options';
  const params = {
    brandId,
    product: product === allProductsItem.id ? undefined : product,
  };

  return get<AppOptionsResponse>(endpoint, {
    params,
  });
};

export const loadCategory = (
  brandId: string,
  product: string,
): Promise<string[]> => {
  return loadAppOptions(brandId, product).then((res) => {
    const categoryList = _.find(res, { optionType: 'Category' });
    if (categoryList) {
      return categoryList.options;
    }

    return [];
  });
};

export const getProductDisplayName = (id: IProductId): string | undefined => {
  const product = productConfig[id];
  return product ? product.displayName : undefined;
};
