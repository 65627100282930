import React, { FC } from 'react';
import { RcIcon } from '@ringcentral/juno';
import DefaultProfileLogo from '@apw/lib/assets/images/image_placeholder.svg';
import { IProfileInContentBlock } from '@apw/lib/types';
import styles from './profileLogo.module.scss';

interface IProfileLogoProps {
  profile: Partial<IProfileInContentBlock>;
  borderRadius?: number;
  logoAlt?: string;
}

export const ProfileLogo: FC<IProfileLogoProps> = ({
  profile,
  borderRadius = 0,
  logoAlt,
}) => {
  const logoUri = profile.application?.iconUri;
  logoAlt = logoAlt || `${profile.application?.displayName || ''} app logo`;

  return logoUri ? (
    <img
      className={styles.profileLogo}
      src={logoUri}
      alt={logoAlt}
      style={{ borderRadius }}
      data-test-automation-id="app-tile-logo"
    />
  ) : (
    <RcIcon
      className={styles.defaultProfileLogo}
      symbol={DefaultProfileLogo}
      data-test-automation-id="profileLogoDefault"
      role="img"
      aria-label={logoAlt}
    />
  );
};
