import React from 'react';
import {
  AppInfo,
  AppLogo,
  AppName,
  AppPublisher,
  Body,
  Content,
  Header,
  Wrapper,
  WrapperShadow,
} from '@apw/components/ipaasTile/ipaasTile.sc';
import { googleAnalytics } from '@apw/core/tracker';
import { IpaasListItem } from '@apw/types';

export interface IpaasTileProps {
  app: IpaasListItem;
  handleClick?: () => void;
}

export const IpaasTile = (props: IpaasTileProps) => {
  const { app, handleClick } = props;

  const appName = `Integrate ${app.thirdPartyAppName} with ${app.rcAppName}`;

  const logPageViewToIntegrationDetails = () => {
    const { platform, rcAppName, thirdPartyAppName } = app;
    const message = `GalleryList:IpaasIntegration:${platform}:${rcAppName}:${thirdPartyAppName}`;
    googleAnalytics.trackPageView(message);
    if (handleClick) {
      handleClick();
    }
  };

  return (
    <Wrapper
      onClick={() => logPageViewToIntegrationDetails()}
      href={app.url}
      target="_blank"
      rel="noreferrer"
      data-test-automation-id={`ipaas-tile`}
    >
      <Content>
        <Header>
          <AppLogo
            className={`${
              app.squareIconWithSpacing ? 'logoSquareWithSpacing' : ''
            }`}
            style={app.extraStyle}
            data-test-automation-id={`ipaas-tile-app-logo`}
          >
            <img src={app.logo} alt={`${appName} app logo`} />
          </AppLogo>
        </Header>
        <Body>
          <AppInfo>
            <AppName data-test-automation-id={`ipaas-tile-app-name`}>
              {appName}
            </AppName>
            <AppPublisher
              title={app.publisher}
              data-test-automation-id={`ipaas-tile-publisher`}
            >
              Powered by {app.publisher}
            </AppPublisher>
          </AppInfo>
        </Body>
      </Content>
      <WrapperShadow />
    </Wrapper>
  );
};
