import React, { FC, ReactElement } from 'react';
import { applyProximaNovaRegular } from '@apw/shared/theme';
import { ellipsis, RcMenuItem, styled } from '@ringcentral/juno';
import { IAppGalleryOption } from '../typings';
import { getGalleryIconNode } from './getGalleryIconNode';

const MATCHED_KEYWORD = 'matchedKeyword';

const MenuItem = styled(RcMenuItem)`
  &&& {
    ${applyProximaNovaRegular()}
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px !important;
    padding-right: 20px !important;
    height: 48px !important;

    &:hover {
      background-color: #f3f3f3;
    }

    &.active {
      background-color: #e8e8e8;
    }
  }
`;

const GalleryIconWrapper = styled.div`
  flex-shrink: 0;
  margin-right: 12px;
  display: flex;
  align-items: center;
`;

const GalleryInfoWrapper = styled.div`
  min-width: 0;
`;

const MenuItemText = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #282829;
  ${ellipsis()}

  .${MATCHED_KEYWORD} {
    font-weight: bold;
  }
`;

const MenuItemSubText = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #666;
  ${ellipsis()}
`;

const getBrandNameNode = (brandName: string, searchKeyword: string) => {
  const lowerCasedBrandName = brandName.toLowerCase();
  const lowerCasedSearchKeyword = searchKeyword.toLowerCase();
  const index = lowerCasedBrandName.indexOf(lowerCasedSearchKeyword);

  let brandNameWithHighlight = brandName;

  if (index > -1) {
    const matchedKeyword = brandName.slice(index, index + searchKeyword.length);
    const replaceValue = `<span class="${MATCHED_KEYWORD}">${matchedKeyword}</span>`;
    const splitBrandName = brandName.split('');
    splitBrandName.splice(index, searchKeyword.length, replaceValue);
    brandNameWithHighlight = splitBrandName.join('');
  }

  return (
    <MenuItemText
      title={brandName}
      data-test-automation-id="brandName"
      data-testid="brandName"
      dangerouslySetInnerHTML={{
        __html: brandNameWithHighlight,
      }}
    />
  );
};

const getParentBrandNode = (parentBrandName: string): ReactElement => {
  return (
    <MenuItemSubText>
      Provided by{' '}
      <span title={parentBrandName} data-test-automatin-id="parentBrandName">
        {parentBrandName}
      </span>
    </MenuItemSubText>
  );
};

interface IAppGalleryOptionProps {
  data: IAppGalleryOption;
  onChange: (id: string) => void;
  isCurrent: boolean;
  searchKeyword: string;
}

const AppGalleryOption: FC<IAppGalleryOptionProps> = (props) => {
  const { data, onChange, isCurrent, searchKeyword } = props;

  const {
    brandId,
    brandName,
    galleryIcon,
    galleryIconType,
    galleryIconClassName,
    parentBrandId,
    parentBrandName,
  } = data;

  const ariaLabel = `${brandName}${
    parentBrandName ? `, Provided by ${parentBrandName}` : ''
  }`;

  return (
    <MenuItem
      className={isCurrent ? 'active' : ''}
      onClick={() => onChange(parentBrandId || brandId)}
      data-test-automation-id={`appGallerySelectorItem-${brandId}`}
      role="listitem"
      tabIndex={0}
      aria-label={ariaLabel}
    >
      <GalleryIconWrapper>
        {getGalleryIconNode(galleryIcon, galleryIconType, galleryIconClassName)}
      </GalleryIconWrapper>
      <GalleryInfoWrapper>
        {getBrandNameNode(brandName, searchKeyword)}
        {parentBrandName && getParentBrandNode(parentBrandName)}
      </GalleryInfoWrapper>
    </MenuItem>
  );
};

interface IAppGalleryOptionsProps {
  appGalleryOptions: IAppGalleryOption[];
  currentGalleryBrandId: string;
  onChange;
  searchKeyword?: string;
}

export const AppGalleryOptions: FC<IAppGalleryOptionsProps> = (props) => {
  const {
    appGalleryOptions,
    currentGalleryBrandId,
    onChange,
    searchKeyword = '',
  } = props;
  return (
    <>
      {appGalleryOptions.map((appGalleryOption) => {
        const isCurrent = appGalleryOption.brandId === currentGalleryBrandId;
        return (
          <AppGalleryOption
            key={appGalleryOption.brandId}
            data={appGalleryOption}
            onChange={onChange}
            isCurrent={isCurrent}
            searchKeyword={searchKeyword}
          />
        );
      })}
    </>
  );
};
