import React, { useMemo } from 'react';
import rcLinkIcon from '@apw/assets/images/rc-link-icon.svg';
import { ProfileAppType } from '@apw/core/filter/app-type.enum';
import {
  CardContainer,
  Content,
  Description,
  IconContainer,
  Title,
  TitleBox,
  TitleIcon,
} from '@apw/shared/coveoSearch/shared/profileCard/ProfileCard.sc';
import { ProfileLogo } from '@apw/shared/profile';
import { IProfileCard } from './profileCard.interface';

export interface ProfileCardProps {
  app: IProfileCard;
}

const ProfileCard = (props: ProfileCardProps) => {
  const { app } = props;

  const isMNA = useMemo(() => {
    const profileType = app.appType;
    return profileType === ProfileAppType.MESSAGING_NOTIFICATION_APP;
  }, [app]);

  return (
    <CardContainer to={app.path}>
      <IconContainer>
        <ProfileLogo
          profile={app}
          borderRadius={12}
          data-test-automation-id="profile-logo"
        />
      </IconContainer>
      <Content>
        <TitleBox data-test-automation-id="profile-title-box">
          <Title profile={app} data-test-automation-id="profile-title" />
          {isMNA && (
            <TitleIcon
              symbol={rcLinkIcon}
              data-test-automation-id="profile-title-icon"
            />
          )}
        </TitleBox>
        <Description
          title={app.shortDes}
          data-test-automation-id="profile-short-desc"
        >
          {app.shortDes}
        </Description>
        <div />
      </Content>
    </CardContainer>
  );
};

export default ProfileCard;
