import { RcThemeInput } from '@ringcentral/juno';
import {
  overridesFactory,
  paletteFactory,
} from '../../../../../shared/theme/utils';

export const telekomDtColor = '#066FAC';

export const telekomDtTheme: RcThemeInput = {
  palette: paletteFactory(telekomDtColor),
  overrides: overridesFactory(telekomDtColor),
};
