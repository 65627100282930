import React, { FC, useRef } from 'react';
import { Popover } from '@apw/core/popover';
import { PopoverExternalControl } from '@apw/core/popover/typings';
import { getCompatibilityHomePath } from '@apw/routes/getCompatibilityPaths';
import {
  CurrentGalleryIndicator,
  YourGalleryIndicator,
} from '@apw/shared/navigation/compatibilitySelector/shared';
import { PopoverContent } from '@apw/shared/navigation/compatibilitySelector/shared/PopoverContent';
import { PopoverTrigger } from '@apw/shared/navigation/compatibilitySelector/shared/PopoverTrigger';
import { breakpoints } from '@apw/shared/theme';
import { useStores } from '@apw/stores';
import { ICompatibility } from '@apw/stores/compatibility';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { styled } from '@ringcentral/juno';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';

const StyledPopover = styled(Popover)`
  [class~='MuiPopover-paper'] {
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2) !important;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  .popoverTriggerWrap {
    width: auto;
  }

  @media ${breakpoints.mobile} {
    width: 100%;
    justify-content: space-between;
    padding: 0 16px 0 18px;
    height: 57px;
  }
`;

interface ICompatibilitySelectorPureProps {
  currentCompatibility: ICompatibility;
  onChange: (brandId: string) => void;
  onPopoverInit: (control: PopoverExternalControl) => void;
  isLogin: boolean;
  isMobile: boolean;
  hidePopover: () => void;
}

const CompatibilitySelectorPure: FC<ICompatibilitySelectorPureProps> = (
  props,
) => {
  const {
    currentCompatibility,
    onChange,
    onPopoverInit,
    isLogin,
    isMobile,
    hidePopover,
  } = props;

  const appGallerySelector = (
    <StyledPopover
      getTrigger={(showPopover, open) => (
        <PopoverTrigger
          compatibility={currentCompatibility}
          onClick={showPopover}
          isPopoverOpen={open}
          isMobile={isMobile}
        />
      )}
      initExternalControl={(control) => onPopoverInit(control)}
    >
      <PopoverContent onChange={onChange} hidePopover={hidePopover} />
    </StyledPopover>
  );

  return (
    <Wrapper>
      {!isLogin && (
        <>
          <YourGalleryIndicator />
          {appGallerySelector}
        </>
      )}
      {isLogin && (
        <CurrentGalleryIndicator currentCompatibility={currentCompatibility} />
      )}
    </Wrapper>
  );
};

interface ICompatibilitySelectorProps {
  onCompatibilityChanged?: Function;
}

export const CompatibilitySelector = observer(
  (props: ICompatibilitySelectorProps) => {
    const { onCompatibilityChanged } = props;
    const { compatibilityStore, compatibilitiesStore, userStore } = useStores();
    const history = useHistory();
    const location = useLocation();
    const isMobile = useMediaQuery(breakpoints.mobile);
    const currentCompatibility = compatibilityStore.compatibility;

    const popoverControlRef = useRef<PopoverExternalControl>({
      hidePopover: () => {},
    });

    const onPopoverInit = (control: PopoverExternalControl) => {
      popoverControlRef.current = control;
    };

    const hidePopover = () => {
      popoverControlRef.current.hidePopover();
    };

    const executeDefaultAction = (compatibility: ICompatibility) => {
      const path = getCompatibilityHomePath(compatibility.slug);
      const newLocation = path + location.search;
      history.push(newLocation);
    };

    const emitCompatibilityChange = (compatibility: ICompatibility) => {
      if (onCompatibilityChanged) {
        onCompatibilityChanged(compatibility);
        return;
      }

      executeDefaultAction(compatibility);
    };

    const onChange = (brandId: string) => {
      const targetCompatibility =
        compatibilitiesStore.getCompatibilityById(brandId);

      if (
        !targetCompatibility ||
        targetCompatibility.id === currentCompatibility.id
      ) {
        hidePopover();
        return;
      }

      compatibilityStore.setCompatibilityById(brandId);
      emitCompatibilityChange(targetCompatibility);
      hidePopover();
    };

    return (
      <CompatibilitySelectorPure
        currentCompatibility={currentCompatibility}
        onChange={onChange}
        onPopoverInit={onPopoverInit}
        isLogin={userStore.isLoggedIn}
        isMobile={isMobile}
        hidePopover={hidePopover}
      />
    );
  },
);
