import React from 'react';
import { IProcessLinks } from '@apw/components/profileDetail';
import { RcLink } from '@ringcentral/juno';
import { NoteBorder } from '@ringcentral/juno-icon';
import {
  LinkHref,
  LinkIcon,
  LinkIconWrapper,
  LinkItem,
  LinkName,
  LinkWrapper,
  Wrapper,
} from './resourceLinks.sc';

interface ResourceLinksProps {
  className: string | undefined;
  links: IProcessLinks[] | undefined;
}

export const ResourceLinks = ({
  className = '',
  links,
}: ResourceLinksProps) => {
  if (!links || !links.length) {
    return null;
  }

  return (
    <Wrapper
      className={`${className}`}
      data-test-automation-id={`resource-links-wrapper`}
    >
      <LinkWrapper>
        {(links || []).map((item, index) => {
          return (
            <LinkItem
              key={item.key}
              data-test-automation-id={`resource-link-item-${index}`}
            >
              <LinkHref>
                <LinkIconWrapper
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                  tabIndex={-1}
                  aria-hidden="true"
                  aria-label={item.label}
                  data-test-automation-id={`resource-link-icon-wrapper`}
                >
                  <LinkIcon
                    symbol={NoteBorder}
                    size="small"
                    color={['secondary', 'main']}
                    data-test-automation-id={`resource-link-icon`}
                  />
                </LinkIconWrapper>
                <LinkName>
                  <RcLink
                    href={item.link}
                    target="_blank"
                    color="secondary"
                    data-test-automation-id={`resource-link-label`}
                  >
                    {item.label}
                  </RcLink>
                </LinkName>
              </LinkHref>
            </LinkItem>
          );
        })}
      </LinkWrapper>
    </Wrapper>
  );
};
