import React, { FC, useContext, useMemo } from 'react';
import { RcTypography } from '@ringcentral/juno';
import {
  Container,
  ItemList,
} from '@apw/lib/components/profileDetail/profileBody/ratingsAndReviewsTab/ratingsSection/content/qualityRating/qualityRating.sc';
import { QualityRatingItem } from '@apw/lib/components/profileDetail/profileBody/ratingsAndReviewsTab/ratingsSection/content/qualityRating/qualityRatingItem/QualityRatingItem';
import { StoreContext } from '@apw/lib/components/profileDetail/StoreContext';
import { IAppRating } from '@apw/lib/types';

export interface IQualityRatingProps {
  ratings: IAppRating;
}

export const QualityRating: FC<IQualityRatingProps> = ({ ratings }) => {
  const { translation } = useContext(StoreContext);
  const items = useMemo(() => {
    return Object.keys(
      translation.ratingsAndReviews.qualityRatings.itemLabels,
    ).map((key, index) => {
      const labels =
        translation.ratingsAndReviews.qualityRatings.itemLabels[key];
      const scores = [
        ratings.valueRating,
        ratings.convenienceRating,
        ratings.featureRating,
        ratings.supportRating,
      ];
      return {
        score: scores[index],
        ...labels,
      };
    });
  }, [ratings]);

  return (
    <Container>
      <RcTypography
        variant={'subheading2'}
        color="neutral.f06"
        data-test-automation-id={'quality-rating-title'}
      >
        {translation.ratingsAndReviews.qualityRatings.title}
      </RcTypography>
      <ItemList data-test-automation-id={'quality-rating-item-list'}>
        {items.map((item) => {
          return (
            <QualityRatingItem
              tooltip={item.tooltip}
              text={item.label}
              score={item.score}
            />
          );
        })}
      </ItemList>
    </Container>
  );
};
