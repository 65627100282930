import React from 'react';
import { SecondaryNavigation } from '@apw/shared/navigation';
import { observer } from 'mobx-react';
import { Container } from './sidebar.sc';

export const Sidebar = observer(() => {
  return (
    <Container data-test-automation-id="sidebar">
      <SecondaryNavigation />
    </Container>
  );
});
