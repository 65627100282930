export enum FormPropsEnum {
  BOT_NAME = 'botName',
}

export const FormPropsConfig = {
  [FormPropsEnum.BOT_NAME]: {
    minLength: 3,
    maxLength: 32,
  },
};
