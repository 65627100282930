import { ErrorResponse } from '@apw/core/api/error-response/error-response.class';
import { AxiosResponse } from 'axios';

interface ErrorResponseBody {
  errorCode: string;
  errors: Array<{
    errorCode: string;
    message: string;
  }>;
  message: string;
}

export class ErrorResponseFromPlatform extends ErrorResponse {
  constructor(err: AxiosResponse) {
    super(err);
  }

  extractErrorCode(): string {
    const responseBody: ErrorResponseBody = this.getResponseBody();

    return Array.isArray(responseBody.errors) && responseBody.errors.length > 0
      ? responseBody.errors[0].errorCode
      : responseBody.errorCode;
  }
}
