export const LeadFormConfig = {
  requirements: {
    label: 'How can we help you? (optional)',
    name: 'note',
    maxlength: 1000,
  },
  receiveEmails: {
    name: 'receiveEmails',
  },
};
