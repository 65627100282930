import React, { FC, useEffect } from 'react';
import { useUserCompatibility } from '@apw/hooks/useUserCompatibility';
import { getCompatibilityHomePath } from '@apw/routes/getCompatibilityPaths';
import { applyProximaNovaBold, breakpoints } from '@apw/shared/theme';
import { useStores } from '@apw/stores';
import { RcIcon, RcLink, styled } from '@ringcentral/juno';
import { Forward } from '@ringcentral/juno-icon';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f3;
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 16px;

  @media ${breakpoints.mobile} {
    flex-direction: column;
    padding: 12px 0;
    height: auto;
  }
`;

const CtaLink = styled(RcLink)`
  &&& {
    ${applyProximaNovaBold};
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
    margin-left: 4px;
    text-decoration: none;

    ${RcIcon} {
      margin-left: 4px;
    }
  }
`;

interface AppGalleryWarningPureProps {
  visible: boolean;
  userGalleryHomePath: string;
  onClick: (event) => void;
  currentGalleryBrandName: string;
}

const AppGalleryWarningPure: FC<AppGalleryWarningPureProps> = (props) => {
  const { visible, userGalleryHomePath, onClick, currentGalleryBrandName } =
    props;

  if (!visible) {
    return null;
  }

  return (
    <Wrapper data-test-automation-id="appGalleryWarning">
      <span>You are viewing {currentGalleryBrandName}'s App Gallery.</span>
      <CtaLink
        Component="a"
        color="secondary"
        href={userGalleryHomePath}
        onClick={onClick}
        data-test-automation-id="visitYourAppGallery"
      >
        <span>Visit your App Gallery</span>
        <RcIcon symbol={Forward} size="medium" />
      </CtaLink>
    </Wrapper>
  );
};

interface AppGalleryWarningProps {
  onVisibilityChange?: (visible: boolean) => void;
}

export const AppGalleryWarning = observer((props: AppGalleryWarningProps) => {
  const { onVisibilityChange } = props;
  const { userStore, compatibilitiesStore, compatibilityStore } = useStores();
  const { user } = userStore;
  const history = useHistory();

  const { isUserIncompatibleWithCurrentGallery: visible } =
    useUserCompatibility();

  const userCompatibility =
    compatibilitiesStore.getCompatibilityByIdOrSubBrandId(user?.brandId!);

  const userGalleryHomePath = getCompatibilityHomePath(
    userCompatibility?.slug || '',
  );

  const onClick = (e) => {
    e.preventDefault();
    history.push(userGalleryHomePath);
  };

  useEffect(() => {
    onVisibilityChange?.(visible);
  }, [visible]);

  return (
    <AppGalleryWarningPure
      visible={visible}
      userGalleryHomePath={userGalleryHomePath}
      onClick={onClick}
      currentGalleryBrandName={compatibilityStore.compatibility.name}
    />
  );
});
