import { useProximaNovaRegular } from '@apw/core/sc';
import { RcMenuItem, RcSelect, RcTextField, styled } from '@ringcentral/juno';

export const BusinessPhoneField = styled(RcTextField)`
  width: 100%;

  [class~='RcIconButton-root'] {
    position: absolute;
    right: 0;
  }

  [class~='MuiInput-input'] {
    padding-bottom: 4px;
  }
`;

export const CountrySelectorContainer = styled(RcSelect)`
  width: 100px;
  margin-bottom: 0 !important;

  [class~='MuiInput-root'] {
    margin-top: 0;
  }

  [class~='MuiInput-input'] {
    padding-top: 9px !important;
  }

  [class~='MuiSelect-icon'] {
    svg {
      font-size: 24px !important;
    }
  }

  [class~='MuiInput-underline'] {
    &:before {
      border-bottom: 0 !important;
    }
  }
`;

export const CountrySelectorItem = styled(RcMenuItem)`
  display: block !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  ${useProximaNovaRegular};
  padding-left: 16px;
  padding-right: 16px;
`;
