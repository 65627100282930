import React, { FC, useEffect, useMemo, useState } from 'react';
import { SEO, Sticky } from '@apw/components';
import Carousel from '@apw/components/carousel/Carousel';
import { BlockContent } from '@apw/components/pageContent';
import { useBrandBannerTexts, useQueryParams } from '@apw/hooks';
import { PageContentAccessible } from '@apw/modules/page/pageContentAccessible';
import { usePartnerNameReplacer } from '@apw/modules/page/usePageLoader';
import { ExpiredPreviewLink, withLayout } from '@apw/shared';
import { Menu } from '@apw/shared/menu';
import { Sidebar } from '@apw/shared/sidebar';
import { useStores } from '@apw/stores';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import {
  HeroContainer,
  MainContainer,
  RightContent,
  SidebarContainer,
} from '../Page.sc';
import { resolveGalleryPageForPreview } from './pagePreviewService';
import { IPagePreviewData, IPagePreviewRequest } from './typings';

interface PagePreviewProps {
  headerHeight?: number;
}

type IPagePreviewState = IPagePreviewData | null | undefined;

const PagePreviewPure: FC<PagePreviewProps> = (props) => {
  const { headerHeight } = props;
  const [previewData, setPreviewData] = useState<IPagePreviewState>();

  const { pageId } = useParams<{ pageId: string }>();
  const queryParams = useQueryParams();
  const { compatibilityStore } = useStores();
  const imagesAlt = useBrandBannerTexts();

  const request = useMemo<IPagePreviewRequest>(() => {
    return buildPreviewRequest(pageId, queryParams);
  }, [pageId, queryParams]);

  const contentBlocks = useMemo<BlockContent>(() => {
    if (previewData) {
      try {
        return JSON.parse(previewData.content.content);
      } catch (e) {
        return [];
      }
    }

    return [];
  }, [previewData]);
  const partnerNameReplacer = usePartnerNameReplacer();

  useEffect(() => {
    resolveGalleryPageForPreview(request)
      .then((data) => {
        const previewDataStr = partnerNameReplacer(data);
        try {
          const previewData = JSON.parse(previewDataStr);
          setPreviewData(previewData);
        } catch (e) {
          setPreviewData(null);
        }
      })
      .catch(() => setPreviewData(null));
  }, [request]);

  return (
    <React.Fragment>
      {previewData && (
        <>
          {compatibilityStore.compatibility.headerBanners.length > 0 && (
            <HeroContainer>
              <Carousel
                images={compatibilityStore.compatibility.headerBanners}
                imagesAlt={imagesAlt}
              />
            </HeroContainer>
          )}
          <Sticky position="top" offset={headerHeight}>
            <Menu />
          </Sticky>
          <MainContainer>
            <SidebarContainer>
              <Sidebar />
            </SidebarContainer>
            <RightContent>
              {previewData && (
                <>
                  <SEO
                    title={previewData?.seo?.title || previewData?.page?.name}
                  />
                  <PageContentAccessible
                    contentBlocks={contentBlocks}
                    isPreviewMode
                  />
                </>
              )}
            </RightContent>
          </MainContainer>
        </>
      )}
      {previewData === null && <ExpiredPreviewLink />}
    </React.Fragment>
  );
};

export const PagePreview = withLayout(observer(PagePreviewPure), {
  stickyHeader: true,
});

const buildPreviewRequest = (
  pageId: string,
  queryParams: any,
): IPagePreviewRequest => {
  const { t: timestamp, o: token, type } = queryParams;

  return {
    id: pageId,
    timestamp: timestamp as string,
    token: token as string,
    type: type as string,
  };
};
