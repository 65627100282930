import React, { FC } from 'react';
import writeNotes from '@apw/assets/images/write_notes.svg';
import { RcTypography } from '@ringcentral/juno';
import { RcTypographyVariant } from '@ringcentral/juno/components/Typography/Typography';
import { Container, Image } from './noRatingsSection.sc';

interface INoRatingsSectionProps {
  variant?: RcTypographyVariant;
  className?: string;
}

export const NoRatingsSection: FC<INoRatingsSectionProps> = ({
  variant = 'subheading1',
  className = '',
}) => {
  return (
    <Container className={className}>
      <Image data-test-automation-id="no-ratings-img" symbol={writeNotes} />
      <RcTypography
        color="neutral.f06"
        variant={variant}
        data-test-automation-id="no-ratings-msg"
        tabIndex={0}
      >
        Be the first to write a customer review
      </RcTypography>
    </Container>
  );
};
