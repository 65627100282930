import { ApplicationType } from '@apw/components/profileDetail/typings';
import { transport } from '@apw/core';
import { del, get, post } from '@apw/core/api/api.service';
import {
  IAccountInfo,
  IBotConfirmationInfo,
} from '@apw/modules/botProvision/typings';
import { IInstallBotResponse } from '@apw/modules/botProvision/typings/install-bot-response.interface';
import { IProfile } from '@apw/types';
import { includes } from 'lodash';
import * as qs from 'qs';

export const isBotProfile = (profile: IProfile): Promise<boolean> => {
  const isServerBot = profile.applicationType === ApplicationType.SERVER_BOT;

  const isBotProfile =
    hasLinkedApp(profile) && isBotsAndNotifications(profile) && isServerBot;

  return Promise.resolve(isBotProfile);
};

const isBotsAndNotifications = (profile: IProfile): boolean => {
  const CATEGORY_NAME = 'Bots & Notifications';
  const appCategory = profile.category || '';
  const categories = appCategory.split(';');
  return includes(categories, CATEGORY_NAME);
};

export const shouldGetAppFromGallery = (item: IProfile): boolean => {
  const botInstallUrl = getBotInstallUrl(item);
  return botInstallUrl === 'http://appgallery';
};

export const getBotInstallUrl = (item: IProfile): string => {
  if (item.downloadLinks && item.downloadLinks.length > 0) {
    return item.downloadLinks[0].link;
  }

  return '';
};

const hasLinkedApp = (profile: IProfile): boolean => {
  return !!profile.appId;
};

export const getConfirmationInfo = (
  appId: string,
): Promise<IBotConfirmationInfo> => {
  const endpoint = `/api/bot/app/${appId}`;
  return get<IBotConfirmationInfo>(endpoint);
};

export const removeBot = (clientId: string): Promise<void> => {
  const endpoint = `/api/bot/app/${clientId}`;
  return del(endpoint, { useGlobalLoading: true });
};

export const requestBotConfirmationInfo = (appId: string) => {
  const BOT_CONFIRMATION_INFO = '/api/bot/app/';
  const endpoint = BOT_CONFIRMATION_INFO + appId;
  return get<IBotConfirmationInfo>(endpoint, {
    useGlobalLoading: true,
  });
};

export const requestInstallBot = (
  clientId: string,
  botName: string,
  customerParameter?: string,
) => {
  const endpoint = `/api/bot/app/${clientId}`;
  return post<IInstallBotResponse>(
    endpoint,
    {
      extensionName: botName,
      customerParameter,
    },
    {
      useGlobalLoading: true,
    },
  );
};

export const isFreeTier = (): Promise<boolean> => {
  return getAccountInfo().then((accountInfo: IAccountInfo) => {
    const servicePlanId = accountInfo.serviceInfo.servicePlan.id;
    return servicePlanId === '4530';
  });
};

export const installBot = (
  clientId: string,
  botName: string,
  customerParameter?: string,
) => {
  return transport.fetchInstallBot(clientId, botName, customerParameter);
};

export const buildRouterParams = (appId: string, search: string) => {
  const queryParams = qs.parse(search.replace(/^\?/, ''));
  return { appId, ...queryParams };
};

export const isHipaaUser = (preCheckErrors: string[]): boolean => {
  return includes(preCheckErrors, 'BOT_HIPAA');
};

export const isBotBrandNotSupported = (preCheckErrors: string[]): boolean => {
  return includes(preCheckErrors, 'BOT_BRAND_NOT_SUPPORTED');
};

export const getAccountInfo = () => {
  const endpoint = '/api/user/account-info';
  return get<IAccountInfo>(endpoint, {
    useGlobalLoading: true,
  });
};

export const hasNoManagementPermission = (
  preCheckErrors: string[],
): boolean => {
  return includes(preCheckErrors, 'NO_BOT_MANAGE_PERMISSION');
};

export const getBotConfirmationInfo = (appId: string) => {
  return transport.fetchBotConfirmationInfo(appId);
};

export const loadTrackProfile = async (brandId, appId) => {
  const defaultProfile = { appId, orgId: null };
  const profile: any = brandId
    ? await transport
        .getProfileByAppIdAndBrandId(appId, brandId)
        .catch(() => defaultProfile)
    : defaultProfile;

  profile.brand = profile.brand || brandId;

  return profile;
};
