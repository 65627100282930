import React from 'react';
import rcLogo from '@apw/assets/images/rc-logo.svg';
import { useCompatibilityHomePath } from '@apw/routes/useCompatibilityPaths';
import { RcLogoImage, RcLogoLink } from './HeaderBrand.sc';

export interface IHeaderBrandProps {
  logoClick?: () => void;
}

export const HeaderBrand = (props: IHeaderBrandProps) => {
  const { logoClick } = props;

  const homePath = useCompatibilityHomePath();

  const onLogoClick = () => {
    if (logoClick) {
      logoClick();
    }
  };

  return (
    <div>
      <RcLogoLink
        to={homePath}
        onClick={() => onLogoClick()}
        aria-label="RingCentral App Gallery"
        data-test-automation-id="header-brand-link"
      >
        <RcLogoImage symbol={rcLogo} />
      </RcLogoLink>
    </div>
  );
};
