import React from 'react';
import { IpaasTile } from '@apw/components';
import { SearchResultSection } from '@apw/modules/search/searchResultSection';
import { SearchResult } from '@apw/shared/searchTracking';
import { IpaasListItem } from '@apw/types';
import { observer } from 'mobx-react';

const IpaasSearchResultPure = (props: {
  ipaasList: IpaasListItem[];
  handleClick: (clickLink, resultType, clickDepth: number) => void;
  isH1: boolean;
}) => {
  const { ipaasList, handleClick } = props;

  const onIpaasClick = (clickItem: IpaasListItem, resultDepth: number) => {
    if (handleClick) {
      handleClick(clickItem.url, SearchResult.IPAAS, resultDepth + 1);
    }
  };

  return (
    <SearchResultSection title={'Integration Platform Apps'} isH1={props.isH1}>
      {ipaasList.map((item, index) => (
        <IpaasTile
          app={item}
          key={`${item.rcAppName}-${item.platform}-${item.thirdPartyAppName}`}
          handleClick={() => onIpaasClick(item, index)}
        />
      ))}
    </SearchResultSection>
  );
};

export const IpaasSearchResult = observer(IpaasSearchResultPure);
