import { useMemo } from 'react';
import moment from 'moment';

export const useRelativeTime = (rawTimeString: string) => {
  return useMemo(() => {
    const timeFromNowInMonths = moment().diff(moment(rawTimeString), 'months');

    if (moment(rawTimeString).year() < moment().year()) {
      return moment(rawTimeString).format('MMM D, YYYY');
    }

    if (timeFromNowInMonths > 6) {
      return moment(rawTimeString).format('MMM D');
    }

    return moment(rawTimeString).fromNow();
  }, [rawTimeString]);
};
