import React, { FC, useContext } from 'react';
import { ProfileOptionsContext } from '@apw/components/profileDetail/profile-options.context';
import { ProfileContext } from '@apw/components/profileDetail/profile.context';
import { showDirectSupport } from '@apw/components/profileDetail/profileHeader/profileHeaderService';
import { ExternalLink } from '@ringcentral/juno-icon';
import {
  DirectSupportButton,
  DirectSupportIcon,
  DirectSupportLink,
  DirectSupportText,
} from './DirectSupport.sc';

export const DirectSupport: FC = () => {
  const profile = useContext(ProfileContext);
  const { onSupport, isPreviewMode } = useContext(ProfileOptionsContext);

  if (showDirectSupport(profile, isPreviewMode!)) {
    const colorTheme = 'secondary';
    return (
      <DirectSupportButton
        variant="plain"
        onClick={() => onSupport!()}
        data-test-automation-id={`direct-support-button`}
      >
        <DirectSupportText>
          <DirectSupportLink
            color={colorTheme}
            data-test-automation-id={`direct-support-link`}
          >
            Contact vendor
          </DirectSupportLink>
        </DirectSupportText>
        <DirectSupportIcon
          color={['text', colorTheme]}
          symbol={ExternalLink}
          size="small"
          data-test-automation-id={`direct-support-icon`}
        />
      </DirectSupportButton>
    );
  }

  return null;
};
