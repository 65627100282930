import { styled } from '@ringcentral/juno';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

export const Title = styled.h5`
  margin: 0 0 16px;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  font-family: 'ProximaNova-Medium', Helvetica, Arial, sans-serif !important;
`;

export const Content = styled.div`
  color: #282829;
  line-height: 24px;
`;
