import { RcThemeInput } from '@ringcentral/juno';
import {
  overridesFactory,
  paletteFactory,
} from '../../../../../shared/theme/utils';

export const verizonColor = '#000000';

export const verizonTheme: RcThemeInput = {
  palette: paletteFactory(verizonColor),
  overrides: overridesFactory(verizonColor),
};
