import { useProximaNovaRegular } from '@apw/core/sc';
import {
  RcIcon,
  RcIconButton,
  RcMenu,
  RcMenuItem,
  styled,
} from '@ringcentral/juno';

export const Container = styled.div`
  display: inline-block;
  margin-left: 5px;
`;

export const UserInfo = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 6px 0 6px 12px !important;
`;

export const UserIcon = styled(RcIcon)`
  margin-right: 0;

  svg {
    color: #666;
    width: 19px;
    height: 19px;
    margin-right: 8px;
  }
`;

export const UserName = styled.span`
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 24px;
  color: #494949;
  font-weight: normal;
  ${useProximaNovaRegular}
`;

export const ArrowIconButton = styled(RcIconButton)`
  margin-right: 0 !important;
  width: 24px;
  height: 24px;
  margin-left: 4px;

  svg {
    color: #666;
    width: 19px;
    height: 19px;
  }
`;

export const UserMenu = styled(RcMenu)`
  [class~='MuiPopover-paper'] {
    width: 132px !important;
    margin-top: 4px;
  }
  [class~='MuiList-padding'] {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  [class~='MuiMenuItem-root'] {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export const SignOutMenuItem = styled(RcMenuItem)`
  ${useProximaNovaRegular}
  font-size: 16px !important;
`;

export const SignOutIcon = styled(RcIcon)`
  margin-right: 4px;
`;
