import { palette2, RcRating, RcTypography, styled } from '@ringcentral/juno';
import { JUNO_THEME_PREFIX } from '@apw/lib/constants';

export const RatingContainer = styled.div`
  display: flex;
  margin-top: auto;
  align-items: center;
`;

export const StyledRating = styled(RcRating)`
  height: 20px;

  .${JUNO_THEME_PREFIX}-MuiRating-decimal {
    margin-right: 4px;
  }

  .RcRating-label {
    padding: 0;
  }

  &&& {
    .RcRating-icon {
      padding: 0;
    }

    .RcRating-iconEmpty {
      color: ${palette2('warning', 'f01')};
    }

    .icon {
      font-size: 20px;
    }
  }
`;

export const RatingCount = styled(RcTypography)`
  margin-left: 4px;
`;
