import { styled, RcTextarea } from '@ringcentral/juno';
import { onMobile } from '@apw/lib/sc';

export const Review = styled(RcTextarea)`
  margin-top: 32px;
  margin-bottom: 16px;

  ${onMobile} {
    textarea {
      height: 320px !important;
    }
  }
`;
