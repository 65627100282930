import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import botDefaultImage from '@apw/assets/images/default-bot-image.png';
import leftRightArrow from '@apw/assets/images/left-right-arrow.svg';
import rcAppLogo from '@apw/assets/images/logo_rc_app.svg';
import { SEO, Tooltip } from '@apw/components';
import { PrimaryButton, SecondaryButton } from '@apw/components/button';
import { mixpanel } from '@apw/core';
import { Language, languageService } from '@apw/core/language';
import { logPageView } from '@apw/core/tracker/google-analytics/google-analytics-core.service';
import { prepareExternalUrl } from '@apw/core/url/url.service';
import { useIsMounted } from '@apw/hooks';
import usePartnerHandlebars from '@apw/hooks/usePartnerHandlebars';
import { useBotProvision } from '@apw/modules/botProvision';
import {
  buildRouterParams,
  getBotConfirmationInfo,
  getConfirmationInfo,
  installBot,
  loadTrackProfile,
} from '@apw/modules/botProvision/bot-provision.service';
import {
  buildForm,
  setFormatError,
  setOccupiedError,
} from '@apw/modules/botProvision/botProvisionConfirm/botProvisionConfirm.service';
import {
  FormPropsConfig,
  FormPropsEnum,
  IAppPermission,
  IBotConfirmationInfo,
} from '@apw/modules/botProvision/typings';
import { buildHandlebarsProfile } from '@apw/modules/profile';
import { ThemeOverrideContext } from '@apw/shared';
import { rootStore, useStores } from '@apw/stores';
import { IProfile } from '@apw/types';
import { RcIcon, RcThemeInput } from '@ringcentral/juno';
import { useFormik } from 'formik';
import { first, get, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IInstallBotResponse } from '../typings/install-bot-response.interface';
import {
  BotButtons,
  BotLogoWrapper,
  BotNameContent,
  BotNameTitle,
  BotPermission,
  BotTip,
  BotTitle,
  ConfirmForm,
  ErrMsg,
  TextField,
  TooltipContent,
  Wrapper,
} from './BotProvisionConfirm.sc';

const APP_MANAGEMENT_URL = 'my-apps';

enum UrlParameterKeys {
  LANDING_URL = 'landing_url',
  BOT_NAME = 'bot_name',
  CUSTOMER_PARAMETER = 'c_para',
  BRAND_ID = 'brand_id',
}

interface IAllParams {
  appId: string;
  [UrlParameterKeys.LANDING_URL]?: string;
  [UrlParameterKeys.BOT_NAME]?: string;
  [UrlParameterKeys.CUSTOMER_PARAMETER]?: string;
  [UrlParameterKeys.BRAND_ID]?: string;
}

const BotProvisionConfirmPure = () => {
  const isMounted = useIsMounted();
  const botProvision = useBotProvision();
  const [appName, setAppName] = useState('');
  const [botLogo, setBotLogo] = useState(botDefaultImage);
  const [permissions, setPermissions] = useState<IAppPermission[]>([]);
  const [botClientId, setBotClientId] = useState<string>('');
  const [disableBotName, setDisableBotName] = useState(false);
  const [displayInfo, setDisplayInfo] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [profile, setProfile] = useState<IProfile>({} as IProfile);

  const themeOverride = useContext(ThemeOverrideContext);

  const formik = useFormik(buildForm());

  const { appId } = useParams<{ appId: string }>();
  const { search } = useLocation();
  const history = useHistory();

  const { userStore, compatibilitiesStore } = useStores();
  const handlebars = usePartnerHandlebars();

  const updateBotConfirmationInfo = (data: IBotConfirmationInfo) => {
    const appName = data.appName;
    setAppName(appName);
    setPermissions(data.appPermissions);
    setBotClientId(data.clientId);
    const installedBotExtension = data.botExtension;
    if (data.botLogo) {
      setBotLogo(data.botLogo);
    }

    const preCheckError = first(data.preCheckErrors) as string;
    if (preCheckError) {
      handleErrorResult(preCheckError, {
        shouldReturn: true,
        installedBotName: get(installedBotExtension, 'name') as string,
        onInstallation: true,
      });
      return;
    }

    setDisplayInfo(true);
    logPageView(`BotConfirmation:${appName}`);
  };

  const getInfo = () => {
    getBotConfirmationInfo(routerParams.appId)
      .then((data) => {
        if (!isMounted.current) {
          return;
        }
        updateBotConfirmationInfo(data);

        let newBotName;
        if (data.disallow) {
          newBotName = data.botName;
        } else {
          newBotName =
            formik.values[FormPropsEnum.BOT_NAME] ||
            data.botName ||
            data.appDisplayName ||
            '';
        }
        newBotName = newBotName.substring(0, 32);
        formik.setFieldValue(FormPropsEnum.BOT_NAME, newBotName, true);
        setDisableBotName(data.disallow);
      })
      .catch((err) => {
        if (!isMounted.current) {
          return;
        }
        const error = get(err, 'response.data.error');
        handleErrorResult(error, {
          shouldReturn: true,
        });
      });
  };

  const routerParams = useMemo<IAllParams>(
    () => buildRouterParams(appId, search),
    [appId, search],
  );

  const getBrandId = () => {
    const brandId = routerParams[UrlParameterKeys.BRAND_ID];
    const landingUrl = routerParams[UrlParameterKeys.LANDING_URL];
    if (!brandId || !landingUrl || landingUrl.includes(APP_MANAGEMENT_URL)) {
      return '';
    }
    return brandId;
  };

  useEffect(() => {
    if (userStore.isLoggedIn) {
      botProvision.checkAccountTier().then((canInstallBots: boolean) => {
        if (canInstallBots) {
          getInfo();

          loadTrackProfile(getBrandId(), appId).then((profile) => {
            if (!isMounted.current) {
              return;
            }
            const handlebarsProfile = buildHandlebarsProfile(
              profile,
              handlebars,
            );
            setProfile(handlebarsProfile);
          });
        }
      });
    } else {
      userStore.signInViaCLW();
    }
    return () => {};
  }, [routerParams]);

  useEffect(() => {
    const currentTheme = resolveCurrentTheme(
      routerParams[UrlParameterKeys.BRAND_ID],
    );

    if (currentTheme) {
      themeOverride.set(currentTheme);
    }

    return () => {
      themeOverride.reset();
    };
  }, [routerParams[UrlParameterKeys.BRAND_ID]]);

  const getBrandName = useCallback(
    (profile) => {
      const compatibility = compatibilitiesStore.getCompatibilityById(
        profile.brand,
      );
      return compatibility ? compatibility.name : '';
    },
    [profile],
  );

  const onCancel = () => {
    if (history.length) {
      history.goBack();
      return;
    }
    window.close();
  };

  const onAuthorize = () => {
    formik.setFieldTouched(FormPropsEnum.BOT_NAME, true, true);
    formik.validateForm().then((validateResult) => {
      const isValid = isEmpty(validateResult);
      if (!isValid) {
        return;
      }

      mixpanel.trackAuthorizeBot(profile, getBrandName(profile));

      setIsSubmitting(true);
      logPageView(`BotConfirmation:${appName}:Authorize`);

      const botName = (formik.values[FormPropsEnum.BOT_NAME] || '').trim();
      installBot(
        botClientId,
        botName,
        routerParams[UrlParameterKeys.CUSTOMER_PARAMETER],
      )
        .then((data: IInstallBotResponse) => {
          const onTrackCompleted = mixpanel.trackInstallBot(
            profile,
            getBrandName(profile),
          );

          onTrackCompleted.subscribe(() => {
            redirectToLandingUrl(data.defaultAppProfileId);
          });

          setIsSubmitting(false);
        })
        .catch((err) => {
          setIsSubmitting(false);
          const error = get(err, 'response.data.error');
          switch (error) {
            case 'BOT_ALREADY_INSTALLED':
              getConfirmationInfo(routerParams.appId).then(
                (data: IBotConfirmationInfo) => {
                  const installedBotName = get(data.botExtension, 'name');
                  botProvision.handleAlreadyInstalledError({
                    installedBotName,
                  });
                },
              );
              break;
            case 'BOT_SUBSCRIBE_WEB_HOOK_FAIL':
              botProvision.handleWebhookSubscriptionFailure();
              break;
            default:
              handleErrorResult(error, {
                onInstallation: true,
              });
          }
        });
    });
  };

  const redirectToLandingUrl = (defaultAppProfileId: string) => {
    window.location.href =
      routerParams[UrlParameterKeys.LANDING_URL] ||
      prepareExternalUrl(`/${defaultAppProfileId}`);
  };

  const handleErrorResult = (code: string, data?: any) => {
    const resultContent = botProvision.handleError(code, data);
    if (!resultContent) {
      return;
    }

    switch (resultContent.code) {
      case 'BOT_NAME_FORMAT_ERROR':
        setFormatError(formik);
        break;
      case 'BOT_NAME_OCCUPIED':
        setOccupiedError(formik);
        break;
      default: {
        break;
      }
    }
  };

  const getErrMsg = () => {
    const botNameErr = formik.errors[FormPropsEnum.BOT_NAME];
    if (!botNameErr) {
      return {};
    }

    const errMsgMapping = {
      FORMAT_ERROR: languageService.get(Language.BOT_NAME_FORMAT_ERROR),
      BOT_NAME_OCCUPIED: languageService.get(Language.BOT_NAME_OCCUPIED),
      DISABLE_BOT_NAME_OCCUPIED: languageService.get(
        Language.DISABLE_BOT_NAME_OCCUPIED,
      ),
    };
    let errorContent = errMsgMapping[botNameErr];
    if (botNameErr === 'occupied') {
      errorContent =
        errMsgMapping[
          disableBotName ? 'DISABLE_BOT_NAME_OCCUPIED' : 'BOT_NAME_OCCUPIED'
        ];
    }
    return {
      dangerouslySetInnerHTML: { __html: errorContent },
    };
  };

  const isFormValid = () => {
    return !formik.isValid;
  };

  const isBotNameErr = () => {
    return !!formik.errors[FormPropsEnum.BOT_NAME];
  };

  const onChangeBotName = (e) => {
    formik.setFieldValue(FormPropsEnum.BOT_NAME, e.target.value, true);
  };

  return (
    <>
      <SEO title={appName} />
      <Wrapper data-test-automation-id="botConfirmationPage" role="main">
        <ConfirmForm hidden={!displayInfo}>
          <form>
            <BotLogoWrapper>
              <img
                data-test-automation-id="bot-confirm-form-logo"
                className="botLogo"
                src={botLogo}
                alt={appName}
              />
              <RcIcon
                data-test-automation-id="bot-logo-icon"
                className="botLogoIcon"
                symbol={leftRightArrow}
                size="xlarge"
              />
              <div className={'rcLogo'}>
                <RcIcon
                  data-test-automation-id="rc-log-icon"
                  className={'rcLogIcon'}
                  symbol={rcAppLogo}
                  size="xxxlarge"
                />
              </div>
            </BotLogoWrapper>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
            <BotTitle tabIndex={0} data-test-automation-id="bot-confirm-msg">
              <span
                data-test-automation-id="bot-app-name"
                className={'appName'}
              >{`${appName} `}</span>
              would like access to RingCentral, lnc.
            </BotTitle>
            <BotNameTitle>
              <span className={'botName'}>Bot Name</span>
              <div
                className={'botNameTooltip'}
                data-test-automation-id="bot-app-name-tooltip"
              >
                <Tooltip
                  title={<TooltipContent {...getToolTip()} />}
                  tabIndex={0}
                />
              </div>
            </BotNameTitle>
            <BotNameContent>
              <TextField
                variant="outline"
                name="botName"
                value={formik.values.botName}
                onChange={onChangeBotName}
                placeholder="bot name"
                size="medium"
                clearBtn
                disabled={disableBotName}
                error={isBotNameErr()}
                inputProps={{
                  maxLength: FormPropsConfig[FormPropsEnum.BOT_NAME].maxLength,
                  'aria-label': 'botName',
                }}
                maxRows={1}
                minRows={1}
                data-test-automation-id="bot-name-input-box"
              />
              {isBotNameErr() && (
                <ErrMsg
                  data-test-automation-id="bot-name-err-msg"
                  role="alert"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                  tabIndex={0}
                  {...getErrMsg()}
                />
              )}
            </BotNameContent>
            <BotPermission
              hidden={!permissions || permissions.length === 0}
              data-test-automation-id="bot-permission-list"
            >
              {(permissions || []).map((item) => (
                <li
                  key={item.id}
                  className={'botPermissionItem'}
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                  tabIndex={0}
                >
                  {item.label}
                </li>
              ))}
            </BotPermission>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
            <BotTip tabIndex={0} data-test-automation-id="bot-tip">
              Add a bot with bot name <span>@{formik.values.botName}</span>
            </BotTip>
            <BotButtons>
              <SecondaryButton
                data-test-automation-id="bot-cancel-button"
                className={'botButtonItem'}
                onClick={onCancel}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton
                data-test-automation-id="bot-authorize-button"
                className={'botButtonItem'}
                disabled={isFormValid() || isSubmitting}
                onClick={onAuthorize}
              >
                Authorize
              </PrimaryButton>
            </BotButtons>
          </form>
        </ConfirmForm>
      </Wrapper>
    </>
  );
};

export const BotProvisionConfirm = observer(BotProvisionConfirmPure);

const getToolTip = () => {
  // eslint-disable-next-line react/no-danger
  return {
    dangerouslySetInnerHTML: {
      __html: languageService.get(Language.BOT_NAME_RULES),
    },
  };
};

const resolveCurrentTheme = (
  brandId: string | undefined,
): RcThemeInput | undefined => {
  if (brandId) {
    const compatibility =
      rootStore.compatibilitiesStore.getCompatibilityById(brandId);
    return compatibility?.theme;
  }

  return undefined;
};
