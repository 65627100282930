import React from 'react';
import { styled } from '@ringcentral/juno';
import { isVimeoUrl, Vimeo } from './vimeo';
import { Youtube } from './youtube/Youtube';

const Mask = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;

interface Props {
  src: string;
  mask?: boolean;
}

export function Video({ src, mask = false }: Props) {
  return (
    <>
      {mask && <Mask />}
      {isVimeoUrl(src) ? <Vimeo src={src} /> : <Youtube src={src} />}
    </>
  );
}
