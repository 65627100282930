import { onMobile } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  margin: 40px auto 0;
  padding-bottom: 50px;

  ${onMobile} {
    width: auto;
  }
`;

export const HeroContainer = styled.div`
  height: 192px;
  width: calc(100% - 2px);
  margin: 0 auto 24px;
  border-radius: 6px;
  background: url(../../assets/images/page-hero.png) no-repeat 100% 100%;
  background-size: cover;
  overflow: hidden;

  ${onMobile} {
    height: 184px;
    margin-bottom: 4px;
  }
`;
