import React, { FC, useContext, useRef } from 'react';
import { RcTypography } from '@ringcentral/juno';
import { PageContentContext } from '@apw/lib/components/pageContent';
import { useLinksInHtml } from '@apw/lib/components/pageContent/shared';
import { IHtmlBlock } from '@apw/lib/types';
import styles from './htmlBlock.module.scss';

interface IHtmlBlockProps {
  data: IHtmlBlock;
  isFirst: boolean;
}

export const HtmlBlock: FC<IHtmlBlockProps> = ({ data }) => {
  const { styleTokens } = useContext(PageContentContext);
  const ref = useRef<any>(null);
  const htmlContent = (data.htmlContent || '').trim();

  useLinksInHtml(ref);

  if (!htmlContent) {
    return null;
  }

  return (
    <RcTypography
      component="div"
      variant={styleTokens.htmlBlock.htmlContent.variant}
      color={styleTokens.htmlBlock.htmlContent.color}
      className={`${styles.wrapper} htmlBlock`}
      ref={ref}
      data-test-automation-id="htmlBlock"
      data-testid="htmlBlock"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
};
