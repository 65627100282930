import React, { useEffect, useMemo, useState } from 'react';
import { ensureAction } from '@apw/core';
import { urlParameterKeys } from '@apw/core/url';
import { getSearchParams, patchSearchParams } from '@apw/core/url/url.service';
import { useCompatibilitySearchPath } from '@apw/routes/useCompatibilityPaths';
import { CoveoSearch } from '@apw/shared/coveoSearch/CoveoSearch';
import { RcIcon } from '@ringcentral/juno';
import { Close, Search } from '@ringcentral/juno-icon';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  SearchFilter,
  SearchFilterContainer,
  SearchFilterMobile,
} from './SearchBox.sc';

const SearchBoxPure = () => {
  const [searchText, setSearchText] = useState('');
  const [coveoDialogStatus, setCoveoDialogStatus] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const pathSearch = useCompatibilitySearchPath();

  const onTextChange = (e) => {
    const { value } = e.target;
    setSearchText(value);
  };

  const onKeyUp = (e) => {
    if (e.keyCode === 13) {
      goToSearchPage(searchText.trim());
    }
  };

  const onFocus = (e) => {
    setCoveoDialogStatus(true);
    e.target.blur();
  };

  const onClear = () => {
    setSearchText('');
    goToSearchPage('');
  };

  const keydownClear = (e) => {
    const actionFn = () => onClear();
    const isEnsureAction = ensureAction(e, actionFn);
    if (isEnsureAction) {
      e.stopPropagation();
    }
  };

  const onKeyDownOnSearchBox = (e) => {
    const actionFn = () => setCoveoDialogStatus(true);
    ensureAction(e, actionFn);
  };

  const searchBoxAriaLabel = useMemo(() => {
    return `Search apps${
      searchText.trim() ? `, keyword is ${searchText}` : ''
    }`;
  }, [searchText]);

  const goToSearchPage = (newSearchText: string) => {
    const newSearch = patchSearchParams({
      [urlParameterKeys.SEARCH_TEXT]: newSearchText || undefined,
      [urlParameterKeys.PARTNER_BADGE]: undefined,
      [urlParameterKeys.APPS]: undefined,
    });
    history.push(`${pathSearch}${newSearch}`);
  };

  useEffect(() => {
    const searchText = getSearchParams()[urlParameterKeys.SEARCH_TEXT];
    setSearchText((searchText as string) || '');
  }, [location.search]);

  const handleCoveoDialogClose = () => {
    setCoveoDialogStatus(false);
  };

  return (
    <>
      {coveoDialogStatus && (
        <CoveoSearch dialogClose={handleCoveoDialogClose} />
      )}
      <SearchFilterContainer
        data-test-automation-id="headerSearchBox"
        role="searchbox"
        aria-label={searchBoxAriaLabel}
        tabIndex={0}
        onKeyDown={onKeyDownOnSearchBox}
      >
        <SearchFilter
          variant="outline"
          clearBtn={false}
          InputProps={{
            startAdornment: <RcIcon size="small" symbol={Search} />,
            endAdornment: searchText && (
              <RcIcon
                onClick={onClear}
                onKeyDown={(e) => keydownClear(e)}
                size="small"
                symbol={Close}
                tabIndex={0}
                role="button"
                aria-label="clear"
                data-test-automation-id="clearKeywordBtn"
              />
            ),
            inputProps: {
              maxLength: 100,
              tabIndex: -1,
              'aria-hidden': 'true',
              'aria-label': 'Search app',
            },
          }}
          value={searchText}
          onFocus={(e) => onFocus(e)}
          onChange={onTextChange}
          onKeyUp={onKeyUp}
          placeholder="Search apps"
        />
      </SearchFilterContainer>

      <SearchFilterMobile data-test-automation-id="headerSearchBoxMobile">
        <RcIcon
          size="small"
          symbol={Search}
          onClick={(e) => onFocus(e)}
          role="button"
          tabIndex={0}
          aria-label="search"
        />
      </SearchFilterMobile>
    </>
  );
};

export const SearchBox = observer(SearchBoxPure);
