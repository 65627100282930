import React, { FC, useEffect, useState } from 'react';
import { transport } from '@apw/core';
import { Language, languageService } from '@apw/core/language';
import { useCommonError } from '@apw/hooks/useCommonError';
import {
  buildLeadFormikConfig,
  showEmailConsentCheckbox,
  trackContactUsSubmitted,
  UserForm,
} from '@apw/modules/profile';
import { useStores } from '@apw/stores';
import { IProfile } from '@apw/types';
import { RcCheckbox } from '@ringcentral/juno';
import { useFormik } from 'formik';
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import { LeadFormConfig } from './leadForm.config';
import {
  CheckboxContainer,
  LeadFormDialog,
  ReceiveEmailsLabel,
  RequirementsField,
} from './LeadForm.sc';

const Requirements: FC<{ form }> = ({ form }) => {
  return (
    <RequirementsField
      data-test-automation-id="lead-form-field-requirement"
      name={LeadFormConfig.requirements.name}
      label={LeadFormConfig.requirements.label}
      autoComplete="off"
      fullWidth
      inputProps={{
        maxLength: LeadFormConfig.requirements.maxlength,
        'aria-label': LeadFormConfig.requirements.label,
      }}
      onChange={form.handleChange}
      value={form.values[LeadFormConfig.requirements.name]}
    />
  );
};

const ReceiveEmails: FC<{ form }> = ({ form }) => {
  const {
    values: { country },
  } = form;

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(showEmailConsentCheckbox(country));
  }, [country]);

  const label = (
    <ReceiveEmailsLabel data-test-automation-id="lead-form-field-receive-email">
      Opt into receiving additional information about our services, products,
      and exclusive offers through email, telephone, regular mail. You can{' '}
      <a
        href="https://go.ringcentral.com/subscription-center.html"
        target="_blank"
        rel="noreferrer"
        data-test-automation-id="lead-form-field-unsubscribe-email"
      >
        Unsubscribe
      </a>{' '}
      at any time.
    </ReceiveEmailsLabel>
  );

  if (visible) {
    return (
      <CheckboxContainer>
        <RcCheckbox
          data-test-automation-id="lead-form-field-receive-email-checkbox"
          label={label}
          name={LeadFormConfig.receiveEmails.name}
          onChange={form.handleChange}
          value={form.values[LeadFormConfig.receiveEmails.name]}
        />
      </CheckboxContainer>
    );
  }
  return null;
};

const extraControls = (form) => {
  return (
    <>
      <Requirements form={form} />
      <ReceiveEmails form={form} />
    </>
  );
};

const getDescription = (profile: IProfile) => {
  const description = profile.isNative
    ? Language.LEAD_FORM_DESCRIPTION
    : Language.PARTNER_LEAD_FORM_DESCRIPTION;
  return languageService.getWithContext(description, profile.publisher);
};

const LeadFormPure: FC<{
  profile: IProfile;
  onSubmit;
  onClose;
}> = ({ profile, onSubmit, onClose }) => {
  const { userStore } = useStores();
  const commonError = useCommonError();
  const form = useFormik(buildLeadFormikConfig());
  const {
    values: { country },
    setFieldValue,
  } = form;

  const handleSubmit = (errorHandler) => {
    transport
      .submitLeadForm(profile._id, profile.appName, form.values)
      .then(() => {
        trackContactUsSubmitted(profile, userStore.isLoggedIn);
        onSubmit();
      })
      .catch((res) => {
        const error = _.get(res, 'response.data');
        return errorHandler.handle(error);
      })
      .catch(() => {
        commonError.show();
      });
  };

  const updateReceiveEmail = (countryId) => {
    if (!showEmailConsentCheckbox(countryId)) {
      setFieldValue(LeadFormConfig.receiveEmails.name, false);
    }
  };

  useEffect(() => {
    updateReceiveEmail(country);
  }, [country]);

  return (
    <LeadFormDialog open>
      <UserForm
        data-test-automation-id="lead-form"
        form={form}
        description={getDescription(profile)}
        extraControls={extraControls(form)}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </LeadFormDialog>
  );
};

export const LeadForm = observer(LeadFormPure);
