import { RcThemeInput } from '@ringcentral/juno';
import {
  overridesFactory,
  paletteFactory,
} from '../../../../../shared/theme/utils';

export const alcatelColor = '#6B489D';

export const alcatelTheme: RcThemeInput = {
  palette: paletteFactory(alcatelColor),
  overrides: overridesFactory(alcatelColor),
};
