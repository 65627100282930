import { onMobile, overflowWrap, useProximaNovaRegular } from '@apw/core/sc';
import { RcTextField, styled } from '@ringcentral/juno';

export const BotButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  .botButtonItem {
    width: 240px !important;
    height: 40px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;

  ${onMobile} {
    ${BotButtons} {
      flex-direction: column-reverse;
      .botButtonItem {
        width: 100% !important;
        height: 45px;
        margin-bottom: 15px;

        &:first-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const ConfirmForm = styled.div`
  max-width: 560px;
  margin: 76px auto 0;
  padding: 30px;
`;

export const BotLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    border-radius: 16px;

    &.botLogo {
      flex: 0 0 auto;
      width: 90px;
      height: 90px;
    }
  }

  .botLogoIcon {
    padding: 0 6%;

    svg {
      stroke-width: 1px;
    }
  }

  .rcLogo {
    flex: 0 1 90px;
    max-width: 200px;
  }

  .rcLogIcon {
    width: 100%;
    height: 100%;

    svg {
      width: 100%;
      height: 90px;
    }
  }
`;

export const BotTitle = styled.div`
  margin: 20px 0 30px;
  font-size: 24px;
  font-weight: 300;
  text-align: center;

  .appName {
    font-weight: 700;
    ${overflowWrap('break-word')};
  }
`;

export const BotNameTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  font-weight: 700;

  .botName {
    vertical-align: middle;
  }

  .botNameTooltip {
    display: inline-flex;
    align-items: center;
    margin-left: 5px;
  }
`;

export const TooltipContent = styled.div`
  padding: 13px 5px;
  font-size: 15px;
  font-weight: 500;
  ${useProximaNovaRegular};

  ol {
    margin: 7px 0 0;
    padding-left: 16px;
  }
`;

export const BotNameContent = styled.div`
  width: 100%;

  div[class~='MuiOutlinedInput-root'] {
    padding: 0 !important;
  }

  input[class~='MuiOutlinedInput-input'] {
    padding: 9px 16px !important;
    line-height: 19px !important;
    ${useProximaNovaRegular};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    // change the background color after auto fill text
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
  }
`;

export const ErrMsg = styled.div`
  padding-top: 5px;
  color: #f95b5c;
  ${overflowWrap('break-word')};
`;

export const BotPermission = styled.div`
  max-height: 200px;
  margin-top: 20px;
  padding: 20px 20px 20px 40px;
  overflow-y: auto;
  list-style-type: disc;
  background: #f5f5f5;
  border-radius: 4px;

  .botPermissionItem {
    font-size: 15px;
    text-align: left;
    color: #797979;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const BotTip = styled.div`
  margin-top: 20px;
  ${overflowWrap('break-word')};
  font-size: 15px;
  text-align: left;
  color: #686869;

  span {
    font-weight: 700;
  }
`;

export const TextField = styled(RcTextField)`
  display: flex;
  width: auto;
`;
