import { prepareExternalUrl } from '@apw/core/url/url.service';
import { BotProvisionConfirm } from '@apw/modules/botProvision';
import { Download } from '@apw/modules/download/Download';
import { MyApps } from '@apw/modules/myApps';
import { Page } from '@apw/modules/page';
import { ProfilePreviewPage } from '@apw/modules/profile/preview';
import ProfileNotAvailableForPartner from '@apw/modules/profile/profileNotAvailableForPartner/ProfileNotAvailableForPartner';
import { SearchPage } from '@apw/modules/search';
import { getCompatibilitySearchPath } from '@apw/routes/getCompatibilityPaths';
import { IRoute } from '@apw/routes/route.interface';
import { withQueryParamCleaner } from '@apw/shared';
import { rootStore } from '@apw/stores';

enum RouteSettingsProperty {
  homePage = 'homePage',
  searchPage = 'searchPage',
  profilePreviewPage = 'profilePreviewPage',
  appInstallPage = 'appInstallPage',
  myAppsPage = 'myAppsPage',
  profileNotAvailableForPartner = 'profileNotAvailableForPartner',
  download = 'download',
}

const MyAppsWithQueryParamCleaner = withQueryParamCleaner(MyApps, []);

export const getRouteSettings = (): Record<RouteSettingsProperty, IRoute> => ({
  homePage: {
    path: '/',
    exact: true,
    component: Page,
    data: {
      isLandingPage: true,
    },
  },
  searchPage: {
    path: rootStore.compatibilitiesStore.items.map((compatibility) =>
      getCompatibilitySearchPath(compatibility.slug),
    ),
    exact: true,
    component: SearchPage,
    data: {
      isSearchPage: true,
    },
  },
  profilePreviewPage: {
    path: '/preview/:profileId',
    component: ProfilePreviewPage,
  },
  appInstallPage: {
    path: '/:appId/install',
    component: BotProvisionConfirm,
    data: {
      auth: true,
    },
  },
  myAppsPage: {
    path: '/my-apps',
    component: MyAppsWithQueryParamCleaner,
    data: {
      auth: true,
      redirect: prepareExternalUrl('/'),
    },
  },
  profileNotAvailableForPartner: {
    path: '/not-available',
    exact: true,
    component: ProfileNotAvailableForPartner,
  },
  download: {
    path: '/download',
    component: Download,
    exact: true,
  },
});
