import React from 'react';
import {
  IMedia,
  MediaPropEnum,
  MediaTypeEnum,
} from '../../../../../../types/profile/shared/media.interface';
import { Screenshot } from './screenshot/Screenshot';
import { Video } from './video';

interface Props {
  media: IMedia;
  mask?: boolean;
}

export function Media({ media, mask = false }: Props) {
  return media[MediaPropEnum.TYPE] === MediaTypeEnum.VIDEO ? (
    <Video src={media[MediaPropEnum.URL]} mask={mask} />
  ) : (
    <Screenshot src={media[MediaPropEnum.URL]} />
  );
}
