import { palette2, styled, typography } from '@ringcentral/juno';

export const Container = styled.div`
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid ${palette2('neutral', 'l02')};
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.25px;
  ${typography('subheading2')};
  color: ${palette2('neutral', 'f06')};
`;
