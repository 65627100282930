import { RefObject, useEffect, useState } from 'react';
import { useDebounce } from '@ringcentral/juno';
import { PROFILE_DETAIL_PADDING_BOTTOM } from '../constants';

const getTabsBodyMinHeight = (
  scrollContainer: HTMLElement,
  tabsHeaderEl: HTMLElement,
) => {
  const totalHeight = scrollContainer.clientHeight;

  let deductedHeight =
    tabsHeaderEl.offsetTop +
    tabsHeaderEl.clientHeight -
    scrollContainer.scrollTop;

  deductedHeight = deductedHeight > 0 ? deductedHeight : 0;

  let result = totalHeight - deductedHeight - PROFILE_DETAIL_PADDING_BOTTOM;

  result = result > 0 ? result : 0;

  return `${result}px`;
};
const useTabsBodyMinHeight = (
  scrollContainerRef: RefObject<HTMLElement | null>,
  tabsHeaderRef: RefObject<HTMLElement | null>,
) => {
  const [tabsBodyMinHeight, setTabsBodyMinHeight] = useState('auto');

  const onScrollDebounced = useDebounce(() => {
    const scrollContainer = scrollContainerRef.current;
    const tabsHeader = tabsHeaderRef.current;

    if (!scrollContainer || !tabsHeader) {
      return;
    }

    const cssValue = getTabsBodyMinHeight(scrollContainer, tabsHeader);
    setTabsBodyMinHeight(cssValue);
  }, 100);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (!scrollContainer) {
      return;
    }

    scrollContainer.addEventListener('scroll', onScrollDebounced);

    return () => {
      scrollContainer.removeEventListener('scroll', onScrollDebounced);
    };
  }, [scrollContainerRef.current]);

  return tabsBodyMinHeight;
};

export { useTabsBodyMinHeight };
