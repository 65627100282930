import { onMobile } from '@apw/core/sc';
import { RcButton, RcDialog, styled } from '@ringcentral/juno';

export const Container = styled(RcDialog)`
  [class~='MuiDialog-paper'] {
    max-width: 760px !important;
    height: 721px !important;
  }

  ${onMobile} {
    max-width: none !important;
    max-height: none !important;
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
  }
`;

export const SkipButton = styled(RcButton).attrs(() => ({
  variant: 'plain',
}))`
  position: relative !important;
  left: 170px;
  ${onMobile} {
    left: 0;
  }
`;
