import React, { useContext, useMemo, useState } from 'react';
import { ProfileContext } from '@apw/components/profileDetail/profile.context';
import MobileVideoDialog from '@apw/components/profileDetail/profileBody/overviewTab/overviewSlider/mobileVideoDialog/MobileVideoDialog';
import {
  Mask,
  SlideBackground,
} from '@apw/components/profileDetail/profileBody/overviewTab/overviewSlider/overviewSlider.sc';
import { OverviewSliderDialog } from '@apw/components/profileDetail/profileBody/overviewTab/overviewSlider/overviewSliderDialog/OverviewSliderDialog';
import OverviewSlideWrap from '@apw/components/profileDetail/profileBody/overviewTab/overviewSlider/overviewSlideWrap/overviewSlideWrap';
import { Breakpoints, SliderEnhance as Slider } from '@apw/components/slider';
import oneTrustHelper from '@apw/core/onetrust';
import { isMobile } from '@apw/core/osDetector';
import { MediaProps, MediaType } from '@apw/types/media.interface';
import ScreenshotSlide from './screenshotSlide/ScreenshotSlide';
import { isVimeoUrl } from './vimeoSlide/vimeo-slide.service';
import VimeoSlide from './vimeoSlide/VimeoSlide';
import YoutubeSlide from './youtubeSlide/YoutubeSlide';

const OverviewSlider = () => {
  const [dialogState, setDialogState] = useState(false);
  const [mobileDialogState, setMobileDialogState] = useState(false);
  const [mobileVideoSrc, setMobileVideoSrc] = useState('');
  const [initialDialogIndex, setInitialDialogIndex] = useState(1);
  const profile = useContext(ProfileContext);
  const media = useMemo(() => profile.media || [], [profile]);
  const mediaFiltered = useMemo(() => {
    return oneTrustHelper.targetingCookiesEnabled
      ? media
      : media.filter((md) => md[MediaProps.TYPE] === MediaType.SCREENSHOT);
  }, [media]);
  const childrenTemp = useMemo(() => {
    return [
      ...mediaFiltered.map((media) => {
        if (media[MediaProps.TYPE] === MediaType.VIDEO) {
          return (
            <React.Fragment>
              <Mask />
              <OverviewSlideWrap>
                {isVimeoUrl(media[MediaProps.URL]) ? (
                  <VimeoSlide url={media[MediaProps.URL]} />
                ) : (
                  <YoutubeSlide url={media[MediaProps.URL]} />
                )}
              </OverviewSlideWrap>
            </React.Fragment>
          );
        }
        return (
          <OverviewSlideWrap>
            <ScreenshotSlide src={media[MediaProps.URL]} />
          </OverviewSlideWrap>
        );
      }),
    ];
  }, [profile]);
  const breakpoints: Breakpoints = useMemo<Breakpoints>(() => {
    return childrenTemp.length === 1
      ? {
          0: { size: 1, gap: 0 },
          768: { size: 1, gap: 0 },
        }
      : {
          0: { size: 1, gap: 0, useIndicator: childrenTemp.length > 1 },
          768: { size: 2, gap: 15, useArrow: childrenTemp.length > 2 },
        };
  }, []);

  const handleSlideClick = (slideIndex) => {
    if (!isMobile()) {
      setInitialDialogIndex(slideIndex);
      setDialogState(true);
      return;
    }
    const index = slideIndex - 1;
    if (mediaFiltered[index][MediaProps.TYPE] === MediaType.VIDEO) {
      setMobileVideoSrc(mediaFiltered[index][MediaProps.URL]);
      setMobileDialogState(true);
    }
  };
  return childrenTemp.length > 0 ? (
    <SlideBackground>
      <Slider breakpoints={breakpoints} onClick={handleSlideClick}>
        {childrenTemp}
      </Slider>
      <OverviewSliderDialog
        initialDialogIndex={initialDialogIndex}
        dialogState={dialogState}
        setDialogState={setDialogState}
      />
      <MobileVideoDialog
        video={mobileVideoSrc}
        dialogState={mobileDialogState}
        setDialogState={setMobileDialogState}
      />
    </SlideBackground>
  ) : null;
};

export default OverviewSlider;
