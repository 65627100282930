import React, { FC } from 'react';
import { getGalleryIconNode } from '@apw/shared/navigation/compatibilitySelector/shared/getGalleryIconNode';
import { breakpoints } from '@apw/shared/theme';
import { ICompatibility } from '@apw/stores/compatibility';
import { styled } from '@ringcentral/juno';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  @media ${breakpoints.mobile} {
    height: 57px;
  }
`;

const GalleryName = styled.span`
  font-size: 16px;
  line-height: 16px;
  color: #16181d;
  margin-left: 8px;
  display: inline-block;

  @media ${breakpoints.mobile} {
    font-size: 18px;
  }
`;

interface ICurrentGalleryIndicatorProps {
  currentCompatibility: ICompatibility;
}

export const CurrentGalleryIndicator: FC<ICurrentGalleryIndicatorProps> = (
  props,
) => {
  const { currentCompatibility } = props;

  const { galleryName, galleryIcon, galleryIconType, className, name } =
    currentCompatibility;

  return (
    <Wrapper
      data-test-automation-id="currentGalleryIndicator"
      tabIndex={0}
      aria-label={`current brand is ${name}`}
    >
      {getGalleryIconNode(galleryIcon, galleryIconType, className)}
      <GalleryName>{galleryName}</GalleryName>
    </Wrapper>
  );
};
