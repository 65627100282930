import { Language, languageService } from '@apw/core/language';
import { getPhoneMaxlength, requireState } from '@apw/modules/profile';
import * as Yup from 'yup';

interface UserFormFieldConfig {
  label?: string;
  name: string;
  minlength?: number;
  maxlength?: number;
  value?: string;
}

export const userFormConfig: { [key: string]: UserFormFieldConfig } = {
  firstName: {
    label: 'First name',
    name: 'firstName',
    maxlength: 40,
  },
  lastName: {
    label: 'Last name',
    name: 'lastName',
    maxlength: 64,
  },
  jobTitle: {
    label: 'Job title',
    name: 'jobTitle',
    maxlength: 64,
  },
  workEmail: {
    label: 'Work email',
    name: 'workEmail',
    maxlength: 255,
  },
  callingCode: {
    name: 'callingCode',
    value: '1',
  },
  phoneNumber: {
    label: 'Business phone number',
    name: 'phoneNumber',
    minlength: 6,
  },
  companyName: {
    label: 'Company name',
    name: 'companyName',
    maxlength: 128,
  },
  companyType: {
    label: 'Company type',
    name: 'companyType',
  },
  country: {
    label: 'Country',
    name: 'country',
  },
  state: {
    label: 'State/Province',
    name: 'state',
  },
  numberOfEmployees: {
    label: 'Number of employees',
    name: 'employeesNumber',
  },
  city: {
    label: 'City',
    name: 'city',
    maxlength: 40,
  },
  recaptchaResponse: {
    name: 'recaptchaResponse',
  },
};

export const userFormSchema = Yup.object().shape({
  [userFormConfig.firstName.name]: Yup.string()
    .required(languageService.get(Language.FORM_ERROR_REQUIRED))
    .trim(),
  [userFormConfig.lastName.name]: Yup.string()
    .required(languageService.get(Language.FORM_ERROR_REQUIRED))
    .trim(),
  [userFormConfig.jobTitle.name]: Yup.string()
    .required(languageService.get(Language.FORM_ERROR_REQUIRED))
    .trim(),
  [userFormConfig.workEmail.name]: Yup.string()
    .required(languageService.get(Language.FORM_ERROR_REQUIRED))
    .email(languageService.get(Language.FORM_ERROR_EMAIL))
    .trim(),
  [userFormConfig.callingCode.name]: Yup.string(),
  [userFormConfig.phoneNumber.name]: Yup.string()
    .required(languageService.get(Language.FORM_ERROR_REQUIRED))
    .min(
      userFormConfig.phoneNumber.minlength || 6,
      languageService.get(Language.FORM_ERROR_PHONE_NUMBER),
    )
    .trim()
    .when(userFormConfig.callingCode.name, (callingCode, schema) => {
      const maxLength = getPhoneMaxlength(callingCode);
      const reg = callingCode ? /^[-\d()\t ]+$/ : /^[-\d()\t +]+$/;
      return schema
        .max(maxLength)
        .matches(reg, languageService.get(Language.FORM_ERROR_PHONE_NUMBER));
    }),
  [userFormConfig.companyName.name]: Yup.string()
    .required(languageService.get(Language.FORM_ERROR_REQUIRED))
    .trim(),
  [userFormConfig.companyType.name]: Yup.string()
    .required(languageService.get(Language.FORM_ERROR_REQUIRED))
    .trim(),
  [userFormConfig.country.name]: Yup.string()
    .required(languageService.get(Language.FORM_ERROR_REQUIRED))
    .trim(),
  [userFormConfig.state.name]: Yup.string()
    .trim()
    .when(userFormConfig.country.name, (countryId, schema) => {
      if (requireState(countryId)) {
        return schema.required(
          languageService.get(Language.FORM_ERROR_REQUIRED),
        );
      }
    }),
  [userFormConfig.numberOfEmployees.name]: Yup.string()
    .required(languageService.get(Language.FORM_ERROR_REQUIRED))
    .trim(),
  [userFormConfig.city.name]: Yup.string()
    .required(languageService.get(Language.FORM_ERROR_REQUIRED))
    .trim(),
  [userFormConfig.recaptchaResponse.name]: Yup.string().required(
    languageService.get(Language.GOOGLE_RECAPTCHA_ERROR_REQUIRED),
  ),
});
