import { useEffect, useRef } from 'react';

export const usePrevious = (value) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
