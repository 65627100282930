import React, { useContext } from 'react';
import { ProfileContext } from '@apw/components/profileDetail/profile.context';
import { CertifiedAppDisclaimer } from '@apw/components/profileDetail/profileBody/overviewTab/certifiedAppDisclaimer';
import OverviewSlider from '@apw/components/profileDetail/profileBody/overviewTab/overviewSlider/OverviewSlider';
import { RatingsAndReviews } from '@apw/components/profileDetail/profileBody/overviewTab/ratingsAndReviews/RatingsAndReviews';
import { ResourceLinks } from '@apw/components/profileDetail/profileBody/overviewTab/resourceLinks/ResourceLinks';
import { TagList } from '@apw/components/profileDetail/profileBody/overviewTab/tagList';
import { breakpoints } from '@apw/shared/theme/utils';
import { useStores } from '@apw/stores';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { observer } from 'mobx-react';
import {
  DetailContent,
  FeaturedInContainer,
  H1Tag,
  HelpfulLinksContainer,
  InstallationContainer,
  LeftContent,
  LongDescContainer,
  PartnerCompatibilityContainer,
  RequirementsContainer,
  RightContent,
  SupportContainer,
  TagListContainer,
  TwoColumnsLayout,
  Wrapper,
} from './overviewTab.sc';

export const OverviewTab = observer(() => {
  const profile = useContext(ProfileContext);
  const isMobile = useMediaQuery(breakpoints.mobile);
  const { userStore } = useStores();

  if (!profile) {
    return null;
  }

  const featuredIn = <FeaturedInContainer pages={profile.galleryPageList} />;

  return (
    <Wrapper>
      <OverviewSlider />
      <TwoColumnsLayout>
        <LeftContent>
          {profile.appRatingEnabled && (
            <RatingsAndReviews ratings={profile.appRating} />
          )}
          <TagListContainer>
            <TagList tagType="CATEGORIES" tagList={profile.categoryTags} />
            <TagList tagType="PRODUCTS" tagList={profile.productTags} />
          </TagListContainer>
          <HelpfulLinksContainer links={profile.helpfulLinks} />
          {!userStore.isLoggedIn && (
            <PartnerCompatibilityContainer
              partnerProfiles={profile.partnerProfiles}
            />
          )}
          {profile.certifiedBadge && <CertifiedAppDisclaimer />}
          {isMobile && featuredIn}
        </LeftContent>
        <RightContent>
          <H1Tag data-test-automation-id={`h1-tag`}>
            {(profile.h1Tag && profile.h1Tag.trim()) || 'More details'}
          </H1Tag>
          <DetailContent>
            <LongDescContainer className={''} content={profile.detailedDes} />
            <ResourceLinks
              className={''}
              links={profile.processResourceLinks}
            />
            <RequirementsContainer requirements={profile.processRequirements} />
            <InstallationContainer
              className={''}
              content={profile.installation}
            />
            <SupportContainer content={profile.processSupport} />
            {!isMobile && featuredIn}
          </DetailContent>
        </RightContent>
      </TwoColumnsLayout>
    </Wrapper>
  );
});
