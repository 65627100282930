import { useContext } from 'react';
import { Language, languageService } from '@apw/core/language';
import { urlParameterKeys } from '@apw/core/url';
import { GlobalInformDialogContext, IDialogController } from '@apw/shared';
import { useStores } from '@apw/stores';
import { useHistory } from 'react-router-dom';
import * as botProvisionService from '../bot-provision.service';
import { useBotProvision } from '../useBotProvision';
import * as botInstallerService from './bot-installer.service';

export const useBotManagement = () => {
  const dialog = useContext<IDialogController>(GlobalInformDialogContext);
  const botProvision = useBotProvision();
  const history = useHistory();
  const { compatibilityStore } = useStores();

  const installBot = async ({
    appId,
    knownErrors = [] as string[],
  }): Promise<any> => {
    const checkers = [await botProvision.checkAccountTier()];
    const [isTierInstallable] = await Promise.all(checkers);
    if (!isTierInstallable) {
      return Promise.reject();
    }

    if (botProvisionService.hasNoManagementPermission(knownErrors)) {
      botProvision.handleNoPermissionError({
        onInstallation: true,
      });
      return Promise.reject();
    }

    if (botProvisionService.isHipaaUser(knownErrors)) {
      botProvision.handleHipaaError();
      return Promise.reject();
    }

    if (botProvisionService.isBotBrandNotSupported(knownErrors)) {
      botProvision.handleBrandNotSupportedError();
      return Promise.reject();
    }

    navigateToBotInstaller(appId, {
      landingUrl: window.location.href,
    });
    return Promise.resolve();
  };

  const removeBot = ({ botClientId, botName }): Promise<any> => {
    return new Promise<any>((resolve) => {
      dialog
        .show({
          title: languageService.get(Language.REMOVE_BOT_CONFIRMATION_TITLE),
          content: languageService.getWithContext(
            Language.REMOVE_BOT_CONFIRMATION,
            botName,
          ),
          hiddenCloseIcon: true,
          actions: [
            { label: 'Cancel', dialogResult: false, variant: 'text' },
            { label: 'Remove', dialogResult: true },
          ],
        })
        .afterClosed((isRemovalConfirmed) => {
          if (isRemovalConfirmed) {
            resolve(requestRemoval({ botClientId, botName }));
          } else {
            resolve(false);
          }
        });
    });
  };

  const requestRemoval = ({ botClientId, botName }): Promise<any> => {
    return botProvisionService.removeBot(botClientId).catch((e) => {
      const errorCode = e?.response?.data?.error;
      if (errorCode === 'BOT_EXTENSION_NOT_FOUND') {
        botProvision.handleAlreadyRemovedError({
          installedBotName: botName,
        });
        return false;
      }
      botProvision.handleError(errorCode, {
        onRemoval: true,
      });
      return Promise.reject();
    });
  };

  const navigateToBotInstaller = (appId: string, options: any = {}) => {
    const { landingUrl } = options;
    const botInstallerRelativePath =
      botInstallerService.getBotInstallerRelativePath(appId);
    history.push(
      `${botInstallerRelativePath}?${urlParameterKeys.LANDING_URL}=${landingUrl}&${urlParameterKeys.BRAND_ID}=${compatibilityStore.brandId}`,
    );
  };

  return {
    installBot,
    removeBot,
    requestRemoval,
  };
};
