import React from 'react';
import { RcIcon } from '@ringcentral/juno';
import { Previous } from '@ringcentral/juno-icon';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Button } from './BackToEditButton.sc';

export const BackToEditButton = observer(() => {
  const history = useHistory();

  const handleClick = () => {
    if (history.length > 1) {
      history.goBack();
      return;
    }

    window.close();
  };

  return (
    <Button
      variant="text"
      color="text.primary"
      onClick={handleClick}
      data-test-automation-id="back-to-edit-button"
    >
      <RcIcon symbol={Previous} size="medium" />
      <span>Back to Edit Profile</span>
    </Button>
  );
});
