import { IProcessedProfile } from '@apw/components/profileDetail';
import { api, safeWindowOpen } from '@apw/core';
import {
  allowExtends,
  allowUrlPrefix,
} from '@apw/modules/profile/downloadGate/downloadableLinks';
import * as _ from 'lodash';

export const isDownloadableUrl = (url: string) => {
  return isDownloadableExt(url) || isDownloadablePrefix(url);
};

const isDownloadableExt = (url: string) => {
  const path = url.split(/[?#]/)[0];
  const tokens = path.split(/\.(?!.*\/)/);
  const ext = tokens[tokens.length - 1];
  return _.includes(allowExtends, ext);
};

const isDownloadablePrefix = (url: string) => {
  const res = url.replace(/https*/, '').split(/[?#]/);
  const prefix = Array.isArray(res) ? res[0] : null;
  return _.includes(allowUrlPrefix, prefix);
};

export const directDownload = (
  profile: IProcessedProfile,
  downloadUrl: string,
  isLogin: boolean,
) => {
  safeWindowOpen(downloadUrl);
  if (isLogin && profile._id) {
    sendMarketoForm(profile._id, profile.appName);
  }
};

export const sendMarketoForm = (profileId: string, profileName: string) => {
  const endpoint = `/api/login-gate-form/${profileId}`;
  api.post<any>(
    endpoint,
    {
      appProfileName: profileName,
    },
    {
      responseType: 'text',
    },
  );
};

const needAppendBrandId2DownloadLinkVanityURL = ['rc-app'];

export const shouldAppendBrandId2DownloadLink = (applicationId: string) => {
  return needAppendBrandId2DownloadLinkVanityURL.includes(applicationId);
};
