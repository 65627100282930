import {
  overflowWrap,
  FontWeight,
  Color,
  FontColor,
  onMobile,
} from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

const desktopLogoSize = 72;
const mobileLogoSize = 48;

export const BotItem = styled.div`
  display: flex;
  align-items: center;
  min-height: 108px;
  padding: 20px;
  cursor: pointer;
  background-color: #f9f9f9;
  border-radius: 8px;

  .contentWrapper {
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 0;
    height: 100%;
    min-height: ${desktopLogoSize}px;
    color: ${Color.black};
  }

  .botLogoWrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    height: ${desktopLogoSize}px;
    overflow: hidden;
  }

  .headerWrapper {
    display: flex;
    align-items: center;
    margin-left: ${desktopLogoSize}px;
    padding: 0 20px;
    font-size: 18px;
    font-weight: ${FontWeight.bold};
  }

  .botDescWrapper {
    margin-top: 10px;
    margin-left: ${desktopLogoSize}px;
    padding: 0 20px;
    font-size: 15px;
    ${overflowWrap('break-word')};
  }

  ${onMobile} {
    display: block;
    height: auto;
    padding: 15px;

    .contentWrapper {
      width: 100%;
      overflow: hidden;
    }

    .botLogoWrapper {
      left: -${mobileLogoSize}px;
      height: ${mobileLogoSize}px;
    }

    .headerWrapper {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-left: ${mobileLogoSize}px;
      min-height: ${mobileLogoSize}px;
      padding: 0 0 0 15px;
    }

    .botDescWrapper {
      margin-top: 16px;
      margin-left: 0;
      padding: 0;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &:not(:first-child) {
    margin-top: 16px;
  }

  &:last-child {
    margin-bottom: 20px;
  }

  &:not(:focus-visible) {
    outline: none;
  }
`;

export const BotNameWrapper = styled.div`
  max-width: 100%;
`;

export const BotName = styled.div`
  ${overflowWrap('break-word')};
  display: inline;
  max-width: 100%;
  margin-bottom: 4px;
  font-weight: ${FontWeight.bold};
  color: ${FontColor.black};
  line-height: 26px;
`;

export const BotDesc = styled.div`
  color: ${Color.black};
`;
