import { RcThemeInput } from '@ringcentral/juno';
import {
  overridesFactory,
  paletteFactory,
} from '../../../../../shared/theme/utils';

export const ecotelColor = '#066FAC';

export const ecotelTheme: RcThemeInput = {
  palette: paletteFactory(ecotelColor),
  overrides: overridesFactory(ecotelColor),
};
