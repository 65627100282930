import React, { FC, useContext } from 'react';
import { RcTypography } from '@ringcentral/juno';
import { RcTypographyVariant } from '@ringcentral/juno/components/Typography/Typography';
import { StoreContext } from '@apw/lib/components/profileDetail/StoreContext';
import writeNotes from '../../../assets/images/write_notes.svg';
import { Container, Image } from './noRatingsSection.sc';

export interface INoRatingsSectionProps {
  variant?: RcTypographyVariant;
  className?: string;
}

export const NoRatingsSection: FC<INoRatingsSectionProps> = ({
  variant = 'subheading1',
  className = '',
}) => {
  const { translation } = useContext(StoreContext);
  return (
    <Container className={className}>
      <Image data-test-automation-id="no-ratings-img" symbol={writeNotes} />
      <RcTypography
        color="neutral.f06"
        variant={variant}
        data-test-automation-id="no-ratings-msg"
        tabIndex={0}
      >
        {translation.ratingsAndReviews.noRatingsMsg}
      </RcTypography>
    </Container>
  );
};
