import React, { useMemo } from 'react';
import {
  ContentWrapper,
  DescWrapper,
  HeaderTitle,
  HeaderWrapper,
  ImageWrapper,
  Wrapper,
} from '@apw/components/pageContent/headerBlock/headerBlock.sc';
import { clickAToNewTab } from '@apw/components/pageContent/shared';
import { palette2, styled } from '@ringcentral/juno';
import { HeaderBlockInterface } from './typings';

function HeaderBlock(props: {
  className: string;
  data: HeaderBlockInterface;
  indexInPageContent: number;
}) {
  const {
    className = '',
    data: {
      title = '',
      desc = '',
      image = '',
      layout = 'textWithImageOnRight',
    } = {},
    indexInPageContent,
  } = props;

  const descContent = {
    dangerouslySetInnerHTML: {
      __html: desc,
    },
  };

  const notImageStyle = () => {
    return image ? '' : 'notImage';
  };

  const getLayoutClassName = (): string => {
    const layoutClassName = {
      textWithImageOnLeft: 'textWithImageOnLeft',
      textWithImageOnRight: 'textWithImageOnRight',
      textWithBackgroundImage: 'textWithBackgroundImage',
    };
    return layoutClassName[layout] || '';
  };

  const titleA11yProps = useMemo(() => {
    return indexInPageContent === 0
      ? {
          role: 'heading',
          'aria-level': 1,
        }
      : {};
  }, [indexInPageContent]);

  return (
    <Wrapper
      className={`${className}`}
      data-test-automation-id={`header-block`}
    >
      <ContentWrapper className={`${getLayoutClassName()} ${notImageStyle()}`}>
        <HeaderWrapper>
          {image && (
            <ImageWrapper>
              <img
                data-test-automation-id={'header-block-image'}
                src={image}
                alt={title}
              />
            </ImageWrapper>
          )}
          <HeaderTitle
            data-test-automation-id={'header-block-title'}
            {...titleA11yProps}
          >
            {title}
          </HeaderTitle>
        </HeaderWrapper>
        {desc && (
          <DescWrapper
            data-test-automation-id={'header-block-desc'}
            {...descContent}
            ref={(ele) => clickAToNewTab(ele)}
          />
        )}
      </ContentWrapper>
    </Wrapper>
  );
}

const StyledHeaderBlock = styled(HeaderBlock)`
  & {
    a {
      color: ${palette2('text', 'secondary')};
    }
  }
`;

export { StyledHeaderBlock as HeaderBlock };
