import { onMobile } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const Section = styled.div`
  &:not(:first-child) {
    margin-top: 28px;

    ${onMobile} {
      margin-top: 24px;
    }
  }
`;
