import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  A11yWrapper,
  Back,
  Indicator,
  IndicatorContainer,
  Next,
  OverflowWrap,
  Slide,
  SliderStyle,
} from '@apw/components/slider/slider.sc';
import { ensureAction } from '@apw/core';
import { ChevronLeft, ChevronRight } from '@ringcentral/juno-icon';
import { debounce } from 'lodash';
import { useDrag } from 'react-use-gesture';
import { CommonGestureState, DragState } from 'react-use-gesture/dist/types';
import { SliderRefMethods } from './slider.interface';

interface Props {
  size: number;
  gap: number;
  useArrow?: boolean;
  useIndicator?: boolean;
  initialSlideIndex?: number;
  initialPageIndex?: number;
  onClick?: Function;
  onChangePage?: Function;
  children?: React.ReactNode[];
  dragEnable?: boolean;
}

const Slider = React.forwardRef<SliderRefMethods, Props>(
  (
    {
      size = 1,
      gap = 0,
      useArrow = false,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      useIndicator = false,
      initialSlideIndex,
      initialPageIndex = 1,
      onClick = () => {},
      onChangePage = () => {},
      dragEnable = true,
      children = [],
    },
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      nextPage,
      backPage,
      goPage,
    }));

    const computedIndex = (index: number) => {
      if (index < 1) return 1;
      // eslint-disable-next-line no-unsafe-optional-chaining
      const leftOfLatestPage = children?.length - size + 1;
      return index <= leftOfLatestPage ? index : leftOfLatestPage;
    };

    const [curIndex, setCurIndex] = useState(() => {
      if (initialSlideIndex) return initialSlideIndex;
      return computedIndex((initialPageIndex - 1) * size + 1);
    });
    const widthPercent = useMemo(() => 100 / size, [size]);
    const distancePercent = useMemo(
      () => -(curIndex - 1) * widthPercent,
      [curIndex, widthPercent],
    );
    const pageCount = useMemo(
      () => Math.ceil(children.length / size),
      [children, size],
    );
    const curPage = useMemo(
      () => Math.ceil((curIndex - 1) / size) + 1,
      [curIndex, size],
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [offsetX, setOffsetX] = useState(0);

    const isFirstPage = useMemo(() => curPage === 1, [curPage]);
    const isLatestPage = useMemo(
      () => curPage === pageCount,
      [curPage, pageCount],
    );

    const nextPage = () => {
      goSlide(curIndex + size);
    };
    const backPage = () => {
      goSlide(curIndex - size);
    };
    const goPage = (p: number) => {
      goSlide(p * size);
    };

    const goSlide = (index: number) => {
      setCurIndex(computedIndex(index));
    };

    const keydownSlider = (event: KeyboardEvent, index: number) => {
      const actionFn = () => onClick(index + 1);
      ensureAction(event, actionFn);
    };

    const handleDrag = debounce((state: CommonGestureState & DragState) => {
      if (state.tap || !state.intentional) return;
      const [x] = state.movement;
      if (x < 0) nextPage();
      else backPage();
    }, 200);

    const bind = useDrag((state) => handleDrag(state), {
      axis: 'x',
      filterTaps: true,
      threshold: 10,
      enabled: dragEnable,
    });

    useEffect(() => {
      onChangePage(curPage);
    }, [curPage]);

    return (
      <>
        <OverflowWrap>
          <SliderStyle
            {...bind()}
            style={{
              transform: `translateX(${distancePercent}%) translateX(${offsetX}px)`,
              width: `calc(100% + ${(size - 1) * gap}px)`,
            }}
            data-test-automation-id="slide-container"
          >
            {children?.map((child, index) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <Slide
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                style={{
                  width: `${widthPercent}%`,
                  paddingRight: `${gap}px`,
                }}
                onClick={() => onClick(index + 1)}
                data-test-automation-id={`slide-${index + 1}`}
              >
                {child}
              </Slide>
            ))}
          </SliderStyle>
          <A11yWrapper role="list" tabIndex={-1}>
            {children?.map((item, index) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <div
                style={{
                  width: `${widthPercent}%`,
                  paddingRight: `${gap}px`,
                  marginLeft: index === 0 ? `${distancePercent}%` : '0',
                }}
                role="listitem"
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
                aria-label={`screen shot ${index + 1}`}
                onFocus={() => goSlide(index + 1)}
                onKeyDown={(e) => keydownSlider(e as any, index)}
              />
            ))}
          </A11yWrapper>
        </OverflowWrap>
        {useArrow && (
          <>
            <Back
              symbol={ChevronLeft}
              onClick={backPage}
              disabled={isFirstPage}
              aria-label="previous screen shot"
              data-test-automation-id={'previous-screen-shot-button'}
            />

            <Next
              symbol={ChevronRight}
              onClick={nextPage}
              disabled={isLatestPage}
              aria-label="next screen shot"
              data-test-automation-id={'next-screen-shot-button'}
            />
          </>
        )}
        {useIndicator && (
          <IndicatorContainer data-test-automation-id={`indicator-container`}>
            {Array.from(Array(pageCount)).map((_, index) => {
              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <Indicator
                  data-test-automation-id={`indicator-${index + 1}`}
                  className={`${curPage === index + 1 ? 'active' : ''}`}
                  onClick={() => goSlide(index + 1)}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                />
              );
            })}
          </IndicatorContainer>
        )}
      </>
    );
  },
);

export { Slider };
