import { onMobile } from '@apw/core/sc';
import { RcButton, styled } from '@ringcentral/juno';

export const BotItemAction = styled.div`
  ${onMobile} {
    margin-top: 16px;
    text-align: right;
  }
`;

export const BotActionBtn = styled(RcButton)`
  flex-shrink: 0;
  display: inline-block;
  height: 38px;
  border-radius: 4px;
`;
