import { useProximaNovaMedium, useProximaNovaRegular } from '@apw/core/sc';
import { RcButton, styled } from '@ringcentral/juno';

export const TagListWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

export const TagType = styled.h5`
  margin: 0 0 24px;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  ${useProximaNovaMedium};
`;

export const TagListContainer = styled.ul`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TagItem = styled.li`
  display: inline-block;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const TagItemButton = styled(RcButton)`
  height: 40px;
  border-radius: 2px;
`;

export const TagItemName = styled.span`
  font-size: 16px;
  font-weight: normal;
  ${useProximaNovaRegular};
`;
