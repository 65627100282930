import { mixpanel } from '@apw/core';
import { SearchClickProperties } from '@apw/core/tracker/types/search-click-properties.interface';
import {
  productConfig,
  supportedCategoryConfig,
} from '@apw/modules/search/filterBar';

export enum SearchResult {
  PROFILE = 'profile',
  PAGE = 'page',
  IPAAS = 'ipaas',
}

export enum SearchSource {
  FLYOUT_RESULTS = 'flyout results',
  SEARCH_RESULTS = 'search results',
}

export type SearchResultType =
  | SearchResult.PROFILE
  | SearchResult.PAGE
  | SearchResult.IPAAS;

export type SearchSourceType =
  | SearchSource.FLYOUT_RESULTS
  | SearchSource.SEARCH_RESULTS;

export const searchTracking = (properties: SearchClickProperties): void => {
  const params = {
    ...(properties.resultType === SearchResult.PROFILE && {
      productFilter: getProductDisplayName(properties.productFilter),
      categoryFilter: getCategoryDisplayName(properties.categoryFilter),
    }),
    ...{
      brand: properties.brand,
      searchTerm: properties.searchTerm,
      destinationURL: buildDestinationURL(properties.destinationURL),
      resultType: properties.resultType,
      clickDepth: properties.clickDepth,
      resultCount: properties.resultCount,
      source: properties.source,
    },
  };
  mixpanel.trackSearchClick(params);
};

const buildDestinationURL = (destinationLink = '') => {
  const httpPattern = /^https?:\/\//i;
  return httpPattern.test(destinationLink)
    ? destinationLink
    : `https://${document.domain}/apps${destinationLink}`;
};

const getProductDisplayName = (id = ''): string => {
  const product = productConfig[id];
  return product ? product.displayName : id;
};

const getCategoryDisplayName = (id = ''): string => {
  const category = supportedCategoryConfig[id];
  return category ? category.displayName : id;
};
