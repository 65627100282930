import React from 'react';
import { DropDownMenu } from '@apw/shared/menu/dropDownMenu';
import { StyledPrimaryNavigation } from '@apw/shared/menu/styledPrimaryNavigation';
import { CompatibilitySelector } from '@apw/shared/navigation';
import { observer } from 'mobx-react';
import { primaryNavigationMenuItem, Desktop, Mobile } from './menu.sc';

export const Menu = observer(() => {
  return (
    <React.Fragment>
      <Desktop data-test-automation-id="menu" role="navigation">
        <StyledPrimaryNavigation
          cssProp={{
            menuItem: primaryNavigationMenuItem,
          }}
        />
        <CompatibilitySelector />
      </Desktop>
      <Mobile data-test-automation-id="menu">
        <DropDownMenu />
      </Mobile>
    </React.Fragment>
  );
});
