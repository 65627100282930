import React, { FC } from 'react';
import { ProfileTile } from '@apw/components';
import { SearchResultSection } from '@apw/modules/search/searchResultSection';
import { getCompatibilityProfilePath } from '@apw/routes/getCompatibilityPaths';
import { SearchResult } from '@apw/shared/searchTracking';
import { useCurrentCompatibility } from '@apw/stores/compatibility';
import { ProfileListItemInterface } from '@apw/types';
import { observer } from 'mobx-react';

const ProfileSearchResultPure: FC<{
  profileList: ProfileListItemInterface[];
  handleClick: (clickLink, resultType, clickDepth: number) => void;
}> = (props) => {
  const { profileList, handleClick } = props;
  const compatibility = useCurrentCompatibility();

  const onProfileClick = (
    clickItem: ProfileListItemInterface,
    resultDepth: number,
  ) => {
    if (handleClick) {
      const defaultProfilePath = getCompatibilityProfilePath(
        compatibility.slug,
        clickItem.vanityUrl || clickItem._id,
      );
      const profilePath = clickItem.path || defaultProfilePath;
      handleClick(profilePath, SearchResult.PROFILE, resultDepth + 1);
    }
  };

  return (
    <SearchResultSection title="Apps, integrations, and extensions" isH1>
      {profileList.map((item, index) => (
        <ProfileTile
          app={item}
          key={item._id}
          handleClick={() => onProfileClick(item, index)}
        />
      ))}
    </SearchResultSection>
  );
};

export const ProfileSearchResult = observer(ProfileSearchResultPure);
