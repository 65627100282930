export enum InstallBotFormErrorEnum {
  BOT_NAME_FORMAT_ERROR = 'BOT_NAME_FORMAT_ERROR',
  OCCUPIED = 'OCCUPIED',
  BOT_NAME_OCCUPIED = 'BOT_NAME_OCCUPIED',
  DISABLE_BOT_NAME_OCCUPIED = 'DISABLE_BOT_NAME_OCCUPIED',
  BOT_BRAND_NOT_SUPPORTED = 'BOT_BRAND_NOT_SUPPORTED',
  BOT_ALREADY_INSTALLED = 'BOT_ALREADY_INSTALLED',
  BOT_SUBSCRIBE_WEB_HOOK_FAIL = 'BOT_SUBSCRIBE_WEB_HOOK_FAIL',
  BOT_HIPAA = 'BOT_HIPAA',
  NO_BOT_MANAGE_PERMISSION = 'NO_BOT_MANAGE_PERMISSION',
  BOT_CALL_REDIRECT_URI_FAIL = 'BOT_CALL_REDIRECT_URI_FAIL',
}
