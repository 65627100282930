import { MutableRefObject } from 'react';
import { uniqBy } from 'lodash';
import { action, computed, observable, reaction } from 'mobx';
import { RatingPopupOption } from '@apw/lib/components/ratingAndReview/typings/ratingPopup.interface';
import {
  IPublishedReviewItemsResponse,
  IReviewItem,
  ReviewStatus,
} from '@apw/lib/components/ratingAndReview/typings/reviewItem.interface';
import { ContentLayout } from '@apw/lib/shared/responsive';
import { IPaging, Profile } from '../../types';
import { profileProcessor } from './profileProcessor';
import {
  IProfileTranslation,
  ProfileTabKeysEnum,
  ProfileWithCustomProps,
} from './types';

type CarouselClickHandler = (index: number) => void;
type PagePathBuilder = (vanityUrl: string) => string;

type SelectedTab =
  | ProfileTabKeysEnum.OVERVIEW
  | ProfileTabKeysEnum.TECHNICAL_DETAILS
  | ProfileTabKeysEnum.RATINGS_AND_REVIEWS;

type ChangeSelectedTabFn = (key: SelectedTab) => void;

type SaveRatingFunc = (
  rating: RatingPopupOption,
  isEdit: boolean,
) => Promise<void>;

type IPublishedReviewItemsLoader = (
  page: number,
) => Promise<IPublishedReviewItemsResponse>;

type IMyReviewLoader = (skipFlagCheck?: boolean) => Promise<IReviewItem | null>;

type IMyReviewFromCacheLoader = () => IReviewItem | null;

type IOnDeleteReviewFn = (reviewItem: IReviewItem) => void;

export class ProfileDetailStore {
  @observable
  currentUserName: string;

  @observable
  profile: ProfileWithCustomProps | undefined;

  @observable
  onCarouselClick: CarouselClickHandler = () => {};

  @observable
  translation: IProfileTranslation = {
    appIconLabel: (appName: string) => `app icon for ${appName}`,
    defaultAppIconLabel: 'default app icon',
    premierPartner: 'PREMIER PARTNER',
    certifiedBadge: 'CERTIFIED',
    cancel: 'Cancel',
    update: 'Update',
    submit: 'Submit',
    back: 'Back',
    continue: 'Continue',
    getSupport: 'Get support',
    h1Tag: 'More details',
    categories: 'CATEGORIES',
    products: 'PRODUCTS',
    helpfulLinks: {
      title: 'HELPFUL LINKS',
      termsOfService: 'Terms of Service',
      privacyPolicy: 'Privacy Policy',
      documentation: 'Documentation',
      publisherWebsite: 'Publisher website',
      supportWebsite: 'Support website',
    },
    tabs: {
      overview: 'Overview',
      technicalDetails: 'Technical details',
      ratingsAndReviews: 'Ratings and reviews',
    },
    permissions: {
      title: 'App Scopes',
      desc: 'When authorized, this app will be granted the following scopes:',
      columns: {
        nameTitle: 'Scope',
        nameLabel: (permission: string) => `scope is ${permission}`,
        descTitle: 'Description',
        descLabel: (desc: string) => `description is ${desc}`,
      },
    },
    featuredIn: 'Featured in',
    carousel: {
      previousScreenshot: 'previous screen shot',
      nextScreenshot: 'next screen shot',
      screenshotNumber: (num: number) => `screen shot ${num}`,
    },
    ratingsAndReviews: {
      title: 'Ratings and reviews',
      basicInfo: {
        countLabelPrefix: 'Based on',
        countLabel: {
          singular: 'rating',
          plural: 'ratings',
        },
      },
      actions: {
        viewReviewsButton: 'View all customer reviews',
        writeReviewButton: 'Write a review',
      },
      overallRatings: {
        title: 'Overall ratings',
      },
      qualityRatings: {
        title: 'Quality ratings',
        itemLabels: {
          value: {
            label: 'Value',
            tooltip: 'Is this app worth your time?',
          },
          convenience: {
            label: 'Ease of use',
            tooltip: 'How easy was it to set up and use the app?',
          },
          feature: {
            label: 'Feature and functionality',
            tooltip: 'How well does the app meet your needs?',
          },
          support: {
            label: 'Support and documentation',
            tooltip: 'Were you able to get any help you needed?',
          },
        },
      },
      noRatingsMsg: 'Be the first to write a customer review',
      noReviewsMsg: 'No reviews yet',
      reviewItem: {
        you: 'You',
        inactiveUser: 'Inactive user',
        pending: 'Pending',
        editButton: 'Edit',
        deleteButton: 'Delete',
      },
      reviewsSection: {
        title: 'Reviews',
      },
      popup: {
        createMode: {
          title: (name) => `Rate your experience using ${name}`,
          saveMsg: 'Thanks for your submission. We will post it soon.',
        },
        editMode: {
          title: 'Update your rating and review',
          saveMsg:
            'Thanks for your submission. We will update your rating soon.',
        },
        reviewTextLabel: 'Write a review (optional)',
        overallSectionTitle: 'Overall rating',
        qualitySectionTitle: 'Quality ratings',
      },
      ratingTooltips: [
        'Terrible',
        'So-so',
        'Helpful',
        'Very helpful',
        'Awesome',
      ],
    },
  };

  @observable
  contentLayout: ContentLayout;

  @observable
  selectedTab: SelectedTab = ProfileTabKeysEnum.OVERVIEW;

  @observable
  onSelectedTabChanged?: ChangeSelectedTabFn;

  @observable
  pagePathBuilder: PagePathBuilder = () => '';

  @action.bound
  setCurrentUserName(userName: string) {
    this.currentUserName = userName;
  }

  @action.bound
  setContentLayout(contentLayout: ContentLayout) {
    this.contentLayout = contentLayout;
  }

  @action.bound
  setProfile(data: Profile) {
    this.profile = profileProcessor(data);
  }

  @action.bound
  setOnCarouselClick(onCarouselClick: CarouselClickHandler) {
    this.onCarouselClick = onCarouselClick;
  }

  @action.bound
  setTranslation(translation: IProfileTranslation): void {
    this.translation = translation;
  }

  @action.bound
  setSelectedTab(tabKey: SelectedTab) {
    this.selectedTab = tabKey;
  }

  @action.bound
  setOnSelectedTabChanged(changeSelectedTab: ChangeSelectedTabFn) {
    this.onSelectedTabChanged = changeSelectedTab;
  }

  @action
  setPagePathBuilder(pagePathBuilder: PagePathBuilder) {
    this.pagePathBuilder = pagePathBuilder;
  }

  @observable
  myReviewItem: IReviewItem | null = null;

  @observable
  isPublishedReviewItemsLoading = false;

  @observable
  publishedReviewItems: IReviewItem[] = [];

  @observable
  pagingForReviews: IPaging = {
    page: 1,
    pageStart: 0,
    pageEnd: 0,
    perPage: 5,
    totalElements: 0,
    totalPages: 1,
  };

  @observable
  saveRating: SaveRatingFunc;

  @observable
  publishedReviewItemsLoader?: IPublishedReviewItemsLoader;

  @observable
  myReviewLoader?: IMyReviewLoader;

  @observable
  myReviewFromCacheLoader?: IMyReviewFromCacheLoader;

  @observable
  onDeleteReview?: IOnDeleteReviewFn;

  @observable
  scrollContainerRef: MutableRefObject<HTMLElement | null>;

  @observable
  ratingPopupOpen = false;

  @observable
  ratingPopupLoading = false;

  @observable
  isTargetingCookiesEnabled: boolean;

  @computed
  get isOneColumnLayout() {
    return this.contentLayout === ContentLayout.ONE_COLUMN;
  }

  @computed
  get isTwoColumnLayout() {
    return this.contentLayout === ContentLayout.TWO_COLUMNS;
  }

  @computed
  get totalNumberForReviews() {
    const publishedReviewItemsCount = this.pagingForReviews?.totalElements || 0;

    return this.showMyPendingReview
      ? publishedReviewItemsCount + 1
      : publishedReviewItemsCount;
  }

  @computed
  get myPendingReviewItem() {
    return this.myReviewItem?.status === ReviewStatus.Pending
      ? this.myReviewItem
      : null;
  }

  @computed
  get myPublishedReviewItem() {
    return this.publishedReviewItems.find((item) => item.myReview);
  }

  @computed
  get showMyPendingReview() {
    return this.myPendingReviewItem && !this.myPublishedReviewItem;
  }

  @action.bound
  setMyReviewItem(reviewItem: IReviewItem | null) {
    this.myReviewItem = reviewItem;
  }

  @action.bound
  setIsPublishedReviewItemsLoading(isLoading: boolean) {
    this.isPublishedReviewItemsLoading = isLoading;
  }

  @action.bound
  setPublishedReviewItems(reviewItems: IReviewItem[]) {
    this.publishedReviewItems = reviewItems;
  }

  @action.bound
  setPagingForReviews(paging: IPaging) {
    this.pagingForReviews = paging;
  }

  @action.bound
  openRatingPopup() {
    this.ratingPopupOpen = true;
  }

  @action.bound
  closeRatingPopup() {
    this.ratingPopupOpen = false;
  }

  @action.bound
  setRatingPopupLoading(loading: boolean) {
    this.ratingPopupLoading = loading;
  }

  @action.bound
  setIsTargetingCookiesEnabled(enabled: boolean) {
    this.isTargetingCookiesEnabled = enabled;
  }

  dispose() {
    while (this.disposers.length > 0) {
      const dispose = this.disposers.pop();

      if (dispose) {
        dispose();
      }
    }
  }

  @action.bound
  setSaveRating(fn) {
    this.saveRating = fn;
  }

  @action.bound
  setPublishedReviewItemsLoader(fn: IPublishedReviewItemsLoader) {
    this.publishedReviewItemsLoader = fn;
  }

  @action.bound
  setMyReviewLoader(fn: IMyReviewLoader) {
    this.myReviewLoader = fn;
  }

  @action.bound
  setMyReviewFromCacheLoader(fn: IMyReviewFromCacheLoader) {
    this.myReviewFromCacheLoader = fn;
  }

  @action.bound
  setOnDeleteReviewFn(fn: IOnDeleteReviewFn) {
    this.onDeleteReview = fn;
  }

  @action.bound
  setScrollContainerRef = (ref: MutableRefObject<HTMLElement | null>) => {
    this.scrollContainerRef = ref;
  };

  private get appId() {
    return this.profile?.appId;
  }

  private disposers: Function[] = [];

  constructor() {
    this.init();
  }

  private init() {
    reaction(
      () => this.appId,
      (appId) => {
        if (appId) {
          this.dispose();
          this.setup();
        }
      },
    );
  }

  private setup() {
    this.setupDataSyncReactionForMyReview();

    this.myReviewLoader?.().then((reviewItem) => {
      this.setMyReviewItem(reviewItem);
    });

    this.loadPublishedReviewItems();
  }

  removeMyPublishedReviewFromList = () => {
    const newPublishedReviewItems = this.publishedReviewItems.filter(
      (reviewItem) => !reviewItem.myReview,
    );

    const originalCount = this.publishedReviewItems.length;
    const newCount = newPublishedReviewItems.length;
    const deduction = originalCount - newCount;

    this.setPublishedReviewItems(newPublishedReviewItems);

    this.setPagingForReviews({
      ...this.pagingForReviews,
      totalElements: this.pagingForReviews.totalElements - deduction,
    });
  };

  loadPublishedReviewItems = (page = 1) => {
    this.setIsPublishedReviewItemsLoading(true);

    this.publishedReviewItemsLoader?.(page)
      .then((res) => {
        const records = res.records || [];

        this.setPagingForReviews(res.paging);

        if (res.paging?.page > 1) {
          const items = uniqBy(
            [...this.publishedReviewItems, ...records],
            (item) => item.id,
          );

          const extendedPublishedReviewItems =
            this.extendPublishedReviewItems(items);

          this.setPublishedReviewItems(extendedPublishedReviewItems);

          return;
        }

        const extendedPublishedReviewItems =
          this.extendPublishedReviewItems(records);

        this.setPublishedReviewItems(extendedPublishedReviewItems);
      })
      .finally(() => {
        this.setIsPublishedReviewItemsLoading(false);
      });
  };

  extendPublishedReviewItems = (reviewItems: IReviewItem[]) => {
    return reviewItems.map((item) => ({
      ...item,
      status: ReviewStatus.Approved,
    }));
  };

  private setupDataSyncReactionForMyReview() {
    const disposer = reaction(
      () => this.myReviewFromCacheLoader?.(),
      (myReviewItem) => {
        if (myReviewItem) {
          this.setMyReviewItem(myReviewItem);
        } else {
          this.setMyReviewItem(null);
        }
      },
    );

    this.disposers.push(disposer);
  }
}
