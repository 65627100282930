import { api } from '@apw/core';
import { isIE } from '@apw/core/browserDetector';
import { isMobile } from '@apw/core/osDetector';
import { prepareExternalUrl } from '@apw/core/url/url.service';
import { IExtension } from '@apw/stores/user/extension.interface';
import { IUser } from '@apw/types';
import Cookies from 'js-cookie';
import { get } from 'lodash';
import { IAdditionalAccountInfo } from '@drw/lib';

let loginUrl;

export const LOAD_USER_ENDPOINT = '/api/user';

export const getUser = () => {
  return api.get<IUser>(LOAD_USER_ENDPOINT, {
    useGlobalLoading: true,
  });
};

export const LOAD_EXTENSION_ENDPOINT = '/api/user/extension-info';

export const getExtension = () => {
  return api.get<IExtension>(LOAD_EXTENSION_ENDPOINT, {
    useGlobalLoading: true,
  });
};

export const LOAD_ADDITIONAL_ACCOUNT_INFO_ENDPOINT =
  '/api/additional-account-info';

export const getAdditionalAccountInfo = () => {
  return api.get<IAdditionalAccountInfo>(
    LOAD_ADDITIONAL_ACCOUNT_INFO_ENDPOINT,
    {
      useGlobalLoading: true,
    },
  );
};

export interface LoginUrlResponse {
  loginUrl: string;
}

const signInViaPopup = () => {
  const left = window.innerWidth / 2 - 250;
  const top = window.innerHeight / 2 - 260;
  const windowFeatures = `location=yes,height=520,width=500,scrollbars=yes,top=${top},left=${left}`;

  const loginWin = window.open(loginUrl, '_blank', windowFeatures);
  if (loginWin) {
    getLoginUrl().then((url) => {
      loginWin.location.href = url;
    });
  }
};

export const setSignInCallback = (callback) => {
  (window as any).loginCallback = callback;
};

export const clearSignInCallback = () => {
  (window as any).loginCallback = null;
};

export const signIn = (signInCallback: Function) => {
  if (isIE() || isMobile()) {
    signInViaCLW();
  } else {
    setSignInCallback(() => {
      signInCallback();
      clearSignInCallback();
    });
    signInViaPopup();
  }
};

export const getLoginUrl = (useGlobalLoading = false) => {
  const endpoint = '/api/login/login-url';
  return api
    .get<LoginUrlResponse>(endpoint, { useGlobalLoading })
    .then((res) => res.loginUrl);
};

export const signInSSO = (url: string) => {
  const ifr = document.createElement('iframe');
  ifr.style.display = 'none';
  ifr.src = url;
  ifr.id = 'sso-iframe';
  ifr.setAttribute('data-ot-ignore', '');
  document.body.appendChild(ifr);
};

export const signInViaCLW = (): void => {
  getLoginUrl().then((loginUrl: string) => {
    storeSession();
    window.location.href = loginUrl;
  });
};

export const LOGOUT_APS_ENDPOINT = '/api/user/logout';

const logoutAPS = () => {
  return api.post<any>(LOGOUT_APS_ENDPOINT);
};

const logoutCLW = () => {
  const CLW_DOMAIN = get(
    window,
    'RCAPW.clwDomain',
    'login-dpwdevxmn.lab.nordigy.ru',
  );
  const endpoint = `https://${CLW_DOMAIN}/api/logout?r=${Math.random()}`;

  return api.jsonp(endpoint, {
    timeout: 1000,
  });
};

export const logout = () =>
  Promise.all([logoutAPS(), logoutCLW().catch(() => {})]);

const previousSessionUrlKey = 'rcapw-previous_session_url';

export const storeSession = (): void => {
  Cookies.set(previousSessionUrlKey, window.location.href, { path: '/' });
};

const navigatedWithinGallery = (): boolean => {
  return window.history.length > 1;
};

export const backOtherwiseClose = () => {
  if (navigatedWithinGallery()) {
    window.history.back();
    return;
  }

  window.close();

  if (!window.closed) {
    window.history.pushState(null, '', prepareExternalUrl('/'));
  }
};
