import {
  alcatelIcon,
  atosIcon,
  attIcon,
  avayaIcon,
  btIcon,
  eastlinkIcon,
  ecotelIcon,
  frontierIcon,
  mcmIcon,
  rcIcon,
  telekomDtIcon,
  telusIcon,
  unifyOfficeDtAtosIcon,
  verizonIcon,
  versatelIcon,
  vodafoneIcon,
} from '@apw/assets/images/brand-icons';
import defaultHeaderBanner from '@apw/assets/images/page-hero.png';
import { IGalleryBrandNav } from '@apw/stores/compatibility/compatibilities/galleryBrand';
import { Compatibility } from '../../compatibility.enum';
import {
  GalleryIconType,
  ICompatibilitiesMapping,
  ICompatibility,
} from '../../compatibility.interface';
import {
  alcatelColor,
  alcatelTheme,
  atosColor,
  atosTheme,
  attColor,
  attTheme,
  avayaPrimaryColor,
  avayaSecondaryColor,
  avayaTheme,
  btColor,
  btTheme,
  eastlinkColor,
  eastlinkTheme,
  ecotelColor,
  ecotelTheme,
  frontierColor,
  frontierTheme,
  mcmColor,
  mcmTheme,
  rcColor,
  rcTheme,
  telekomDtColor,
  telekomDtTheme,
  telusColor,
  telusTheme,
  unifyOfficeDtAtosDtColor,
  unifyOfficeDtAtosDtTheme,
  verizonColor,
  verizonTheme,
  versatelColor,
  versatelTheme,
  vodafoneColor,
  vodafoneTheme,
} from './themes';

const defaultHorizontalNavs: IGalleryBrandNav[] = [
  {
    label: 'Build apps',
    url: 'https://developers.ringcentral.com',
  },
  {
    label: 'Suggest an app',
    url: 'https://ideas.ringcentral.com/ideas/new',
  },
];

const items: ICompatibility[] = [
  {
    name: 'RingCentral',
    galleryName: 'RingCentral Gallery',
    id: /* RC US: */ Compatibility.RINGCENTRAL,
    idAliases: [
      /* RC CA: */ '3610',
      /* RC UK: */ '3710',
      /* RC EU: */ '2010',
      /* RC AU: */ '5010',
      /* RC FedRAMP: */ '1250',
    ],
    value: 'ringcentral',
    isDefault: true,
    logo: 'ringcentral.svg',
    className: 'ringcentral',
    jupiterWebUrl: RCAPW.jupiterWebUrl.RC,
    slug: '',
    theme: rcTheme,
    primaryColor: rcColor,
    secondaryColor: rcColor,
    galleryIcon: rcIcon,
    galleryIconType: GalleryIconType.SVG_FILE,
    headerBanners: [defaultHeaderBanner],
    horizontalNavs: defaultHorizontalNavs,
    subBrands: [],
  },
  {
    name: 'AT&T Office@Hand',
    galleryName: 'AT&T Office@Hand Gallery',
    id: Compatibility.ATT,
    idAliases: [/* ATT_UB */ '3460'],
    value: 'office@hand',
    valueAliases: ['officeathand', 'office-at-hand'],
    logo: 'office-at-hand.svg',
    className: 'office-at-hand',
    jupiterWebUrl: RCAPW.jupiterWebUrl.ATT,
    slug: 'office-at-hand',
    theme: attTheme,
    primaryColor: attColor,
    secondaryColor: attColor,
    galleryIcon: attIcon,
    galleryIconType: GalleryIconType.PNG_FILE,
    headerBanners: [defaultHeaderBanner],
    horizontalNavs: defaultHorizontalNavs,
    subBrands: [],
  },
  {
    name: 'Avaya Cloud Office',
    galleryName: 'Avaya Cloud Office Gallery',
    id: Compatibility.AVAYA,
    value: 'avayacloudoffice',
    valueAliases: ['avaya cloud office'],
    logo: 'avaya-cloud-office.svg',
    className: 'avaya-cloud-office',
    jupiterWebUrl: RCAPW.jupiterWebUrl.Avaya,
    slug: 'avaya-cloud-office',
    theme: avayaTheme,
    primaryColor: avayaPrimaryColor,
    secondaryColor: avayaSecondaryColor,
    galleryIcon: avayaIcon,
    galleryIconType: GalleryIconType.PNG_FILE,
    headerBanners: [defaultHeaderBanner],
    horizontalNavs: defaultHorizontalNavs,
    subBrands: [],
  },
  {
    name: 'BT Cloud Work',
    galleryName: 'BT Cloud Work Gallery',
    id: Compatibility.BT,
    value: 'btcloudwork',
    valueAliases: ['bt cloud phone', 'bt cloud work', 'btcloudphone'],
    logo: 'bt-cloud-work.svg',
    className: 'bt-cloud-work',
    jupiterWebUrl: RCAPW.jupiterWebUrl.BT,
    slug: 'bt-cloud-work',
    theme: btTheme,
    primaryColor: btColor,
    secondaryColor: btColor,
    galleryIcon: btIcon,
    galleryIconType: GalleryIconType.SVG_FILE,
    headerBanners: [defaultHeaderBanner],
    horizontalNavs: defaultHorizontalNavs,
    subBrands: [],
  },
  {
    name: 'Rainbow Office',
    galleryName: 'Rainbow Office Gallery',
    id: Compatibility.ALCATEL,
    value: 'rainbowoffice',
    valueAliases: ['rainbow office'],
    logo: 'alcatel-rainbow-office.svg',
    className: 'rainbow-office',
    jupiterWebUrl: RCAPW.jupiterWebUrl.Alcatel,
    slug: 'rainbow-office',
    theme: alcatelTheme,
    primaryColor: alcatelColor,
    secondaryColor: alcatelColor,
    galleryIcon: alcatelIcon,
    galleryIconType: GalleryIconType.SVG_FILE,
    headerBanners: [defaultHeaderBanner],
    horizontalNavs: defaultHorizontalNavs,
    subBrands: [],
  },
  {
    name: 'RingCentral for Symphony',
    galleryName: 'RingCentral for Symphony',
    id: Compatibility.MCM,
    value: 'ringcentralforsymphony',
    valueAliases: ['symphony', 'ringcentral for symphony'],
    logo: 'ringcentral-for-symphony.svg',
    className: 'ringcentral-for-symphony',
    jupiterWebUrl: '',
    slug: 'ringcentral-for-symphony',
    theme: mcmTheme,
    primaryColor: mcmColor,
    secondaryColor: mcmColor,
    galleryIcon: mcmIcon,
    galleryIconType: GalleryIconType.SVG_FILE,
    headerBanners: [defaultHeaderBanner],
    horizontalNavs: defaultHorizontalNavs,
    subBrands: [],
  },
  {
    name: 'RingCentral mit ecotel',
    galleryName: 'RingCentral mit ecotel',
    id: Compatibility.ECOTEL,
    value: 'ringcentralmitecotel',
    valueAliases: ['ecotel', 'ringcentral mit ecotel'],
    logo: 'ringcentral-mit-ecotel.svg',
    className: 'ringcentral-mit-ecotel',
    jupiterWebUrl: '',
    slug: 'ringcentral-mit-ecotel',
    theme: ecotelTheme,
    primaryColor: ecotelColor,
    secondaryColor: ecotelColor,
    galleryIcon: ecotelIcon,
    galleryIconType: GalleryIconType.SVG_FILE,
    headerBanners: [defaultHeaderBanner],
    horizontalNavs: defaultHorizontalNavs,
    subBrands: [],
  },
  {
    name: 'RingCentral with Eastlink',
    galleryName: 'RingCentral with Eastlink',
    id: Compatibility.EASTLINK,
    value: 'ringcentralwitheastlink',
    valueAliases: ['eastlink', 'ringcentral with eastlink'],
    logo: 'ringcentral-with-eastlink.svg',
    className: 'ringcentral-with-eastlink',
    jupiterWebUrl: '',
    slug: 'ringcentral-with-eastlink',
    theme: eastlinkTheme,
    primaryColor: eastlinkColor,
    secondaryColor: eastlinkColor,
    galleryIcon: eastlinkIcon,
    galleryIconType: GalleryIconType.SVG_FILE,
    headerBanners: [defaultHeaderBanner],
    horizontalNavs: defaultHorizontalNavs,
    subBrands: [],
  },
  {
    name: 'RingCentral with Verizon',
    galleryName: 'RingCentral with Verizon',
    id: Compatibility.VERIZON,
    value: 'ringcentralwithverizon',
    valueAliases: ['verizon', 'ringcentral with verizon'],
    logo: 'verizon.svg',
    className: 'verizon',
    jupiterWebUrl: RCAPW.jupiterWebUrl.Verizon,
    slug: 'ringcentral-with-verizon',
    theme: verizonTheme,
    primaryColor: verizonColor,
    secondaryColor: verizonColor,
    galleryIcon: verizonIcon,
    galleryIconType: GalleryIconType.SVG_FILE,
    headerBanners: [defaultHeaderBanner],
    horizontalNavs: defaultHorizontalNavs,
    subBrands: [],
  },
  {
    name: 'TELUS Business Connect',
    galleryName: 'TELUS Business Connect Gallery',
    id: Compatibility.TELUS,
    value: 'telusbusinessconnect',
    valueAliases: ['telus business connect'],
    logo: 'telus-business-connect.svg',
    className: 'telus-business-connect',
    jupiterWebUrl: RCAPW.jupiterWebUrl.Telus,
    slug: 'telus-business-connect',
    theme: telusTheme,
    primaryColor: telusColor,
    secondaryColor: telusColor,
    galleryIcon: telusIcon,
    galleryIconType: GalleryIconType.SVG_FILE,
    headerBanners: [defaultHeaderBanner],
    horizontalNavs: defaultHorizontalNavs,
    subBrands: [],
  },
  {
    name: 'Unify Office',
    galleryName: 'Unify Office Gallery',
    id: Compatibility.ATOS,
    value: 'unifyoffice',
    valueAliases: ['unify office'],
    logo: 'atos-unify-office.svg',
    className: 'atos-unify-office',
    jupiterWebUrl: RCAPW.jupiterWebUrl.Atos,
    slug: 'unify-office',
    theme: atosTheme,
    primaryColor: atosColor,
    secondaryColor: atosColor,
    galleryIcon: atosIcon,
    galleryIconType: GalleryIconType.PNG_FILE,
    headerBanners: [defaultHeaderBanner],
    horizontalNavs: defaultHorizontalNavs,
    subBrands: [],
  },
  {
    name: 'Vodafone Business UC',
    galleryName: 'Vodafone Business UC Gallery',
    id: Compatibility.VODAFONE,
    value: 'vodafonebusiness',
    valueAliases: ['vodafone business'],
    logo: 'vodafone-business.svg',
    className: 'vodafone-business',
    jupiterWebUrl: RCAPW.jupiterWebUrl.Vodafone,
    slug: 'vodafone-business',
    theme: vodafoneTheme,
    primaryColor: vodafoneColor,
    secondaryColor: vodafoneColor,
    galleryIcon: vodafoneIcon,
    galleryIconType: GalleryIconType.SVG_FILE,
    headerBanners: [defaultHeaderBanner],
    horizontalNavs: defaultHorizontalNavs,
    subBrands: [],
  },
  {
    name: 'RingCentral mit 1&1 Connected Calls',
    galleryName: 'RingCentral mit 1&1 Connected Calls',
    id: Compatibility.VERSATEL,
    value: 'versatel',
    valueAliases: ['ringCentral mit 1und1 connected calls'],
    logo: 'ringcentral-mit-1und1-connected-calls.svg',
    className: 'versatel',
    jupiterWebUrl: '',
    slug: 'ringcentral-mit-1und1-connected-calls',
    theme: versatelTheme,
    primaryColor: versatelColor,
    secondaryColor: versatelColor,
    galleryIcon: versatelIcon,
    galleryIconType: GalleryIconType.SVG_FILE,
    headerBanners: [defaultHeaderBanner],
    horizontalNavs: defaultHorizontalNavs,
    subBrands: [],
  },
  {
    name: 'RingCentral mit Telekom',
    galleryName: 'RingCentral mit Telekom',
    id: Compatibility.TELEKOM_DT,
    value: 'telekom',
    valueAliases: ['ringCentral mit telekom'],
    logo: 'ringcentral-mit-telekom.svg',
    className: 'telekom',
    jupiterWebUrl: '',
    slug: 'ringcentral-mit-telekom',
    theme: telekomDtTheme,
    primaryColor: telekomDtColor,
    secondaryColor: telekomDtColor,
    galleryIcon: telekomDtIcon,
    galleryIconType: GalleryIconType.SVG_FILE,
    headerBanners: [defaultHeaderBanner],
    horizontalNavs: defaultHorizontalNavs,
    subBrands: [],
  },
  {
    name: 'Unify Office by RingCentral',
    galleryName: 'Unify Office by RingCentral',
    id: Compatibility.UNIFY_OFFICE_DT_ATOS,
    value: 'unifyofficetelekom',
    valueAliases: ['unifyoffice telekom mit ringcentral'],
    logo: 'unifyoffice-telekom-mit-ringcentral.svg',
    className: 'unifyofficetelekom',
    jupiterWebUrl: '',
    slug: 'unifyoffice-telekom-mit-ringcentral',
    theme: unifyOfficeDtAtosDtTheme,
    primaryColor: unifyOfficeDtAtosDtColor,
    secondaryColor: unifyOfficeDtAtosDtColor,
    galleryIcon: unifyOfficeDtAtosIcon,
    galleryIconType: GalleryIconType.SVG_FILE,
    headerBanners: [defaultHeaderBanner],
    horizontalNavs: defaultHorizontalNavs,
    subBrands: [],
  },
  {
    name: 'Frontier plus RingCentral',
    galleryName: 'Frontier plus RingCentral',
    id: Compatibility.FRONTIER,
    value: 'frontier',
    valueAliases: ['frontier plus ringcentral'],
    logo: 'frontier-plus-ringcentral.svg',
    className: 'frontier',
    jupiterWebUrl: '',
    slug: 'frontier-plus-ringcentral',
    theme: frontierTheme,
    primaryColor: frontierColor,
    secondaryColor: frontierColor,
    galleryIcon: frontierIcon,
    galleryIconType: GalleryIconType.SVG_FILE,
    headerBanners: [defaultHeaderBanner],
    horizontalNavs: defaultHorizontalNavs,
    subBrands: [],
  },
  // for new brand, use rcTheme,rcColor, rcIcon as fallback value
  {
    name: 'RingCentral with Sunrise',
    galleryName: 'RingCentral with Sunrise',
    id: Compatibility.SUNRISE,
    value: 'sunrise',
    valueAliases: ['ringCentral with sunrise'],
    logo: 'ringcentral.svg',
    className: 'ringcentral',
    jupiterWebUrl: '',
    slug: 'ringCentral-with-sunrise',
    theme: rcTheme,
    primaryColor: rcColor,
    secondaryColor: rcColor,
    galleryIcon: rcIcon,
    galleryIconType: GalleryIconType.SVG_FILE,
    headerBanners: [defaultHeaderBanner],
    horizontalNavs: defaultHorizontalNavs,
    subBrands: [],
  },
].sort((brandA, brandB) => {
  if (brandA.id === Compatibility.RINGCENTRAL) {
    return -1;
  }
  if (brandB.id === Compatibility.RINGCENTRAL) {
    return 1;
  }
  return brandA.name.localeCompare(brandB.name);
});

const mapping: ICompatibilitiesMapping = items.reduce(
  (mapping, compatibility) => {
    mapping[compatibility.id] = compatibility;
    return mapping;
  },
  {},
);

export const fallbackCompatibilities = {
  items,
  mapping,
};
