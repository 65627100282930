import React from 'react';
import { RcRating, RcRatingProps } from '@ringcentral/juno';
import { RatingMixin, EmptyRectangle, Rectangle } from './RectangleRating.sc';

export const RectangleRating = (props: RcRatingProps) => {
  return (
    // @ts-ignore
    <div css={RatingMixin}>
      <RcRating
        icon={<Rectangle />}
        emptyIcon={<EmptyRectangle />}
        {...(props as any)}
      />
    </div>
  );
};
