import { NoReviewsSection } from '@apw/components/ratingAndReview/noReviewsSection/NoReviewsSection';
import { onMobile } from '@apw/core/sc';
import { RcTypography, styled } from '@ringcentral/juno';

export const Wrapper = styled.div``;

export const SectionHeading = styled(RcTypography)`
  margin-top: 0;
`;

export const SectionBody = styled.div``;

export const TotalNumber = styled.span``;

export const StyledNoReviewsSection = styled(NoReviewsSection)`
  margin-top: 132px;
  margin-bottom: 132px;

  ${onMobile} {
    margin-top: 80px;
    margin-bottom: 80px;
  }
`;
