import { PageSectionEnum } from './page-section.enum';

export const pageSections = {
  [PageSectionEnum.DISCOVER_APPS]: {
    sectionId: PageSectionEnum.DISCOVER_APPS,
    displayName: 'Discover Apps',
  },
  [PageSectionEnum.PRODUCTS]: {
    sectionId: PageSectionEnum.PRODUCTS,
    displayName: 'Products',
  },
  [PageSectionEnum.CATEGORIES]: {
    sectionId: PageSectionEnum.CATEGORIES,
    displayName: 'Categories',
  },
  [PageSectionEnum.HIDDEN]: {
    sectionId: PageSectionEnum.HIDDEN,
    displayName: 'Hidden',
  },
};
