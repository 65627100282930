import React, { FC } from 'react';
import { RcIcon, RcLink, styled } from '@ringcentral/juno';
import { NoteBorder } from '@ringcentral/juno-icon';
import { ILink } from '../../../../../types';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 82px;
  border-top: 1px solid #cbcbcb;
  padding-top: 26px;
  font-size: 16px;
  font-weight: 600;

  @media (max-width: 767px) {
    margin-bottom: 48px;
  }
`;

const LinkList = styled.ul`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
`;

const LinkItem = styled.li`
  width: 100%;
`;

const LinkWrapper = styled(RcLink)`
  display: inline-flex;
  word-break: break-word;
  overflow-wrap: break-word;
`;

const LinkIcon = styled.div`
  display: inline-flex;
  align-items: center;
  height: 24px;
  margin-right: 20px;
`;

const LinkName = styled.span`
  display: inline-block;
  overflow-wrap: break-word;
`;

export const ResourceLinks: FC<{
  className?: string;
  links?: ILink[];
}> = (props) => {
  const { className = '', links } = props;

  if (!links || !links.length) {
    return null;
  }

  return (
    <Wrapper className={`resourceLinks ${className || ''}`}>
      <LinkList data-test-automation-id="resourceLinksList">
        {links.map((item, index) => {
          return (
            <LinkItem key={item.key}>
              <LinkWrapper
                variant="inherit"
                href={item.link}
                download
                target="_blank"
                rel="noreferrer"
                data-test-automation-id={`resourceLinksItem-${index}`}
              >
                <LinkIcon data-test-automation-id="resourceLinksItemIcon">
                  <RcIcon symbol={NoteBorder} size="small" />
                </LinkIcon>
                <LinkName data-test-automation-id="resourceLinksItemLabel">
                  {item.label}
                </LinkName>
              </LinkWrapper>
            </LinkItem>
          );
        })}
      </LinkList>
    </Wrapper>
  );
};
