import React from 'react';
import {
  Content,
  Title,
  Wrapper,
} from '@apw/components/profileDetail/profileBody/overviewTab/certifiedAppDisclaimer/certifiedAppDisclamier.sc';

export const CertifiedAppDisclaimer = () => {
  return (
    <Wrapper>
      <Title
        data-test-automation-id="certified-app-disclaimer-title"
        tabIndex={0}
      >
        DISCLAIMERS
      </Title>
      <Content
        data-test-automation-id="certified-app-disclaimer-content"
        tabIndex={0}
      >
        RingCentral briefly reviews all apps prior to publishing them in our App
        Gallery. Apps with a “Certified” badge have undergone additional review
        to assess quality and usability. RingCentral does not certify the
        security or data handling practices of any app. You should review and
        understand the terms of service and privacy notice of third-party
        provided apps or integrations.
      </Content>
    </Wrapper>
  );
};
