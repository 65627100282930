import { onMobile, useProximaNovaSemibold } from '@apw/core/sc';
import { RcButton, RcRating, RcTypography, styled } from '@ringcentral/juno';

export const Wrapper = styled.div`
  display: flex;
  padding: 32px 0;

  ${onMobile} {
    flex-direction: column;
  }
`;

export const LeftContainer = styled.div`
  width: 200px;
  flex-shrink: 0;
  margin-right: 60px;

  ${onMobile} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: auto;
    margin-right: 0;
  }
`;

export const RightContainer = styled.div`
  flex-grow: 1;
  min-width: 0;
`;

export const ReviewerName = styled(RcTypography)`
  ${useProximaNovaSemibold};
  word-wrap: break-word;

  ${onMobile} {
    margin-right: 16px;
    margin-bottom: 4px;
  }
`;

export const CreatedTime = styled(RcTypography)`
  margin-top: 8px;

  ${onMobile} {
    margin-top: 0;
    margin-bottom: 4px;
  }
`;

export const ReviewerRating = styled(RcRating)`
  &&& {
    .MuiRating-icon {
      padding: 0 2px;
    }

    &.MuiRating-root {
      > span:first-child .MuiRating-icon {
        padding-left: 0;
      }
    }

    .RcRating-iconFilled {
      color: #f6ad16 !important;
    }

    .RcRating-iconEmpty {
      color: #f6ad16 !important;
    }

    svg {
      font-size: 22px;
    }

    ${onMobile} {
      margin-top: 12px;
    }
  }
`;

export const ReviewContent = styled(RcTypography)`
  margin-top: 12px;
  word-wrap: break-word;

  ${onMobile} {
    margin-top: 4px;
  }
`;

export const StatusBadgeContainer = styled.div`
  margin-top: 4px;

  ${onMobile} {
    margin-top: 0;
    margin-bottom: 12px;
  }
`;

export const ReviewActions = styled.div`
  margin-top: 8px;

  ${RcButton}:not(:first-child) {
    margin-left: 24px;
  }

  ${RcButton}:first-child {
    margin-left: -4px;
  }

  ${onMobile} {
    margin-top: 24px;
  }
`;
