import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ProfileViewModelContext } from '@apw/components/profileDetail/Profile.ViewModel.context';
import { RatingStep } from '@apw/components/ratingAndReview/popup/content/RatingStep';
import { ReviewStep } from '@apw/components/ratingAndReview/popup/content/ReviewStep';
import { RatingPopupStore } from '@apw/components/ratingAndReview/popup/ratingPopup.store';
import {
  RcButton,
  RcDialogActions,
  RcDialogContent,
  RcDialogTitle,
} from '@ringcentral/juno';
import { observer } from 'mobx-react';

interface RatingPopupContentProps {
  onClose: () => void;
  onSave: (store: RatingPopupStore, isEdit: boolean) => void;
}

const RatingPopupContentPure = ({
  onClose,
  onSave,
}: RatingPopupContentProps) => {
  const profileVM = useContext(ProfileViewModelContext);

  const name = useMemo(
    () =>
      profileVM.profile?.applicationDisplayName || profileVM.profile?.appName!,
    [profileVM.profile],
  );

  const isEdit = useMemo(
    () => !!profileVM.myReviewItem,
    [profileVM.myReviewItem],
  );

  const saveBtnName = useMemo(() => (isEdit ? 'Update' : 'Submit'), [isEdit]);

  const title = useMemo(
    () =>
      isEdit
        ? 'Update your rating and review'
        : `Rate your experience using ${name}`,
    [isEdit, name],
  );

  const store = useMemo(() => {
    return isEdit
      ? new RatingPopupStore(profileVM.myReviewItem!)
      : new RatingPopupStore();
  }, [isEdit]);

  const [step, setStep] = useState<'Rating' | 'Review'>('Rating');
  const isRatingStep = useMemo(() => step === 'Rating', [step]);

  const setRating = useCallback(() => setStep('Rating'), [setStep]);
  const setReview = useCallback(() => setStep('Review'), [setStep]);

  return (
    <>
      <RcDialogTitle data-test-automation-id="rating-popup-title">
        {title}
      </RcDialogTitle>
      <RcDialogContent>
        {isRatingStep ? (
          <RatingStep store={store} />
        ) : (
          <ReviewStep store={store} />
        )}
      </RcDialogContent>
      <RcDialogActions>
        {isRatingStep ? (
          <>
            <RcButton
              variant="text"
              data-test-automation-id="rating-popup-cancel"
              onClick={onClose}
            >
              Cancel
            </RcButton>
            <RcButton
              disabled={!store.valid}
              onClick={setReview}
              data-test-automation-id="rating-popup-continue"
            >
              Continue
            </RcButton>
          </>
        ) : (
          <>
            <RcButton
              variant="text"
              onClick={setRating}
              data-test-automation-id="rating-popup-back"
            >
              Back
            </RcButton>
            <RcButton
              onClick={() => onSave(store, isEdit)}
              data-test-automation-id="rating-popup-save"
              disabled={!store.dirty}
            >
              {saveBtnName}
            </RcButton>
          </>
        )}
      </RcDialogActions>
    </>
  );
};

export const RatingPopupContent = observer(RatingPopupContentPure);
