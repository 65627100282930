import { RcIcon, styled } from '@ringcentral/juno';

export const Container = styled.div`
  text-align: center;
  margin-top: 38px;
  margin-bottom: 24px;
`;

export const Image = styled(RcIcon)`
  margin-bottom: 20px;
  svg {
    width: 160px;
    height: 160px;
  }
`;
