import React, { ReactElement } from 'react';
import { GalleryIconType } from '@apw/stores/compatibility';
import { RcIcon } from '@ringcentral/juno';
import { GalleryIconContainer } from './galleryIcon.sc';

export const getGalleryIconNode = (
  galleryIcon: any,
  galleryIconType: GalleryIconType,
  galleryIconClassName: string,
): ReactElement => {
  const isSvg = galleryIconType === GalleryIconType.SVG_FILE;

  return (
    <GalleryIconContainer>
      {isSvg && (
        <RcIcon
          symbol={galleryIcon}
          size="large"
          className={galleryIconClassName}
          data-test-automation-id="galleryIcon"
        />
      )}
      {!isSvg && (
        <img
          src={galleryIcon}
          alt=""
          className={galleryIconClassName}
          data-test-automation-id="galleryIcon"
        />
      )}
    </GalleryIconContainer>
  );
};
