import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Container, Header, Body } from './sidebarSection.sc';

interface SidebarSectionProps {
  name: string;
  defaultExpanded?: boolean;
  children: ReactNode;
}

export const SidebarSection = observer((props: SidebarSectionProps) => {
  const { name, defaultExpanded = true, children } = props;

  return (
    <Container
      defaultExpanded={defaultExpanded}
      data-test-automation-id={`sidebarSection-${name}`}
    >
      <Header expandIcon data-test-automation-id="sidebar-header">
        {name}
      </Header>
      <Body data-test-automation-id="sidebar-body">{children}</Body>
    </Container>
  );
});
