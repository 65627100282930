import { transport } from '@apw/core';
import { get } from '@apw/core/api/api.service';
import { IRawBotItems } from '@apw/modules/myApps';
import {
  IAdditionalAppInfo,
  IClientInfoWebUrisResponse,
  IRawBotItem,
  IRawBotItemsResponse,
} from '@apw/modules/myApps/typings';

export const getClientInfoWebUris = () => {
  return transport.fetchClientInfoWebUris();
};

export const getInstalledBots = (perPage = 5) => {
  return transport.fetchInstalledBots().then((bots) => {
    return divideIntoPages(bots.botList, perPage);
  });
};

export const divideIntoPages = (
  allInstalledBots: IRawBotItem[] = [],
  perPage = 5,
): IRawBotItems => {
  return allInstalledBots.reduce((accumulated, installedBot, index) => {
    const page = Math.floor(index / perPage);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    Array.isArray(accumulated[page])
      ? accumulated[page].push(installedBot)
      : (accumulated[page] = [installedBot]);
    return accumulated;
  }, {});
};

export const getAdditionalAppInfo = (botExtensionId: string) => {
  return transport.fetchAdditionalAppInfo(botExtensionId);
};

export const getProfileImageUrl = (botExtensionId: string): string => {
  const PROFILE_IMAGE = (botExtensionId: string) =>
    `/api/bot/profile-image/${botExtensionId}`;
  const relativePath = PROFILE_IMAGE(botExtensionId);
  return `${process.env.PUBLIC_URL}${relativePath}`;
};

export const requestClientInfoWebUris = () => {
  const endpoint = '/api/client-info/webUris';
  return get<IClientInfoWebUrisResponse>(endpoint);
};

export const requestInstalledBots = () => {
  const endpoint = '/api/bot/installed-bots';
  return get<IRawBotItemsResponse>(endpoint);
};

export const requestAdditionalAppInfo = (
  botExtensionId: string,
): Promise<IAdditionalAppInfo> => {
  const ADDITIONAL_APP_INFO = (botExtensionId: string) =>
    `/api/bot/additional-app-info/${botExtensionId}`;
  return get<IAdditionalAppInfo>(ADDITIONAL_APP_INFO(botExtensionId));
};
