import {
  textNoWrap,
  useProximaNovaRegular,
  useProximaNovaSemibold,
} from '@apw/core/sc';
import { css } from '@ringcentral/juno';

export const cardTitle = css`
  font-size: 18px;
  font-weight: bold;
  color: rgb(0, 17, 56);
  letter-spacing: 0.25px;
  line-height: 26px;
  ${textNoWrap('18px')}
  ${useProximaNovaSemibold}
`;

export const cardDescription = css`
  color: rgb(104, 104, 105);
  line-height: 20px;
  ${textNoWrap('14px')}
  ${useProximaNovaRegular}
`;
