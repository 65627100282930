import { onMobile } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CollectionBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;

  ${onMobile} {
    margin-right: 0;
  }
`;
