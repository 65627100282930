import React from 'react';
import {
  RatingMixin,
  EmptyRectangle,
  Rectangle,
} from '@apw/components/ratingAndReview/rectangleRating/RectangleRating.sc';
import { RcRating, RcRatingProps } from '@ringcentral/juno';

export const RectangleRating = (props: RcRatingProps) => {
  return (
    // @ts-ignore
    <RcRating
      css={RatingMixin}
      icon={<Rectangle />}
      emptyIcon={<EmptyRectangle />}
      {...props}
    />
  );
};
