import React from 'react';
import { useRouteDataContext } from '@apw/hooks/useRouteDataContext';
import { getCompatibilityPagePath } from '@apw/routes/getCompatibilityPaths';
import { SidebarSection } from '@apw/shared/sidebar/sidebarSection/SidebarSection';
import { useCurrentCompatibility } from '@apw/stores/compatibility';
import { PageListItem } from '@apw/types';
import { RcMenuItem } from '@ringcentral/juno';
import { observer } from 'mobx-react';
import { NavItem } from './PageNavigation.sc';

interface PageNavigationProps {
  sectionName: string;
  items: PageListItem[];
  firstPageAsDefaultPage?: boolean;
}

export const PageNavigation = observer((props: PageNavigationProps) => {
  const { items = [], sectionName, firstPageAsDefaultPage = false } = props;
  const compatibility = useCurrentCompatibility();
  const routeData = useRouteDataContext();
  const getPagePath = (vanityUrl) =>
    getCompatibilityPagePath(compatibility.slug, vanityUrl);

  return (
    <SidebarSection name={sectionName}>
      {items.map((page, index) => (
        <NavItem
          key={page.pageId}
          to={getPagePath(page.vanityUrl)}
          isActive={(match) =>
            !!(
              match ||
              (firstPageAsDefaultPage &&
                index === 0 &&
                routeData?.isLandingPage)
            )
          }
          data-test-automation-id={`pageNavigation-${page.pageId}`}
          aria-label={page.name}
        >
          <RcMenuItem aria-hidden="true">
            <span title={page.name}>{page.name}</span>
          </RcMenuItem>
        </NavItem>
      ))}
    </SidebarSection>
  );
});
