import { Language } from './language.enum';
import { Languages, LanguagesWithContext } from './languages';

class LanguageService {
  private languages = Languages;

  private languagesWithContext = LanguagesWithContext;

  get(id: Language): string {
    return this.languages[id];
  }

  getWithContext(id: Language, ...context: string[]): string {
    return this.languagesWithContext[id](...context);
  }
}

export const languageService = new LanguageService();
