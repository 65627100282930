import { Page } from '@apw/modules/page';
import { PagePreview } from '@apw/modules/page/preview';
import { PartnerEcosystem } from '@apw/modules/partnerEcosystem';
import { ProfilePage } from '@apw/modules/profile';
import { ProfilePageQueryParamsEnum } from '@apw/modules/profile/ProfilePageQueryParams.enum';
import {
  getCompatibilityGalleryPagePreviewPath,
  getCompatibilityHomePath,
  getCompatibilityMyAppsPath,
  getCompatibilityPagePath,
  getCompatibilityPartnerEcosystemPath,
  getCompatibilityProfilePath,
} from '@apw/routes/getCompatibilityPaths';
import { IRoute } from '@apw/routes/route.interface';
import { getRouteSettings } from '@apw/routes/routeSettings';
import { utmTrackingParams, withQueryParamCleaner } from '@apw/shared';
import {
  Compatibility,
  ICompatibilitiesMapping,
} from '@apw/stores/compatibility';
import { RouteProps } from 'react-router-dom';

export const getCompatibilityRoutes = (
  compatibilities: ICompatibilitiesMapping,
): RouteProps[] => {
  const routes: IRoute[] = [];
  const routeSettings = getRouteSettings();

  return Object.keys(compatibilities).reduce((previous, key) => {
    const compatibility = compatibilities[key];

    previous.push(
      {
        path: getCompatibilityHomePath(compatibility.slug),
        component: Page,
        exact: true,
        data: {
          isLandingPage: true,
        },
      },
      {
        path: getCompatibilityMyAppsPath(compatibility.slug),
        component: routeSettings.myAppsPage.component,
        exact: true,
        data: {
          auth: true,
          redirect: getCompatibilityHomePath(compatibility.slug),
        },
      },
      {
        path: getCompatibilityPartnerEcosystemPath(compatibility.slug),
        component: PartnerEcosystem,
        exact: true,
        data: {},
      },
      {
        path: getCompatibilityPagePath(compatibility.slug, ':vanityUrl'),
        component: Page,
        data: {
          isIndividualPage: true,
        },
      },
      {
        path: getCompatibilityGalleryPagePreviewPath(
          compatibility.slug,
          ':pageId',
        ),
        component: PagePreview,
        data: {
          isPreviewPage: true,
        },
      },
      routeSettings.download,
    );

    return previous;
  }, routes);
};

const ProfilePageWithQueryParamCleaner = withQueryParamCleaner(ProfilePage, [
  ...utmTrackingParams,
  ProfilePageQueryParamsEnum.appDownload,
  ProfilePageQueryParamsEnum.TAB,
  ProfilePageQueryParamsEnum.brandId,
  ProfilePageQueryParamsEnum.TARGET,
]);

export const getCompatibilityProfileRoutes = (
  compatibilities: ICompatibilitiesMapping,
): RouteProps[] => {
  const routes: RouteProps[] = [];

  // RC Profile Path should be placed at last, as it's path matching rule is `/:vanityUrl`.

  const partnerCompatibilityProfileRoutes = Object.keys(compatibilities)
    .filter((key) => key !== `${Compatibility.RINGCENTRAL}`)
    .reduce((previous, key) => {
      const compatibility = compatibilities[key];

      previous.push({
        path: getCompatibilityProfilePath(compatibility.slug, ':vanityUrl'),
        component: ProfilePageWithQueryParamCleaner,
      });

      return previous;
    }, routes);

  const rcCompatibility = compatibilities[Compatibility.RINGCENTRAL];
  const rcCompatibilityProfileRoute = {
    path: getCompatibilityProfilePath(rcCompatibility.slug, ':vanityUrl'),
    component: ProfilePageWithQueryParamCleaner,
  };

  return [...partnerCompatibilityProfileRoutes, rcCompatibilityProfileRoute];
};
