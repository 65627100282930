import React, { useContext } from 'react';
import { styled } from '@ringcentral/juno';
import { observer } from 'mobx-react';
import { FeaturedIn } from '@apw/lib/components/profileDetail/profileBody/overviewTab/featuredIn';
import { RatingsAndReviews } from '@apw/lib/components/profileDetail/profileBody/overviewTab/ratingsAndReviews/RatingsAndReview';
import { StoreContext } from '../../StoreContext';
import { HelpfulLinks } from './helpfulLinks';
import { LongDesc } from './longDesc';
import { ProfileCarousel } from './profileCarousel';
import { ResourceLinks } from './resourceLinks';
import { TagList } from './tagList';

const LeftDetailContent = styled.div`
  flex: 0 0 auto;
  width: 212px;
  margin-right: 32px;
  padding-top: 4px;

  .leftContentItem {
    margin-bottom: 40px;
    border-bottom: 1px solid #cbcbcb;
    padding-bottom: 4px;

    &:empty,
    &:last-child {
      margin-bottom: 0;
      border-bottom-width: 0;
    }
  }
`;

const RightDetailContent = styled.div`
  flex: 1 1 auto;
  width: 0;
  overflow-wrap: break-word;
`;

const DetailContent = styled.div`
  display: flex;

  &.oneColumnLayout {
    flex-direction: column-reverse;
    margin-top: 40px;

    ${LeftDetailContent} {
      width: 100%;
      margin-right: 0;
    }

    ${RightDetailContent} {
      width: auto;
    }
  }
`;

const H1Tag = styled.h1`
  margin: 0 0 20px;
  overflow-wrap: break-word;
  font-size: 22px;
  font-weight: 600;
  color: #001138;
`;

function OverviewTabPure() {
  const { profile, translation, isOneColumnLayout } = useContext(StoreContext);

  if (!profile) {
    return null;
  }

  return (
    <div data-test-automation-id="overview">
      <ProfileCarousel />
      <DetailContent className={isOneColumnLayout ? 'oneColumnLayout' : ''}>
        <LeftDetailContent data-test-automation-id="overview-panel-sidebar">
          {profile.appRatingEnabled && (
            <RatingsAndReviews ratings={profile.appRating} />
          )}
          <div className="leftContentItem">
            <TagList
              tagType={translation.categories}
              tagList={profile.categoryTags}
              readonly
            />
            <TagList
              tagType={translation.products}
              tagList={profile.productTags}
              readonly
            />
          </div>
          <HelpfulLinks
            className="leftContentItem"
            links={profile.helpfulLinks}
          />
          {profile.pageList && isOneColumnLayout && (
            <FeaturedIn pages={profile.pageList} />
          )}
        </LeftDetailContent>
        <RightDetailContent data-test-automation-id="overview-panel-main">
          <H1Tag className="h1Tag" data-test-automation-id="profileH1Tag">
            {(profile.h1Tag && profile.h1Tag.trim()) || translation.h1Tag}
          </H1Tag>
          <LongDesc content={profile.detailedDes} />
          <ResourceLinks links={profile.processResourceLinks} />
          {profile.pageList && !isOneColumnLayout && (
            <FeaturedIn pages={profile.pageList} />
          )}
        </RightDetailContent>
      </DetailContent>
    </div>
  );
}

export const OverviewTab = observer(OverviewTabPure);
