import React, { FC } from 'react';
import { IProcessedProfile } from '@apw/components/profileDetail';
import { googleAnalytics, mixpanel, safeWindowOpen } from '@apw/core';
import { platformTypeToAppType } from '@apw/core/application.service';
import { onMobile } from '@apw/core/sc';
import { applyProximaNovaRegular } from '@apw/shared/theme/utils/typography';
import { rootStore } from '@apw/stores';
import { RcButton, RcIcon, RcLink, styled } from '@ringcentral/juno';
import { ExternalLink } from '@ringcentral/juno-icon';

const ButtonWrapper = styled(RcButton)`
  display: inline-flex;
  align-items: center;
  padding: 0 !important;
  font-size: 16px !important;
  font-weight: normal !important;
  ${applyProximaNovaRegular()};

  ${onMobile} {
    margin-top: 20px !important;
  }

  a {
    ${applyProximaNovaRegular()};
  }

  &:hover {
    a {
      text-decoration: underline;
    }
  }
`;

const StyledText = styled.span`
  vertical-align: middle;

  a {
    font-size: 15px !important;
    font-weight: normal !important;
    ${applyProximaNovaRegular()};
  }

  ${onMobile} {
    a {
      font-size: 20px !important;
      line-height: 24px;
    }
  }
`;

export const StyledIcon = styled(RcIcon)`
  margin-left: 6px;

  ${onMobile} {
    width: 20px;
    height: 20px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

const trackGetSupportClick = (profile: IProcessedProfile) => {
  const brand = rootStore.compatibilitiesStore.getCompatibilityById(
    profile.brand,
  );

  const properties = {
    appId: profile.appId || '',
    profileId: profile._id,
    profileName: profile.appName,
    brandPartner: brand?.name || '',
    appName: profile.applicationName || '',
    appDisplayName: profile.applicationDisplayName || '',
    appType: platformTypeToAppType(profile.applicationType) || '',
  };

  mixpanel.trackGetSupportClicked(properties);
  googleAnalytics.trackGetSupportClicked(properties);
};

const getSupport = (profile: IProcessedProfile) => {
  safeWindowOpen(profile.supporterLink);
  trackGetSupportClick(profile);
};

export const GetSupport: FC<{ profile: IProcessedProfile }> = ({
  profile,
}: {
  profile: IProcessedProfile;
}) => {
  const colorTheme = 'secondary';
  return (
    <ButtonWrapper
      variant="plain"
      onClick={() => getSupport(profile)}
      data-test-automation-id={`get-support-wrapper`}
    >
      <StyledText>
        <RcLink color={colorTheme} data-test-automation-id={`get-support-link`}>
          Get support
        </RcLink>
      </StyledText>
      <StyledIcon
        color={['text', colorTheme]}
        symbol={ExternalLink}
        size="small"
        data-test-automation-id={`get-support-icon`}
      />
    </ButtonWrapper>
  );
};
