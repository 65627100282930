import { PrimaryButton } from '@apw/components/button';
import { PlatformOption } from '@apw/components/profileDetail/profileHeader/ctaButton/appDownloadButton/platformOption';
import {
  ctaButtonSizeOnMobile,
  ctaButtonTextOnMobile,
} from '@apw/components/profileDetail/profileHeader/ctaButton/ctaButton.sc';
import { Popover } from '@apw/core/popover';
import {
  menuItemHoverActiveStatus,
  onMobile,
  PAGE_MOBILE_PADDING,
  useProximaNovaRegular,
} from '@apw/core/sc';
import { RcIcon, RcMenuItem, styled } from '@ringcentral/juno';

export const MenuTriggerContainer = styled.div`
  display: flex;
`;

export const MenuTriggerButton = styled(PrimaryButton)`
  padding: 8px 16px;

  ${ctaButtonSizeOnMobile};
`;

export const MenuTriggerPlatformOption = styled(PlatformOption)`
  ${ctaButtonTextOnMobile};

  ${onMobile} {
    justify-content: center;
  }
`;

export const MenuTriggerPrimaryItem = styled(MenuTriggerButton)`
  flex-grow: 1;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
`;

export const MenuTriggerPopoverOpener = styled(MenuTriggerButton)`
  flex-shrink: 0;
  width: 30px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: 1px !important;
  min-width: 0 !important;
  padding: 8px 0 !important;
`;

export const MenuTriggerPopoverOpenerIcon = styled(RcIcon)`
  margin-right: 0 !important;
`;

export const MenuItem = styled(RcMenuItem)`
  ${useProximaNovaRegular};
  display: flex;
  padding: 4px 16px;
  min-height: 40px !important;
  white-space: normal !important;
  ${menuItemHoverActiveStatus};
`;

export const PopoverContainer = styled(Popover)`
  [class~='MuiPopover-paper'] {
    min-width: 200px;
    max-width: 300px;
    transform: translateX(-200px) !important;

    ${onMobile} {
      max-width: calc(100% - ${PAGE_MOBILE_PADDING * 2}px);
      left: ${PAGE_MOBILE_PADDING}px !important;
      transform: none !important;
    }
  }
`;
