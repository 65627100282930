import {
  onMobile,
  PAGE_DESKTOP_MARGINTOP,
  PAGE_MOBILE_MARGINTOP,
  FontColor,
  useProximaNovaBold,
  PAGE_MOBILE_PADDING,
} from '@apw/core/sc';
import { NavigationBar } from '@apw/shared';
import { styled } from '@ringcentral/juno';

export const NavigationBarStyled = styled(NavigationBar)`
  margin-bottom: 0 !important;
`;

export const MainContainer = styled.div`
  margin-top: ${PAGE_DESKTOP_MARGINTOP}px;
  display: flex;

  ${onMobile} {
    margin-top: ${PAGE_MOBILE_MARGINTOP}px;
    flex-direction: column;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background: white;
  margin-right: 48px;
  width: 240px;

  ${onMobile} {
    display: none;
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  min-width: 0;

  ${onMobile} {
    margin-top: 10px;
  }
`;

export const ResultTitle = styled.div`
  font-size: 40px;
  font-weight: bold;
  color: ${FontColor.black};
  word-break: break-all;
  ${useProximaNovaBold};

  ${onMobile} {
    margin-bottom: 26px;
    font-size: 32px;
  }
`;

export const MobileBar = styled.div`
  display: none;

  ${onMobile} {
    display: block;
    margin: 0 -${PAGE_MOBILE_PADDING}px;
  }
`;

export const ResultList = styled.div`
  margin-top: 52px;

  ${onMobile} {
    margin-top: 10px;
  }
`;
