// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.74.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.74.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".collectionBlock_wrapper__3ZcY-{width:100%;margin-bottom:32px}.collectionBlock_wrapper__3ZcY-:last-child{margin-bottom:0}.collectionBlock_collectionBody__2QPTh{display:flex;flex-wrap:wrap;margin-right:-12px}@media(max-width: 767px){.collectionBlock_collectionBody__2QPTh{margin-right:0}}.collectionBlock_loadingWrapper__1ipXl{position:relative;width:100%;height:50px}", "",{"version":3,"sources":["webpack://./src/components/pageContent/collectionBlock/collectionBlock.module.scss"],"names":[],"mappings":"AAAA,gCAAS,UAAU,CAAC,kBAAkB,CAAC,2CAAoB,eAAe,CAAC,uCAAgB,YAAY,CAAC,cAAc,CAAC,kBAAkB,CAAC,yBAAyB,uCAAgB,cAAc,CAAC,CAAC,uCAAgB,iBAAiB,CAAC,UAAU,CAAC,WAAW","sourcesContent":[".wrapper{width:100%;margin-bottom:32px}.wrapper:last-child{margin-bottom:0}.collectionBody{display:flex;flex-wrap:wrap;margin-right:-12px}@media(max-width: 767px){.collectionBody{margin-right:0}}.loadingWrapper{position:relative;width:100%;height:50px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "collectionBlock_wrapper__3ZcY-",
	"collectionBody": "collectionBlock_collectionBody__2QPTh",
	"loadingWrapper": "collectionBlock_loadingWrapper__1ipXl"
};
export default ___CSS_LOADER_EXPORT___;
