import { IRawBotItems } from '@apw/modules/myApps';
import { BotItemStatus, IBotItem } from '@apw/modules/myApps/botList/typings';
import {
  getAdditionalAppInfo,
  getClientInfoWebUris,
  getInstalledBots,
  getProfileImageUrl,
} from '@apw/modules/myApps/myApp.service';
import { RootStore } from '@apw/stores';
import { action, computed, observable } from 'mobx';

export const BOT_LIST_PER_PAGE = 5;

export class MyAppsStore {
  @observable
  isUserAppsVisible = false;

  @observable
  serviceWebHome = '';

  @observable
  rawBotItems: IRawBotItems = {};

  @observable
  botItems: IBotItem[] = [];

  @observable
  isBotListLoading = false;

  @observable
  currentPageForBotList = 1;

  @observable
  totalPagesForBotList = 1;

  constructor(private rootStore: RootStore) {}

  @action.bound
  setIsUserAppsVisible(visible: boolean) {
    this.isUserAppsVisible = visible;
  }

  @action.bound
  setServiceWebHome(url: string) {
    this.serviceWebHome = url;
  }

  @action.bound
  setRawBotItems(botItems: IRawBotItems) {
    this.rawBotItems = botItems;
  }

  @action.bound
  setBotItems(botItems: IBotItem[]) {
    this.botItems = botItems;
  }

  @action.bound
  setBotListLoading(loading: boolean) {
    this.isBotListLoading = loading;
  }

  @action.bound
  setCurrentPageForBotList(page: number) {
    this.currentPageForBotList = page;
  }

  @action.bound
  setTotalPagesForBotList(totalPages: number) {
    this.totalPagesForBotList = totalPages;
  }

  async init() {
    await this.loadClientInfoWebUris();
    await this.loadBotList();
  }

  async onRawBotItemsLoad(rawBotItems: IRawBotItems) {
    const totalPages = Object.keys(rawBotItems).length;

    this.setRawBotItems(rawBotItems);
    this.setTotalPagesForBotList(totalPages);
    this.setCurrentPageForBotList(1);

    await this.loadBotItemsByPage(1);
  }

  @computed
  get isInfiniteScrollEnabled() {
    if (this.isBotListLoading) {
      return false;
    }

    return this.currentPageForBotList < this.totalPagesForBotList;
  }

  onScroll = async () => {
    if (!this.isInfiniteScrollEnabled) {
      return;
    }

    if (this.currentPageForBotList < this.totalPagesForBotList) {
      await this.setCurrentPageForBotList(this.currentPageForBotList + 1);

      try {
        await this.loadBotItemsByPage(this.currentPageForBotList);
      } catch (e) {
        await this.setCurrentPageForBotList(this.currentPageForBotList - 1);
      }
    }
  };

  dispose() {
    this.setIsUserAppsVisible(false);
    this.setServiceWebHome('');

    this.setRawBotItems({});
    this.setBotItems([]);
    this.setBotListLoading(false);
    this.setCurrentPageForBotList(1);
    this.setTotalPagesForBotList(1);
  }

  private async loadClientInfoWebUris() {
    try {
      const res = await getClientInfoWebUris();
      const visible = res.serviceWebAccess && res.authorizedAppsAccess;

      this.setIsUserAppsVisible(visible);
      this.setServiceWebHome(res.serviceWebHome);
    } catch (e) {
      // pass
    }
  }

  loadBotList = async () => {
    this.setBotListLoading(true);

    try {
      const rawBotItems = await getInstalledBots(BOT_LIST_PER_PAGE);
      await this.onRawBotItemsLoad(rawBotItems);
    } catch (e) {
      // pass
    } finally {
      this.setBotListLoading(false);
    }
  };

  removeBotItem(botExtensionId: string) {
    const newBotItems = this.botItems.filter((botItem) => {
      return botItem.id !== botExtensionId;
    });

    this.setBotItems(newBotItems);
  }

  private async loadBotItemsByPage(page = 1) {
    const index = page - 1;
    const rawBotItems = this.rawBotItems[index];

    try {
      this.setBotListLoading(true);

      const appInfoItems = await Promise.all(
        rawBotItems.map((rawBotItem) => {
          return getAdditionalAppInfo(rawBotItem.botExtension.id).catch(() => ({
            id: '',
            name: '',
            description: '',
            appScope: '',
          }));
        }),
      );

      const botItems: IBotItem[] = [];

      appInfoItems.forEach((appInfoItem, i) => {
        const rawBotItem = rawBotItems[i];
        const botExtension = rawBotItem.botExtension;
        const botItem: IBotItem = {
          id: botExtension.id,
          logoUrl: getProfileImageUrl(botExtension.id),
          name: botExtension.name,
          botClientId: botExtension.applicationKey,
          appId: appInfoItem.id,
          type: appInfoItem.appScope,
          desc: appInfoItem.description,
          status: BotItemStatus.REMOVABLE,
          appName: appInfoItem.name,
          ableToRemove: rawBotItem.ableToRemove,
          installer:
            botExtension.creatorId ===
            this.rootStore.userStore.user?.extensionId
              ? 'you'
              : rawBotItem.creator || 'deleted user',
        };
        botItems.push(botItem);
      });

      this.setBotListLoading(false);

      if (page === 1) {
        this.setBotItems(botItems);
        return;
      }

      this.setBotItems([...this.botItems, ...botItems]);
    } catch (e) {
      this.setBotListLoading(false);
      throw new Error(e);
    }
  }
}
