// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.74.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.74.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".blockHeader_headerContainer__3-qhO{margin-top:8px;margin-bottom:20px}.blockHeader_heading__kV7rx{word-wrap:break-word;overflow-wrap:break-word}.blockHeader_subHeading__1na18{word-wrap:break-word;overflow-wrap:break-word;margin-top:4px}", "",{"version":3,"sources":["webpack://./src/components/pageContent/shared/blockHeader/blockHeader.module.scss"],"names":[],"mappings":"AAAA,oCAAiB,cAAc,CAAC,kBAAkB,CAAC,4BAAS,oBAAoB,CAAC,wBAAwB,CAAC,+BAAY,oBAAoB,CAAC,wBAAwB,CAAC,cAAc","sourcesContent":[".headerContainer{margin-top:8px;margin-bottom:20px}.heading{word-wrap:break-word;overflow-wrap:break-word}.subHeading{word-wrap:break-word;overflow-wrap:break-word;margin-top:4px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": "blockHeader_headerContainer__3-qhO",
	"heading": "blockHeader_heading__kV7rx",
	"subHeading": "blockHeader_subHeading__1na18"
};
export default ___CSS_LOADER_EXPORT___;
