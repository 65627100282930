import React, { ReactElement, useRef } from 'react';
import { PopoverExternalControl } from '@apw/core/popover/typings';
import { ProductContent } from '@apw/modules/search/filterBar/share/ProductContent';
import { useStores } from '@apw/stores';
import { RcIcon } from '@ringcentral/juno';
import { ArrowDown2, ArrowUp2 } from '@ringcentral/juno-icon';
import { observer } from 'mobx-react';
import {
  Container,
  ProductTriggerStyled,
  PopoverContainer,
} from './ProductFilter.sc';
import { allProductsItem, productConfig } from './products';

const ProductTrigger = (productId: string) => {
  const product = productConfig[productId] || allProductsItem;
  return (showPopover: (event) => void, open: boolean): ReactElement => {
    return (
      <ProductTriggerStyled
        size="large"
        onClick={showPopover}
        className={`${open && 'on'}`}
        tabIndex={0}
        role="button"
        aria-expanded={open}
        data-test-automation-id="product-filter"
      >
        {product.displayName}
        <RcIcon
          className={'triggerIcon'}
          symbol={open ? ArrowUp2 : ArrowDown2}
          size="large"
          aria-hidden="true"
        />
      </ProductTriggerStyled>
    );
  };
};

const ProductFilterPure = () => {
  const { searchStore } = useStores();
  const hidePopover = () => {
    popoverControlRef.current.hidePopover();
  };

  const popoverControlRef = useRef<PopoverExternalControl>({
    hidePopover: () => {},
  });

  return (
    <Container>
      <PopoverContainer
        data-test-automation-id="product-filter-popover"
        getTrigger={ProductTrigger(searchStore.selectedProductId)}
        initExternalControl={(control) => (popoverControlRef.current = control)}
      >
        <ProductContent
          data-test-automation-id="product-filter-content"
          onChange={hidePopover}
        />
      </PopoverContainer>
    </Container>
  );
};

export const ProductFilter = observer(ProductFilterPure);
