import { styled } from '@ringcentral/juno';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: auto !important;
  }
`;
