import { useProximaNovaBold } from '@apw/core/sc';
import { RcIcon, styled } from '@ringcentral/juno';

const color = '#32773B';

export const Wrapper = styled.span`
  color: ${color};
  font-size: 12px;
  line-height: 16px;
`;

export const CertifiedIcon = styled(RcIcon)`
  vertical-align: text-bottom;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${color};
    }
  }
`;

export const CertifiedText = styled.span`
  ${useProximaNovaBold};
  margin-left: 4px;
`;
