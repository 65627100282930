import { styled } from '@ringcentral/juno';

export const Container = styled.div`
  overflow: hidden;
  position: relative;
  height: 100%;
`;

export const ItemList = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  cursor: pointer;
  transition: transform 0.5s cubic-bezier(0.1, 0.57, 0.1, 1);
  white-space: nowrap;
  font-size: 0;
  touch-action: pan-y;

  &.noTransition {
    transition: transform 0s ease;
  }
`;

export const Item = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left bottom;
`;

export const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  left: 50%;
  bottom: 20px;
  z-index: 1;
  transform: translateX(-50%);
`;

export const Dot = styled.div`
  cursor: pointer;
  margin: 0 10px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 2px solid #6a7186;

  &.active {
    background-color: #6a7186;
  }
`;

export const A11yWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
  }
`;
