import { css } from '@ringcentral/juno';

const fallbackFontFamily = 'Helvetica, Arial, sans-serif';

export const applyProximaNovaRegular = () => {
  return css`
    font-family: 'ProximaNova-Regular', ${fallbackFontFamily} !important;
  `;
};

export const applyProximaNovaMedium = () => {
  return css`
    font-family: 'ProximaNova-Medium', ${fallbackFontFamily} !important;
  `;
};

export const applyProximaNovaBold = () => {
  return css`
    font-family: 'ProximaNova-Bold', ${fallbackFontFamily} !important;
  `;
};

export const applyProximaNovaSemibold = () => {
  return css`
    font-family: 'ProximaNova-Semibold', ${fallbackFontFamily} !important;
  `;
};
