import React from 'react';
import { HeaderContainer, Main, SideItem } from '@apw/shared/header/Header.sc';
import { BrandDeveloper, HeaderBrand, SearchBox } from './shared';

export function Header() {
  return (
    <HeaderContainer data-test-automation-id="header">
      <Main data-test-automation-id="main">
        <SideItem data-test-automation-id="side-item">
          <HeaderBrand />
        </SideItem>
        <SearchBox />
        <SideItem>
          <BrandDeveloper />
        </SideItem>
      </Main>
    </HeaderContainer>
  );
}
