import { APIRequestOptions } from '@apw/core';
import axios, { AxiosResponse } from 'axios';
import _jsonp from 'jsonp';
import qs from 'qs';

const XSRF_COOKIE_NAME = 'rcapw-XSRF-TOKEN';
const BASE_URL = '/apps/';

export const axiosInstance = axios.create({
  xsrfCookieName: XSRF_COOKIE_NAME,
  baseURL: BASE_URL,
});

const paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: 'repeat' });
};

export const get = <T>(
  url: string,
  options?: APIRequestOptions,
): Promise<T> => {
  if (options) {
    options.paramsSerializer = paramsSerializer;
  }
  if (window.__APW_STATE__) {
    const cachedValue = window.__APW_STATE__[url];
    if (cachedValue) {
      delete window.__APW_STATE__[url];
      return Promise.resolve(cachedValue);
    }
  }

  return promiseRetry(
    () =>
      axiosInstance
        .get<T>(url, options)
        .then((res: AxiosResponse<T>) => res.data),
    options?.retries,
  );
};

export const post = <T>(
  url: string,
  data?: any,
  options?: APIRequestOptions,
): Promise<T> =>
  promiseRetry(
    () =>
      axiosInstance
        .post<T>(url, data, options)
        .then((res: AxiosResponse<T>) => res.data),
    options?.retries,
  );

export const put = <T>(
  url: string,
  data?: any,
  options?: APIRequestOptions,
): Promise<T> =>
  promiseRetry(
    () =>
      axiosInstance
        .put<T>(url, data, options)
        .then((res: AxiosResponse<T>) => res.data),
    options?.retries,
  );

export const del = <T>(url: string, options?: APIRequestOptions): Promise<T> =>
  promiseRetry(
    () =>
      axiosInstance
        .delete<T>(url, options)
        .then((res: AxiosResponse<T>) => res.data),
    options?.retries,
  );

// https://github.com/webmodules/jsonp
export const jsonp = (
  url: string,
  options: {
    param?: string;
    timeout?: number;
    prefix?: string;
    name?: string;
  } | null = null,
): Promise<any> =>
  new Promise((resolve, reject) => {
    _jsonp(url, options, (err, data) => {
      if (err) {
        reject(err);
      }

      resolve(data);
    });
  });

export const promiseRetry = <T>(promiseTrigger, time = 0): Promise<T> => {
  return new Promise(async (resolve, reject) => {
    // eslint-disable-next-line no-constant-condition
    while (1) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const res = await promiseTrigger();
        return resolve(res);
      } catch (error) {
        if (time-- <= 0) return reject(error);
      }
    }
  });
};
