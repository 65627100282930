import React, { FC } from 'react';
import { styled } from '@ringcentral/juno';
import { richTextFieldService } from '../../../../../services';

// the quill-editor can render 8-level nest bullets at most
const listIndentLevel = 8;
const listRowSpacing = 20;
const nestListRowSpacing = 12;

export const appendBulletIndentStyle = (basicSpace: number) => {
  const classesStyle: string[] = [];
  for (let i = 0; i < listIndentLevel; i++) {
    const num = i + 1;
    const classStyle = `
      &.ql-indent-${num} {
        padding-left: ${basicSpace * num}px;
      }
    `;
    classesStyle.push(classStyle);
  }
  return classesStyle.join('');
};

const orderedItemCounterReset = (start: number, end: number) => {
  let result = '';
  for (let i = start; i <= end; i++) {
    result = `${result} list-${i}`;
  }
  return result;
};

const orderedItemCounterMode = (index: number) => {
  const val = index % 3;
  if (val === 0) {
    return 'decimal';
  }
  if (val === 1) {
    return 'lower-alpha';
  }
  if (val === 2) {
    return 'lower-roman';
  }
  return 'decimal';
};

export const appendOrderedIndentStyle = (basicSpace: number) => {
  const classesStyle: string[] = [];
  for (let i = 0; i < listIndentLevel; i++) {
    const num = i + 1;
    const classStyle = `
    &.ql-indent-${num}{
        counter-increment: list-${num};
        counter-reset: ${orderedItemCounterReset(num + 1, listIndentLevel + 1)};
        padding-left: ${basicSpace * num}px;
        [class~='ql-ui'] {
          &:before {
            content: counter(list-${num}, ${orderedItemCounterMode(num)}) '. ';
           }
        }
    }`;
    classesStyle.push(classStyle);
  }
  return classesStyle.join('');
};

const nestListRowAdjustStyle = () => {
  return `margin-top: -${listRowSpacing - nestListRowSpacing}px;`;
};

export const appendNestListRowSpacingStyle = () => {
  const classesStyle: string[] = [];
  for (let i = 0; i < listIndentLevel; i++) {
    const num = i + 1;
    const classStyle = `
    &.ql-indent-${num} {
      &+[class~=ql-indent-${num}] {
        ${nestListRowAdjustStyle()}
      }
      &+[class~=ql-indent-${num + 1}] {
        ${nestListRowAdjustStyle()}
      }
    }`;
    classesStyle.push(classStyle);
  }
  return classesStyle.join('');
};

const MarkdownContentWrapper = styled.div`
  font-size: 16px;
  font-weight: normal;
  color: #282829;

  h1,
  h2,
  h3 {
    margin: 20px 0 20px;
    line-height: 26px;
    font-weight: 600;
    font-size: 20px;
  }

  h3 {
    font-size: 16px;
    line-height: 24px;
  }

  p {
    margin: 0 0 20px;
    line-height: 24px;
  }

  ol,
  ul {
    margin: 0;
    line-height: 24px;
    padding-left: 37px;

    li {
      margin-bottom: ${listRowSpacing}px;
      padding-left: 0;
      color: #282829;
    }
  }

  ol li {
    padding-left: 3px;

    // Workaround for quill@2.0.0-dev.3, which produces <ol><li data-list="bullet">example</li></ol> for unordered lists
    &[data-list='bullet'] {
      list-style: none;
      .ql-ui::before {
        content: '\\2022';
        display: inline-block;
        margin-left: -16px;
        text-align: left;
        width: 16px;
      }
      ${appendBulletIndentStyle(40)};
    }

    &[data-list='ordered'] {
      list-style: none;
      counter-increment: list-0;
      counter-reset: ${orderedItemCounterReset(1, listIndentLevel + 1)};
      .ql-ui:before {
        display: inline-block;
        margin-left: -16px;
        text-align: left;
        width: 16px;
      }
      .ql-ui:before {
        content: counter(list-0, ${orderedItemCounterMode(0)}) '. ';
      }
      ${appendOrderedIndentStyle(40)};
    }

    &[data-list='bullet'],
    &[data-list='ordered'] {
      & + [class~='ql-indent-1'] {
        ${nestListRowAdjustStyle()}
      }
      ${appendNestListRowSpacingStyle()}
    }
  }

  a {
    line-height: 26px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  blockquote {
    margin: 20px;
    padding-left: 14px;
    border-left: 2px solid #686869;
  }
`;

const Wrapper = styled(MarkdownContentWrapper)`
  width: 100%;
  margin-bottom: 32px;
`;

export const LongDesc: FC<{
  content: string;
  className?: string;
}> = ({ content, className }) => {
  const detailDesc = {
    dangerouslySetInnerHTML: {
      __html: richTextFieldService.convertToHtmlFormat(content),
    },
  };

  return (
    <Wrapper
      className={`longDesc ${className || ''}`}
      {...detailDesc}
      data-test-automation-id="profileLongDesc"
    />
  );
};
