import { onMobile, useProximaNovaRegular } from '@apw/core/sc';
import { RcDialog, RcTextarea, styled } from '@ringcentral/juno';

export const LeadFormDialog = styled(RcDialog)`
  [class~='MuiDialog-paper'] {
    max-width: 760px !important;
    height: 721px !important;

    ${onMobile} {
      max-width: none !important;
      max-height: none !important;
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
    }
  }
`;

export const RequirementsField = styled(RcTextarea)`
  margin-bottom: 24px !important;

  textarea {
    background: transparent !important;
    height: auto !important;
    ${useProximaNovaRegular};
  }
`;

export const CheckboxContainer = styled.div`
  [class~='MuiFormControlLabel-root'] {
    margin-right: 0 !important;
  }
`;

export const ReceiveEmailsLabel = styled.span`
  ${useProximaNovaRegular};
  font-size: 13px;
  line-height: 18px;
  color: #666;
  display: inline-block;

  a {
    text-decoration: none !important;
  }
`;
