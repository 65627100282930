import React from 'react';
import { ScaleContainer, Content } from './ScaleDiv.sc';

const ScaleDiv = ({ widthWeight, heightWeight, children }) => {
  return (
    <ScaleContainer
      style={{ paddingBottom: `${(heightWeight / widthWeight) * 100}%` }}
    >
      <Content>{children}</Content>
    </ScaleContainer>
  );
};

export default ScaleDiv;
