import React, { FC } from 'react';
import { useIsMobileDevice } from '@apw/hooks/deviceWidth/useIsMobileDevice';
import { PartnerAccountTooltip } from '@apw/modules/profile';
import { HeaderMobilePopup } from '@apw/shared';
import { signIn } from '@apw/stores/user';
import { Close } from '@ringcentral/juno-icon';
import {
  DialogTitle,
  DialogCloseButton,
  DialogContent,
  DialogContentMain,
  ActionButtonContainer,
  ActionButtonHeader,
  ActionButton,
} from './LoginOptionContent.sc';

type LoginOptionContentPureProps = {
  title: string;
  onClose: () => void;
  onAfterSignIn: () => void;
  onAsGuest: () => void;
};

const SignIn: FC<{ onAfterSignIn: () => void }> = ({ onAfterSignIn }) => {
  return (
    <ActionButtonContainer>
      <ActionButtonHeader>
        <span data-test-automation-id="login-option-dialog-sign-in-msg">
          To continue, sign in with your RingCentral <br />
          or Partner account
        </span>
        <PartnerAccountTooltip automationId="login-option-dialog-sign-in-tooltip" />
      </ActionButtonHeader>
      <ActionButton
        onClick={() => {
          signIn(onAfterSignIn);
        }}
        data-test-automation-id="login-option-dialog-sign-in-button"
      >
        Sign In
      </ActionButton>
    </ActionButtonContainer>
  );
};

const Guest: FC<{ onAsGuest: () => void }> = ({ onAsGuest }) => {
  return (
    <ActionButtonContainer>
      <ActionButtonHeader>
        <span data-test-automation-id="login-option-dialog-guest-msg">
          Don't have a RingCentral or Partner <br />
          account?
        </span>
        <PartnerAccountTooltip automationId="login-option-dialog-guest-tooltip" />
      </ActionButtonHeader>
      <ActionButton
        variant="outlined"
        onClick={onAsGuest}
        data-test-automation-id="login-option-dialog-guest-button"
      >
        Continue as Guest
      </ActionButton>
    </ActionButtonContainer>
  );
};

const LoginOptionContentPure = ({
  title,
  onClose,
  onAfterSignIn,
  onAsGuest,
}: LoginOptionContentPureProps) => {
  const isMobile = useIsMobileDevice();

  return (
    <React.Fragment>
      <HeaderMobilePopup onBack={onClose} />
      <DialogTitle data-test-automation-id="login-option-dialog-title">
        {title}
        <DialogCloseButton
          data-test-automation-id="login-option-dialog-close-button"
          onClick={onClose}
          symbol={Close}
          aria-label="close"
          aria-hidden={isMobile ? 'true' : 'false'}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentMain>
          <SignIn onAfterSignIn={onAfterSignIn} />
          <Guest onAsGuest={onAsGuest} />
        </DialogContentMain>
      </DialogContent>
    </React.Fragment>
  );
};

export const LoginOptionContent = LoginOptionContentPure;
