import { createGlobalStyle } from '@ringcentral/juno';

export const GLOBAL_SCROLL_BLOCK_CLASS = 'global-scroll-block';

export const GlobalScrollBlockStyles = createGlobalStyle`
  html.${GLOBAL_SCROLL_BLOCK_CLASS} {
    position: fixed;
    width: 100%;
    left: 0;
    overflow-y: scroll !important;

    body {
      padding-right: 0 !important;
    }
  }
`;
