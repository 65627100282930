import React from 'react';
import { SignInButton } from '@apw/components/ratingAndReview/actionButtons/signInButton/SignInButton';
import { WriteReviewButton } from '@apw/components/ratingAndReview/actionButtons/writeReviewButton/WriteReviewButton';
import { useUserCompatibility } from '@apw/hooks';
import { useStores } from '@apw/stores';
import { observer } from 'mobx-react';

export const ActionButtons = observer(({ fullWidth = true }) => {
  const { isUserIncompatibleWithCurrentGallery } = useUserCompatibility();
  const { userStore } = useStores();

  return userStore.isLoggedIn ? (
    <WriteReviewButton
      fullWidth={fullWidth}
      disabled={isUserIncompatibleWithCurrentGallery}
    />
  ) : (
    <SignInButton fullWidth={fullWidth} />
  );
});
