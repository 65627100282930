import facebook from '@apw/assets/images/footer-icon/facebook.svg';
import instagram from '@apw/assets/images/footer-icon/instagram.svg';
import linkedin from '@apw/assets/images/footer-icon/linkedin.svg';
import tiktok from '@apw/assets/images/footer-icon/tiktok.svg';
import twitter from '@apw/assets/images/footer-icon/twitter.svg';
import youtube from '@apw/assets/images/footer-icon/youtube.svg';

export const socialIcons = [
  {
    name: 'Facebook',
    href: 'https://www.facebook.com/ringcentral',
    icon: facebook,
  },
  {
    name: 'Twitter',
    href: 'https://twitter.com/ringcentraldevs',
    icon: twitter,
  },
  {
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/company/ringcentral',
    icon: linkedin,
  },
  {
    name: 'YouTube',
    href: 'https://www.youtube.com/user/ringcentral',
    icon: youtube,
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/ringcentral',
    icon: instagram,
  },
  {
    name: 'Tiktok',
    href: 'https://www.tiktok.com/@ringcentral',
    icon: tiktok,
  },
];

export const footerLinks = [
  {
    label: 'Legal',
    href: 'https://www.ringcentral.com/legal.html',
  },
  {
    label: 'Privacy',
    href: 'https://www.ringcentral.com/legal/privacy-notice.html',
  },
  {
    label: 'Cookie Notice',
    href: 'https://www.ringcentral.com/legal/cookie-notice.html',
  },
  {
    label: 'Site Map',
    href: 'https://www.ringcentral.com/sitemap.html',
  },
  {
    label: 'Accessibility',
    href: 'https://www.ringcentral.com/accessibility.html',
  },
  {
    label: 'Contact Us',
    href: 'https://www.ringcentral.com/whyringcentral/contactus.html',
  },
  {
    label: 'Cookie Preferences',
    onClick() {
      if (window.OneTrust) {
        window.OneTrust.ToggleInfoDisplay();
      }
    },
  },
  {
    label: 'Do not sell or share my personal data',
    onClick() {
      if (window.OneTrust) {
        window.OneTrust.ToggleInfoDisplay();
      }
    },
  },
];
