import { useState } from 'react';

export const useOnce = (callback: Function) => {
  const [isCalled, setIsCalled] = useState(false);
  return (...args) => {
    if (isCalled) return;
    setIsCalled(true);
    return callback(...args);
  };
};
