import React, { useContext, useMemo, useRef } from 'react';
import { palette2, styled } from '@ringcentral/juno';
import { observer } from 'mobx-react';
import { MediaPropEnum, MediaTypeEnum } from '@apw/lib/types';
import Carousel, {
  CarouselBreakpoints,
  CarouselRef,
} from '../../../../carousel/Carousel';
import { StoreContext } from '../../../StoreContext';
import { Media } from './media';
import { ScaleBox } from './scaleBox';

function ScaleWrap(props) {
  return (
    <ScaleBox widthWeight={488} heightWeight={274}>
      {props.children}
    </ScaleBox>
  );
}

const CarouselBackground = styled.div`
  background: ${palette2('neutral', 'b03')};
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 42px;
`;

const SingleMediaContainer = styled.div`
  max-width: 488px;
  margin: auto;
`;

export const ProfileCarousel = observer(() => {
  const { profile, onCarouselClick, translation, isTargetingCookiesEnabled } =
    useContext(StoreContext);

  const carouselRef = useRef<CarouselRef>(null);
  const breakpoints: CarouselBreakpoints = {
    0: { size: 1, gap: 0, shift: 0 },
    960: { size: 2, gap: 16, shift: 0 },
  };

  const mediaFiltered = useMemo(() => {
    const items = profile?.media || [];

    if (!isTargetingCookiesEnabled) {
      return items.filter(
        (item) => item[MediaPropEnum.TYPE] === MediaTypeEnum.SCREENSHOT,
      );
    }

    return items;
  }, [profile?.media, isTargetingCookiesEnabled]);

  const onCarouselKeydown = (e, index) => {
    if (['Enter', ' '].includes(e.key)) {
      e.preventDefault();
      onCarouselClick(index);
    }
  };

  if (mediaFiltered.length === 0) {
    return null;
  }

  return (
    <CarouselBackground data-test-automation-id="profileCarousel">
      {mediaFiltered.length > 1 && (
        <Carousel
          ref={carouselRef}
          breakpoints={breakpoints}
          click={onCarouselClick}
          arrow={(pageTotal) => pageTotal > 1}
          draggable
          translation={{
            previousBtn: translation.carousel.previousScreenshot,
            nextBtn: translation.carousel.nextScreenshot,
          }}
        >
          {mediaFiltered.map((item, index) => (
            <>
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
              <div
                role="group"
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
                aria-label={translation.carousel.screenshotNumber(index + 1)}
                onFocus={() => carouselRef.current?.go(index + 1)}
                onKeyDown={(e) => onCarouselKeydown(e, index + 1)}
              >
                <ScaleWrap>
                  <Media media={item} mask />
                </ScaleWrap>
              </div>
            </>
          ))}
        </Carousel>
      )}
      {mediaFiltered.length === 1 && (
        <SingleMediaContainer
          onClick={() => onCarouselClick(1)}
          data-test-automation-id="carouselSingleMedia"
          role="group"
          tabIndex={0}
          aria-label={translation.carousel.screenshotNumber(1)}
          onKeyDown={(e) => onCarouselKeydown(e, 1)}
        >
          <ScaleWrap>
            <Media media={mediaFiltered[0]} mask />
          </ScaleWrap>
        </SingleMediaContainer>
      )}
    </CarouselBackground>
  );
});
