import React from 'react';
import {
  PrimaryNavigation,
  StyledPrimaryNavigationProps,
} from '@apw/shared/navigation';
import { palette2, styled } from '@ringcentral/juno';
import { NavLink } from 'react-router-dom';

export const StyledPrimaryNavigation = (
  props: StyledPrimaryNavigationProps,
) => {
  return <PrimaryNavigation StyledNavLink={StyledNavLink} {...props} />;
};

const classNameWhenActive = 'active';
const color = palette2('text', 'secondary');

const StyledNavLink = styled(NavLink).attrs(() => ({
  activeClassName: classNameWhenActive,
}))`
  &.${classNameWhenActive} {
    [class~='MuiMenuItem-root'] {
      color: ${color};
      font-weight: bold;
      &:hover,
      &:active {
        color: ${color};
      }
    }
  }
`;
