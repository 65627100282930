import React, { FC, useCallback, useEffect, useState } from 'react';
import { useCompatibilityPartnerEcosystemPath } from '@apw/routes/useCompatibilityPaths';
import { useAppGalleryOptions } from '@apw/shared/navigation/compatibilitySelector/shared/useAppGalleryOptions';
import { IAppGalleryOption } from '@apw/shared/navigation/compatibilitySelector/typings';
import {
  applyProximaNovaRegular,
  applyProximaNovaSemibold,
  breakpoints,
} from '@apw/shared/theme';
import { useStores } from '@apw/stores';
import {
  css,
  palette2,
  RcButton,
  RcIcon,
  RcTextField,
  styled,
} from '@ringcentral/juno';
import { Filter, Forward } from '@ringcentral/juno-icon';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import notFoundIcon from '../../../../assets/images/not-found.svg';
import { AppGalleryList, AppGalleryOptions } from './index';

const Wrapper = styled.div`
  padding: 20px 0 0;
  width: 320px;

  @media ${breakpoints.mobile} {
    width: 100%;
  }
`;

const SectionTitle = styled.div`
  &&& {
    display: flex;
    align-items: center;
    color: #121212;
    padding: 0 20px;

    &,
    ${RcButton} {
      ${applyProximaNovaSemibold()}
      font-size: 16px;
      line-height: 24px;
    }

    ${RcButton} {
      padding: 0;
      margin-left: 4px;
    }
  }
`;

const SearchResultWrapper = styled.div``;

const NoSearchResultsWrapper = styled.div``;

const NoSearchResultsIndicator = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const NotFoundText = styled.span`
  font-size: 16px;
  line-height: 22px;
  color: #16181d;
  margin-top: 24px;
`;

const NotFoundIcon = styled(RcIcon)`
  svg {
    width: 128px;
    height: 128px;
  }
`;

const placeholder = (color: string) => css`
  &::-webkit-input-placeholder {
    color: ${color} !important;
    opacity: 1;
  }
  &:-moz-placeholder {
    color: ${color} !important;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: ${color} !important;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: ${color} !important;
    opacity: 1;
  }
`;

const SearchBox = styled(RcTextField)`
  margin: 16px 20px;
  height: 40px;
  min-height: 40px;
  width: auto;
  border-color: #c7c7c7;
  display: flex;

  .MuiInput-root {
    height: 40px;
  }

  &.focused {
    border-color: ${palette2('border' as any, 'primary')};
  }

  svg {
    font-size: 16px;
    color: #666;
  }

  input {
    ${applyProximaNovaRegular()}
    ${placeholder('#666')}
    font-size: 16px !important;
    line-height: 22px;
  }

  button {
    width: 16px !important;

    svg {
      color: #666;
    }
  }
`;

const SectionContent = styled.div`
  height: 283px;
  overflow: auto;

  // @see DPW-16266
  transform: translateZ(0);
`;

const DividerWrapper = styled.div`
  margin-top: 16px;
  padding: 0 16px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e8e9ed;
`;

const ExplorePartnersSection = styled.div`
  button {
    text-align: left;
    justify-content: flex-start;
    margin: 20px;
    padding: 0 !important;
  }

  .forwardIcon {
    margin-left: 8px;
  }
`;

const ExplorePartnersLinkText = styled.span`
  color: ${palette2('text', 'primary')};
  ${applyProximaNovaSemibold()}
  font-size: 16px;
  line-height: 22px;
`;

interface IPopoverContentPureProps {
  rcGallery: IAppGalleryOption;
  partnerGalleries: IAppGalleryOption[];
  currentGalleryBrandId: string;
  onChange;
  onKeywordChange: (keyword: string) => void;
  keyword: string;
  searched: boolean;
  rcSearchResults: IAppGalleryOption[];
  partnerSearchResults: IAppGalleryOption[];
  onExplorePartners: () => void;
  onSignIn: () => void;
}

const PopoverContentPure: FC<IPopoverContentPureProps> = ({
  rcGallery,
  partnerGalleries,
  currentGalleryBrandId,
  onChange,
  onKeywordChange,
  keyword,
  searched,
  rcSearchResults,
  partnerSearchResults,
  onExplorePartners,
  onSignIn,
}) => {
  const [isSearchBoxFocused, setIsSearchBoxFocused] = useState(false);

  const noSearchResults =
    rcSearchResults.length === 0 && partnerSearchResults.length === 0;

  const searchResultBlock = (
    <>
      {!noSearchResults && (
        <SearchResultWrapper
          data-test-automation-id="compatibilitySelectorSearchResultWrapper"
          role="list"
        >
          {rcSearchResults.length > 0 && (
            <AppGalleryOptions
              appGalleryOptions={rcSearchResults}
              currentGalleryBrandId={currentGalleryBrandId}
              onChange={onChange}
              searchKeyword={keyword}
            />
          )}
          {partnerSearchResults.length > 0 && (
            <AppGalleryOptions
              appGalleryOptions={partnerSearchResults}
              currentGalleryBrandId={currentGalleryBrandId}
              onChange={onChange}
              searchKeyword={keyword}
            />
          )}
        </SearchResultWrapper>
      )}
      {noSearchResults && (
        <NoSearchResultsWrapper data-test-automation-id="compatibilitySelectorNoSearchResultsWrapper">
          <NoSearchResultsIndicator
            aria-label="No search results found"
            tabIndex={0}
          >
            <NotFoundIcon symbol={notFoundIcon} size="large" />
            <NotFoundText>No search results found</NotFoundText>
          </NoSearchResultsIndicator>
        </NoSearchResultsWrapper>
      )}
    </>
  );

  const appGalleryListBlock = (
    <AppGalleryList
      rcGallery={rcGallery}
      partnerGalleries={partnerGalleries}
      currentGalleryBrandId={currentGalleryBrandId}
      onChange={onChange}
    />
  );

  return (
    <Wrapper role="main">
      <SectionTitle>
        Choose a gallery or{' '}
        <RcButton
          variant="plain"
          onClick={onSignIn}
          data-test-automation-id="compatibilitySelectorSignInButton"
        >
          sign in
        </RcButton>
      </SectionTitle>
      <SearchBox
        variant="outline"
        size="small"
        InputProps={{
          startAdornment: <RcIcon size="small" symbol={Filter} />,
          'aria-label': 'Search for an App Gallery',
        }}
        placeholder="Search for an App Gallery"
        className={isSearchBoxFocused ? 'focused' : ''}
        onFocus={() => setIsSearchBoxFocused(true)}
        onBlur={() => setIsSearchBoxFocused(false)}
        onChange={(e) => onKeywordChange(e.target.value)}
        data-test-automation-id="compatibilitySelectorSearchBox"
      />
      <SectionContent>
        {searched && searchResultBlock}
        {!searched && appGalleryListBlock}
      </SectionContent>

      <DividerWrapper>
        <Divider />
      </DividerWrapper>

      <ExplorePartnersSection>
        <RcButton
          variant="plain"
          onClick={onExplorePartners}
          data-test-automation-id="explorePartnersButton"
        >
          <ExplorePartnersLinkText>
            Explore all partner brands
          </ExplorePartnersLinkText>
          <RcIcon className="forwardIcon" symbol={Forward} size="medium" />
        </RcButton>
      </ExplorePartnersSection>
    </Wrapper>
  );
};

interface IPopoverContentProps {
  onChange: (brandId: string) => void;
  hidePopover: () => void;
}

export const PopoverContent: FC<IPopoverContentProps> = observer(
  ({ onChange, hidePopover }) => {
    const { compatibilitiesStore, userStore } = useStores();
    const history = useHistory();
    const partnerEcosystemPath = useCompatibilityPartnerEcosystemPath();
    const [keyword, setKeyword] = useState<string>('');

    const [rcSearchResults, setRcSearchResults] = useState<IAppGalleryOption[]>(
      [],
    );
    const [partnerSearchResults, setPartnerSearchResults] = useState<
      IAppGalleryOption[]
    >([]);

    const {
      rcGallery,
      partnerGalleries,
      sourceForSearch,
      currentGalleryBrandId,
    } = useAppGalleryOptions();

    const onSearch = useCallback((keyword: string) => {
      const results = sourceForSearch.filter((item) => {
        keyword = (keyword || '').toLowerCase();
        const brandName = item.brandName.toLowerCase();
        return brandName.includes(keyword);
      });

      const rcResults = results.filter(
        (r) => r.brandId === compatibilitiesStore.rcCompatibility?.id,
      );

      const partnerResults = results
        .filter((r) => r.brandId !== compatibilitiesStore.rcCompatibility?.id)
        .sort((a, b) => {
          return a.brandName.localeCompare(b.brandName);
        });

      setRcSearchResults(rcResults);
      setPartnerSearchResults(partnerResults);
    }, []);

    const onSignIn = () => {
      userStore.signInViaCLW();
    };

    const onExplorePartners = () => {
      history.push(partnerEcosystemPath);
      hidePopover();
    };

    useEffect(() => {
      onSearch(keyword);
    }, [keyword]);

    return (
      <PopoverContentPure
        rcGallery={rcGallery}
        partnerGalleries={partnerGalleries}
        currentGalleryBrandId={currentGalleryBrandId}
        onChange={onChange}
        onKeywordChange={(keyword) => setKeyword(keyword)}
        keyword={keyword}
        searched={!!keyword}
        rcSearchResults={rcSearchResults}
        partnerSearchResults={partnerSearchResults}
        onExplorePartners={onExplorePartners}
        onSignIn={onSignIn}
      />
    );
  },
);
