import {
  ApplicationType,
  AppTypeEnum,
} from '@apw/components/profileDetail/typings';

export const platformTypeToAppType = (
  type: ApplicationType,
): AppTypeEnum | undefined => {
  const appTypeMapping = {
    [ApplicationType.SERVER_BOT]: AppTypeEnum.BOT,
    [ApplicationType.NOTIFICATION_APP]: AppTypeEnum.NOTIFICATION_APP,
    [ApplicationType.PROFILE_ONLY]: AppTypeEnum.PROFILE_ONLY,
  };
  if (type) {
    return appTypeMapping[type] || AppTypeEnum.REST_API;
  }
  return;
};
