export enum Product {
  MESSAGE = 'Glip',
  VIDEO = 'Video',
  WEBINAR = 'Webinar',
  PHONE = 'Office',

  CONTACT_CENTER = 'Contact Center',
  ENGAGE_DIGITAL = 'Engage Digital',
  ENGAGE_VOICE = 'Engage Voice',
  MEETINGS = 'Meetings',
  SERVICE_WEB = 'Service Web',
}
