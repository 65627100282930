import React, { useEffect, useRef, useState } from 'react';
import {
  PopoverExternalControl,
  PopoverTriggerGenerator,
} from '@apw/core/popover/typings';
import { SecondaryNavigation } from '@apw/shared/navigation';
import { useStores } from '@apw/stores';
import { RcListItemSecondaryAction } from '@ringcentral/juno';
import { ArrowDown2 } from '@ringcentral/juno-icon';
import { observer } from 'mobx-react';
import { useLocation, useParams } from 'react-router-dom';
import {
  ContentWrap,
  MenuTrigger,
  MenuTriggerIcon,
  MenuTriggerText,
  PopoverContainer,
} from './dropDownMenu.sc';

const triggerGeneratorFactory = (
  currentPageName: string,
): PopoverTriggerGenerator => {
  return (showPopover, open) => {
    return (
      <MenuTrigger
        color="neutral.f07"
        button
        canHover
        singleLine
        size="medium"
        onClick={showPopover}
        data-test-automation-id="menu-trigger"
      >
        <MenuTriggerText
          primary={open ? 'Browse App Gallery' : currentPageName}
          primaryTypographyProps={{ color: 'textSecondary' }}
        />
        <RcListItemSecondaryAction data-test-automation-id="rc-list-item-secondary-action">
          <MenuTriggerIcon symbol={ArrowDown2} size="large" />
        </RcListItemSecondaryAction>
      </MenuTrigger>
    );
  };
};

export const DropDownMenu = observer(() => {
  const { pagesStore } = useStores();
  const { vanityUrl } = useParams<{ vanityUrl: string }>();
  const location = useLocation();
  const [pageName, setPageName] = useState('');

  useEffect(() => {
    if (!vanityUrl) {
      const firstPageName = pagesStore.firstPage?.name;
      setPageName(firstPageName || '');
      return;
    }

    const currentPage = pagesStore.vanityUrlToPage[vanityUrl];

    if (currentPage) {
      setPageName(currentPage.name);
    }
  }, [pagesStore.vanityUrlToPage, vanityUrl]);

  const triggerGenerator = triggerGeneratorFactory(pageName);

  const popoverControlRef = useRef<PopoverExternalControl>({
    hidePopover: () => {},
  });

  useEffect(() => {
    popoverControlRef.current.hidePopover();
  }, [location]);

  return (
    <PopoverContainer
      getTrigger={triggerGenerator}
      initExternalControl={(control) => (popoverControlRef.current = control)}
      expandPopover
      data-test-automation-id="dropdown-popover-container"
    >
      <ContentWrap>
        <SecondaryNavigation data-test-automation-id="dropdown-secondary-nav" />
      </ContentWrap>
    </PopoverContainer>
  );
});
