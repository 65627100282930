import React, { useMemo } from 'react';
import externalLink from '@apw/assets/images/external-link.svg';
import defaultImage from '@apw/assets/images/feature-card-placeholder.png';
import { Rating } from '@apw/components/ratingAndReview/rating/Rating';
import { getCompatibilityPagePath } from '@apw/routes/getCompatibilityPaths';
import { useCompatibilityProfilePath } from '@apw/routes/useCompatibilityPaths';
import {
  addUtmTrackingParams,
  UtmTrackingParam,
} from '@apw/shared/utmTracking';
import { useStores } from '@apw/stores';
import { MultipleLineEllipsis } from '@drw/lib';
import {
  FeatureContainerByATag,
  Mask,
  Container,
  Sponsored,
  ImageContainer,
  FeatureImage,
  Content,
  FeatureTitleContainer,
  FeatureTitle,
  ExternalLink,
  FeatureFrontText,
  FeatureContainerByLink,
  RatingContainer,
} from '../featureBlock.sc';
import {
  FeatureOptionEnum,
  FeatureTypeEnum,
  IFeatureItem,
} from './typings/feature-item';

const utmTrackingParams = {
  [UtmTrackingParam.SOURCE]: 'app-gallery',
  [UtmTrackingParam.CAMPAIGN]: 'featured',
};

const FeatureBlockItem = ({ item }: { item: IFeatureItem }) => {
  const { pagesStore, compatibilitiesStore } = useStores();
  const profilePath = useCompatibilityProfilePath(
    item.profile?.vanityUrlApplicationName!,
  );

  const featureMap = useMemo(() => {
    const getPagePath = (item: IFeatureItem): string => {
      const page = item.page;
      if (!page) return '';
      const compatibility = compatibilitiesStore.getCompatibilityById(
        page.brandId,
      );
      if (!compatibility) return '';
      const path = getCompatibilityPagePath(compatibility.slug, page.vanityUrl);
      return addUtmTrackingParams(path, utmTrackingParams);
    };

    return {
      [FeatureTypeEnum.OtherLink]: (item: IFeatureItem) => ({
        Tag: FeatureContainerByATag,
        path: item.otherLink,
        target: '_blank',
      }),
      [FeatureTypeEnum.IndividualApp]: () => ({
        Tag: FeatureContainerByLink,
        path: addUtmTrackingParams(profilePath, utmTrackingParams),
        target: '_self',
      }),
      [FeatureTypeEnum.Page]: (item: IFeatureItem) => ({
        Tag: FeatureContainerByLink,
        path: getPagePath(item),
        target: '_self',
      }),
    };
  }, [pagesStore.pageIdToPage]);

  const backgroundImage = useMemo(() => {
    if (item.imageOption === FeatureOptionEnum.Custom) {
      return item.image;
    }
    if (item.featureType === FeatureTypeEnum.IndividualApp) {
      return item.profile?.application.iconUri || defaultImage;
    }
    if (item.featureType === FeatureTypeEnum.Page) {
      return item.page?.imageUrl || defaultImage;
    }
    return defaultImage;
  }, [item]);

  const backgroundSize = useMemo(() => {
    return item.featureType === FeatureTypeEnum.IndividualApp &&
      item.imageOption === FeatureOptionEnum.Default
      ? 'contain'
      : 'cover';
  }, [item]);

  const backgroundColor = useMemo(() => {
    return item.featureType === FeatureTypeEnum.IndividualApp &&
      item.imageOption === FeatureOptionEnum.Default
      ? item.color
      : 'transparent';
  }, [item]);

  const imgAlt = useMemo(() => {
    if (item.featureType === FeatureTypeEnum.IndividualApp) {
      return `${item.profile?.application?.displayName} app logo`;
    }
    return `${item.title} image`;
  }, [item]);

  const featureContainer = useMemo(
    () => featureMap[item.featureType](item),
    [featureMap, item],
  );

  const ratingInfo = useMemo(() => {
    const appRating = item.profile?.application?.appRating;
    if (appRating) {
      return {
        overallRating: appRating.overallRating,
        voteCount: appRating.voteCount,
      };
    }
    return null;
  }, [item.profile]);

  return (
    // @ts-ignore
    <featureContainer.Tag
      href={featureContainer.path}
      target={featureContainer.target}
      // @ts-ignore
      to={featureContainer.path}
    >
      <Mask />
      <Container data-test-automation-id={'feature-block-item-container'}>
        {item.sponsored && <Sponsored>Sponsored</Sponsored>}
        <ImageContainer>
          <FeatureImage
            data-test-automation-id="featureItemImage"
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize,
              backgroundColor,
            }}
            role="img"
            aria-label={imgAlt}
          />
        </ImageContainer>
        <Content>
          <FeatureTitleContainer>
            <FeatureTitle
              data-test-automation-id="feature-item-title"
              title={item.title}
            >
              {item.title}
            </FeatureTitle>
            {item.featureType === FeatureTypeEnum.OtherLink && (
              <ExternalLink
                symbol={externalLink}
                data-test-automation-id="feature-item-external-link"
              />
            )}
          </FeatureTitleContainer>
          <FeatureFrontText
            title={item.frontText}
            data-test-automation-id="feature-item-front-text"
          >
            <MultipleLineEllipsis linesToDisplay={2} lineHeightPx={24}>
              {item.frontText}
            </MultipleLineEllipsis>
          </FeatureFrontText>
          {item.profile?.application?.appRatingEnabled && ratingInfo && (
            <RatingContainer data-test-automation-id="feature-item-rating-wrapper">
              <Rating
                rating={ratingInfo.overallRating}
                ratingCount={ratingInfo.voteCount}
              />
            </RatingContainer>
          )}
        </Content>
      </Container>
    </featureContainer.Tag>
  );
};

export { FeatureBlockItem };
