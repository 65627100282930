import { onMobile, useProximaNovaSemibold } from '@apw/core/sc';
import { RcButton, RcIcon, styled } from '@ringcentral/juno';

export const Container = styled.div`
  display: none;

  ${onMobile} {
    display: block;
  }
`;

export const RcLogoLink = styled.a`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  text-decoration: none;
  cursor: pointer;
  position: fixed;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: #fff;
  padding: 0 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

export const RcLogoImage = styled(RcIcon)`
  svg {
    width: 200px;
    height: 100%;
  }
`;

export const Actions = styled.div`
  margin-top: 60px;
  line-height: 60px;
  height: 60px;
  padding-left: 12px;
`;

export const BackButton = styled(RcButton)`
  &&& {
    ${useProximaNovaSemibold};
    font-size: 16px;
  }
`;
