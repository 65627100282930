import React, { ReactElement } from 'react';
import { isMobile } from '@apw/core/osDetector';
import {
  allProductsItem,
  primaryProducts,
  secondaryProducts,
} from '@apw/modules/search/filterBar';
import { useCompatibilitySearchPath } from '@apw/routes/useCompatibilityPaths';
import { useStores } from '@apw/stores';
import { IProduct, IProductId } from '@apw/types/product';
import { RcMenuList } from '@ringcentral/juno';
import { useHistory } from 'react-router-dom';
import { ProductItem, Separator } from './ProductContent.sc';

const ProductList = (props: {
  productItems: IProduct[];
  selectedProductId: IProductId;
  onChange: (productId: IProductId) => void;
}) => {
  const { productItems, selectedProductId, onChange } = props;
  const isItemSelected = (productId: IProductId) => {
    return productId === selectedProductId;
  };
  return (
    <>
      {productItems.map((product) => (
        <ProductItem
          data-test-automation-id={`product-item-${product.id}`}
          key={product.id}
          value={product.id}
          className={`${
            isItemSelected(product.id) ? 'selectProductItem' : ''
          } ${isMobile() && 'mobileDevice'}`}
          onClick={() => onChange(product.id)}
          tabIndex={0}
        >
          {product.displayName}
        </ProductItem>
      ))}
    </>
  );
};

export const ProductContent = (props: {
  onChange: () => void;
}): ReactElement => {
  const { onChange } = props;
  const { searchStore } = useStores();
  const pathSearch = useCompatibilitySearchPath();
  const history = useHistory();

  const onProductChange = (productId: IProductId) => {
    searchStore.setSelectedProductId(productId);
    history.push(`${pathSearch}${searchStore.getQueryString()}`);
    if (onChange) {
      onChange();
    }
  };

  return (
    <RcMenuList aria-label="product filter options">
      <ProductList
        data-test-automation-id="primary-product-list"
        productItems={[allProductsItem, ...primaryProducts]}
        selectedProductId={searchStore.selectedProductId}
        onChange={onProductChange}
      />
      <Separator />
      <ProductList
        data-test-automation-id="secondary-product-list"
        productItems={secondaryProducts}
        selectedProductId={searchStore.selectedProductId}
        onChange={onProductChange}
      />
    </RcMenuList>
  );
};
