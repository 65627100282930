export enum Compatibility {
  RINGCENTRAL = '1210',
  ATT = '3420',
  AVAYA = '6010',
  BT = '7710',
  ALCATEL = '2110',
  VERIZON = '2210',
  TELUS = '7310',
  ATOS = '2020',
  VODAFONE = '7010',
  ECOTEL = '4210',
  EASTLINK = '4610',
  MCM = '4810',
  VERSATEL = '4710',
  FRONTIER = '4910',
  TELEKOM_DT = '2030',
  UNIFY_OFFICE_DT_ATOS = '2040',
  SUNRISE = '2050',
}
