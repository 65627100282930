import React, { useEffect, useState } from 'react';
import { JumpToUnread } from '@ringcentral/juno-icon';
import { BackToTop as Back, Icon } from './BackToTop.sc';

export interface IBackToTop {
  onClick?: () => void;
}

export function BackToTop(props: IBackToTop) {
  const { onClick } = props;
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('scroll', windowScrollHandler);
    return () => {
      window.removeEventListener('scroll', windowScrollHandler);
    };
  }, []);

  const windowScrollHandler = () => {
    setVisible(window.pageYOffset > window.innerHeight * 4);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    if (onClick) {
      onClick();
    }
  };

  return (
    <Back
      className={`${visible && 'visible'}`}
      data-test-automation-id="go-back-button"
    >
      <Icon
        symbol={JumpToUnread}
        variant="round"
        size="xlarge"
        onClick={scrollToTop}
        aria-label="back to top"
      />
    </Back>
  );
}
