import { get } from '@apw/core/api/api.service';
import {
  DetectableOS,
  getParsedOS,
  isDesktop,
  isMobile,
  isUndetected,
} from '@apw/core/osDetector';
import { OsInBEEnum } from '@apw/modules/download/constants';
import { DownloadTargetEnum, IProfile } from '@apw/types';
import { IParams } from './types/params.interface';

const OSParamValueMapping = {
  [DetectableOS.Windows]: [OsInBEEnum.WINDOWS],
  [DetectableOS.macOS]: [OsInBEEnum.MAC],
  [DetectableOS.iOS]: [OsInBEEnum.IOS],
  [DetectableOS.Android]: [OsInBEEnum.ANDROID],
  desktop: [OsInBEEnum.MAC, OsInBEEnum.WINDOWS],
  mobile: [OsInBEEnum.IOS, OsInBEEnum.ANDROID],
  all: [],
};

export const getOSParamsFromTarget = (target: string) => {
  const params: Pick<IParams, 'target' | 'osList'> = {
    target: undefined,
    osList: [],
  };
  const osName = getParsedOS().name;
  let osParamsValueKey;
  const targetValue = target?.toLowerCase();
  switch (targetValue) {
    case DownloadTargetEnum.DESKTOP: {
      params.target = targetValue;
      osParamsValueKey = osName && isDesktop() ? osName : 'desktop';
      break;
    }
    case DownloadTargetEnum.MOBILE: {
      params.target = targetValue;
      osParamsValueKey = osName && isMobile() ? osName : 'mobile';
      break;
    }
    default:
      osParamsValueKey = osName && !isUndetected() ? osName : 'all';
      break;
  }
  params.osList = OSParamValueMapping[osParamsValueKey];
  return params;
};

export const normalizeQueryParams = ({
  brandId,
  app,
  target,
  auto_download,
}) => {
  const params: IParams = {
    brandId,
    app: app?.toLowerCase(),
    ...getOSParamsFromTarget(target),
    auto_download: 'true',
  };
  if (auto_download?.toLowerCase() === 'false') {
    params.auto_download = 'false';
  }
  return params;
};

export const fetchProfilesFromBE = (
  brandId: string,
  app?: string,
  osList?: string[],
): Promise<IProfile[]> => {
  let endpoint = '/api/app/download-url';
  const params: { [key: string]: string } = { brandId };
  if (app) {
    params.appCategory = app;
  }
  if (osList && osList.length) {
    const osParam = osList.reduce(
      (prev, currOS, index) =>
        `${prev}os=${currOS}${index === osList.length - 1 ? '' : '&'}`,
      '',
    );
    endpoint = `${endpoint}?${osParam}`;
  }
  return get<any>(endpoint, {
    params,
  }).then((res) => res.records);
};
