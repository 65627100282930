import React from 'react';
import { useInformDialog } from '@apw/hooks/useInformDialog';

export const useCommonError = () => {
  const informDialog = useInformDialog();

  return {
    show: () => {
      informDialog.show({
        title: 'Sorry!',
        content: (
          <div className="commonErrorMessage">
            Sorry, your request cannot be submitted at this moment. Please try
            again later. If the problem still exists, please contact{' '}
            <a
              href="http://success.ringcentral.com/RCContactSupp"
              target="_blank"
              rel="noreferrer"
            >
              Customer Support
            </a>
            .
          </div>
        ),
      });
    },
  };
};
