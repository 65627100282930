import React, { FC, useEffect } from 'react';
import { SEO, Sticky } from '@apw/components';
import Carousel from '@apw/components/carousel/Carousel';
import { useBrandBannerTexts } from '@apw/hooks';
import { BotList } from '@apw/modules/myApps/botList';
import { UserApps } from '@apw/modules/myApps/userApps';
import { Menu } from '@apw/shared/menu';
import { withLayout } from '@apw/shared/withLayout';
import { useStores } from '@apw/stores';
import { observer } from 'mobx-react';
import { HeroContainer, Wrapper } from './MyApps.sc';

const MyAppsPure: FC<{ headerHeight?: number }> = ({ headerHeight }) => {
  const { userStore, myAppsStore } = useStores();
  const { compatibilityStore } = useStores();
  const imagesAlt = useBrandBannerTexts();
  const { isUserAppsVisible, serviceWebHome } = myAppsStore;

  useEffect(() => {
    if (!userStore.isLoggedIn) {
      userStore.signInViaCLW();
      return;
    }

    myAppsStore.init();
    return () => myAppsStore.dispose();
  }, []);

  return (
    <React.Fragment>
      <SEO title={'Installed apps'} />
      {compatibilityStore.compatibility.headerBanners.length > 0 && (
        <HeroContainer>
          <Carousel
            images={compatibilityStore.compatibility.headerBanners}
            imagesAlt={imagesAlt}
          />
        </HeroContainer>
      )}
      <Sticky position="top" offset={headerHeight}>
        <Menu />
      </Sticky>
      <Wrapper data-test-automation-id="myAppsPage">
        {isUserAppsVisible && <UserApps url={serviceWebHome} />}
        <BotList />
      </Wrapper>
    </React.Fragment>
  );
};

export const MyApps = withLayout(observer(MyAppsPure), {
  stickyHeader: true,
});
