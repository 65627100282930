import { IPageContent } from '@apw/lib/types/page/pageContent.interface';
import { IProfileSimpleModel } from '@apw/lib/types/profile/profileSimpleModel.interface';

export interface IFeatureBase {
  featureType: IFeatureType;
  imageOption: FeatureOptionEnum;
  sponsored: boolean;
  image: string;
  title: string;
  frontText: string;
}

export interface IFeatureApp extends IFeatureBase {
  featureType: FeatureTypeEnum.App;
  appId: string;
  color: string;
  titleOption: FeatureOptionEnum;
  frontTextOption: FeatureOptionEnum;
  profile?: IProfileSimpleModel;
}

export interface IFeaturePage extends IFeatureBase {
  featureType: FeatureTypeEnum.Page;
  pageId: string;
  page?: Pick<
    IPageContent,
    'brandId' | 'description' | 'imageUrl' | 'name' | 'pageId' | 'vanityUrl'
  >;
}

export interface IFeatureLink extends IFeatureBase {
  featureType: FeatureTypeEnum.Link;
  otherLink: string;
}

export enum FeatureOptionEnum {
  Default = 'default',
  Custom = 'custom',
}

export type IFeatureType = 'Page' | 'Individual App' | 'Other Link';

export enum FeatureTypeEnum {
  Page = 'Page',
  App = 'Individual App',
  Link = 'Other Link',
}
