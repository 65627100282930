import { v4 as uuid } from 'uuid';
import { AppResourceLink, ILink, Profile } from '../../types';
import { categoryConfig } from '../../types/category';
import { productConfig } from '../../types/product';
import { HelpfulLinksEnum, ProfileWithCustomProps } from './types';

export const profileProcessor = (profile: Profile): ProfileWithCustomProps => {
  const model: ProfileWithCustomProps = { ...profile };

  model.categoryTags = handleCategoryTags(model.category);
  model.productTags = handleProductTags(model.product);

  model.helpfulLinks = handleHelpfulLinks(model);
  model.processResourceLinks = handleResourceLinks(model.resourcesLinks);

  return model;
};

const handleHelpfulLinks = (profile: ProfileWithCustomProps): ILink[] => {
  if (!profile) {
    return [];
  }

  const helpfulLinks = [
    getHelpfulLinkItem(
      profile.termsOfService,
      HelpfulLinksEnum.TERMS_OF_SERVICE,
    ),
    getHelpfulLinkItem(profile.privacyPolicy, HelpfulLinksEnum.PRIVACY_POLICY),
    getHelpfulLinkItem(
      profile.documentationLink,
      HelpfulLinksEnum.DOCUMENTATION,
    ),
    getHelpfulLinkItem(
      profile.publisherLink,
      HelpfulLinksEnum.PUBLISHER_WEBSITE,
    ),
    getHelpfulLinkItem(profile.supporterLink, HelpfulLinksEnum.SUPPORT_WEBSITE),
  ];

  return helpfulLinks.filter(Boolean) as ILink[];
};

const getHelpfulLinkItem = (link: string | undefined, linkName: string) => {
  if (!link || !link.trim()) {
    return;
  }
  return {
    key: uuid(),
    label: linkName,
    link,
  };
};

const handleCategoryTags = (category: string): ILink[] => {
  if (!category || !category.trim()) {
    return [];
  }
  return category.split(';').map((item) => {
    const categoryItem = handleCategoryItem(item);
    return {
      label: categoryItem.displayName,
      key: uuid(),
      link: '',
    };
  });
};

const handleProductTags = (product: string[]): ILink[] => {
  if (!product || !product.length) {
    return [];
  }
  return product.map((item) => {
    const productItem = handleSpecialProductItem(item);
    return {
      label: productItem,
      key: uuid(),
      link: '',
    };
  });
};

const handleSpecialProductItem = (product) => {
  const productItem = productConfig[product];
  return productItem ? productItem.displayName : product;
};

const handleCategoryItem = (category): { id: string; displayName: string } => {
  const item = categoryConfig[category];
  return item ? item : { id: category, displayName: category };
};

const handleResourceLinks = (resourceLinks: AppResourceLink[]) => {
  if (!resourceLinks || !resourceLinks.length) {
    return [];
  }
  return resourceLinks.map((item) => {
    return {
      label: item.label,
      link: item.link,
      key: uuid(),
    };
  });
};
