import { styled } from '@ringcentral/juno';

export const DEFAULT_ICON_SIZE = 24;
export const SPECIAL_ICON_SIZE = DEFAULT_ICON_SIZE * 1.5;

export const GalleryIconContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${DEFAULT_ICON_SIZE}px;
  height: ${DEFAULT_ICON_SIZE}px;

  img {
    width: 100%;
  }
  .ringcentral,
  .office-at-hand,
  .bt-cloud-work,
  .telus-business-connect,
  .atos-unify-office {
    width: ${DEFAULT_ICON_SIZE}px;
    height: ${DEFAULT_ICON_SIZE}px;
  }

  .avaya-cloud-office,
  .atos-unify-office {
    width: ${SPECIAL_ICON_SIZE}px;
    height: ${SPECIAL_ICON_SIZE}px;
  }
`;
