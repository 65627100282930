export enum SitemapProperty {
  ENTITY_TYPE = 'sitemapagentitytype',
  LOGO_URL = 'sitemapaglogourl',
  SHORT_DESC = 'sitemapagshortdesc',
  PROFILE_TYPE = 'sitemapagprofiletype',
  PRODUCT = 'sitemapagproduct',
  CATEGORY = 'sitemapagcategory',
  PAGE_NAME = 'sitemapagpagename',
  BRAND = 'sitemapagbrand',
  PARTNER_BADGE = 'sitemapagpartnerbadge',
  PROFILE_ID = 'sitemapagprofileid',
  PAGE_ID = 'sitemapagpageid',
  PUBLISHER = 'sitemapagpublisher',
  APP_DISPLAY_NAME = 'sitemapagdisplayname',
  APP_ICON_URL = 'sitemapagiconurl',
  CERTIFIED_BADGE = 'sitemapagcertifiedbadge',
  OVERALL_RATING = 'sitemapagoverallrating',
  VOTE_COUNT = 'sitemapagvotecount',
}
