import React, { FC, useContext, useMemo, useRef } from 'react';
import { RcTypography } from '@ringcentral/juno';
import { PageContentContext } from '@apw/lib/components/pageContent';
import { useLinksInHtml } from '@apw/lib/components/pageContent/shared';
import { IHeaderBlock } from '@apw/lib/types';
import styles from './headerBlock.module.scss';

const layoutClassNameMapping = {
  textWithImageOnLeft: styles.textWithImageOnLeft,
  textWithImageOnRight: styles.textWithImageOnRight,
  textWithBackgroundImage: styles.textWithBackgroundImage,
};

interface IHeaderBlockProps {
  data: IHeaderBlock;
  isFirst: boolean;
}

export const HeaderBlock: FC<IHeaderBlockProps> = ({ data, isFirst }) => {
  const {
    title = '',
    desc = '',
    image = '',
    layout = 'textWithImageOnRight',
  } = data;

  const { styleTokens } = useContext(PageContentContext);
  const descRef = useRef<any>(null);

  useLinksInHtml(descRef);

  const layoutClassName = layoutClassNameMapping[layout];

  const contentClassName = [
    styles.content,
    layoutClassName,
    image ? '' : styles.noImage,
  ].join(' ');

  const headingA11yProps = useMemo(
    () =>
      isFirst
        ? {
            role: 'heading',
            'aria-level': 1,
          }
        : {},
    [isFirst],
  );

  const titleNode = (
    <RcTypography
      component="div"
      variant={styleTokens.headerBlock.title.variant}
      color={styleTokens.headerBlock.title.color}
      className={styles.title}
      {...headingA11yProps}
    >
      {title}
    </RcTypography>
  );

  const descNode = (
    <RcTypography
      component="div"
      variant={styleTokens.headerBlock.desc.variant}
      color={styleTokens.headerBlock.desc.color}
      className={styles.descContainer}
      ref={descRef}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: desc,
      }}
    />
  );

  const imageNode = (
    <div className={styles.imageContainer}>
      <img src={image} alt={title} />
    </div>
  );

  return (
    <div
      className={`${styles.wrapper} headerBlock`}
      data-test-automation-id="headerBlock"
      data-testid="headerBlock"
    >
      <div className={contentClassName}>
        <div className={styles.group}>
          {image && imageNode}
          {titleNode}
        </div>
        {desc && descNode}
      </div>
    </div>
  );
};
