import { useDebounce, useEventListener } from '@ringcentral/juno';
export const shouldInvokeFunc = (target, distanceFromBottom = 100) => {
    if (!target) {
        return false;
    }
    return (target.scrollTop + target.clientHeight + distanceFromBottom >=
        target.scrollHeight);
};
export const useScrollLoading = (scrollEleRef, loadFn, distanceFromBottom = 100, debounceTime = 300) => {
    const loadDebounceFn = useDebounce((e) => {
        if (!shouldInvokeFunc(scrollEleRef.current, distanceFromBottom)) {
            return;
        }
        loadFn(e);
    }, debounceTime);
    return useEventListener(scrollEleRef, 'scroll', (e) => {
        loadDebounceFn(e);
    });
};
