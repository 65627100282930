import React, { FC } from 'react';
import {
  RatingContainer,
  RatingCount,
  StyledRating,
} from '@apw/components/ratingAndReview/rating/rating.sc';

interface IRatingProps {
  rating: number;
  ratingCount?: number;
  className?: string;
}

export const Rating: FC<IRatingProps> = ({
  rating,
  ratingCount,
  className,
}) => {
  return (
    <RatingContainer className={className}>
      <StyledRating
        // @ts-ignore
        precision={0.1}
        value={rating}
        readOnly
        data-test-automation-id={'rating-stars'}
      />
      {ratingCount && (
        <RatingCount
          data-test-automation-id={'rating-count'}
          tabIndex={0}
          color="neutral.f04"
          variant="caption1"
        >
          {ratingCount} {ratingCount > 1 ? 'ratings' : 'rating'}
        </RatingCount>
      )}
    </RatingContainer>
  );
};
