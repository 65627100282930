export enum ProfileAppType {
  BOT = 'Bot',
  BROWSER_EXTENSION = 'Browser Extension',
  DESKTOP_CLIENT = 'Desktop Client',
  MOBILE_APP = 'Mobile App',
  NATIVE_INTEGRATION = 'Native Integration',
  PLUGIN = 'Plugin',
  WORKFLOW = 'Workflow',
  MESSAGING_NOTIFICATION_APP = 'Messaging Notification App',
  UNKNOWN = 'Unknown',
}
