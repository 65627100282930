import React, { FC, useEffect, useState } from 'react';
import { ICountry, getCountries } from '@apw/core/country';
import { defaultPhoneMaxlength, getPhoneMaxlength } from '@apw/modules/profile';
import { observer } from 'mobx-react';
import {
  BusinessPhoneField,
  CountrySelectorContainer,
  CountrySelectorItem,
} from './BusinessPhone.sc';

const CountrySelector: FC<{ fieldConfig: any; form: any }> = ({
  fieldConfig,
  form,
}) => {
  const [countries, setCountries] = useState([] as ICountry[]);
  useEffect(() => {
    let isMounted = true;
    getCountries().then((countries) => {
      if (isMounted) {
        setCountries(countries);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <CountrySelectorContainer
      MenuProps={{
        autoFocus: false,
      }}
      name={fieldConfig.callingCode.name}
      value={form.values.callingCode}
      onChange={form.handleChange}
      data-test-automation-id="calling-code-selector"
      renderValue={(callingCode) => <span>+{callingCode}</span>}
    >
      {countries &&
        countries.map((country) => (
          <CountrySelectorItem
            data-test-automation-id={`country-item-${country.id}`}
            value={country.callingCode}
            key={country.id}
          >
            {country.name}(+{country.callingCode})
          </CountrySelectorItem>
        ))}
    </CountrySelectorContainer>
  );
};

const BusinessPhonePure: FC<{
  fieldConfig: any;
  form: any;
  showCallingCode: boolean;
}> = ({ fieldConfig, form, showCallingCode }) => {
  const {
    values: { callingCode, phoneNumber },
    setFieldValue,
  } = form;
  const errorMsg =
    form.touched[fieldConfig.phoneNumber.name] &&
    form.errors[fieldConfig.phoneNumber.name];
  const handleChange = (event) => {
    form.setFieldValue(fieldConfig.phoneNumber.name, event.target.value);
  };

  const [maxlength, setMaxlength] = useState(defaultPhoneMaxlength);

  const updateBusinessPhone = () => {
    let businessPhone = phoneNumber;
    if (showCallingCode) {
      businessPhone = `+${callingCode}${phoneNumber}`;
    }
    setFieldValue('businessPhone', businessPhone);
  };

  useEffect(() => {
    setMaxlength(getPhoneMaxlength(callingCode));
  }, [callingCode]);

  useEffect(() => {
    updateBusinessPhone();
  }, [showCallingCode, callingCode, phoneNumber]);

  return (
    <BusinessPhoneField
      data-test-automation-id="business-phone-field"
      label={fieldConfig.phoneNumber.label}
      name={fieldConfig.phoneNumber.name}
      value={form.values.phoneNumber || ''}
      onChange={handleChange}
      helperText={
        errorMsg && (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          <div role="alert" tabIndex={0}>
            {errorMsg}
          </div>
        )
      }
      error={
        !!(
          form.touched[fieldConfig.phoneNumber.name] &&
          form.errors[fieldConfig.phoneNumber.name]
        )
      }
      autoComplete="off"
      InputProps={{
        startAdornment: showCallingCode ? (
          <CountrySelector fieldConfig={fieldConfig} form={form} />
        ) : null,
        inputProps: {
          maxLength: maxlength,
          'aria-label': fieldConfig.phoneNumber.label,
        },
      }}
    />
  );
};

export const BusinessPhone = observer(BusinessPhonePure);
