export const getCompatibilityPath = (slug: string, path = ''): string => {
  path = path.replace(/^\//, '');

  if (slug) {
    return `/${slug}/${path}`;
  }

  return `/${path}`;
};

export const getCompatibilityHomePath = (slug: string): string => {
  return getCompatibilityPath(slug, '');
};

export const getCompatibilityMyAppsPath = (slug: string): string => {
  return getCompatibilityPath(slug, 'my-apps');
};

export const getCompatibilitySearchPath = (slug: string): string => {
  return getCompatibilityPath(slug, 'search');
};

export const getCompatibilityPartnerEcosystemPath = (slug: string): string => {
  return getCompatibilityPath(slug, 'partner-ecosystem');
};

export const getCompatibilityPagePath = (
  slug: string,
  vanityUrl: string,
): string => {
  return getCompatibilityPath(slug, `/p/${vanityUrl}`);
};

export const getCompatibilityProfilePath = (
  slug: string,
  vanityUrl: string,
): string => {
  return getCompatibilityPath(slug, `${vanityUrl}`);
};

export const getCompatibilityGalleryPagePreviewPath = (
  slug: string,
  pageId: string,
): string => {
  return getCompatibilityPath(slug, `/gallery-page-preview/${pageId}`);
};
