import React, { FC } from 'react';
import { getGalleryIconNode } from '@apw/shared/navigation/compatibilitySelector/shared/getGalleryIconNode';
import { ICompatibility } from '@apw/stores/compatibility';
import { palette2, RcButton, RcIcon, styled } from '@ringcentral/juno';
import { ArrowDown, ArrowUp } from '@ringcentral/juno-icon';

const TriggerButton = styled(RcButton)`
  &&& {
    min-width: auto;
    width: auto;
    border-color: #c7c7c7;
    padding: 7px 10px;

    &.open {
      border-color: ${palette2('secondary', 'main')};
    }
  }
`;

const ArrowIcon = styled(RcIcon)`
  &&& {
    margin-left: 8px;
    margin-right: -6px;

    svg {
      color: #666;
      font-size: 24px;
    }
  }
`;

interface IPopoverTriggerProps {
  compatibility: ICompatibility;
  onClick: (e) => void;
  isPopoverOpen: boolean;
  isMobile: boolean;
}

export const PopoverTrigger: FC<IPopoverTriggerProps> = (props) => {
  const { compatibility, onClick, isPopoverOpen, isMobile } = props;
  const { galleryIcon, galleryIconType, className } = compatibility;

  return (
    <TriggerButton
      size="large"
      variant={isMobile ? 'text' : 'outlined'}
      color="neutral"
      onClick={onClick}
      className={isPopoverOpen ? 'open' : ''}
      data-test-automation-id="compatibilitySelectorPopoverTrigger"
      data-testid="compatibilitySelectorPopoverTrigger"
      aria-label={`brand selector, current brand is ${compatibility.name}`}
    >
      {getGalleryIconNode(galleryIcon, galleryIconType, className)}
      <ArrowIcon symbol={isPopoverOpen ? ArrowUp : ArrowDown} size="large" />
    </TriggerButton>
  );
};
