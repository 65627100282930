export enum Category {
  AI = 'AI',
  ANALYTICS = 'Analytics',
  AUTOMATION = 'Automation',
  BOTS_NOTIFICATIONS = 'Bots & Notifications',
  CALENDARING = 'Calendaring',
  COLLABORATION = 'Collaboration',
  CRM = 'CRM',
  CUSTOMER_SUPPORT = 'Customer Support',
  DEVELOPER_TOOLS = 'Developer Tools',
  EDUCATION = 'Education',
  ERP = 'ERP',
  FAX = 'Fax',
  FINANCIAL_SERVICES = 'Financial Services',
  HEALTHCARE = 'Healthcare',
  LABS = 'Labs',
  LEGAL = 'Legal',
  MARKETING_AUTOMATION = 'Marketing Automation',
  PRODUCTIVITY = 'Productivity',
  RETAIL = 'Retail',
  SMS = 'SMS',
  SPEECH = 'Speech',
  STORAGE = 'Storage',
}
