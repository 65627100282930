import React, { FC, useContext } from 'react';
import { ExternalLink } from '@ringcentral/juno-icon';
import { observer } from 'mobx-react';
import { ProfileDetailContext } from '../../index';
import { Link, LabelStyled, IconStyled } from './GetSupport.sc';

type GetSupportProps = {
  onClick?: () => void;
};

const GetSupportPure: FC<GetSupportProps> = (props) => {
  const store = useContext(ProfileDetailContext);
  const { onClick } = props;
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Link
      variant="body1"
      href={store.profile?.supporterLink}
      target="_blank"
      rel="noopener"
      onClick={handleClick}
      data-test-automation-id="getSupport"
    >
      <LabelStyled>{store.translation.getSupport}</LabelStyled>
      <IconStyled symbol={ExternalLink} size="small" aria-hidden="true" />
    </Link>
  );
};

export const GetSupport = observer(GetSupportPure);
