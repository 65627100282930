import React, { FC, useContext, useMemo } from 'react';
import { styled } from '@ringcentral/juno';
import { observer } from 'mobx-react';
import { PageCard } from '@apw/lib/components/pageCard';
import { StoreContext } from '@apw/lib/components/profileDetail/StoreContext';
import { IPageSimpleModel } from '@apw/lib/types';

const FEATURED_IN_CLASSNAME = 'featuredInWrapper';
const PAGE_CARD_GAP = '12px';
const ALL_APPLICATIONS = 'All applications';
const PAGE_CARD_CLASSNAME = 'pageCard';
const ONE_PER_ROW = 'onePerRow';

const Wrapper = styled.div``;

const Title = styled.div`
  margin: 0 0 20px;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .${PAGE_CARD_CLASSNAME} {
    width: calc(50% - ${PAGE_CARD_GAP} / 2);
    margin-bottom: ${PAGE_CARD_GAP};
  }

  &.${ONE_PER_ROW} {
    .${PAGE_CARD_CLASSNAME} {
      width: 100%;
    }
  }
`;

interface IFeaturedInProps {
  pages: IPageSimpleModel[];
  className?: string;
}

export const FeaturedIn: FC<IFeaturedInProps> = observer(
  ({ pages, className = '' }) => {
    const {
      translation,
      pagePathBuilder,
      isOneColumnLayout,
      isTwoColumnLayout,
    } = useContext(StoreContext);

    const items = useMemo(() => {
      return (pages || [])
        .filter((page) => Boolean(page.imageUrl))
        .filter((page) => page.name !== ALL_APPLICATIONS);
    }, [pages]);

    if (items.length === 0) {
      return null;
    }

    const showOnePerRow = isOneColumnLayout || isTwoColumnLayout;

    return (
      <Wrapper className={`${className} ${FEATURED_IN_CLASSNAME}`}>
        <Title className="featuredInTitle">{translation.featuredIn}</Title>
        <Content className={showOnePerRow ? ONE_PER_ROW : ''}>
          {items.map((page) => (
            <PageCard
              key={page.pageId}
              className={PAGE_CARD_CLASSNAME}
              page={page}
              pagePathBuilder={pagePathBuilder}
            />
          ))}
        </Content>
      </Wrapper>
    );
  },
);
