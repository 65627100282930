import { PrimaryButton } from '@apw/components/button';
import {
  ctaButtonSizeOnMobile,
  ctaButtonTextOnMobile,
} from '@apw/components/profileDetail/profileHeader/ctaButton/ctaButton.sc';
import { styled } from '@ringcentral/juno';

export const MnaButton = styled(PrimaryButton)`
  ${ctaButtonSizeOnMobile};

  [class~='MuiButton-label'] {
    ${ctaButtonTextOnMobile};
  }
`;
