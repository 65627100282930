import React, { FC, ReactNode, useCallback, useEffect } from 'react';
import { domService } from '@apw/components/dom/dom.service';
import { RcLoading, styled } from '@ringcentral/juno';
import * as _ from 'lodash';

interface InfiniteScrollProps {
  distanceFromBottom?: number;
  enabled: boolean;
  onScroll: () => void;
  children: ReactNode;
}

const LoadingWrapper = styled.div`
  position: relative;
  height: 64px;
  margin-bottom: 64px;
`;

const shouldInvokeFunc = (distanceFromBottom: number): boolean => {
  return (
    domService.getPageScrollXY().y + window.innerHeight + distanceFromBottom >=
    domService.getDocumentHeight()
  );
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const enableScroll = (enable = true, scrollHandler): void => {
  if (enable) {
    window.addEventListener('scroll', scrollHandler);
  } else {
    window.removeEventListener('scroll', scrollHandler);
  }
};

export const InfiniteScroll: FC<InfiniteScrollProps> = ({
  distanceFromBottom = 150,
  enabled,
  onScroll,
  children,
}) => {
  const scrollHandler = useCallback(
    _.throttle(() => {
      if (shouldInvokeFunc(distanceFromBottom)) {
        onScroll();
      }
    }, 500),
    [distanceFromBottom, onScroll],
  );

  useEffect(() => {
    enableScroll(enabled, scrollHandler);
    return () => {
      enableScroll(false, scrollHandler);
    };
  });

  return (
    <>
      {children}
      {enabled && (
        <LoadingWrapper>
          <RcLoading loading>
            <span />
          </RcLoading>
        </LoadingWrapper>
      )}
    </>
  );
};
