import React, { ReactElement, useRef } from 'react';
import { PopoverExternalControl } from '@apw/core/popover/typings';
import { CategoryContent } from '@apw/modules/search/filterBar/share/CategoryContent';
import { ProductContent } from '@apw/modules/search/filterBar/share/ProductContent';
import {
  RcIconButton,
  RcListItemSecondaryAction,
  RcListItemText,
} from '@ringcentral/juno';
import { ArrowDown2 } from '@ringcentral/juno-icon';
import { observer } from 'mobx-react';
import { Divider, FilterContainer, MenuTrigger } from './FilterMobileBar.sc';

const FilterTrigger = () => {
  return (showPopover: (event) => void): ReactElement => {
    return (
      <MenuTrigger color="neutral.f07" size="small" onClick={showPopover}>
        <RcListItemText data-test-automation-id="filter-mobile-bar-title">
          FILTER OPTIONS
        </RcListItemText>
        <RcListItemSecondaryAction>
          <RcIconButton
            data-test-automation-id="filter-mobile-bar-icon-button"
            symbol={ArrowDown2}
            size="large"
            tabIndex={-1}
            aria-hidden="true"
          />
        </RcListItemSecondaryAction>
      </MenuTrigger>
    );
  };
};

export const FilterMobileBar = observer(() => {
  const popoverControlRef = useRef<PopoverExternalControl>({
    hidePopover: () => {},
  });

  const hidePopover = () => {
    popoverControlRef.current.hidePopover();
  };

  return (
    <FilterContainer
      data-test-automation-id="filter-mobile-container"
      getTrigger={FilterTrigger()}
      initExternalControl={(control) => (popoverControlRef.current = control)}
      expandPopover
    >
      <div className={'contentWrap'}>
        <ProductContent onChange={hidePopover} />
        <Divider />
        <CategoryContent onChange={hidePopover} />
      </div>
    </FilterContainer>
  );
});
