import { rootStore } from '@apw/stores';

export const buildUrl = (appId: string, brandId: string): string | null => {
  const baseUrl = resolveBaseUrl(brandId);
  return baseUrl ? `${baseUrl}/l/apps/discover?appid=${appId}` : null;
};

const resolveBaseUrl = (brandId: string): string | null => {
  const compatibility =
    rootStore.compatibilitiesStore.getCompatibilityById(brandId);

  if (compatibility) {
    return compatibility.jupiterWebUrl ? compatibility.jupiterWebUrl : null;
  }

  return null;
};
