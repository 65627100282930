import { onMobile, useProximaNovaSemibold } from '@apw/core/sc';
import { RcButton, styled } from '@ringcentral/juno';

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 24px;

  & > button {
    display: block;
    height: 40px;
    width: 100%;

    ${onMobile} {
      height: 48px;
      font-size: 20px !important;
      ${useProximaNovaSemibold}
    }
  }
`;

export const SignInToProceedBtn = styled(RcButton)`
  ${useProximaNovaSemibold};
`;

export const GetFreeTrialBtn = styled(RcButton)`
  margin-top: 8px !important;
`;

export const GetFreeTrialText = styled.span`
  a {
    font-size: 15px !important;
    font-weight: 700 !important;
    text-decoration: none !important;
    ${useProximaNovaSemibold} !important;

    ${onMobile} {
      height: 48px !important;
      font-size: 20px !important;
    }
  }
`;
