import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Container } from '@apw/components/slider/sliderEnhance.sc';
import { throttle } from 'lodash';
import { Slider } from './Slider';
import { Breakpoint, Breakpoints, SliderRefMethods } from './slider.interface';

interface Props {
  breakpoints?: Breakpoints;
  keyboardEnable?: boolean;
  dragEnable?: boolean;
  initialSlideIndex?: number;
  initialPageIndex?: number;
  onClick?: Function;
  onChangePage?: Function;
  children?: React.ReactNode[];
}

const SliderEnhance = React.forwardRef<SliderRefMethods, Props>(
  ({ breakpoints, keyboardEnable = false, ...props }, ref) => {
    const points = useMemo<Breakpoints>(
      () => ({
        0: { size: 1, gap: 0, useArrow: false, useIndicator: false },
        ...breakpoints,
      }),
      [breakpoints],
    );

    useImperativeHandle(ref, () => {
      const ref = sliderRef.current as SliderRefMethods;
      return {
        nextPage: ref.nextPage,
        backPage: ref.backPage,
        goPage: ref.goPage,
      };
    });

    const sliderRef = useRef<SliderRefMethods>(null);
    const pointsKeys = useMemo<string[]>(() => Object.keys(points), [points]);
    const [point, setPoint] = useState(points[0]);

    useEffect(() => {
      const handleKeyboard = (event) => {
        const keyMap = {
          ArrowLeft: sliderRef.current?.backPage,
          ArrowRight: sliderRef.current?.nextPage,
        };
        const executor = keyMap[event.key];
        if (executor) executor();
      };
      if (keyboardEnable) document.addEventListener('keydown', handleKeyboard);
      return () => {
        document.removeEventListener('keydown', handleKeyboard);
      };
    }, []);

    useEffect(() => {
      onResize();
      window.addEventListener('resize', onResize);
      return () => {
        window.removeEventListener('resize', onResize);
      };
    }, []);

    const onResize = throttle(() => {
      const width = window.innerWidth;
      const point = computedBreakpoint(width);
      setPoint(point);
    }, 200);

    const computedBreakpoint = (width: number): Breakpoint => {
      let latestLessThanWidthKey = 0;
      for (const key of pointsKeys) {
        const k = Number(key);
        if (k <= width) latestLessThanWidthKey = k;
        else break;
      }
      return points[latestLessThanWidthKey];
    };

    return (
      <Container data-test-automation-id={`slider-enhance-container`}>
        <Slider
          ref={sliderRef}
          size={point.size}
          gap={point.gap}
          useArrow={!!point.useArrow}
          useIndicator={!!point.useIndicator}
          {...props}
        />
      </Container>
    );
  },
);

export { SliderEnhance };
