import React, { useContext, useState } from 'react';
import { ProfileViewModelContext } from '@apw/components/profileDetail/Profile.ViewModel.context';
import {
  ActionButtonsContainer,
  Divider,
  NoRatingsWrapper,
  StyledNoRatingsSection,
} from '@apw/components/profileDetail/profileBody/ratingsAndReviewsTab/RatingsAndReviewsTab.sc';
import { RatingsSection } from '@apw/components/profileDetail/profileBody/ratingsAndReviewsTab/ratingsSection/RatingsSection';
import { ReviewsSection } from '@apw/components/profileDetail/profileBody/ratingsAndReviewsTab/reviewsSection/ReviewsSection';
import { ActionButtons } from '@apw/components/ratingAndReview/actionButtons/ActionButtons';
import { useIsMobileDevice } from '@apw/hooks/deviceWidth/useIsMobileDevice';
import { RcSnackbar } from '@ringcentral/juno';
import { observer } from 'mobx-react';

export const RatingsAndReviewsTab = observer(() => {
  const profileVM = useContext(ProfileViewModelContext);
  const isMobileDevice = useIsMobileDevice();
  const [snackBarOpen, setSnackbarOpen] = useState(false);
  const profile = profileVM.profile;

  if (!profile) {
    return null;
  }

  const onReviewItemDeleted = () => {
    setSnackbarOpen(true);
  };

  const reviewItemDeletedSnackbar = (
    <RcSnackbar
      open={snackBarOpen}
      autoHideDuration={5000}
      onClose={() => setSnackbarOpen(false)}
      message="Rating and review deleted"
      data-test-automation-id="review-item-deleted-snackbar"
    />
  );

  const getTabContent = () => {
    if (!profile.appRating && profileVM.totalNumberForReviews === 0) {
      return (
        <NoRatingsWrapper>
          <StyledNoRatingsSection
            variant={isMobileDevice ? 'subheading1' : 'headline1'}
          />
          <ActionButtonsContainer>
            <ActionButtons fullWidth={isMobileDevice} />
          </ActionButtonsContainer>
        </NoRatingsWrapper>
      );
    }

    return (
      <>
        {profile.appRating && (
          <>
            <RatingsSection ratings={profile.appRating} />
            <Divider />
          </>
        )}
        <ReviewsSection onItemDeleted={onReviewItemDeleted} />
      </>
    );
  };

  return (
    <>
      {getTabContent()}
      {snackBarOpen && reviewItemDeletedSnackbar}
    </>
  );
});
