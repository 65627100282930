import { onMobile, useProximaNovaBold } from '@apw/core/sc';
import { css, styled } from '@ringcentral/juno';

export const Container = styled.div`
  margin-top: 24px;
`;

export const ctaButtonTextOnMobile = css`
  ${onMobile} {
    ${useProximaNovaBold};
    font-size: 20px !important;
  }
`;

export const ctaButtonSizeOnMobile = css`
  ${onMobile} {
    height: 48px;
  }
`;
