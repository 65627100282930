import {
  useProximaNovaSemibold,
  onMobile,
  overflowWrap,
  useProximaNovaRegular,
  Color,
} from '@apw/core/sc';
import { styled, css } from '@ringcentral/juno';

export const Wrapper = styled.div`
  width: 100%;
  color: #001138;

  &:not(:first-child) {
    margin-top: 56px;
  }
`;

const sectionTitleStyles = css`
  margin: 0;
  font-size: 30px;
  font-weight: 300;
  ${useProximaNovaSemibold};
`;

export const SectionTitleH2 = styled.div`
  ${sectionTitleStyles};

  &.addInsTitle {
    margin-bottom: 24px;
  }
`;

export const SectionTitleDiv = styled.div`
  ${sectionTitleStyles};

  &.mnaAppsTitle,
  &.botAppsTitle {
    font-size: 22px;
    font-weight: 600;
    color: #001138;
  }
`;

export const SectionDesc = styled.div`
  margin-top: 16px;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 16px;
  color: #494949;
  ${overflowWrap('break-word')};
  ${useProximaNovaRegular};

  ${onMobile} {
    line-height: 24px;
  }

  p {
    margin: 0;
    display: inline-block;

    ${onMobile} {
      &:not(:first-child) {
        margin-top: 15px;
      }
    }

    a {
      text-decoration: none;
    }

    span {
      font-size: 16px !important;
      font-weight: 700 !important;
      ${useProximaNovaRegular};
    }
  }

  &.mnaAppsDesc {
    margin-bottom: 48px;

    ${onMobile} {
      margin-bottom: 52px;
    }
  }

  &.botAppsDesc {
    margin-bottom: 28px;
  }
`;
export const NotInstall = styled.span`
  color: rgb(106, 113, 134);
`;

export const NotBotItem = styled.div`
  background: ${Color.slighterGray};
  padding: 18px 18px 22px;
  border-radius: 8px;

  a {
    text-decoration: none;
  }

  p,
  span {
    line-height: 24px !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    ${useProximaNovaRegular};
  }
`;
