import { RcTypography, styled, palette2 } from '@ringcentral/juno';
import { OverallRating } from '@apw/lib/components/ratingAndReview/overallRating/OverallRating';

export const BasicInfo = styled.div`
  border-bottom: 1px solid ${palette2('neutral', 'l02')};
  padding-bottom: 24px;
  margin-bottom: 20px;
`;

export const ScoreWrapper = styled(RcTypography)`
  margin-top: 4px;
`;

export const StyledOverallRating = styled(OverallRating)`
  &&& {
    > div {
      margin-bottom: 8px;
    }
  }
`;
