export enum CustomDimension {
  COMPATIBILITY = 'compatibility',
  APP_TYPE = 'app_type',
  APP_NAME = 'app_name',
  APP_ID = 'app_id',
  PROFILE_NAME = 'profile_name',
  BRAND_PARTNER = 'brand_partner',
  CLICK_PATH = 'click_path',
  APP_SHORT_CODE = 'app_short_code',
  BUTTON_URL = 'button_url',
  PROFILE_ID = 'profile_id',
  BUTTON_STATE = 'button_state',
  BUTTON_TEXT = 'button_text',
  LOGGED_IN_STATE = 'logged_in_state',
  ORG_TYPE = 'org_type',
  ORG_ID = 'org_id',
}
