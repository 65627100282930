import React, { FC } from 'react';
import { RcLoading, styled } from '@ringcentral/juno';

interface GlobalLoadingProps {
  isLoading: boolean;
}

const StyledContainer = styled('div')`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

export const GlobalLoading: FC<GlobalLoadingProps> = (
  props: GlobalLoadingProps,
) => {
  const { isLoading } = props;

  return isLoading ? (
    <StyledContainer>
      <RcLoading loading={isLoading}>
        <span />
      </RcLoading>
    </StyledContainer>
  ) : null;
};
