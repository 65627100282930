export enum FontWeight {
  light = 300,
  regular = 500,
  bold = 700,
}

export enum FontColor {
  black = '#001138',
}

export enum Color {
  red = '#f95b5c',
  green = '#5fb95c',
  lighterBlue = '#e1f4fb',
  seaBlue = '#41a8da',
  lightBlue = '#0684bd',
  blue = '#0073ae',
  orange = '#ff8800',
  slighterGray = '#f9f9f9',
  slightGray = '#f2f2f2',
  lighterGray = '#e2e2e2',
  lighterGray2 = '#c7c7c7',
  lightGray = '#979797',
  lightGray2 = '#999999',
  gray = '#797979',
  darkerGary = '#a9a9a9',
  dimGray = '#666666',
  lighterBlack = '#585858',
  black = '#2f2f2f',
  darkBlack = '#303030',
  lightBlack = '#616161',
  white = '#ffffff',
}

// page
export const PAGE_MAX_WIDTH = 1296;

// page Wrapper Padding
export const PAGE_DESKTOP_PADDING = 28;
export const PAGE_MOBILE_PADDING = 20;

// page content margin
export const PAGE_DESKTOP_MARGINTOP = 56;
export const PAGE_MOBILE_MARGINTOP = 16;
