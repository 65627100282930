import React, { FC, ReactNode, useContext } from 'react';
import { lineClamp, RcIcon, styled } from '@ringcentral/juno';
import { observer } from 'mobx-react';
import { CertifiedBadge } from '@apw/lib/components/certifiedBadge';
import { ProfileDetailContext } from '@apw/lib/components/profileDetail';
import { RatingWrapper } from '@apw/lib/components/profileDetail/profileHeader/profileHeader.sc';
import { RatingInfo } from '@apw/lib/components/ratingAndReview/ratingInfo/RatingInfo';
import { useIsMobile } from '@apw/lib/hooks/useIsMobile';
import { IAppRating } from '@apw/lib/types/profile/appRating.interface';
import defaultAppLogo from '../../../assets/images/image_placeholder.svg';
import { GetSupport } from './getSupport';
import { PremierPartnerBadge } from './premierPartnerBadge';

const APP_INFO_ROW = 'appInfoRow';
const SHORT_DESCRIPTION_ROW = 'shortDescriptionRow';
const PROFILE_ACTIONS_ROW = 'profileActionsRow';
const MEDIUM_SIZE_LEFT_COLUMN_WIDTH = '168px';
const SMALL_SIZE_LEFT_COLUMN_WIDTH = '128px';

interface Props {
  model: {
    appName: string;
    appIconUri: string;
    publisher?: string;
    description?: string;
    partnerBadge?: string;
    supporterLink?: string;
    appRatingEnabled?: boolean;
    appRating?: IAppRating;
  };
  ctaButton: ReactNode;
  getSupportClick?: () => void;
  showActions?: boolean;
}

const ProfileHeaderPure: FC<Props> = (props: Props) => {
  const { ctaButton, getSupportClick, showActions = true } = props;
  const store = useContext(ProfileDetailContext);
  const isMobile = useIsMobile();
  const {
    appName,
    appIconUri,
    description,
    partnerBadge,
    supporterLink,
    publisher,
    appRatingEnabled,
    appRating,
  } = props.model;

  const isPremierPartner = partnerBadge === 'Premier';
  const shortDescription = (
    <ShortDescription
      className="shortDescription"
      title={description}
      data-test-automation-id="profileShortDesc"
    >
      {description}
    </ShortDescription>
  );

  const renderRatings = () => {
    if (appRatingEnabled && appRating) {
      return (
        <RatingWrapper
          data-test-automation-id={'profile-header-rating-wrapper'}
        >
          <RatingInfo
            rating={appRating.overallRating}
            ratingCount={appRating.voteCount}
            translation={store.translation.ratingsAndReviews.basicInfo}
          />
        </RatingWrapper>
      );
    }
    return <div />;
  };

  return (
    <Wrapper data-test-automation-id="profileHeader">
      <DisplayRow className={APP_INFO_ROW}>
        <LeftColumn>
          <AppIcon data-test-automation-id="profileAppIcon">
            {appIconUri ? (
              <img
                src={appIconUri}
                alt={store.translation.appIconLabel(appName)}
                draggable={false}
              />
            ) : (
              <RcIcon
                className="defaultAppLogo"
                symbol={defaultAppLogo}
                iconSize="extraLarge"
                aria-label={store.translation.defaultAppIconLabel}
              />
            )}
          </AppIcon>
        </LeftColumn>
        <RightColumn>
          <AppNameWrapper>
            <AppName
              className="appName"
              title={appName}
              data-test-automation-id="profileAppName"
            >
              {appName}
            </AppName>
            {store.profile?.certifiedBadge && (
              <CertifiedBadgeWrapper text={store.translation.certifiedBadge} />
            )}
          </AppNameWrapper>
          <AppMeta className="appMeta" data-test-automation-id="profileAppMeta">
            <AppPublisher title={publisher}>{publisher}</AppPublisher>
            {isPremierPartner && <PremierPartnerBadge />}
          </AppMeta>
          {isMobile && renderRatings()}
          <span>{shortDescription}</span>
          {!isMobile && renderRatings()}
        </RightColumn>
      </DisplayRow>
      <DisplayRow className={SHORT_DESCRIPTION_ROW}>
        <span>{shortDescription}</span>
      </DisplayRow>
      {showActions ? (
        <DisplayRow className={PROFILE_ACTIONS_ROW}>
          <ActionsRowLeftColumn>
            <CtaButtonWrapper>{ctaButton}</CtaButtonWrapper>
          </ActionsRowLeftColumn>
          <ActionsRowRightColumn>
            <ActionsWrapper>
              {supporterLink && <GetSupport onClick={getSupportClick} />}
            </ActionsWrapper>
          </ActionsRowRightColumn>
        </DisplayRow>
      ) : null}
    </Wrapper>
  );
};

export const ProfileHeader = observer(ProfileHeaderPure);

const DisplayRow = styled.div`
  display: flex;
`;

const LeftColumn = styled.div`
  flex-shrink: 0;
  width: ${MEDIUM_SIZE_LEFT_COLUMN_WIDTH};
`;

const RightColumn = styled.div`
  flex-grow: 1;
  margin-left: 40px;

  @media (max-width: 960px) {
    margin-left: 24px;
  }
`;

const AppIcon = styled.div`
  width: ${MEDIUM_SIZE_LEFT_COLUMN_WIDTH};
  height: ${MEDIUM_SIZE_LEFT_COLUMN_WIDTH};
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: ${MEDIUM_SIZE_LEFT_COLUMN_WIDTH};
    max-height: ${MEDIUM_SIZE_LEFT_COLUMN_WIDTH};
    border-radius: 22.3%;
  }

  .defaultAppLogo {
    svg {
      width: ${MEDIUM_SIZE_LEFT_COLUMN_WIDTH};
      height: ${MEDIUM_SIZE_LEFT_COLUMN_WIDTH};
    }
  }
`;

const CtaButtonWrapper = styled.div``;

const AppNameWrapper = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
`;

const AppName = styled.div`
  ${lineClamp(2, 80)};
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 960px) {
    ${lineClamp(3, 96)};
  }
`;

const CertifiedBadgeWrapper = styled(CertifiedBadge)`
  margin-top: 4px;
`;

const AppMeta = styled.div`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
`;

const AppPublisher = styled.span`
  ${lineClamp(2, 56)};
`;

const ShortDescription = styled.div`
  margin-top: 24px;
`;

const ActionsWrapper = styled.div`
  line-height: 36px;
`;

const Wrapper = styled.div`
  margin-bottom: 20px;

  .${SHORT_DESCRIPTION_ROW} {
    display: none;
  }

  .${APP_INFO_ROW} {
    .shortDescription {
      display: block;
      ${lineClamp(2, 56)}
    }
  }

  .${PROFILE_ACTIONS_ROW} {
    flex-wrap: wrap;
    margin-top: 24px;
  }

  @media (max-width: 960px) {
    .${SHORT_DESCRIPTION_ROW} {
      display: block;
      margin-top: 20px;

      .shortDescription {
        ${lineClamp(3, 72)}
      }
    }

    .${APP_INFO_ROW} {
      ${LeftColumn} {
        width: ${SMALL_SIZE_LEFT_COLUMN_WIDTH};

        ${AppIcon} {
          width: ${SMALL_SIZE_LEFT_COLUMN_WIDTH};
          height: ${SMALL_SIZE_LEFT_COLUMN_WIDTH};

          img {
            max-width: ${SMALL_SIZE_LEFT_COLUMN_WIDTH};
            max-height: ${SMALL_SIZE_LEFT_COLUMN_WIDTH};
          }

          .defaultAppLogo svg {
            width: ${SMALL_SIZE_LEFT_COLUMN_WIDTH};
            height: ${SMALL_SIZE_LEFT_COLUMN_WIDTH};
          }
        }
      }

      .shortDescription {
        display: none;
      }
    }

    .${PROFILE_ACTIONS_ROW} {
      margin-top: 24px;
    }
  }
`;

const ActionsRowLeftColumn = styled.div`
  flex-shrink: 0;
  width: ${MEDIUM_SIZE_LEFT_COLUMN_WIDTH};
  margin-right: 40px;

  @media (max-width: 960px) {
    margin-right: 24px;
  }
`;

const ActionsRowRightColumn = styled.div`
  flex-grow: 1;
`;
