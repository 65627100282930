import { IProcessedProfile } from '@apw/components/profileDetail';
import { transport } from '@apw/core';
import { post } from '@apw/core/api/api.service';
import { profileProcessor } from '@apw/modules/profile/shared/profileProcessor';
import { IProfile } from '@apw/types';

export const loadProfileForPreview = (
  profileId: string,
  timestamp: string,
  token: string,
  type: string,
  brandId?: string,
): Promise<IProfile> => {
  const endpoint = '/api/app/preview';
  return post<IProfile>(endpoint, {
    id: profileId,
    timestamp,
    token,
    type,
    ...(brandId && { brandId }),
  });
};

export const resolveProfileForPreview = (
  profileId: string,
  timestamp: string,
  token: string,
  type: string,
  brandId?: string,
): Promise<IProcessedProfile> => {
  return transport
    .fetchProfileForPreview(profileId, timestamp, token, type, brandId)
    .then((res) => {
      return profileProcessor(res);
    });
};
