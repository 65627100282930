import { IPageCard } from '@apw/shared/coveoSearch/shared/pageCard/page.interface';
import { IProfileCard } from '@apw/shared/coveoSearch/shared/profileCard/profileCard.interface';
import {
  ICoveoResultForPage,
  ICoveoResultForProfile,
} from '@apw/shared/searchProvider';
import { IpaasListItem } from '@apw/types';
import { coveoPageService } from '../searchProvider/coveo/coveoPageService';
import { coveoProfileService } from '../searchProvider/coveo/coveoProfileService';

const URI_PREFIX = 'https://www.ringcentral.com/apps';

export const normalizeUri = (uri: string): string => {
  return uri.replace(URI_PREFIX, '');
};

export const normalizeSitemapProperty = (value: string): string => {
  return replaceSpecialCharacters(value);
};

const replaceSpecialCharacters = (value: string): string => {
  return (value || '').replace(/&amp;/g, '&');
};

export const normalizeProfileResults = (
  results: ICoveoResultForProfile[],
): IProfileCard[] => {
  return results.map((result) => {
    return coveoProfileService.transform(result);
  });
};

export const normalizeIpaasResult = (result: IpaasListItem): IProfileCard => {
  const { rcAppName, thirdPartyAppName, platform, logo, url } = result;
  const appName = `${rcAppName} x ${thirdPartyAppName} powered by ${platform}`;
  const shortDesc = `Connect ${rcAppName} and ${thirdPartyAppName} through the logic of ${platform}`;

  return {
    logos: [logo],
    shortDes: shortDesc,
    appName,
    appType: '',
    path: url,
  };
};

export const normalizeIpaasResults = (
  results: IpaasListItem[],
): IProfileCard[] => {
  return results.map((result) => {
    return normalizeIpaasResult(result);
  });
};

export const normalizePageResults = (
  results: ICoveoResultForPage[],
): IPageCard[] => {
  return results.map((result) => {
    return coveoPageService.transform(result);
  });
};
