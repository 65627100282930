import { onMobile, useProximaNovaSemibold } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const InstallationStyle = styled.div`
  width: 100%;
`;

export const InstallationHeader = styled.h5`
  ${useProximaNovaSemibold};
  width: 100%;
  margin: 0 0 20px;
  font-size: 22px;
  font-weight: 600;

  ${onMobile} {
    font-size: 20px;
  }
`;

export const InstallationContent = styled.div`
  width: 100%;
`;
