import React, { FC } from 'react';
import {
  BasicInfo,
  ScoreWrapper,
  StyledOverallRating,
} from '@apw/components/profileDetail/profileBody/overviewTab/ratingsAndReviews/ratingsSection/ratingsSection.sc';
import { ViewReviewButton } from '@apw/components/profileDetail/profileBody/overviewTab/ratingsAndReviews/ratingsSection/viewReviewsButton/ViewReviewButton';
import { buildOverallItems } from '@apw/components/ratingAndReview/overallRating/overallRating.service';
import { Rating } from '@apw/components/ratingAndReview/rating/Rating';
import { IAppRating } from '@apw/types';

export interface IRatingsSectionProps {
  ratings: IAppRating;
}

export const RatingsSection: FC<IRatingsSectionProps> = ({ ratings }) => {
  const overallRatingItems = buildOverallItems(ratings);
  return (
    <div>
      <BasicInfo data-test-automation-id={'overview-tab-ratings-section'}>
        <ScoreWrapper
          color="neutral.f06"
          variant="display3"
          data-test-automation-id={'ratings-section-score'}
          tabIndex={0}
        >
          {ratings.overallRating.toFixed(1)}
        </ScoreWrapper>
        <Rating
          rating={ratings.overallRating}
          ratingCount={ratings.voteCount}
        />
        <ViewReviewButton />
      </BasicInfo>
      <StyledOverallRating
        items={overallRatingItems}
        automationId={'overview-tab-overall-ratings'}
      />
    </div>
  );
};
