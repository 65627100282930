import { BPS, respondTo, onMobile, useProximaNovaSemibold } from '@apw/core/sc';
import { MobileHeader } from '@apw/shared/menu';
import { RcButton, RcIcon, RcPopover, styled } from '@ringcentral/juno';

export const Container = styled.div`
  white-space: nowrap;
`;

export const BrandDeveloperWidget = styled.div`
  text-align: right;
  font-size: 20px;
  line-height: 28px;

  a {
    color: #4475fd;
    font-size: 18px;
    vertical-align: middle;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  ${onMobile} {
    display: none;
  }
`;

export const SignInButton = styled(RcButton)`
  width: 225px;
  height: 40px;
  font-size: 16px;
  ${useProximaNovaSemibold}
`;

export const MobileMenuWidget = styled.div`
  display: none;

  ${onMobile} {
    display: block;
  }
`;

export const MobileHeaderContent = styled(MobileHeader)`
  display: none;

  ${respondTo(BPS.ltTablet)} {
    display: block;
  }

  ${onMobile} {
    display: block;
  }
`;

export const TriggerIcon = styled(RcIcon)`
  display: flex;
  align-items: center;
  justify-content: center;

  ${respondTo(BPS.ltTablet)} {
    width: 32px;
    height: 32px;
  }

  ${onMobile} {
    width: 32px;
    height: 32px;
  }

  vertical-align: middle;

  svg {
    ${respondTo(BPS.ltTablet)} {
      font-size: 30px;
    }
  }
`;

export const MobileHeaderWrapper = styled(RcPopover)`
  [class~='MuiPopover-paper'] {
    position: fixed;
    top: -800px !important;
    left: 0 !important;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    transition: top 0.4s !important;
    transform: none !important;
    opacity: 1 !important;
  }

  &.open {
    [class~='MuiPopover-paper'] {
      top: 0 !important;
    }
  }
`;
