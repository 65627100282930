import React, { ReactElement } from 'react';
import { isMobile } from '@apw/core/osDetector';
import { allCategories } from '@apw/modules/search/filterBar';
import { useCompatibilitySearchPath } from '@apw/routes/useCompatibilityPaths';
import { useStores } from '@apw/stores';
import { RcMenuList } from '@ringcentral/juno';
import { useHistory } from 'react-router-dom';
import { CategoryItem } from './CategoryContent.sc';

export const CategoryContent = (props: {
  onChange: () => void;
}): ReactElement => {
  const { searchStore } = useStores();
  const pathSearch = useCompatibilitySearchPath();
  const history = useHistory();

  const categories = [allCategories, ...searchStore.categories];
  const { onChange } = props;
  const isItemSelected = (category: string) => {
    return category === searchStore.selectedCategory;
  };

  const onCategoryChange = (category: string) => {
    searchStore.setSelectedCategory(category);
    history.push(`${pathSearch}${searchStore.getQueryString()}`);
    if (onChange) {
      onChange();
    }
  };

  return (
    <RcMenuList
      aria-label="category filter options"
      data-test-automation-id="category-list"
    >
      {categories.map((category) => (
        <CategoryItem
          data-test-automation-id={`category-item-${category.id}`}
          key={category.id}
          value={category.id}
          onClick={() => onCategoryChange(category.id)}
          className={`${
            isItemSelected(category.id) ? 'selectCategoryItem' : ''
          } ${isMobile() && 'mobileDevice'}`}
          tabIndex={0}
        >
          {category.displayName}
        </CategoryItem>
      ))}
    </RcMenuList>
  );
};
