import { BlockType, IContentBlocks } from '@apw/lib/types';
import { isFeatureBlockAvailable } from './featureBlock.service';

export const firstH1BlockIndex = (contentBlocks: IContentBlocks) => {
  return contentBlocks.findIndex((block) => {
    if (block.blockType === BlockType.Collection && block.appList?.length) {
      return true;
    }
    if (
      block.blockType === BlockType.Feature &&
      isFeatureBlockAvailable(block.items) &&
      block.heading?.trim()
    ) {
      return true;
    }
    return block.blockType === BlockType.Header;
  });
};
