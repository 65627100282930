import React from 'react';
import { ProfileTile } from '@apw/components';
import { RcLoading } from '@ringcentral/juno';
import { BlockHeader } from '../shared';
import { CollectionBody, Wrapper } from './collectionBlock.sc';
import { CollectionBlockInterface } from './typings';

export function CollectionBlock(props: {
  className: string;
  data: CollectionBlockInterface;
  indexInPageContent: number;
}) {
  const {
    className = '',
    data: {
      title,
      subTitle,
      displayCount = 0,
      appList = [],
      isLoading = false,
    } = {},
    indexInPageContent,
  } = props;

  if (!appList || !appList.length) {
    return null;
  }

  return (
    <Wrapper className={className} data-test-automation-id={`collection-block`}>
      <BlockHeader
        data={{ heading: title, subHeading: subTitle }}
        isH1={indexInPageContent === 0}
      />
      <CollectionBody>
        {(appList || []).slice(0, Number(displayCount)).map((app) => {
          return <ProfileTile app={app} key={app._id} />;
        })}
      </CollectionBody>
      {isLoading && (
        <RcLoading loading>
          <span />
        </RcLoading>
      )}
    </Wrapper>
  );
}
