import React, { useContext } from 'react';
import { ProfileOptionsContext } from '@apw/components/profileDetail/profile-options.context';
import { rootStore } from '@apw/stores';
import { RcLink } from '@ringcentral/juno';
import {
  GetFreeTrialBtn,
  GetFreeTrialText,
  SignInToProceedBtn,
  Wrapper,
} from './RequireLogin.sc';
import { onGetFreeTrial } from './requireLogin.service';

const isRCBrand = (brandId: string) => {
  return rootStore.compatibilitiesStore.isRCBrand(brandId);
};

export const RequireLogin = ({ pageName, brandId }) => {
  const { onRequireLoginGate } = useContext(ProfileOptionsContext);
  return (
    <Wrapper>
      <SignInToProceedBtn
        onClick={onRequireLoginGate}
        color="text.primary"
        size="medium"
        variant="outlined"
        data-test-automation-id={`sign-in-to-proceed-button`}
      >
        Sign in to proceed
      </SignInToProceedBtn>
      {isRCBrand(brandId) && (
        <GetFreeTrialBtn
          variant="text"
          size="medium"
          onClick={() => onGetFreeTrial(pageName, 'Sign up for a free trial')}
          data-test-automation-id={`get-free-trial-button`}
        >
          <GetFreeTrialText>
            <RcLink
              color="primary"
              data-test-automation-id={`get-free-trial-link`}
            >
              Sign up for a free trial
            </RcLink>
          </GetFreeTrialText>
        </GetFreeTrialBtn>
      )}
    </Wrapper>
  );
};
