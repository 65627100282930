import { useProximaNovaMedium, FontWeight } from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const Title = styled.div`
  ${useProximaNovaMedium};
  margin-bottom: 24px;
  line-height: 24px;
  font-size: 16px;
  font-weight: ${FontWeight.regular};
  color: #282829;
`;
