import { updateParams } from '@apw/core/url/url.service';

export enum UtmTrackingParam {
  SOURCE = 'utm_source',
  MEDIUM = 'utm_medium',
  CAMPAIGN = 'utm_campaign',
  CONTENT = 'utm_content',
  TERM = 'utm_term',
}

export const utmTrackingParams = [
  UtmTrackingParam.SOURCE,
  UtmTrackingParam.MEDIUM,
  UtmTrackingParam.CAMPAIGN,
  UtmTrackingParam.CONTENT,
  UtmTrackingParam.TERM,
];

export const addUtmTrackingParams = (url: string, params: any): string => {
  return updateParams(url, params);
};
