import React, { FC } from 'react';
import {
  CertifiedIcon,
  CertifiedText,
  Wrapper,
} from '@apw/components/certifiedBadge/certifiedBadge.sc';
import { ItemListSelected } from '@ringcentral/juno-icon';
import { Tooltip } from '../tooltip';

interface ICertifiedBadgeProps {
  className?: string;
}

export const CertifiedBadge: FC<ICertifiedBadgeProps> = ({
  className = '',
}) => {
  return (
    <Wrapper
      className={`${className}`}
      data-test-automation-id="certifiedBadge"
    >
      <CertifiedIcon
        symbol={ItemListSelected}
        data-test-automation-id={`certified-badge-icon`}
      />
      <Tooltip
        trigger={
          <CertifiedText data-test-automation-id="certifiedBadgeText">
            CERTIFIED
          </CertifiedText>
        }
        title={
          'This app has undergone additional assessments to assure its quality and ease of use.'
        }
        tabIndex={0}
        automationId={`certified-badge-tooltip`}
      />
    </Wrapper>
  );
};
