import React from 'react';
import { IBotItem } from '@apw/modules/myApps/botList/typings';
import { styled } from '@ringcentral/juno';

interface IBotInstallerProps {
  item: IBotItem;
}

export const BotInstaller = (props: IBotInstallerProps) => {
  const { item } = props;

  if (!item.installer) {
    return null;
  }

  return (
    <Wrapper data-test-automation-id="installed-bot-installer">
      Installed by {item.installer}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: #666666;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;
