import React, { FC } from 'react';
import {
  defaultButtonName,
  OSConfig,
  OSConfigItem,
  osTitle,
  SupportedOS,
} from '@apw/components/profileDetail/profileHeader/ctaButton/appDownloadButton/appDownloadService';
import { AppDownloadLink } from '@apw/types';
import { ExternalLink } from '@ringcentral/juno-icon';
import * as _ from 'lodash';
import {
  Container,
  ExternalLinkIcon,
  MainContent,
  PlatformIcon,
} from './platformOption.sc';

interface PlatformOptionProps {
  item: AppDownloadLink;
  className?: string;
  withExternalLinkIcon?: boolean;
  useIconForActive?: boolean;
  useIconForDisabled?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  firstItem?: boolean;
}

export const PlatformOption: FC<PlatformOptionProps> = ({
  item,
  className = '',
  withExternalLinkIcon = false,
  useIconForActive = false,
  useIconForDisabled = false,
}) => {
  const hideOSIcon = () => {
    return _.includes([SupportedOS.OtherOS, SupportedOS.noOS], item.os);
  };

  const getDefaultButtonName = () => {
    return defaultButtonName[item.os];
  };

  const buttonLabel = (item.desc && item.desc.trim()) || getDefaultButtonName();
  const buttonTitle = `${buttonLabel} ${osTitle[item.os] || ''}`;

  return (
    <Container className={`${className}`}>
      <MainContent>
        <span
          aria-label={buttonTitle}
          data-test-automation-id={`platform-option-label`}
        >
          {buttonLabel}
        </span>
        {!hideOSIcon() && (
          <PlatformIcon
            symbol={
              useIconForActive
                ? useIconForDisabled
                  ? getOsConfig(item.os).iconDisabled
                  : getOsConfig(item.os).iconActive
                : getOsConfig(item.os).icon
            }
            size="medium"
            className={`${item.os || SupportedOS.OtherOS}`}
            data-test-automation-id={`platform-option-icon`}
          />
        )}
      </MainContent>
      {withExternalLinkIcon && (
        <ExternalLinkIcon
          symbol={ExternalLink}
          size="small"
          data-test-automation-id={`external-link-icon`}
        />
      )}
    </Container>
  );
};

const getOsConfig = (os: string): OSConfigItem => {
  return OSConfig[os];
};
