/**
 * The event names are specified in below Google Sheet
 * Legacy: https://docs.google.com/spreadsheets/d/1KmDP0TXaXwaHjEFZl_6tHmdN45GPF9N5jqa5GSRuLdg/edit#gid=1080979200
 * New:    https://docs.google.com/spreadsheets/d/1x8FxqTq39vs7zXcDR8WuUnbqaUDE3evYjaJsx64QGGg/edit#gid=0
 */

export enum TrackedEvent {
  PAGEVIEWS = 'Pageviews',
  ACTIONS = 'Actions',
  AUTHORIZE_BOT = 'AG: Authorized Bot',
  INSTALL_BOT = 'AG: Installed Bot',
  REMOVE_BOT = 'AG: Removed Bot',
  ADD_BOT_TO_CHAT = 'AG: Bot added to conversation',
  SEARCH_BEHAVIOR = 'Search Behavior',
  AG_CONTACT_US_PROMPT = 'AG: Contact Us Prompt',
  AG_CONTACT_US_SUBMITTED = 'AG: Contact Us Submitted',
  AG_GET_APP_CLICKED = 'AG: Get App Clicked',
  AG_GET_APP_COMPLETED = 'AG: Get App Completed',
  AG_BIG_BLUE_BUTTON_CLICKED = 'AG: Big Blue Button Clicked',
  SEARCH_CLICK = 'Search click',
  AG_GET_SUPPORT = 'AG: Get support',
  INSTALL_MNA = 'AG: Installed Notification App',
  RATING_PENDING = 'Rating: Submit for approval',
  RATING_STATE_CHANGE = 'Rating: State change',
  TOGGLE_INSTALLED_APP = 'Jup_Web/DT_apps_enableOrDisableApp',
  OPEN_SET_UP_INSTALLED_APP = 'Jup_Web/DT_apps_setupAppDialog',
  REMOVE_INSTALLED_APP = 'Jup_Web/DT_apps_deleteApp',
  OPEN_DISCOVER_FROM_MY_APPS = 'Jup_Web/DT_apps_discoverApps',
  ADD_TO_CONVERSATION = 'Jup_Web/DT_apps_addToConversation',
  GO_TO_CONVERSATION = 'Jup_Web/DT_msg_goToConversation',
  SHOW_CONVERSATION_PICKER = 'Jup_Web/DT_apps_addAppDialog',
  JAG_ENTRANCE = 'AG: JAG Entrance',
}
