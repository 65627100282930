import {
  Color,
  FontWeight,
  onMobile,
  useProximaNovaRegular,
  useProximaNovaSemibold,
} from '@apw/core/sc';
import {
  RcButton,
  RcCircularProgress,
  RcDialogActions,
  RcDialogContent,
  RcDialogTitle,
  RcIconButton,
  RcMenuItem,
  RcSelect,
  RcTextField,
  styled,
} from '@ringcentral/juno';

export const Loading = styled(RcCircularProgress)`
  position: absolute;
  left: 50%;
  top: 50%;
`;

export const DialogTitle = styled(RcDialogTitle)`
  padding: 28px 24px 8px 48px !important;

  h2 {
    ${useProximaNovaSemibold};
  }

  ${onMobile} {
    padding-top: 0 !important;
    padding-left: 15px !important;
    h2 {
      font-size: 24px !important;
    }
  }
`;

export const DialogCloseButton = styled(RcIconButton)`
  position: absolute !important;
  top: 12px !important;
  right: 12px !important;
  color: #686869 !important;

  svg {
    font-size: 20px;
  }

  ${onMobile} {
    display: none !important;
  }
`;

export const DialogContent = styled(RcDialogContent)`
  padding: 0 48px 12px !important;

  ${onMobile} {
    padding: 0 15px 25px !important;
  }

  [class~='RcTextFieldFormHelperText-error'] {
    ${useProximaNovaRegular};
  }
`;

export const Description = styled.p`
  font-size: 15px;
  line-height: 24px;
  font-weight: ${FontWeight.regular};
  color: ${Color.lightBlack};
  margin-bottom: 24px;
  word-wrap: break-word;

  ${onMobile} {
    font-size: 14px;
    margin-bottom: 19px;
  }
`;

export const FormField = styled.div`
  [class~='MuiInput-input'] {
    ${useProximaNovaRegular};
  }
`;

export const FormTextField = styled(RcTextField)`
  [class~='MuiInput-input'] {
    ${useProximaNovaRegular};
  }
`;

export const RecaptchaContainer = styled(FormField)`
  text-align: right;
  margin-bottom: 10px;

  ${onMobile} {
    text-align: center;
    margin-bottom: 10px !important;
  }

  > div {
    display: inline-block;
  }

  .error {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const GroupLabel = styled.div`
  flex-shrink: 0;
  width: 108px;
  font-size: 18px;
  color: ${Color.black};
  padding-top: 16px;

  ${onMobile} {
    padding-top: 0;
    padding-bottom: 18px;
    font-size: 20px;

    &:empty {
      display: none;
    }
  }
`;

export const GroupContent = styled.div`
  display: flex;
  flex-grow: 1;
`;

const Group = styled.div`
  display: flex;
  margin-top: 16px !important;

  &:first-of-type {
    margin-top: 0 !important;
  }

  ${onMobile} {
    flex-direction: column;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }
`;

export const GroupOneColumn = styled(Group)`
  ${GroupContent} {
    flex-direction: column;
    flex-basis: 100%;
  }

  ${FormField}, ${FormTextField} {
    width: 100%;
    height: auto;
    margin-bottom: 40px;

    ${onMobile} {
      margin-bottom: 25px;
    }
  }
`;

export const GroupTwoColumn = styled(Group)`
  ${GroupContent} {
    flex-wrap: wrap;

    ${onMobile} {
      flex-direction: column;
    }
  }

  ${FormField}, ${FormTextField} {
    width: 50%;
    margin-bottom: 40px !important;

    &:nth-child(odd) {
      padding-right: 25px;
    }

    &:nth-child(even) {
      padding-left: 25px;

      label {
        padding-left: 25px;

        ${onMobile} {
          padding-left: 0;
        }
      }
    }

    ${onMobile} {
      width: 100% !important;
      padding: 0 !important;
    }
  }
`;

export const SelectorField = styled(RcSelect)`
  [class~='MuiInput-input'] {
    padding-left: 0 !important;
  }

  label {
    padding-left: 0 !important;
  }

  [class~='MuiSelect-icon'] {
    svg {
      font-size: 24px !important;
    }
  }
`;

export const SelectorFieldWithErrorMsg = styled(SelectorField)`
  [class~='MuiInputLabel-root'] {
    color: #df1c0c;
  }

  [class~='MuiInput-input'] {
    border-bottom: 2px solid #f54c3d !important;
    padding-bottom: 4px !important;
  }
`;

export const SelectorFieldItem = styled(RcMenuItem)`
  display: block !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  ${useProximaNovaRegular};
  padding-left: 16px;
  padding-right: 16px;
`;

export const FieldErrorMsg = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 16px;
  color: #df1c0c;
  margin-top: 4px;
`;

export const SubmitButton = styled(RcButton)``;

export const DialogActions = styled(RcDialogActions)`
  justify-content: space-between !important;
  padding: 8px 48px 32px !important;

  a {
    text-decoration: none;

    &,
    span {
      font-size: 16px !important;
    }
  }

  ${onMobile} {
    padding: 0 15px 25px !important;
    flex-direction: column-reverse;

    button {
      width: 100% !important;
      height: 50px !important;
      font-size: 17px !important;
      margin: 0 auto !important;
    }

    a {
      margin-right: 0;
      margin-top: 60px;
      font-size: 15px;
    }

    ${SubmitButton} {
      margin-bottom: 16px !important;
    }
  }
`;
