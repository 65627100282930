import React from 'react';
import { Wrapper } from '@apw/components/pageContent/htmlBlock/htmlBlock.sc';
import { clickAToNewTab } from '@apw/components/pageContent/shared';
import { palette2, styled } from '@ringcentral/juno';
import { HtmlBlockInterface } from './typings';

function HtmlBlock(props: {
  className: string;
  data: HtmlBlockInterface;
  indexInPageContent: number;
}) {
  const { className = '', data: { htmlContent = '' } = {} } = props;

  if (!htmlContent || !htmlContent.trim()) {
    return null;
  }

  const content = { dangerouslySetInnerHTML: { __html: htmlContent } };

  return (
    <Wrapper
      data-test-automation-id={`html-block`}
      className={className}
      ref={(ele) => clickAToNewTab(ele)}
      {...content}
    />
  );
}

const StyledHtmlBlock = styled(HtmlBlock)`
  & {
    a {
      color: ${palette2('text', 'secondary')};
    }
  }
`;

export { StyledHtmlBlock as HtmlBlock };
