import { BPS, respondTo, onMobile } from '@apw/core/sc';
import { RcIcon, styled } from '@ringcentral/juno';
import { Link } from 'react-router-dom';

export const RcLogoLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  text-decoration: none;
  cursor: pointer;

  ${onMobile} {
    flex-wrap: wrap;
  }
}
`;

export const RcLogoImage = styled(RcIcon)`
  svg {
    width: 300px;
    height: 32px;

    ${respondTo(BPS.ltTablet)} {
      width: 260px;
      height: 100%;
    }

    ${onMobile} {
      width: 220px;
      height: 100%;
    }
`;
