import Cookies from 'js-cookie';
import { googleAnalytics } from '@apw/core/tracker';
import { rootStore } from '@apw/stores';

export class OneTrustHelper {
  oneTrust: any;

  oneTrustMethodsUsed = [
    'GetDomainData',
    'IsAlertBoxClosed',
    'OnConsentChanged',
    'Close',
    'ToggleInfoDisplay',
  ];

  lastActiveGroups: string[];

  constructor() {
    this.getOneTrustInstance();
  }

  getOneTrustInstance() {
    if (this.isOneTrustLoaded) {
      this.onOneTrustLoaded();
    } else {
      const ITERATION_ATTEMPTS_AMOUNT = 2000;
      let iterationNumber = 0;

      const oneTrustInterval = setInterval(() => {
        iterationNumber++;

        if (this.isOneTrustLoaded) {
          clearInterval(oneTrustInterval);
          this.onOneTrustLoaded();
          return;
        }

        if (iterationNumber === ITERATION_ATTEMPTS_AMOUNT) {
          clearInterval(oneTrustInterval);

          // eslint-disable-next-line no-console
          console.warn('ONETRUST HELPER - OneTrust is not found');
        }
      }, 20);
    }
  }

  get isOneTrustLoaded() {
    return (
      window.OneTrust &&
      this.oneTrustMethodsUsed.every((method) => !!window.OneTrust[method])
    );
  }

  onOneTrustLoaded() {
    this.oneTrust = window.OneTrust;

    try {
      this.lastActiveGroups = this.currentActiveGroups;
      this.addOneTrustFlag();
      this.registerOneTrustElementEvents();
      this.registerAutoClosing();
      this.registerConsentChanged();
      this.initGoogleAnalytics();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('ONETRUST HELPER - Error while handling OneTrust', e);
    }
  }

  addOneTrustFlag() {
    const htmlElement = document.querySelector('html');

    if (htmlElement) {
      htmlElement.classList.add('onetrust');
    }
  }

  registerOneTrustElementEvents() {
    document.body.addEventListener('click', (event: any) => {
      if (event.target && event.target.id === 'close-pc-btn-handler') {
        window.OneTrust.Close();
      }
    });
  }

  registerAutoClosing() {
    if (!window.OneTrust.IsAlertBoxClosed()) {
      const CLOSING_TIME = 20 * 1000;

      setTimeout(function () {
        const pcModal = document.querySelector('.onetrust-pc-dark-filter');

        if (pcModal) {
          const pcModalDisplayStyle = getComputedStyle(pcModal).display;

          if (pcModalDisplayStyle === 'none') {
            window.OneTrust.Close();
          }
        }
      }, CLOSING_TIME);
    }
  }

  registerConsentChanged() {
    window.OneTrust.OnConsentChanged(() => {
      const shouldReload =
        JSON.stringify(this.lastActiveGroups.sort()) !==
        JSON.stringify(this.currentActiveGroups.sort());
      this.lastActiveGroups = this.currentActiveGroups;

      if (shouldReload) {
        window.location.reload();
      }
    });
  }

  initGoogleAnalytics() {
    const currentActiveGroups = this.currentActiveGroups;
    const isGAPassed =
      currentActiveGroups.includes('C0002') &&
      currentActiveGroups.includes('C0004');

    if (isGAPassed) {
      googleAnalytics.init({
        trackerName: 'AppGallery',
        trackingId: RCAPW.GoogleAnalytics.TrackingID,
        getCompatibility: () => rootStore.compatibilityStore.compatibility.name,
      });
      googleAnalytics.isGoogleAnalyticsEnabled$.next(true);
    }
  }

  get currentActiveGroups() {
    if (typeof window.OptanonActiveGroups !== 'undefined') {
      const activeGroups = window.OptanonActiveGroups.split(',').filter(
        (groupId) => groupId !== '',
      );
      if (activeGroups.length !== 0) {
        return activeGroups;
      }
    }

    return this.cachedActiveGroups
      .map((group) => {
        const groupArr = group.split(':');
        return +groupArr[1] ? groupArr[0] : '';
      })
      .filter((group) => group !== '');
  }

  get cachedActiveGroups() {
    const optanonConsentCookieValue = Cookies.get('OptanonConsent');
    if (!optanonConsentCookieValue) {
      return ['C0001:1', 'C0002:1', 'C0003:1', 'C0004:1'];
    }

    const groupsProperty = optanonConsentCookieValue
      .split('&')
      .filter((property) => property.indexOf('groups') !== -1)[0];
    if (!groupsProperty) return ['C0001'];

    const groupsValues = groupsProperty.substr(
      groupsProperty.indexOf('=') + 1,
      groupsProperty.length,
    );
    const decodedGroups = decodeURIComponent(groupsValues);

    return decodedGroups.split(',');
  }

  get targetingCookiesEnabled() {
    return this.currentActiveGroups.includes('C0004');
  }

  resetOneTrust() {
    Cookies.remove('OptanonAlertBoxClosed');
    Cookies.remove('OptanonConsent');
    window.location.reload();
  }
}

const oneTrustHelper = new OneTrustHelper();
window.OneTrustHelper = oneTrustHelper;

export default oneTrustHelper;
