import { OverallRating } from '@apw/components/ratingAndReview/overallRating/OverallRating';
import { RcTypography, styled } from '@ringcentral/juno';

export const BasicInfo = styled.div`
  border-bottom: 1px solid #cbcbcb;
  padding-bottom: 24px;
  margin-bottom: 20px;
`;

export const ScoreWrapper = styled(RcTypography)`
  margin-top: 4px;
`;

export const StyledOverallRating = styled(OverallRating)`
  &&& {
    > div {
      margin-bottom: 8px;
    }
  }
`;
