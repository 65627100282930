import {
  isBotProfile,
  shouldGetAppFromGallery,
} from '@apw/modules/botProvision/bot-provision.service';
import { IProfile } from '@apw/types';
import { Observable, Observer } from 'rxjs';

export const getBotInstallerRelativePath = (appId: string): string => {
  return `/${appId}/install`;
};

const getBotInstallerFullUrl = (appId: string): string => {
  const relativePath = getBotInstallerRelativePath(appId);
  const fullPath = `${process.env.PUBLIC_URL}${relativePath}`;
  return `${window.location.protocol}//${window.location.host}${fullPath}`;
};

export const getInstallUrlForSeo = (
  item: IProfile,
): Observable<string | undefined> => {
  return new Observable((observer: Observer<string | undefined>) => {
    isBotProfile(item)
      .then((isBotItem: boolean) => {
        if (!isBotItem || !shouldGetAppFromGallery(item)) {
          observer.next(undefined);
          return;
        }

        observer.next(getBotInstallerFullUrl(item.appId as string));
      })
      .catch(() => {});
  });
};
