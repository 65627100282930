import { buildTheme } from '@apw/shared/theme/utils/themeBuilder';
import { Compatibility } from '@apw/stores/compatibility';
import { fallbackCompatibilities } from '@apw/stores/compatibility/compatibilities/fallback/compatibilities';
import { prepareGalleryBrands } from '@apw/stores/compatibility/compatibilities/galleryBrand/galleryBrand.service';
import {
  GalleryIconType,
  ICompatibility,
} from '@apw/stores/compatibility/compatibility.interface';
import { IGalleryBrand, IGallerySubBrand } from './galleryBrand';

interface IPreparedCompatibilities {
  items: ICompatibility[];
  useFallback: boolean;
}

export const prepareCompatibilities = async (
  enableHiddenAppGalleries: boolean,
): Promise<IPreparedCompatibilities> => {
  try {
    const options = {
      getAll: enableHiddenAppGalleries ? true : undefined,
    };

    const galleryBrands = await prepareGalleryBrands(options);

    return {
      items: galleryBrandsPipeline(galleryBrands),
      useFallback: false,
    };
  } catch (err) {
    return {
      items: fallbackCompatibilities.items,
      useFallback: true,
    };
  }
};

const galleryBrandsPipeline = (
  galleryBrands: IGalleryBrand[],
): ICompatibility[] => {
  let items = createCompatibilities(galleryBrands);
  items = ensureRcCompatibility(items);
  items = sortCompatibilities(items);
  return items;
};

const ensureRcCompatibility = (
  compatibilities: ICompatibility[],
): ICompatibility[] => {
  const rcCompatibility = compatibilities.find(
    (compatibility) => compatibility.id === Compatibility.RINGCENTRAL,
  );

  if (rcCompatibility) {
    return compatibilities;
  }

  const fallbackRcCompatibility =
    fallbackCompatibilities.mapping[Compatibility.RINGCENTRAL];

  return [fallbackRcCompatibility, ...compatibilities];
};

const createCompatibilities = (
  galleryBrands: IGalleryBrand[],
): ICompatibility[] => {
  return galleryBrands.map((item) => createCompatibility(item));
};

const createCompatibility = (galleryBrand: IGalleryBrand): ICompatibility => {
  const brandId = galleryBrand.brandId;
  const fallbackCompatibility = fallbackCompatibilities.mapping[brandId];
  const parsedConfig = galleryBrand.parsedConfig;

  return {
    ...fallbackCompatibility,
    name: galleryBrand.name,
    galleryName: galleryBrand.galleryName,
    id: galleryBrand.brandId,
    slug: galleryBrand.urlSlug,
    galleryIcon: parsedConfig.icon,
    galleryIconType: GalleryIconType.URL,
    headerBanners: parsedConfig.banners,
    primaryColor: parsedConfig.primaryColor,
    secondaryColor: parsedConfig.secondaryColor,
    theme: buildTheme({
      colors: {
        primaryColor: parsedConfig.primaryColor,
        secondaryColor: parsedConfig.secondaryColor,
      },
    }),
    jupiterWebUrl: parsedConfig.partnerDomain
      ? `https://${parsedConfig.partnerDomain}`
      : undefined,
    horizontalNavs: parsedConfig.navs,
    subBrands: sortSubBrands(galleryBrand.subBrands || []),
  };
};

const sortSubBrands = (subBrands: IGallerySubBrand[]): IGallerySubBrand[] => {
  return subBrands.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
};

const sortCompatibilities = (
  compatibilities: ICompatibility[],
): ICompatibility[] => {
  return compatibilities.slice().sort((a, b) => {
    if (b.id === Compatibility.RINGCENTRAL) {
      return 1;
    }

    return (a.name || '').localeCompare(b.name || '');
  });
};
