import { PrimaryButton } from '@apw/components/button';
import { RcDialog, RcIconButton, styled } from '@ringcentral/juno';
import { RcDivider } from '@ringcentral/juno/components/Divider';

export const DialogContainer = styled(RcDialog)`
  [class~='MuiDialog-paperWidthSm'] {
    max-width: 1152px !important;
  }
`;

export const DialogSlideBackground = styled.div`
  background: rgb(243, 243, 243);
  border-radius: 8px;
  padding: 16px;

  *::-ms-backdrop,
  div {
    /* IE 11 */
    height: 100%;
  }
`;

export const Divider = styled(RcDivider)`
  margin-top: 24px !important;
  background-color: #cbcbcb !important;
`;

export const CloseBtn = styled(PrimaryButton)``;

export const ActionContainer = styled.div`
  padding: 15px 0 12px 0;
  position: relative;
  box-sizing: content-box;
  height: 40px;

  ${CloseBtn} {
    width: 96px;
    position: absolute;
    top: 15px;
    right: 0;
  }
`;

export const Back = styled(RcIconButton)``;

export const Next = styled(RcIconButton)``;

export const SlideAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${Back} {
    margin-right: 18px;
  }

  ${Next} {
    margin-left: 2px;
  }

  ${Back},${Next} {
    &.disable {
      color: #cccccc;
      cursor: not-allowed;
    }
  }
`;

export const SlideIndicator = styled.div`
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background: rgb(40, 40, 41);
  margin-right: 16px;
  cursor: pointer;

  &.active {
    border: 2px solid rgb(0, 17, 56);
    background-color: white;
  }
`;
