/* eslint-disable */
import {
  ICompatibility,
} from '@apw/stores/compatibility';
import {
  getCompatibilityHomePath,
  getCompatibilitySearchPath,
} from '@apw/routes/getCompatibilityPaths';
import {
  RedirectionRules,
  RedirectionRulesFactory,
} from '@apw/hooks/redirectionRules';
import { urlParameterKeys } from '@apw/core/url';
import { getRedirectTarget } from '@apw/core/redirect/redirect.service';
import { rootStore } from '@apw/stores';

enum LegacyQueryParam {
  APP_CATEGORY = 'appCategory',
  PRODUCT = 'product',
  SEARCH_TEXT = 'q',
  COMPATIBILITY = 'compatibility',
  PARTNER_BADGE = 'partnerBadge',
  APPS = 'apps',
}

export interface RedirectionRulesFactoryData {
  queryParams: any;
  currentCompatibility: ICompatibility;
}

export const redirectionRulesFactory: RedirectionRulesFactory<RedirectionRulesFactoryData> = (
  data,
): RedirectionRules => {
  const {queryParams, currentCompatibility} = data;

  const resolveCompatibility = () => {
    const value = queryParams[LegacyQueryParam.COMPATIBILITY];

    return (
      rootStore.compatibilitiesStore.getCompatibilityByValue(value) ||
      currentCompatibility
    );
  };

  const buildCompatibilityHomePath = () => {
    const compatibility = resolveCompatibility();
    return getCompatibilityHomePath(compatibility.slug);
  };

  const buildCompatibilitySearchPath = () => {
    const compatibility = resolveCompatibility();
    return getCompatibilitySearchPath(compatibility.slug);
  };

  return [
    {
      // `/?compatibility=office@hand` to `/office-at-hand/`
      test: () => {
        const isCompatibilitySet = !!queryParams[
          LegacyQueryParam.COMPATIBILITY
          ];

        const allOtherParamsEmpty = [
          LegacyQueryParam.APP_CATEGORY,
          LegacyQueryParam.PRODUCT,
          LegacyQueryParam.SEARCH_TEXT,
          LegacyQueryParam.PARTNER_BADGE,
          LegacyQueryParam.APPS,
        ].every((key) => !queryParams[key]);

        return isCompatibilitySet && allOtherParamsEmpty;
      },
      getTarget: () => {
        return getRedirectTarget({
          basePath: buildCompatibilityHomePath(),
          keyMapping: {},
          queryParams,
        });
      },
    },

    // `/?partnerBadge=Premier` to `/<brandSlug>/search?partnerBadge=Premier`
    {
      test: () => {
        return !!queryParams[LegacyQueryParam.PARTNER_BADGE];
      },
      getTarget: () => {
        return getRedirectTarget({
          basePath: buildCompatibilitySearchPath(),
          keyMapping: {
            [LegacyQueryParam.PARTNER_BADGE]: urlParameterKeys.PARTNER_BADGE,
          },
          queryParams,
        });
      },
    },

    // `/?apps=ringcentral-glip,ringcentral-phone` to `/apps/p/ringcentral-apps`
    // `/?apps=rc-app,rc-phone` to `/apps/p/ringcentral-apps`
    {
      test: () => {
        const appsParam: string = queryParams[LegacyQueryParam.APPS];
        const redirectList = [
          'ringcentral-glip,ringcentral-phone',
          'rc-app,rc-phone',
        ];
        return !!appsParam && redirectList.includes(appsParam.toLowerCase());
      },
      getTarget: () => {
        const rcAppsPagePath = '/p/ringcentral-apps';
        return getRedirectTarget({
          basePath: rcAppsPagePath,
          keyMapping: {},
          queryParams,
        });
      },
    },

    // `/?apps=vanityUrl1,vanityUrl2` to `/<brandSlug>/search?apps=vanityUrl1,vanityUrl2`
    {
      test: () => {
        return !!queryParams[LegacyQueryParam.APPS];
      },
      getTarget: () => {
        return getRedirectTarget({
          basePath: buildCompatibilitySearchPath(),
          keyMapping: {
            [LegacyQueryParam.APPS]: urlParameterKeys.APPS,
          },
          queryParams,
        });
      },
    },

    // `/?appCategory=AI&product=Video&q=123` to `/<brandSlug>/search?appCategory=AI&product=Video&q=123`
    {
      test: () => {
        return [
          LegacyQueryParam.APP_CATEGORY,
          LegacyQueryParam.PRODUCT,
          LegacyQueryParam.SEARCH_TEXT,
        ].some((key) => !!queryParams[key]);
      },
      getTarget: () => {
        const appCategoryEncoder = (value) => {
          if (typeof value === 'string') {
            const parts = value.split(',');
            return parts
              .map((part) => encodeURIComponent(part))
              .join(',');
          }
          return value;
        };

        const searchTextEncoder = (value) => value ? encodeURIComponent(value) : value;

        return getRedirectTarget({
          basePath: buildCompatibilitySearchPath(),
          keyMapping: {
            [LegacyQueryParam.APP_CATEGORY]: urlParameterKeys.APP_CATEGORY,
            [LegacyQueryParam.PRODUCT]: urlParameterKeys.PRODUCT,
            [LegacyQueryParam.SEARCH_TEXT]: urlParameterKeys.SEARCH_TEXT,
          },
          customEncoders: {
            [urlParameterKeys.APP_CATEGORY]: appCategoryEncoder,
            [urlParameterKeys.SEARCH_TEXT]: searchTextEncoder,
          },
          queryParams,
        });
      },
    },
  ];
};
