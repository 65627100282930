import { useProximaNovaSemibold } from '@apw/core/sc';
import { RcButton, styled } from '@ringcentral/juno';

export const BaseButtonContainer = styled(RcButton)`
  ${useProximaNovaSemibold};
  width: 100%;
  min-height: 40px !important;

  [class~='MuiButton-label'] {
    font-size: 16px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
