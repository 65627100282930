import { DependencyList, useEffect, useRef } from 'react';
import { Observable } from 'rxjs';

export const useSubscribe = (
  observableCreator: () => Observable<any>,
  observer: Function,
  deps?: DependencyList | undefined,
) => {
  const observerRef = useRef<Function>(() => {});

  useEffect(() => {
    observerRef.current = observer;
  });

  useEffect(() => {
    const subscription = observableCreator().subscribe((x) => {
      observerRef.current(x);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [...(deps || [])]);
};
