import React, { FC } from 'react';
import { BrokenLinkIndicator } from '@apw/shared/brokenLinkIndicator';
import { GenericErrorLinkIcon } from '@apw/shared/expiredPreviewLink/ExpiredPreviewLink.sc';
import { useTheme } from '@material-ui/core/styles';
import { ExternalLink } from '@ringcentral/juno-icon';

export const ExpiredPreviewLink: FC = () => {
  const theme = useTheme();

  return (
    <BrokenLinkIndicator>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <div role="alert" tabIndex={0}>
        This page preview has expired.
      </div>
      <div>
        Visit the{' '}
        <a
          href="https://developers.ringcentral.com"
          target="_blank"
          rel="noreferrer"
          style={{ color: theme.palette.text.primary }}
        >
          developer platform
          <GenericErrorLinkIcon
            symbol={ExternalLink}
            size="small"
            tabIndex={-1}
            data-test-automation-id="generic-error-link"
          />
        </a>{' '}
        to preview it again if needed.
      </div>
    </BrokenLinkIndicator>
  );
};
