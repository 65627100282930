import React, { FC, ReactNode } from 'react';
import genericErrorImage from '@apw/assets/images/generic-error.png';
import {
  CallToAction,
  Container,
  Content,
  Image,
} from '@apw/shared/brokenLinkIndicator/BrokenLinkIndicator.sc';
import { GoToAppGallery } from './goToAppGallery';

interface GenericErrorProps {
  children: ReactNode;
  callToAction?: ReactNode;
  className?: string;
}

export const BrokenLinkIndicator: FC<GenericErrorProps> = ({
  children,
  callToAction = <GoToAppGallery />,
  className = '',
}) => {
  return (
    <Container
      className={className}
      data-test-automation-id="broken-link-indicator-container"
    >
      <Image
        src={genericErrorImage}
        alt="Generic Error"
        data-test-automation-id="indicator-image"
      />
      <Content data-test-automation-id="indicator-content">{children}</Content>
      {callToAction && (
        <CallToAction data-test-automation-id="indicator-call-to-action">
          {callToAction}
        </CallToAction>
      )}
    </Container>
  );
};
