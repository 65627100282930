import {
  onMobile,
  PAGE_DESKTOP_MARGINTOP,
  PAGE_MOBILE_MARGINTOP,
} from '@apw/core/sc';
import { css, styled } from '@ringcentral/juno';

export const navigationBarMixin = css`
  margin-bottom: 0 !important;
`;

export const MainContainer = styled.div`
  width: calc(100% - 288px);
  margin: auto;
  margin-top: ${PAGE_DESKTOP_MARGINTOP}px;
  display: flex;
  flex-wrap: wrap;
  ${onMobile} {
    width: 100%;
    margin-top: ${PAGE_MOBILE_MARGINTOP}px;
  }
`;
