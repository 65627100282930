import React from 'react';
import { CriteriaBox } from '@apw/components/ratingAndReview/popup/content/CriteriaBox';
import {
  GlobalTooltipStyle,
  Overall,
  OverallRating,
  Quality,
  RatingContainer,
} from '@apw/components/ratingAndReview/popup/content/RatingStep.sc';
import { RatingPopupStore } from '@apw/components/ratingAndReview/popup/ratingPopup.store';
import { QualityRatingTooltips } from '@apw/components/ratingAndReview/qualityRating/qualityRatingLabels';
import { observer } from 'mobx-react';

interface RatingStepProps {
  store: RatingPopupStore;
}

const RatingStepPure = ({ store }: RatingStepProps) => {
  return (
    <>
      <GlobalTooltipStyle />
      <Overall
        color="neutral.f05"
        variant="subheading2"
        data-test-automation-id="rating-popup-overall-title"
      >
        Overall rating *
      </Overall>
      <RatingContainer>
        <OverallRating
          name="rating-popup-overall"
          value={store.overallRating}
          tooltips={['Terrible', 'So-so', 'Helpful', 'Very helpful', 'Awesome']}
          onChange={(e, value) => store.setVoteStar(value)}
          data-test-automation-id="rating-popup-overall"
        />
      </RatingContainer>
      <Quality
        color="neutral.f05"
        variant="subheading2"
        data-test-automation-id="rating-popup-quality"
      >
        Quality ratings
      </Quality>
      <CriteriaBox
        name="rating-popup-value"
        label="Value *"
        tooltip={QualityRatingTooltips.Value}
        value={store.valueRating}
        onChange={(e, value) => store.setValue(value)}
        data-test-automation-id="rating-popup-value"
      />
      <CriteriaBox
        name="rating-popup-convenience"
        label="Ease of use *"
        tooltip={QualityRatingTooltips.Convenience}
        value={store.convenienceRating}
        onChange={(e, value) => store.setConvenience(value)}
        data-test-automation-id="rating-popup-convenience"
      />
      <CriteriaBox
        name="rating-popup-feature"
        label="Feature and functionality *"
        tooltip={QualityRatingTooltips.Feature}
        value={store.featureRating}
        onChange={(e, value) => store.setFeature(value)}
        data-test-automation-id="rating-popup-feature"
      />
      <CriteriaBox
        name="rating-popup-support"
        label="Support and documentation *"
        tooltip={QualityRatingTooltips.Support}
        value={store.supportRating}
        onChange={(e, value) => store.setSupport(value)}
        data-test-automation-id="rating-popup-support"
      />
    </>
  );
};

export const RatingStep = observer(RatingStepPure);
