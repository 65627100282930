export interface BlockInterface {
  blockType: BlockType;
}

export type BlockType = 'header' | 'feature' | 'collection' | 'html';

export enum BlockTypeEnum {
  Header = 'header',
  Feature = 'feature',
  Collection = 'collection',
  Html = 'html',
}
