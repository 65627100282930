import React from 'react';
import { styled } from '@ringcentral/juno';

const SlideImage = styled.div`
  cursor: pointer;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
`;

export function Screenshot({ src }: { src: string }) {
  return (
    <SlideImage
      data-test-automation-id="carouselScreenshot"
      style={{ backgroundImage: `url(${src})` }}
    />
  );
}
