import React, { FC } from 'react';
import placeholderImage from '@apw/assets/images/feature-card-placeholder.png';
import { IPageCard } from '@apw/components/pageCard/typings';
import { useCompatibilityPagePath } from '@apw/routes/useCompatibilityPaths';
import { applyProximaNovaSemibold } from '@apw/shared/theme/utils/typography';
import { styled } from '@ringcentral/juno';
import { Link } from 'react-router-dom';
import { MultipleLineEllipsis } from '@drw/lib';

interface PageCardProps {
  page: IPageCard;
  className?: string;
  handleClick?: () => void;
  pathResolver?: (rawPath: string) => string;
}

export const PageCard: FC<PageCardProps> = ({
  page,
  className = '',
  handleClick,
  pathResolver,
}) => {
  const defaultPagePath = useCompatibilityPagePath(page.vanityUrl);

  const pagePath = () => {
    const path = page.path || defaultPagePath;
    return pathResolver ? pathResolver(path) : path;
  };

  const onPageClick = () => {
    if (handleClick) {
      handleClick();
    }
  };

  return (
    <StyledContainer className={className}>
      <StyledLink
        to={pagePath}
        onClick={onPageClick}
        data-test-automation-id="page-card-link"
      >
        <StyledPageImage
          style={{
            backgroundImage: `url("${page.imageUrl}")`,
          }}
          role="img"
          aria-label={`${page.name} image`}
          data-test-automation-id="page-card-image"
        >
          {!page.imageUrl && (
            <img
              src={placeholderImage}
              alt=""
              aria-hidden="true"
              data-test-automation-id="page-card-image-placeholder"
            />
          )}
        </StyledPageImage>
        <StyledPageInfo>
          <StyledPageTitle
            title={page.name}
            data-test-automation-id="page-card-name"
          >
            {page.name}
          </StyledPageTitle>
          {page.description && (
            <StyledPageDescription
              title={page.description}
              data-test-automation-id="page-card-desc"
            >
              <MultipleLineEllipsis linesToDisplay={2} lineHeightPx={24}>
                {page.description}
              </MultipleLineEllipsis>
            </StyledPageDescription>
          )}
        </StyledPageInfo>
        <StyledMaskLayer />
      </StyledLink>
    </StyledContainer>
  );
};

const StyledLink = styled(Link)`
  display: block;
  height: 100%;
  overflow: hidden;
  text-decoration: none;
`;

const StyledMaskLayer = styled.div`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 17, 56, 0.04);
  pointer-events: none;
`;

const StyledContainer = styled.div`
  border-radius: 8px;
  border: 1px solid rgb(225, 230, 234);
  width: 328px;
  position: relative;

  &:hover ${StyledMaskLayer} {
    display: block;
  }
`;

const StyledPageImage = styled.div`
  height: 176px;
  background-size: cover;
  background-position: 50%;
  display: flex;
  justify-content: center;
  align-items: stretch;
`;

const StyledPageInfo = styled.div`
  padding: 20px 16px 24px;
`;

const StyledPageTitle = styled.div`
  ${applyProximaNovaSemibold()}
  font-size: 22px;
  color: rgb(0, 17, 56);
  letter-spacing: 0.28px;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledPageDescription = styled.div`
  margin-top: 8px;
  font-size: 16px;
  color: rgb(40, 40, 41);
  letter-spacing: 0.25px;
`;
