const ensureActionKeys = ['Enter', ' '];

export const ensureAction = (
  event: KeyboardEvent,
  actionFn: () => void,
  actionKeys: string[] = ensureActionKeys,
) => {
  if (actionKeys.includes(event.key)) {
    event.preventDefault();
    actionFn();
    return true;
  }
  return false;
};
