import {
  RcTypography,
  RcRating,
  styled,
  palette2,
  createGlobalStyle,
} from '@ringcentral/juno';

export const OverallRating = styled(RcRating)`
  .icon {
    font-size: 32px;
  }

  .RcRating-iconEmpty {
    color: ${palette2('warning', 'f01')};
  }
`;

export const Overall = styled(RcTypography)`
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const RatingContainer = styled.div`
  margin-left: -8px;
  margin-bottom: 32px;
`;

export const Quality = styled(RcTypography)`
  margin-bottom: 16px;
`;

export const GlobalTooltipStyle = createGlobalStyle`
  body > [class~='RcTooltip-popper'][x-out-of-boundaries] {
    > [class~='RcTooltip-tooltip'] {
      visibility: hidden;
    }
  }
`;
