import React from 'react';
import suggestAppImg from '@apw/assets/images/suggest-app.svg';
import {
  suggestAppLink,
  trackSuggestApp,
} from '@apw/modules/search/suggestApp/suggestApp.service';
import { Button, Container, Description, Image, Link } from './SuggestApp.sc';

export const SuggestApp = ({ className = '' }) => {
  return (
    <Container className={`${className}`}>
      <Image
        symbol={suggestAppImg}
        data-test-automation-id="suggest-app-image"
      />
      <Description
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        data-test-automation-id="suggest-app-desc"
      >
        Can't find what you're looking for? <br /> Let us know.
      </Description>
      <Link
        href={suggestAppLink}
        target="_blank"
        rel="noreferrer"
        tabIndex={0}
        aria-label="Suggest an app"
        data-test-automation-id="suggest-app-link"
      >
        <Button
          onClick={() => trackSuggestApp()}
          color="text.primary"
          variant="outlined"
          tabIndex={-1}
          aria-hidden="true"
          data-test-automation-id="suggest-app-button"
        >
          Suggest an app
        </Button>
      </Link>
    </Container>
  );
};
