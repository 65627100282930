import { PartnerBadge } from '@apw/types/partnerBadge';

export const supportedPartnerBadgeConfig = {
  [PartnerBadge.PREMIER]: {
    id: PartnerBadge.PREMIER,
    displayName: PartnerBadge.PREMIER,
  },
  [PartnerBadge.AUTHORIZED]: {
    id: PartnerBadge.AUTHORIZED,
    displayName: PartnerBadge.AUTHORIZED,
  },
  [PartnerBadge.ELITE]: {
    id: PartnerBadge.ELITE,
    displayName: PartnerBadge.ELITE,
  },
};
