import React, { FC, useContext, useMemo } from 'react';
import { RcBadge, RcButton } from '@ringcentral/juno';
import { observer } from 'mobx-react';
import moment from 'moment';
import { StoreContext } from '@apw/lib/components/profileDetail/StoreContext';
import { useRelativeTime } from '@apw/lib/hooks/useRelativeTime';
import { IReviewItem, ReviewStatus } from '../typings/reviewItem.interface';
import {
  CreatedTime,
  LeftContainer,
  ReviewActions,
  ReviewContent,
  ReviewerName,
  ReviewerRating,
  RightContainer,
  StatusBadgeContainer,
  Wrapper,
} from './ReviewItem.sc';

interface IReviewItemProps {
  item: IReviewItem;
  isMyReview?: boolean;
  className?: string;
  onEdit?: (reviewId: string) => void;
  onDelete?: (reviewItem: IReviewItem) => void;
  'data-test-automation-id'?: string;
}

export const ReviewItem: FC<IReviewItemProps> = observer(
  ({
    item,
    isMyReview = false,
    className = '',
    onEdit,
    onDelete,
    'data-test-automation-id': dataTestAutomationId = 'review-item',
  }) => {
    const { id, status, userName, creationTime, overallRating, content } = item;
    const { translation, currentUserName } = useContext(StoreContext);

    const {
      you: YOU_LABEL,
      inactiveUser: INACTIVE_USER_LABEL,
      pending: PENDING_LABEL,
      editButton: EDIT_BUTTON_LABEL,
      deleteButton: DELETE_BUTTON_LABEL,
    } = translation.ratingsAndReviews.reviewItem;

    const creationTimeWithTimeErrorTolerated = useMemo(() => {
      return moment(creationTime).subtract(3, 'seconds').toISOString();
    }, [creationTime]);

    const relativeCreatedTime = useRelativeTime(
      creationTimeWithTimeErrorTolerated,
    );

    const statusBadgeContent = useMemo(() => {
      const mapping = {
        [ReviewStatus.Pending]: PENDING_LABEL,
      };

      return mapping[status];
    }, [status]);

    const CURRENT_USER_LABEL = currentUserName || YOU_LABEL;

    const reviewerName = useMemo(() => {
      const name = isMyReview ? CURRENT_USER_LABEL : userName;
      return name || INACTIVE_USER_LABEL;
    }, [isMyReview, userName]);

    return (
      <Wrapper
        data-test-automation-id={dataTestAutomationId}
        className={className}
      >
        <LeftContainer>
          <ReviewerName
            variant="subheading2"
            color="neutral.f06"
            data-test-automation-id="reviewer-name"
          >
            {reviewerName}
          </ReviewerName>
          <CreatedTime
            color="neutral.f04"
            data-test-automation-id="created-time"
          >
            {relativeCreatedTime}
          </CreatedTime>
        </LeftContainer>
        <RightContainer>
          <ReviewerRating
            value={overallRating}
            readOnly
            data-test-automation-id="reviewer-rating"
          />
          {isMyReview && statusBadgeContent && (
            <StatusBadgeContainer>
              <RcBadge
                color="action.grayLight"
                textColor="neutral.f01"
                data-test-automation-id="status-badge"
                badgeContent={statusBadgeContent}
                overlap="none"
                variant="standard"
              />
            </StatusBadgeContainer>
          )}
          <ReviewContent
            variant="subheading1"
            color="neutral.f06"
            data-test-automation-id="review-content"
          >
            {content}
          </ReviewContent>
          {isMyReview && (
            <ReviewActions>
              <RcButton
                variant="plain"
                color="action.primary"
                onClick={() => onEdit?.(id)}
                data-test-automation-id="review-edit-button"
              >
                {EDIT_BUTTON_LABEL}
              </RcButton>
              <RcButton
                variant="plain"
                color="action.primary"
                onClick={() => onDelete?.(item)}
                data-test-automation-id="review-delete-button"
              >
                {DELETE_BUTTON_LABEL}
              </RcButton>
            </ReviewActions>
          )}
        </RightContainer>
      </Wrapper>
    );
  },
);
