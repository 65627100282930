import React, { FC } from 'react';
import { PartnerAccountTooltip } from '@apw/modules/profile';
import { HeaderMobilePopup } from '@apw/shared';
import { rootStore, useStores } from '@apw/stores';
import { signIn } from '@apw/stores/user';
import { Close } from '@ringcentral/juno-icon';
import {
  ButtonContainer,
  ButtonHeader,
  ButtonWrapper,
  CloseButton,
  DialogContent,
  DialogTitle,
  Main,
} from './requireLoginOption.sc';

export type RequireLoginOptionContentPureProps = {
  title: string;
  onClose: () => void;
  onAfterSignIn: () => void;
  onGetFreeTrial: () => void;
};

const SignIn: FC<{ onAfterSignIn: () => void }> = ({ onAfterSignIn }) => {
  return (
    <ButtonContainer>
      <ButtonHeader>
        <span data-test-automation-id="require-login-dialog-sign-in-msg">
          You need a RingCentral or RingCentral <br /> partner account to
          continue.
        </span>
        <PartnerAccountTooltip automationId="require-login-dialog-sign-in-tooltip" />
      </ButtonHeader>
      <ButtonWrapper
        className="signInBtn"
        size="medium"
        onClick={() => {
          signIn(onAfterSignIn);
        }}
        data-test-automation-id="require-login-dialog-sign-in-button"
      >
        Sign in
      </ButtonWrapper>
    </ButtonContainer>
  );
};

const StartFreeTrial: FC<{ onGetFreeTrial: () => void }> = ({
  onGetFreeTrial,
}) => {
  return (
    <ButtonContainer>
      <ButtonHeader>
        <span data-test-automation-id="require-login-dialog-free-trial-msg">
          Don't have a RingCentral account yet? <br /> Sign up today for a free
          trial.
        </span>
      </ButtonHeader>
      <ButtonWrapper
        className="freeTrialBtn"
        size="medium"
        variant="outlined"
        onClick={onGetFreeTrial}
        data-test-automation-id="require-login-dialog-free-trial-button"
      >
        Start your free trial
      </ButtonWrapper>
    </ButtonContainer>
  );
};

const isRCBrand = (brandId: string) => {
  return rootStore.compatibilitiesStore.isRCBrand(brandId);
};

const DownloadLoginOptionContentPure = ({
  title,
  onClose,
  onAfterSignIn,
  onGetFreeTrial,
}: RequireLoginOptionContentPureProps) => {
  const { compatibilityStore } = useStores();

  return (
    <React.Fragment>
      <HeaderMobilePopup onBack={onClose} />
      <DialogTitle data-test-automation-id="require-login-dialog-title">
        {title}
        <CloseButton
          onClick={onClose}
          symbol={Close}
          data-test-automation-id="require-login-dialog-close-button"
        />
      </DialogTitle>
      <DialogContent>
        <Main>
          <SignIn onAfterSignIn={onAfterSignIn} />
          {isRCBrand(compatibilityStore.brandId) && (
            <StartFreeTrial onGetFreeTrial={onGetFreeTrial} />
          )}
        </Main>
      </DialogContent>
    </React.Fragment>
  );
};

export const RequireLoginOptionContent = DownloadLoginOptionContentPure;
