import { onMobile, useProximaNovaSemibold } from '@apw/core/sc';
import { RcIcon, styled } from '@ringcentral/juno';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 82px;
  border-top: 1px solid #cbcbcb;
  padding-top: 26px;
  font-size: 16px;
  font-weight: 600;
  ${useProximaNovaSemibold};

  ${onMobile} {
    margin-bottom: 48px;
  }
`;

export const LinkWrapper = styled.ul`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
`;

export const LinkItem = styled.li`
  width: 100%;
`;

export const LinkHref = styled.div`
  display: inline-flex;
  width: 100%;
  max-width: 100%;
  line-height: 26px;
`;

export const LinkIconWrapper = styled.a`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  height: 26px;
  margin-right: 12px;

  &:hover {
    & + .linkName {
      span {
        text-decoration: underline;
      }
    }
  }
`;

export const LinkIcon = styled(RcIcon)`
  align-items: center;
`;

export const LinkName = styled.span`
  flex: 0 1 auto;
  width: 100%;
  max-width: calc(100% - 28px);

  a {
    text-decoration: none;
  }

  span {
    overflow-wrap: break-word;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 26px !important;
    text-decoration: none;
    ${useProximaNovaSemibold}
  }
`;
