import React, { ReactNode } from 'react';
import {
  Header,
  HeaderTitle,
} from '@apw/shared/coveoSearch/shared/section/Section.sc';

const Section = (props: {
  children: ReactNode;
  title: string;
  isH1: boolean;
}) => {
  const titleA11yProps = props.isH1
    ? {
        role: 'heading',
        'aria-level': 1,
      }
    : {};
  return (
    <div>
      <Header {...titleA11yProps} data-test-automation-id="section-header">
        <HeaderTitle data-test-automation-id="section-title">
          {props.title}
        </HeaderTitle>
      </Header>
      {props.children}
    </div>
  );
};

export default Section;
