import { richTextFieldService } from '@apw/components/markdown/rich-text-field.service';
import {
  IProcessedProfile,
  IProcessLinks,
} from '@apw/components/profileDetail';
import { timeZoneService } from '@apw/core/timeZone';
import { TimeZone } from '@apw/core/timeZone/time-zone.interface';
import {
  productConfig,
  supportedCategoryConfig,
} from '@apw/modules/search/filterBar';
import {
  getCompatibilityProfilePath,
  getCompatibilitySearchPath,
} from '@apw/routes/getCompatibilityPaths';
import { rootStore } from '@apw/stores';
import { AppResourceLink, IProfile } from '@apw/types';
import { v4 as uuid } from 'uuid';

export const profileProcessor = (profile: IProfile): IProcessedProfile => {
  const processedProfile: IProcessedProfile = { ...profile };

  const slug = rootStore.compatibilitiesStore.getCompatibilityById(
    processedProfile.brand,
  )?.slug;
  const searchPath = getCompatibilitySearchPath(slug || '');
  processedProfile.categoryTags = handleCategoryTags(
    processedProfile.category,
    searchPath,
  );
  processedProfile.productTags = handleProductTags(
    processedProfile.product,
    searchPath,
  );
  processedProfile.partnerProfiles = handlePartnerVanityUrls(
    processedProfile.partnerVanityUrls,
  );
  processedProfile.helpfulLinks = handleHelpfulLinks(processedProfile);
  processedProfile.processResourceLinks = handleResourceLinks(
    processedProfile.resourcesLinks,
  );
  processedProfile.processRequirements = handleRequirements(
    processedProfile.requirements,
  );

  return processedProfile;
};

export const handleSupportAvailableTime = async (
  profile: IProcessedProfile,
): Promise<IProcessedProfile> => {
  if (!profile.support) {
    return profile;
  }

  profile.processSupport = {
    email: profile.support.email,
    contactPhone: profile.support.contactPhone,
    availableTime: {
      ...(profile.support.availableTime || {}),
      availableTimeZone: '',
    },
  };

  if (!profile.processSupport.availableTime.tz) {
    return profile;
  }

  const timezone: TimeZone = await timeZoneService
    .getTimeZone(profile.processSupport.availableTime.tz)
    .toPromise();

  if (!profile.processSupport) {
    return profile;
  }

  const result = timezone ? parseInt(timezone.bias, 10) / 60 : 0;

  profile.processSupport.availableTime.availableTimeZone =
    timeZoneService.formatTimeZone(result);

  return profile;
};

const handleHelpfulLinks = (profile: IProcessedProfile) => {
  const helpfulLinks: IProcessLinks[] = [];

  if (!profile) {
    return helpfulLinks;
  }

  let termsOfService;
  let privacyPolicy;
  let documentationLink;
  let publisherLink;
  let supporterLink;

  if (profile.termsOfService) {
    termsOfService = getHelpfulLinkItem(
      profile.termsOfService,
      'Terms of Service',
    );
  }

  if (profile.privacyPolicy) {
    privacyPolicy = getHelpfulLinkItem(profile.privacyPolicy, 'Privacy Policy');
  }

  if (profile.documentationLink) {
    documentationLink = getHelpfulLinkItem(
      profile.documentationLink,
      'Documentation',
    );
  }

  if (profile.publisherLink) {
    publisherLink = getHelpfulLinkItem(
      profile.publisherLink,
      'Publisher website',
    );
  }

  if (profile.supporterLink) {
    supporterLink = getHelpfulLinkItem(
      profile.supporterLink,
      'Support website',
    );
  }

  if (termsOfService) helpfulLinks.push(termsOfService);
  if (privacyPolicy) helpfulLinks.push(privacyPolicy);
  if (documentationLink) helpfulLinks.push(documentationLink);
  if (publisherLink) helpfulLinks.push(publisherLink);
  if (supporterLink) helpfulLinks.push(supporterLink);

  return helpfulLinks;
};

const getHelpfulLinkItem = (link: string, linkName: string) => {
  if (!link || !link.trim()) {
    return;
  }
  return {
    key: uuid(),
    label: linkName,
    link,
  };
};

const buildPartnerProfile = (brand, vanityUrl: string) => {
  return {
    brandName: brand.name,
    profileUrl: `${getCompatibilityProfilePath(brand.slug, vanityUrl)}`,
  };
};

const buildPartnerProfiles = (partnerVanityUrls: any, partnerBrands) => {
  const partnerProfiles: any[] = [];

  partnerBrands.forEach((brand) => {
    const vanityUrl = partnerVanityUrls[brand.id];
    const partnerProfile = buildPartnerProfile(brand, vanityUrl);
    partnerProfiles.push(partnerProfile);
  });

  return partnerProfiles;
};

const getPartnerProfiles = (partnerVanityUrls: any) => {
  if (!partnerVanityUrls) {
    return [];
  }

  const partnerBrands = rootStore.compatibilitiesStore.items.filter((brand) => {
    return !!partnerVanityUrls[brand.id];
  });

  return buildPartnerProfiles(partnerVanityUrls, partnerBrands);
};

const handlePartnerVanityUrls = (partnerVanityUrls) => {
  if (!partnerVanityUrls) {
    return [];
  }

  return getPartnerProfiles(partnerVanityUrls).map((item) => {
    return {
      ...item,
      key: uuid(),
    };
  });
};

const handleCategoryTags = (category: string, searchPath: string) => {
  if (!category || !category.trim()) {
    return undefined;
  }
  return category.split(';').map((item) => {
    const categoryItem = handleCategoryItem(item);
    return {
      tag: categoryItem.displayName,
      key: uuid(),
      href: `${searchPath}?appCategory=${encodeURIComponent(
        categoryItem.displayName,
      )}`,
    };
  });
};

const handleProductTags = (product: string[], searchPath: string) => {
  if (!product || !product.length) {
    return undefined;
  }
  return product.map((item) => {
    const productItem = handleSpecialProductItem(item);
    return {
      tag: productItem,
      key: uuid(),
      href: `${searchPath}?product=${encodeURIComponent(productItem)}`,
    };
  });
};

const handleSpecialProductItem = (product) => {
  const productItem = productConfig[product];
  return productItem ? productItem.displayName : product;
};

const handleCategoryItem = (category): { id: string; displayName: string } => {
  const item = supportedCategoryConfig[category];
  return item ? item : { id: category, displayName: category };
};

const handleResourceLinks = (resourceLinks: AppResourceLink[]) => {
  if (!resourceLinks || !resourceLinks.length) {
    return [];
  }
  return resourceLinks.map((item) => {
    return {
      label: item.label,
      link: item.link,
      key: uuid(),
    };
  });
};

const handleRequirements = (requirements: string[]) => {
  if (!requirements || !requirements.length) {
    return [];
  }
  return requirements.map((item) => {
    return {
      key: uuid(),
      requirement: richTextFieldService.convertToHtmlFormat(item),
    };
  });
};
