import { filter, take } from 'rxjs/operators';
import { getSiteKey, isGoogleRecaptchaLoaded } from './load-google-recaptcha';
import { GoogleRecaptchaOperator } from './typings/google-recaptcha-operator.interface';
import { RecaptchaRenderParams } from './typings/recaptcha-render-params.interface';

export class GoogleRecaptcha {
  private widgetId: string;

  constructor(
    private container: string | HTMLElement,
    private parameters?: RecaptchaRenderParams,
  ) {
    this.render();
  }

  getResponse(): string {
    // eslint-disable-next-line no-void
    if (this.widgetId === void 0) {
      return '';
    }

    return this.recaptcha.getResponse(this.widgetId);
  }

  reset() {
    // eslint-disable-next-line no-void
    if (this.widgetId === void 0) {
      return;
    }

    this.recaptcha.reset(this.widgetId);
  }

  private get recaptcha(): GoogleRecaptchaOperator {
    // @ts-ignore
    return window.grecaptcha;
  }

  private render() {
    isGoogleRecaptchaLoaded
      .pipe(
        filter((loaded: boolean) => loaded),
        take(1),
      )
      .subscribe(() => {
        this.widgetId = this.recaptcha.render(
          this.container,
          Object.assign(
            {
              sitekey: getSiteKey(),
            },
            this.parameters,
          ),
        );
      });
  }
}
