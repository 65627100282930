import React, { FC } from 'react';
import { BaseButton, BaseButtonProps } from '../baseButton';

type SecondaryButtonProps = Omit<BaseButtonProps, 'buttonStyle'>;

export const SecondaryButton: FC<SecondaryButtonProps> = ({
  onClick,
  className = '',
  children,
}) => {
  return (
    <BaseButton
      onClick={onClick}
      buttonStyle="outlined"
      className={`${className}`}
    >
      {children}
    </BaseButton>
  );
};
