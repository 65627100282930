// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.74.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.74.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profileLogo_profileLogo__2kEZ4{max-width:100%;max-height:100%;height:100%}.profileLogo_defaultProfileLogo__2-2_m{width:100%;height:100%}.profileLogo_defaultProfileLogo__2-2_m svg{width:100%;height:100%}", "",{"version":3,"sources":["webpack://./src/components/profileTile/shared/profileLogo.module.scss"],"names":[],"mappings":"AAAA,gCAAa,cAAc,CAAC,eAAe,CAAC,WAAW,CAAC,uCAAoB,UAAU,CAAC,WAAW,CAAC,2CAAwB,UAAU,CAAC,WAAW","sourcesContent":[".profileLogo{max-width:100%;max-height:100%;height:100%}.defaultProfileLogo{width:100%;height:100%}.defaultProfileLogo svg{width:100%;height:100%}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileLogo": "profileLogo_profileLogo__2kEZ4",
	"defaultProfileLogo": "profileLogo_defaultProfileLogo__2-2_m"
};
export default ___CSS_LOADER_EXPORT___;
