import React, { FC } from 'react';
import { styled } from '@ringcentral/juno';
import { IAppGalleryOption } from '../typings';
import { AppGalleryOptions } from './AppGalleryOptions';

const Wrapper = styled.div``;

interface IAppGalleryListProps {
  rcGallery: IAppGalleryOption;
  partnerGalleries: IAppGalleryOption[];
  currentGalleryBrandId: string;
  onChange;
}

export const AppGalleryList: FC<IAppGalleryListProps> = (props) => {
  const { rcGallery, partnerGalleries, currentGalleryBrandId, onChange } =
    props;

  return (
    <Wrapper
      data-test-automation-id="compatibilitySelectorAppGalleryList"
      role="list"
    >
      <AppGalleryOptions
        appGalleryOptions={[rcGallery]}
        currentGalleryBrandId={currentGalleryBrandId}
        onChange={onChange}
      />
      <AppGalleryOptions
        appGalleryOptions={partnerGalleries}
        currentGalleryBrandId={currentGalleryBrandId}
        onChange={onChange}
      />
    </Wrapper>
  );
};
