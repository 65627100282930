import { useMemo } from 'react';
import { brandBannersTexts } from '@apw/modules/page/constants';
import { useStores } from '@apw/stores';
import { ICompatibility } from '@apw/stores/compatibility';

export const useBrandBannerTexts = () => {
  const { compatibilityStore } = useStores();
  return useMemo(() => {
    const bannerTexts = getBrandBannersTexts(compatibilityStore.compatibility);
    const defaultBannerText = bannerTexts[0];
    return compatibilityStore.compatibility.headerBanners.map(
      (_, index) => bannerTexts[index] || defaultBannerText,
    );
  }, [compatibilityStore.compatibility.headerBanners]);
};

const getDefaultBannerText = (brandName: string) =>
  `${brandName} works where you work. Browser integrated business apps to drive productivity and save time for you and you business.`;

const getBrandBannersTexts = (brand: ICompatibility): string[] => {
  if (brandBannersTexts[brand.id]?.length) {
    return brandBannersTexts[brand.id];
  }
  return [getDefaultBannerText(brand.name)];
};
