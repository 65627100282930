import React from 'react';
import { useCompatibilityHomePath } from '@apw/routes/useCompatibilityPaths';
import { Previous } from '@ringcentral/juno-icon';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from './goBackButtom.sc';

export const GoBackButton = observer(() => {
  const homePath = useCompatibilityHomePath();
  const history = useHistory();

  const handleClick = () => {
    history.push(homePath);
  };

  return (
    <Button
      variant="text"
      color="text.primary"
      onClick={handleClick}
      data-test-automation-id="go-back-button"
    >
      <Icon
        symbol={Previous}
        size="medium"
        data-test-automation-id="go-back-button-icon"
      />
      <span>Back to Gallery</span>
    </Button>
  );
});
