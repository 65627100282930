import { styled } from '@ringcentral/juno';
import { RcDivider } from '@ringcentral/juno/components/Divider';
import {
  NoRatingsSection,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  INoRatingsSectionProps,
} from '@apw/lib/components/ratingAndReview/noRatingsSection/NoRatingsSection';
import { onMobile } from '@apw/lib/sc';

export const NoRatingsWrapper = styled.div``;

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 120px;

  ${onMobile} {
    margin-bottom: 80px;
  }
`;

export const StyledNoRatingsSection = styled(NoRatingsSection)`
  margin-top: 80px;

  ${onMobile} {
    margin-top: 40px;
  }
`;

export const Divider = styled(RcDivider)`
  &&& {
    margin-bottom: 44px;
  }
`;
