import React, { FC } from 'react';
import {
  Container,
  CountInfo,
  RatingContainer,
  Score,
  StyledRating,
} from '@apw/components/profileDetail/profileBody/ratingsAndReviewsTab/ratingsSection/content/basicInfo/basicInfo.sc';

export interface IBasicInfo {
  overallRating: number;
  voteCount: number;
}

export const BasicInfo: FC<IBasicInfo> = ({ overallRating, voteCount }) => {
  return (
    <Container data-test-automation-id={'rating-and-review-tab-basic-info'}>
      <Score
        variant="display3"
        color="neutral.f06"
        data-test-automation-id={'rating-and-review-tab-score'}
        tabIndex={0}
      >
        {overallRating.toFixed(1)}
      </Score>
      <RatingContainer>
        <StyledRating rating={overallRating} />
        <CountInfo
          variant="subheading1"
          color="neutral.f04"
          data-test-automation-id={'rating-and-review-tab-rating-count'}
          tabIndex={0}
        >
          Based on {voteCount} {voteCount > 1 ? 'ratings' : 'rating'}
        </CountInfo>
      </RatingContainer>
    </Container>
  );
};
