import React, { FC } from 'react';
import { BlockContent, BlockTypeEnum, CollectionBlock } from '@apw/components';
import { GalleryFeatureBlock } from '@apw/components/pageContent/galleryFeatureBlock';
import { Wrapper } from '@apw/components/pageContent/pageContent.sc';
import { HeaderBlock } from './headerBlock/HeaderBlock';
import { HtmlBlock } from './htmlBlock/HtmlBlock';

interface PageContentProps {
  content: BlockContent;
}

const PageContent: FC<PageContentProps> = ({ content }) => {
  const blockTypeMap = {
    [BlockTypeEnum.Header]: HeaderBlock,
    [BlockTypeEnum.Feature]: GalleryFeatureBlock,
    [BlockTypeEnum.Collection]: CollectionBlock,
    [BlockTypeEnum.Html]: HtmlBlock,
  };

  return (
    <Wrapper id="page-content" data-test-automation-id={`page-content`}>
      {content.map((block, index) => {
        const BlockTag = blockTypeMap[block.blockType];
        // @ts-ignore
        // eslint-disable-next-line react/no-array-index-key
        return <BlockTag data={block} key={index} indexInPageContent={index} />;
      })}
    </Wrapper>
  );
};

export { PageContent };
