import React, { FC } from 'react';
import {
  Container,
  Title,
} from '@apw/components/profileDetail/profileBody/overviewTab/ratingsAndReviews/ratingsAndReviews.sc';
import { RatingsSection } from '@apw/components/profileDetail/profileBody/overviewTab/ratingsAndReviews/ratingsSection/RatingsSection';
import { ActionButtons } from '@apw/components/ratingAndReview/actionButtons/ActionButtons';
import { NoRatingsSection } from '@apw/components/ratingAndReview/noRatingsSection/NoRatingsSection';
import { IAppRating } from '@apw/types';

export interface IRatingsAndReviewsProps {
  ratings?: IAppRating;
}

export const RatingsAndReviews: FC<IRatingsAndReviewsProps> = ({ ratings }) => {
  return (
    <Container>
      <Title data-test-automation-id={'ratings-and-review-title'}>
        RATINGS AND REVIEWS
      </Title>
      {ratings ? <RatingsSection ratings={ratings} /> : <NoRatingsSection />}
      <ActionButtons />
    </Container>
  );
};
