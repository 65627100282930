import {
  getCompatibilityGalleryPagePreviewPath,
  getCompatibilityHomePath,
  getCompatibilityMyAppsPath,
  getCompatibilityPagePath,
  getCompatibilityPartnerEcosystemPath,
  getCompatibilityProfilePath,
  getCompatibilitySearchPath,
} from '@apw/routes/getCompatibilityPaths';
import { useCurrentCompatibility } from '@apw/stores/compatibility';

export const useCompatibilityHomePath = (): string => {
  const compatibility = useCurrentCompatibility();
  return getCompatibilityHomePath(compatibility.slug);
};

export const useCompatibilityMyAppsPath = (): string => {
  const compatibility = useCurrentCompatibility();
  return getCompatibilityMyAppsPath(compatibility.slug);
};

export const useCompatibilitySearchPath = (): string => {
  const compatibility = useCurrentCompatibility();
  return getCompatibilitySearchPath(compatibility.slug);
};

export const useCompatibilityPartnerEcosystemPath = (): string => {
  const compatibility = useCurrentCompatibility();
  return getCompatibilityPartnerEcosystemPath(compatibility.slug);
};

export const useCompatibilityPagePath = (vanityUrl: string): string => {
  const compatibility = useCurrentCompatibility();
  return getCompatibilityPagePath(compatibility.slug, vanityUrl);
};

export const useCompatibilityProfilePath = (vanityUrl: string): string => {
  const compatibility = useCurrentCompatibility();
  return getCompatibilityProfilePath(compatibility.slug, vanityUrl);
};

export const useCompatibilityGalleryPagePreviewPath = (
  pageId: string,
): string => {
  const compatibility = useCurrentCompatibility();
  return getCompatibilityGalleryPagePreviewPath(compatibility.slug, pageId);
};
