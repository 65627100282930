import { transport } from '@apw/core';
import { urlParameterKeys } from '@apw/core/url';
import {
  RedirectionRules,
  RedirectionRulesFactory,
} from '@apw/hooks/redirectionRules';
import {
  getCategoryByIdOrName,
  getProductByIdOrName,
} from '@apw/modules/search/filterBar';
import { buildParamAppsArray } from '@apw/modules/search/filterBar/multipleAppsFilter';
import { getCompatibilitySearchPath } from '@apw/routes/getCompatibilityPaths';
import { rootStore } from '@apw/stores';
import { ICompatibility } from '@apw/stores/compatibility';
import { Category } from '@apw/types/category';
import * as qs from 'qs';

const buildCompatibilitySearchPath = () => {
  return window.location.pathname.replace('/apps', '');
};

export const redirectionRulesFactory: RedirectionRulesFactory<any> = ({
  queryParams,
  pathname,
}): RedirectionRules => {
  return [
    {
      test: () => {
        return !!queryParams[urlParameterKeys.COMPATIBILITY];
      },
      getTarget: () => {
        const compatibilityValue = queryParams[urlParameterKeys.COMPATIBILITY];
        const compatibility =
          rootStore.compatibilitiesStore.getCompatibilityByValue(
            compatibilityValue,
          );

        const newParams = {};
        Object.keys(queryParams).forEach((key) => {
          if (key === urlParameterKeys.APPS) {
            newParams[key] = queryParams[key];
            return;
          }

          if (key !== urlParameterKeys.COMPATIBILITY) {
            newParams[key] = encodeURIComponent(queryParams[key]);
          }
        });
        const searchString = qs.stringify(newParams, {
          addQueryPrefix: true,
          encode: false,
        });

        if (compatibility) {
          const pathSearch = getCompatibilitySearchPath(compatibility.slug);
          return `${pathSearch}${searchString}`;
        }

        return `${buildCompatibilitySearchPath()}${searchString}`;
      },
    },
    // `/unify-office/search?appCategory=AI&product=Video&q=123&partnerBadge=Elite&apps=rc-app` to `/search?apps=rc-app`
    {
      test: () => {
        return !!queryParams[urlParameterKeys.APPS];
      },
      getTarget: async () => {
        const vanityUrls = queryParams[urlParameterKeys.APPS]?.split(',');
        const appsArray = Array.isArray(vanityUrls) ? vanityUrls : [vanityUrls];
        const paramAppsArray = buildParamAppsArray(appsArray);
        const currentCompatibility =
          rootStore.compatibilitiesStore.resolve(pathname);
        let targetCompatibility: ICompatibility | undefined;

        if (rootStore.compatibilitiesStore.isRCBrand(currentCompatibility.id)) {
          const profile = await transport.fetchProfile(paramAppsArray[0]);

          targetCompatibility =
            rootStore.compatibilitiesStore.getCompatibilityById(profile.brand);
        } else {
          targetCompatibility = currentCompatibility;
        }

        const searchString = qs.stringify(
          {
            [urlParameterKeys.APPS]: paramAppsArray.join(','),
          },
          { addQueryPrefix: true, encode: false },
        );

        if (targetCompatibility) {
          const pathSearch = getCompatibilitySearchPath(
            targetCompatibility.slug,
          );
          return `${pathSearch}${searchString}`;
        }

        return `${buildCompatibilitySearchPath()}${searchString}`;
      },
    },

    // `?appCategory=AI&product=Video&q=123&partnerBadge=Elite` to `?partnerBadge=Elite`
    {
      test: () => {
        return !!queryParams[urlParameterKeys.PARTNER_BADGE];
      },
      getTarget: () => {
        const partnerBadges = queryParams[urlParameterKeys.PARTNER_BADGE];
        return `${buildCompatibilitySearchPath()}?${
          urlParameterKeys.PARTNER_BADGE
        }=${partnerBadges}`;
      },
    },

    // `/?product=Glip,Video&appCategory=Calendaring,AI` to `?product=Message&appCategory=Calendaring`
    {
      test: () => {
        return [urlParameterKeys.APP_CATEGORY, urlParameterKeys.PRODUCT].some(
          (key) => !!queryParams[key],
        );
      },
      getTarget: () => {
        const { productIdOrName, searchText } = parseFilterParams(queryParams);

        let { categoryIdOrName } = parseFilterParams(queryParams);
        categoryIdOrName = handleCategoryId(categoryIdOrName);

        const product = getProductByIdOrName(productIdOrName);
        const category = getCategoryByIdOrName(categoryIdOrName);

        const newSearchParams = {
          [urlParameterKeys.PRODUCT]: product
            ? encodeURIComponent(product.displayName)
            : productIdOrName,
          [urlParameterKeys.APP_CATEGORY]: category
            ? encodeURIComponent(category.displayName)
            : categoryIdOrName,
          [urlParameterKeys.SEARCH_TEXT]: searchText
            ? encodeURIComponent(searchText)
            : searchText,
        };

        const searchString = qs.stringify(newSearchParams, {
          addQueryPrefix: true,
          encode: false,
        });

        return `${buildCompatibilitySearchPath()}${searchString}`;
      },
    },
  ];
};

const specialCategoryMapping = {
  Calendering: Category.CALENDARING,
};

const handleCategoryId = (categoryId: string) => {
  if (specialCategoryMapping[categoryId]) {
    return specialCategoryMapping[categoryId];
  }
  return categoryId;
};

const parseFilterParams = (queryParams) => {
  const product = queryParams[urlParameterKeys.PRODUCT]
    ? queryParams[urlParameterKeys.PRODUCT].split(',')
    : undefined;
  const category = queryParams[urlParameterKeys.APP_CATEGORY]
    ? queryParams[urlParameterKeys.APP_CATEGORY].split(',')
    : undefined;
  const searchText = queryParams[urlParameterKeys.SEARCH_TEXT];

  const productIdOrName = Array.isArray(product) ? product[0] : product;
  const categoryIdOrName = Array.isArray(category) ? category[0] : category;

  return { productIdOrName, categoryIdOrName, searchText };
};
