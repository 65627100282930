import { useEffect, useState } from 'react';
import { isBotProfile } from '@apw/modules/botProvision';
import { isNotificationApp } from '@apw/modules/profile/shared/mnaService';
import { IProfile } from '@apw/types';

export const useProfileType = (profile: IProfile) => {
  const [isMna, setIsMna] = useState(false);
  const [isBot, setIsBot] = useState(false);
  const [isRegularApp, setIsRegularApp] = useState(false);

  useEffect(() => {
    const isMnaApp = isNotificationApp(profile);
    setIsMna(isMnaApp);
    isBotProfile(profile).then((result) => {
      setIsBot(result);
      setIsRegularApp(!isMnaApp && !result);
    });
  }, [profile]);

  return { isMna, isBot, isRegularApp };
};
