import { RcThemeInput } from '@ringcentral/juno';
import {
  overridesFactory,
  paletteFactory,
} from '../../../../../shared/theme/utils';

export const versatelColor = '#134094';

export const versatelTheme: RcThemeInput = {
  palette: paletteFactory(versatelColor),
  overrides: overridesFactory(versatelColor),
};
