import { action, computed, observable } from 'mobx';
import { RatingPopupOption } from '../typings/ratingPopup.interface';

export class RatingPopupStore {
  @observable overallRating: RatingPopupOption['overallRating'];

  @observable valueRating: RatingPopupOption['valueRating'];

  @observable convenienceRating: RatingPopupOption['convenienceRating'];

  @observable featureRating: RatingPopupOption['featureRating'];

  @observable supportRating: RatingPopupOption['supportRating'];

  @observable content: RatingPopupOption['content'];

  @observable dirty = false;

  constructor({
    overallRating = null,
    valueRating = null,
    convenienceRating = null,
    featureRating = null,
    supportRating = null,
    content = '',
  }: Partial<RatingPopupOption> = {}) {
    this.overallRating = overallRating;
    this.valueRating = valueRating;
    this.convenienceRating = convenienceRating;
    this.featureRating = featureRating;
    this.supportRating = supportRating;
    this.content = content;
  }

  @computed get review() {
    return {
      overallRating: this.overallRating,
      valueRating: this.valueRating,
      convenienceRating: this.convenienceRating,
      featureRating: this.featureRating,
      supportRating: this.supportRating,
      content: this.content.trim(),
    };
  }

  @computed get valid() {
    return (
      !!this.overallRating &&
      !!this.valueRating &&
      !!this.convenienceRating &&
      !!this.featureRating &&
      !!this.supportRating
    );
  }

  @action setVoteStar(rating: number | null) {
    this.overallRating = rating;
    this.dirty = true;
  }

  @action setValue(rating: number | null) {
    this.valueRating = rating;
    this.dirty = true;
  }

  @action setConvenience(rating: number | null) {
    this.convenienceRating = rating;
    this.dirty = true;
  }

  @action setFeature(rating: number | null) {
    this.featureRating = rating;
    this.dirty = true;
  }

  @action setSupport(rating: number | null) {
    this.supportRating = rating;
    this.dirty = true;
  }

  @action setContent(review: string) {
    this.content = review;
    this.dirty = true;
  }
}
