import { get } from '@apw/core/api/api.service';
import {
  IPagesForAllBrandsResponse,
  PagesInterface,
} from '@apw/types/pages.interface';

export const loadPages = (brandId: string): Promise<PagesInterface> => {
  const endpoint = `/api/pages/brand/${brandId}`;
  return get<PagesInterface>(endpoint, { useGlobalLoading: true });
};

export const loadPagesForAllBrands =
  (): Promise<IPagesForAllBrandsResponse> => {
    const endpoint = `/api/pages`;
    return get<IPagesForAllBrandsResponse>(endpoint, {
      useGlobalLoading: true,
    });
  };

export const loadGalleryPagesForAllBrands =
  (): Promise<IPagesForAllBrandsResponse> => {
    const endpoint = `/api/gallery-pages`;
    return get<IPagesForAllBrandsResponse>(endpoint, {
      useGlobalLoading: true,
    });
  };
