import React, {
  createRef,
  MutableRefObject,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { EMPTY, fromEvent, merge, Observable, Subject } from 'rxjs';
import { interval as rxInterval } from 'rxjs/internal/observable/interval';
import {
  filter,
  map,
  repeat,
  repeatWhen,
  switchMap,
  take,
  takeUntil,
  tap,
  throttleTime as rxThrottleTime,
} from 'rxjs/operators';
import { useDerivedState } from './useDerivedState';
import { useInnerWidth } from './useInnerWidth';
import { useResizeObserver } from './useResizeObserver';
import { useSubscribe } from './useSubscribe';

export interface Breakpoints {
  [key: number]: Breakpoint;
}

// Responsive prop should in Breakpoint property and UI Component custom behavior
export interface Breakpoint {
  size: number;
  [propName: string]: any;
}

type Result = {
  index: number;
  pageIndex: number;
  pageTotal: number;
  breakpoint: Breakpoint;
  canPrevious: boolean;
  canNext: boolean;
  go: (index) => void;
  goPage: (index) => void;
  next: () => void;
  previous: () => void;
  slides: ReactNode[];
};

const computeIndex = (
  index: number,
  count: number,
  size: number,
  loop: boolean,
) => {
  if (loop) return index;
  if (index < 1) return 1;
  const indexForLatestPage = count - size + 1;
  return index <= indexForLatestPage ? index : indexForLatestPage;
};

const computeBreakpoint = (breakpoints: Breakpoints, width: number) => {
  let maxKey = 0;
  const bp: Breakpoints = { 0: { size: 1 }, ...breakpoints };
  Reflect.ownKeys(bp).forEach((key) => {
    const k = Number(key);
    if (k <= width && k >= maxKey) maxKey = k;
  });
  return bp[maxKey];
};

export type ResizeObservable = 'window' | 'container';

interface Options {
  breakpoints?: Breakpoints;
  resize?: ResizeObservable;
  resizeRef?: RefObject<HTMLElement>;
  initialIndex?: number;
  interval?: number;
  draggable?: boolean;
  dragRef?: MutableRefObject<HTMLElement | null>;
  keyboard?: boolean;
  loop?: boolean;
  throttleTime?: number;
  children?: ReactNode | ReactNode[];
}

export const useCarousel = ({
  breakpoints = {},
  resize = 'window',
  resizeRef = createRef<HTMLElement>(),
  initialIndex = 1,
  interval = 0,
  draggable = false,
  dragRef,
  keyboard = false,
  loop = false,
  throttleTime = 0,
  children = [],
}: Options = {}): Result => {
  const innerWidth = useInnerWidth();
  const elementWidth = useResizeObserver(resizeRef);
  const breakpoint = useMemo(() => {
    const width = resize === 'window' ? innerWidth : elementWidth;
    return computeBreakpoint(breakpoints, width);
  }, [breakpoints, innerWidth, elementWidth]);

  const count = useMemo(() => React.Children.count(children), [children]);
  const slides = useMemo(() => {
    const array = React.Children.toArray(children);
    if (!loop) return array;
    if (array.length < breakpoint.size) {
      throw new Error(
        'Cannot loop because the length of "children" is less than "size" prop',
      );
    }
    return [...array, ...array, ...array, ...array.slice(0, -1)];
  }, [loop, breakpoint, children]);

  const [index, setIndex] = useDerivedState<number>(
    loop ? initialIndex + count : initialIndex,
  );

  const pageIndex = useMemo(
    () => Math.ceil((index - 1) / breakpoint.size) + 1,
    [index, breakpoint],
  );

  const pageTotal = useMemo(
    () => Math.ceil(count / breakpoint.size),
    [count, breakpoint],
  );

  const goPage = useCallback(
    (i) => go$.current.next((i - 1) * breakpoint.size + 1),
    [breakpoint],
  );

  const canPrevious = useMemo(() => {
    return loop ? true : pageIndex > 1;
  }, [pageIndex]);
  const canNext = useMemo(() => {
    return loop ? true : pageIndex < pageTotal;
  }, [pageIndex, pageTotal]);

  const go$ = useRef(new Subject());
  const go = useCallback((i) => go$.current.next(i), []);
  useSubscribe(
    () => go$.current,
    (i) => {
      setIndex(computeIndex(i, count, breakpoint.size, loop));
    },
  );

  const arrow$ = useRef(new Subject());
  useEffect(() => {
    const arr$ = loop
      ? arrow$.current.pipe(rxThrottleTime(throttleTime))
      : arrow$.current;
    arr$.subscribe(go$.current);
  }, [loop]);

  const next = useCallback(() => {
    arrow$.current.next(index + breakpoint.size);
  }, [index, breakpoint, go]);

  const previous = useCallback(() => {
    arrow$.current.next(index - breakpoint.size);
  }, [index, breakpoint, go]);

  useSubscribe(
    () => {
      let drag$: Observable<boolean> = EMPTY;
      let interval$: Observable<boolean> = EMPTY;
      let keyboard$: Observable<boolean> = EMPTY;
      if (draggable) {
        if (!dragRef) {
          throw new Error('The "dragRef" prop is undefined');
        }
        if (dragRef.current) {
          const start$ = fromEvent<TouchEvent>(dragRef.current, 'touchstart');
          const end$ = fromEvent<TouchEvent>(dragRef.current, 'touchend');
          const down$ = fromEvent<MouseEvent>(dragRef.current, 'mousedown');
          const up$ = fromEvent<MouseEvent>(dragRef.current, 'mouseup');
          const click$ = fromEvent<MouseEvent>(dragRef.current, 'click');

          const touchdrag$ = start$.pipe(
            switchMap((start) =>
              end$.pipe(
                map(
                  (end) =>
                    start.touches[0].clientX - end.changedTouches[0].clientX,
                ),
              ),
            ),
          );

          const mousedrag$ = down$.pipe(
            switchMap((start) =>
              up$.pipe(map((end) => start.clientX - end.clientX)),
            ),
          );

          const preventClickEvent = () => {
            click$.pipe(take(1)).subscribe((e) => {
              e.stopImmediatePropagation();
            });
          };

          drag$ = merge(touchdrag$, mousedrag$).pipe(
            filter((distance) => Math.abs(distance) > 10),
            map((distance) => distance > 0),
            tap(preventClickEvent),
            take(1),
            repeat(),
          );
        }
      }

      if (interval) {
        let enter$: Observable<MouseEvent> = EMPTY;
        let leave$: Observable<MouseEvent> = EMPTY;
        if (dragRef && dragRef.current) {
          enter$ = fromEvent<MouseEvent>(dragRef.current, 'mouseenter');
          leave$ = fromEvent<MouseEvent>(dragRef.current, 'mouseleave');
        }
        interval$ = rxInterval(interval).pipe(
          map(() => true),
          takeUntil(go$.current),
          repeat(),
          takeUntil(enter$),
          repeatWhen(() => leave$),
        );
      }

      if (keyboard) {
        const keys = ['ArrowRight', 'ArrowLeft'];
        const keydown$ = fromEvent<KeyboardEvent>(document, 'keydown');
        keyboard$ = keydown$.pipe(
          filter((event) => keys.includes(event.key)),
          map((event) => event.key === 'ArrowRight'),
        );
      }
      const change$ = merge(drag$, interval$, keyboard$);
      return loop ? change$.pipe(rxThrottleTime(throttleTime)) : change$;
    },
    (isNext) => {
      const action = isNext ? next : previous;
      action();
    },
    [dragRef?.current, loop],
  );

  return {
    index,
    pageTotal,
    pageIndex,
    breakpoint,
    canPrevious,
    canNext,
    go,
    goPage,
    next,
    previous,
    slides,
  };
};
