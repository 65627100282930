import {
  palette2,
  RcLinearProgress,
  RcTypography,
  styled,
} from '@ringcentral/juno';
import { JUNO_THEME_PREFIX } from '@apw/lib/constants';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const StarsNumber = styled(RcTypography)`
  margin-right: 5px;
  width: 10px;
  text-align: center;
`;

export const ProgressBar = styled(RcLinearProgress)`
  &&& {
    margin-left: 12px;
    margin-right: 16px;
    border: 0;
    height: 10px;
    border-radius: 20px;
    width: 120px;
    background-color: ${palette2('neutral', 'b03')};

    .${JUNO_THEME_PREFIX}-MuiLinearProgress-bar {
      background-color: ${palette2('interactive', 'f01')};
      border-radius: 20px;
    }
  }
`;

export const Percentage = styled(RcTypography)`
  width: 25px;
`;
