import { useEffect } from 'react';
import { getCompatibilityHomePath } from '@apw/routes/getCompatibilityPaths';
import { useStores } from '@apw/stores';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

interface IPartnerGalleryGuardOptions {
  enabled: boolean;
}

export const usePartnerGalleryGuard = (
  options: IPartnerGalleryGuardOptions,
) => {
  const { pagesStore, compatibilityStore, compatibilitiesStore } = useStores();
  const history = useHistory();
  const { enabled } = options;

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const rcCompatibility = compatibilitiesStore.rcCompatibility;

    if (compatibilityStore.compatibility === rcCompatibility) {
      return;
    }

    if (isEmpty(pagesStore.pagesForAllBrands)) {
      return;
    }

    if (!pagesStore.hasVisiblePages(compatibilityStore.brandId)) {
      const path = getCompatibilityHomePath(rcCompatibility?.slug || '');
      history.replace(path);
    }
  }, [pagesStore.pagesForAllBrands, compatibilityStore.compatibility]);
};
