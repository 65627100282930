import React, { FC } from 'react';
import { BaseButtonContainer } from '@apw/components/button/baseButton/baseButton.sc';

export interface BaseButtonProps {
  onClick?: (event) => void;
  className?: string;
  children: any;
  buttonStyle: 'contained' | 'outlined';
  disabled?: boolean;
  ariaLabel?: string;
  ariaExpanded?: 'true' | 'false';
}

export const BaseButton: FC<BaseButtonProps> = ({
  onClick,
  className = '',
  children,
  buttonStyle,
  disabled = false,
  ariaLabel,
  ariaExpanded,
}) => {
  return (
    <BaseButtonContainer
      variant={buttonStyle}
      color="text.primary"
      onClick={onClick}
      className={`${className}`}
      disabled={disabled}
      aria-label={ariaLabel}
      aria-expanded={ariaExpanded}
      data-test-automation-id={`base-button`}
    >
      {children}
    </BaseButtonContainer>
  );
};
