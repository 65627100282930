import React, { useContext } from 'react';
import { ProfileContext } from '@apw/components/profileDetail/profile.context';
import { styled } from '@ringcentral/juno';

const mobile = '767px';

const Wrapper = styled.div`
  width: 100%;
`;

const SectionTitle = styled.h2`
  margin: 0 0 16px;
  font-size: 22px;
  font-weight: 600;
  color: #001138;
`;

const SectionDesc = styled.p`
  margin: 0 0 20px;
  font-size: 16px;
  color: #282829;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: #282829;

  thead,
  tr {
    border-bottom: 1px solid #e5e5e5;
  }

  tr:last-child {
    border-bottom-width: 0;
  }

  td {
    padding: 17px 0 16px 16px;
  }

  th:first-child,
  td:first-child {
    width: 176px;
  }
  @media (max-width: ${mobile}) {
    th:first-child,
    td:first-child {
      width: 128px;
    }
  }
`;

const Thead = styled.thead`
  background-color: #f3f3f3;

  th {
    padding: 8px 0 8px 16px;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    word-wrap: break-word;
    word-break: break-word;
  }
`;

const Tr = styled.tr`
  &:hover {
    background-color: rgba(102, 102, 102, 0.08);
  }

  td {
    font-size: 16px;
    line-height: 24px;
    word-wrap: break-word;
    word-break: break-word;
    vertical-align: baseline;
  }
`;

export const Permissions = () => {
  const profile = useContext(ProfileContext);
  const permissionList = profile.permissions || [];

  if (!permissionList.length) {
    return null;
  }

  return (
    <Wrapper>
      <SectionTitle data-test-automation-id="technical-detail-permissions-title">
        App Scopes
      </SectionTitle>
      <SectionDesc data-test-automation-id="technical-detail-permissions-desc">
        When authorized, this app will be granted the following scopes:
      </SectionDesc>
      <Table>
        <Thead>
          <tr aria-hidden="true">
            <th data-test-automation-id="technical-detail-permissions-table-th-permission">
              Scope
            </th>
            <th data-test-automation-id="technical-detail-permissions-table-th-desc">
              Description
            </th>
          </tr>
        </Thead>
        <tbody>
          {permissionList.map((item) => (
            <Tr key={item.id}>
              <td
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
                aria-label={`scope is ${item.name}`}
                data-test-automation-id="technical-detail-permissions-table-td-permission"
              >
                {item.name}
              </td>
              <td
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
                aria-label={`description is ${item.description}`}
                data-test-automation-id="technical-detail-permissions-table-td-desc"
              >
                {item.description}
              </td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
};
