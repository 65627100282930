import { get } from '@apw/core/api/api.service';
import {
  ProfileListResponseInterface,
  ProfileListSearchParams,
} from '@apw/types';
import { ProfileListRequestInterface } from '@apw/types/profileListRequest.interface';

export const searchProfiles = (
  request: ProfileListRequestInterface,
  options?: { useGlobalLoading?: boolean },
) => {
  const params = getCommonParams({
    page: request.page || 1,
    perPage: request.perPage || 24,
  });
  request = Object.assign(request, params);
  return getProfileList(request, options);
};

const getProfileList = (params, options?: { useGlobalLoading?: boolean }) => {
  const endpoint = '/api/app';
  return get<ProfileListResponseInterface>(endpoint, {
    useGlobalLoading:
      options?.useGlobalLoading !== undefined
        ? options.useGlobalLoading
        : params.page === 1,
    retries: 1,
    params,
  });
};

const getCommonParams = (customParams?): ProfileListSearchParams => {
  const defaultParams: ProfileListSearchParams = {
    perPage: 9,
    page: 1,
    sortBy: ['priority', 'releaseTime', 'appGalleryName'],
    sortOrder: ['desc', 'desc', 'asc'],
  };

  const requestParams: ProfileListSearchParams = {
    ...defaultParams,
    ...(customParams || {}),
  };

  return {
    perPage: requestParams.perPage,
    page: requestParams.page,
    sortBy: (requestParams.sortBy || []).map((name) => name),
    sortOrder: (requestParams.sortOrder || []).map((order) => order),
  };
};
