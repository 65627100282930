import React, { FC } from 'react';
import { useCompatibilityHomePath } from '@apw/routes/useCompatibilityPaths';
import {
  ButtonStyled,
  LinkStyled,
} from '@apw/shared/brokenLinkIndicator/goToAppGallery/GoToAppGallery.sc';

export const GoToAppGallery: FC = () => {
  const homePath = useCompatibilityHomePath();

  return (
    <LinkStyled
      to={homePath}
      tabIndex={0}
      aria-label="Go to App Gallery"
      data-test-automation-id="go-to-app-gallery"
    >
      <ButtonStyled
        color="text.primary"
        variant="outlined"
        aria-hidden="true"
        tabIndex={-1}
      >
        Go to App Gallery
      </ButtonStyled>
    </LinkStyled>
  );
};
