import React, { useContext } from 'react';
import {
  BigBlueButtonInterface,
  IProcessedProfile,
} from '@apw/components/profileDetail';
import { ProfileOptionsContext } from '@apw/components/profileDetail/profile-options.context';
import { ProfileContext } from '@apw/components/profileDetail/profile.context';
import { MnaButton } from '@apw/components/profileDetail/profileHeader/ctaButton/mnaInstallButton/mnaInstallButton.sc';
import { trackBigBlueButtonClicked } from '@apw/components/profileDetail/profileHeader/ctaButton/shared/ctaButtonService';
import { safeWindowOpen } from '@apw/core';
import { useUserCompatibility } from '@apw/hooks';
import { useStores } from '@apw/stores';
import { BigBlueButtonStateEnum } from '@apw/types/bigBlueButtonState.enum';
import { observer } from 'mobx-react';
import { buildUrl } from './mnaInstallService';

export const MnaInstallButton = observer(() => {
  const profile = useContext(ProfileContext);
  const { isPreviewMode } = useContext(ProfileOptionsContext);
  const { userStore } = useStores();
  const { isUserIncompatibleWithCurrentGallery } = useUserCompatibility();

  return (
    <MnaButton
      onClick={() => launchJupiter(profile, userStore.isLoggedIn)}
      disabled={isPreviewMode || isUserIncompatibleWithCurrentGallery}
      data-test-automation-id={`mna-install-button`}
    >
      Install app
    </MnaButton>
  );
});

const launchJupiter = (profile: IProcessedProfile, isLogin: boolean): void => {
  const appId = profile.appId as string;
  const url = buildUrl(appId, profile.brand);

  if (!url) {
    return;
  }

  safeWindowOpen(url);
  trackViaAnalysisTools(profile, url, isLogin);
};

const trackViaAnalysisTools = (
  profile: IProcessedProfile,
  url: string,
  isLogin: boolean,
) => {
  const buttonMsg: BigBlueButtonInterface = {
    desc: 'Install app',
    buttonState: BigBlueButtonStateEnum.PRIMARY,
  };
  trackBigBlueButtonClicked(profile, buttonMsg, isLogin);
};
