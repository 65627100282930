import React from 'react';
import { palette2, styled } from '@ringcentral/juno';
import {
  RequirementHeader,
  RequirementItem,
  RequirementList,
  RequirementsWrapper,
} from './requirements.sc';

const Requirements = ({ className = '', requirements }) => {
  if (
    !requirements ||
    !requirements.length ||
    !requirements.filter((item) => item.requirement && item.requirement.trim())
      .length
  ) {
    return null;
  }

  const itemContent = (item) => {
    const key = item.key;
    const content = { __html: item.requirement };
    return (
      // eslint-disable-next-line react/no-danger
      <RequirementItem
        data-test-automation-id={`requirements-item`}
        key={key}
        dangerouslySetInnerHTML={content}
      />
    );
  };

  return (
    <RequirementsWrapper className={`${className}`}>
      <RequirementHeader>Requirements</RequirementHeader>
      <RequirementList data-test-automation-id={`requirements-list`}>
        {requirements.map((item) => {
          return itemContent(item);
        })}
      </RequirementList>
    </RequirementsWrapper>
  );
};

const StyledRequirements = styled(Requirements)`
  a {
    color: ${palette2('text', 'secondary')};
  }
`;

export { StyledRequirements as Requirements };
