import React, { FC } from 'react';
import { IProfile } from '@apw/types';

interface IProfileNameProps {
  profile: Partial<IProfile>;
  [x: string]: any;
}

export const ProfileName: FC<IProfileNameProps> = ({ profile, ...others }) => {
  const name = profile.applicationDisplayName || profile.appName;

  return (
    <div title={name} data-test-automation-id="profileName" {...others}>
      {name}
    </div>
  );
};
