import React from 'react';
import IpaasIcon from '@apw/components/ipaasTile/IpaasIcon/IpaasIcon';
import { IpaasListItem } from '@apw/types';
import { ExternalLink } from '@ringcentral/juno-icon';
import {
  CardContainer,
  Content,
  Description,
  ExternalLinkIcon,
  IconContainer,
  PlatformIndicator,
  PlatformName,
  Title,
} from './IpaasCard.sc';

export interface IpaasCardProps {
  app: IpaasListItem;
}

const IpaasCard = (props: IpaasCardProps) => {
  const { app } = props;

  const capitalizeStr = (str: string) => {
    if (str) {
      const capital = str.charAt(0).toUpperCase();
      str = capital + str.substring(1);
    }
    return str;
  };
  const title = `${app.rcAppName} x ${
    app.thirdPartyAppName
  } powered by ${capitalizeStr(app.platform)}`;
  const description = `Connect ${app.rcAppName} and ${
    app.thirdPartyAppName
  } through the logic of ${capitalizeStr(app.platform)}`;

  return (
    <CardContainer href={app.url} target="_blank" rel="noreferrer">
      <IconContainer>
        <IpaasIcon
          app={app}
          title={title}
          data-test-automation-id="ipaas-icon"
        />
      </IconContainer>
      <Content>
        <Title title={title} data-test-automation-id="ipaas-title">
          {title}
        </Title>
        <Description
          title={description}
          data-test-automation-id="ipaas-description"
        >
          {description}
        </Description>
        <PlatformIndicator data-test-automation-id="ipaas-platform-indicator">
          <span>
            visit <PlatformName>{app.platform}</PlatformName>
          </span>
          <ExternalLinkIcon
            symbol={ExternalLink}
            data-test-automation-id="ipaas-external-link-icon"
          />
        </PlatformIndicator>
        <div />
      </Content>
    </CardContainer>
  );
};

export default IpaasCard;
