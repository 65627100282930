import React from 'react';
import { decodeBrand } from '@apw/core';
import * as _ from 'lodash';
import { Helmet } from 'react-helmet';
import sanitizeHtml from 'sanitize-html';

export const sanitize = (value: string | undefined): string | undefined => {
  if (!value) {
    return;
  }
  return sanitizeHtml(value, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
  });
};

export interface IOpenGraphTag {
  title: string;
  type: string;
  image: string;
  url: string;
  description: string;
  site_name: string;
}

export interface ITwitterCardTag {
  card: string;
  title: string;
  image: string;
  description: string;
  site: string;
  creator: string;
}

export interface ISEOProps {
  title?: string | null;
  titleSuffix?: boolean;
  metaDescription?: string | null;
  ogTag?: IOpenGraphTag;
  twitterTag?: ITwitterCardTag;
  linkedData?: string;
  canonical?: string;
}

export const SEO = (props: ISEOProps) => {
  const {
    title,
    metaDescription,
    ogTag,
    twitterTag,
    linkedData,
    canonical,
    titleSuffix = true,
  } = props;

  return (
    <Helmet>
      {title && (
        <title>
          {`${decodeBrand(sanitize(title))}${
            titleSuffix ? ' | RingCentral App Gallery' : ''
          }`}
        </title>
      )}
      {metaDescription && (
        <meta
          name="description"
          content={decodeBrand(sanitize(metaDescription))}
        />
      )}
      {ogTag &&
        !_.isEmpty(ogTag) &&
        Object.keys(ogTag).map((tag) => (
          <meta
            property={`og:${sanitize(tag)}`}
            content={sanitize(ogTag[tag])}
            key={tag}
          />
        ))}
      {twitterTag &&
        !_.isEmpty(twitterTag) &&
        Object.keys(twitterTag).map((tag) => (
          <meta
            name={`twitter:${sanitize(tag)}`}
            content={sanitize(twitterTag[tag])}
            key={tag}
          />
        ))}
      {linkedData && <script type="application/ld+json">{linkedData}</script>}
      {_.isString(canonical) && (
        <link
          rel="canonical"
          href={`https://www.ringcentral.com/apps/${canonical}`}
        />
      )}
    </Helmet>
  );
};
