import { Compatibility } from '../../compatibility.enum';

export const BrandId2ShortNameMapping = {
  [Compatibility.RINGCENTRAL]: 'RingCentral',
  [Compatibility.ATT]: 'AT&T',
  [Compatibility.ALCATEL]: 'Alcatel',
  [Compatibility.ATOS]: 'Atos',
  [Compatibility.AVAYA]: 'Avaya',
  [Compatibility.BT]: 'BT',
  [Compatibility.VERIZON]: 'Verizon',
  [Compatibility.TELUS]: 'Telus',
  [Compatibility.VODAFONE]: 'Vodafone',
  [Compatibility.MCM]: 'MCM',
  [Compatibility.ECOTEL]: 'Ecotel',
  [Compatibility.EASTLINK]: 'Eastlink',
  [Compatibility.VERSATEL]: 'Versatel',
  [Compatibility.TELEKOM_DT]: 'Telekom (DT)',
  [Compatibility.UNIFY_OFFICE_DT_ATOS]: 'Unify Office (DT/ATOS)',
  [Compatibility.FRONTIER]: 'Frontier',
};
