import {
  cardDescription,
  cardTitle,
} from '@apw/shared/coveoSearch/shared/contentView/contentView.sc';
import { ProfileName } from '@apw/shared/profile';
import { RcIcon, styled } from '@ringcentral/juno';
import { Link } from 'react-router-dom';

export const CardContainer = styled(Link)`
  display: flex;
  padding: 14px 16px;
  text-decoration: none;

  &:hover {
    background: #f5f6f7;
  }
`;

export const IconContainer = styled.div`
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 12px;
`;

export const Content = styled.div`
  overflow: hidden;
`;

export const TitleBox = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled(ProfileName)`
  ${cardTitle}
  width: auto;
`;

export const TitleIcon = styled(RcIcon)`
  margin-left: 6px;
`;

export const Description = styled.div`
  ${cardDescription}
`;
