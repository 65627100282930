import React, { ReactElement } from 'react';
import { GoBackButton } from '@apw/shared/navigationBar/goBackButton';
import { observer } from 'mobx-react';
import { Container, Content } from './navigationBar.sc';

interface NavigationBarProps {
  leftContent?: ReactElement;
  rightContent?: ReactElement;
  cssProp?: any;
}

export const NavigationBar = observer((props: NavigationBarProps) => {
  const { leftContent = <GoBackButton />, rightContent, cssProp = '' } = props;

  return (
    <Container css={cssProp} data-test-automation-id="navigation-bar-container">
      <Content data-test-automation-id="navigation-bar-content">
        {leftContent}
        {rightContent}
      </Content>
    </Container>
  );
});
