import { GalleryPageSection } from '@apw/stores/pages/galleryPageSection.enum';

export const galleryPageSections = {
  [GalleryPageSection.DISCOVER_APPS]: {
    sectionId: GalleryPageSection.DISCOVER_APPS,
    displayName: 'Discover Apps',
  },
  [GalleryPageSection.PRODUCTS]: {
    sectionId: GalleryPageSection.PRODUCTS,
    displayName: 'Products',
  },
  [GalleryPageSection.CATEGORIES]: {
    sectionId: GalleryPageSection.CATEGORIES,
    displayName: 'Categories',
  },
  [GalleryPageSection.HIDDEN]: {
    sectionId: GalleryPageSection.HIDDEN,
    displayName: 'Hidden',
  },
};
