import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  BlockType,
  FeatureOptionEnum,
  FeatureTypeEnum,
  IContentBlocks,
  IFeatureBlock,
  IFeatureApp,
} from '@apw/lib/types';
import { PageContentView } from './PageContent.View';

interface PageContentAccessibleProps {
  contentBlocks: IContentBlocks;
}

export const transformFeatureApp = (feature: IFeatureApp) => {
  const profile = feature.profile;
  if (!profile) return;
  if (feature.titleOption === FeatureOptionEnum.Default) {
    feature.title = profile?.application.displayName || profile?.name || '';
  }
  if (feature.frontTextOption === FeatureOptionEnum.Default) {
    feature.frontText = profile?.shortDescription || '';
  }
};

const Component: FC<PageContentAccessibleProps> = ({ contentBlocks }) => {
  const [content, setContent] = useState<IContentBlocks>(contentBlocks);

  useEffect(() => {
    setContent([...contentBlocks]);
    dispatchBlock(contentBlocks);
  }, [contentBlocks]);

  const dispatchBlock = (blocks: IContentBlocks) => {
    const strategies = {
      [BlockType.Feature]: actionGalleryFeature,
    };

    blocks.forEach((block, index) => {
      const action = strategies[block.blockType];
      if (action) action(block, index);
    });
  };

  const actionGalleryFeature = (block: IFeatureBlock) => {
    if (!block.items || block.items.length === 0) return;
    block.items.forEach((item) => {
      if (item.featureType === FeatureTypeEnum.App) {
        const feature = item as IFeatureApp;
        transformFeatureApp(feature);
      }
    });
    setContent([...content]);
  };

  return <PageContentView contentBlocks={content} />;
};

export const PageContent = observer(Component);
