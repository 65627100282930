import { RedirectionProcess } from '@apw/hooks/redirectionRules/types/redirectionProcess.interface';
import { RedirectionRulesFactory } from '@apw/hooks/redirectionRules/types/redirectionRulesFactory.interface';
import { useHistory, useLocation } from 'react-router-dom';

export const useRedirectionRules = <T>(
  factory: RedirectionRulesFactory<T>,
): RedirectionProcess<T> => {
  const history = useHistory();
  const location = useLocation();

  return {
    kickStart: (data: T): Promise<boolean> => {
      const redirectionRules = factory(data);
      const currentUrl = location.pathname + location.search;

      for (const rule of redirectionRules) {
        if (rule.test()) {
          const target = rule.getTarget();
          if (typeof target === 'string') {
            history.replace(target);
            return Promise.resolve(currentUrl !== target);
          }
          return target.then((url) => {
            history.replace(url);
            return currentUrl !== url;
          });
        }
      }
      return Promise.resolve(false);
    },
  };
};
