import React, { FC } from 'react';
import { BasicInfo } from '@apw/lib/components/profileDetail/profileBody/ratingsAndReviewsTab/ratingsSection/content/basicInfo/BasicInfo';
import {
  Container,
  RatingsWidgetContainer,
  StyledOverallRating,
} from '@apw/lib/components/profileDetail/profileBody/ratingsAndReviewsTab/ratingsSection/content/content.sc';
import { QualityRating } from '@apw/lib/components/profileDetail/profileBody/ratingsAndReviewsTab/ratingsSection/content/qualityRating/QualityRating';
import { buildOverallItems } from '@apw/lib/components/ratingAndReview/overallRating/overallRating.service';
import { WriteReviewButton } from '@apw/lib/components/ratingAndReview/writeReviewButton/WriteReviewButton';
import { useIsMobile } from '@apw/lib/hooks/useIsMobile';
import { IAppRating } from '@apw/lib/types';

export interface IContentProps {
  ratings: IAppRating;
}

export const Content: FC<IContentProps> = ({ ratings }) => {
  const overallRatingItems = buildOverallItems(ratings);
  const isMobile = useIsMobile();
  return (
    <Container data-test-automation-id={'rating-and-review-tab-ratings'}>
      <BasicInfo
        overallRating={ratings.overallRating}
        voteCount={ratings.voteCount}
      />
      <RatingsWidgetContainer>
        <StyledOverallRating
          items={overallRatingItems}
          automationId={'rating-and-review-tab-overall-ratings'}
        />
        <QualityRating ratings={ratings} />
      </RatingsWidgetContainer>
      {isMobile && <WriteReviewButton fullWidth />}
    </Container>
  );
};
