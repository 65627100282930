import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IProfileOptions, TabLabelEnum } from '@apw/components/profileDetail';
import {
  defaultProfileOptions,
  ProfileOptionsContext,
} from '@apw/components/profileDetail/profile-options.context';
import { ProfileViewModel } from '@apw/components/profileDetail/Profile.ViewModel';
import { RatingsAndReviewsTab } from '@apw/components/profileDetail/profileBody/ratingsAndReviewsTab';
import { TechnicalDetails } from '@apw/components/profileDetail/profileBody/technicalDetails';
import { RatingPopupContainer } from '@apw/components/ratingAndReview/popup/RatingPopupContainer';
import { useQueryParams } from '@apw/hooks';
import { useIsMobileDevice } from '@apw/hooks/deviceWidth/useIsMobileDevice';
import { ProfilePageQueryParamsEnum } from '@apw/modules/profile/ProfilePageQueryParams.enum';
import { RcPaper, RcTab, RcTabContext } from '@ringcentral/juno';
import { observer } from 'mobx-react';
import * as qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import { ProfileContext } from '../profile.context';
import { ProfileViewModelContext } from '../Profile.ViewModel.context';
import { OverviewTab } from './overviewTab';
import {
  Container,
  ProfileContent,
  ProfileContentWithTabs,
  TabPanel,
  TabsWrapper,
} from './profileBody.sc';

interface ProfileBodyProps {
  viewModel: ProfileViewModel;
  isPreviewMode: boolean;
}

const ProfileBodyPure: FC<ProfileBodyProps> = ({
  viewModel,
  isPreviewMode,
}) => {
  const isMobileDevice = useIsMobileDevice();
  const options = useMemo<IProfileOptions>(
    () => ({
      ...defaultProfileOptions,
      isPreviewMode,
    }),
    [isPreviewMode],
  );

  const profile = viewModel.profile!;
  const includePermissions = !!profile.permissions?.length;
  const history = useHistory();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(TabLabelEnum.OVERVIEW);
  const queryParams = useQueryParams();
  const tabQueryParam = queryParams[ProfilePageQueryParamsEnum.TAB];

  const tabs = useMemo(() => {
    const allTabs = [
      {
        label: TabLabelEnum.OVERVIEW,
        value: TabLabelEnum.OVERVIEW,
        component: <OverviewTab />,
        automationId: 'overview-tab',
        display: true,
      },
      {
        label: isMobileDevice
          ? TabLabelEnum.TECHNICAL_DETAILS_MOBILE
          : TabLabelEnum.TECHNICAL_DETAILS,
        value: TabLabelEnum.TECHNICAL_DETAILS,
        component: <TechnicalDetails />,
        automationId: 'technical-details-tab',
        display: includePermissions,
      },
      {
        label: TabLabelEnum.RATINGS_AND_REVIEWS,
        value: TabLabelEnum.RATINGS_AND_REVIEWS,
        component: <RatingsAndReviewsTab />,
        automationId: 'ratings-and-reviews-tab',
        display: profile.appRatingEnabled,
      },
    ];

    return allTabs.filter((tab) => tab.display);
  }, [includePermissions, viewModel.profile]);

  const availableTabs = tabs.map((tab) => tab.value);

  const updateQueryParamForTab = useCallback(
    (newTab: TabLabelEnum) => {
      const newParams = { ...queryParams };
      const queryParamKey = ProfilePageQueryParamsEnum.TAB;

      if (!availableTabs.includes(newTab)) {
        return;
      }

      if (newTab === TabLabelEnum.OVERVIEW) {
        delete newParams[queryParamKey];
      } else {
        newParams[queryParamKey] = newTab;
      }

      const search = qs.stringify(newParams, { addQueryPrefix: true });
      const path = location.pathname + search;
      history.replace(path);
    },
    [history, location.pathname, queryParams, availableTabs],
  );

  useEffect(() => {
    if (availableTabs.includes(tabQueryParam)) {
      setCurrentTab(tabQueryParam);
      return;
    }

    setCurrentTab(TabLabelEnum.OVERVIEW);
  }, [availableTabs, tabQueryParam]);

  const getProfileContent = () => {
    if (tabs.length === 1) {
      return <ProfileContent>{tabs[0].component}</ProfileContent>;
    }

    return (
      <ProfileContentWithTabs>
        <RcTabContext value={currentTab}>
          <RcPaper square elevation={0}>
            <TabsWrapper onChange={(_, value) => updateQueryParamForTab(value)}>
              {tabs.map((tab) => (
                <RcTab
                  label={tab.label}
                  value={tab.value}
                  data-test-automation-id={tab.automationId}
                />
              ))}
            </TabsWrapper>
          </RcPaper>
          {tabs.map((tab) => (
            <TabPanel key={tab.value} value={tab.value}>
              {tab.component}
            </TabPanel>
          ))}
        </RcTabContext>
        <RatingPopupContainer />
      </ProfileContentWithTabs>
    );
  };

  return (
    <ProfileViewModelContext.Provider value={viewModel}>
      <ProfileContext.Provider value={profile}>
        <ProfileOptionsContext.Provider value={options}>
          <Container>{getProfileContent()}</Container>
        </ProfileOptionsContext.Provider>
      </ProfileContext.Provider>
    </ProfileViewModelContext.Provider>
  );
};

export const ProfileBody = observer(ProfileBodyPure);
