import { includes } from 'lodash';
import { Observable, Observer } from 'rxjs';

class DomService {
  private window = window;

  private readonly document = window.document;

  constructor() {}

  // https://stackoverflow.com/questions/1248081/get-the-browser-viewport-dimensions-with-javascript
  getScreenWidth(): number {
    return Math.max(
      this.document.documentElement.clientWidth,
      this.window.innerWidth,
    );
  }

  getScreenHeight(): number {
    return Math.max(
      this.document.documentElement.clientHeight,
      this.window.innerHeight,
    );
  }

  // Below is taken from http://james.padolsey.com/javascript/get-document-height-cross-browser/
  getDocumentHeight(): number {
    const D = this.document;
    return Math.max(
      D.body.scrollHeight,
      D.documentElement.scrollHeight,
      D.body.offsetHeight,
      D.documentElement.offsetHeight,
      D.body.clientHeight,
      D.documentElement.clientHeight,
    );
  }

  getTopPosition(element: any): number {
    let topPosition: number = element.offsetTop;

    // tslint:disable-next-line:no-conditional-assignment
    // eslint-disable-next-line no-cond-assign
    while ((element = element.offsetParent)) {
      topPosition += element.offsetTop;
    }
    return topPosition;
  }

  get documentReady(): Observable<boolean> {
    const self = this;
    return new Observable((observer: Observer<boolean>) => {
      if (includes(['interactive', 'complete'], this.document.readyState)) {
        observer.next(true);
        observer.complete();
      } else {
        this.document.addEventListener(
          'readystatechange',
          function onReadyStateChange() {
            if (self.document.readyState === 'interactive') {
              observer.next(true);
              observer.complete();
              self.document.removeEventListener(
                'readystatechange',
                onReadyStateChange,
              );
            }
          },
        );
      }
    });
  }

  get documentComplete(): Observable<boolean> {
    const self = this;
    return new Observable((observer: Observer<boolean>) => {
      if (this.document.readyState === 'complete') {
        observer.next(true);
        observer.complete();
      } else {
        this.document.addEventListener(
          'readystatechange',
          function onReadyStateChange() {
            if (self.document.readyState === 'complete') {
              observer.next(true);
              observer.complete();
              self.document.removeEventListener(
                'readystatechange',
                onReadyStateChange,
              );
            }
          },
        );
      }
    });
  }

  getScrollHeight(element: HTMLElement): number {
    let scrollHeight = element.scrollHeight;
    const userAgent = this.window.navigator.userAgent;

    if (userAgent.indexOf('Firefox') > -1) {
      scrollHeight -= 2;
    } else if (isIE(userAgent)) {
      scrollHeight -= 1;
    }

    return scrollHeight;

    function isIE(ua) {
      return (
        ua.indexOf('MSIE ') > -1 ||
        ua.indexOf('Trident/') > -1 ||
        ua.indexOf('Edge/') > -1
      );
    }
  }

  // Below is taken from http://www.howtocreate.co.uk/tutorials/javascript/browserwindow
  getPageScrollXY(): { x: number; y: number } {
    let scrOfX = 0;
    let scrOfY = 0;

    if (typeof this.window.pageYOffset === 'number') {
      // Netscape compliant
      scrOfY = this.window.pageYOffset;
      scrOfX = this.window.pageXOffset;
    } else if (
      this.document.body &&
      (this.document.body.scrollLeft || this.document.body.scrollTop)
    ) {
      // DOM compliant
      scrOfY = this.document.body.scrollTop;
      scrOfX = this.document.body.scrollLeft;
    } else if (
      this.document.documentElement &&
      (this.document.documentElement.scrollLeft ||
        this.document.documentElement.scrollTop)
    ) {
      // IE6 standards compliant mode
      scrOfY = this.document.documentElement.scrollTop;
      scrOfX = this.document.documentElement.scrollLeft;
    }

    return {
      x: scrOfX,
      y: scrOfY,
    };
  }

  scrollIntoView(element: HTMLElement, topOffset: number) {
    if (element) {
      this.window.scrollTo(0, this.getTopPosition(element) + topOffset);
    }
  }

  containsClass(element: HTMLElement, cName) {
    const reg = new RegExp(`(\\s|^)${cName}(\\s|$)`);
    if (reg.test(element.className)) {
      return true;
    }
    return false;
  }

  addClass(element: HTMLElement, cName) {
    if (element.classList) {
      element.classList.add(cName);
    } else {
      const preName = element.className;
      if (preName) {
        const reg = new RegExp(`(\\s|^)${cName}(\\s|$)`);
        if (reg.test(preName)) return;
        element.className += `${cName}`;
      } else {
        element.className = cName;
      }
    }
  }

  removeClass(element: HTMLElement, cName) {
    if (element.classList) {
      element.classList.remove(cName);
    } else {
      const preName = element.className;
      const reg = new RegExp(`(\\s|^)${cName}(\\s|$)`);
      element.className = preName.replace(reg, '').trim();
    }
  }

  toggleClass(element: HTMLElement, cName) {
    if (this.containsClass(element, cName)) {
      this.removeClass(element, cName);
    } else {
      this.addClass(element, cName);
    }
  }

  getStyle(element, attr) {
    if (element.currentStyle) {
      return element.currentStyle[attr];
    }
    return getComputedStyle(element)[attr];
  }
}

export const domService = new DomService();
