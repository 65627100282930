import { MD5 } from 'crypto-js';
import moment, { Moment } from 'moment';
import { IValueWithExpireTime, RcExtIdAndAppId } from './types';

export const generateHashKey = (
  rcExtId: string,
  appId: string,
): RcExtIdAndAppId => {
  return MD5(`${rcExtId}${appId}`).toString();
};

export const generateExpireTime = (expireTime: Moment): string => {
  return expireTime.toISOString();
};

export const isExpired = (value: IValueWithExpireTime): boolean => {
  return moment().isAfter(moment(value.expireTime));
};
