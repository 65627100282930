import React from 'react';
import { Tooltip } from '@apw/components';
import {
  Container,
  LabelBox,
  RatingBox,
} from '@apw/components/ratingAndReview/popup/content/CriteriaBox.sc';
import { RectangleRating } from '@apw/components/ratingAndReview/rectangleRating/RectangleRating';
import { RcTypography } from '@ringcentral/juno';

interface CriteriaBoxProps {
  label: string;
  tooltip: string;
  name: string;
  value: number | null;
  onChange: (event: React.ChangeEvent<{}>, value: number | null) => void;
}

export const CriteriaBox = ({
  label,
  tooltip,
  name,
  value,
  onChange,
}: CriteriaBoxProps) => {
  return (
    <Container data-test-automation-id={`quality-rating-item-${name}`}>
      <LabelBox>
        <RcTypography
          color="neutral.f05"
          variant="body1"
          data-test-automation-id={`rating-popup-quality-rating-item-label`}
        >
          {label}
        </RcTypography>
        <Tooltip
          tabIndex={0}
          title={tooltip}
          automationId={`rating-popup-quality-rating-item-tooltip`}
        />
      </LabelBox>
      <RatingBox>
        <RectangleRating
          tooltips={['Terrible', 'So-so', 'Helpful', 'Very helpful', 'Awesome']}
          name={name}
          value={value}
          onChange={onChange}
        />
      </RatingBox>
    </Container>
  );
};
