import { MutableRefObject } from 'react';
import { useDebounce, useEventListener } from '@ringcentral/juno';

const DEFAULT_DEBOUNCE_TIME = 300;
const DEFAULT_DISTANCE_FROM_BOTTOM = 100;

type Options = {
  debounceTime?: number;
  distanceFromBottom?: number;
  contentContainerRef?: MutableRefObject<HTMLElement | null>;
};

const useInfiniteScroll = (
  scrollContainerRef: MutableRefObject<HTMLElement | null>,
  loadMoreFn: (e: Event) => void,
  options: Options = {},
) => {
  const {
    debounceTime = DEFAULT_DEBOUNCE_TIME,
    distanceFromBottom = DEFAULT_DISTANCE_FROM_BOTTOM,
    contentContainerRef = scrollContainerRef,
  } = options;

  const reachedTriggerPoint = () => {
    const scrollContainer = scrollContainerRef.current;
    const contentContainer = contentContainerRef.current;

    if (!scrollContainer || !contentContainer) {
      return false;
    }

    const scrollContainerProps = {
      scrollTop: scrollContainer.scrollTop,
      visibleHeight: scrollContainer.clientHeight,
    };

    const contentContainerProps = {
      offsetTop: contentContainer.offsetTop,
      totalHeight: contentContainer.scrollHeight,
    };

    return (
      scrollContainerProps.scrollTop +
        scrollContainerProps.visibleHeight +
        distanceFromBottom >=
      contentContainerProps.offsetTop + contentContainerProps.totalHeight
    );
  };

  const onScroll = useDebounce((e: Event) => {
    if (reachedTriggerPoint()) {
      loadMoreFn(e);
    }
  }, debounceTime);

  return useEventListener(scrollContainerRef, 'scroll', (e: Event) => {
    onScroll(e);
  });
};

export { useInfiniteScroll };
