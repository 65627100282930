import { RcThemeInput } from '@ringcentral/juno';
import {
  overridesFactory,
  paletteFactory,
} from '../../../../../shared/theme/utils';

export const mcmColor = '#005B87';

export const mcmTheme: RcThemeInput = {
  palette: paletteFactory(mcmColor),
  overrides: overridesFactory(mcmColor),
};
