import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
} from 'react';
import { RootStore } from './RootStore';

export const rootStore = new RootStore();
export const RootContext = createContext<RootStore>(rootStore);

export const RootContextProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => (
  <RootContext.Provider value={rootStore}>{children}</RootContext.Provider>
);

export const useStores = (): RootStore => useContext<RootStore>(RootContext);
