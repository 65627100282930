import React from 'react';
import { styled } from '@ringcentral/juno';

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export function ScaleBox({ widthWeight, heightWeight, children }) {
  return (
    <Container
      style={{ paddingBottom: `${(heightWeight / widthWeight) * 100}%` }}
    >
      <Content>{children}</Content>
    </Container>
  );
}
