import React from 'react';
import { DialogWrapper } from './requireLoginOption.sc';
import { RequireLoginOptionContent } from './RequireLoginOptionContent';

type RequireLoginOptionProps = {
  title: string;
  onClose: () => void;
  onAfterSignIn: () => void;
  onGetFreeTrial: () => void;
};

export const RequireLoginOption = (props: RequireLoginOptionProps) => {
  return (
    <DialogWrapper open size="large">
      <RequireLoginOptionContent {...props} />
    </DialogWrapper>
  );
};
