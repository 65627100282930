import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@ringcentral/juno';
import { checkIsSupportCss } from './checkIsSupportCss';
import { dpwMultipleLineEllipsis, setStyle } from './dpwMultipleLineEllipsis';

const Wrapper = styled.div`
  overflow: hidden;
  word-wrap: break-word; // ie11
  overflow-wrap: break-word; // chrome
  &.webkit-style {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
`;

export function MultipleLineEllipsis(props) {
  const { children, linesToDisplay = 1, lineHeightPx = 16 } = props;

  const element = useRef(null);
  const [isSupportCss, setIsSupportCss] = useState(false);

  useEffect(() => {
    const ele = element.current;
    if (!ele) {
      return;
    }
    if (checkIsSupportCss(ele)) {
      setIsSupportCss(true);
      setStyle(ele, '-webkit-line-clamp', `${linesToDisplay}`);
      setStyle(ele, 'line-height', `${lineHeightPx}px`);
      setStyle(ele, 'max-height', `${lineHeightPx * linesToDisplay}px`);
      return;
    }
    dpwMultipleLineEllipsis(ele, lineHeightPx, linesToDisplay);
  }, []);

  return (
    <Wrapper
      ref={element}
      className={`${isSupportCss ? 'webkit-style' : ''}`}
      data-test-automation-id={`multiple-line-ellipsis-wrapper`}
    >
      {children}
    </Wrapper>
  );
}
