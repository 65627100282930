import { onMobile, useProximaNovaSemibold } from '@apw/core/sc';
import { RcButton, RcIcon, styled } from '@ringcentral/juno';

export const Button = styled(RcButton)`
  ${useProximaNovaSemibold};
  padding: 8px !important;
  min-width: auto !important;
  font-size: 16px !important;

  ${onMobile} {
    padding-left: 0 !important;
  }
`;

export const BackIcon = styled(RcIcon)`
  margin-right: 8px;
  svg {
    font-size: 20px;
  }
`;
