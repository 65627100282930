import { useProximaNovaSemibold } from '@apw/core/sc';
import { RcButton, styled } from '@ringcentral/juno';
import { Link } from 'react-router-dom';

export const LinkStyled = styled(Link)`
  text-decoration: none;
`;

export const ButtonStyled = styled(RcButton)`
  &&& {
    ${useProximaNovaSemibold}
    width: 160px;
    height: 40px;
    font-size: 16px;
  }
`;
