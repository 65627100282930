// @ts-nocheck
import { storageService } from '@apw/shared/storage/storage.service';
import { set } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { GoogleAnalyticsConfig } from './types';

let getCompatibility: (() => any) | undefined;
let gtagInstance: any;
const STORAGE_KEY = 'RCDPW-ShowGoogleAnalyticsLogs';

export const isGoogleAnalyticsEnabled$ = new BehaviorSubject(false);

export const prepareGoogleAnalytics = (options: GoogleAnalyticsConfig) => {
  loadGoogleAnalytics(options.trackingId);

  getCompatibility = options.getCompatibility;

  window.dataLayer = window.dataLayer || [];

  function gtag() {
    // eslint-disable-next-line prefer-rest-params
    dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', options.trackingId, { debug_mode: true });

  gtagInstance = gtag;

  set(window, 'RCDPW.showGoogleAnalyticsLogs', (shouldShow = true) => {
    storageService.setItem(STORAGE_KEY, `${shouldShow}`);
  });
};

const executeWhenEnabled$ = isGoogleAnalyticsEnabled$.pipe(
  filter((enabled$) => enabled$),
  take(1),
);

export const logPageView = (
  pageName: string,
  userId?: string,
  path?: string,
) => {
  executeWhenEnabled$.subscribe(() => {
    if (userId) {
      gtagInstance('set', 'user_id', userId);
    }

    let compatibility;
    if (getCompatibility !== undefined) {
      compatibility = getCompatibility();
    }

    gtagInstance('event', 'pageview', {
      title: pageName,
      page: path || window.location.pathname + window.location.search,
      compatibility,
    });

    if (showGoogleAnalyticsLogs()) {
      // eslint-disable-next-line no-console
      console.log(`Track: ${pageName}`);
    }
  });
};

export const trackButtonClick = (
  label: string,
  customDimensions?: any,
): void => {
  trackEvent('Buttons', 'click', label, customDimensions);
};

const trackEvent = (
  category: string,
  action: string,
  label: string,
  customDimensions?: any,
): void => {
  executeWhenEnabled$.subscribe(() => {
    gtagInstance(`event`, label, {
      event_category: category,
      event_action: action,
      event_label: label,
      ...customDimensions,
    });

    if (showGoogleAnalyticsLogs()) {
      // eslint-disable-next-line no-console
      console.log(
        `Google Analytics -> Send Event\n` +
          `- Event category: ${category}\n` +
          `- Event action: ${action}\n` +
          `- Event label: ${label}`,
      );

      // eslint-disable-next-line no-console
      console.log('- Custom dimensions:', customDimensions);
    }
  });
};

export const buildCustomDimensions = (mapping: {
  [index: string]: string;
}): any => {
  const customDimensions = {};

  Object.keys(mapping).forEach((customDimensionIndex) => {
    customDimensions[customDimensionIndex] = mapping[customDimensionIndex];
  });

  return customDimensions;
};

const showGoogleAnalyticsLogs = (): boolean => {
  return storageService.getItem(STORAGE_KEY) === true.toString();
};

/* eslint-disable */
const loadGoogleAnalytics = (trackingId: string) => {
  const gTagScript = document.createElement('script');
  const firstScript = document.getElementsByTagName('script')[0];

  gTagScript.async = true;
  gTagScript.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
  firstScript.parentNode.insertBefore(gTagScript, firstScript);
}
/* eslint-enable */
