import { styled } from '@ringcentral/juno';
import { onLtMidScreen, onLtSmScreen, onMobile } from '@apw/lib/sc';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  ${onLtMidScreen} {
    padding-top: 0;
  }

  ${onLtSmScreen} {
    margin-top: 28px;
  }

  ${onMobile} {
    margin-bottom: 48px;
  }
`;

export const ItemList = styled.div`
  margin-top: 12px;
`;
