import {
  overflowWrap,
  useProximaNovaMedium,
  useProximaNovaRegular,
} from '@apw/core/sc';
import { RcIcon, styled } from '@ringcentral/juno';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;

  h5 {
    ${useProximaNovaMedium};
    margin: 0 0 16px;
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.25px;
  }
`;

export const LinkWrapper = styled.ul`
  display: table;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-bottom: 40px;
  list-style-type: none;
`;

export const LinkItem = styled.li`
  padding: 8px 0;
  line-height: 24px;
  font-size: 16px;
  font-weight: normal;
  ${useProximaNovaRegular};
`;

export const LinkItemContent = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-decoration: none;

  &:hover span {
    text-decoration: underline;
  }
`;

export const LinkUrl = styled.span`
  ${overflowWrap('break-word')};
  ${useProximaNovaRegular};
  display: inline-block;
  width: 100%;
  font-size: 16px;
  font-weight: normal;
`;

export const LinkIcon = styled(RcIcon)`
  flex-shrink: 0;
`;
