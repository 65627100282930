import androidIcon from '@apw/assets/images/app-download-os/app-download-os-Android-dark-gray.svg';
import androidDisabledIcon from '@apw/assets/images/app-download-os/app-download-os-Android-disabled.svg';
import androidActiveIcon from '@apw/assets/images/app-download-os/app-download-os-Android-white.svg';
import generalIcon from '@apw/assets/images/app-download-os/app-download-os-general-dark-gray.svg';
import generalDisabledIcon from '@apw/assets/images/app-download-os/app-download-os-general-disabled.svg';
import generalActiveIcon from '@apw/assets/images/app-download-os/app-download-os-general-white.svg';
import iOSIcon from '@apw/assets/images/app-download-os/app-download-os-iOS-dark-gray.svg';
import iOSDisabledIcon from '@apw/assets/images/app-download-os/app-download-os-iOS-disabled.svg';
import iOSActiveIcon from '@apw/assets/images/app-download-os/app-download-os-iOS-white.svg';
import linuxIcon from '@apw/assets/images/app-download-os/app-download-os-Linux-dark-gray.svg';
import linuxDisabledIcon from '@apw/assets/images/app-download-os/app-download-os-Linux-disabled.svg';
import linuxActiveIcon from '@apw/assets/images/app-download-os/app-download-os-Linux-white.svg';
import macIcon from '@apw/assets/images/app-download-os/app-download-os-macOS-dark-gray.svg';
import macDisabledIcon from '@apw/assets/images/app-download-os/app-download-os-macOS-disabled.svg';
import macActiveIcon from '@apw/assets/images/app-download-os/app-download-os-macOS-white.svg';
import windowsIcon from '@apw/assets/images/app-download-os/app-download-os-Windows-dark-gray.svg';
import windowsDisabledIcon from '@apw/assets/images/app-download-os/app-download-os-Windows-disabled.svg';
import windowsActiveIcon from '@apw/assets/images/app-download-os/app-download-os-Windows-white.svg';
import {
  DetectableOS,
  getMacChip,
  getParsedOS,
  getWindowsBit,
  isAndroid,
  isDesktop,
  isIOS,
  isMac,
  isMobile,
  isSafari,
  isUndetected,
  isWindows,
  MacChipEnum,
  WindowsBitEnum,
} from '@apw/core/osDetector';
import {
  isBotProfile,
  shouldGetAppFromGallery,
} from '@apw/modules/botProvision/bot-provision.service';
import { AppDownloadLink, DownloadTargetEnum, IProfile } from '@apw/types';
import * as _ from 'lodash';
import { forEach, includes } from 'lodash';
import { Observable, Observer } from 'rxjs';

export const resolve = (profile: IProfile): AppDownloadLink[] => {
  if (isMobile()) {
    return resolveLinkForMobile(profile);
  }

  if (isDesktop() || isUndetected()) {
    return resolveLinksForDesktop(profile);
  }

  return [];
};

export const getCurrentOS = () => {
  const userOS = getUserOS();

  if (userOS) {
    return OSConfig[userOS].name;
  }

  return getParsedOS().name;
};

export const getAvailableOS = (
  downloadLinks: AppDownloadLink[],
): AppDownloadLink[] => {
  return downloadLinks.filter((downloadLink) =>
    includes(operatingSystems, downloadLink.os),
  );
};

const resolveLinkForMobile = (profile: IProfile): AppDownloadLink[] => {
  if (hasDownloadLink(profile, getUserOS())) {
    return getDownloadLinks(profile, getUserOS());
  }

  if (hasDownloadLink(profile, SupportedOS.noOS)) {
    return getDownloadLinks(profile, SupportedOS.noOS);
  }

  return [];
};

const resolveLinksForDesktop = (profile: IProfile): AppDownloadLink[] => {
  const links: AppDownloadLink[] = [];

  if (hasDownloadLink(profile, getUserOS())) {
    links.push(...getUserOSDownloadLinks(profile, getUserOS()));
  }

  forEach(SupportedOS, (os) => {
    if (os !== getUserOS() && hasDownloadLink(profile, os)) {
      links.push(...getDownloadLinks(profile, os));
    }
  });

  return links;
};

const hasDownloadLink = (profile: IProfile, os: string): boolean => {
  const downloadLinks = profile.downloadLinks as AppDownloadLink[];

  try {
    return !!downloadLinks.find((link) => link.os === os);
  } catch (err) {
    return false;
  }
};

const isDownloadItemForM1 = (item: AppDownloadLink) =>
  item.desc?.toLowerCase().includes('m1');

const isDownloadItemFor64Bit = (item: AppDownloadLink) =>
  item.link?.toLowerCase().includes('.msi');

const isDownloadItemFor32Bit = (item: AppDownloadLink) =>
  item.link?.toLowerCase().includes('.exe');

const sortFnForMac = (a: AppDownloadLink, b: AppDownloadLink) => {
  if (isSafari()) {
    return () => 0;
  }
  const isM1Chip = getMacChip() === MacChipEnum.M1;
  const isAForM1 = isDownloadItemForM1(a);
  const isBForM1 = isDownloadItemForM1(b);
  const sortFnForM1 = () => {
    if (isAForM1 && !isBForM1) {
      return -1;
    }
    if (!isAForM1 && isBForM1) {
      return 1;
    }
    return 0;
  };
  const sortFnForIntel = () => {
    if (isAForM1 && !isBForM1) {
      return 1;
    }
    if (!isAForM1 && isBForM1) {
      return -1;
    }
    return 0;
  };
  return isM1Chip ? sortFnForM1() : sortFnForIntel();
};

const sortFnForWindows = (a: AppDownloadLink, b: AppDownloadLink) => {
  const is64Bit = getWindowsBit() === WindowsBitEnum.BIT_64;
  const sortFnFor64Bit = () => {
    const isAFor64Bit = isDownloadItemFor64Bit(a);
    const isBFor64Bit = isDownloadItemFor64Bit(b);
    if (isAFor64Bit && !isBFor64Bit) {
      return -1;
    }
    if (!isAFor64Bit && isBFor64Bit) {
      return 1;
    }
    return 0;
  };
  const sortFnFor32Bit = () => {
    const isAFor32Bit = isDownloadItemFor32Bit(a);
    const isBFor32Bit = isDownloadItemFor32Bit(b);
    if (isAFor32Bit && !isBFor32Bit) {
      return -1;
    }
    if (!isAFor32Bit && isBFor32Bit) {
      return 1;
    }
    return 0;
  };
  return is64Bit ? sortFnFor64Bit() : sortFnFor32Bit();
};

const sortDownloadItems = (downloadItems: AppDownloadLink[], os: string) => {
  const sortFnStrategies = {
    [SupportedOS.macOS]: (a: AppDownloadLink, b: AppDownloadLink) =>
      sortFnForMac(a, b),
    [SupportedOS.Windows]: (a: AppDownloadLink, b: AppDownloadLink) =>
      sortFnForWindows(a, b),
  };
  const sortFn = sortFnStrategies[os];
  if (sortFn) {
    return downloadItems.sort(sortFn);
  }
  return downloadItems;
};

export const getUserOSDownloadLinks = (
  profile: IProfile,
  os: string,
): AppDownloadLink[] => {
  return sortDownloadItems(getDownloadLinks(profile, os), os);
};

const getDownloadLinks = (profile: IProfile, os: string): AppDownloadLink[] => {
  const downloadLinks = profile.downloadLinks as AppDownloadLink[];
  return downloadLinks.filter((link) => link.os === os);
};

export const getUserOS = (): string => {
  let userOS: any = null;

  forEach(OSConfig, (osConfigItem, os) => {
    if (osConfigItem.isCurrentOS()) {
      userOS = os;
    }
  });

  return userOS as string;
};

export const getDownloadUrlForSeo = (
  item: IProfile,
): Observable<string | undefined> => {
  return new Observable((observer: Observer<string | undefined>) => {
    isBotProfile(item)
      .then((isBotItem: boolean) => {
        if (isBotItem && shouldGetAppFromGallery(item)) {
          observer.next(undefined);
          return;
        }
        // @ts-ignore
        observer.next(_.first(item.downloadLinks).link);
      })
      .catch(() => {});
  });
};

export const getDownloadItemByCurrentOS = (
  downloadItems: AppDownloadLink[],
  target?: string,
) => {
  const targetValue = target?.toLowerCase();
  const isTargetNotMatchOS =
    (targetValue === DownloadTargetEnum.DESKTOP && !isDesktop()) ||
    (targetValue === DownloadTargetEnum.MOBILE && !isMobile());
  if (targetValue && isTargetNotMatchOS) {
    return;
  }
  const getDownloadItemStrategies = {
    [DetectableOS.macOS]: () => getMacDownloadItem(downloadItems),
    [DetectableOS.Windows]: () => getWindowsDownloadItem(downloadItems),
    [DetectableOS.iOS]: () => getIOSDownloadItem(downloadItems),
    [DetectableOS.Android]: () => getAndroidDownloadItem(downloadItems),
  };
  const osName = getParsedOS().name || '';
  const getFn = getDownloadItemStrategies[osName];
  if (!getFn) {
    return;
  }
  return getFn();
};

const getWindowsDownloadItem = (downloadItems: AppDownloadLink[]) => {
  const downloadItemsForWindows = downloadItems.filter(
    (item) => item.os === SupportedOS.Windows,
  );
  if (getWindowsBit() === WindowsBitEnum.BIT_64) {
    const downloadItem = downloadItemsForWindows.find((item) =>
      isDownloadItemFor64Bit(item),
    );
    if (downloadItem) {
      return downloadItem;
    }
  }
  return downloadItemsForWindows.find((item) => isDownloadItemFor32Bit(item));
};

const getMacDownloadItem = (
  downloadItems: AppDownloadLink[],
): AppDownloadLink | undefined => {
  if (isSafari()) {
    return;
  }
  const downloadItemsForMac = downloadItems.filter(
    (item) => item.os === SupportedOS.macOS,
  );
  const isM1Chip = getMacChip() === MacChipEnum.M1;
  return isM1Chip
    ? downloadItemsForMac.find((item) => isDownloadItemForM1(item))
    : downloadItemsForMac.find((item) => !isDownloadItemForM1(item));
};

const getIOSDownloadItem = (downloadItems: AppDownloadLink[]) => {
  return downloadItems.find((item) => item.os === SupportedOS.iOS);
};

const getAndroidDownloadItem = (downloadItems: AppDownloadLink[]) => {
  return downloadItems.find((item) => item.os === SupportedOS.Android);
};

export enum SupportedOS {
  macOS = 'macOS',
  Windows = 'Windows',
  Android = 'Android',
  iOS = 'iOS',
  Linux = 'Linux',
  OtherOS = 'otherOS',
  noOS = '',
}

const operatingSystems = [
  SupportedOS.macOS,
  SupportedOS.Windows,
  SupportedOS.Android,
  SupportedOS.iOS,
  SupportedOS.Linux,
  SupportedOS.OtherOS,
];

export interface OSConfigItem {
  icon: any;
  iconActive: any | null;
  iconDisabled: any | null;
  iconExtraStyles?: object;
  isCurrentOS: () => boolean;
  name: string;
}

export const OSConfig: { [key: string]: OSConfigItem } = {
  [SupportedOS.noOS]: {
    icon: generalIcon,
    iconActive: generalActiveIcon,
    iconDisabled: generalDisabledIcon,
    iconExtraStyles: { 'max-height': '23px' },
    isCurrentOS: () => false,
    name: '',
  },
  [SupportedOS.macOS]: {
    icon: macIcon,
    iconActive: macActiveIcon,
    iconDisabled: macDisabledIcon,
    iconExtraStyles: { 'max-height': '25px' },
    isCurrentOS: () => isMac(),
    name: 'macOS',
  },
  [SupportedOS.Windows]: {
    icon: windowsIcon,
    iconActive: windowsActiveIcon,
    iconDisabled: windowsDisabledIcon,
    iconExtraStyles: { 'max-width': '20px' },
    isCurrentOS: () => isWindows(),
    name: 'Windows',
  },
  [SupportedOS.iOS]: {
    icon: iOSIcon,
    iconActive: iOSActiveIcon,
    iconDisabled: iOSDisabledIcon,
    iconExtraStyles: { 'max-width': '23px' },
    isCurrentOS: () => isIOS(),
    name: 'iOS',
  },
  [SupportedOS.Android]: {
    icon: androidIcon,
    iconActive: androidActiveIcon,
    iconDisabled: androidDisabledIcon,
    iconExtraStyles: { 'max-height': '27px' },
    isCurrentOS: () => isAndroid(),
    name: 'Android',
  },
  [SupportedOS.Linux]: {
    icon: linuxIcon,
    iconActive: linuxActiveIcon,
    iconDisabled: linuxDisabledIcon,
    iconExtraStyles: { 'max-height': '23px' },
    isCurrentOS: () => false,
    name: 'Linux',
  },
  [SupportedOS.OtherOS]: {
    icon: generalIcon,
    iconActive: generalActiveIcon,
    iconDisabled: generalDisabledIcon,
    iconExtraStyles: { 'max-height': '23px' },
    isCurrentOS: () => isUndetected(),
    name: 'Others',
  },
};

const DIRECT_DOWNLOAD_BUTTON = 'Download for';

const OTHER_OS_BUTTON = 'Visit page to download';

export const defaultButtonName = {
  [SupportedOS.Windows]: DIRECT_DOWNLOAD_BUTTON,
  [SupportedOS.macOS]: DIRECT_DOWNLOAD_BUTTON,
  [SupportedOS.iOS]: DIRECT_DOWNLOAD_BUTTON,
  [SupportedOS.Android]: DIRECT_DOWNLOAD_BUTTON,
  [SupportedOS.Linux]: DIRECT_DOWNLOAD_BUTTON,
  [SupportedOS.OtherOS]: OTHER_OS_BUTTON,
  [SupportedOS.noOS]: OTHER_OS_BUTTON,
};

export const osTitle = {
  [SupportedOS.Windows]: 'Windows',
  [SupportedOS.macOS]: 'Mac',
  [SupportedOS.iOS]: 'iOS',
  [SupportedOS.Android]: 'Android',
  [SupportedOS.Linux]: 'Linux',
};
