import { Popover } from '@apw/core/popover';
import { menuItemHoverActiveStatus, useProximaNovaRegular } from '@apw/core/sc';
import { RcMenuItem, styled } from '@ringcentral/juno';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  button {
    justify-content: flex-start;
    color: #494949 !important;
  }
`;

export const PopoverContainer = styled(Popover)`
  [class~='MuiPopover-paper'] {
    margin-top: 8px;
  }
`;

export const CategoryTriggerStyled = styled(RcMenuItem)`
  display: flex;
  justify-content: space-between !important;
  ${useProximaNovaRegular};
  font-size: 16px !important;
  padding: 8px 16px !important;
  ${menuItemHoverActiveStatus};

  .triggerIcon {
    margin-left: auto;
    svg {
      font-size: 24px;
    }
  }

  &.on {
    background: #f3f3f3;
  }
`;
