import { CoveoSearchStore } from '@apw/stores/CoveoSearchStore';
import { CompatibilitiesStore, CompatibilityStore } from './compatibility';
import { GoogleRecaptchaStore } from './GoogleRecaptchaStore';
import { MixpanelStore } from './MixpanelStore';
import { MyAppsStore } from './myApps';
import { MyReviewsStore } from './myReviews';
import { PagesStore } from './pages';
import { SearchStore } from './search';
import { UserStore } from './user';

export class RootStore {
  compatibilitiesStore: CompatibilitiesStore;

  compatibilityStore: CompatibilityStore;

  userStore: UserStore;

  mixpanelStore: MixpanelStore;

  googleRecaptchaStore: GoogleRecaptchaStore;

  pagesStore: PagesStore;

  searchStore: SearchStore;

  myAppsStore: MyAppsStore;

  coveoSearchStore: CoveoSearchStore;

  myReviewsStore: MyReviewsStore;

  constructor() {
    this.compatibilitiesStore = new CompatibilitiesStore(this);
    this.compatibilityStore = new CompatibilityStore(this);
    this.userStore = new UserStore();
    this.mixpanelStore = new MixpanelStore();
    this.googleRecaptchaStore = new GoogleRecaptchaStore();
    this.pagesStore = new PagesStore(this);
    this.searchStore = new SearchStore(this);
    this.myAppsStore = new MyAppsStore(this);
    this.coveoSearchStore = new CoveoSearchStore();
    this.myReviewsStore = new MyReviewsStore();
  }
}
