import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { StoreContext } from '@apw/lib/components/profileDetail/StoreContext';
import { RatingPopup } from '@apw/lib/components/ratingAndReview/popup/ratingPopup';

const RatingPopupContainerPure = () => {
  const detailStore = useContext(StoreContext);

  return <RatingPopup onSave={detailStore.saveRating} />;
};

export const RatingPopupContainer = observer(RatingPopupContainerPure);
