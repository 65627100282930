import {
  overflowWrap,
  useProximaNovaMedium,
  useProximaNovaRegular,
} from '@apw/core/sc';
import { styled } from '@ringcentral/juno';

export const Item = styled.li``;

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;

  h5 {
    ${useProximaNovaMedium};
    margin: 0 0 16px;
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.25px;
  }

  ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    padding-bottom: 0;
    list-style-type: none;

    &:last-child {
      padding-bottom: 40px;
    }
  }

  ${Item} {
    padding: 8px 0;

    p {
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
    }

    a {
      text-decoration: none;
    }

    span {
      ${overflowWrap('break-word')};
      ${useProximaNovaRegular};
      font-size: 16px;
      font-weight: normal;
    }
  }
`;
