import {
  IPageContent,
  IProfileInPageContent,
} from '@apw/modules/page/typings/page-content.interface';

export interface IFeatureItem {
  featureType: TFeatureType;
  appId?: string;
  pageId?: string;
  otherLink?: string;
  sponsored: boolean;
  color?: string;
  image?: string;
  title?: string;
  frontText?: string;
  imageOption: FeatureOptionEnum;
  titleOption?: FeatureOptionEnum;
  frontTextOption?: FeatureOptionEnum;

  /** individual app * */
  profile?: IProfileInPageContent;

  /** page * */
  page?: IPageContent;
}

export enum FeatureOptionEnum {
  Default = 'default',
  Custom = 'custom',
}

export type TFeatureType = 'Page' | 'Individual App' | 'Other Link';

export enum FeatureTypeEnum {
  Page = 'Page',
  IndividualApp = 'Individual App',
  OtherLink = 'Other Link',
}
