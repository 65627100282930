import { transport } from '@apw/core';
import { storageService } from '@apw/shared/storage/storage.service';
import { action, computed, observable, reaction, runInAction } from 'mobx';

const FORCED_STATUS_STORAGE_KEY = 'RCDPW.forceEnableCoveoSearch';
const FORCE_ENABLE = String(true);
const FORCE_DISABLE = String(false);

export class CoveoSearchStore {
  @computed
  public get isEnabled(): boolean {
    if (this.forcedStatus !== undefined) {
      return this.forcedStatus;
    }

    return this.remoteParameterStatus;
  }

  @observable
  private remoteParameterStatus: boolean;

  @observable
  private forcedStatus?: boolean;

  @observable
  public loading = false;

  constructor() {
    this.resolveRemoteParameterStatus();
    this.initForcedStatus();
  }

  @action.bound
  private resolveRemoteParameterStatus(): void {
    this.loading = true;

    // Wait until `transport` is available, as a workaround for circular dependency
    setTimeout(() => {
      transport
        .requestCoveoSearchFlag()
        .then((res) => {
          const isEnabled = Boolean(res?.result);

          runInAction(() => {
            this.remoteParameterStatus = isEnabled;
          });
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false;
          });
        });
    });
  }

  private initForcedStatus(): void {
    this.resolveForcedStatus();
    this.onForcedStatusChange();
    RCAPW.forceEnableCoveoSearch = this.forceEnable;
    RCAPW.clearForcedStatusForCoveoSearch = this.clearForcedStatus;
  }

  @action.bound
  private resolveForcedStatus(): void {
    const forcedStatus = storageService.getItem(FORCED_STATUS_STORAGE_KEY);

    if (forcedStatus === FORCE_ENABLE) {
      this.forceEnable(true);
      return;
    }

    if (forcedStatus === FORCE_DISABLE) {
      this.forceEnable(false);
      return;
    }

    this.clearForcedStatus();
  }

  @action.bound
  private forceEnable(enable = true): void {
    this.forcedStatus = enable;
  }

  @action.bound
  private clearForcedStatus(): void {
    this.forcedStatus = undefined;
  }

  private onForcedStatusChange(): void {
    reaction(
      () => this.forcedStatus,
      (forcedStatus) => {
        if (forcedStatus === undefined) {
          storageService.removeItem(FORCED_STATUS_STORAGE_KEY);
          return;
        }

        storageService.setItem(FORCED_STATUS_STORAGE_KEY, forcedStatus);
      },
    );
  }
}
