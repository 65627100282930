import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { StoreContext } from '@apw/lib/components/profileDetail/StoreContext';
import { Criteria } from '@apw/lib/components/ratingAndReview/popup/criteria/criteria';
import { RatingPopupStore } from '@apw/lib/components/ratingAndReview/popup/ratingPopup.store';
import {
  Overall,
  RatingContainer,
  OverallRating,
  Quality,
  GlobalTooltipStyle,
} from '@apw/lib/components/ratingAndReview/popup/ratingStep/ratingStep.sc';

interface RatingStepProps {
  store: RatingPopupStore;
}

const RatingStepPure = ({ store }: RatingStepProps) => {
  const { translation } = useContext(StoreContext);
  const qualityItemLabels =
    translation.ratingsAndReviews.qualityRatings.itemLabels;
  return (
    <>
      <GlobalTooltipStyle />
      <Overall
        color="neutral.f05"
        variant="subheading2"
        data-test-automation-id="rating-popup-overall-title"
      >
        {translation.ratingsAndReviews.popup.overallSectionTitle} *
      </Overall>
      <RatingContainer>
        <OverallRating
          name="rating-popup-overall"
          value={store.overallRating}
          tooltips={translation.ratingsAndReviews.ratingTooltips}
          onChange={(e, value) => store.setVoteStar(value)}
          data-test-automation-id="rating-popup-overall"
        />
      </RatingContainer>
      <Quality
        color="neutral.f05"
        variant="subheading2"
        data-test-automation-id="rating-popup-quality-title"
      >
        {translation.ratingsAndReviews.popup.qualitySectionTitle}
      </Quality>
      <Criteria
        name="value"
        label={`${qualityItemLabels.value.label} *`}
        tooltip={qualityItemLabels.value.tooltip}
        value={store.valueRating}
        onChange={(e, value) => store.setValue(value)}
      />
      <Criteria
        name="convenience"
        label={`${qualityItemLabels.convenience.label} *`}
        tooltip={qualityItemLabels.convenience.tooltip}
        value={store.convenienceRating}
        onChange={(e, value) => store.setConvenience(value)}
      />
      <Criteria
        name="feature"
        label={`${qualityItemLabels.feature.label} *`}
        tooltip={qualityItemLabels.feature.tooltip}
        value={store.featureRating}
        onChange={(e, value) => store.setFeature(value)}
      />
      <Criteria
        name="support"
        label={`${qualityItemLabels.support.label} *`}
        tooltip={qualityItemLabels.support.tooltip}
        value={store.supportRating}
        onChange={(e, value) => store.setSupport(value)}
      />
    </>
  );
};

export const RatingStep = observer(RatingStepPure);
