import {
  FormPropsConfig,
  FormPropsEnum,
} from '@apw/modules/botProvision/typings';
import * as Yup from 'yup';

export const setFormatError = (formik) => {
  formik.setErrors({ [FormPropsEnum.BOT_NAME]: 'FORMAT_ERROR' });
};

export const setOccupiedError = (formik) => {
  formik.setErrors({ [FormPropsEnum.BOT_NAME]: 'occupied' });
};

export const buildForm = () => {
  return {
    initialValues: {
      [FormPropsEnum.BOT_NAME]: '',
    },
    validationSchema: formSchema,
    onSubmit: () => {},
  };
};

const formSchema = Yup.object().shape({
  botName: Yup.string()
    .trim()
    .min(FormPropsConfig[FormPropsEnum.BOT_NAME].minLength, 'FORMAT_ERROR')
    .max(FormPropsConfig[FormPropsEnum.BOT_NAME].maxLength, 'FORMAT_ERROR')
    .required('FORMAT_ERROR')
    .matches(/^(\s|\d|[a-zA-Z]|\.|-|_)+$/, 'FORMAT_ERROR'),
});
