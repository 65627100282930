import React, { useEffect, useState } from 'react';
import { InfiniteScroll } from '@apw/components/infiniteScroll';
import { googleAnalytics } from '@apw/core';
import { trackPageView } from '@apw/core/tracker/mixpanel';
import { prepareExternalUrl } from '@apw/core/url/url.service';
import { getCompatibilitySearchPath } from '@apw/routes/getCompatibilityPaths';
import { useStores } from '@apw/stores';
import { Category } from '@apw/types/category';
import { RcLink } from '@ringcentral/juno';
import { observer } from 'mobx-react';
import {
  NotBotItem,
  NotInstall,
  SectionDesc,
  SectionTitleDiv,
  SectionTitleH2,
  Wrapper,
} from './BotList.sc';
import { BotListItem } from './botListItem';

const BotListPure = () => {
  const { myAppsStore, userStore, compatibilitiesStore } = useStores();

  const [searchBotsLink, setSearchBotsLink] = useState('');
  const [mnaListLink, setMnaListLink] = useState('');

  const { botItems, isInfiniteScrollEnabled, onScroll, loadBotList } =
    myAppsStore;

  useEffect(() => {
    trackPageView('Installed Bots List');
    googleAnalytics.trackPageView('InstalledBotsList');

    const link = updateSearchAvailableBotsLink();

    setSearchBotsLink(link);
    updateMnaListLink();
  }, []);

  const updateMnaListLink = () => {
    const jupiterWebUrl = compatibilitiesStore.getJupiterWebUrl(
      userStore.user?.brandId || '',
    );
    setMnaListLink(`${jupiterWebUrl}/l/apps/installed`);
  };

  const updateSearchAvailableBotsLink = () => {
    const slug = compatibilitiesStore.getCompatibilityById(
      userStore.user?.brandId || '',
    )?.slug;
    const searchPath = getCompatibilitySearchPath(
      slug || compatibilitiesStore.rcCompatibility?.slug || '',
    );
    return prepareExternalUrl(
      `${searchPath}?appCategory=${encodeURIComponent(
        Category.BOTS_NOTIFICATIONS,
      )}`,
    );
  };

  return (
    <Wrapper data-test-automation-id="add-ins-container">
      {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
      <SectionTitleH2
        className={'addInsTitle'}
        data-test-automation-id="addInsTitle"
        role="heading"
        aria-level={1}
      >
        Add-ins
      </SectionTitleH2>
      <SectionTitleDiv
        className={'mnaAppsTitle'}
        data-test-automation-id="mnaAppsTitle"
      >
        Notification apps
      </SectionTitleDiv>
      <SectionDesc
        className={'mnaAppsDesc'}
        data-test-automation-id="mnaAppsDesc"
      >
        <p>
          A list of currently installed notification apps can be viewed and
          managed from&nbsp;
          <RcLink
            href={mnaListLink}
            target={`_blank`}
            data-test-automation-id="mnaAppLink"
          >
            <span>within the team messaging client</span>
          </RcLink>
          .
        </p>
      </SectionDesc>
      <SectionTitleDiv
        className={'botAppsTitle'}
        data-test-automation-id="botAppsTitle"
      >
        Bots
      </SectionTitleDiv>
      <SectionDesc
        className={'botAppsDesc'}
        data-test-automation-id="botAppsDesc"
      >
        <p>The following bots are currently installed in your organization.</p>
      </SectionDesc>
      <div data-test-automation-id="botAppListContainer">
        <div data-test-automation-id="botAppList">
          <InfiniteScroll
            distanceFromBottom={150}
            enabled={isInfiniteScrollEnabled}
            onScroll={onScroll}
          >
            {botItems.map((item) => {
              return (
                <BotListItem
                  data-test-automation-id={`bot-list-item-${item.id}`}
                  key={item.id}
                  item={item}
                  onChange={() => loadBotList()}
                />
              );
            })}
          </InfiniteScroll>
        </div>
        {botItems.length === 0 && !isInfiniteScrollEnabled && (
          <NotBotItem data-test-automation-id="my-apps-not-installed-any-bots">
            <NotInstall>You have not installed any bots yet. </NotInstall>
            <RcLink href={searchBotsLink}>
              <span>Check all available bots &gt;</span>
            </RcLink>
          </NotBotItem>
        )}
      </div>
    </Wrapper>
  );
};

export const BotList = observer(BotListPure);
