interface IViewModel {
  dispose: () => void;
}

export abstract class BaseViewModel<P> implements IViewModel {
  props: P | undefined;

  protected constructor(props?: P) {
    this.props = props;
  }

  dispose() {
    throw new Error('Not implemented: dispose');
  }
}
