import React, { useEffect, useMemo } from 'react';
import { googleAnalytics, mixpanel } from '@apw/core';
import { useQueryParams } from '@apw/hooks';
import { BrokenLinkIndicator, withLayout } from '@apw/shared';
import { useStores } from '@apw/stores';

const ProfileNotAvailableForPartner = () => {
  const queryParams = useQueryParams();
  const { compatibilitiesStore } = useStores();
  const name = useMemo(
    () => compatibilitiesStore.getCompatibilityById(queryParams.partner)?.name,
    [queryParams],
  );

  useEffect(() => {
    googleAnalytics.trackPageView(`GalleryItem:AppNotFound`);
    mixpanel.trackPageView('Not found');
  }, []);
  return (
    <BrokenLinkIndicator callToAction={null}>
      {`This integration is not available for ${name}.\r\n`}
      <a
        href="https://ideas.ringcentral.com/ideas/new"
        target="_blank"
        rel="noreferrer"
      >
        Request this integration
      </a>
      {` using our ideas portal.`}
    </BrokenLinkIndicator>
  );
};
export default withLayout(ProfileNotAvailableForPartner, {
  stickyHeader: true,
});
