import { onMobile, useProximaNovaRegular } from '@apw/core/sc';
import { RcButton, RcIcon, RcLink, styled } from '@ringcentral/juno';

export const DirectSupportButton = styled(RcButton)`
  ${useProximaNovaRegular};
  padding: 0 !important;
  font-size: 16px !important;
  font-weight: normal !important;

  &:hover {
    a {
      text-decoration: underline;
    }
  }
`;

export const DirectSupportText = styled.span`
  vertical-align: middle;
`;

export const DirectSupportLink = styled(RcLink)`
  ${useProximaNovaRegular};
  font-size: 16px !important;
  font-weight: 400 !important;

  ${onMobile} {
    font-size: 20px !important;
    line-height: 24px;
  }
`;

export const DirectSupportIcon = styled(RcIcon)`
  margin-left: 6px;

  ${onMobile} {
    width: 20px;
    height: 20px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;
