import React, { useContext, useMemo, useState } from 'react';
import { ProfileViewModelContext } from '@apw/components/profileDetail/Profile.ViewModel.context';
import { RatingPopup } from '@apw/components/ratingAndReview/popup/RatingPopup';
import { RatingPopupStore } from '@apw/components/ratingAndReview/popup/ratingPopup.store';
import { ReviewStatus } from '@apw/components/ratingAndReview/typings/reviewItem.interface';
import { transport } from '@apw/core';
import { platformTypeToAppType } from '@apw/core/application.service';
import {
  trackRatingForPending,
  trackRatingStateChange,
} from '@apw/core/tracker/mixpanel';
import { MediumEnum } from '@apw/core/tracker/types';
import { IRatingStateChangeProperties } from '@apw/core/tracker/types/rating-state-change-properties.interface';
import { useCommonError } from '@apw/hooks/useCommonError';
import { useStores } from '@apw/stores';
import { RcSnackbar } from '@ringcentral/juno';
import { observer } from 'mobx-react';

export const RatingPopupContainerPure = () => {
  const { myReviewsStore, userStore } = useStores();
  const commonError = useCommonError();
  const profileVM = useContext(ProfileViewModelContext);

  const appId = useMemo(() => profileVM.profile?.appId!, [profileVM.profile]);

  const onClose = () => {
    profileVM.closeRatingPopup();
  };

  const onSave = async (store: RatingPopupStore, isEdit) => {
    try {
      const res = await transport.saveReview(appId, store.review);

      setMsg(
        isEdit
          ? 'Thanks for your submission. We will update your rating soon.'
          : 'Thanks for your submission. We will post it soon.',
      );

      setSnackOpen(true);

      const oldStatus = profileVM.myReviewItem?.status;
      const isStateChange = oldStatus !== res.status;
      const isPending = res?.status === ReviewStatus.Pending;

      if (!isEdit && isPending) {
        trackRatingForPending(profileVM.profile!, res);
      } else if (isStateChange) {
        const profile = profileVM.profile;
        const properties: IRatingStateChangeProperties = {
          appId: profile?.appId,
          appName: profile?.applicationName,
          appType: platformTypeToAppType(profile?.applicationType!),
          appDisplayName: profile?.applicationDisplayName,
          brand: profile?.brand,
          orgId: profile?.orgId,
          orgType: profile?.orgType,
          profileName: profile?.appName,
          medium: MediumEnum.WEB,
          requestDate: res.creationTime,
          modifiedDate: new Date().toISOString(),
          oldState: oldStatus || '',
          newState: res.status,
          overallRating: res.overallRating,
          valueRating: res.valueRating,
          convenienceRating: res.convenienceRating,
          featureRating: res.featureRating,
          supportRating: res.supportRating,
          reviews: res.content,
        };
        trackRatingStateChange(properties);
      }

      const extensionId = userStore.user?.extensionId!;
      if (isPending) {
        myReviewsStore.onPendingItemFound(extensionId, appId, res);
      } else {
        myReviewsStore.onApprovedOrRejectedItemFound(extensionId, appId, res);
      }

      profileVM.removeMyPublishedReviewFromList();
      onClose();
    } catch (e) {
      commonError.show();
    }
  };

  const [snackOpen, setSnackOpen] = useState(false);
  const [msg, setMsg] = useState('');

  return (
    <>
      {snackOpen && (
        <RcSnackbar
          open={snackOpen}
          autoHideDuration={5000}
          onClose={() => setSnackOpen(false)}
          message={msg}
          data-test-automation-id="rating-popup-snackbar"
        />
      )}
      {profileVM.ratingPopupOpen ? (
        <RatingPopup open onClose={onClose} onSave={onSave} />
      ) : null}
    </>
  );
};

export const RatingPopupContainer = observer(RatingPopupContainerPure);
