import { styled } from '@ringcentral/juno';
import { ItemLabel } from '@apw/lib/components/profileDetail/profileBody/ratingsAndReviewsTab/ratingsSection/content/qualityRating/qualityRatingItem/itemLabel/Itemlabel';
import { JUNO_THEME_PREFIX } from '@apw/lib/constants';

export const Container = styled.div`
  display: flex;
  align-items: center;

  &&& {
    .${JUNO_THEME_PREFIX}-MuiRating-decimal {
      margin: 0 4px 0 0;
    }
    .RcRating-icon div {
      width: 28px;
      height: 10px;
    }
  }
`;

export const StyledLabel = styled(ItemLabel)`
  margin-top: 4px;
  margin-left: 20px;
  padding: 4px 0;

  &&& {
    svg {
      font-size: 16px;
    }
  }
`;
