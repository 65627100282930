import { storageService } from '@apw/shared/storage/storage.service';
import { set } from 'lodash';
import * as _ from 'lodash';
import mixpanel, { Config } from 'mixpanel-browser';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, take, timeoutWith } from 'rxjs/operators';

const STORAGE_KEY = 'RCDPW-ShowMixpanelLogs';
const isMixpanelLoaded$ = new BehaviorSubject(false);
const mixpanelLoaded = isMixpanelLoaded$
  .pipe(
    filter((loaded) => loaded),
    take(1),
  )
  .toPromise();

const showMixpanelLogs = (): boolean => {
  return storageService.getItem(STORAGE_KEY) === true.toString();
};

export const initialize = (token: string, config?: Partial<Config>) => {
  mixpanel.init(token, config);
  isMixpanelLoaded$.next(true);

  set(window, 'RCDPW.showMixpanelLogs', (show = true) => {
    storageService.setItem(STORAGE_KEY, `${show}`);
  });

  set(window, 'mixpanel', mixpanel);
};

export const identify = (rcExtensionId: string) => {
  if (showMixpanelLogs()) {
    // eslint-disable-next-line no-console
    console.log(
      `Mixpanel -> Identify user.`,
      `\nRC extension ID:`,
      rcExtensionId,
    );
  }

  mixpanelLoaded.then(() => {
    mixpanel.identify(rcExtensionId);
  });
};

export const setGroup = (groupKey: string, groupIds: string[]) => {
  if (showMixpanelLogs()) {
    // eslint-disable-next-line no-console
    console.log(
      `Mixpanel -> Set group.`,
      `\nGroup key:`,
      groupKey,
      `\nGroup IDs:`,
      groupIds,
    );
  }

  mixpanelLoaded.then(() => {
    mixpanel.set_group(groupKey, groupIds);
  });
};

export const track = (
  eventName: string,
  properties: object,
): Observable<any> => {
  if (showMixpanelLogs()) {
    // eslint-disable-next-line no-console
    console.log(
      `Mixpanel -> Send Event "${eventName}"`,
      `\nProperties:`,
      properties,
    );
  }

  const onTracked = new Subject();

  mixpanelLoaded.then(() => {
    mixpanel.track(eventName, properties, () => onTracked.next());
  });

  return onTracked.pipe(timeoutWith(3000, of(1)));
};

export const register = (superProperties: object) => {
  if (showMixpanelLogs()) {
    // eslint-disable-next-line no-console
    console.log(
      `Mixpanel -> Register super property.`,
      `\nSuper properties:`,
      superProperties,
    );
  }

  mixpanelLoaded.then(() => {
    mixpanel.register(superProperties);
  });
};

export const isIgnored = () => {
  return _.get(mixpanel, 'persistence.props.$ignore') === 'true';
};

export const reset = () => {
  if (showMixpanelLogs()) {
    // eslint-disable-next-line no-console
    console.log(`Mixpanel -> Reset.`);
  }

  mixpanelLoaded.then(() => {
    mixpanel.reset();
  });
};
