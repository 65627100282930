import React, { useEffect } from 'react';
import { domService } from '@apw/components/dom/dom.service';
import { triggerDocumentHeightChange } from '@apw/components/ObserverScroll/ObserverScroll';
import {
  GLOBAL_SCROLL_BLOCK_CLASS,
  GlobalScrollBlockStyles,
} from '@apw/shared/withGlobalScroll/withGlobalScroll.sc';

export const globalScrollBlockForLeadForm = (active: boolean) => {
  const htmlEl = document.getElementsByTagName('html')[0];

  const onPopoverShow = () => {
    domService.addClass(htmlEl, GLOBAL_SCROLL_BLOCK_CLASS);
  };

  const onPopoverHidden = () => {
    domService.removeClass(htmlEl, GLOBAL_SCROLL_BLOCK_CLASS);
  };

  return active ? onPopoverShow() : onPopoverHidden();
};

export const withGlobalScrollForLeadForm = (Content) => {
  return (props) => {
    useEffect(() => {
      globalScrollBlockForLeadForm(true);
      triggerDocumentHeightChange();
      return () => {
        globalScrollBlockForLeadForm(false);
      };
    }, []);

    return (
      <>
        <GlobalScrollBlockStyles />
        <Content {...props} />
      </>
    );
  };
};
