import React, {
  CSSProperties,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { domService } from '@apw/components/dom/dom.service';
import { ObserverScroll } from '@apw/components/ObserverScroll/ObserverScroll';
import { StickyWrap } from '@apw/components/sticky/Sticky.sc';

const htmlEl = document.getElementsByTagName('html')[0];
export const htmlElFixed = () => {
  return domService.getStyle(htmlEl, 'position') === 'fixed';
};

export interface StickyProps {
  enabled?: boolean;
  children: ReactNode;
  position: 'top' | 'bottom';
  offset?: number;
  style?: CSSProperties;
  onChange?: (sticky: boolean, stickyRef: HTMLElement) => void;
  getStickyRef?: (wrapRef: HTMLElement | null) => void;
}

export interface StickyRef {
  recalculate: () => void;
}

export const Sticky = React.forwardRef<StickyRef, StickyProps>((props, ref) => {
  const {
    enabled = true,
    children,
    position,
    offset,
    style,
    onChange,
    getStickyRef,
  } = props;
  const [sticky, setSticky] = useState(false);
  const [wrapStyle, setWrapStyle] = useState({});
  const [stickyStyle, setStickyStyle] = useState({});
  const wrapRef = useRef<HTMLDivElement | null>(null);
  const stickyRef = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    recalculate: shouldStick,
  }));

  useEffect(() => {
    if (enabled) {
      handleScroll();
    }
  }, []);

  const handleScroll = () => {
    const wrapElStyle = {
      height: stickyRef && (stickyRef.current as HTMLDivElement).offsetHeight,
    };
    setWrapStyle(wrapElStyle);

    if (getStickyRef) {
      getStickyRef(stickyRef.current);
    }
    return shouldStick();
  };

  const shouldStick = () => {
    if (wrapRef) {
      if (position === 'top') {
        return getPageYOffset() >=
          (wrapRef.current as HTMLDivElement).offsetTop - (offset || 0)
          ? setAsSticky()
          : unsetAsSticky();
      }
      if (position === 'bottom') {
        return getPageYOffset() + window.innerHeight <=
          (wrapRef.current as HTMLDivElement).offsetTop +
            (wrapRef.current as HTMLDivElement).getBoundingClientRect().height +
            (offset || 0)
          ? setAsSticky()
          : unsetAsSticky();
      }
      return false;
    }
    return false;
  };

  const getPageYOffset = () => {
    if (htmlElFixed()) {
      return Math.abs(parseInt(htmlEl.style.top || '0', 10));
    }
    return window.pageYOffset;
  };

  const setAsSticky = () => {
    let elStyle = {};

    elStyle = {
      position: 'fixed',
      width: wrapRef && (wrapRef.current as HTMLDivElement).offsetWidth,
    };

    if (style) {
      elStyle = {
        ...elStyle,
        ...style,
      };
    }

    if (position === 'top') {
      elStyle = {
        top: offset || 0,
        ...elStyle,
      };
    }

    if (position === 'bottom') {
      elStyle = {
        bottom: offset || 0,
        ...elStyle,
      };
    }

    setStickyStyle(elStyle);
    setSticky(true);

    if (onChange) {
      onChange(true, stickyRef.current!);
    }
  };

  const unsetAsSticky = () => {
    setSticky(false);
    if (onChange) {
      onChange(false, stickyRef.current!);
    }
  };

  const activeSticky = () => {
    return enabled && sticky;
  };

  return (
    <ObserverScroll callback={handleScroll} activeObserver={enabled}>
      <div
        ref={wrapRef}
        style={activeSticky() ? wrapStyle : undefined}
        data-test-automation-id="sticky-container"
      >
        <StickyWrap
          ref={stickyRef}
          style={activeSticky() ? stickyStyle : undefined}
          data-test-automation-id="sticky-wrapper"
        >
          {children}
        </StickyWrap>
      </div>
    </ObserverScroll>
  );
});
