import { overridesFactory } from '@apw/shared/theme/utils/overrides.factory';
import { paletteFactory } from '@apw/shared/theme/utils/palette.factory';
import { RcThemeInput } from '@ringcentral/juno';

export const atosColor = '#0066A1';

export const atosTheme: RcThemeInput = {
  palette: paletteFactory(atosColor),
  overrides: overridesFactory(atosColor),
};
