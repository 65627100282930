import { RcThemeInput } from '@ringcentral/juno';
import {
  overridesFactory,
  paletteFactory,
} from '../../../../../shared/theme/utils';

export const eastlinkColor = '#332A86';

export const eastlinkTheme: RcThemeInput = {
  palette: paletteFactory(eastlinkColor),
  overrides: overridesFactory(eastlinkColor),
};
