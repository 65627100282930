import { isSSR } from '@apw/core';
import { action, observable } from 'mobx';

export class GoogleRecaptchaStore {
  @observable
  public isEnabled = !isSSR();

  @observable
  public siteKey = RCAPW.googleRecaptchaKey;

  @action.bound
  setIsEnabled(value: boolean): void {
    this.isEnabled = value;
  }

  @action.bound
  setSiteKey(value: string): void {
    this.siteKey = value;
  }
}
