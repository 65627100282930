import React, { useMemo } from 'react';
import {
  HeaderContainer,
  Heading,
  SubHeading,
} from '@apw/components/pageContent/shared/blockContainer/blockHeader.sc';
import { BlockHeaderInterface } from './typings/block-header.interface';

const BlockHeader = ({
  data,
  isH1 = false,
}: {
  data: BlockHeaderInterface;
  isH1: boolean;
}) => {
  const headingA11yProps = useMemo(() => {
    return isH1
      ? {
          role: 'heading',
          'aria-level': 1,
        }
      : {};
  }, [isH1]);
  return (
    <HeaderContainer>
      {data.heading && (
        <Heading
          data-test-automation-id={'block-heading'}
          {...headingA11yProps}
        >
          {data.heading}
        </Heading>
      )}
      {data.subHeading && (
        <SubHeading data-test-automation-id={'block-sub-heading'}>
          {data.subHeading}
        </SubHeading>
      )}
    </HeaderContainer>
  );
};

export { BlockHeader };
