import { RcIconButton, styled } from '@ringcentral/juno';

const arrowOffset = '60px';

export const OverflowWrap = styled.div`
  overflow: hidden;
`;

export const SliderStyle = styled.div`
  position: relative;
  z-index: 1;
  transition: transform 0.5s cubic-bezier(0.1, 0.57, 0.1, 1);
  white-space: nowrap;
  font-size: 0;
  transform: translateZ(0);
  will-change: transform;
  touch-action: pan-y;
`;

export const Slide = styled.div`
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
`;

const ActionBtn = styled(RcIconButton)`
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%) !important;
`;

export const Back = styled(ActionBtn)`
  left: -${arrowOffset};
`;

export const Next = styled(ActionBtn)`
  right: -${arrowOffset};
`;

export const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
  position: relative;
  z-index: 1;
`;

export const Indicator = styled.div`
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background: rgb(40, 40, 41);
  margin-right: 16px;
  cursor: pointer;

  &.active {
    border: 2px solid rgb(0, 17, 56);
    background-color: white;
  }
`;

export const A11yWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;

  div {
    flex: 0 0 auto;
  }
`;
