import {
  IReviewItem,
  ReviewStatus,
} from '@apw/components/ratingAndReview/typings/reviewItem.interface';
import { transport } from '@apw/core';
import {
  delCanFetchFlag,
  hasCanFetchFlag,
  setCanFetchFlag,
  shouldProtectCanFetchFlagFromDeletion,
} from '@apw/stores/myReviews/canFetchFlag.service';
import { IMyReviewsCache } from '@apw/stores/myReviews/types';
import {
  generateExpireTime,
  generateHashKey,
  isExpired,
} from '@apw/stores/myReviews/utils';
import { set } from 'lodash';
import { action, observable } from 'mobx';
import moment from 'moment';

const EXPIRE_DURATION = 30;
const DURATION_UNIT = 'minutes';

export class MyReviewsStore {
  @observable
  items: IMyReviewsCache = {};

  constructor() {
    set(window, 'RCAPW.getMyReviewsCache', () => {
      return this.items;
    });
  }

  async loadItem(
    rcExtId: string,
    appId: string,
    skipFlagCheck = false,
  ): Promise<IReviewItem | null> {
    const myReviewItem = this.getItemFromCache(rcExtId, appId);

    if (myReviewItem) {
      return myReviewItem;
    }

    this.delItem(rcExtId, appId);

    const canFetchFlag = skipFlagCheck || hasCanFetchFlag(rcExtId, appId);

    if (canFetchFlag) {
      await this.loadItemFromBackend(rcExtId, appId);
      return this.getItemFromCache(rcExtId, appId);
    }

    return null;
  }

  getItemFromCache(rcExtId: string, appId: string): IReviewItem | null {
    const hashKey = generateHashKey(rcExtId, appId);
    const cache = this.items[hashKey];
    const expired = cache && isExpired(cache);

    return cache && !expired ? cache.reviewItem : null;
  }

  onPendingItemFound(
    rcExtId: string,
    appId: string,
    reviewItem: IReviewItem,
  ): void {
    this.setItem(rcExtId, appId, reviewItem);
    setCanFetchFlag(rcExtId, appId);
  }

  onApprovedOrRejectedItemFound(
    rcExtId: string,
    appId: string,
    reviewItem: IReviewItem,
    protectCanFetchFlag = false,
  ): void {
    this.setItem(rcExtId, appId, reviewItem);

    if (!protectCanFetchFlag) {
      delCanFetchFlag(rcExtId, appId);
    }
  }

  onItemDeleted(
    rcExtId: string,
    appId: string,
    protectCanFetchFlag = false,
  ): void {
    this.delItem(rcExtId, appId);

    if (!protectCanFetchFlag) {
      delCanFetchFlag(rcExtId, appId);
    }
  }

  loadItemFromBackend = async (rcExtId: string, appId: string) => {
    try {
      const myReviewItem = await transport.fetchMyReviewItem(appId);

      if (myReviewItem?.status === ReviewStatus.Pending) {
        this.onPendingItemFound(rcExtId, appId, myReviewItem);
        return;
      }

      const protectCanFetchFlag = shouldProtectCanFetchFlagFromDeletion(
        rcExtId,
        appId,
      );

      this.onApprovedOrRejectedItemFound(
        rcExtId,
        appId,
        myReviewItem,
        protectCanFetchFlag,
      );
    } catch (err) {
      if (err.response.status !== 404) {
        return;
      }

      const protectCanFetchFlag = shouldProtectCanFetchFlagFromDeletion(
        rcExtId,
        appId,
      );

      this.onItemDeleted(rcExtId, appId, protectCanFetchFlag);
    }
  };

  @action.bound
  setItem(rcExtId: string, appId: string, reviewItem: IReviewItem): void {
    const hashKey = generateHashKey(rcExtId, appId);

    this.items = {
      ...this.items,
      [hashKey]: {
        reviewItem,
        expireTime: generateExpireTime(
          moment().add(EXPIRE_DURATION, DURATION_UNIT),
        ),
      },
    };
  }

  @action.bound
  delItem(rcExtId: string, appId: string): void {
    const hashKey = generateHashKey(rcExtId, appId);

    const newItem = { ...this.items };
    delete newItem[hashKey];

    this.items = newItem;
  }
}
