import { useCallback, useEffect, useMemo, useState } from 'react';
import { axiosInstance } from './api.service';
import { APIRequestOptions } from './APIRequestOptions';

export const useAPIRequestLoading = (): [boolean] => {
  const [counter, setCounter] = useState(0);
  const increase = useCallback(() => setCounter((c) => c + 1), [setCounter]);
  const decrease = useCallback(() => setCounter((c) => c - 1), [setCounter]);

  const interceptor = useMemo(
    () => ({
      request: (config: APIRequestOptions) => {
        if (config.useGlobalLoading) {
          increase();
        }

        return config;
      },
      response: (response) => {
        const { config } = response;

        if (config.useGlobalLoading) {
          decrease();
        }

        return response;
      },
      error: (error) => {
        const { config } = error;

        if (config.useGlobalLoading) {
          decrease();
        }

        return Promise.reject(error);
      },
    }),
    [increase, decrease],
  );

  useEffect(() => {
    const interceptors = axiosInstance.interceptors;
    const reqInterceptor = interceptors.request.use(
      interceptor.request,
      interceptor.error,
    );
    const resInterceptor = interceptors.response.use(
      interceptor.response,
      interceptor.error,
    );

    return () => {
      interceptors.request.eject(reqInterceptor);
      interceptors.response.eject(resInterceptor);
    };
  }, [interceptor]);

  return [counter > 0];
};
