import React, { FC, useContext } from 'react';
import { RcIcon, styled } from '@ringcentral/juno';
import premierPartnerBadge from '../../../../assets/images/premier-partner-badge.svg';
import { ProfileDetailContext } from '../../index';

export const PremierPartnerBadge: FC = () => {
  const store = useContext(ProfileDetailContext);
  return (
    <Wrapper data-test-automation-id="premierPartnerBadge">
      <IconContainer>
        <RcIcon
          className="premierPartnerIcon"
          symbol={premierPartnerBadge}
          size="inherit"
          aria-hidden="true"
        />
      </IconContainer>
      <Text>{store.translation.premierPartner}</Text>
    </Wrapper>
  );
};

const Wrapper = styled.span`
  margin-left: 8px;
`;

const IconContainer = styled.span`
  display: inline-block;
  height: 22px;
  font-size: 18px;
  vertical-align: middle;
  position: relative;
  top: -1px;
`;

const Text = styled.span`
  display: inline-block;
  height: 20px;
  margin-left: 8px;
  font-size: 16px;
  vertical-align: baseline;
`;
