import { BlockInterface } from '@apw/components/pageContent/typings/block.interface';
import { IProfileInPageContent } from '@apw/modules/page/typings/page-content.interface';
import { PagingInterface, ProfileListItemInterface } from '@apw/types';

export interface CollectionBlockInterface extends BlockInterface {
  title: string;
  subTitle?: string;
  collectionId: number;
  displayCount: number;
  appList: ProfileListItemInterface[];
  isLoading?: boolean;
  profiles: {
    records: IProfileInPageContent[];
    paging: PagingInterface;
  };
}

export enum CollectionBlockPropertyEnum {
  TITLE = 'title',
  SUB_TITLE = 'subTitle',
  COLLECTION_ID = 'collectionId',
  DISPLAY_COUNT = 'displayCount',
}

export enum DisplayOnAppsEnum {
  All = 9999,
}
