import React from 'react';
import premierPartnerBadge from '@apw/assets/images/premier-partner-badge.svg';
import { CertifiedBadge } from '@apw/components/certifiedBadge';
import { Rating } from '@apw/components/ratingAndReview/rating/Rating';
import { useCompatibilityProfilePath } from '@apw/routes/useCompatibilityPaths';
import { ProfileLogo, ProfileName } from '@apw/shared/profile';
import { ProfileListItemInterface } from '@apw/types';
import { useTheme } from '@material-ui/core/styles';
import { RcIcon } from '@ringcentral/juno';
import { Link } from 'react-router-dom';
import { MultipleLineEllipsis } from '@drw/lib';
import {
  AppPublisher,
  Content,
  RatingWrapper,
  ShortDes,
  TileBottom,
  TileTop,
  Wrapper,
  WrapperShadow,
} from './ProfileTile.sc';

export interface ProfileTileProps {
  app: ProfileListItemInterface;
  handleClick?: () => void;
}

export function ProfileTile(props: ProfileTileProps) {
  const { app, handleClick } = props;
  const theme = useTheme();
  const defaultProfilePath = useCompatibilityProfilePath(
    `${app.vanityUrl || app._id}`,
  );
  const profilePath = app.path || defaultProfilePath;

  const onTileClick = () => {
    if (handleClick) {
      handleClick();
    }
  };

  return (
    <Wrapper
      data-test-automation-id={`profileTile-${app.vanityUrl || app._id}`}
    >
      <Link to={profilePath} onClick={onTileClick}>
        <Content>
          <TileTop>
            <div className={'appLogo'}>
              <ProfileLogo profile={app} borderRadius={16} />
            </div>
            {app.certifiedBadge && (
              <CertifiedBadge className={'certifiedBadge'} />
            )}
          </TileTop>
          <TileBottom>
            <div className={'appInfo'}>
              <ProfileName profile={app} className={'appName'} />
              <AppPublisher>
                <span
                  className={'publisherName'}
                  data-test-automation-id={'app-publisher'}
                >
                  {app.publisher}
                </span>
                {app.partnerBadge === 'Premier' && (
                  <RcIcon
                    className={'appPublisherIcon'}
                    symbol={premierPartnerBadge}
                    size="inherit"
                    style={{ color: theme.palette.secondary.main }}
                    role="img"
                    aria-label="PREMIER PARTNER"
                  />
                )}
              </AppPublisher>
              <ShortDes
                title={app.shortDes}
                data-test-automation-id={'app-short-desc'}
              >
                <MultipleLineEllipsis linesToDisplay={2} lineHeightPx={24}>
                  {app.shortDes}
                </MultipleLineEllipsis>
              </ShortDes>
              {app.appRatingEnabled && app.appRating && (
                <RatingWrapper
                  data-test-automation-id={'profile-tile-rating-wrapper'}
                >
                  <Rating
                    rating={app.appRating.overallRating}
                    ratingCount={app.appRating.voteCount}
                  />
                </RatingWrapper>
              )}
            </div>
          </TileBottom>
        </Content>
      </Link>
      <WrapperShadow />
    </Wrapper>
  );
}
