import React from 'react';
import rcLogo from '@apw/assets/images/rc-logo.svg';
import { RcIcon } from '@ringcentral/juno';
import { Previous } from '@ringcentral/juno-icon';
import {
  Actions,
  BackButton,
  Container,
  RcLogoImage,
  RcLogoLink,
} from './HeaderMobilePopup.sc';

export const HeaderMobilePopup = ({ onBack }) => {
  return (
    <Container>
      <RcLogoLink
        href="/apps"
        rel="noreferrer"
        aria-label="RingCentral App Gallery"
      >
        <RcLogoImage
          symbol={rcLogo}
          data-test-automation-id="mobile-header-popup-image"
        />
      </RcLogoLink>
      <Actions>
        <BackButton
          variant="plain"
          onClick={onBack}
          data-test-automation-id="mobile-header-back-btn"
        >
          <RcIcon
            symbol={Previous}
            size="medium"
            data-test-automation-id="mobile-header-back-icon"
          />
          Back
        </BackButton>
      </Actions>
    </Container>
  );
};
