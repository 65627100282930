export enum AppTypeEnum {
  BOT = 'Bot',
  NOTIFICATION_APP = 'Notification app',
  REST_API = 'REST API',
  PROFILE_ONLY = 'Profile-only',
}

export enum ApplicationType {
  MOBILE_IOS = 'MobileIOS',
  MOBILE_ANDROID = 'MobileAndroid',
  MOBILE_OTHER = 'MobileOther',

  DESKTOP_WINDOWS = 'DesktopWindows',
  DESKTOP_MAC = 'DesktopMac',
  DESKTOP_OTHER = 'DesktopOther',

  BROWSER_BASED = 'BrowserBased',

  SERVER_WEB = 'ServerWeb',
  SERVER_BOT = 'ServerBot',
  SERVER_ONLY = 'ServerOther',
  NOTIFICATION_APP = 'MessagingNotification',
  PROFILE_ONLY = 'ProfileOnly',
}
