import React from 'react';
import { SlideImage } from './screenshotSlide.sc';

const ScreenshotSlide = ({ src }: { src: string }) => {
  return (
    <SlideImage
      style={{ backgroundImage: `url(${src})` }}
      data-test-automation-id={'screenshot-slide-item'}
    />
  );
};

export default ScreenshotSlide;
