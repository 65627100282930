import React from 'react';
import {
  Container,
  SlideWrap,
} from '@apw/components/profileDetail/profileBody/overviewTab/overviewSlider/overviewSlideWrap/overviewSlideWrap.sc';
import ScaleDiv from '@apw/components/scaleDiv/ScaleDiv';

const OverviewSlideWrap = ({ children }) => {
  return (
    <Container data-test-automation-id={`overview-slide-wrap`}>
      <SlideWrap>
        <ScaleDiv widthWeight={488} heightWeight={274}>
          {children}
        </ScaleDiv>
      </SlideWrap>
    </Container>
  );
};

export default OverviewSlideWrap;
