import React, { FC, useMemo, useRef, useState } from 'react';
import {
  A11yWrapper,
  Container,
  Dot,
  DotContainer,
  Item,
  ItemList,
} from '@apw/components/carousel/carousel.sc';
import { useSubscribe } from '@apw/hooks/useSubscribe';
import { useThrottle } from '@apw/hooks/useThrottle';
import {
  EMPTY,
  fromEvent,
  interval as RxInterval,
  merge,
  Observable,
} from 'rxjs';
import {
  filter,
  map,
  repeat,
  repeatWhen,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

interface Props {
  images: string[];
  imagesAlt?: string[];
  autoplay?: boolean;
  interval?: number;
  loop?: boolean;
  draggable?: boolean;
  indicator?: boolean;
}

const Carousel: FC<Props> = ({
  images,
  autoplay = true,
  interval = 5000,
  loop = true,
  draggable = true,
  indicator = true,
  imagesAlt = [],
}) => {
  const itemsRef = useRef(null);
  const items = useMemo(() => {
    if (!loop) return images;
    // eslint-disable-next-line no-unsafe-optional-chaining
    return [images[images?.length - 1], ...images, images[0]];
  }, [images, loop]);
  const [distance, setDistance] = useState(() => {
    if (!loop) return 0;
    return 1;
  });

  const [transitionEnable, setTransitionEnable] = useState(true);

  const next = () => {
    throttleSetDistance(distance + 1);
  };

  const previous = () => {
    throttleSetDistance(distance - 1);
  };

  const goIndex = (index) => {
    throttleSetDistance(index + 1);
  };

  const focusSlider = (index: number) => {
    setTransitionEnable(true);
    setDistance(index + 1);
  };

  const boundaryMap = {
    // eslint-disable-next-line no-unsafe-optional-chaining
    0: items?.length - 2,
    // eslint-disable-next-line no-unsafe-optional-chaining
    [items?.length - 1]: 1,
  };

  const throttleSetDistance = useThrottle(
    (index) => {
      setTransitionEnable(true);
      setDistance(index);
      // eslint-disable-next-line no-unsafe-optional-chaining
      if (index === 0 || index === items?.length - 1) {
        const transitionend = fromEvent(itemsRef.current!, 'transitionend');
        transitionend.pipe(take(1)).subscribe(() => {
          setTransitionEnable(false);
          setDistance(boundaryMap[index]);
        });
      }
    },
    650,
    { trailing: false },
  );

  useSubscribe(
    () => {
      if (images?.length <= 1) return EMPTY;
      let drag$: Observable<boolean> = EMPTY;
      let interval$: Observable<boolean> = EMPTY;
      if (draggable) {
        const start$ = fromEvent<TouchEvent>(itemsRef.current!, 'touchstart');
        const end$ = fromEvent<TouchEvent>(itemsRef.current!, 'touchend');
        const down$ = fromEvent<MouseEvent>(itemsRef.current!, 'mousedown');
        const up$ = fromEvent<MouseEvent>(itemsRef.current!, 'mouseup');
        const click$ = fromEvent<TouchEvent>(itemsRef.current!, 'click');

        const preventClickEvent = () => {
          click$.pipe(take(1)).subscribe((e) => {
            e.stopImmediatePropagation();
          });
        };

        const touchdrag$ = start$.pipe(
          switchMap((start) =>
            end$.pipe(
              map(
                (end) =>
                  start.touches[0].clientX - end.changedTouches[0].clientX,
              ),
            ),
          ),
        );

        const mousedrag$ = down$.pipe(
          switchMap((start) =>
            up$.pipe(map((end) => start.clientX - end.clientX)),
          ),
        );

        drag$ = merge(touchdrag$, mousedrag$).pipe(
          filter((distance) => Math.abs(distance) > 10),
          map((distance) => distance > 0),
          tap(preventClickEvent),
          take(1),
          repeat(),
        );
      }

      if (autoplay && images.length > 1) {
        const enter$ = fromEvent<MouseEvent>(itemsRef.current!, 'mouseenter');
        const leave$ = fromEvent<MouseEvent>(itemsRef.current!, 'mouseleave');
        interval$ = RxInterval(interval).pipe(
          map(() => true),
          takeUntil(enter$),
          repeatWhen(() => leave$),
        );
      }

      return merge(drag$, interval$);
    },
    (isNext) => {
      if (isNext > 0) next();
      else previous();
    },
  );

  return (
    <Container ref={itemsRef} data-test-automation-id={`carousel`}>
      <ItemList
        data-test-automation-id={'carousel-list'}
        className={`${transitionEnable ? '' : 'noTransition'}`}
        style={{ transform: `translateX(${-100 * distance}%)` }}
      >
        {items.map((item, index) => (
          <Item
            // eslint-disable-next-line react/no-array-index-key
            key={item + index}
            style={{ backgroundImage: `url('${item}')` }}
            data-test-automation-id={`carousel-item-${index}`}
          />
        ))}
      </ItemList>
      {indicator && images?.length > 1 && (
        <DotContainer data-test-automation-id={'carousel-dot-list'}>
          {images?.map((_, index) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <Dot
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${
                distance - 1 === index || boundaryMap[distance] === index + 1
                  ? 'active'
                  : ''
              }`}
              onClick={() => goIndex(index)}
              data-test-automation-id={`carousel-dot-${index + 1}`}
            />
          ))}
        </DotContainer>
      )}
      {/* for a11y */}
      <A11yWrapper data-test-automation-id={'carousel-a11y-wrapper'}>
        {images.map((item: string, index: number) => (
          <img
            src={item}
            alt={imagesAlt[index] || ''}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            onFocus={() => focusSlider(index)}
            data-test-automation-id={`carousel-a11y-img-${index}`}
          />
        ))}
      </A11yWrapper>
    </Container>
  );
};

export default Carousel;
