import React, { FC, useContext } from 'react';
import { RcButton } from '@ringcentral/juno';
import { StoreContext } from '@apw/lib/components/profileDetail/StoreContext';

export interface IWriteReviewButtonProps {
  disabled?: boolean;
  fullWidth: boolean;
}

export const WriteReviewButton: FC<IWriteReviewButtonProps> = ({
  disabled,
  fullWidth,
}) => {
  const store = useContext(StoreContext);
  const onClick = () => {
    store.openRatingPopup();
  };

  return (
    <RcButton
      size="large"
      variant="outlined"
      color="action.primary"
      radius="lg"
      fullWidth={fullWidth}
      onClick={onClick}
      data-test-automation-id={'write-review-button'}
      disabled={disabled}
    >
      {store.translation.ratingsAndReviews.actions.writeReviewButton}
    </RcButton>
  );
};
