import { css, palette2, styled } from '@ringcentral/juno';
import { JUNO_THEME_PREFIX } from '@apw/lib/constants';

export const Rectangle = styled.div`
  width: 40px;
  height: 20px;
  background-color: ${palette2('interactive', 'f01')};
`;

export const EmptyRectangle = styled(Rectangle)`
  background-color: transparent;
  box-sizing: border-box;
  border: 1px solid ${palette2('neutral', 'l03')};
`;

export const RatingMixin = css`
  [class~='${JUNO_THEME_PREFIX}-MuiRating-label'] {
    padding: 0 4px !important;

    &:nth-of-type(1) {
      ${Rectangle}, ${EmptyRectangle} {
        border-bottom-left-radius: 9px;
        border-top-left-radius: 9px;
      }
    }
    &:nth-of-type(5) {
      ${Rectangle}, ${EmptyRectangle} {
        border-bottom-right-radius: 9px;
        border-top-right-radius: 9px;
      }
    }
  }

  [class~='${JUNO_THEME_PREFIX}-MuiRating-decimal'] {
    [class~='${JUNO_THEME_PREFIX}-MuiRating-icon'] {
      box-sizing: content-box;
      padding: 0 !important;
    }

    margin: 0 4px;
    &:first-child {
      ${Rectangle}, ${EmptyRectangle} {
        border-bottom-left-radius: 9px;
        border-top-left-radius: 9px;
      }
    }
    &:last-child {
      > span:last-child {
        ${Rectangle}, ${EmptyRectangle} {
          border-bottom-right-radius: 9px;
          border-top-right-radius: 9px;
        }
      }
    }
  }
`;
