import { get, post } from '@apw/core/api/api.service';
import {
  EntityType,
  ICoveoRequestForPages,
  ICoveoRequestForProfiles,
  ICoveoResponseForPages,
  ICoveoResponseForProfiles,
} from '@apw/shared/searchProvider';
import { SitemapProperty } from './sitemap-property.enum';

const SEARCH_HUB = 'appgallery';
const ENDPOINT = 'https://ringcentralwj3vizhz.org.coveo.com/rest/search/v2';
const HEADERS = {
  Authorization: `Bearer ${RCAPW.coveoApiKey}`,
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const FIELDS_TO_INCLUDE = [
  SitemapProperty.ENTITY_TYPE,
  SitemapProperty.LOGO_URL,
  SitemapProperty.SHORT_DESC,
  SitemapProperty.PROFILE_TYPE,
  SitemapProperty.PAGE_NAME,
  SitemapProperty.PROFILE_ID,
  SitemapProperty.PARTNER_BADGE,
  SitemapProperty.PUBLISHER,
  SitemapProperty.PAGE_ID,
  SitemapProperty.APP_DISPLAY_NAME,
  SitemapProperty.APP_ICON_URL,
  SitemapProperty.CERTIFIED_BADGE,
  SitemapProperty.OVERALL_RATING,
  SitemapProperty.VOTE_COUNT,
];

const normalizeCategory = (category: any): string => {
  const mapping = {
    'Bots & Notifications': 'Bots Notifications',
  };

  return mapping[category] || category;
};

export const searchProfiles = ({
  brand,
  keyword,
  product,
  category,
  paging,
}: ICoveoRequestForProfiles): Promise<ICoveoResponseForProfiles> => {
  category = normalizeCategory(category);

  const filters = [
    `@${SitemapProperty.BRAND}=${brand}`,
    product ? `@${SitemapProperty.PRODUCT}="${product}"` : null,
    category ? `@${SitemapProperty.CATEGORY}="${category}"` : null,
  ].filter(Boolean);

  const body = {
    cq: `@${SitemapProperty.ENTITY_TYPE}=${EntityType.PROFILE}`,
    aq: filters.join(' AND '),
    q: keyword,
    searchHub: SEARCH_HUB,
    fieldsToInclude: FIELDS_TO_INCLUDE,
    firstResult: (paging.page - 1) * paging.perPage,
    numberOfResults: paging.perPage,
  };

  return post<ICoveoResponseForProfiles>(ENDPOINT, body, { headers: HEADERS });
};

export const searchPages = ({
  brand,
  keyword,
  paging,
}: ICoveoRequestForPages): Promise<ICoveoResponseForPages> => {
  const body = {
    cq: `@${SitemapProperty.ENTITY_TYPE}=${EntityType.PAGE}`,
    aq: `@${SitemapProperty.BRAND}=${brand}`,
    q: keyword,
    searchHub: SEARCH_HUB,
    fieldsToInclude: FIELDS_TO_INCLUDE,
    firstResult: (paging.page - 1) * paging.perPage,
    numberOfResults: paging.perPage,
  };

  return post<ICoveoResponseForPages>(ENDPOINT, body, { headers: HEADERS });
};

export const requestCoveoSearchFlag = (): Promise<{ result: boolean }> => {
  const endpoint = '/api/coveo-enabled';
  return get(endpoint);
};
