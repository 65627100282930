import React, { useContext } from 'react';
import { RcTypography } from '@ringcentral/juno';
import { Container } from '@apw/lib/components/profileDetail/profileBody/ratingsAndReviewsTab/ratingsSection/header/header.sc';
import { StoreContext } from '@apw/lib/components/profileDetail/StoreContext';
import { WriteReviewButton } from '@apw/lib/components/ratingAndReview/writeReviewButton/WriteReviewButton';
import { useIsMobile } from '@apw/lib/hooks/useIsMobile';

export const Header = () => {
  const isMobile = useIsMobile();
  const { translation } = useContext(StoreContext);
  return (
    <Container>
      <RcTypography
        variant="headline2"
        color="neutral.f06"
        data-test-automation-id={'ratings-section-title'}
      >
        {translation.ratingsAndReviews.title}
      </RcTypography>
      {!isMobile && <WriteReviewButton fullWidth />}
    </Container>
  );
};
