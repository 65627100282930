import React, { ReactNode, useEffect } from 'react';
import { useThrottle } from '@apw/hooks/useThrottle';

export const DOCUMENT_HEIGHT_CHANGE_EVENT = 'DOCUMENT_HEIGHT_CHANGE_EVENT';
export const triggerDocumentHeightChange = () => {
  const event = document.createEvent('Event');
  event.initEvent(DOCUMENT_HEIGHT_CHANGE_EVENT, true, true);
  window.dispatchEvent(event);
};

export interface ObserverScrollProps {
  children: ReactNode;
  callback: () => void;
  target?: HTMLElement;
  activeObserver?: boolean;
  wait?: number;
}

export const ObserverScroll = (props: ObserverScrollProps) => {
  const {
    children,
    callback,
    target = window,
    activeObserver = true,
    wait = 0,
  } = props;

  const handleCallback = useThrottle(() => callback(), wait);

  useEffect(() => {
    if (activeObserver) {
      target.addEventListener('scroll', handleCallback);
      target.addEventListener('resize', handleCallback);
      target.addEventListener(DOCUMENT_HEIGHT_CHANGE_EVENT, handleCallback);
    } else {
      target.removeEventListener('scroll', handleCallback);
      target.removeEventListener('resize', handleCallback);
      target.removeEventListener(DOCUMENT_HEIGHT_CHANGE_EVENT, handleCallback);
    }

    return () => {
      target.removeEventListener('scroll', handleCallback);
      target.removeEventListener('resize', handleCallback);
      target.removeEventListener(DOCUMENT_HEIGHT_CHANGE_EVENT, handleCallback);
    };
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
